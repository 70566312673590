import React from "react";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { country_codes, toAbsoluteUrl } from "src/Helpers";
import FormikSearchDropdown from "../SearchDropdown/FormikSearchDropdown";

type Props = {
  formik: any;
  placeholder?: string;
  label?: string;
  nameKey: string;
  defaultValue?: string;
  value?: string;
  onchange?: (e: any) => void;
  disabled?: boolean,
  displayCode?: boolean,
};

const FormikCountryCodePIcker2 = ({
  formik,
  nameKey,
  label,
  value,
  onchange,
  placeholder,
  disabled,
  displayCode
}: Props) => {
  // console.log({ phone: value });
  return (
    <FormikSearchDropdown
      formik={formik}
      qa-data={nameKey}
      placeholder={placeholder}
      label={label}
      nameKey={nameKey}
      value={value}
      disabled={disabled}
      displayCode={displayCode}
      onchange={(e: any) => onchange && onchange(e)}
      options={country_codes.map((o: any) => ({
        label: displayCode ? o.code : o.dial_code,
        value: o.dial_code,
        icons: toAbsoluteUrl(o.flag),
      }))}
    />
  );
};

export default FormikCountryCodePIcker2;
