
import { addDays } from "date-fns";
import { DESIGNATION_OPTIONS_2, SECURITY_QUATIONS_LIST, checkIsBusinessEmailAlreadyExists, type_of_representative_OPTIONS } from "src/Helpers";
import * as yup from 'yup';



export const EmailValidate_Filed = {
    business_email: {
        key: "business_email",
        label: "Email",
        field_type: "email",
        field_mode: "email",
        placeholder: "Enter Email ID",
    }
}

export interface FieldOptions {
    key: string;
    label: string;
    field_type: string; // Adjust the type based on the actual field types you are using
    field_mode: string
    options?: string[]; // Adjust the type based on the actual options you want
    placeholder: string;
}

export interface IRepresentativeForm {
    business_email: string,
    country_code: string,
    phone_number: string,
    type_of_representative: string;
    designation_of_representative: string;
    name_of_representative: string;
    national_identification_number: string;
    nationality_of_representative: string;
    bank_verification_number: string;
    passport_number: string;
    passport_expiry: string;
    cerpac_number: string;
    cerpac_expiry: string;
    security_question1: string;
    security_answer1: string;
    security_question2: string;
    security_answer2: string;
    security_question3: string;
    security_answer3: string;
    cac_file_url: string;
    other_file_url: string;
}


export type IDisabledFields = {
    [K in keyof IRepresentativeForm]: boolean;
};

export const INIT_CHANGE_REPRENTATIVE_DEFAULT_FORM: IRepresentativeForm = {
    business_email: "",
    country_code: "234",
    phone_number: "",
    type_of_representative: '',
    designation_of_representative: '',
    name_of_representative: '',
    national_identification_number: '',
    nationality_of_representative: '',
    bank_verification_number: '',
    passport_number: '',
    passport_expiry: '',
    cerpac_number: '',
    cerpac_expiry: '',
    security_question1: '',
    security_answer1: '',
    security_question2: '',
    security_answer2: '',
    security_question3: '',
    security_answer3: '',
    cac_file_url: '',
    other_file_url: '',
}

const errorMessages = {
    type_of_company: "Please select type of company",
    state_of_company: "Please select state of company",
    rc_number: "Please enter valid RC Number",
    company_name: "Please enter company name",
    business_phone_code: "Please select phone code",
    business_phone_number: "Please enter phone number",
    business_email: "Please enter email id",
    company_address1: "Please enter House No / Area.",
    company_address2: "Please enter Street / Landmark",
    type_of_representative: "Please select type of representative",
    designation_of_representative: "Please select designation of representative.",
    name_of_representative: "Please enter name of representative", // Only if type_of_representative is 'Nigerian'.
    national_identification_number: "Please Enter National Identification Number",
    nationality_of_representative: "Please select nationality of representative", // No specific error message, as it's optional.
    bank_verification_number: "Please enter bank verification number", // No specific error message, as it's optional.
    passport_number: "Please enter passport number",
    passport_expiry: "Please select passport expiry",
    cerpac_number: "Please enter cerpac number",
    cerpac_expiry: "Please select cerpac expiry",
    security_question1: "Please select security question1",
    security_answer1: "Please enter security answer1",
    security_question2: "Please select security question2",
    security_answer2: "Please enter security answer2",
    security_question3: "Please select security question3",
    security_answer3: "Please enter security answer3",
    special_characters_error: "Spacial characters are not allowed",
};

export const ChangeRepresentativeFormFiledsSchema = yup.object().shape({
    business_email: yup.string().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, { message: "Invalid Email address" }).email().required(errorMessages.business_email),
    country_code: yup.string().test('business_phone_code', (value, validationcontext) => {
        const { createError, parent: { business_phone_number } } = validationcontext;
        if (!value && business_phone_number) {
            return createError({ message: "Please select country code" });
        }
        return true;
    }),
    phone_number: yup.string()
        .matches(/^([0-9]+)$/, 'Invalid Phone number')
        .length(10, "Phone number must be 10 digits")
        .required(errorMessages.business_phone_number),
    type_of_representative: yup.string().required(errorMessages.type_of_representative),
    designation_of_representative: yup.string().required(errorMessages.designation_of_representative),
    name_of_representative: yup.string().matches(/^([a-zA-Z. ]+)$/, { message: errorMessages.special_characters_error }).min(3, "Min lenght must be atleast 3 characters").required(errorMessages.name_of_representative),
    national_identification_number: yup.string().length(11, "National Identification Number must be 11 digits.").optional().test('national_identification_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Nigerian') {
            return createError({ message: errorMessages.national_identification_number });
        }
        else if (value && type_of_representative === 'Foreigner') {
            return true;
        }
        return true;
    }),
    nationality_of_representative: yup.string().required(errorMessages.nationality_of_representative),
    bank_verification_number: yup.string().length(11, "Bank Verification Number must be 11 digits").required("Please enter bank verification number"),
    passport_number: yup.string().min(8, "Passport Number must be atleast 8 characters ").max(12, "It max 12 digits").optional().test('passport_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.passport_number });
        }
        return true;
    }),
    passport_expiry: yup.string().optional().test('passport_expiry', (value, validationcontext) => {
        const { createError, parent: { type_of_representative, passport_number } } = validationcontext;

        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && !passport_number && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.passport_expiry });
        }

        if (!value && passport_number) {
            return createError({ message: errorMessages.passport_expiry });
        }

        if (value && inputDate && inputDate <= addDays(currentDate, 2)) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    cerpac_number: yup.string().length(8, "CARPAC number must be 8 characters").optional().test('cerpac_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.cerpac_number });
        }

        return true;
    }),
    cerpac_expiry: yup.string().optional().test('cerpac_expiry', (value, validationcontext) => {
        const { createError, parent: { type_of_representative, cerpac_number, passport_expiry } } = validationcontext;

        const inputDate = value && new Date(value);
        const currentDate = new Date();
        const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;

        if (!value && (cerpac_number || type_of_representative === 'Foreigner')) {
            return createError({ message: errorMessages.cerpac_expiry });
        }

        if (value && inputDate && inputDate <= addDays(currentDate, 2)) {
            return createError({ message: "Expiry date should not be less than current date." });
        }
        else if (value && pasportDate && inputDate && inputDate > pasportDate) {
            return createError({ message: "Expiry date should not be greater than passport expiry date." });
        }

        return true;
    }),
    security_question1: yup.string().required(errorMessages.security_question1),
    security_answer1: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer1),
    security_question2: yup.string().required(errorMessages.security_question2),
    security_answer2: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer2),
    security_question3: yup.string().required(errorMessages.security_question3),
    security_answer3: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer3),
    cac_file_url: yup.mixed().optional().test('cac_file_url', (value, validationcontext) => {

        const { createError, parent: { tcc_number } } = validationcontext;

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && tcc_number && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }


        if (value && tcc_number && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    other_file_url: yup.mixed().optional().test('other_file_url', (value, validationcontext) => {

        const { createError, parent: { tcc_number } } = validationcontext;

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && tcc_number && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }


        if (value && tcc_number && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    })
});


export const ChangeRepresentativeFormFileds = {
    business_email: {
        key: "business_email",
        label: "Business email",
        field_type: "email",
        field_mode: "text",
        placeholder: "Business email",
    },
    phone_number: {
        key: "phone_number_and_code",
        label: "Business phone no.",
        field_type: "phone_no",
        phone_no_form_key: "phone_number",
        country_code_form_key: "country_code",
        placeholder: "Business phone no.",
    },
    type_of_representative: {
        key: 'type_of_representative',
        label: 'Type of representative',
        field_type: 'options',
        options: type_of_representative_OPTIONS,
        placeholder: 'Type of representative',

    },
    designation_of_representative: {
        key: 'designation_of_representative',
        label: 'Designation of representative',
        field_type: 'options_with_search',
        options: DESIGNATION_OPTIONS_2,
        placeholder: 'Designation of representative',
        optionsWidth: 400,

    },
    name_of_representative: {
        key: 'name_of_representative',
        label: 'Name of Representative',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Name of representative',
        maxLength: 50,
        regex: /[^A-Za-z ]+/g,
    },
    national_identification_number: {
        key: 'national_identification_number',
        label: 'National identification number of representative',
        field_type: 'text',
        field_mode: 'numeric',
        placeholder: 'National identification number of representative',
        maxLength: 11,
        regex: /[^0-9]+/g,

    },
    nationality_of_representative: {
        key: 'nationality_of_representative',
        label: 'Nationality of representative',
        field_type: 'options_with_search',
        options: [],
        placeholder: 'Nationality of representative',

    },
    bank_verification_number: {
        key: 'bank_verification_number',
        label: 'BANK verification number (BVN)',
        field_type: 'text',
        field_mode: 'numeric',
        placeholder: 'Bank verification number of representative',
        maxLength: 11,
        regex: /[^0-9-]+/g,

    },
    passport_number: {
        key: 'passport_number',
        label: 'PASSPORT NO. OF REPRESENTATIVE',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Passport no. of representative',
        maxLength: 12,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,

    },
    passport_expiry: {
        key: 'passport_expiry',
        label: 'Passport expiry of representative',
        field_type: 'date',
        placeholder: 'Passport expiry of representative',

    },
    cerpac_number: {
        key: 'cerpac_number',
        label: 'CERPAC NO. OF REPRESENTATIVE',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'CERPAC no. of representative',
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,

    },
    cerpac_expiry: {
        key: 'cerpac_expiry',
        label: 'CERPAC expiry of representative',
        field_type: 'date',
        placeholder: 'CERPAC expiry of representative',

    },
    empty: {
        key: 'empty',
        label: '',
        field_type: 'empty',
        placeholder: '',

    },
    security_question1: {
        key: "security_question1",
        label: "Security Question 1",
        field_type: 'options_with_search',
        options: SECURITY_QUATIONS_LIST,
        placeholder: 'Security Question 1',
    },
    security_answer1: {
        key: "security_answer1",
        label: "Security Answer 1",
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Security Answer 1',
    },
    security_question2: {
        key: "security_question2",
        label: "Security Question 2",
        field_type: 'options_with_search',
        options: SECURITY_QUATIONS_LIST,
        placeholder: 'Security Question 2',
    },
    security_answer2: {
        key: "security_answer2",
        label: "Security Answer 2",
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Security Answer 2',
    },
    security_question3: {
        key: "security_question3",
        label: "Security Question 3",
        field_type: 'options_with_search',
        options: SECURITY_QUATIONS_LIST,
        placeholder: 'Security Question 3',
    },
    security_answer3: {
        key: "security_answer3",
        label: "Security Answer 3",
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Security Answer 3',
    },
    cac_file_url: {
        key: "cac_file_url",
        label: "CAC File URL",
        field_type: 'file',
        placeholder: 'CAC File URL',
    },
    other_file_url: {
        key: "other_file_url",
        label: "AUTHORIZATION LETTER",
        field_type: 'file',
        placeholder: 'Other File URL',
    },
}