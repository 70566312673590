import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { getAuth } from "src/Modules/Auth/Core";
import { IQuotaSetupForm, QuotaSetupForm_INIT, positionsData } from "src/Modules/QuotaSetup/module";
import { createQuota } from "src/services/services";
import { ICreateQuotaPostData } from "src/types";



// Define a type for the slice state
interface IemployeeReducer {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  activeTab: number
  status: number | undefined;
  quotaDetails: IQuotaSetupForm
}

const defaultValues = QuotaSetupForm_INIT

// Define the initial state using that type
const initialState: IemployeeReducer = {
  error: false,
  message: undefined,
  isLoading: false,
  activeTab: 1,
  status: undefined,
  quotaDetails: defaultValues
};

// Create an async thunk for making the API call
export const createQuotaAsync: any = createAsyncThunk(
  "createQuotaSetup/create",
  async (quotaDetails: {
    approvalNumber: string,
    expatriateType: string,
    expirationDate: string,
    totalSlotApproved: string,
    approvedDate: string,
    approvalLetterDate: string,
    expatriate_doc: string,
    additional_name: string,
    additional_doc: string,
    position: positionsData[],
    availableQuota: string,
    companyName: string,
    RCNumber: string
  }, thunkApi: any) => {
    try {
      const { user_id, api_token }: any = getAuth();

      let formData = quotaDetails;

      // console.log({ formData });
      // Make the API call using Axios
      const postData: ICreateQuotaPostData = {
        "approvalNumber": formData.approvalNumber,
        "expatriateType": formData.expatriateType,
        "expirationDate": format(new Date(formData.expirationDate), "dd/MM/yyyy"),
        "totalSlotApproved": formData.totalSlotApproved,
        "approvedDate": format(new Date(formData.approvedDate), "dd/MM/yyyy"),
        "approvalLetterDate": format(new Date(formData.approvalLetterDate), "dd/MM/yyyy"),
        "expatriate_doc": formData.expatriate_doc as any,
        "position": formData.position && formData.position?.map((p: positionsData) => {
          return ({
            "Position": p.position_name,
            "SlotApproved": p.position_count
          })
        }) as any,
        "userid": user_id,
        "comanyname": formData.companyName,
        "rcnumber": formData.RCNumber,
        "additional_doc": formData.additional_doc as string,
        "additional_name": formData.additional_name
      };

      // // console.log({ user_id, api_token });

      const response: any = await createQuota(postData);
      if (response?.status && response?.status == 201) {
        // console.log({ createEmpResponse: response });
        return response;
      }
      else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      else {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createQuotaSetupSlicer = createSlice({
  name: "createQuotaSetup",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload?.message;
      state.status = action.payload?.status;
    },
    resetError: (state) => {
      state.error = false;
      state.status = 0;
      state.message = undefined;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
      state.status = undefined;
      state.quotaDetails = defaultValues;
      state.activeTab = 1
    },
    setActiveTab: (state, action: PayloadAction<any>) => {
      state.activeTab = action.payload;
    },
    setQuotaDetails: (state, action: PayloadAction<any>) => {
      state.quotaDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createQuotaAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        createQuotaAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        createQuotaAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.status = action.payload?.status || undefined;
        }
      );
  },
});

export const {
  setError,
  resetState,
  resetError,
  setActiveTab,
  setQuotaDetails
} = createQuotaSetupSlicer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default createQuotaSetupSlicer.reducer;
//  export default []
