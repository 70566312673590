import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "src/components/ui/checkbox";

import { Task } from "../data/schema";

import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { format } from "date-fns";
import Batch from "./Batch";
import { Badge } from "src/components/ui/badge";
import { ChevronDownIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Select } from "@radix-ui/react-select";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "src/components/ui/select";
import { setTransaction } from "src/Silcers/paymentsListSlicer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const columns: ColumnDef<Task>[] = [
  // {
  //   accessorKey: "id",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Task" />
  //   ),
  //   cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  // {
  //   id: "name",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Name" />
  //   ),
  //   cell: ({ row }) => {
  //     return (
  //       <div className="flex space-x-2">
  //         <div className="flex flex-col">
  //           <div>{row.original.name}</div>
  //           {/* <div>{row.original.sex}</div> */}
  //         </div>
  //       </div>
  //     );
  //   },
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "transaction_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Transaction Id" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col w-auto items-start">
          <div className="uppercase">{row.original.transaction_id}</div>
          {/* <div>Expiry On :{row.original.passportexpiry}</div> */}
        </div>
      );
    },
  },
  {
    accessorKey: "dateTime",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({ row }) => {
      // console.log({ dateTime: row.original.dateTime });

      return (
        <div className="flex flex-col w-auto items-start">
          <div>
            {row.original?.dateTime && !row.original.dateTime.includes('/') && format(new Date(row.original.dateTime), "dd/MM/yyyy")}
            {row.original?.dateTime && row.original.dateTime.includes('/') && row.original.dateTime}
          </div>
          {/* <div>Expiry On :{row.original.visaexpiracydate}</div> */}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "paymentstatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payment Status" />
    ),
    cell: ({ row }) => {
      switch (row.original.status) {
        case "success":
          return (
            <Badge  >Completed</Badge>
          );
        case "failed":
          return (
            <Badge variant={'destructive'}>Failed</Badge>
          );
        case "pending":
          return (
            <Badge variant={"secondary"}>Pending</Badge>
          );
      }
    },
  },
  {
    accessorKey: "total_amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount ($)" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col w-auto items-start">
          <div>$ {row.original.total_amount?.toFixed(2)}</div>
          {/* <div>Expiry On :{row.original.visaexpiracydate}</div> */}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      let id: string = row.original.transaction_id;
      return <Batch id={id} />;
    },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
  },
];
