import React, { useState } from 'react'
import NavBar from 'src/components/Common/NavBar'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "src/components/ui/accordion"

interface FAQItem {
    question: string;
    answer: string | React.ReactNode;
    showAnswer: boolean;
}

function FaqMian() {
    const [showAllAnswers, setShowAllAnswers] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [faqData, setFAQData] = useState<FAQItem[]>([
        {
            question: 'What is the Expatriate Employment Levy (EEL)?',
            answer: 'The EEL is a financial contribution imposed on employers who hire expatriate workers. This Levy which is mostly on the off-shore earnings of expatriates working in Nigeria aims to balance economic growth and workforce development by ensuring equitable contributions from expatriate employment.',
            showAnswer: false,
        },
        {
            question: 'Who is Eligible for the EEL?',
            answer: "Eligibility varies depending on factors like industry, expatriate's role, and duration of stay or employment in Nigeria. Non-citizen or non-resident expatriates that occupy Quota positions or engaged by means of Temporary Work Permit usually fall within the EEL's scope.",
            showAnswer: false,
        },
        {
            question: 'What are the document requirements for getting a EEL card?',
            answer: <>
                To apply for an Employee Expatriate Levy Card, companies are required to submit the following documents:<br />
                <br />
                <strong>Company Documents:</strong><br />

                Corporate Affairs Commission (CAC) documentation is mandatory.<br />
                Tax Clearance Certificate (TCC) is optional.<br />
                PenCom documentation is optional.<br />
                Industrial Training Fund (ITF) documentation is optional.<br />
                Renewal of Expatriate Quota Positions is mandatory<br />
                <br />
                <strong>Employee Documents:</strong><br />
                Employee's International Passport.<br />
                Digital Passport Size Photograph (51 mm x 51 mm, maximum file size of 2 MB with white background).<br />
                Employee's Combined Expatriate Residence Permit and Alien Card (CERPAC).<br />
                <br />
                Please ensure that all documents comply with the specified maximum file size of 5 MB and are in either JPEG, PNG, or PDF formats<br />
            </>,
            showAnswer: false,
        },
        {
            question: 'How much is the EEL Levy?',
            answer: <>
                Employers of Expatriates covered by the EEL are required to pay 15,000 (USD) for Directors and 10,000 (USD) for Non-Directors.<br />
                If the quota is valid for less than a year, the EEL fee will be calculated proportionally.
            </>,
            showAnswer: false,
        },
        {
            question: 'Are there exemptions from the EEL?',
            answer: <>
            <strong>Exemptions of EEL Payment.</strong><br/>  <br/>          
            <strong>Diplomatic Exemptions: </strong>Diplomats and individuals on official government business are granted exemptions from EEL. This includes ambassadors, consuls, and members of international organizations.<br/><br/>
            <strong>ECOWAS Exemption: </strong> Expatriate Staff of the ECOWAS organization are exempted from the payment of EEL. <br/><br/>
            <strong>Non-Working Dependents: </strong>Dependents of all categories of Expatriates in Nigeria are exempted from payment of the EEL. However, this exemption shall not apply to Dependents who are confirmed to be engaged in any employment while resident in Nigeria. However, they have to generate their EEL Card Using the EELMS Online portal as dependent under the respective employee.<br/><br/>
            <strong>Humanitarian Exemptions </strong>There may be exemptions for individuals facing urgent humanitarian situations, such as victims of natural disasters, armed conflicts, or other crises. Special visas or permits may be granted to those in need.<br/>
            <br/><strong>NGO, Religious Organization Exemptions: </strong>Staffs of NGOs, and Religious Organizations are exempted from the payment of EEL. However, they have to generate their EEL Card Using the EELMS Online portal.
            </>
            ,
            showAnswer: false,
        },
        {
            question: 'How often is the EEL paid?',
            answer: 'EEL payment is annually.',
            showAnswer: false,
        },
        {
            question: 'Does the EEL apply to short-term employments?',
            answer: 'Expatriates engaged in short-term employments/assignments may be exempted from the EEL, provided the duration of such employment/engagement is cumulatively less than 183 days in one (1) fiscal year, from the date of entry into Nigeria for such employment/engagement.',
            showAnswer: false,
        },
        {
            question: 'How can employers ensure compliance with EEL reporting?',
            answer: 'Employers can ensure compliance by maintaining accurate records, submitting timely reports, and adhering to reporting deadlines. Employing digital reporting platforms can streamline the process.',
            showAnswer: false,
        },
        {
            question: 'How to replace the employee in the existing EEL?',
            answer: 'Employers can remove leaving employees and allocate the position to new employees without any charge till the existing EEL validation. The EEL portal allowed unlimited change within the validation period.',
            showAnswer: false,
        },
        {
            question: 'What resources can employers and expatriates access?',
            answer: 'Government websites, official guidelines, online calculators, and industry associations often provide resources for understanding EEL regulations, calculations, and compliance procedures.',
            showAnswer: false,
        },
        {
            question: 'Is there a mobile application available for EEL?',
            answer: 'Yes, to download the EEL application, simply visit the Google Play Store if you are using an Android phone or the App Store if you have an iPhone. ',
            showAnswer: false,
        },
        {
            question: 'Is CERPAC number mandatory for dependents?',
            answer: 'CERPAC number is not mandatory for dependents under 18 years old. ',
            showAnswer: false,
        },
        {
            question: 'What is the Migrant e-Registration Number?',
            answer: <>
            As per THE MIGRANT e-REGISTRATION GUIDELINES 2019 ISSUED BY THE NIGERIA IMMIGRATION SERVICE The Nigerian Immigration Service (NIS) has mandated the registration of all migrants who have lived in or plan to remain in Nigeria for over 90 days on a new Migration e-Registration Platform (the “Platform”). Expatriates must provide evidence of registration on the Platform before applying for any immigration permits such as CERPAC, resident cards, or temporary work visas, including any renewals. 
            <br/><br/>
            The requirement for registration on the Platform applies to the following:<br/>
            -	Employed /self-employed<br/>
            -	Students<br/>
            -	Expatriate spouse of Nigerians (Niger wife and special immigrant status)<br/>
            -	Dependents (spouse and children over 18 years)<br/>
            -	The expatriate is required to visit the NIS office for data capture and submit the following documents:<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; o	Registration form which can be obtained at the NIS office;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      o	A copy of the passport data page;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     o	A copy of the CERPAC/ECOWAS card;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      o	Evidence of payment of registration fee.<br/>

            </> ,
            showAnswer: false,
        },
        {
            question: 'EEL exemption policy',
            answer: 
            <>
             </>,
            showAnswer: false,
        },
        {
            question: 'Help-desk queries?',
            answer: <>
                Help desk can address all questions, clarifications, levy calculations and any other issues related to the Expatriate Employee Levy Management System(EELMS).<br />
                For registration, please visit: <a href='www.eel.interior.gov.ng' target="_blank" className=' text-primary'>www.eel.interior.gov.ng</a> <br />
                For any query, you can reach out to us via email at : <span className=' text-primary'><a target="_blank" href={`mailto:enquiry.eel@interior.gov.ng`}>
                enquiry.eel@interior.gov.ng
                  </a></span>  <br />
                If you prefer to call us, our help line is available at: <span className=' text-primary'>+234-7080647200</span> <br />
            </>,
            showAnswer: false,
        },
    ]);

    // Filter FAQ items based on the search query
    const filteredFAQ = faqData.filter((faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Toggle visibility of answers
    // const toggleAnswer = (index: number) => {
    //     const updatedFAQ = [...faqData];
    //     updatedFAQ[index].showAnswer = !updatedFAQ[index].showAnswer;
    //     setFAQData(updatedFAQ);
    // };

    // Toggle visibility of all answers
    // const toggleAllAnswers = () => {
    //     setShowAllAnswers(!showAllAnswers);
    // };

    return (
        <div className=' p-10'>
            <div className="flex flex-col">
                <h1 className="text-3xl text-primary font-semibold mb-4">Frequently Asked Questions</h1>
                <div className=" bg-white shadow-lg rounded-lg p-6 text-black mb-4">
                    <p>This section contains a range of questions that may arise, providing accurate
                        information and offering clear explanations to dispel uncertainties and
                        facilitate a better understanding of the EEL. Addressing common queries about the levy provides
                        valuable insights for both employers and expatriates.
                    </p>
                    <Accordion type="single" collapsible className="w-full">
                        {filteredFAQ.map((faq, index) =>
                            <AccordionItem key={index} value={`item-${index + 1}`}>
                                <AccordionTrigger className='my-2'>
                                    <button
                                        className="w-full text-left font-semibold focus:outline-none text-xl text-primary"
                                    // onClick={() => toggleAnswer(index)}
                                    >
                                        {faq.question}
                                    </button>
                                </AccordionTrigger>
                                <AccordionContent>
                                    <p className="text-gray-600 text-left sub-styles px-5">{faq.answer}</p>
                                </AccordionContent>
                            </AccordionItem>
                        )}
                    </Accordion>
                    {/* {filteredFAQ.map((faq, index) => (
                        <div className="mb-4" key={index}>
                            <button
                                className="w-full text-left font-semibold focus:outline-none text-xl"
                                onClick={() => toggleAnswer(index)}
                            >
                                {faq.question}
                            </button>
                            {faq.showAnswer && (
                                <p className="text-gray-600 text-left sub-styles">{faq.answer}</p>
                            )}
                        </div>
                    ))} */}
                    {/* {filteredFAQ.length === 0 && (
                        <p>No results found.</p>
                    )}
                    <button
                        className="w-full text-left font-semibold text-primary focus:outline-none mt-5"
                        onClick={toggleAllAnswers}
                    >
                        {showAllAnswers ? 'Hide Answers' : 'View More'}
                    </button> */}
                </div>
            </div>
        </div>
    )
}

export default FaqMian