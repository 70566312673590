import { SVGC } from 'src/Helpers/SVGC'
import { optionsType, toAbsoluteUrl } from 'src/Helpers'
import React, { memo, useEffect, useState } from 'react'

type Props = {
    options: optionsType[],
    value: string | undefined,
    onchange: (e: any) => void,
    ogValue?: string
}

const SelectValue = ({ value, options, onchange, ogValue }: Props) => {

    const [selectedObect, setselectedObect] = useState<optionsType | undefined>(undefined);

    // console.log({ selectedObect, options, value, ogValue });


    useEffect(() => {
        if (value && ogValue && options && options.length > 0) {
            let selected: any = options &&
                value && options.find((o: any) => o?.value?.toLowerCase() == value.toLowerCase())

            if (!selected) {
                selected = options &&
                    value && options.find((o: any) => o?.value == value)
            }

            // console.log({ selected, options });
            if (!selected) {
                onchange(undefined)
            }
            else {
                setselectedObect(selected)
            }
        }
        return () => { }
    }, [value, ogValue, options])



    return (
        <>
            {selectedObect && selectedObect ? (
                <>
                    <div className="flex w-full h-full">
                        {selectedObect.icons && selectedObect.icons && (
                            <span className="mx-2">
                                <SVGC
                                    path={toAbsoluteUrl("/" + selectedObect.icons) || ""}
                                    className="w-[20px] h-[20px]"
                                />
                            </span>
                        )}

                        <span>{selectedObect.label}</span>
                    </div>
                </>
            ) : <></>}
        </>
    )
}

export default SelectValue