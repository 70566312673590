import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { getAuth } from "src/Modules/Auth/Core";
import { IQuotaDetailsView, QuotaDetailsViewInit, QuotaSetupForm_INIT, positionsData } from "src/Modules/QuotaSetup/module";
import { createQuota, getAllQuotas, getQuotaDetails } from "src/services/services";
import { RootState } from "src/store";
import { ICreateQuotaPostData, IQuotasList } from "src/types";



// Define a type for the slice state
interface IQuotaReducer {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  activeTab: number,
  quotaType: "Active" | "Expired"
  status: number | undefined;
  quotas: IQuotasList[];
  QuotaDetail: IQuotaDetailsView;
}

const defaultValues = QuotaSetupForm_INIT
const defaultQuotDetails = QuotaDetailsViewInit

// Define the initial state using that type
const initialState: IQuotaReducer = {
  error: false,
  message: undefined,
  isLoading: false,
  activeTab: 1,
  quotaType: "Active",
  status: undefined,
  quotas: [],
  QuotaDetail: defaultQuotDetails,
};

const transformData = (data: any) => {
  return {
    approvalNumber: data.approvalNumber,
    expatriateType: data.expatriateType,
    expirationDate: data.expirationDate,
    totalSlotApproved: data.totalSlotApproved,
    approvedDate: data.approvedDate,
    approvalLetterDate: data.approvalLetterDate,
    expatriate_doc: data.expatriate_doc,
    additional_name: data.additional_name,
    additional_doc: data.additional_doc,
    position: data.position,
    availableQuota: data.availableQuota,
    companyName: data.comanyname,
    RCNumber: data.rcnumber,
    _id: data._id
  }

}

// Create an async thunk for making the API call
export const fetchActiveQuotasAsync: any = createAsyncThunk(
  "QuotaList/fetch",
  async (rc_number: string, thunkApi: any) => {
    try {
      const { quotaType } = thunkApi.getState().quotaList;
      const response: any = await getAllQuotas(rc_number, quotaType);
      if (response?.status && response?.status == 200) {
        // console.log({ createEmpResponse: response });
        thunkApi.dispatch(setQuotas(response.data));
        //return response;
      }
      else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      else {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const fetchQuotaDetailsByIdAsync: any = createAsyncThunk(
  'QuotaDetails/fetch',
  async (id: any, thunkApi: any) => {
    try {
      let response: any = await getQuotaDetails(id);
      if (response && response.status == 200) {
        const data = transformData(response.data)
        thunkApi.dispatch(setQuotaDetails(data));
        return response;
      }
      else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      else {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const QuotaListSlicer = createSlice({
  name: "QuotaList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload?.message;
      state.status = action.payload?.status;
    },
    resetError: (state) => {
      state.error = false;
      state.status = 0;
      state.message = undefined;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
      state.status = undefined;
      state.quotas = [];
      state.activeTab = 1
    },
    setActiveTab: (state, action: PayloadAction<any>) => {
      state.activeTab = action.payload;
    },
    setQuotas: (state, action: PayloadAction<any>) => {
      state.quotas = action.payload;
    },
    setQuotaDetails: (state, action: PayloadAction<any>) => {
      state.QuotaDetail = action.payload;
    },
    setQuotaType: (state, action: PayloadAction<any>) => {
      state.quotaType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchActiveQuotasAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        fetchActiveQuotasAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        fetchActiveQuotasAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        fetchQuotaDetailsByIdAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.QuotaDetail = defaultQuotDetails;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        fetchQuotaDetailsByIdAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.QuotaDetail = transformData(action.payload?.data);
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        fetchQuotaDetailsByIdAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.QuotaDetail = defaultQuotDetails;
          state.status = action.payload?.status || undefined;
        }
      );
  },
});

export const {
  setError,
  resetState,
  resetError,
  setActiveTab,
  setQuotas,
  setQuotaDetails,
  setQuotaType
} = QuotaListSlicer.actions;

// // Other code such as selectors can use the imported `RootState` type
export const getQuotaReducerState = (state: RootState) => state.quotaList

export default QuotaListSlicer.reducer;
//  export default []
