import { useFormik } from "formik";
import { Loader2, PlusIcon } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";
import {
  fetchCountriesAsync
} from "src/Silcers/CountryStateCityPIckerSlicer";
import { setIfDepended, setNewEmployee } from "src/Silcers/createEmployeeSlicer";
import LightBtn from "src/components/Common/LightBtn";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import LoadingDialog from "src/components/LoadingDialog";
import FormikCityPicker from "src/components/ui/CityPIcker/FormikCityPicker";
import FormikCountrycode from "src/components/ui/CountrySelect/FormikCountrycode";
import { Label } from "src/components/ui/Label";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
import FormikStatePicker from "src/components/ui/StatePicker/FormikStatePicker";
import { Button } from "src/components/ui/button";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radiogroup";
import { cn } from "src/lib/utils";
import { RootState } from "src/store";
import {
  Icreate_employee,
  Icreate_employee_schema_yup,
  countriesNotAllowed,
  createEmployeeFields
} from "../../modules";
import { getDDQuotaPositionsList, validateEmployeeCERPACApi, validateEmployeeEmailApi, validateEmployeePassportApi } from "src/services/services";
import { IApiResponse } from "src/types";
import { handleApiCall, optionsType } from "src/Helpers";
import { format } from "date-fns";
import ErrorDialog from "src/components/ErrorDialog";
import axios, { AxiosError } from "axios";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip";

function EmpAddNew() {
  const { auth } = useAuth();

  const {
    createEmployee: { newEmployeeForm },
    CountryStateCityPIcker: { countryList },
    login: { user },
  } = useSelector((state: RootState) => state);

  const e_profile_img_input_ref = useRef<any>(null);
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<string>("");
  const [quotaPositionsList, setquotaPositionsList] = useState<optionsType[]>([])
  const [quotaPositionsExpiryList, setquotaPositionsExpiryList] = useState<optionsType[]>([])
  const [showQuotaEmptyError, setshowQuotaEmptyError] = useState<boolean>(false)

  const [apiRequestState, setApiRequestState] = useState<{
    isLoading: boolean,
    isError: boolean | undefined,
    message: string | undefined,
  }>({
    isLoading: false,
    isError: false,
    message: ""
  })

  useEffect(() => {
    dispath(setIfDepended(false));
    dispath(fetchCountriesAsync());
    formik.setFieldValue("name_of_company", user?.comapnyName);
    // formik.setFieldValue("is_dependants", false);
    fetchQuotaList()
    return () => { };
  }, []);

  useEffect(() => {
    const file = newEmployeeForm?.employee_profile_photo;
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
    }

    return () => { };
  }, [newEmployeeForm?.employee_profile_photo]);

  const formik = useFormik<Partial<Icreate_employee>>({
    initialValues: newEmployeeForm, //newEmployeeForm || CREATE_EMPLOYEE_INIT,
    validationSchema: Icreate_employee_schema_yup,
    onSubmit: (value: Partial<Icreate_employee>) => {
      submitHandler(value);
    },
  });

  const fetchQuotaList = async () => {
    try {
      setApiRequestState({ isLoading: true, message: "Fetching Quota Details", isError: false })
      const apiResp: IApiResponse = await getDDQuotaPositionsList(user.rcNumber);
      setApiRequestState({ isLoading: false, message: apiResp?.message || "", isError: false })
      // console.log({ apiResp })
      if (apiResp.status == 200) {
        if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length > 0) {
          setquotaPositionsList(apiResp.data.map((d: any) => {
            return {
              label: d.position,
              value: d._id
            }
          }))

          setquotaPositionsExpiryList(apiResp.data.map((d: any) => {
            return {
              label: d.expirationDate,
              value: d._id
            }
          }))
        }
        else if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length == 0) {
          setshowQuotaEmptyError(true)
        }
      } else if (apiResp?.data && Array.isArray(apiResp?.data) && apiResp?.data.length == 0) {
        setshowQuotaEmptyError(true)
      }
      else {
        setquotaPositionsList([])
      }
    } catch (e) {
      const error = e as Error | AxiosError
      if (axios.isAxiosError(error)) {
        // console.log(error.response?.data)
        setApiRequestState({ isLoading: false, message: error.response?.data?.message || "", isError: true })
      }
      else {
        setApiRequestState({ isLoading: false, message: error.message || "", isError: true })
      }
    }
  }



  const submitHandler = async (value: any) => {
    let data: any = value;
    dispath(setNewEmployee(data));
    navigate("/employee-review");
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log({ files: event.target.files });

    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("employee_profile_photo", file);
    }
    else {
      setProfileImage("");
      formik.setFieldValue("employee_profile_photo", "");
    }
    // form.setValue("employee_profile_photo", file);

  };


  const handleValidateEmployeeEmail = async (email: string) => {

    setApiRequestState({
      isLoading: true,
      isError: false,
      message: "Validating Email"
    })

    const apiFunc = () => validateEmployeeEmailApi(email)
    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);

    if (status === 200) {
      formik.setFieldError("employee_email", "Email already exists")
    }

    setApiRequestState({
      isLoading: false,
      message: message,
      isError: isError,
    })

  }

  const handleValidateEmployeeCerpac = async (cerpac_no: string) => {

    setApiRequestState({
      isLoading: true,
      isError: false,
      message: "Validating CERPAC No."
    })

    const apiFunc = () => validateEmployeeCERPACApi(cerpac_no)
    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);

    if (status === 200) {
      formik.setFieldError("cerpac_no", "CERPAC No. already exists")
    }

    setApiRequestState({
      isLoading: false,
      message: message,
      isError: isError,
    })

  }


  const handleValidateEmployeePassportNo = async (passport_no: string) => {

    setApiRequestState({
      isLoading: true,
      isError: false,
      message: "Validating passport No."
    })

    const apiFunc = () => validateEmployeePassportApi(passport_no)
    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);

    if (status === 200) {
      formik.setFieldError("passport_no", "Passport No. already exists")
    }

    setApiRequestState({
      isLoading: false,
      message: message,
      isError: isError,
    })

  }


  return (
    <div className="p-4 mx-auto w-4/4 border rounded">
      <form onSubmit={formik.handleSubmit}>
        {/* <TopHeadlogo
                        mainContent={'Create Employee/Expatriate'}
                        subContent={''}
                    /> */}
        <PageHeadingComponent
          mainContent={"Create Employee/Expatriate"}
          subContent={""}
        />
        <div className="card bg-base-100 shadow-sm rounded-md">
          <div className="grid grid-cols-4 gap-4">
            <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
              {/* Surename And GIVEN NAME */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_no}
                    required
                    onBlur={(e: any) => {
                      formik.setFieldTouched("passport_no")
                      if (formik.values.passport_no) {
                        handleValidateEmployeePassportNo(formik.values.passport_no as string)
                      }
                    }}
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_no", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_expiry}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_expiry", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_doc}
                    required
                    value={formik.values.passport_doc}
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_doc", e)
                    }
                  />
                </div>
              </div>
              {/* Surename And GIVEN NAME */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.surname}
                    required
                    onchange={(e: any) => formik.setFieldValue("surname", e)}
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.given_name}
                    required
                    onchange={(e: any) => formik.setFieldValue("given_name", e)}
                  />
                </div>
              </div>
              {/* M EREG NO Type of Emp*/}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.mereg_number}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("mereg_number", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.type_of_employee}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("type_of_employee", e)
                    }
                  />
                </div>
              </div>
              {/* Quota position and quota expiry  */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <div className="flex space-x-2">
                    <div className="flex-1">
                      <RenderFormikFiled
                        formik={formik}
                        disabled={quotaPositionsList.length == 0}
                        options={quotaPositionsList}
                        formObject={createEmployeeFields.quota_position_id}
                        required
                        onchange={(e: any) => {
                          formik.setFieldValue("quota_position_id", e)
                          formik.setFieldValue("quota_position", quotaPositionsList.find((d: optionsType) => d.value === e)?.label)
                          const date: any = quotaPositionsExpiryList.find((d: optionsType) => d.value === e)?.label
                          console.log({ date });
                          formik.setFieldValue("quota_expiry", date)
                        }}
                      />
                    </div>
                    <div>
                      <TooltipProvider>
                        <Tooltip >
                          <TooltipTrigger>
                            <div className="mt-7 h-10 px-4 py-2 rounded bg-primary text-white" onClick={()=> navigate("/quota/create")} >
                              <PlusIcon />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            Add Quota
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: "11px" }}>Note : Check the Quota expiry date before proceeding</span>
                  </div>
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    disabled={quotaPositionsExpiryList.length == 0}
                    formObject={createEmployeeFields.quota_expiry}
                    required
                    options={quotaPositionsExpiryList}
                    onchange={(e: any) =>
                      formik.setFieldValue("quota_expiry", e)
                    }
                  />
                </div>
              </div>
              {/* type of Employee and designation Country of origin  and  */}
              <div className="grid grid-cols-2 gap-x-2">

                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.country_of_origin}
                    required
                    options={countryList?.filter((d: optionsType) => !countriesNotAllowed.includes(d.value)) || []}
                    onchange={(e: any) =>
                      formik.setFieldValue("country_of_origin", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.name_of_company}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("name_of_company", e)
                    }
                  />
                </div>
              </div>
              {/* Nationality  */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.nationality}
                    required
                    options={countryList || []}
                    onchange={(e: any) =>
                      formik.setFieldValue("nationality", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.dob}
                    required
                    onchange={(e: any) => formik.setFieldValue("dob", e)}
                  />
                </div>
              </div>
            </div>
            <div className="col col-span-1 p-4 flex justify-center items-center">
              <div className="flex flex-col">
                <div
                  onClick={() => e_profile_img_input_ref.current.click()}
                  className={cn(
                    "relative w-40 h-40 rounded-full overflow-hidden bg-secondary",
                    {
                      "border border-destructive bg-red-50 ":
                        // form.getFieldState("employee_profile_photo").invalid,
                        formik.errors.employee_profile_photo,
                    }
                  )}
                >
                  <label
                    htmlFor="profile-upload"
                    className="absolute inset-0 flex items-center justify-center cursor-pointer"
                  >
                    <input
                      type="file"
                      ref={e_profile_img_input_ref}
                      key={"e-profile-image-input"}
                      accept={"image/jpeg,image/png"}
                      className="hidden"
                      onChange={(e: any) => {
                        // console.log(e.target.files);

                        handleImageUpload(e)
                      }}

                    />
                    <div className="text-center">
                      {profileImage && (
                        <img
                          src={profileImage}
                          key={"e-profile-image"}
                          alt=""
                          className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                            ? "opacity-50"
                            : ""
                            }`}
                        />
                      )}
                      {profileImage === "" && (
                        <p className="mt-2 sub-styles">
                          Click to upload your Photo
                        </p>
                      )}
                    </div>
                  </label>
                </div>
                <div className={"text-xs text-destructive w-40 my-2"}>
                  {
                    formik.touched.employee_profile_photo &&
                    formik.errors.employee_profile_photo && (
                      <p className={cn("text-xs font-medium text-destructive")}>
                        {formik.errors.employee_profile_photo}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-1 gap-4 p-4">
            {/* Sex and Marital status */}
            <div className="grid grid-cols-2 gap-x-3">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.sex}
                  required
                  onchange={(e: any) => formik.setFieldValue("sex", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.marital_status}
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("marital_status", e)
                  }
                />
              </div>
            </div>
            {/* Passport Number and expiry upload documnet */}
            {/* <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.passport_no}
                  required
                  onchange={(e: any) => formik.setFieldValue("passport_no", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.passport_expiry}
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("passport_expiry", e)
                  }
                />
              </div>
            </div> */}
            {/* Passport NO and   Crepac no/Temporary recipt no */}
            {/* Visa type and uvisa no*/}
            <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_type}
                  required
                  onchange={(e: any) => {
                    formik.setFieldValue("visa_type", e)
                    formik.setFieldValue("visa_number", "")
                    formik.setFieldValue("visa_expiry", "")
                  }}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_number}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  onchange={(e: any) => formik.setFieldValue("visa_number", e)}
                />
              </div>
            </div>
            {/* ate of expiry  Name of Company  */}
            <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_expiry}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  onchange={(e: any) => formik.setFieldValue("visa_expiry", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_doc}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  value={formik.values.visa_doc}
                  onchange={(e: any) => {
                    formik.setFieldValue("visa_doc", e)

                  }}
                />
              </div>
            </div>
            {/* CERPAC NO & EXPIRY */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_no}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required={formik.values.visa_type == "Residence Visa"}
                  onBlur={(e: any) => {
                    formik.setFieldTouched("cerpac_no")
                    if (formik.values.cerpac_no) {
                      handleValidateEmployeeCerpac(formik.values.cerpac_no as string)
                    }
                  }}
                  onchange={(e: any) => formik.setFieldValue("cerpac_no", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_expiry}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required={formik.values.visa_type == "Residence Visa"}
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_expiry", e)
                  }
                />
              </div>
            </div>
            {/* upload cerpac front and BAck */}
            <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_front_doc}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required={formik.values.visa_type == "Residence Visa"}
                  value={formik.values.cerpac_front_doc}
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_front_doc", e)
                  }
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_back_doc}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required={formik.values.visa_type == "Residence Visa"}
                  value={formik.values.cerpac_back_doc}
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_back_doc", e)
                  }
                />
              </div>
            </div>

            {/* Work status and */}
            {/* <div className=" grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.work_status}
                    key={createEmployeeFields.work_status.key}
                    required
                  />
                </div>

              </div> */}
            {/* Employee phone no and employee email */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <Label className="required uppercase">
                  Employee Phone Number
                </Label>
                <div className="flex items-start mt-2">
                  <FormikCountrycodePicker
                    formik={formik}
                    nameKey={createEmployeeFields.employee_phone_code.key}
                    placeholder={
                      createEmployeeFields.employee_phone_code.placeholder
                    }
                    onchange={(e: any) =>
                      formik.setFieldValue("employee_phone_code", e)
                    }
                  />
                  <FormikPhonenumber
                    classname="grow"
                    formik={formik}
                    nameKey={createEmployeeFields.employee_phone_number.key}
                    placeholder={
                      createEmployeeFields.employee_phone_number.placeholder
                    }
                    onchange={(e: any) => {
                      formik.setFieldValue("employee_phone_number", e);
                    }}
                  />
                </div>
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.employee_email}
                  required
                  onBlur={(e: any) => {
                    formik.setFieldTouched("employee_email")
                    if (formik.values.employee_email) {
                      handleValidateEmployeeEmail(formik.values.employee_email as string)
                    }
                  }}
                  onchange={(e: any) =>
                    formik.setFieldValue("employee_email", e)
                  }
                />
              </div>
            </div>
            {/* Employee Address 1 / 2 */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createEmployeeFields.employee_address_1}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("employee_address_1", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createEmployeeFields.employee_address_2}
                required={false}
                onchange={(e: any) =>
                  formik.setFieldValue("employee_address_2", e)
                }
              />
            </div>
            {/* Country and state  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.country}
                    key={createEmployeeFields.country.key}
                    required
                  /> */}
                {/* <CountryPickerFormFiled
                  form={form}
                  nameKey={createEmployeeFields.country.key}
                  required
                /> */}
                {/* <FormikCountrycode
                  formik={formik}
                  nameKey={createEmployeeFields.country.key}
                  required
                  onchange={(e: any) => {
                    formik.setFieldValue("country", e);
                  }}
                /> */}
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.country}
                  required
                  options={countryList || []}
                  onchange={(e: any) =>
                    formik.setFieldValue("country", e)
                  }
                />
              </div>
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.state}
                    key={createEmployeeFields.state.key}
                    required
                  /> */}
                {/* <StatepickerFormFiled
                  countryname={form.getValues("country")}
                  form={form}
                  nameKey={createEmployeeFields.state.key}
                  required
                /> */}
                <FormikStatePicker
                  formik={formik}
                  nameKey={createEmployeeFields.state.key}
                  required
                  countryname={formik.values.country}
                  onchange={(e: any) => {
                    formik.setFieldValue("state", e);
                  }}
                />
              </div>
            </div>
            {/* City and Zip code  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.city}
                    key={createEmployeeFields.city.key}
                    required
                  /> */}
                {/* <CityFormFiledPicker
                  form={form}
                  stateName={form.getValues("state")}
                  nameKey={createEmployeeFields.city.key}
                  required
                /> */}
                <FormikCityPicker
                  formik={formik}
                  nameKey={createEmployeeFields.city.key}
                  required
                  stateName={formik.values.state}
                  onchange={(e: any) => {
                    formik.setFieldValue("city", e);
                  }}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.zipcode}
                  required={false}
                  onchange={(e: any) => formik.setFieldValue("zipcode", e)}
                />
              </div>
            </div>
            {/* ADD more dependent Fields */}
            <div className="flex items-center space-x-4">
              {/* <div className="flex justify-between">
                <FormField
                  control={form.control}
                  name={"is_dependants"}
                  render={({ field }) => {
                    // console.log({ fieldvalue: field.value });

                    return (
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <Label className="uppercase">
                            Do you have any dependent ?
                          </Label>
                          <div>
                            <RadioGroup
                              value={field.value ? "yes" : "no"}
                              onValueChange={(e: any) => {
                                if (e == "yes") {
                                  form.setValue("is_dependants", true);
                                  //setShowDependantDialog(true);
                                  //setdependent("yes")
                                } else {
                                  form.setValue("is_dependants", false);
                                  //setShowDependantDialog(false);
                                  //setdependent("no")
                                }
                              }}
                              //className={"mt-2"}
                            >
                              <div className="flex">
                                <div className="flex items-center space-x-2">
                                  <RadioGroupItem value="yes" id="r1" />
                                  <Label htmlFor="r1">YES</Label>
                                </div>
                                <div className="flex items-center space-x-2 mx-8">
                                  <RadioGroupItem value="no" id="r2" />
                                  <Label htmlFor="r2">NO</Label>
                                </div>
                              </div>
                            </RadioGroup>
                          </div>
                        </div>
                        <FormMessage />
                      </div>
                    );
                  }}
                />
              </div> */}
              <div className="flex">
                <Label className="uppercase mr-4">Do you have any dependent ?</Label>
                <RadioGroup
                  value={formik.values.is_dependants ? "yes" : "no"}
                  onValueChange={(e: any) => {
                    if (e == "yes") {
                      // dispatch(setIfDepended(true));
                      dispath(setIfDepended(true));
                      formik.setFieldValue("is_dependants", true);
                    } else {
                      formik.setFieldValue("is_dependants", false);
                      dispath(setIfDepended(false));
                    }
                  }}
                //className={"mt-2"}
                >
                  <div className="flex">
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="yes" id="r1" />
                      <Label htmlFor="r1">YES</Label>
                    </div>
                    <div className="flex items-center space-x-2 mx-8">
                      <RadioGroupItem value="no" id="r2" />
                      <Label htmlFor="r2">NO</Label>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <LightBtn
              onclick={(e: any) => {
                // e.preventDefault();
                // form.trigger();
                navigate('/employee')
              }}
              title="Cancel"
            />
            <Button
              disabled={apiRequestState.isLoading}
              variant={apiRequestState.isLoading ? "secondary" : "default"}
              className="mb-2 px-10 w-44 mx-5"
            >
              {apiRequestState.isLoading && apiRequestState.isLoading && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </Button>
          </div>
        </div>
      </form>

      <LoadingDialog isOpen={apiRequestState.isLoading} message={apiRequestState.message} />

      <ErrorDialog isOpen={apiRequestState.isError || false} message={apiRequestState.message} onCancelClick={() => setApiRequestState({
        isError: false,
        message: "",
        isLoading: false
      })} />

      <ErrorDialog isOpen={showQuotaEmptyError} message="Quota not available please add new quota" onCancelClick={() => {
        setshowQuotaEmptyError(false)
        navigate('/employee')
      }} />
    </div>
  );
}

export default EmpAddNew;
