import React, { useEffect, useMemo, useState } from "react";
import { SVGC } from "src/Helpers/SVGC";
import { toAbsoluteUrl } from "src/Helpers/AssetHelpers";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Button } from "src/components/ui/button";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import EmployeeList from "./EmployeeList";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "src/components/Common/NavBar";
import {
  IcreatePaymentPostData,
  createEmployeePaymentAsync,
} from "src/Silcers/paymentSlicer";
import { Task } from "./EmployeeTable/data/schema";
import { useDispatch } from "react-redux";
import { cn } from "src/lib/utils";
import { formatAmt } from "src/Helpers";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radiogroup";
import { Label } from "src/components/ui/Label";
import RemitaPaymentComponent from "./RemitaPayment";
import RemitaComponent from "./RemitaIntegration";

type Props = {};

const EmplyoeeConvert = (props: Props) => {
  const [payMethod, setPayMethod] = useState<string>("credit");
  const navigate = useNavigate();
  const {
    employees: { selectedEmployee, selectedEmployeesWithPayment },
    login: { user },
  }: any = useSelector((state: RootState) => state);

  useEffect(() => {
    if (
      !selectedEmployee ||
      (selectedEmployee && selectedEmployee?.length == 0)
    ) {
      navigate("/employee");
    }
    return () => { };
  }, [selectedEmployee]);

  const dispatch = useDispatch();

  const totalNiera = useMemo(
    () =>
      selectedEmployeesWithPayment && selectedEmployeesWithPayment.length > 0
        ? selectedEmployeesWithPayment?.reduce(function (
          acc: number,
          obj: any
        ) {
          return acc + parseFloat(obj.amount_niara);
        },
          0)
        : 0,
    [selectedEmployeesWithPayment]
  );

  const totalDollers = useMemo(
    () =>
      selectedEmployeesWithPayment && selectedEmployeesWithPayment?.length > 0
        ? selectedEmployeesWithPayment?.reduce(function (
          acc: number,
          obj: any
        ) {
          return acc + parseFloat(obj.amount_dollor);
        },
          0)
        : 0,
    [selectedEmployeesWithPayment]
  );

  const groupingDollersAmt = useMemo(() => {
    const amountCountMap = new Map();

    selectedEmployee &&
      selectedEmployeesWithPayment?.forEach((item: any) => {
        const dollars = item.amount_dollor;
        if (amountCountMap.has(dollars)) {
          amountCountMap.set(dollars, amountCountMap.get(dollars) + 1);
        } else {
          amountCountMap.set(dollars, 1);
        }
      });

    return amountCountMap;
  }, [selectedEmployeesWithPayment, selectedEmployee]);

  const postData: IcreatePaymentPostData = useMemo(() => {
    return {
      registration_Object_id: user._id,
      employees:
        selectedEmployeesWithPayment &&
        selectedEmployeesWithPayment?.map((e: Task) => ({
          _id: e.employee_id,
          amount: e.amount_niara,
          email: e.empemail,
          name: e.surname + " " + e.givenname,
        })),
      total_amount: totalDollers,
      employeecount: String(selectedEmployeesWithPayment?.length),
    };
  }, [selectedEmployeesWithPayment, user, totalDollers]);

  // console.log({ totalDollers  });


  return (
    <div className="p-7">
      {/* <NavBar/> */}
      <div className=" grid grid-cols-8 gap-x-6">
        <div className="col-span-6 h-full border border-gray-600 rounded-lg p-7">
          <div className=" mb-4">
            <PageHeadingComponent
              mainContent="PAYMENT CONVERSION"
              subContent={""}
            />
          </div>
          <EmployeeList />
        </div>
        <div className="col-span-2">
          <div className=" bg-green-50 p-6 border border-primary rounded-xl ">
            <h6 className=" sub-styles">Total Selected Employee</h6>
            <h6 className=" text-3xl font-semibold text-primary my-4">
              {selectedEmployee.length}
            </h6>
            <div className=" my-2">
              {/* 
              <h6 className=" my-3 text-gray-500 font-medium">
                $ 20,000.00 X 1 = $ 20,000.00
              </h6> */}
              {groupingDollersAmt &&
                Array.from(groupingDollersAmt.entries()).map(
                  ([dollars, count]) => {
                    //console.log({dollars,count});

                    return (
                      <h6 className=" my-3 text-gray-500 font-medium">
                        $ {dollars && formatAmt(dollars)} X {count} = $ {formatAmt(dollars * count)}
                      </h6>
                    );
                  }
                )}
              <h6 className=" text-gray-500 font-semibold text-lg mt-3">
                Total Sum = $ {formatAmt(totalDollers)}
              </h6>
              <h6 className=" text-base mt-3">
                {" "}
                Converted ($) USD to (₦) Naira real-time
              </h6>
            </div>
            <span className=" inline-block uppercase text-primary text-2xl font-medium my-3">
              ₦ {totalNiera && formatAmt(totalNiera)}
            </span>
            <div className=" flex flex-col space-y-2">
              <RemitaComponent amt={totalNiera} selectedEmployeesWithPayment={selectedEmployeesWithPayment} user={user} totalDollers={totalDollers} />
              
            </div>
            <div className="">
              {/* <div className="flex items-center justify-center">
                <Button
                  onClick={() => {
                    dispatch(
                      createEmployeePaymentAsync({
                        ...postData,
                        paymentmode: payMethod,
                        bankname: "Nirvana",
                      })
                    );
                    navigate("/employee-payment");
                  }}
                  className="inline-block px-16"
                >
                  Continue
                </Button>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmplyoeeConvert;
