import { DESIGNATION_OPTIONS, DESIGNATION_OPTIONS_2, nationality_representative_OPTIONS, NATURE_OF_BUSSINESS, NATURE_OF_BUSSINESS_OPTIONS_2, optionsType, SECTORS_OPTIONs, SECTORS_OPTIONS_2, STATE_OF_COMPANY_OPTIONS, TYPE_OF_COMPANY_OPTIONS, type_of_designation_representative_OPTIONS, type_of_representative_OPTIONS } from "src/Helpers";
import * as z from "zod";
import * as yup from 'yup';
import { ExpatriateQuotaDetail } from "src/types";

export type ICompanyProfile = {
    type_of_company: string | undefined,
    rc_number: string | undefined,
    tin_number: string | undefined,
    company_name: string | undefined,
    sector: string | undefined,
    state_of_company: string | undefined,
    nature_of_business: string | undefined,
    business_phone_country_code: string | undefined,
    business_phone_number: string | undefined,
    business_email: string | undefined,
    country: string | undefined,
    state: string | undefined,
    city: string | undefined,
    zipcode: string | undefined,
    company_address1: string | undefined,
    company_address2: string | undefined,
    type_of_representative: string | undefined,
    designation_of_representative: string | undefined,
    name_of_representative: string | undefined,
    national_identification_number: string | undefined,
    nationality_of_representative: string | undefined,
    bank_verification_number: number | undefined;
    passport_number: string | undefined,
    tcc_number: string | undefined,
    passport_expiry: string | undefined,
    cerpac_number: string | undefined,
    cerpac_expiry: string | undefined,
    // quota_position: number | undefined,
    // quota_utilized: number | undefined,
    // quota_remaining: number | undefined,
    cac?: File | string | undefined;
    cacdocument?: string;
    tcc?: File | string | undefined;
    tccdocument?: string;
    itf?: File | string | undefined;
    itfdocument?: string;
    pancom?: File | string | undefined;
    pancomdocument?: string;
    // expatriate_quota_position?: File | string | undefined;
    additional_document?: File | string | undefined;
    quota_details: ExpatriateQuotaDetail[],
    _id?: string

}


export const INIT_COMPANY_PROFILE_FORM = {
    type_of_company: '',
    rc_number: '',
    tin_number: '',
    tcc_number: '',
    company_name: '',
    sector: '',
    state_of_company: "",
    nature_of_business: undefined,
    business_phone_country_code: "234",
    business_phone_number: '',
    business_email: '',
    country: 'Nigeria',
    state: '',
    city: '',
    zipcode: '',
    company_address1: '',
    company_address2: '',
    type_of_representative: '',
    designation_of_representative: '',
    name_of_representative: '',
    national_identification_number: '',
    nationality_of_representative: '',
    bank_verification_number: 0,
    passport_number: '',
    passport_expiry: '',
    cerpac_number: '',
    cerpac_expiry: '',
    // quota_position: 0,
    // quota_utilized: 0,
    // quota_remaining: 0,
    cac: undefined,
    tcc: undefined,
    itf: undefined,
    pancom: undefined,
    // expatriate_quota_position: undefined,
    additional_document: undefined,
    quota_details: []

}

export const INIT_DATA: ICompanyProfile = {
    type_of_company: "Private limited company",
    rc_number: "123456789",
    tcc_number: "123456789",
    tin_number: "12345678901234567890",
    company_name: "ABC Corporation",
    sector: "Other",
    state_of_company: "State Government",
    nature_of_business: 'option1',
    business_phone_country_code: "234",
    business_phone_number: "5551234567",
    business_email: "info@abccorp.com",
    country: "United States",
    state: "California",
    city: "San Francisco",
    zipcode: "421501",
    company_address1: "123 Main Street",
    company_address2: "Suite 100",
    type_of_representative: "Nigerian",
    designation_of_representative: "Director",
    name_of_representative: "John Doe",
    national_identification_number: "12345678901234567890",
    nationality_of_representative: "American",
    bank_verification_number: 1234567890123456,
    passport_number: "1234567A",
    passport_expiry: "2023-12-31",
    cerpac_number: "CP987654",
    cerpac_expiry: "2023-10-15",
    // quota_position: 100,
    // quota_utilized: 25,
    // quota_remaining: 75,
    cac: undefined,
    tcc: undefined,
    itf: undefined,
    pancom: undefined,
    // expatriate_quota_position: undefined,
    additional_document: undefined,
    quota_details: []
};


const temp_options: optionsType[] = [
    {
        label: 'test-1',
        value: 'test1'
    },
    {
        label: 'test-2',
        value: 'test2'
    }
]

const customErrorMessages = {
    type_of_company: "Please select a company type",
    rc_number: "Please enter a valid RC number",
    tin_number: "Please enter valid TIN number.",
    tcc_number: "Please enter valid TCC number.",
    company_name: "Please enter company name",
    sector: "Please select a sector",
    nature_of_business: "Nature of business must be a string.",
    state_of_company: "This field is rwequired",
    business_phone_country_code: "Business phone country code must be a number.",
    business_phone_number: "Business phone number is required.",
    business_email: "Please enter a valid email.",
    country: "Please select a country",
    state: "Please select a state",
    city: "Please select a city",
    zipcode: "Please enter a valid zipcode",
    company_address1: "Company address1 must be a string.",
    company_address2: "Company address2 must be a string.",
    type_of_representative: "Type of representative must be a string.",
    designation_of_representative: "Designation of representative must be a string.",
    name_of_representative: "Name of representative must be a string.",
    national_identification_number: "National identification number must be a digit.",
    nationality_of_representative: "Nationality of representative must be a string.",
    bank_verification_number: "Bank verification number must be a number.",
    passport_number: "Passport number must be a string.",
    passport_expiry: "Passport expiry must be a string in the format 'yyyy-mm-dd'.",
    cerpac_number: "CERPAC number must be a string.",
    cerpac_expiry: "CERPAC expiry must be a string in the format 'yyyy-mm-dd'.",
    quota_position: "Quota position must be a number.",
    quota_utilized: "Quota utilized must be a number.",
    quota_remaining: "Quota remaining must be a number.",
    cac: "CAC must be a file.",
    tcc: "TCC must be a file.",
    expatriate_quota_position: "Expatriate quota position must be a file.",
    additional_document: "Document must be a file.",
    special_characters_error: "Special Characteres are not allowed"
};


interface filedstype {
    key: string,
    field_mode: string,
    placeholder: string,
    maxLength: number,
    regex: string,
    label: string,
    caseSensetiveLabel?: boolean,
    options?: Array<any> | undefined
}

export const keys_and_labels = {
    type_of_company: {
        key: 'type_of_company',
        label: 'Type Of Company',
        field_type: 'options',
        options: TYPE_OF_COMPANY_OPTIONS,
        placeholder: 'Type Of Company',
        readonly: true
    },
    rc_number: {
        key: "rc_number",
        label: "RC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "RC Number",
        maxLength: 13,
        regex: /[^0-9]+/g,
        prefix: "RC-",
        readonly: true
    },
    tin_number: {
        key: 'tin_number',
        label: 'Tin Number',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Tin Number',
        blockletters: true,
        maxLength: 20,
        regex: /[^0-9-]+/g,
    },
    tcc_number: {
        key: 'tcc_number',
        label: 'TCC Number',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'TCC Number',
        blockletters: true,
        maxLength: 15,
        regex: /[^0-9-]+/g
    },
    company_name: {
        key: 'company_name',
        label: 'Name of Company',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Company Name',
        maxLength: 50,
        regex: /[^A-Za-z 0-9&./-]+/g,
        readonly: true
    },
    sector: {
        key: 'sector',
        label: 'Sector',
        field_type: 'options_with_search',
        options: SECTORS_OPTIONS_2,
        placeholder: 'Sector',
        optionsWidth: 400,
    },
    state_of_company: {
        key: "state_of_company",
        label: "State of company",
        field_type: "options",
        options: STATE_OF_COMPANY_OPTIONS,
        placeholder: "State of company",
        readonly: true
    },
    nature_of_business: {
        key: 'nature_of_business',
        label: 'Nature Of Business',
        field_type: 'options_with_search',
        options: NATURE_OF_BUSSINESS_OPTIONS_2,
        placeholder: 'Nature Of Business',
        optionsWidth: 400,
    },
    business_phone_country_code: {
        key: 'business_phone_country_code',
        label: 'Business Phone Country Code',
        field_type: 'custom',
        placeholder: 'Country Code',
        readonly: true
    },
    business_phone_number: {
        key: 'business_phone_number',
        label: 'Business Phone Number',
        field_type: 'custom',
        placeholder: 'Business Phone Number',
        maxLength: 10,
        regex: /[^0-9]+/g,
        readonly: true

    },
    business_email: {
        key: 'business_email',
        label: 'Business Email',
        field_type: 'text',
        field_mode: 'email',
        placeholder: 'Business Email',
        readonly: true

    },
    country: {
        key: 'country',
        label: 'Country',
        field_type: 'options',
        options: [],
        placeholder: 'Country'
    },
    state: {
        key: 'state',
        label: 'State',
        field_type: 'options',
        options: [],
        placeholder: 'State'
    },
    city: {
        key: 'city',
        label: 'City',
        field_type: 'options',
        options: [],
        placeholder: 'City'
    },
    zipcode: {
        key: 'zipcode',
        label: 'Zip code',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Zip code',
        maxLength: 6,
        regex: /[^0-9]+/g
    },
    company_address1: {
        key: 'company_address1',
        label: 'Address Line 1',
        field_type: 'text',
        field_mode: 'text',
        maxLength: 250,
        placeholder: 'House NO / Area',
        regex: /[^A-Za-z0-9/ ]+/g,
        readonly: true
    },
    company_address2: {
        key: 'company_address2',
        label: 'Address Line 2',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Street / Landmark',
        maxLength: 250,
        regex: /[^A-Za-z0-9/ ]+/g,
        readonly: true
    },
    type_of_representative: {
        key: 'type_of_representative',
        label: 'Type Of Representative',
        field_type: 'options',
        options: type_of_representative_OPTIONS,
        placeholder: 'Type Of Representative',
        readonly: true
    },
    designation_of_representative: {
        key: 'designation_of_representative',
        label: 'Designation Of Representative',
        field_type: 'options_with_search',
        options: DESIGNATION_OPTIONS_2,
        placeholder: 'Designation Of Representative',
        optionsWidth: 400,
        readonly: true
    },
    name_of_representative: {
        key: 'name_of_representative',
        label: 'Name Of Representative of representative',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Name Of Representative of representative',
        regex: /[^A-Za-z. ]+/g,
        maxLength: 50,
        readonly: true
    },
    national_identification_number: {
        key: 'national_identification_number',
        label: 'National Identification Number of representative',
        field_type: 'text',
        field_mode: 'numeric',
        placeholder: 'National Identification Number of representative',
        maxLength: 11,
        regex: /[^0-9]+/g,
        readonly: true
    },
    nationality_of_representative: {
        key: 'nationality_of_representative',
        label: 'Nationality Of Representative of representative',
        field_type: 'options',
        options: nationality_representative_OPTIONS,
        placeholder: 'Nationality Of Representative of representative',
        readonly: true
    },
    bank_verification_number: {
        key: 'bank_verification_number',
        label: 'BANK VERIFICATION NUMBER (BVN)',
        field_type: 'text',
        field_mode: 'numeric',
        placeholder: 'Bank Verification Number of representative',
        maxLength: 11,
        regex: /[^0-9-]+/g,
        readonly: true
    },
    passport_number: {
        key: 'passport_number',
        label: 'PASSPORT NO. OF REPRESENTATIVE',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'Passport Number of representative',
        maxLength: 12,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,
        readonly: true
    },
    passport_expiry: {
        key: 'passport_expiry',
        label: 'Passport Expiry of representative',
        field_type: 'date',
        placeholder: 'Passport Expiry of representative',
        readonly: true
    },
    cerpac_number: {
        key: 'cerpac_number',
        label: 'CERPAC NO. OF REPRESENTATIVE',
        field_type: 'text',
        field_mode: 'text',
        placeholder: 'CERPAC Number of representative',
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,
        readonly: true
    },
    cerpac_expiry: {
        key: 'cerpac_expiry',
        label: 'CERPAC Expiry of representative',
        field_type: 'date',
        placeholder: 'CERPAC Expiry of representative',
        readonly: true
    },
    // quota_position: {
    //     key: 'quota_position',
    //     label: 'Quota Position',
    //     field_type: 'text',
    //     field_mode: 'number',
    //     placeholder: 'Quota Position',
    //     regex: /[^0-9]+/g,

    // },
    // quota_utilized: {
    //     key: 'quota_utilized',
    //     label: 'Quota Utilized',
    //     field_type: 'text',
    //     field_mode: 'number',
    //     placeholder: 'Quota Utilized',
    //     regex: /[^0-9]+/g
    // },
    // quota_remaining: {
    //     key: 'quota_remaining',
    //     label: 'Quota Un-Utilized',
    //     field_type: 'text',
    //     field_mode: 'number',
    //     placeholder: 'Quota Remaining',
    //     regex: /[^0-9]+/g,
    //     readonly: true
    // },
    cac:
    {
        key: 'cac',
        label: 'UPLOAD C.A.C DOCUMENT: CERTIFICATE OF INCORPORATION',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Corporate Affairs Commission'
    },

    tcc:
    {
        key: 'tcc',
        label: 'UPLOAD T.C.C :  TAX CLEARANCE CERTIFICATE (TCC)',
        field_type: 'file',
        field_mode: '',
        placeholder: 'TAX CLEARANCE CERTIFICATE (TCC)'
    }
    ,
    // expatriate_quota_position:
    // {
    //     key: 'expatriate_quota_position',
    //     label: 'UPLOAD RENEWAL OF EXPATRIATE QUOTA POSITIONS',
    //     field_type: 'file',
    //     field_mode: '',
    //     placeholder: 'RENEWAL OF EXPATRIATE QUOTA POSITIONS'
    // },
    additional_document:
    {
        key: 'additional_document',
        label: 'Additional Document',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Additional Document',

    },
    itf: {
        key: 'itf',
        label: 'Upload I.T.F : Industrial Training Fund',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Upload ITF',

    },
    pancom: {
        key: 'pancom',
        label: 'Pencom Document',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Pencom Document',
    },

}




export const companyProfileSchema_yup = yup.object().shape({
    type_of_company: yup.string().required("Select type of company"),
    rc_number: yup.string().matches(/^([0-9 _-]+)$/, { message: customErrorMessages.special_characters_error }).min(4, "RC Number must be atleast 4 digits.").max(13).required(customErrorMessages.rc_number),
    tin_number: yup.string().matches(/^[0-9]*-[0-9]*$/, "TIN Number must be atleast 13 digits including hyphen").min(13, "TIN Number must be atleast 13 digits including hyphen").max(20).required("TIN Number Required"),
    tcc_number: yup.string().matches(/^([0-9]+)$/, "TCC Number must be digits only").min(12, "TCC Number must be atleast 12 digits").max(15, "TCC Number max 15 digits").optional().test('tcc_number', (value, validationcontext) => {
        const { createError, parent: { tcc } } = validationcontext;
        if (!value && tcc) {
            return createError({ message: "Please enter TCC number" });
        }
        return true;
    }),
    company_name: yup.string().matches(/^([a-zA-Z 0-9&.-]+)$/, { message: customErrorMessages.special_characters_error }).min(3, "Company name must be at least 3 characters").max(50, "max 50 characters").required(customErrorMessages.company_name),
    sector: yup.string().required("Select sector").min(1, customErrorMessages.sector),
    // state_of_company: yup.string().required("Select state of company").min(1, customErrorMessages.state_of_company),
    nature_of_business: yup.string().required("Select nature of bussiness"),
    business_phone_country_code: yup.string().optional().test('business_phone_code', (value, validationcontext) => {
        const { createError, parent: { business_phone_number } } = validationcontext;
        if (!value && business_phone_number) {
            return createError({ message: "Please select country code" });
        }
        return true;
    }),
    business_phone_number: yup.string()
        .matches(/^([0-9]+)$/, 'Invalid phone')
        .length(10)
        .required(customErrorMessages.business_phone_number),
    business_email: yup.string().required("Enter business email").matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, { message: "Invalid Email" }).email().min(1, customErrorMessages.business_email),
    country: yup.string().required("Select Country"),
    state: yup.string().required("Select state "),
    city: yup.string().required("Select city"),
    zipcode: yup.string().optional().length(6, customErrorMessages.zipcode),
    company_address1: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).required("Enter company address").min(3, "Min 3 characters").max(250, "Maximum length is 250 characters"),
    company_address2: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).min(3, "Min 3 characters").max(250, "Maximum length is 250 characters").required("Enter company address"),
    type_of_representative: yup.string().required("Select type of representative"),
    designation_of_representative: yup.string().required("Select designation of representative").min(1, customErrorMessages.designation_of_representative),
    name_of_representative: yup.string().matches(/^([a-zA-Z. ]+)$/, { message: customErrorMessages.special_characters_error }).required(customErrorMessages.name_of_representative),
    national_identification_number: yup.string().length(11, "National Identification Number must be 11 digits.").optional().test('national_identification_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Nigerian') {
            return createError({ message: customErrorMessages.national_identification_number });
        }
        else if (value && type_of_representative === 'Foreigner') {
            return true;
        }
        return true;
    }),
    nationality_of_representative: yup.string().required("Nationality of representative is required"),
    bank_verification_number: yup.string().length(11, "Bank Verification Number must be 11 digits").required(),
    passport_number: yup.string().min(8, "It should at least 8 digits").max(12, "It max 12 digits").optional().test('passport_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: customErrorMessages.passport_number });
        }

        return true;
    }),
    // passport_expiry: yup.string().optional().test('passport_expiry', (value, validationcontext) => {
    //     const { createError, parent: { type_of_representative, passport_number } } = validationcontext;

    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();

    //     if (!value && !passport_number && type_of_representative === 'Foreigner') {
    //         return createError({ message: customErrorMessages.passport_expiry });
    //     }

    //     if (!value && type_of_representative === 'Foreigner' && passport_number) {
    //         return createError({ message: customErrorMessages.passport_expiry });
    //     }

    //     if (value && inputDate && inputDate <= currentDate) {
    //         return createError({ message: "Expiry date should not be less than current date." });
    //     }

    //     return true;
    // }),
    cerpac_number: yup.string().length(8, "It Should be 8 digits").optional().test('cerpac_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: customErrorMessages.cerpac_number });
        }

        return true;
    }),
    // cerpac_expiry: yup.string().optional().test('cerpac_expiry', (value, validationcontext) => {
    //     const { createError, parent: { type_of_representative, cerpac_number, passport_expiry } } = validationcontext;

    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();
    //     const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;

    //     if (!value && !cerpac_number && type_of_representative === 'Foreigner') {
    //         return createError({ message: customErrorMessages.cerpac_expiry });
    //     }

    //     if (!value && cerpac_number) {
    //         return createError({ message: customErrorMessages.cerpac_expiry });
    //     }

    //     if (value && inputDate && inputDate <= currentDate) {
    //         return createError({ message: "Expiry date should not be less than current date." });
    //     }

    //     else if (value && pasportDate && inputDate && inputDate > pasportDate) {
    //         return createError({ message: "Expiry date should not be greater than passport expiry date." });
    //     }

    //     // if (value && inputDate && inputDate <= currentDate) {
    //     //     return createError({ message: "Expiry date should not be less than current date." });
    //     // }

    //     return true;
    // }),
    // quota_position: yup.number().min(1, "Quota position must be at least 1").required("Enter quota position").max(1000, "Quota position must be less than or equal to 1000"),
    // quota_utilized: yup.number().required("Enter quota utilization").max(1000).test('quota_utilized', (value, validationcontext) => {
    //     const { createError, parent: { quota_position } } = validationcontext;
    //     if (!value) {
    //         return createError({ message: "Enter quota utilization" });
    //     }

    //     if (value && Number(value) > Number(quota_position)) {
    //         return createError({ message: "Utilized Quota sholud not be greater than quota position" });
    //     }

    //     return true;
    // }),
    // quota_remaining: yup.number().max(1000),
    cac: yup.mixed().test('cac', (value, validationcontext) => {

        const { createError, parent: { quota_position } } = validationcontext;

        // console.log({ value });

        if (!value) {
            return createError({ message: "CAC Documnet is Mandatory" });
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    tcc: yup.mixed().optional().test('tcc', (value, validationcontext) => {

        const { createError, parent: { tcc_number } } = validationcontext;

        if (!value && tcc_number) {
            return createError({ message: "Select TCC." });
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && tcc_number && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }


        if (value && tcc_number && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    itf: yup.mixed().notRequired().test('itf', (value, validationcontext) => {

        const { createError, parent: { quota_position } } = validationcontext;

        if (!value) {
            // return createError({ message: "Select ITF." });
            return true
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    pancom: yup.mixed().notRequired().test('pancom', (value, validationcontext) => {

        const { createError, parent: { quota_position } } = validationcontext;

        if (!value) {
            // return createError({ message: "Select pencom." });
            return true;
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    additional_document: yup.mixed().optional().test('additional_document', (value, validationcontext) => {

        const { createError, parent: { quota_position } } = validationcontext

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
});


export const companyProfileUpdateSchema_yup = yup.object().shape({
    type_of_company: yup.string().required("Select type of company"),
    rc_number: yup.string().matches(/^([0-9 _-]+)$/, { message: customErrorMessages.special_characters_error }).min(4, "RC Number must be atleast 4 digits.").max(13).required(customErrorMessages.rc_number),
    tin_number: yup.string().matches(/^[0-9]*-[0-9]*$/, "TIN Number must be atleast 13 digits including hyphen").min(13, "TIN Number must be atleast 13 digits including hyphen").max(20).required("TIN Number Required"),
    company_name: yup.string().matches(/^([a-zA-Z 0-9&.-]+)$/, { message: customErrorMessages.special_characters_error }).min(3, "Company name must be at least 3 characters").max(50, "max 50 characters").required(customErrorMessages.company_name),
    sector: yup.string().required("Select sector").min(1, customErrorMessages.sector),
    state_of_company: yup.string().required("Select state of company").min(1, customErrorMessages.state_of_company),
    nature_of_business: yup.string().required("Select nature of bussiness"),
    business_phone_country_code: yup.string().optional().test('business_phone_code', (value, validationcontext) => {
        const { createError, parent: { business_phone_number } } = validationcontext;
        if (!value && business_phone_number) {
            return createError({ message: "Please select country code" });
        }
        return true;
    }),
    business_phone_number: yup.string()
        .matches(/^([0-9]+)$/, 'Invalid phone')
        .length(10)
        .required(customErrorMessages.business_phone_number),
    business_email: yup.string().required("Enter business email").matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, { message: "Invalid Email" }).email().min(1, customErrorMessages.business_email),
    country: yup.string().required("Select Country"),
    state: yup.string().required("Select state "),
    city: yup.string().required("Select city"),
    zipcode: yup.string().optional().length(6, customErrorMessages.zipcode),
    company_address1: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).required("Enter company address").min(3, "Min 3 characters").max(250, "Maximum length is 250 characters"),
    company_address2: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).min(3, "Min 3 characters").max(250, "Maximum length is 250 characters").required("Enter company address"),
    type_of_representative: yup.string().required("Select type of representative"),
    designation_of_representative: yup.string().required("Select designation of representative").min(1, customErrorMessages.designation_of_representative),
    name_of_representative: yup.string().matches(/^([a-zA-Z. ]+)$/, { message: customErrorMessages.special_characters_error }).required(customErrorMessages.name_of_representative),
    national_identification_number: yup.string().length(11, "National Identification Number must be 11 digits.").optional().test('national_identification_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Nigerian') {
            return createError({ message: customErrorMessages.national_identification_number });
        }
        else if (value && type_of_representative === 'Foreigner') {
            return true;
        }
        return true;
    }),
    nationality_of_representative: yup.string().required("Nationality of representative is required"),
    bank_verification_number: yup.string().length(11, "Bank Verification Number must be 11 digits").required(),
    passport_number: yup.string().min(8, "It should at least 8 digits").max(12, "It max 12 digits").optional().test('passport_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: customErrorMessages.passport_number });
        }

        return true;
    }),
    // passport_expiry: yup.string().optional().test('passport_expiry', (value, validationcontext) => {
    //     const { createError, parent: { type_of_representative, passport_number } } = validationcontext;

    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();

    //     if (!value && !passport_number && type_of_representative === 'Foreigner') {
    //         return createError({ message: customErrorMessages.passport_expiry });
    //     }

    //     if (!value && type_of_representative === 'Foreigner' && passport_number) {
    //         return createError({ message: customErrorMessages.passport_expiry });
    //     }

    //     if (value && inputDate && inputDate <= currentDate) {
    //         return createError({ message: "Expiry date should not be less than current date." });
    //     }

    //     return true;
    // }),

    cerpac_number: yup.string().length(8, "It Should be 8 digits").optional().test('cerpac_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: customErrorMessages.cerpac_number });
        }

        return true;
    }),

    // cerpac_expiry: yup.string().optional().test('cerpac_expiry', (value, validationcontext) => {
    //     const { createError, parent: { type_of_representative, cerpac_number, passport_expiry } } = validationcontext;

    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();
    //     const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;

    //     if (!value && !cerpac_number && type_of_representative === 'Foreigner') {
    //         return createError({ message: customErrorMessages.cerpac_expiry });
    //     }

    //     if (!value && cerpac_number) {
    //         return createError({ message: customErrorMessages.cerpac_expiry });
    //     }

    //     if (value && inputDate && inputDate <= currentDate) {
    //         return createError({ message: "Expiry date should not be less than current date." });
    //     }

    //     else if (value && pasportDate && inputDate && inputDate > pasportDate) {
    //         return createError({ message: "Expiry date should not be greater than passport expiry date." });
    //     }

    //     // if (value && inputDate && inputDate <= currentDate) {
    //     //     return createError({ message: "Expiry date should not be less than current date." });
    //     // }

    //     return true;
    // }),
    // quota_position: yup.number().min(1, "Quota position must be at least 1").required("Enter quota position").max(1000, "Quota position must be less than or equal to 1000"),
    // quota_utilized: yup.number().required("Enter quota utilization").max(1000).test('quota_utilized', (value, validationcontext) => {
    //     const { createError, parent: { quota_position } } = validationcontext;
    //     if (!value) {
    //         return createError({ message: "Enter quota utilization" });
    //     }

    //     if (value && Number(value) > Number(quota_position)) {
    //         return createError({ message: "Utilized Quota sholud not be greater than quota position" });
    //     }

    //     return true;
    // }),
    // quota_remaining: yup.number().max(1000),
    
});


export type company_profile = {
    activeTab: number,
    totalTabs: number,
    form_data: ICompanyProfile
}

export const INIT_STATE = {
    activeTab: 1,
    totalTabs: 2,
    form_data: INIT_COMPANY_PROFILE_FORM
}

export type MainContextProps = {
    state: company_profile,
    setActiveTab: (e: number) => void,
    setFormData: (e: ICompanyProfile) => void,
    ResetFormData: () => void
}

export const initialProps: MainContextProps = {
    state: INIT_STATE,
    setActiveTab: () => { },
    setFormData: () => { },
    ResetFormData: () => { }
}

export const ACTION_TYPES = {
    INIT: "INIT",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_RESET_ACTIVE_TAB: "SET_RESET_ACTIVE_TAB",
    SET_FORM_DATA: "SET_FORM_DATA",
    SET_RESET_FORM_DATA: "SET_RESET_FORM_DATA",
}

export const Reducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.INIT:
            return INIT_STATE
            break;
        case ACTION_TYPES.SET_ACTIVE_TAB:
            return { ...state, activeTab: action.payload }
            break;
        case ACTION_TYPES.SET_RESET_ACTIVE_TAB:
            return { ...state, activeTab: 1 }
            break;
        case ACTION_TYPES.SET_FORM_DATA:
            return { ...state, form_data: action.payload }
            break;
        case ACTION_TYPES.SET_RESET_FORM_DATA:
            return { ...state, form_data: INIT_COMPANY_PROFILE_FORM }
            break;
        default:
            break;
    }
}
