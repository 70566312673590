import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { getAuth } from "src/Modules/Auth/Core";
import { IDependant, INIT_Dependant } from "src/Modules/Dependents/modules";
import { createNewDependent, getDependentDetails, updateDependentDetails } from "src/services/services";
import { ICreateNewEmployeePostData } from "src/types";
import { format_DDMMYYYY_To_Date, format_Date_To_DDMMYYYY } from "../Helpers";

const defaultValues = {
  ...INIT_Dependant,
  _id: "",
  employee_id: "",
  accompany_with: "",
};

// Define a type for the slice state
interface IDependentReducer {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  dependents: IDependant & {
    _id: string;
    employee_id: string;
    accompany_with: string;
  };
  status: number | undefined;
}

// Define the initial state using that type
const initialState: IDependentReducer = {
  error: false,
  message: undefined,
  isLoading: false,
  dependents: defaultValues,
  status: undefined,
};

// Create an async thunk for making the API call
export const createDependentAsync: any = createAsyncThunk(
  "dependents/createDependent",
  async (employeeData: ICreateNewEmployeePostData, thunkApi) => {

    // console.log({ dependentData: employeeData });
    try {
      const { user_id, api_token }: any = getAuth();
      let formData: Partial<
        IDependant & {
          dependent_profile_photo_urls: string;
          passport_doc_url: string;
          nationality: string;
          name_of_company: string;
          mereg_number: string;
          _id: string;
        }
      > = employeeData;
      // Make the API call using Axios
      const postData = {
        userid: user_id,
        surname: formData.surname,
        given_name: formData.given_name,
        date_of_birth: formData.dob,
        sex: formData.sex,
        passportnumber: formData.passport_no,
        relation: formData.relationship,
        passportexpiry: format_Date_To_DDMMYYYY(formData.passport_expiry),
        // visatype: formData.visa_type,
        // visanumber: formData.visa_number,
        // visa_issue_date: formData.visa_issue,
        // visa_expiry_date: formData.visa_expiry,
        cerpac_number: formData.cerpac_no,
        cerpac_expiry_date: format_Date_To_DDMMYYYY(formData.cerpac_expiry),
        passport_photo: formData?.passport_doc,
        dependant_profile_photo: formData.dependent_profile_image,
        nationality: formData.nationality,
        comapnyname: formData.name_of_company,
        mereg_no: formData.mereg_number,
        employee_id: formData._id,
        accompanyWith: formData.accompany_with,
        // cerpac_issue_date: formData.cerpac_issue
      };

      // console.log({ user_id, api_token });

      const response: any = await createNewDependent(postData);
      if (response.status == 201) {
        return response;
      } else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
        // console.log("response errorr");
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Create an async thunk for making the API call
export const updateDependentAsync: any = createAsyncThunk(
  "dependents/updateDependent",
  async (employeeData: any, thunkApi) => {
    try {
      const { user_id, api_token }: any = getAuth();
      let formData: Partial<
        IDependant & {
          dependent_profile_photo_urls: string;
          passport_doc_url: string;
          nationality: string;
          name_of_company: string;
          mereg_number: string;
          _id: string;
        }
      > = employeeData;
      // Make the API call using Axios
      const postData = {
        userid: user_id,
        surname: formData.surname,
        given_name: formData.given_name,
        date_of_birth: formData.dob,
        sex: formData.sex,
        passportnumber: formData.passport_no,
        relation: formData.relationship,
        passportexpiry: format_Date_To_DDMMYYYY(formData.passport_expiry),
        // visatype: formData.visa_type,
        // visanumber: formData.visa_number,
        // visa_issue_date: formData.visa_issue,
        // visa_expiry_date: formData.visa_expiry,
        cerpac_number: formData.cerpac_no,
        cerpac_expiry_date: format_Date_To_DDMMYYYY(formData.cerpac_expiry),
        passport_photo: formData?.passport_doc,
        dependant_profile_photo: formData?.dependent_profile_image,
        nationality: formData.nationality,
        comapnyname: formData.name_of_company,
        mereg_no: formData.mereg_number,
        employee_id: formData.employee_id,
        accompanyWith: formData.accompany_with,
        // cerpac_issue_date: formData.cerpac_issue,
        _id: formData._id
      };

      // console.log({ user_id, api_token });

      const response: any = await updateDependentDetails(postData);
      if (response.status == 200) {
        return response;
      } else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
        // console.log("response errorr");
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
// Create an async thunk for making the API call
export const fetchDependentAsync: any = createAsyncThunk(
  "dependents/fecth",
  async (id: string, thunkApi) => {
    try {
      const { user_id, api_token }: any = getAuth();
      // console.log({ user_id, api_token });

      const response: any = await getDependentDetails(id);
      if (response.status == 200) {
        // console.log({ fetchDependentAsyncResponse: response });

        let data = response.data;

        let newData = {
          surname: data["surname"],
          given_name: data["given_name"],
          relationship: data["relation"],
          dob: data["date_of_birth"],
          sex: data["sex"],
          passport_no: data["passportnumber"],
          passport_expiry: format_DDMMYYYY_To_Date(data["passportexpiry"]),
          passport_doc: data["passport_photo"],
          cerpac_no: data["cerpac_number"],
          cerpac_expiry: format_DDMMYYYY_To_Date(data["cerpac_expiry_date"]),
          // visa_type: data["visatype"],
          // visa_number: data["visanumber"],
          // visa_expiry: data["visa_expiry_date"],
          // visa_issue: data["visa_issue_date"],
          dependent_profile_image: data["dependant_profile_photo"],
          _id: data["_id"],
          employee_id: data["employee_id"],
          accompany_with: data["accompanyWith"],
          // cerpac_issue: data["cerpac_issue_date"]
        };

        thunkApi.dispatch(setDependents(newData));
        return newData;
      } else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
        // console.log("response errorr");
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createDependentSlicer = createSlice({
  name: "createDependent",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload;
      state.status = action.payload?.status || undefined;
    },
    setDependents: (state, action: PayloadAction<any>) => {
      state.dependents = action.payload;
    },
    partialReset: (state) => {
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
    },
    resetState: (state) => {
      state.dependents = defaultValues;
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
      state.status = undefined;
    },
    resetStatus: (state) => {
      state.status = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createDependentAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        createDependentAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        createDependentAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        fetchDependentAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
          state.dependents = defaultValues;
        }
      )
      .addCase(
        fetchDependentAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.dependents = action.payload;
        }
      )
      .addCase(
        fetchDependentAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.dependents = defaultValues;
        }
      )
      .addCase(
        updateDependentAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        updateDependentAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        updateDependentAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.status = action.payload?.status || undefined;
        }
      )
  },
});

export const { setError, setDependents, resetState, partialReset, resetStatus } =
  createDependentSlicer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default createDependentSlicer.reducer;
//  export default []
