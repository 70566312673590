import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";

import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { Loader2, ShieldClose, PlusIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";

import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Label } from "src/components/ui/Label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  getDependentProfilePic,
  getProfilePic,
  getUpladedFile,
  uploadfile,
} from "src/services/services";
import { RootState } from "src/store";
import {
  createDependentAsync,
  fetchDependentAsync,
  partialReset,
  resetState,
} from "src/Silcers/createDependentSlicer";

import { resetState as resetCreateEmployeeState } from "src/Silcers/createEmployeeSlicer";
import { SVGC } from "src/Helpers/SVGC";
import { getUploadFileData, toAbsoluteUrl } from "src/Helpers";
import ViewFIleDialog from "src/components/ViewFIleDialog";

function ViewDependent() {
  const {
    createDependent: { error, message, isLoading, status, dependents },
    createEmployee: { newEmployeeForm },
  } = useSelector((state: RootState) => state);
  const [isLoadingIMage, setisLoadingIMage] = useState(false)

  const { id } = useParams();

  // console.log({ id });


  useEffect(() => {
    if (id) {
      dispatch(fetchDependentAsync(id));
      getProfilePicUrl(id)
    }
    return () => { };
  }, [id]);

  // console.log("this is dependent Review", { newEmployeeForm });

  const [isuploadingFiles, setisuploadingFiles] = useState(false);
  const [isError, setisError] = useState(false);
  const [openSuccessModel, setopenSuccessModel] = useState(false);

  const formData: any = dependents;

  const dispatch = useDispatch();

  const { auth } = useAuth();

  const [profileImage, setProfileImage] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (status == 201) {
      setopenSuccessModel(true);
    }
    return () => { };
  }, [status]);


  useEffect(() => {
    const file = dependents?.dependent_profile_image;
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else if (typeof file === "string") {
      // console.log({ dependents });
      //setProfileImage(dependents?.dependent_profile_image);
      // getProfilePicUrl(id as string);
    }
    return () => { };
  }, [dependents?.dependent_profile_image, id]);


  const getProfilePicUrl = async (id: string) => {
    try {
      setisLoadingIMage(true)
      const response: any = await getUploadFileData("dependentdocs", id, "dependant_profile_photo");
      if (response) {
        setProfileImage(response)
      }
      setisLoadingIMage(false)
    } catch (error) {
      setisLoadingIMage(false)
    }
    // console.log({ apiResp });
  };



  return (
    <div className="p-7 mx-auto w-4/4 border rounded">

      <PageHeadingComponent
        mainContent={"DEPENDENT/EXPATRIATE View"}
        subContent={""}
      />

      <div className="card bg-base-100 shadow-sm rounded-md">
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Surname</h4> */}
                <Label className="uppercase">Surname</Label>
                <p className=" text-sm text-gray-500">{formData?.surname}</p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Given Name</h4> */}
                <Label className="uppercase">Given Name</Label>
                <p className=" text-sm text-gray-500">{formData?.given_name}</p>
              </div>
            </div>

            {/* DOB And SEX */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Date of Birth</h4> */}
                <Label className="uppercase">Date of Birth</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.dob &&
                    format(new Date(formData.dob), "dd/MM/yyyy")}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Sex</h4> */}
                <Label className="uppercase">Sex</Label>
                <p className=" text-sm text-gray-500">{formData?.sex}</p>
              </div>
            </div>
            {/* Passport NO and Passport Expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Passport No</h4> */}
                <Label className="uppercase">Passport No</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_no}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Passport Expiry</h4> */}
                <Label className="uppercase">Passport Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {/* {formData?.passport_expiry &&
                    format(new Date(formData.passport_expiry), "dd/MM/yyyy")} */}
                  {formData?.passport_expiry && !formData?.passport_expiry.includes("/") &&
                    format(new Date(formData.passport_expiry), "dd/MM/yyyy")}
                  {formData?.passport_expiry && formData?.passport_expiry.includes("/") &&
                    formData.passport_expiry}
                </p>
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="relative w-40 h-40 rounded-full overflow-hidden bg-secondary">
              {
                <>
                  <label
                    htmlFor="profile-upload"
                    className="absolute inset-0 flex items-center justify-center cursor-pointer"
                  >
                    <div className="text-center">
                      {

                        isLoadingIMage ? <>
                          <div className="flex flex-col items-center">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            <div>Loading..</div>
                          </div>
                        </> :
                          <>
                            <img
                              src={profileImage}
                              alt=""
                              className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                                ? "opacity-50"
                                : ""
                                }`}
                            />
                            {profileImage === "" && (
                              <p className="mt-2 ">Click to upload your Photo</p>
                            )}
                          </>

                      }

                    </div>
                  </label>
                </>
              }
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 gap-4 p-4">
          {/* Marital Status and Sex */}
          <div className="grid grid-cols-3 gap-x-3">
            <div>
              {/* <h4 className=" uppercase">PASSPORT DOCUMENT</h4> */}
              <Label className="uppercase">PASSPORT DOCUMENT</Label>
              <p className=" text-sm  text-gray-500">
                {/* {typeof formData?.passport_doc_url === "string"
                  ? formData?.passport_doc_url
                  : null}
                {typeof formData?.passport_doc === "string"
                  ? formData?.passport_doc
                  : null} */}
                {/* {formData?.passport_doc &&
                  formData?.passport_doc instanceof File
                  ? formData?.passport_doc.name
                  : null} */}
                {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
                {formData && id && formData?.passport_doc && <ViewFIleDialog doc_id={id} fileType={"dependentdocs"} file_key={"passport_photo"} title="Passport Document" file_ext={formData?.passport_doc as string} />}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Sex</h4> */}
              <Label className="uppercase">ACCOMPANY WITH</Label>
              <p className=" text-sm text-gray-500">{formData?.accompany_with}</p>
            </div>

            <div>
              <Label className="uppercase">Relationship</Label>
              <p className=" text-sm text-gray-500">{formData?.relationship}</p>
            </div>
          </div>
          {/* Visa Type & Visa Number & Visa Issue Date */}
          {/* <div className="grid grid-cols-3 gap-x-3">
            <div>
              <Label className="uppercase">Visa type</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_type}</p>
            </div>
            <div>
              <Label className="uppercase">Visa Number</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_number}</p>
            </div>
            <div>
              <Label className="uppercase">Visa Issue Date</Label>
              <p className=" text-sm text-gray-500">
                {formData?.visa_issue && !formData?.visa_issue.includes("/") &&
                  format(new Date(formData.visa_issue), "dd/MM/yyyy")}
                {formData?.visa_issue && formData?.visa_issue.includes("/") &&
                  formData.visa_issue}
              </p>
            </div>
          </div> */}
          {/* visa expiry &  CERPAC Number & CERPAC Isssue date */}
          <div className="grid grid-cols-3 gap-x-3">
            {/* <div>
              <Label className="uppercase">VISA EXPIRY</Label>
              <p className=" text-sm text-gray-500">
                {formData?.visa_expiry && !formData?.visa_expiry.includes("/") &&
                  format(new Date(formData.visa_expiry), "dd/MM/yyyy")}
                {formData?.visa_expiry && formData?.visa_expiry.includes("/") &&
                  formData.visa_expiry}
              </p>
            </div> */}
            <div>
              <Label className="uppercase">Cerpac Number</Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_no}</p>
            </div>
            <div>
              <Label className="uppercase">CERPAC EXPIRY</Label>
              <p className=" text-sm text-gray-500">
                {/* {formData?.cerpac_expiry && format(new Date(formData?.cerpac_expiry), "dd/MM/yyyy")} */}
                {formData?.cerpac_expiry && !formData?.cerpac_expiry.includes("/") &&
                  format(new Date(formData.cerpac_expiry), "dd/MM/yyyy")}
                {formData?.cerpac_expiry && formData?.cerpac_expiry.includes("/") &&
                  formData.cerpac_expiry}
              </p>
            </div>
          </div>
          {/* visa expiry &  CERPAC Number & CERPAC Isssue date */}
          {/* <div className="grid grid-cols-3 gap-x-3">
            <div>
              <Label className="uppercase">CERPAC EXPIRY</Label>
              <p className=" text-sm text-gray-500">
               
                {formData?.cerpac_expiry && !formData?.cerpac_expiry.includes("/") &&
                  format(new Date(formData.cerpac_expiry), "dd/MM/yyyy")}
                {formData?.cerpac_expiry && formData?.cerpac_expiry.includes("/") &&
                  formData.cerpac_expiry}
              </p>
            </div>
          </div> */}
        </div>

        <div className="flex justify-center w-full">
          {/* <Button
            onClick={() => navigate("/employee/dependents/create")}
            variant="outline"
            className="mb-2 px-10 w-44 mx-5"
          >
            Edit
          </Button> */}
          <Button
            disabled={isLoading}
            variant={
              isLoading || isuploadingFiles
                ? "secondary"
                : isError
                  ? "destructive"
                  : "default"
            }
            onClick={() => navigate(-1)}
            className="mb-2 px-10 w-44 mx-5"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </div>

      <Dialog open={error} onOpenChange={() => { }}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-destructive text-lg font-bold">
                    ERROR{" "}
                  </div>
                </div>
                <ShieldClose size={24} className="text-destructive" />
                <div className="my-2">{message}</div>
                <Button
                  variant="secondary"
                  className="focus:outline-none"
                  onClick={() => dispatch(partialReset())}
                >
                  Cancel
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccessModel} onOpenChange={() => console.log()}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-destructive text-lg font-bold"></div>
                </div>
                <div className="border border-primary rounded-full">
                  <SVGC
                    path={toAbsoluteUrl("/media/icons/svg/tick-circle.svg")}
                    className="h-[32px] w-[32px]"
                  />
                </div>
                <div className="my-2">Dependent Created</div>
                <div className="flex justify-center space-x-2">
                  <Button
                    size={"sm"}
                    variant="secondary"
                    className="focus:outline-none mt-2"
                    onClick={() => {
                      dispatch(resetState());
                      navigate(-1);
                    }}
                  >
                    <PlusIcon size={18} className="me-2" /> Dependent
                  </Button>
                  <Button
                    size={"sm"}
                    className="focus:outline-none mt-2"
                    onClick={() => {
                      dispatch(resetState());
                      dispatch(resetCreateEmployeeState());
                      navigate("/employee");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewDependent;
