import { Link, useNavigate } from "react-router-dom";
import LightBtn from "src/components/Common/LightBtn";
import { Button } from "src/components/ui/button";

function StepFourDone() {
  const navigate = useNavigate();

  return (
    <div className=" w-full md:text-center p-7">
      <span className=" uppercase text-2xl">Congratulations !</span>
      <br />
      <span className=" uppercase text-2xl mt-2 text-primary font-semibold">
        Your account has been created successfully.
      </span>
      <p className="text-lg font-medium mt-3">
        Kindly check your registered email to activate your account.
      </p>

      <p className=" my-3 md:mx-auto text-slate-400 sub-styles font-normal md:text-center md:w-3/4">
        <span className=" text-red-500 font-medium">Note:</span> If you are encountering any problems receiving emails
        for the purpose of activating your account. Please contact
        help desk on {process.env.REACT_APP_ENQUIRY_PHONE_NUMBER} or mail us on <span className=" text-primary font-medium">
          <Link target="_blank" to={`mailto:${process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}`}>
            {process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}
          </Link>
        </span>
      </p>

      <Button
        onClick={() => navigate({pathname:'/'})}
        className="my-4 px-10 w-44 mx-5 text-primary bg-white border border-primary hover:text-white"
      >
        Done
      </Button>
      {/* <Button onClick={() => navigate({ pathname: "/" })}  />Done<Button/> */}
    </div>
  );
}

export default StepFourDone;
