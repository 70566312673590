import { Label } from "@radix-ui/react-label";
import React, { ChangeEvent } from "react";
import { Input } from "../Input";
import { cn } from "src/lib/utils";
import { ClassNameValue } from "tailwind-merge";

type Props = {
  formik: any;
  label?: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  type?: string;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
  classname?: string;
  onchange?: (e: any) => void;
  disabled?:boolean,
  error_className?:ClassNameValue
};

const FormikPhonenumber = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  type,
  inputMode,
  classname,
  onchange,
  disabled,
  error_className
}: Props) => {
  return (
    <div className={classname}>
      <Label className={cn(`${required ? "required" : ""}`)}>{label}</Label>
      <div >
        <Input
          name={nameKey}
          qa-data={nameKey}
          maxLength={10}
          type={type || "text"}
          inputMode={inputMode}
          placeholder={placeholder ?? label}
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const text = e.target.value.replace(/[^0-9]/g, "");
            onchange && onchange(text);
          }}
          value={formik.values[nameKey]}
          //{...field}
        />
      </div>
      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive",error_className)}>
          {formik.errors[nameKey]}
        </p>
      )}
    </div>
  );
};

export default FormikPhonenumber;
