import React, { useEffect, useState } from 'react'
import Dashboard from './Dashboard'
import axios, { AxiosResponse } from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { blob } from 'stream/consumers';

type Props = {}

// Set PDF.js worker source
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// const contentType = resp.headers.get('content-type');

// if (contentType && contentType.includes('multipart/form-data')) {
//   const rawData = await resp.request.responseText // Get the response body as text

//   const startMarker = 'Content-Type: application/pdf';
//   const endMarker = `--${rawData.split('--')[1]}`; // Get the last boundary marker

//   const startIndex = rawData.indexOf(startMarker);
//   const endIndex = rawData.indexOf(endMarker, startIndex);

//   if (startIndex !== -1 && endIndex !== -1) {
//     const pdfContent = rawData.substring(startIndex, endIndex);
//     console.log({pdfContent: pdfContent.toString});

//     const blobData = new Blob([pdfContent],{type:'text/plain'})

//     if(blobData instanceof Blob){
//         console.log("yes blob data")
//         setPdfBlob(URL.createObjectURL(blobData));
//     }

//     // setPdfBlob(pdfContent);

//     // var client = new BlockBlobClient(rawData);
//     // const response = await client.download();
//     // const blob = await response.blobBody;
//     // if (blob) {
//     //   setPdfBlob(URL.createObjectURL(blob));
//     //   // console.log("##### ", imgSrc);
//     // }
//   }

const Main = (props: Props) => {

  const blobdata = ``;

  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [pdfBlob, setPdfBlob] = useState<any>(null);
  const [imageBlob, setimageBlob] = useState<any>(null);



  useEffect(() => {
    return () => { }
  }, [])

  return (
    <div className='p-7 h-[77vh]'>
      <Dashboard />

    </div>
  )
}

export default Main