import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { RootState } from "src/store";
import {
  IDependant,
  INIT_Dependant,
  INIT_Dependant_VALUES,
} from "../modules";
import DependForm from "./DependForm";

function CreateDependents() {
  const { auth } = useAuth();

  const { dependents } = useSelector(
    (state: RootState) => state.createDependent
  );

  const dispath = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="p-4 mx-auto w-4/4 border rounded">
      <PageHeadingComponent mainContent={"Add Dependents"} subContent={""} />
      <DependForm
        formData={dependents}
      />
    </div>
  );
}

export default CreateDependents;
