// import { Icons } from "../../../components/Icons"

import { ChangeEvent, HtmlHTMLAttributes, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SECURITY_QUATIONS_OPTIONS, optionsType } from "src/Helpers";

import { useFormik } from "formik";
import {
  partialReset,
  resetstate,
  save,
} from "src/Silcers/companyRegistrationSlicer";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import FormikCountrycode from "src/components/ui/CountrySelect/FormikCountrycode";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
import { Button } from "src/components/ui/button";
import { RootState } from "src/store";
import { useRegistrationMainContext } from "../../RegistrationMainContext";
import {
  IRegistration,
  Quations,
  REGISTRATION_INIT,
  REGISTRATION_INIT_VALUES,
  registrationSchemaForNigerian,
  registration_fileds,
} from "./modules";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Loader2, ShieldClose } from "lucide-react";
import {
  checkIsValidEmail,
  getCompanyDetailsByRCNumber,
  verifyRcNumberByTokenRequest,
} from "src/services/services";
import axios, { AxiosError, AxiosResponse } from "axios";
import { CompanyData, IEcitibizApiResponse, IsomeFiledDisabled, IsomeFiledRequired } from "src/types";
import { Label } from "src/components/ui/Label";
import { cn } from "src/lib/utils";
import { genSaltSync, hashSync, compareSync } from "bcrypt-ts";
import LightBtn from "src/components/Common/LightBtn";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "src/components/ErrorDialog";
import { fi } from "date-fns/locale";
import LoadingDialog from "src/components/LoadingDialog";

export function StepTwoRegForm() {
  const navigate = useNavigate();
  const { error, registrationData, message, isLoading: requestIsLoading } = useSelector(
    (state: RootState) => state.company_registration
  );

  const [someFiledsDisabled, setsomeFiledsDisabled] =
    useState<IsomeFiledDisabled>({
      company_name: false,
      business_email: false,
      business_phone_code: false,
      business_phone_number: false,
      name_of_representative: false,
      country: false,
      rc_number: false
    });

  const [someFiledsRequired, setsomeFiledsRequired] =
    useState<IsomeFiledRequired>({
      designation_of_representative: true,
      name_of_representative: true,
      national_identification_number: false,
      nationality_of_representative: true,
      bank_verification_number: true,
      passport_number: false,
      passport_expiry: false,
      cerpac_number: false,
      cerpac_expiry: false,
    });

  const [isLoading, setisLoading] = useState(false);
  const [isVlaidEmail, setisVlaidEmail] = useState(false);
  const [emailVerifyMsg, setemailVerifyMsg] = useState<any>(undefined);
  const [loadingTitle, setloadingTitle] = useState<any>(undefined)
  const [rc_Number_error, setrc_Number_error] = useState<{
    error: boolean,
    message: string
  }>({
    error: false,
    message: ""
  })

  const {
    state: { activeTab },
    updateState,
  } = useRegistrationMainContext();
  const dispatch = useDispatch();

  const [formState, setformState] = useState<IRegistration>(REGISTRATION_INIT);

  // const form = useForm<any>({
  //   resolver: yupResolver(registrationSchemaForNigerian),
  //   values: formState,
  // });

  useEffect(() => {
    if (error) {
    }
    return () => { };
  }, [error]);

  const formik = useFormik<IRegistration>({
    initialValues: registrationData,
    validationSchema: registrationSchemaForNigerian,
    //validateOnBlur:true,
    onSubmit: (values: IRegistration) => {
      dispatch(save(values));
    },
  });


  const rendemoveKeysFromAObject = (keys: string[], object: optionsType[]) => {
    return object.filter((o: optionsType) => !keys.includes(o.value));
  };

  const updateRequiredFiled = (e: string) => {
    if (e == "Nigerian") {
      formik.setFieldValue("national_identification_number","")
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: true,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: false,
        passport_expiry: false,
        cerpac_number: false,
        cerpac_expiry: false,
      });

    
    } else if (e == "Foreigner") {
      formik.setFieldValue("national_identification_number","")
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: false,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: true,
        passport_expiry: true,
        cerpac_number: true,
        cerpac_expiry: true,
      });
    }
  };

  const checkValidEmail = async (e: any) => {
    try {
      setisLoading(true);
      setloadingTitle("Verifying Email")
      const apiResp = await checkIsValidEmail({
        businessEmail: e,
      });
      // console.log({ apiResp });
      if (apiResp.status == 200) {
        // console.log("yes error");
        //formik.setFieldError("business_email", apiResp.message);
        setisLoading(false);
        setisVlaidEmail(false);
        setemailVerifyMsg("Email ID already exists");
        formik.setFieldError("business_email", "Email ID already exists");
        formik.setFieldValue("business_email_is_valid",false)

      } else {
        setisLoading(false);
        setisVlaidEmail(true);
        setemailVerifyMsg(undefined);
        formik.setFieldValue("business_email_is_valid",true)
      }
    } catch (e) {
      setisLoading(false);
      setisVlaidEmail(true);
      setemailVerifyMsg(undefined);
    }
  };


  const verifyRCNumber = async (e: any) => {

    try {
      setisLoading(true)
      setloadingTitle("Verifying RC Number")

      // check RC Number is Exists
      const apiResp = await verifyRcNumberByTokenRequest(String(e));
      
      if (apiResp.status == 200) {
        
          setisLoading(false)

          const { CompanyName,PhoneNumber,Email,NameOfRepresentative } = apiResp?.data;

          const withoutspace = PhoneNumber.replace(/[^0-9]/g, "");
          const [code, number] = withoutspace.split(/(?<=\d)(?=\d{10}$)/);

          formik.setFieldValue("name_of_representative", NameOfRepresentative);
          formik.setFieldValue("company_name", CompanyName);
          // formik.setFieldValue("business_email", Email);
          formik.setFieldValue("business_phone_code", code);
          formik.setFieldValue("business_phone_number", number);
          formik.setFieldValue("is_moi_verified", true);

          if (Email) {
            checkValidEmail(Email);
          }

          let newState = {
            ...someFiledsDisabled,
            company_name: CompanyName ? true : false,
          }

          setsomeFiledsDisabled(newState);
        
        formik.setFieldValue("rc_number_is_valid",true)
      }
      else if(apiResp.status == 203){
        setisLoading(false)
        formik.setFieldValue("name_of_representative", "");
        formik.setFieldValue("company_name", "");
        formik.setFieldValue("business_email", "");
        formik.setFieldValue("business_phone_code", "234");
        formik.setFieldValue("business_phone_number", "");

        setsomeFiledsDisabled({
          // rc_number: true,
          company_name: false,
          business_email: false,
          business_phone_code: false,
          business_phone_number: false,
          name_of_representative: false,
          country: false,
          rc_number: false
        });
        formik.setFieldValue("rc_number_is_valid",true)
      }
    }
    catch (e) {
      setisLoading(false)
      const error = e as Error | AxiosError

      if (axios.isAxiosError(error)) {

        if(error.response?.status == 302){
          formik.setFieldError("rc_number", "RC number already exists");
          formik.setFieldValue("rc_number_is_valid",false)
        }

        setrc_Number_error({
          error: true,
          message: error.response?.data?.message
        })
      }
      else {
        setrc_Number_error({
          error: true,
          message: error?.message
        })
      }
    }
  };

  const verifyEmail = async (e: any) => {
    await checkValidEmail(e);
  };

  useEffect(() => {
    dispatch(partialReset());
    if (registrationData) {
      setformState(registrationData);
      // REGISTRATION_INIT && Object.keys(REGISTRATION_INIT).map((key:any)=>{
      //   console.log(key,registrationData[key]);
      //   form.setValue(key,registrationData[key])
      // })
    }
    return () => { };
  }, []);

  // console.log({ errors: formik.errors, touched: formik.touched, someFiledsDisabled,values : formik.values });

  return (
    <>
      {/* <CountryPickerFormFiled form={form} nameKey={'country'} key={'country-piker'} required   /> */}
      {/* <Form {...form}> */}
      <form
        onSubmit={formik.handleSubmit}
      //onChange={() => form.getValues()}
      >
        <div className="md:p-7 grid grid-cols-1 gap-3">
          {/* type of company and State of company */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              {/* <RenderFormFiled
                  formObject={registration_fileds.rc_number}
                  required={true}
                  key={registration_fileds.rc_number.key}
                  onchange={(e: any) =>
                    setformState({ ...formState, rc_number: e })
                  }
                /> */}
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.rc_number}
                required={true}
                disabled={someFiledsDisabled.rc_number}
                key={registration_fileds.rc_number.key}
                onchange={(e: any) => formik.setFieldValue("rc_number", e)}
                onBlur={async (e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value) {
                    formik.setFieldTouched("rc_number");
                    verifyRCNumber(e.target.value);
                  }
                }}
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.state_of_company}
                required={true}
                key={registration_fileds.state_of_company.key}
                onchange={(e: any) =>
                  formik.setFieldValue("state_of_company", e)
                }
              /> */}
            </div>
          </div>
          {/* Name of company && Ec number */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.type_of_company}
                required={true}
                key={registration_fileds.type_of_company.key}
                onchange={(e: any) =>
                  formik.setFieldValue("type_of_company", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                disabled={someFiledsDisabled.company_name}
                formObject={registration_fileds.company_name}
                required={true}
                key={registration_fileds.company_name.key}
                onchange={(e: any) => formik.setFieldValue("company_name", e)}
              />
            </div>
          </div>
          {/* bussiness email and phone  */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div className=" flex flex-col">
              <RenderFormikFiled
                formik={formik}
                disabled={someFiledsDisabled.business_email}
                formObject={registration_fileds.business_email}
                required={true}
                key={registration_fileds.business_email.key}
                onchange={(e: any) => formik.setFieldValue("business_email", e)}
                onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value != "") {
                    formik.setFieldTouched("business_email");
                    e.target.value != "" && verifyEmail(e.target.value);
                  }
                }}
              //sufix={isVlaidEmail}
              />
              {/* <div className="text-destructive text-sm mt-2">
                {emailVerifyMsg ? emailVerifyMsg : null}
              </div> */}
            </div>

            <div className="">
              <Label className="required uppercase">
                Business Phone Number
              </Label>
              <div className="flex items-start">
                <FormikCountrycodePicker
                  formik={formik}
                  nameKey={registration_fileds.business_phone_code.key}
                  placeholder={
                    registration_fileds.business_phone_code.placeholder
                  }
                  onchange={(e: any) =>
                    formik.setFieldValue("business_phone_code", e)
                  }
                  disabled={someFiledsDisabled.business_phone_code}
                />
                <FormikPhonenumber
                  classname="grow"
                  formik={formik}
                  nameKey={registration_fileds.business_phone_number.key}
                  placeholder={
                    registration_fileds.business_phone_number.placeholder
                  }
                  onchange={(e: any) => {
                    formik.setFieldValue("business_phone_number", e);
                  }}
                  disabled={someFiledsDisabled.business_phone_number}
                />
              </div>
            </div>
          </div>
          {/* Company Address 1 / 2 */}
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={registration_fileds.company_address1}
              required={true}
              key={registration_fileds.company_address1.key}
              onchange={(e: any) => formik.setFieldValue("company_address1", e)}
            />
          </div>
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={registration_fileds.company_address2}
              required={true}
              key={registration_fileds.company_address2.key}
              onchange={(e: any) => formik.setFieldValue("company_address2", e)}
            />
          </div>
          {/* Type of representative and designation  */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.type_of_representative}
                required={true}
                key={registration_fileds.type_of_representative.key}
                onchange={(e) => {
                  updateRequiredFiled(e);
                  formik.setFieldValue("type_of_representative", e);
                  //console.log({ e });

                  if (e === "Nigerian") {
                    //form.setValue("nationality_of_representative", );
                    formik.setFieldValue(
                      "nationality_of_representative",
                      "Nigeria"
                    );
                  }
                  else if (formik.values.nationality_of_representative == "Nigeria") {
                    formik.setFieldValue(
                      "nationality_of_representative",
                      ""
                    );
                  }
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.designation_of_representative}
                required={someFiledsRequired.designation_of_representative}
                key={registration_fileds.designation_of_representative.key}
                onchange={(e: any) =>
                  formik.setFieldValue("designation_of_representative", e)
                }
              />
            </div>
          </div>
          {/* Name of representative and national identification no */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                disabled={someFiledsDisabled.name_of_representative}
                formObject={registration_fileds.name_of_representative}
                required={someFiledsRequired.name_of_representative}
                key={registration_fileds.name_of_representative.key}
                onchange={(e: any) =>
                  formik.setFieldValue("name_of_representative", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.national_identification_number}
                required={someFiledsRequired.national_identification_number}
                key={registration_fileds.national_identification_number.key}
                onchange={(e: any) =>
                  formik.setFieldValue("national_identification_number", e)
                }
              />
            </div>
          </div>
          {/* NAtionality of Represntative and Bank verification no */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <FormikCountrycode
                disabled={formik.values.type_of_representative == "Nigerian"}
                formik={formik}
                nameKey={registration_fileds.nationality_of_representative.key}
                required={someFiledsRequired.nationality_of_representative}
                label={registration_fileds.nationality_of_representative.label}
                onchange={(e: any) =>
                  formik.setFieldValue("nationality_of_representative", e)
                }
                optionsWidth={
                  registration_fileds.nationality_of_representative.optionsWidth
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.bank_verification_number}
                required={true}
                key={registration_fileds.bank_verification_number.key}
                onchange={(e: any) =>
                  formik.setFieldValue("bank_verification_number", e)
                }
              />
            </div>
          </div>
          {/* PAssport no of representative and  Passport expiry */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.passport_number}
                required={someFiledsRequired.passport_number}
                key={registration_fileds.passport_number.key}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_number", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.passport_expiry}
                required={someFiledsRequired.passport_expiry}
                key={registration_fileds.passport_expiry.key}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_expiry", e)
                }
              />
            </div>
          </div>
          {/* crepc NO and CREP expiry */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.cerpac_number}
                required={someFiledsRequired.cerpac_number}
                key={registration_fileds.cerpac_number.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_number", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.cerpac_expiry}
                required={someFiledsRequired.cerpac_expiry}
                key={registration_fileds.cerpac_expiry.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
              />
            </div>
          </div>
          {/* password / confirm password  */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.password}
                required={true}
                key={registration_fileds.password.key}
                onchange={(e: any) => formik.setFieldValue("password", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={registration_fileds.confirm_password}
                required={true}
                key={registration_fileds.confirm_password.key}
                onchange={(e: any) =>
                  formik.setFieldValue("confirm_password", e)
                }
              />
            </div>
          </div>
          {/* Security  1 */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={{
                  ...registration_fileds.security_question1,
                }}
                required={true}
                key={registration_fileds.security_question1.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_question1", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={{
                  ...registration_fileds.security_answer1,
                }}
                disabled={!formik.values.security_question1}
                required={true}
                key={registration_fileds.security_answer1.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_answer1", e)
                }
              />
            </div>
          </div>
          {/* Security  2 */}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={{
                  ...registration_fileds.security_question2,
                  options: rendemoveKeysFromAObject(
                    [
                      //formik.getValues("security_question1"),
                      //form.getValues("security_question3"),
                      formik.values.security_question1 as string,
                      formik.values.security_question3 as string,
                    ],
                    Quations
                  ),
                }}
                disabled={!formik.values.security_question1 && !formik.values.security_answer1}
                required={true}
                key={registration_fileds.security_question2.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_question2", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                disabled={!formik.values.security_question2}
                formObject={{
                  ...registration_fileds.security_answer2,
                }}

                //disabled={!form.getValues("security_answer1")}
                required={true}
                key={registration_fileds.security_answer2.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_answer2", e)
                }
              />
            </div>
          </div>
          {/* Security  3*/}
          <div className="grid md:grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={{
                  ...registration_fileds.security_question3,
                  options: rendemoveKeysFromAObject(
                    [
                      formik.values.security_question1 as string,
                      formik.values.security_question2 as string,
                    ],
                    Quations
                  ),
                }}
                disabled={!formik.values.security_question2 && !formik.values.security_answer2}
                //disabled={!form.getValues("security_question2")}
                required={true}
                key={registration_fileds.security_question3.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_question3", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                disabled={!formik.values.security_question3}
                formObject={registration_fileds.security_answer3}
                required={true}
                //disabled={!form.getValues("security_question2")}
                key={registration_fileds.security_answer3.key}
                onchange={(e: any) =>
                  formik.setFieldValue("security_answer3", e)
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-center w-full ">
          <Button
            variant={'outline'}
            className="mb-2 px-16 md:mr-4"
            onClick={(e: any) => {
              navigate('/auth')
            }}
          >Cancel</Button>
          <Button qa-data="registration-form-submit-btn" className="mb-2 px-16 mt-4 md:mt-0">
            Continue
          </Button>
        </div>
      </form>

      <LoadingDialog isOpen={requestIsLoading} />


      {/* </Form> */}

      <Dialog open={isLoading} onOpenChange={() => console.log()}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle>{loadingTitle && loadingTitle}</DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div>Please wait</div>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                </div>
                <div>Do not close dialog and reload page until complete.</div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <ErrorDialog isOpen={rc_Number_error.error} message={rc_Number_error.message} onCancelClick={() => {
        setrc_Number_error({
          error: false,
          message: ""
        })
      }} />
    </>
  );
}
