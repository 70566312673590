import React, { useEffect, useMemo } from "react";
import EmployeeList from "./Components/EmployeeList";
import { SVGC } from "src/Helpers/SVGC";
import { toAbsoluteUrl } from "src/Helpers/AssetHelpers";
import Timeline from "./Components/Timeline";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Button } from "src/components/ui/button";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import NavBar from "src/components/Common/NavBar";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Loader2, ShieldClose } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendPaymentStatus } from "src/services/services";
import { getPaymentState, sendPaymentStatusAsync } from "src/Silcers/paymentSlicer";
import { Task } from "./Components/EmployeeTable/data/schema";
import { formatAmt } from "src/Helpers";

type Props = {};

const DashbaordMain = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedEmployee, selectedEmployeesWithPayment }: any = useSelector(
    (state: RootState) => state.employees
  );
  // console.log({selectedEmployeesWithPayment});


  const totalNiera = useMemo(
    () =>
      selectedEmployeesWithPayment && selectedEmployeesWithPayment?.length > 0
        ? selectedEmployeesWithPayment?.reduce(function (
          acc: number,
          obj: any
        ) {
          return acc + parseFloat(obj.amount_niara);
        },
          0)
        : 0,
    [selectedEmployeesWithPayment]
  );

  const {
    createPaymentIsLoading,
    createPaymentIsError,
    createPaymentErrorMessage,
    createPaymentSuccess,
    createPaymentResponse,
    sendPaymentStatusResponse,
    sendPaymentStatusIsLoading,
    sendPaymentStatusIsError,
    sendPaymentStatusErrorMessage,
  }: any = useSelector((state: RootState) => state.paymentReducer);

  useEffect(() => {
    if (createPaymentSuccess) {
      dispatch(
        sendPaymentStatusAsync({
          status: "success",
          _ids:
            selectedEmployeesWithPayment &&
            selectedEmployeesWithPayment.map((e: Task) => e.employee_id),
        })
      );
    }
    return () => { };
  }, [createPaymentSuccess]);

  const transactionID  = useMemo(() => createPaymentResponse?.transaction_id , [createPaymentResponse?.transaction_id])

  return (
    <div className="p-7">
      {/* <NavBar/> */}
      <div className=" grid grid-cols-8 gap-x-6">
        <div className="col-span-6 h-full border border-gray-600 rounded-lg p-7">
          <div className=" mb-4">
            <PageHeadingComponent
              mainContent="Payment confirmation"
              subContent={`Total selected Employees / Expatriates ${selectedEmployee.length}`}
            />
          </div>
          <EmployeeList />
        </div>
        <div className="col-span-2">
          <div className=" bg-green-50 p-6 border border-primary rounded-xl ">
            <div className="bg-white rounded-sm p-4">
              <div className="flex flex-col items-center w-full p-4">
                <SVGC
                  path={toAbsoluteUrl(
                    "/media/icons/svg/check-with-ring-primary.svg"
                  )}
                  className={"w-[50px] h-[50px]"}
                />
                <div className="text-primary text-2xl font-semibold">₦ {formatAmt(totalNiera)}</div>
                <div className="text-slate-500 text-sm font-medium mt-2">
                  Payment Done
                </div>
              </div>
              <div>
                <Timeline />
              </div>
            </div>
            <div className="mt-7">
              <div className="flex items-center justify-center">
                <Button onClick={() => navigate(`/payment_recipt/${transactionID}`)} className="inline-block px-7">Print Receipt</Button>
              </div>
              <div className="flex items-center justify-center mt-4">
                <Button variant="outline" className="px-7 border border-primary" onClick={() => navigate('/dashboard')}>
                  Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={createPaymentIsLoading} onOpenChange={() => { }}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div>Please wait</div>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                </div>
                <div>
                  Do not close dialog and reload page until complete the
                  transaction.
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog
        open={createPaymentIsError || sendPaymentStatusIsError}
        onOpenChange={() => { }}
      >
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-destructive text-lg font-bold">
                    ERROR{" "}
                  </div>
                </div>
                <ShieldClose size={24} className="text-destructive" />
                <div className="my-2">
                  {createPaymentIsError && createPaymentErrorMessage}
                  {sendPaymentStatusIsError && sendPaymentStatusErrorMessage}
                </div>
                <Button
                  variant="secondary"
                  className="focus:outline-none"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sendPaymentStatusIsLoading}
        onOpenChange={() => { }}
      >
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-primary uppercase text-lg font-bold">
                    Updateting STatus
                  </div>
                </div>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                <div className="my-2">
                  Please Wait, do not close or reload page
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DashbaordMain;
