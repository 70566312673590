import { useMemo, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
// import { Icons } from "../../../components/Icons"

import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from "formik";
import { Loader2, ShieldClose } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import LightBtn from "src/components/Common/LightBtn";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { Button } from "src/components/ui/button";
import FormikCityPicker from "src/components/ui/CityPIcker/FormikCityPicker";
import FormikCountrycode from "src/components/ui/CountrySelect/FormikCountrycode";
import { Label } from "src/components/ui/Label";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
import FormikStatePicker from "src/components/ui/StatePicker/FormikStatePicker";
import { RootState } from "src/store";
import { useMainContext } from "../MainContext";
import {
  companyProfileSchema_yup,
  companyProfileUpdateSchema_yup,
  ICompanyProfile,
  INIT_COMPANY_PROFILE_FORM,
  keys_and_labels,
} from "../modules";
import { IsomeFiledRequired } from "src/types";
import { uploadfile } from "src/services/services";
import {
  fetchCompanyDetailsAsync,
  reSetError,
  setError,
  setInitials,
  setSelectedCompany,
  updateCompanyProfileAsync,
} from "src/Silcers/companySlicer";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { checkIfAnyFilesToBeUpload } from "src/Helpers";
import LoadingDialog from "src/components/LoadingDialog";
import ErrorDialog from "src/components/ErrorDialog";
import axios, { AxiosError } from "axios";
import { cn } from "src/lib/utils";
import { update_company_kyc_validation_schema } from "../CompanyProfileView/UpdateCompanyKYC/modules";

type Props = {};

export default function ProfileEdit({ }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    compayProfile: {
      companyProfileForm,
      updateCompanyResponseStatus,
      error,
      message,
      isLoading
    },
    login: { user },
  }: any = useSelector((state: RootState) => state);

  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });
  const { setActiveTab } = useMainContext();

  const PreviousFromData:
    | Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        expatriate_quota_positionfiles: File | undefined;
        additional_documentilesfiles: File | undefined;
      }
    >
    | undefined = useMemo(() => companyProfileForm, [companyProfileForm]);

  const formik = useFormik<
    Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        expatriate_quota_positionfiles: File | undefined;
      }
    >
  >({
    initialValues: companyProfileForm,
    validationSchema: companyProfileUpdateSchema_yup,
    onSubmit: async (
      values: Partial<
        ICompanyProfile & {
          cacfiles: File | undefined;
          tccfiles: File | undefined;
          expatriate_quota_positionfiles: File | undefined;
        }
      >
    ) => {
      await onSubmitActions();
    },
  });

  useEffect(() => {
    dispatch(fetchCompanyDetailsAsync());
    return () => { };
  }, []);

  useEffect(() => {
    if (updateCompanyResponseStatus && updateCompanyResponseStatus == 200) {
      //dispatch(resetSave());
      navigate("/company-profile-view");
    }
    return () => { };
  }, [updateCompanyResponseStatus]);


  // const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
  //   setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
  //   if (isError) {
  //     setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
  //     return
  //   }
  // };

  const onSubmitActions = async () => {
    // console.log({ data });

    try {

      let newData: any = { ...formik.values };
      // setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
      // newData = await checkIfAnyFilesToBeUpload(newData, uploadProgress)

      if (newData) {
        setTimeout(() => {
          const compareValues = Object.keys(companyProfileForm)
            .filter(key => companyProfileForm[key] !== newData[key as keyof ICompanyProfile])
            .reduce((acc: any, key) => {
              acc[key] = newData[key as keyof ICompanyProfile];
              return acc;
            }, {});

          // console.log({ compareValues })
          // setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          dispatch(updateCompanyProfileAsync(compareValues));

        }, 500);
      }

    } catch (err) {
      let error = err as Error | AxiosError
      if (axios.isAxiosError(error)) {
        dispatch(setError(error.response?.data?.message))
      }
      else {
        dispatch(setError(error?.message))
      }
    }
  };

  const [someFiledsRequired, setsomeFiledsRequired] =
    useState<IsomeFiledRequired>({
      designation_of_representative: true,
      name_of_representative: true,
      national_identification_number: false,
      nationality_of_representative: true,
      bank_verification_number: true,
      passport_number: false,
      passport_expiry: false,
      cerpac_number: false,
      cerpac_expiry: false,
    });

  const updateRequiredFiled = (e: string) => {
    formik.setFieldValue("national_identification_number","")
    if (e == "Nigerian") {
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: true,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: false,
        passport_expiry: false,
        cerpac_number: false,
        cerpac_expiry: false,
      });
    } else if (e == "Foreigner") {
      formik.setFieldValue("national_identification_number","")
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: false,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: true,
        passport_expiry: true,
        cerpac_number: true,
        cerpac_expiry: true,
      });
    }
  };

  // console.log({ formikErrors: formik.errors, formikValues: formik.values });
  // console.log({uploadingFileStatus})

  return (
    <div className="p-4 lg:w-4/4 lg:mx-auto">
      <form onError={(e: any) => { }} onSubmit={formik.handleSubmit}>
        <div className="p-3 grid grid-cols-1 gap-3">
          {/* RC number state of company */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.rc_number}
                required
                onchange={(e: any) => formik.setFieldValue("rc_number", e)}
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.state_of_company}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("state_of_company", e)
                }
              /> */}
            </div>
          </div>
          {/* Type of company name of company */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.type_of_company}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("type_of_company", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.company_name}
                required
                onchange={(e: any) => formik.setFieldValue("company_name", e)}
              />
            </div>
          </div>
          {/* Nature of Bussiness */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.sector}
                required
                onchange={(e: any) => formik.setFieldValue("sector", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.nature_of_business}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("nature_of_business", e)
                }
              />
            </div>
          </div>
          {/* SECtor and Nature of Business  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.tin_number}
                required
                onchange={(e: any) => formik.setFieldValue("tin_number", e)}
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.tcc_number}
                required={formik.values.tcc ? true : false}
                onchange={(e: any) => formik.setFieldValue("tcc_number", e)}
              /> */}
            </div>
          </div>
          {/* bussiness email and phone  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.business_email}
                required
                onchange={(e: any) => formik.setFieldValue("business_email", e)}
              />
            </div>
            <div className="space-y-2">
              <Label className="required uppercase">
                Business Phone Number
              </Label>
              <div className="flex items-start">
                <FormikCountrycodePicker
                  formik={formik}
                  nameKey={keys_and_labels.business_phone_country_code.key}
                  placeholder={
                    keys_and_labels.business_phone_country_code.placeholder
                  }
                  onchange={(e: any) =>
                    formik.setFieldValue("business_phone_country_code", e)
                  }
                  disabled={keys_and_labels.business_phone_country_code.readonly}
                />
                <FormikPhonenumber
                  classname="grow"
                  formik={formik}
                  nameKey={keys_and_labels.business_phone_number.key}
                  placeholder={
                    keys_and_labels.business_phone_number.placeholder
                  }
                  onchange={(e: any) => {
                    formik.setFieldValue("business_phone_number", e);
                  }}
                  disabled={keys_and_labels.business_phone_number.readonly}
                />
              </div>
            </div>
          </div>
          {/* Country and state  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <CountryPickerFormFiled
                form={form}
                nameKey={keys_and_labels.country.key}
                required
                onchange={(e: any) =>
                  setformState({ ...formState, country: e })
                }
              /> */}
              <FormikCountrycode
                formik={formik}
                nameKey={keys_and_labels.country.key}
                required
                onchange={(e: any) => {
                  formik.setFieldValue("country", e);
                }}
              />
            </div>
            <div>
              {/* <StatepickerFormFiled
                form={form}
                nameKey={formfields.state.key}
                countryname={form.getValues("country")}
                required
                onchange={(e: any) => setformState({ ...formState, state: e })}
              /> */}
              <FormikStatePicker
                formik={formik}
                nameKey={keys_and_labels.state.key}
                required
                countryname={formik.values.country}
                onchange={(e: any) => {
                  formik.setFieldValue("state", e);
                }}
              />
            </div>
          </div>
          {/* City and Zip code  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <CityFormFiledPicker
                form={form}
                nameKey={formfields.city.key}
                stateName={form.getValues("state")}
                required
                onchange={(e: any) => setformState({ ...formState, city: e })}
              /> */}
              <FormikCityPicker
                formik={formik}
                nameKey={keys_and_labels.city.key}
                required
                stateName={formik.values.state}
                onchange={(e: any) => {
                  formik.setFieldValue("city", e);
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.zipcode}
                required={false}
                onchange={(e: any) => formik.setFieldValue("zipcode", e)}
              />
            </div>
          </div>
          {/* Company Address 1 / 2 */}
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={keys_and_labels.company_address1}
              required
              onchange={(e: any) => formik.setFieldValue("company_address1", e)}
            />
          </div>
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={keys_and_labels.company_address2}
              required={false}
              onchange={(e: any) => formik.setFieldValue("company_address2", e)}
            />
          </div>
          {/* Type of representative and designation  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.type_of_representative}
                required
                onchange={(e: any) => {
                  formik.setFieldValue("type_of_representative", e);
                  updateRequiredFiled(e);
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.designation_of_representative}
                required={someFiledsRequired.designation_of_representative}
                onchange={(e: any) =>
                  formik.setFieldValue("designation_of_representative", e)
                }
              />
            </div>
          </div>
          {/* Name of representative and national identification no */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.name_of_representative}
                required={someFiledsRequired.name_of_representative}
                onchange={(e: any) =>
                  formik.setFieldValue("name_of_representative", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.national_identification_number}
                required={someFiledsRequired.national_identification_number}
                onchange={(e: any) =>
                  formik.setFieldValue("national_identification_number", e)
                }
              />
            </div>
          </div>
          {/* Bank verification no  and Passport NO of Represntative*/}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.bank_verification_number}
                required={true}
                onchange={(e: any) =>
                  formik.setFieldValue("bank_verification_number", e)
                }
              />
            </div>
            <div>

            </div>
          </div>
          {/* crepc NO and PAsport expiry */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.passport_number}
                required={someFiledsRequired.passport_number}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_number", e)
                }
              />

            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.passport_expiry}
                required={someFiledsRequired.passport_expiry}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_expiry", e)
                }
              />
            </div>
          </div>
          {/* CERPAC EXPIRY & quota position  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.cerpac_number}
                required={someFiledsRequired.cerpac_number}
                onchange={(e: any) => formik.setFieldValue("cerpac_number", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.cerpac_expiry}
                required={someFiledsRequired.cerpac_expiry}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
              />
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.quota_position}
                required
                onchange={(e: any) => formik.setFieldValue("quota_position", e)}
              /> */}
            </div>
          </div>
          {/* UN-Utilized qutoa Upload CAC TCC ,reveewal Additional Document*/}
          {/* <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.quota_utilized}
                required
                onchange={(e: any) => {
                  console.log({
                    e,
                    quota_position: formik.values.quota_position,
                  });

                  if (e && formik.values.quota_position) {
                    var calc =
                      parseInt(formik.values.quota_position as any) -
                        parseInt(e) >
                        0
                        ? parseInt(formik.values.quota_position as any) -
                        parseInt(e)
                        : 0;

                    formik.setFieldValue("quota_remaining", calc);
                    if (
                      parseInt(e) >
                      parseInt(formik.values.quota_position as any)
                    ) {
                      formik.setFieldError(
                        "quota_utilized",
                        "Quota utilized should not be greater than quota_position"
                      );
                    }
                  }
                  formik.setFieldValue("quota_utilized", e);
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.quota_remaining}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("quota_remaining", e)
                }
              />
            </div>
          </div> */}

          {/* CAC TCC EXPIRATE */}

          {/* <RenderFormikFiled
            formik={formik}
            formObject={keys_and_labels.cac}
            value={formik.values.cac}
            required
            onchange={(e: any) => formik.setFieldValue("cac", e)}
          /> */}

          {/* tcc EXPIRATE */}

          {/* <RenderFormikFiled
            formik={formik}
            formObject={keys_and_labels.tcc}
            value={formik.values.tcc}
            required={formik.values.tcc_number ? true : false}
            onchange={(e: any) => formik.setFieldValue("tcc", e)}
          /> */}

          {/* expatriate_quota_position */}

          {/* <RenderFormikFiled
            formik={formik}
            value={formik.values.expatriate_quota_position}
            formObject={keys_and_labels.expatriate_quota_position}
            required
            onchange={(e: any) =>
              formik.setFieldValue("expatriate_quota_position", e)
            }
          /> */}
          {/* <RenderFormikFiled
            formik={formik}
            value={formik.values.itf}
            formObject={keys_and_labels.itf}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("itf", e)
            }
          /> */}
          {/* <RenderFormikFiled
            formik={formik}
            value={formik.values.pancom}
            formObject={keys_and_labels.pancom}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("pancom", e)
            }
          /> */}
          {/* <RenderFormikFiled
            formik={formik}
            value={formik.values.additional_document}
            formObject={keys_and_labels.additional_document}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("additional_document", e)
            }
          /> */}
          <div className="flex justify-center w-full">
            {/* <Button className="mb-2 px-10 w-44 mx-5 bg-secondary-foreground">
            Save as Draft
          </Button> */}
            <LightBtn
              onclick={(e: any) => {

                navigate(-1)
              }}
              title="Cancel"
            />
            <Button
              disabled={uploadingFileStatus.isLoading || isLoading}
              type="submit"
              className="mb-2 px-10 w-44 mx-5"
            >
              {(uploadingFileStatus.isLoading && uploadingFileStatus.isLoading) || (isLoading && isLoading) && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </Button>
          </div>


        </div>
      </form>


      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Sending Requests" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(reSetError())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => onSubmitActions()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />
    </div>
  );
}


