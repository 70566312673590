import {
  ColumnDef
} from "@tanstack/react-table";

import { Task } from "../data/schema";
import Actions from "./Actions";
import Showcheckbox from "./Showcheckbox";
import { DataTableColumnHeader } from "./data-table-column-header";

export const columns: ColumnDef<Task>[] = [
  // {
  //   id: "select",
  //   cell: ({ row }) =>
  //   (
  //     <Showcheckbox row={row} />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },

  {
    accessorKey: "givenname",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Employee Name" />
    ),
    cell({ row: { original: { givenname, surname } } }) {
      return <div>{ givenname} { surname}</div>
    },
  },
  {
    accessorKey: "empemail",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  {
    accessorKey: "position",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone No." />
    ),
  },
  {
    id: "actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Action" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
  // {
  //   accessorKey: "department",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Department" />
  //   ),
  // },
];
