import { DESIGNATIONS_OPTIONS_DROPDOWN, nationality_representative_OPTIONS, SECURITY_QUATIONS, SECURITY_QUATIONS_OPTIONS, STATE_OF_COMPANY_OPTIONS, TYPE_OF_COMPANY_OPTIONS, type_of_representative_OPTIONS } from "src/Helpers";
import * as yup from 'yup';
import { z } from "zod";
import { format, addDays } from 'date-fns'

export type IRegistration = {
    type_of_company: string | undefined,
    // state_of_company: string | undefined,
    rc_number: string | undefined,
    company_name: string | undefined,
    business_phone_number: string | undefined,
    business_phone_code: string | undefined,
    business_email: string | undefined,
    company_address1: string | undefined,
    company_address2?: string | undefined,
    type_of_representative: string | undefined | null,
    designation_of_representative: string | undefined | null,
    name_of_representative: string | undefined | null,
    national_identification_number: string | undefined | null,
    nationality_of_representative: string | undefined | null,
    bank_verification_number: string | undefined | null,
    passport_number: string | undefined | null,
    passport_expiry: string | undefined,
    cerpac_number: string | undefined | null,
    cerpac_expiry: string | undefined,
    password: string | undefined,
    confirm_password: string | undefined,
    security_question1: string | undefined,
    security_answer1: string | undefined,
    security_question2: string | undefined,
    security_answer2: string | undefined,
    security_question3: string | undefined,
    security_answer3: string | undefined,
    rc_number_is_valid?: boolean,
    business_email_is_valid?: boolean,
    is_moi_verified?:boolean
}

export const REGISTRATION_INIT: IRegistration = {
    type_of_company: undefined,
    // state_of_company: undefined,
    rc_number: undefined,
    company_name: undefined,
    business_phone_number: undefined,
    business_phone_code: "234",
    business_email: undefined,
    company_address1: undefined,
    company_address2: undefined,
    type_of_representative: undefined,
    designation_of_representative: undefined,
    name_of_representative: undefined,
    national_identification_number: undefined,
    nationality_of_representative: undefined,
    bank_verification_number: undefined,
    passport_number: undefined,
    passport_expiry: undefined,
    cerpac_number: undefined,
    cerpac_expiry: undefined,
    password: undefined,
    confirm_password: undefined,
    security_question1: undefined,
    security_answer1: undefined,
    security_question2: undefined,
    security_answer2: undefined,
    security_question3: undefined,
    security_answer3: undefined,
    is_moi_verified:false
}

export const REGISTRATION_DUMMY_DATA: IRegistration = {
    type_of_company: "Private limited company",
    // state_of_company: "State Government",
    rc_number: "123456",
    company_name: "ABC Corporation",
    business_phone_code: "+234",
    business_phone_number: "2345678901",
    business_email: "abc@example.com",
    company_address1: "123 Main Street",
    company_address2: "Apt 4B",
    type_of_representative: "Nigerian",
    designation_of_representative: "CEO",
    name_of_representative: "John Doe",
    national_identification_number: "123456789",
    nationality_of_representative: "Nigerian",
    bank_verification_number: "987654321",
    passport_number: "A1234567B ",
    passport_expiry: "2023-09-01",
    cerpac_number: "A1234567B ",
    cerpac_expiry: "2023-09-01",
    password: "P@ssw0rd",
    confirm_password: "P@ssw0rd",
    security_question1: "",
    security_answer1: "test",
    security_question2: "",
    security_answer2: "test",
    security_question3: "",
    security_answer3: "test",
    is_moi_verified:false
}

const errorMessages = {
    type_of_company: "Please select type of company",
    // state_of_company: "Please select state of company",
    rc_number: "Please enter valid RC Number",
    company_name: "Please enter company name",
    business_phone_code: "Please select phone code",
    business_phone_number: "Please enter phone number",
    business_email: "Please enter email id",
    company_address1: "Please enter House No / Area.",
    company_address2: "Please enter Street / Landmark",
    type_of_representative: "Please select type of representative",
    designation_of_representative: "Please select designation of representative.",
    name_of_representative: "Please enter name of representative", // Only if type_of_representative is 'Nigerian'.
    national_identification_number: "Please Enter National Identification Number",
    nationality_of_representative: "Please select nationality of representative", // No specific error message, as it's optional.
    bank_verification_number: "Please enter bank verification number", // No specific error message, as it's optional.
    passport_number: "Please enter passport number",
    passport_expiry: "Please select passport expiry",
    cerpac_number: "Please enter cerpac number",
    cerpac_expiry: "Please select cerpac expiry",
    password: "Please enter password",
    password_regex: "",
    confirm_password: "Please enter confirm password",
    confirm_password_not_match: "Password and confirm password not match",
    security_question1: "Please select security question1",
    security_answer1: "Please enter security answer1",
    security_question2: "Please select security question2",
    security_answer2: "Please enter security answer2",
    security_question3: "Please select security question3",
    security_answer3: "Please enter security answer3",
    special_characters_error: "Spacial characters are not allowed",
};

export const registrationSchemaForNigerian = yup.object().shape({
    type_of_company: yup.string().required(errorMessages.type_of_company),
    // state_of_company: yup.string().required(errorMessages.state_of_company),
    rc_number: yup.string().matches(/^([0-9]+)$/, { message: errorMessages.special_characters_error }).min(4, "RC Number must be at least 4 digits.").max(13).test('rc_number', (value, validationcontext) => {
        const { createError, parent: { rc_number_is_valid } } = validationcontext;
        if (!value ) {
            return createError({ message: errorMessages.rc_number});
        }
        else if(!rc_number_is_valid){
            return createError({ message: "RC number already exists"});
        }
        return true;
    }),
    company_name: yup.string().matches(/^([a-zA-Z 0-9 &.-]+)$/, { message: errorMessages.special_characters_error }).min(3, "Company name must be at least 3 characters").max(50, "Company name should be less than 50 characters.").required(errorMessages.company_name),
    business_phone_code: yup.string().optional().test('business_phone_code', (value, validationcontext) => {
        const { createError, parent: { business_phone_number } } = validationcontext;
        if (!value && business_phone_number) {
            return createError({ message: "Please select country code" });
        }
        return true;
    }),
    business_phone_number: yup.string()
        .matches(/^([0-9]+)$/, 'Invalid Phone number')
        .length(10, "Phone number must be 10 digits")
        .required(errorMessages.business_phone_number),
    business_email: yup.string().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, { message: "Invalid Email address" }).email().test('business_email', (value, validationcontext) => {
        const { createError, parent: { business_email_is_valid } } = validationcontext;
        if (!value ) {
            return createError({ message: errorMessages.business_email});
        }
        else if(!business_email_is_valid){
            return createError({ message: "Email ID already exists"});
        }
        return true;
    }),
    company_address1: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).min(10, "Min lenght must be atleast 10 characters").max(250, "Max 250 characters only").required(errorMessages.company_address1),
    company_address2: yup.string().matches(/^([a-zA-Z0-9/ ]+)$/, { message: "Only space and / allowed" }).min(10, "Min lenght must be atleast 10 characters").max(250, "Max 250 characters only").required(errorMessages.company_address2),
    type_of_representative: yup.string().required(errorMessages.type_of_representative),
    designation_of_representative: yup.string().required(errorMessages.designation_of_representative),
    name_of_representative: yup.string().matches(/^([a-zA-Z. ]+)$/, { message: errorMessages.special_characters_error }).min(3, "Min lenght must be atleast 3 characters").required(errorMessages.name_of_representative),
    national_identification_number: yup.string().length(11, "National Identification Number must be 11 digits.").optional().test('national_identification_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Nigerian') {
            return createError({ message: errorMessages.national_identification_number });
        }
        else if (value && type_of_representative === 'Foreigner') {
            return true;
        }
        return true;
    }),
    nationality_of_representative: yup.string().required(errorMessages.nationality_of_representative),
    bank_verification_number: yup.string().length(11, "Bank Verification Number must be 11 digits").required("Please enter bank verification number"),
    passport_number: yup.string().min(8, "Passport Number must be atleast 8 characters ").max(12, "It max 12 digits").optional().test('passport_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.passport_number });
        }
        return true;
    }),
    passport_expiry: yup.string().optional().test('passport_expiry', (value, validationcontext) => {
        const { createError, parent: { type_of_representative, passport_number } } = validationcontext;

        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && !passport_number && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.passport_expiry });
        }

        if (!value && passport_number) {
            return createError({ message: errorMessages.passport_expiry });
        }

        if (value && inputDate && inputDate <= addDays(currentDate, 2)) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    cerpac_number: yup.string().length(8, "CARPAC number must be 8 characters").optional().test('cerpac_number', (value, validationcontext) => {
        const { createError, parent: { type_of_representative } } = validationcontext;
        if (!value && type_of_representative === 'Foreigner') {
            return createError({ message: errorMessages.cerpac_number });
        }

        return true;
    }),
    cerpac_expiry: yup.string().optional().test('cerpac_expiry', (value, validationcontext) => {
        const { createError, parent: { type_of_representative, cerpac_number, passport_expiry } } = validationcontext;

        const inputDate = value && new Date(value);
        const currentDate = new Date();
        const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;

        if (!value && (cerpac_number || type_of_representative === 'Foreigner')) {
            return createError({ message: errorMessages.cerpac_expiry });
        }

        if (value && inputDate && inputDate <= addDays(currentDate, 2)) {
            return createError({ message: "Expiry date should not be less than current date." });
        }
        else if (value && pasportDate && inputDate && inputDate > pasportDate) {
            return createError({ message: "Expiry date should not be greater than passport expiry date." });
        }

        return true;
    }),
    password: yup.string()
        .optional()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { password } } = validationcontext;
            if (!value) {
                return createError({ message: errorMessages.password });
            }

            if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/)) {
                return createError({ message: "Password length should be between 8-18 characters, including capital letters, numbers, and special characters. " });
            }
            return true;
        }),
    confirm_password: yup.string().optional().test('confirm_password', (value, validationcontext) => {
        const { createError, parent: { password } } = validationcontext;
        if (!value) {
            return createError({ message: errorMessages.password });
        }

        // if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/)) {
        //     return createError({ message: errorMessages.password_regex });
        // }

        if (password != value) {
            return createError({ message: errorMessages.confirm_password_not_match });
        }
        return true;
    }),
    security_question1: yup.string().required(errorMessages.security_question1),
    security_answer1: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer1),
    security_question2: yup.string().required(errorMessages.security_question2),
    security_answer2: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer2),
    security_question3: yup.string().required(errorMessages.security_question3),
    security_answer3: yup.string().matches(/^([a-zA-Z0-9 ]+)$/, { message: "Only space and Alphanum allowed" }).min(3).max(25).required(errorMessages.security_answer3),
});

export const Quations = SECURITY_QUATIONS && SECURITY_QUATIONS?.map((o: any) => ({
    value: o,
    label: o
}));

const getYearFromNextTwoYear = () => {
    var year = new Date().getFullYear()
    return year + 2
}

export const registration_fileds = {
    type_of_company: {
        key: "type_of_company",
        label: "Type of company",
        field_type: "options",
        options: TYPE_OF_COMPANY_OPTIONS,
        placeholder: "Type of company",
    },
    // state_of_company: {
    //     key: "state_of_company",
    //     label: "State of company",
    //     field_type: "options",
    //     options: STATE_OF_COMPANY_OPTIONS,
    //     placeholder: "State of company",
    // },
    rc_number: {
        key: "rc_number",
        label: "RC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "RC Number",
        maxLength: 13,
        regex: /[^0-9]+/g,
        prefix: "RC-"
    },
    company_name: {
        key: "company_name",
        label: "Name Of Company",
        field_type: "text",
        field_mode: "text",
        placeholder: "Name Of Company",
        regex: /[^A-Za-z 0-9&.-]+/g,
        maxLength: 50
    },
    business_phone_code: {
        key: "business_phone_code",
        label: "Business Phone Code",
        field_type: "phone",
        placeholder: "Business Phone Code",
    },
    business_phone_number: {
        key: "business_phone_number",
        label: "Business Phone Number",
        field_type: "phone",
        placeholder: "Business Phone Number",
    },
    business_email: {
        key: "business_email",
        label: "Business Email",
        field_type: "email",
        field_mode: "text",
        placeholder: "Business Email",
    },
    company_address1: {
        key: "company_address1",
        label: "Address Line 1",
        field_type: "text",
        field_mode: "text",
        placeholder: "House NO / Area",
        maxLength: 250,
        regex: /[^A-Za-z0-9/ ]+/g,
        showMaxLength: true
    },
    company_address2: {
        key: "company_address2",
        label: "Address Line 2",
        field_type: "text",
        field_mode: "text",
        placeholder: "Street / Landmark",
        maxLength: 250,
        regex: /[^A-Za-z0-9/ ]+/g,
        showMaxLength: true
    },
    type_of_representative: {
        key: "type_of_representative",
        label: "Type of Representative",
        field_type: "options",
        options: type_of_representative_OPTIONS,
        placeholder: "Type of Representative",
    },
    designation_of_representative: {
        key: "designation_of_representative",
        label: "Designation of Representative",
        field_type: "options_with_search",
        options: DESIGNATIONS_OPTIONS_DROPDOWN,
        optionsWidth: 400,
        placeholder: "Designation of Representative",
    },
    name_of_representative: {
        key: "name_of_representative",
        label: "Name of Representative",
        field_type: "text",
        field_mode: "text",
        regex: /[^A-Za-z. ]+/g,
        placeholder: "Name of Representative",
        maxLength: 50,
    },
    national_identification_number: {
        key: "national_identification_number",
        label: "National Identification Number of Representative (NIN)",
        field_type: "text",
        field_mode: "text",
        placeholder: "National Identification Number of Representative (NIN)",
        maxLength: 11,
        regex: /[^0-9]+/g
    },
    nationality_of_representative: {
        key: "nationality_of_representative",
        label: "Nationality of Representative",
        field_type: "options_with_search",
        options: nationality_representative_OPTIONS,
        placeholder: "Nationality of Representative",
        optionsWidth: 350,
    },
    bank_verification_number: {
        key: "bank_verification_number",
        label: "BANK VERIFICATION NUMBER (BVN)",
        field_type: "text",
        field_mode: "text",
        placeholder: "Bank Verification Number",
        maxLength: 11,
        regex: /[^0-9]+/g
    },
    passport_number: {
        key: "passport_number",
        label: "PASSPORT NO. OF REPRESENTATIVE",
        field_type: "text",
        field_mode: "text",
        placeholder: "Passport Number",
        maxLength: 12,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true
    },
    passport_expiry: {
        key: "passport_expiry",
        label: "PASSPORT EXPIRY OF REPRESENTATIVE",
        field_type: "date",
        field_mode: "text",
        placeholder: "Passport Expiry",
    },
    cerpac_number: {
        key: "cerpac_number",
        label: "CERPAC NO. OF REPRESENTATIVE",
        field_type: "text",
        field_mode: "text",
        placeholder: "CERPAC Number",
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true
    },
    cerpac_expiry: {
        key: "cerpac_expiry",
        label: "CERPAC Expiry OF REPRESENTATIVE",
        field_type: "date",
        field_mode: "text",
        placeholder: "CERPAC Expiry",
        toYear: getYearFromNextTwoYear(),
        fromYear:new Date().getFullYear()
    },
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Password",
    },
    confirm_password: {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Confirm Password",
    },
    security_question1: {
        key: "security_question1",
        label: "Security Question 1",
        field_type: "options_with_search",
        options: Quations,
        optionsWidth: 500,
        placeholder: "Security Question 1",
    },
    security_answer1: {
        key: "security_answer1",
        label: "Security Answer 1",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 1",
        maxLength: 25,
        regex: /[^A-Za-z0-9 ]+/g,
    },
    security_question2: {
        key: "security_question2",
        label: "Security Question 2",
        field_type: "options_with_search",
        options: Quations,
        optionsWidth: 500,
        placeholder: "Security Question 2",
    },
    security_answer2: {
        key: "security_answer2",
        label: "Security Answer 2",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 2",
        maxLength: 25,
        regex: /[^A-Za-z0-9 ]+/g,
    },
    security_question3: {
        key: "security_question3",
        label: "Security Question 3",
        field_type: "options_with_search",
        options: Quations,
        optionsWidth: 500,
        placeholder: "Security Question 3",
    },
    security_answer3: {
        key: "security_answer3",
        label: "Security Answer 3",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 3",
        maxLength: 25,
        regex: /[^A-Za-z0-9 ]+/g,
    },
}

export const REGISTRATION_INIT_VALUES = {
    "type_of_company": "Private limited company",
    // "state_of_company": "Other",
    "rc_number": "1111111111111",
    "company_name": "test company bb",
    "business_phone_number": "5551234567",
    "business_phone_code": "234",
    "business_email": "test.b@gmail.com",
    "company_address1": "adres",
    "type_of_representative": "Foreigner",
    "designation_of_representative": "Chief Technology Officer",
    "name_of_representative": "represntative",
    "national_identification_number": "31232112312",
    "nationality_of_representative": "Algeria",
    "bank_verification_number": "21312313231",
    "passport_number": "12312323",
    "passport_expiry": "2023-10-13",
    "cerpac_number": "12312312",
    "cerpac_expiry": "2023-10-26",
    "password": "Pass121@",
    "confirm_password": "Pass121@",
    "security_question1": "In what city or town does your nearest sibling live?",
    "security_answer1": "test",
    "security_question2": "What was the name of your elementary / primary school?",
    "security_answer2": "test",
    "security_question3": "What is your pet’s name?",
    "security_answer3": "test"
}