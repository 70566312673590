import React, { ReactNode, useEffect, useReducer, useState } from "react";

import { cn } from "src/lib/utils";
import StepOneDetail from "./CompanySteps/StepOneDetail.new";
import StepTwoReview from "./CompanySteps/StepTwoReview";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { company_profile_heradings } from "src/Helpers";
import { MainContextProvider, useMainContext } from "./MainContext";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { ICompanyProfile } from "./modules";
import { useDispatch } from "react-redux";
import { getUserByTokenAsync } from "src/Silcers/loginSlicer";
import { useNavigate } from "react-router-dom";

interface MainStepsTabsContextProps {
  activeTab: number;
  handleTabClick: (tabIndex: number) => void;
  getTabContent?: () => ReactNode;
}

function CompanyStepTab({
  activeTab,
  handleTabClick,
}: MainStepsTabsContextProps) {
  return (
    <div className="tabs w-full tabs-boxed: rounded-sm flex align-middle my-3">
      {company_profile_heradings.map((el, index) => (
        <div
          onClick={() => handleTabClick(index + 1)}
          key={index}
          className={cn(
            "p-4 flex-grow hover:cursor-pointer border-b-4 border-gray-700 mx-3",
            "",
            { "border-primary": activeTab == index + 1 }
          )}
        >
          <span className="text-sm font-medium sub-styles">
            Step {index + 1}
          </span>
          <h3 className=" text-lg font-medium uppercase">{el.heading}</h3>
          <p className="text-sm text-muted-foreground">{el.subHeading}</p>
        </div>
      ))}
    </div>
  );
}

const Main = () => {

  const {
    state: { activeTab },
    setActiveTab,
  } = useMainContext();

  const { isNewUser } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isNewUser) {
      navigate("/company-profile-view");
    }
    return () => { }
  }, [])


  const dispatch = useDispatch()

  const companyProfileForm: Partial<ICompanyProfile> | undefined = useSelector((state: RootState) => state.compayProfile.companyProfileForm);
  // console.log({ activeTab, companyProfileForm });

  useEffect(() => {
    dispatch(getUserByTokenAsync())
    return () => { }
  }, [])


  return (
    <div className="md:px-4 lg:w-4/4 m-auto mt-5">
      <div className="card bg-base-100 shadow-sm rounded-md">
        <PageHeadingComponent
          mainContent={company_profile_heradings[activeTab - 1]["mainHeading"]}
          subContent={""}
        />
        <CompanyStepTab
          activeTab={activeTab}
          handleTabClick={(e: any) => setActiveTab(e)}
        />
        {activeTab === 1 && <StepOneDetail />}
        {activeTab === 2 && <StepTwoReview />}
      </div>
    </div>
  );
};

function MainCompanyDetail() {
  return (
    <MainContextProvider>
      <Main />
    </MainContextProvider>
  );
}

export default MainCompanyDetail;
