// import StepCircle from "src/Modules/Auth/Regsitration/Components/StepCircle";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAssigneEmployeeToEmptyCardReducerState, setAssignDialogIsOpenStatus, setSelectedCard, setSelectedDesignation, setSelectedEmployee } from "src/Silcers/assigneEmployeeToEmptyCardSlicer";
import LightBtn from "src/components/Common/LightBtn";
import { Button } from "src/components/ui/button";
import { RootState } from "src/store";

type Props = {
  row: any;
};

const Actions = ({ row }: Props) => {

  const dispatch = useDispatch()
  const { selectedCard, assinedEmployeeAndCardsPostData } = useSelector((state: RootState) => getAssigneEmployeeToEmptyCardReducerState(state))

  const isEmployeeAlreadyAssignedToThisCard = useMemo(() => assinedEmployeeAndCardsPostData && Array.isArray(assinedEmployeeAndCardsPostData) && assinedEmployeeAndCardsPostData.find((emp: any) => emp.card_id == row._id), [assinedEmployeeAndCardsPostData])

  return (
    <div>
      {
        !isEmployeeAlreadyAssignedToThisCard ?
          <Button size={"sm"}  className="text-xs bg-transparent border border-primary  text-primary hover:text-white " onClick={() => {
            dispatch(setSelectedCard(row?._id))
            dispatch(setAssignDialogIsOpenStatus(true))
            dispatch(setSelectedDesignation(row?.designation))
          }}> Select Employee</Button> : isEmployeeAlreadyAssignedToThisCard?.employee_nam
      }
    </div>
  );
};

export default Actions;
