import { useFormik } from 'formik'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import { INIT_UPDATE_EMPLOYEE_KYC_DOCUMENT, IcurrentEmployeeDetails, update_employee_kyc_form_fileds, update_employee_kyc_validation_schema, userdetails_transform_to_form_keys } from './module'
import { useEffect, useMemo, useState } from 'react'
import SubmitBtn from 'src/components/Common/SubmitBtn'
import LightBtn from 'src/components/Common/LightBtn'
import { IRequestUsestate } from 'src/types'
import { checkIfAnyFilesToBeUpload } from 'src/Helpers'
import axios, { AxiosError } from 'axios'
import ErrorDialog from 'src/components/ErrorDialog'
import { update_employee_kyc_details_request_api } from 'src/services/services'
import { cn } from 'src/lib/utils'
import LoadingDialog from 'src/components/LoadingDialog'
import { Console } from 'console'

type Props = {
    currentUserDetails: IcurrentEmployeeDetails,
    isOpen: "show" | "hide",
    onChangeOpenState: (e: any) => void
}

const UpdateKYCDocumentMain = ({ currentUserDetails, onChangeOpenState, isOpen }: Props) => {


    const [requestState, setrequestState] = useState<IRequestUsestate>({
        isError: false,
        isLoading: false,
        message: "",
        status: undefined
    })
    const [uploadingFileStatus, setuploadingFileStatus] = useState<{
        isLoading: boolean,
        isError: boolean,
        message: string | undefined
    }>({
        isLoading: false,
        isError: false,
        message: undefined
    });
    const [uploadedFileStatus, setuploadedFileStatus] = useState<{
        totalFiles: number,
        uploadedFiles: number,
    }>({
        totalFiles: 0,
        uploadedFiles: 0,
    });

    const new_currentUserDetails = useMemo(() => Object.keys(userdetails_transform_to_form_keys).reduce((acc: any, key) => {
        let value: any = currentUserDetails[userdetails_transform_to_form_keys[key as keyof object] as keyof object]
        value = typeof value == "string" ? value.trim() : value
        acc[key] = value
        acc["old_" + key] = value
        return acc;
    }, {}), [userdetails_transform_to_form_keys, currentUserDetails])


    useEffect(() => {
        if (isOpen == "show") {
            formik.setFieldValue("documentType", "")
            formik.resetForm()
        }
    }, [isOpen])


    const initial_values = useMemo(() => {
        if (new_currentUserDetails) return new_currentUserDetails
        return INIT_UPDATE_EMPLOYEE_KYC_DOCUMENT
    }, [new_currentUserDetails, INIT_UPDATE_EMPLOYEE_KYC_DOCUMENT])

    const formik = useFormik({
        initialValues: initial_values,
        validationSchema: update_employee_kyc_validation_schema,
        onSubmit(values, formikHelpers) {
            if (formik.errors && Object.keys(formik.errors).length == 0) {
                // console.log({isAnyValueChanged});

                handleSubmit();
            }
        },
    })


    useEffect(() => {
        if (formik.values.visa_type != "Residence Visa") {
            ["cerpac_no", "cerpac_expiry", "cerpac_front_doc", "cerpac_back_doc", "visa_type", "visa_number", "visa_expiry", "visa_doc"].map((key) => {
                return formik.setFieldValue(key, new_currentUserDetails["old_" + key])
            })
        }
        return () => { }
    }, [formik.values.visa_type])


    const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
        setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
        if (isError) {
            setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
            setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: undefined })
            return
        }
    };


    const handleSubmit = async () => {
        try {
            setrequestState({ ...requestState, isLoading: true, isError: false, message: "", status: undefined })

            const newdata = await checkIfAnyFilesToBeUpload(getDataKeysBySelectedDocumentOption, uploadProgress)
            console.log({newdata});
            
            if (newdata) {
                // const trasformkeys = Object.keys(userdetails_transform_to_form_keys).reduce((acc: any, key) => {
                //     if (newdata && Object.keys(isAnyValueChanged).includes(key)) {
                //         acc[userdetails_transform_to_form_keys[key as keyof object]] = newdata[key as keyof object];
                //     }
                //     return acc;
                // }, {})

                const apiResp = await update_employee_kyc_details_request_api({
                    _id: currentUserDetails._id,
                    ...newdata
                })
                console.log({ apiResp })
                if (apiResp?.status == 200) {
                    setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: apiResp?.status })
                    onChangeOpenState("hide")
                }
            }

        } catch (err) {
            const error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error.response?.data?.message, status: undefined })
            }
            else {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error?.message, status: undefined })
            }
        }
    }


    const doctypeOptions = useMemo(() => {
        let documentTypeOptions = [
            {
                label: "Passport Document",
                value: "PASSPORT"
            }, {
                label: "VISA Document",
                value: "VISA"
            },
        ]

        if (new_currentUserDetails.visatype == "Residence Visa") {
            documentTypeOptions.push({
                label: "CERPAC Document",
                value: "CERPAC"
            })
        }

        return documentTypeOptions
    }, [currentUserDetails])

    // console.log({ new_currentUserDetails })

    const isAnyValueChanged: any = useMemo(() => {

        const { _id, ...restUserDetails } = new_currentUserDetails;
        const { documentType, quota_expiry, ...restFormDetails } = formik.values;

        return Object.keys(new_currentUserDetails).reduce((acc: any, key) => {
            if (!key.startsWith("old_")) {
                if ((restUserDetails[key] != formik.values[key]) || (restUserDetails["old_" + key] != formik.values[key])) {
                    acc[key] = formik.values[key as keyof object]
                }
            }
            return acc;
        }, {})

    }, [new_currentUserDetails, formik.values])

    const getDataKeysBySelectedDocumentOption = useMemo(() => {
        if (formik.values.documentType == "PASSPORT") {
            return {
                passportnumber: formik.values.passport_no,
                passportexpiry: formik.values.passport_expiry,
                uploadpassport: formik.values.passport_doc
            }
        }
        else if (formik.values.documentType == "VISA") {
            if (formik.values.visa_type == "Residence Visa") {
                return {
                    cerpac_number: formik.values.cerpac_no,
                    cerpac_back: formik.values.cerpac_back_doc,
                    cerpac_front: formik.values.cerpac_front_doc,
                    cerpac_expiry: formik.values.cerpac_expiry,
                    visatype: formik.values.visa_type,
                }
            }
            return {
                visatype: formik.values.visa_type,
                visanumber: formik.values.visa_number,
                visaexpiracydate: formik.values.visa_expiry,
                visa_document: formik.values.visa_doc,
            }
        }
    }, [formik.values, isAnyValueChanged])


    const isDisabledSubmitBtn = useMemo(() => (isAnyValueChanged && Object.keys(isAnyValueChanged).length == 0), [isAnyValueChanged, formik.errors])

    const { documentType, ...rest_form_fileds } = update_employee_kyc_form_fileds;

    console.log({ values: formik.values, isAnyValueChanged, errors: formik.errors })

    return (
        <div>
            <CustomeDialog headerTitle="Update KYC Document" isOpen={isOpen == "show"} hideDefaultCancle hideDefaultSubmit  >
                <form onSubmit={formik.handleSubmit} >
                    <div className='space-y-2 mb-4'>
                        <div><RenderFormikFiled formik={formik} options={doctypeOptions} value={formik.values.documentType} formObject={documentType} onchange={(e: any) => {
                            formik.setValues(initial_values)
                            formik.setFieldValue("documentType", e)
                        }} /></div>
                        <>
                            {
                                rest_form_fileds && Object.entries(rest_form_fileds).map(([key, value]: [any, any]) => {
                                    if (value && value?.showIf && value?.showIf(formik)) {
                                        return <div className='flex flex-col '>
                                            <div><RenderFormikFiled formik={formik} value={formik.values[value.key as keyof object]} formObject={value} onchange={(e: any) => {
                                                formik.setFieldValue(value.key, e)
                                            }} /></div>
                                            <div className='flex justify-end'><span className='text-primary text-xs cursor-pointer underline underline-offset-1' onClick={() => formik.setFieldValue(value.key, formik.values["old_" + value.key as keyof object])} >Reset</span></div>
                                        </div>
                                    }
                                })
                            }
                        </>
                    </div>
                    <div className='flex justify-center space-x-4 mt-4' > <LightBtn title='Cancel' onclick={() => onChangeOpenState("hide")} /><SubmitBtn isDisabled={isDisabledSubmitBtn} title='Submit' isLoading={false} /> </div>
                </form>
            </CustomeDialog>
            <LoadingDialog isOpen={requestState.isLoading || uploadingFileStatus.isLoading} message='' >
                <div className="flex flex-col w-full space-y-2">
                    <div className="text-center">
                        {requestState.isLoading ? "Sending Requests" : null}
                        {uploadingFileStatus.isLoading ? "Uploading Files" : null}
                    </div>
                    {uploadingFileStatus.isLoading && <div className="flex space-x-1">
                        {
                            uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
                            })
                        }
                    </div>}
                </div>
            </LoadingDialog>
            <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={() => setrequestState({ ...requestState, isError: false, message: "", status: undefined })} />
            <ErrorDialog isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => setuploadingFileStatus({ ...requestState, isError: false, message: "" })} />
        </div>
    )
}

export default UpdateKYCDocumentMain