import { ENUM_KYC_STATUS, toAbsoluteUrl } from "src/Helpers/";
import { SVGC } from "src/Helpers/SVGC";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NavBar from "src/components/Common/NavBar";
import { Button } from "src/components/ui/button";
import { Progress } from "src/components/ui/progress";
import { getCompnayKycStatus, getQuotaSummury } from "src/services/services";
import { RootState } from "src/store";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Badge } from "src/components/ui/badge";
import { CheckCircledIcon } from '@radix-ui/react-icons'
import { ShieldCheck, ShieldOff } from "lucide-react";

const quotas = [
  "total number of quota",
  "total number of quota available",
  "total number of quota used",
  "total number of quota expired",
];

// interface IQuotaSummuryResponse {
//   quotaPositions: number;
//   utilizedQuota: number;
// }

interface dashBoardSummury {
  approved_quota: number,
  utilized_quota: number,
  un_utilized_quota: number,
  total_employees: number,
  paid_employees: number,
  incomplete_employees: number,
  kyc_status?: boolean
}


const RenderSummuryComponent = ({ title, value }: { title: string, value: string | number }) => {
  return <div className={`border border-primary rounded-lg p-3`}>
    <h4 className=" font-medium text-gray-600text-lg uppercase"> {title} </h4>
    <p className=" text-4xl font-bold text-gray-400 mt-3">
      {value}
    </p>
  </div>
}



function Dashboard() {
  const navigate = useNavigate();
  const { isNewUser, user: { comapnyName }, isVerified } = useSelector((state: RootState) => state.login)

  const [kys_status, setkys_status] = useState<ENUM_KYC_STATUS>()

  const [quotaSummury, setquotaSummury] = useState<dashBoardSummury>({
    approved_quota: 0,
    utilized_quota: 0,
    un_utilized_quota: 0,
    total_employees: 0,
    paid_employees: 0,
    incomplete_employees: 0,

  });

  useEffect(() => {
    fetchCompanyStatus()
    fetchQuotaDetails();
    return () => { };
  }, []);

  const fetchCompanyStatus = async () => {
    try {
      const apiResp = await getCompnayKycStatus();
      // console.log({ apiResp })
      setkys_status(apiResp.data?.kyc_status)

    } catch (error) {

    }
  }

  const fetchQuotaDetails = async () => {
    try {
      const apiResp = await getQuotaSummury();
      if (apiResp && apiResp.status == 200) {
        if (apiResp.quotadata) {
          const data = apiResp.quotadata;

          let newQuotaSummury = {
            ...quotaSummury,
            approved_quota: data?.slotsApproved || 0,
            utilized_quota: data?.utilizedquota || 0,
            un_utilized_quota: data?.un_utilized_quota || 0,
            total_employees: data?.totalemployee || 0,
            paid_employees: data?.employeecomplete || 0,
            incomplete_employees: data?.employeepending || 0
          }

          setquotaSummury(newQuotaSummury);
        }

      }
    } catch (e) { }
  };


  const progress = useMemo(() => isNewUser ? 40 : 100, [isNewUser])
  const employeProgress = useMemo(() => {
    if (quotaSummury.approved_quota && quotaSummury.utilized_quota) {
      return Number(((Number(quotaSummury.utilized_quota) / Number(quotaSummury.approved_quota)) * 100).toFixed(2))
    }
    else return 0
  }, [quotaSummury.approved_quota, quotaSummury.utilized_quota])

  // console.log({ employeProgress });

  const kycStatusIcon = (status: string | undefined) => {
    if (status == ENUM_KYC_STATUS.APPROVED) {
      return <div className="rounded-full space-x-1 border border-primary px-4 flex items-center py-1">
        <span className="text-xs text-primary">Verified</span><ShieldCheck className="text-primary" size={16} />
      </div>
    }
    else if (status == ENUM_KYC_STATUS.REJECTED) {
      return <div className="rounded-full space-x-1 border border-destructive px-4 flex items-center py-1">
        <span className="text-xs text-destructive">Rejected</span><ShieldOff className="text-destructive" size={16} />
      </div>
    }
    else if (status == ENUM_KYC_STATUS.PENDING) {
      return <Badge className="text-xs" variant={"secondary"}>Pending</Badge>
    }
    else return <Badge className="text-xs" variant={"secondary"}>Pending</Badge>
  }

  return (
    <div className="">
      <div className=" flex justify-between">
        <div className="border-l-2  border-primary  px-2">
          <span className="">Welcome</span> <span className="text-primary uppercase">{comapnyName} !</span>
        </div>
        <div className="flex  space-x-2">
          <span >KYC Status</span>
          {/* {kys_status ?
            <div className="rounded-full space-x-1 border border-primary px-4 flex items-center py-1">
              <span className="text-xs text-primary">Verified</span><ShieldCheck className="text-primary" size={16} />
            </div> :
            <div className="rounded-full space-x-1 border border-destructive px-4 flex items-center py-1">
              <span className="text-xs text-destructive">Un-Verified</span><ShieldOff className="text-destructive" size={16} />
            </div>
          } */}
          {kycStatusIcon(kys_status)}
        </div>
      </div>
      <div className="flex border border-primary rounded-lg bg-secondary my-5 py-3">
        <div className="grow m-6">
          <div className="flex items-center">
            {" "}
            <h1 className="text-lg uppercase font-medium">Company Details </h1>
            <span className="ml-2">
              <SVGC
                path={toAbsoluteUrl(progress == 100 ? "/media/icons/svg/tick-circle.svg" : "/media/icons/svg/tick-circle-gray.svg")}
              ></SVGC>
            </span>
          </div>
          <p className="text-xs mt-2">
            Provide the complete details about your company.
          </p>

          <Progress className="my-2 h-1.5 " value={progress} />


        </div>
        <div className="grow m-6">
          <div className="flex items-center">
            <h1 className="text-lg uppercase font-medium">
              Employee/Expatriate details
            </h1>
            {/* <span className="ml-2">
              <SVGC
                path={toAbsoluteUrl("/media/icons/svg/tick-circle-gray.svg")}
              ></SVGC>
            </span> */}
          </div>
          <p className="text-xs mt-2">
            Provide the complete Employee/Expatriate details
          </p>
          {/* <div className="w-full h-2 bg-gray-300 rounded-md mt-3">
            <div className="w-2/5 h-full bg-primary"></div>
          </div> */}
          <Progress value={employeProgress} className="my-2 h-1.5" />
        </div>
        <div className="grow m-6">
          <div className=" flex justify-center items-center">
            <div>
              <p className="text-xs mb-2">Overall profile completed {progress}%.</p>
              <Button
                onClick={() => navigate("/company-profile")}
                className="mx-auto w-full"
              >
                Complete & Apply
              </Button>
            </div>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-3 gap-3" >
        <RenderSummuryComponent title="Quota Approved" value={quotaSummury.approved_quota || 0} />
        <RenderSummuryComponent title="Quota Utilized" value={quotaSummury.utilized_quota || 0} />
        <RenderSummuryComponent title="Quota Un-Utilized" value={quotaSummury.un_utilized_quota} />
        <RenderSummuryComponent title="Total Employee" value={quotaSummury.total_employees} />
        <RenderSummuryComponent title="Active Employees" value={quotaSummury.paid_employees} />
        <RenderSummuryComponent title="Incomplete Employees Profile" value={quotaSummury.incomplete_employees} />
      </div>


      {/* <div className="flex justify-between rounded-lg">

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Quota Approved
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {quotaSummury.quotaPositions}
          </p>
        </div>

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Quota Utilized
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {quotaSummury.utilizedQuota}
          </p>
        </div>

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Quota Un-Utilized
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {Number(quotaSummury.quotaPositions) -
              Number(quotaSummury.utilizedQuota)}
          </p>
        </div>
      </div>
      <div className="flex justify-between rounded-lg">

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Total Employee
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {quotaSummury.quotaPositions}
          </p>
        </div>

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Active Employee
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {quotaSummury.utilizedQuota}
          </p>
        </div>

        <div
          className={`border border-primary rounded-lg flex-grow ${"mx-3"} p-3`}
        >
          <h4 className=" font-medium text-gray-600text-lg uppercase">
            Incomplete Eployee
          </h4>
          <p className=" text-4xl font-bold text-gray-400 mt-3">
            {Number(quotaSummury.quotaPositions) -
              Number(quotaSummury.utilizedQuota)}
          </p>
        </div>

      </div> */}
    </div>
  );
}

export default Dashboard;