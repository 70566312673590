import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { IQuotaSetupForm, QuotaSetupFormFileds, QuotaSetupForm_INIT, QuotaSetupForm_Schema, tabelHeaders } from '../module'
import RenderFormFiled from 'src/components/Common/RenderFormFiled'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import PageHeadingComponent from 'src/components/Common/PageHeadingComponent'

import { Button } from 'src/components/ui/button'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import { uploadfile } from 'src/services/services'
import { useDispatch } from 'react-redux'
import { createQuotaAsync, resetError, resetState, setActiveTab } from 'src/Silcers/CompanyQuota/createQuotaSetupSlicer'
import LoadingDialog from 'src/components/LoadingDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import { useNavigate } from 'react-router-dom'
import { Loader2 } from 'lucide-react'
import TableComponent from 'src/components/Common/TableComponent'
import { Label } from 'src/components/ui/Label'
import { checkIfAnyFilesToBeUpload } from 'src/Helpers'
import { format } from 'date-fns'
import { cn } from 'src/lib/utils'



type Props = {

}

const RenderFiled = ({ formOptions: { key, label, description }, value, }: { formOptions: { label: string, key: string, description?: string }, value: string | number | undefined }) => {
    return (
        <div>
            <Label className="uppercase">{label}</Label>
            {description && <div className=' text-xs text-gray-600'>{description}</div>}
            <p className=" sub-style text-sm mt-1 text-gray-600">
                {
                    value && value ?
                        ["expirationDate", "approvedDate", "approvalLetterDate"].includes(key) ?
                            <>
                                {value && typeof value == "string" && !value.includes("/") && format(new Date(value), "dd/MM/yyyy")}
                                {value && typeof value == "string" && value.includes("/") && value}
                            </>
                            : <>
                                {typeof value == "string" || typeof value == "number" ? value : "-"}
                            </>
                        : null
                }

            </p>
        </div>
    )
}

const SetupFormReview = ({ }: Props) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { quotaDetails, isLoading, error, message, status } = useSelector((state: RootState) => state.createQuotaSetup)
    const formData = useMemo(() => quotaDetails, [quotaDetails])
    const [uploadingFileStatus, setuploadingFileStatus] = useState<{
        isLoading: boolean,
        isError: boolean,
        message: string | undefined
    }>({
        isLoading: false,
        isError: false,
        message: undefined
    });
    const [uploadedFileStatus, setuploadedFileStatus] = useState<{
        totalFiles: number,
        uploadedFiles: number,
    }>({
        totalFiles: 0,
        uploadedFiles: 0,
    });


    useEffect(() => {
        if (status == 201) {
            dispatch(resetState())
            navigate('/quota')
        }
    }, [status])

    const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
        setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
        if (isError) {
            setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
            dispatch(resetError())
            return
        }
    };

    const onSubmit = async () => {
        setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
        let newFormData = await checkIfAnyFilesToBeUpload(formData, uploadProgress)
        if (newFormData) {
            setTimeout(() => {
                setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
                dispatch(createQuotaAsync(newFormData))
            }, 500);
        }
    }

    // console.log({ uploadedFileStatus });


    return (
        <>
            {/* Headers */}
            <div className='px-4 py-2 font-semibold text-primary uppercase'>Quota Setup Review</div>
            {/* Quota Setup form  */}
            <div className="p-7 grid grid-cols-2 gap-3">
                <div className='space-y-2'>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.approvalNumber} value={formData.approvalNumber} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.expatriateType} value={formData.expatriateType} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.expirationDate} value={formData.expirationDate} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.totalSlotApproved} value={formData.totalSlotApproved} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.approvedDate} value={formData.approvedDate} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.approvalLetterDate} value={formData.approvalLetterDate} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.expatriate_doc} value={formData.expatriate_doc instanceof File ? formData.expatriate_doc.name : formData.expatriate_doc} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.additional_name} value={formData.additional_name} /></>
                    <><RenderFiled formOptions={QuotaSetupFormFileds.additional_doc} value={formData.additional_doc instanceof File ? formData.additional_doc.name : formData.additional_doc} /></>
                </div>
                <div>
                    <TableComponent
                        headers={tabelHeaders}
                        data={formData.position || []}
                        showSRNO={true}
                    />
                </div>
            </div>
            <div className='flex justify-center'>
                <Button variant={'secondary'} className="mb-2 px-10 w-44 mx-5" onClick={() => dispatch(setActiveTab(1))} >Edit</Button>
                <Button
                    disabled={uploadingFileStatus.isLoading || isLoading}
                    variant={error ? "secondary" : "default"}
                    className="mb-2 px-10 w-44 mx-5"
                    onClick={onSubmit}
                >
                    {(isLoading || uploadingFileStatus.isLoading) && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Continue
                </Button>
            </div>
            <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
                <div className="flex flex-col w-full space-y-2">
                    <div className="text-center">
                        {isLoading ? "Sending Requests" : null}
                        {uploadingFileStatus.isLoading ? "Uploading Files" : null}
                    </div>
                    {uploadingFileStatus.isLoading && <div className="flex space-x-1">
                        {
                            uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
                            })
                        }
                    </div>}
                </div>
            </LoadingDialog>
            <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(resetError())} />
            <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => onSubmit()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
                setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
            }} />

        </>
    )
}

export default SetupFormReview