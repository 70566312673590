import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "src/components/ui/checkbox";

import { Task } from "../data/schema";

import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { formatAmt } from "src/Helpers";

export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "user_name",
    header: ({ column }) => (
      // <DataTableColumnHeader className="" column={column} title="Name" />
      <div className={cn("flex items-center space-x-2")}>
        <Button
          variant="ghost"
          size="sm"
          className="-ml-3 h-8 data-[state=open]:bg-accent"
        >
          <span>Name</span>
        </Button>
      </div>
    ),
    cell: ({ row }) => {
      // console.log({row});

      return (
        <div className="flex space-x-2">
          <div>
            <Avatar>
              <AvatarImage
                src={row.original.employee_profile_photo}
                alt="@shadcn"
              />
              <AvatarFallback>
                <span className="uppercase">
                  {row.original.givenname[0] + row.original.surname[0]}
                </span>
              </AvatarFallback>
            </Avatar>
          </div>
          <div className="flex flex-col">
            <div>{row.original.surname} {row.original.givenname}</div>
            <div className="text-gray-500 capitalize">
              {row.original.sex}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    accessorKey: "dollers",
    header: ({ column }) => (
      <div className={cn("flex items-center space-x-2")}>
        <Button
          variant="ghost"
          size="sm"
          className="-ml-3 h-8 data-[state=open]:bg-accent"
        >
          <span>($) PAYABLE AMOUNT OF LEVY</span>
        </Button>
      </div>
      // <DataTableColumnHeader className="" column={column} title="Amounts" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex  flex-col justify-start">
          <span>$ {row.original.amount_dollor && formatAmt(row.original.amount_dollor)}</span>
          <span>₦ {formatAmt(row.original.amount_niara)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },

  // {
  //   id: "actions",
  //   cell: ({ row }) => <DataTableRowActions row={row} />,
  // },
];
