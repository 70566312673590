import { useMemo } from 'react'
import { columns } from './components/columns'
import { DataTable } from './components/data-table'
import { useQueryResponseData } from './core/QueryResponseProvider'
import { useDispatch } from 'react-redux'

type Props = {
}

const DataTableMain = ({  }: Props) => {

    const dispatch = useDispatch()
    const apiRespData = useQueryResponseData()
    let data = useMemo(() => apiRespData, [apiRespData,dispatch,])

    return (
        <DataTable data={data} columns={columns} />
    )
}

export default DataTableMain