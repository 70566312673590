import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const taskSchema = z.object({
  surname: z.any(),
  given_name: z.any(),
  accompany_with: z.any(),
  relationship: z.any(),
  dob: z.any(),
  sex: z.any(),
  passport_no: z.any(),
  passport_expiry: z.any(),
  passport_doc: z.any(),
  cerpac_no: z.any(),
  cerpac_issue: z.any(),
  cerpac_expiry: z.any(),
  visa_type: z.any(),
  visa_number: z.any(),
  visa_expiry: z.any(),
  visa_issue: z.any(),
  dependent_profile_image: z.any(),
  _id: z.any(),
  employee_id: z.any(),
  employee_payment_status: z.any(),
  card_generated: z.any()
})

export type Task = z.infer<typeof taskSchema>
