import { FC, createContext, useContext, useState } from 'react';
import { WithChildren } from 'src/Helpers/react18MigrationHelpers';

type regsitration = {
    activeTab: number,
    totalTabs: number
}

const initiaRegistrationState = {
    activeTab: 1,
    totalTabs: 4
}

type RegisterContextProps = {
    state: regsitration,
    updateState: (updates: Partial<regsitration>) => void
}

export const initialRegisterContextPropst: RegisterContextProps = {
    state: initiaRegistrationState,
    updateState: () => { },
}

const RegistrationMainContext = createContext<RegisterContextProps>(initialRegisterContextPropst)

const RegistrationMainContextProvider: FC<WithChildren> = ({ children }) => {
    const [state, setState] = useState<regsitration>(initiaRegistrationState)

    const updateState = (updates: Partial<regsitration>) => {
        const updatedState = { ...state, ...updates }
        setState(updatedState)
    }
    return (
        <RegistrationMainContext.Provider value={{ state, updateState }}>
            {children}
        </RegistrationMainContext.Provider>
    )
}

const useRegistrationMainContext = () => useContext(RegistrationMainContext)
export { RegistrationMainContextProvider, useRegistrationMainContext };
