import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../Helpers'
import { getAuth, removeAuth } from "../../Modules/Auth/Core/AuthHelpers";
import React, { useEffect, useState } from 'react'
import { PersonIcon } from "@radix-ui/react-icons";
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Select } from '@radix-ui/react-select';
import { SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { UserModel, useAuth } from 'src/Modules/Auth/Core';
import { SVGC } from 'src/Helpers/SVGC';

type Props = {

}

const HeaderComponent = ({  }: Props) => {
    const navigate = useNavigate()
    return (
        <div className='flex justify-between my-2 mx-4'>
            <div>
                {/* <SVGC path={toAbsoluteUrl('/media/svg/ministry.svg')} className=' w-[150px] h-[150px] mt-[5px] mr-2' ></SVGC> */}
                <img src={toAbsoluteUrl('/media/images/ministry.png')} className='w-[110px]' />
            </div>
            <div>
                <img src={toAbsoluteUrl('/media/logo/eel_logo.png')} className='w-[80px]' />
            </div>
            <div>
                {/* <SVGC path={toAbsoluteUrl('/media/svg/ministry.svg')} className=' w-[150px] h-[150px] mt-[5px] mr-2' ></SVGC> */}
                <img src={toAbsoluteUrl('/media/images/immigration.png')} className='w-[110px]' />
            </div>
        </div>
    )
}

export default HeaderComponent