/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  PaginationState,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery
} from 'src/Helpers'
import { useQueryRequest } from './QueryRequestProvider'

import axios, { AxiosError } from 'axios'
import { getAllCardUnAssignedEmployeesRequestApi } from 'src/services/services'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { getAssigneEmployeeToEmptyCardReducerState } from 'src/Silcers/assigneEmployeeToEmptyCardSlicer'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const { selectedDesignation } = useSelector((state: RootState) => getAssigneEmployeeToEmptyCardReducerState(state))

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])

  useEffect(() => {
    if (selectedDesignation) {
      updateState({
        filter: {
          position: selectedDesignation
        }
      })
    }
  }, [selectedDesignation])

  const {
    isFetching,
    refetch,
    data: response,
    isError,
    error
  } = useQuery(
    `UN-ASSIGNED-EMPLOYEES-ALL-${query}`,
    async () => {
      return getAllCardUnAssignedEmployeesRequestApi(query.replace("page=1&items_per_page=30&",""));
    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }
  )

  let message = useMemo(() => {
    if (isError) {
      let err = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        return err.response?.data?.message
      }
      else return err.message
    }
    else return ""
  }, [error, isError])


  console.log({ response });


  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, isError, message }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
}

