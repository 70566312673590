import * as yup from 'yup'

export interface IRenewQuota {
    selected_position_id:string,
    position: string,
    quota_expiry: string,
    quotaId: string,
}

export const INIT_RENEW_qUOTA_FORM = {
    selected_position_id:"",
    position: "",
    quota_expiry: "",
    quotaId: "",
}


export const renewQuotaValidationSchema = yup.object({
    position: yup.string().required(),
    quota_expiry: yup.string().required(),
    quotaId: yup.string().required(),
})

export const RENEW_QUOTA_FORM_FILEDS = {
    selected_position_id: {
        key: "selected_position_id",
        label: "Quota Position",
        field_type: "options_with_search",
        options: [],
        placeholder: "Quota Position",
    },
    quota_expiry: {
        key: "quota_expiry",
        label: "Quota Expiry",
        field_type: "text",
        placeholder: "Quota Expiry",
        readonly: true
    },
    quotaId: {
        key: "quotaId",
        label: "Quota ID",
        field_type: "options_with_search",
        options: [],
        placeholder: "Quota Position",
    }
}
