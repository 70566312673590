import { Card, CardContent } from "src/components/ui/card";
import { useRegistrationMainContext } from "../RegistrationMainContext";
import StepFourDone from "./steps/StepFourDone";
import StepOneComp from "./steps/StepOneComp";
import StepThreeDetail from "./steps/StepThreeDetail";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import EllOverview from "src/Modules/ReadMore/EllOverview";
import Requirements from "src/Modules/ReadMore/Requirements";
import LevyDetails from "src/Modules/ReadMore/LevyDetails";
import { StepTwoRegForm } from "./steps/StepTwoRegForm";

type Props = {};

const Forms = (props: Props) => {
  const { activeTab } = useSelector(
    (state: RootState) => state.company_registration
  );

  // const { state: { activeTab, totalTabs } } = useRegistrationMainContext();

  return (
    <Card>
      <CardContent className="py-2">
        {activeTab == 1 ? <StepOneComp /> : null}
        {activeTab == 2 ? <EllOverview /> : null}
        {activeTab == 3 ? <Requirements /> : null}
        {activeTab == 4 ? <LevyDetails /> : null}
        {activeTab == 5 ? <StepTwoRegForm /> : null}
        {activeTab == 6 ? <StepThreeDetail /> : null}
        {activeTab == 7 ? <StepFourDone /> : null}
      </CardContent>
    </Card>
  );
};

export default Forms;
