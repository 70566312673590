import { ShieldAlert, ShieldClose, ShieldOffIcon } from "lucide-react";
import React from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../ui/dialog";
import { Button } from "../ui/button";

type Props = {
    isOpen: boolean;
    onCancelClick?: () => void;
    message?: string;
};

const InformDialog = ({ isOpen, onCancelClick, message }: Props) => {
    return (
        <Dialog open={isOpen}
            onOpenChange={
                () => { }
            }>
            <DialogContent hideClose>
                <DialogHeader>
                    <DialogTitle></DialogTitle>
                    <DialogDescription>
                        <div className="flex flex-col items-center">
                            <ShieldOffIcon  size={24}
                                className="text-indigo-500" />
                            <div className="flex space-x-2 items-center ">
                                <div className="text-indigo-500 text-lg font-bold">INFORM</div>
                            </div>
                            <div className="my-2">
                                {message}</div>
                            <Button variant="secondary" className="focus:outline-none"
                                onClick={onCancelClick}>
                                Continue
                            </Button>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

export default InformDialog;
