import { GENDER_OPTIONS, RELATIONS_OPTIONS, VISA_TYPES_OPTIONS } from "src/Helpers";
import * as yup from "yup";

export type IDependant = {
    surname: string | undefined;
    given_name: string | undefined;
    accompany_with: string | undefined;
    relationship: string | undefined;
    dob: string | undefined;
    sex: string | undefined;
    passport_no: string | undefined;
    passport_expiry: string | undefined;
    passport_doc: string | undefined | any;
    cerpac_no: string | undefined;
    cerpac_issue: string | undefined;
    cerpac_expiry: string | undefined;
    // visa_type: string | undefined;
    // visa_number: string | undefined;
    // visa_expiry: string | undefined;
    // visa_issue: string | undefined;
    dependent_profile_image: any;
    employee_id?: string
};

function isValidDate(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

const errorMessages = {
    surname: "Surname is required.",
    given_name: "Given Name is required.",
    accompany_with: "Accompany with is required.",
    relationship: "Relationship is required.",
    dob: "Date of Birth is required.",
    sex: "Sex is required.",
    passport_no: "Passport number is required",
    passport_expiry: "Passport Expiry is required.",
    passport_doc: "Please upload passport documents",
    cerpac_no: "CERPAC Number is required.",
    cerpac_issue: "CERPAC Issue Date is required.",
    cerpac_expiry: "CERPAC Expiry Date is required.",
    // visa_type: "Visa Type is required.",
    // visa_number: "Visa Number is required.",
    // visa_expiry: "Select Visa expiry date",
    // visa_issue: "Select Issue Visa date",
    dependent_profile_image: "Dependent Profile Image is required.",
    special_characters_error: "Special characters are not allowed"
};


export const createDependantSchema = yup.object().shape({
    surname: yup.string().min(3, "Should not be allowed less than 3 characters").max(30).matches(/^([a-zA-Z _-]+)$/, { message: errorMessages.special_characters_error }).required(errorMessages.surname),
    given_name: yup.string().min(3, "Should not be allowed less than 3 characters").max(30).matches(/^([a-zA-Z ]+)$/, { message: errorMessages.special_characters_error }).required(errorMessages.given_name),
    accompany_with: yup.string().required(errorMessages.accompany_with),
    relationship: yup.string().required(errorMessages.relationship),
    dob: yup.string().required(errorMessages.dob).test('dob', (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value) {
            return createError({ message: "Please select date of birth" });
        }

        if (value && inputDate && inputDate > currentDate) {
            return createError({ message: "Date of Birth can't accept future date" });
        }

        return true;
    }),
    sex: yup.string().required(errorMessages.sex),
    passport_no: yup.string().min(8, "It should at least 8 digits").max(12, "It max 12 digits").required(errorMessages.passport_no),
    passport_expiry: yup.string().required("Select passport expiry").test('passport_expiry', (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && passport_no) {
            return createError({ message: errorMessages.passport_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Can not accept past date" });
        }

        return true;
    }),
    passport_doc: yup.mixed().test("passport_doc", (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        // Check if the value is a File object

        // console.log({ value, type: typeof value });


        if (!value && passport_no) {
            return createError({ message: "Please upload passport" });
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document should not be more than 5 MB" + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        // if (!(value instanceof File)) {
        //     return createError({ message: "Please select valid file" });
        // }

        // if (typeof value == "string") {
        //     return true
        // }

        return true;
    }).required(errorMessages.passport_doc),
    cerpac_no: yup.string().optional().test('cerpac_issue', (value, validationcontext) => {
        const { createError, parent: { dob, visa_type } } = validationcontext;
        const inputDate = value && new Date(value);
        var _dob = new Date(dob);
        var today = new Date();
        var eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

        // if (!value && dob) {
        //     return createError({ message: errorMessages.cerpac_no });
        // }

        if (!value && ((dob && _dob < eighteenYearsAgo))) {
            return createError({ message: errorMessages.cerpac_no });
        }

        if (value && dob && _dob < eighteenYearsAgo && value.length < 8) {
            return createError({ message: "It must be 8 characters " });
        }

        return true;
    }),
    // cerpac_issue: yup.string().matches(/^([0-9/-]+)$/, { message: "Invalid issue date" }).optional().test('cerpac_issue', (value, validationcontext) => {
    //     const { createError, parent: { cerpac_no, visa_type } } = validationcontext;
    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();

    //     if (!value && ((cerpac_no && cerpac_no !== "MINOR") && visa_type !== "Residence Visa")) {
    //         return createError({ message: errorMessages.cerpac_expiry });
    //     }

    //     if (value && !isValidDate(value)) {
    //         return createError({ message: "Invalid Date" });
    //     }

    //     if (value && inputDate && inputDate >= currentDate) {
    //         return createError({ message: "Can not accept future date" });
    //     }

    //     return true;
    // }),
    cerpac_expiry: yup.string().optional().test('cerpac_expiry', (value, validationcontext) => {
        const { createError, parent: { cerpac_no, passport_expiry, visa_type } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();
        const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;

        if (!value && ((cerpac_no && cerpac_no !== "MINOR"))) {
            return createError({ message: errorMessages.cerpac_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Can not accept past date" });
        }
        else if (value && cerpac_no !== "MINOR" && pasportDate && inputDate && inputDate > pasportDate) {
            return createError({ message: "Expiry date should not be greater than passport expiry date." });
        }


        return true;
    }),
    // visa_type: yup.string().required(errorMessages.visa_type),
    // visa_number: yup.string().min(8, "Visa number at least 8 digits").max(10, "Visa number maximum 10 digits").test('visa_expiry', (value, validationcontext) => {
    //     const { createError, parent: { visa_type } } = validationcontext;

    //     if (!value && visa_type !== "Residence Visa") {
    //         return createError({ message: errorMessages.visa_number });
    //     }

    //     return true;
    // }),
    // visa_expiry: yup.string().test('visa_expiry', (value, validationcontext) => {
    //     const { createError, parent: { visa_number, visa_type } } = validationcontext;
    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();

    //     if (!value && (visa_number)) {
    //         return createError({ message: errorMessages.visa_expiry });
    //     }

    //     if (value && inputDate && inputDate <= currentDate) {
    //         return createError({ message: "Can not accept past date" });
    //     }

    //     return true;
    // }),
    // visa_issue: yup.string().required(errorMessages.visa_issue).test('visa_issue', (value, validationcontext) => {
    //     const { createError, parent: { visa_number } } = validationcontext;
    //     const inputDate = value && new Date(value);
    //     const currentDate = new Date();

    //     if (!value && visa_number) {
    //         return createError({ message: errorMessages.visa_issue });
    //     }

    //     if (value && !isValidDate(value)) {
    //         return createError({ message: "Invalid Date" });
    //     }

    //     if (value && inputDate && inputDate >= currentDate) {
    //         return createError({ message: "Can not accept future date" });
    //     }

    //     return true;
    // }),
    dependent_profile_image: yup.mixed().test("dependent_profile_image", "Invalid file type", (value, validationcontext) => {
        const { createError } = validationcontext;
        if (!value) {
            return createError({ message: "Plese select profile photo " });
        }
        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 2) {
            return createError({ message: "Invalid or unsupported image. Accepted formats: PNG, JPG, JPEG (up to 2 MB).." });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg"].includes(value.type)) {
            return createError({ message: "Invalid or unsupported image. Accepted formats: PNG, JPG, JPEG (up to 2 MB).." });
        }
        return true;
    }).required("Select profile photo"),
});


export const INIT_Dependant = {
    surname: "",
    given_name: "",
    accompany_with: "",
    relationship: undefined,
    dob: "",
    sex: undefined,
    passport_no: "",
    passport_expiry: "",
    passport_doc: null,
    cerpac_no: "",
    cerpac_issue: "",
    cerpac_expiry: "",
    // visa_type: undefined,
    // visa_number: "",
    // visa_expiry: "",
    // visa_issue: "",
    dependent_profile_image: undefined,
};

export const INIT_Dependant_VALUES = {
    surname: "Doe",
    given_name: "John",
    accompany_with: "Jane Doe",
    relationship: undefined,
    dob: "1990-01-01", // Date of Birth in yyyy-mm-dd format
    sex: undefined,
    passport_no: "A1234567",
    passport_expiry: "2025-12-31", // Passport Expiry Date in yyyy-mm-dd format
    passport_doc: undefined, // Passport Document File Name
    cerpac_no: "C9876543",
    cerpac_issue: "2022-03-15", // CERPAC Issue Date in yyyy-mm-dd format
    cerpac_expiry: "2024-03-15", // CERPAC Expiry Date in yyyy-mm-dd format
    // visa_type: undefined,
    // visa_number: "4111111111111111",
    // visa_expiry: "2023-06-30", // Visa Expiry Date in yyyy-mm-dd format
    // visa_issue: "2023-06-15", // Visa Issue Date in yyyy-mm-dd format
    dependent_profile_image: "profile.jpg", // Profile Image File Name
};


const getYearFromNextTwoYear = () => {
    var year = new Date().getFullYear()
    return year + 2
}

export const createDependentFields = {

    surname: {
        key: "surname",
        label: "Surname",
        field_type: "text",
        field_mode: "text",
        placeholder: "Surname",
        regex: /[^A-Za-z]+/g,
        maxLength: 30
    },
    given_name: {
        key: "given_name",
        label: "Given Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Given Name",
        regex: /[^A-Za-z ]+/g,
        maxLength: 30
    },
    accompany_with: {
        key: "accompany_with",
        label: "Accompany With",
        field_type: "text",
        field_mode: "text",
        placeholder: "Accompany With",
        regex: /[^A-Za-z ]+/g,
        readonly: true
    },
    relationship: {
        key: "relationship",
        label: "Relationship",
        field_type: "options",
        options: RELATIONS_OPTIONS,
        placeholder: "Relationship"
    },
    dob: {
        key: "dob",
        label: "Date of Birth",
        field_type: "date",
        field_mode: "text",
        placeholder: "Date of Birth"
    },
    sex: {
        key: "sex",
        label: "Sex",
        field_type: "options",
        options: GENDER_OPTIONS,
        placeholder: "Sex"
    },
    passport_no: {
        key: "passport_no",
        label: "Passport Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Passport Number",
        maxLength: 12,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true
    },
    passport_expiry: {
        key: "passport_expiry",
        label: "Passport Expiry",
        field_type: "date",
        field_mode: "text",
        placeholder: "Passport Expiry"
    },
    passport_doc: {
        key: "passport_doc",
        label: "Passport Document",
        field_type: "file",
        field_mode: "text",
        placeholder: "Passport Document"
    },
    cerpac_no: {
        key: "cerpac_no",
        label: "CERPAC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "CERPAC Number",
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true
    },
    cerpac_issue: {
        key: "cerpac_issue",
        label: "CERPAC Issue",
        field_type: "date",
        field_mode: "text",
        placeholder: "CERPAC Issue"
    },
    cerpac_expiry: {
        key: "cerpac_expiry",
        label: "CERPAC Expiry",
        field_type: "date",
        field_mode: "text",
        placeholder: "CERPAC Expiry",
        toYear: getYearFromNextTwoYear(),
        fromYear:new Date().getFullYear()
    },
    // visa_type: {
    //     key: "visa_type",
    //     label: "Visa Type",
    //     field_type: "options",
    //     options: VISA_TYPES_OPTIONS,
    //     placeholder: "Visa Type"
    // },
    // visa_number: {
    //     key: "visa_number",
    //     label: "Visa Number",
    //     field_type: "text",
    //     field_mode: "text",
    //     placeholder: "Visa Number",
    //     maxLength: 10,
    //     regex: /[^A-Za-z0-9]+/g,
    //     blockletters: true
    // },
    // visa_expiry: {
    //     key: "visa_expiry",
    //     label: "Visa Expiry",
    //     field_type: "date",
    //     field_mode: "text",
    //     placeholder: "Visa Expiry"
    // },
    // visa_issue: {
    //     key: "visa_issue",
    //     label: "Visa Issue",
    //     field_type: "date",
    //     field_mode: "text",
    //     placeholder: "Visa Issue"
    // }
}

