export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1"
export const FILE_UPLOAD_BASE_URL = process.env.REACT_APP_FILE_UPLOAD_BASE_URL + "/api/v1"

export const LOGIN_URL = BASE_URL + '/login'


// ===========================================================================================
// ||                                   COMPANY                                             ||
// ===========================================================================================  
export const URL_UPDATE_COMPANY_KYC_DETAILS = BASE_URL + "/company/kyc/update"


// ===========================================================================================
// ||                                   EMPLOYEE                                             ||
// ===========================================================================================  
export const URL_UPDATE_EMPLOYEE_KYC_DETAILS = BASE_URL + "/employee/kyc/update"


export const REGISTRATION_URL = BASE_URL + '/register'
export const EMAIL_VALIDATE_URL = BASE_URL + '/emailvalidate'
export const FORGOT_PASSWORD_URL = BASE_URL + '/forgetpassword'
export const FORGOT_PASSWORD_AFTER_LOGIN = BASE_URL + '/resetauthpasswsord'
export const GET_USER_DETAILS_URL = BASE_URL + '/getuser'
export const UPLOAD_FILES_URL = FILE_UPLOAD_BASE_URL + '/upload'
export const GET_FILE_URL = FILE_UPLOAD_BASE_URL
// export const UPLOAD_FILES_BY_EMAILURL = FILE_UPLOAD_BASE_URL + '/upload'
// export const UPLOAD_FILES_BY_EMAILURL = BASE_URL + '/uploaddocument'
export const COMPANY_PROFILE_URL = BASE_URL + '/createcompany'
export const COMPANY_PROFILE_UPDATE = BASE_URL + "/updatecompany"
export const GET_COMPANY_PROFILE_DETAILS = BASE_URL + "/getcompany"
export const CREATE_EMPLOYEE_URL = BASE_URL + "/createemployee"
export const CREATE_DEPENDENT_URL = BASE_URL + "/createdependent"
export const UPDATE_DEPENDENT_DETAILS_URL = BASE_URL + "/updatedependent"
export const GET_DEPENDENT_DETAILS_URL = BASE_URL + "/getdependentbyid"
export const GET_ALL_EMPLOYEES_URL = BASE_URL + "/getallemployee"
export const GET_EMPLOYEES_DETAILS_URL = BASE_URL + "/getemployee"

export const CONTACT_US_POST_DETAILS = BASE_URL + "/contactus"

export const GET_COMPLETE_EMPLOYEES_DETAILS_URL = BASE_URL + "/getcompleteemployee"
export const GET_INCOMPLETE_EMPLOYEES_DETAILS_URL = BASE_URL + "/getincompleteemployee"
export const GET_PAID_EMPLOYEES_DETAILS_URL = BASE_URL + "/getpaidemployee"
export const GET_AMT_OF_SELECTED_EMPLOYEES_URL = BASE_URL + "/employee/amount"

export const GET_EMPLOYEES_DEPENDENTS_URL = BASE_URL + "/getdependent"
export const UPDATE_EMPLOYEES_DETAILS_URL = BASE_URL + "/updateemployee"
export const COMPLETE_EMPLOYEES_PROFILE_URL = BASE_URL + "/completecsvprifile"
export const GET_ALL_COUNTRIES_URL = BASE_URL + "/country"
export const GET_ALL_CITIES_URL = (state: string) => BASE_URL + `/city/${state}`
export const GET_ALL_STATES_URL = (country: string) => BASE_URL + `/state/${country}`
export const CURRUNCY_CONVERSION_URL = "https://v6.exchangerate-api.com/v6/cb4e26acf9b8bdcc95cb6a6d/pair/USD/NGN"
export const CREATE_PAYMENT_URL = BASE_URL + "/createpayment"
export const SEND_PAYMENT_STATUS_URL = BASE_URL + "/paymentstatus"
export const GET_PAYMENT_LIST_URL = BASE_URL + "/paymentslist"
export const GET_TRANSACTION_URL = BASE_URL + "/paymentdetails"
export const GET_CARD_DETAILS_URL = BASE_URL + "/getcard"
export const GET_DEPENDENT_CARD_DETAILS_URL = BASE_URL + "/getdependentcard"
export const GET_PROFILE_PIC = BASE_URL + "/getprofilepic"
export const GET_DEPENDENT_PROFILE_PIC = BASE_URL + "/getdependentphoto"
export const DELETE_EMPLOYEE_URL = (id: string) => BASE_URL + `/employees/${id}/documents/archive`
export const DELETE_DIRECT_EMPLOYEE_URL = BASE_URL + "/deleteemployee"
export const GET_QUOTA_SUMMURY_URL = BASE_URL + "/quota"
export const GET_PROFILE_URL = BASE_URL + "/getprofilepic"
export const VERIFY_EMAIL_URL = BASE_URL + "/checkemail"
export const GENERATE_DEPENDENT_CARD_URL = BASE_URL + "/dependentcard"
export const VERIFY_RC_NUMBER_URL = "https://ecitibiz.interior.gov.ng/Api/Expatriate/CompanyDetails?RCNumber=RC-"
export const FORGOT_PASSWORD_REQUEST_URL = BASE_URL + "/createrequest"
export const UPLOAD_CSV_URL = BASE_URL + "/uploadcsv"
export const VERIFY_EMAIL_BY_TOKEN_URL = BASE_URL + "/emailverified"
export const VERIFY_RC_NUMBER_BY_TOKEN_URL = BASE_URL + "/verifiedrcnumber"
export const RESEND_VERIFY_EMAIL_BY_TOKEN_URL = BASE_URL + "/resendemail"

export const RESET_PASSWORD_URL = BASE_URL + "/admin/setnewpassword"
export const SET_SECURITY_QUATION_ANDPASSWORD_URL = BASE_URL + "/resetforgotpassword"
export const CHECK_EMAIL_VERFICATION_TOKEN_STATUS_URL = BASE_URL + "/emailexpiry"
// Quota 
export const QUOTA_CREATE_URL = BASE_URL + "/createquota"
export const QUOTA_LIST_URL = BASE_URL + "/quotalist"
export const DD_QUOTA_POSITIONS_LIST_URL = BASE_URL + "/quota/position"
export const GET_QUOPTA_DETAILS_URL = BASE_URL + "/quota/quotaview"

// payment apis
export const GENEREATE_RRR_URL = BASE_URL + "/getrrr"
export const GET_PAYMENT_STATUS = BASE_URL + "/getstatus/byrrr"
export const URL_CHECK_PAYMENT_STATUS = BASE_URL + "/paymentstatus"

// check emeployee email
export const VALIDATE_EMEPLOYEE_EMAIL_URL = BASE_URL + "/employee/checkmail"
export const VALIDATE_EMEPLOYEE_CERPAC_URL = BASE_URL + "/employee/checkcerpacknumber"
export const VALIDATE_EMEPLOYEE_PASSPORT_URL = BASE_URL + "/employee/checkpassportnumber"

// CARD -PRINTING REQUEST
export const CREATE_CARD_PRINTING_REQUEST_URL = BASE_URL + "/cards/printing-request/create"

export const ENQUIY_REQUEST_URL = BASE_URL + "/enquiry"
export const COMPANY_KYC_STATUS_URL = BASE_URL + "/getkycstatus"
export const CHANGE_REPRENTATIVE_EMAIL_VALIDATION_URL = BASE_URL + "/validate/email"
export const CHANGE_REPRENTATIVE_FORM_SUBMIT_URL = BASE_URL + "/user/change-representative"
export const GET_ALL_EMPTY_CARDS_REQUESTS_URL = BASE_URL + "/employees/cards/all"

export const GET_ALL_CARD_UN_ASSIGNED_EMPLOYEES = BASE_URL + "/unpaid/employees/all"
export const ASSIGN_EMPLOYEE_TO_EMPTY_CARD_URL = BASE_URL + "/assign/employee/cards"

export const QUOTA_UPDATE_URL = BASE_URL + "/employee/quota/update"
export const EXEMPT_REQUEST_URL = BASE_URL + "/request/exempt/create"