import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type ErrorType = {
    error: null | any,
    status: number,
}
export const initaiState: ErrorType = {
    error: null,
    status: 0,
}

export const errorSlicer = createSlice({
    name: 'auth',
    initialState: initaiState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {            
            state.error = action.payload.data;
            state.status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = null;
            state.status = 0;            
        },
    }
});

export const { setError, resetError } = errorSlicer.actions;

// export const selectAuth = (state: RootState) => state.auth;

export default errorSlicer.reducer;