import { PASSWORD_REGX } from 'src/Helpers'
import * as yup from 'yup'

export const SetNewPasswordFileds = {
    old_password: {
        key: "old_password",
        label: "OLd assword",
        field_type: "password",
        field_mode: "password",
        placeholder: "Password",
    },
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "password",
        placeholder: "Password",
    },
    confirm_password: {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "password",
        placeholder: "Confirm Password",
    },
}

export const SET_NEW_PASSWORD_SCHEMA = yup.object().shape({
    confirm_password: yup.string()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { password } } = validationcontext;
            if (!value) {
                return createError({ message: "Please Enter Confirm Password" });
            }

            if (value && password && value !== password) {
                return createError({ message: "Password not match" });
            }
            return true;
        }),

    password: yup.string()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { } } = validationcontext;
            if (!value) {
                return createError({ message: "Please Enter New Password" });
            }

            if (value && !value.match(PASSWORD_REGX)) {
                return createError({ message: "Password length should be between 8-18 characters, including capital letters, numbers, and special characters. " });
            }
            return true;
        }),
    old_password: yup.string()
        .test('old_password', (value, validationcontext) => {
            const { createError, parent: { } } = validationcontext;
            if (!value) {
                return createError({ message: "Please Enter Old Password" });
            }

            // if (value && !value.match(PASSWORD_REGX)) {
            //     return createError({ message: "Password length should be between 8-18 characters, including capital letters, numbers, and special characters. " });
            // }
            return true;
        }),
})
