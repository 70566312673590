import { VISA_TYPES_OPTIONS, handleApiCall } from "src/Helpers"
import { validateEmployeePassportApi } from "src/services/services";
import * as yup from "yup";

export type IDocumentType = "PASSPORT" | "CERPAC" | "VISA"

export type IcurrentEmployeeDetails = {
    _id: string | undefined,
    passportnumber: string | undefined,
    passportexpiry: string | undefined,
    uploadpassport: string | undefined,
    cerpac_number: string | undefined,
    cerpac_back: string | undefined,
    cerpac_front: string | undefined,
    cerpac_expiry: string | undefined,
    visatype: string | undefined,
    visanumber: string | undefined,
    visaexpiracydate: string | undefined,
    visa_document: string | undefined,
    quota_expiry: string | undefined,
}

export const INIT_UPDATE_EMPLOYEE_KYC_DOCUMENT = {
    passport_no: "",
    passport_expiry: "",
    passport_doc: "",
    cerpac_no: "",
    cerpac_expiry: "",
    cerpac_front_doc: "",
    cerpac_back_doc: "",
    visa_type: "",
    visa_number: "",
    visa_expiry: "",
    visa_doc: "",

    old_passport_no: "",
    old_passport_expiry: "",
    old_passport_doc: "",
    old_cerpac_no: "",
    old_cerpac_expiry: "",
    old_cerpac_front_doc: "",
    old_cerpac_back_doc: "",
    old_visa_type: "",
    old_visa_number: "",
    old_visa_expiry: "",
    old_visa_doc: "",

    documentType: undefined,
    quota_expiry: ""
}

export interface UPDATE_EMPLOYEE_KYC_DOCUMENT {
    passport_no: string | undefined,
    passport_expiry: string | undefined,
    passport_doc: string | File | undefined,
    cerpac_no: string | undefined,
    cerpac_expiry: string | undefined,
    cerpac_front_doc: string | File | undefined
    cerpac_back_doc: string | File | undefined,
    visa_type: string | undefined,
    visa_number: string | undefined,
    visa_expiry: string | undefined,
    visa_doc: string | undefined;

    old_passport_no: string | undefined,
    old_passport_expiry: string | undefined,
    old_passport_doc: string | File | undefined,
    old_cerpac_no: string | undefined,
    old_cerpac_expiry: string | undefined,
    old_cerpac_front_doc: string | File | undefined
    old_cerpac_back_doc: string | File | undefined,
    old_visa_type: string | undefined,
    old_visa_number: string | undefined,
    old_visa_expiry: string | undefined,
    old_visa_doc: string | undefined;

    documentType: IDocumentType | undefined,
    quota_expiry: string
}

export const userdetails_transform_to_form_keys = {
    passport_no: "passportnumber",
    passport_expiry: "passportexpiry",
    passport_doc: "uploadpassport",
    cerpac_no: "cerpac_number",
    cerpac_expiry: "cerpac_expiry",
    cerpac_front_doc: "cerpac_front",
    cerpac_back_doc: "cerpac_back",
    visa_type: "visatype",
    visa_number: "visanumber",
    visa_expiry: "visaexpiracydate",
    visa_doc: "visa_document",
    quota_expiry: "quota_expiry"
}



export const update_employee_kyc_validation_schema = yup.object().shape({
    documentType: yup.string().required("please select document type"),
    passport_no: yup.string().when("documentType", {
        is: "PASSPORT",
        then(schema) {
            return schema.min(8, "Passport Number must be atleast 8 characters").max(12, "It max 12 digits").test('passport_no', async (value, validationcontext) => {
                const { createError, parent: { passport_no, quota_expiry, old_passport_no, passport_expiry, passport_doc, old_passport_expiry, old_passport_doc } } = validationcontext;

                if (!value) {
                    return createError({ message: "Passport number is required." });
                }



                if (value != old_passport_no) {
                    const apiFunc = () => validateEmployeePassportApi(passport_no)
                    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);
                    return status === 200 ? createError({ message: "Passport number is already exists." }) : true
                }

                //(passport_expiry != old_passport_expiry) ||
                // if (value == old_passport_no && ((passport_doc !== old_passport_doc))) {
                //     return createError({ message: "please enter new passport number." });
                // }

                return true;
            })
        }
    }),
    passport_expiry: yup.string().when("documentType", {
        is: "PASSPORT",
        then(schema) {
            return schema.test('passport_expiry', (value, validationcontext) => {

                const { createError, parent: { passport_no, quota_expiry, old_passport_no, passport_expiry, passport_doc, old_passport_expiry, old_passport_doc } } = validationcontext;
                const quotaExpiryDate = quota_expiry && new Date(quota_expiry?.split("/")?.reverse().join("-"));

                const inputDate = value && new Date(value);
                const currentDate = new Date();

                if (!value && passport_no) {
                    return createError({ message: "Passport expiry is required." });
                }

                // if (value == old_passport_expiry && ((passport_no != old_passport_no) || (passport_doc !== old_passport_doc))) {
                //     return createError({ message: "please select new passport expiry." });
                // }

                // if (value && inputDate && inputDate <= currentDate) {
                //     return createError({ message: "Expiry date should not be less than current date." });
                // }

                return true;
            })
        }
    }),
    passport_doc: yup.mixed().when("documentType", {
        is: "PASSPORT",
        then(schema) {
            return schema.required().test("passport_doc", (value, validationcontext) => {

                const { createError, parent: { passport_no, quota_expiry, old_passport_no, passport_expiry, passport_doc, old_passport_expiry, old_passport_doc } } = validationcontext;

                if (!value && passport_no) {
                    return createError({ message: "Please upload passport" });
                }

                //|| (passport_expiry !== old_passport_expiry)
                // if (value == old_passport_doc && ((passport_no != old_passport_no))) {
                //     return createError({ message: "please select new passport document." });
                // }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
                }
                
                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }
                return true;
            })
        }
    }),
    cerpac_no: yup.string().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.length(8, "CARPAC number must be 8 characters").test('cerpac_expiry', (value, validationcontext) => {
                const { createError, parent: { cerpac_no, old_cerpac_no, visa_type, passport_expiry, quota_expiry, cerpac_back_doc, old_cerpac_back_doc, cerpac_front_doc, old_cerpac_front_doc, cerpac_expiry, old_cerpac_expiry } } = validationcontext;
                const inputDate = value && new Date(value);
                const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;
                const quotaExpiryDate = quota_expiry && new Date(quota_expiry?.split("/")?.reverse().join("-"));

                const currentDate = new Date();

                if (visa_type == "Residence Visa") {

                    if (!value) {
                        return createError({ message: "CERPAC number is required" });
                    }

                    //(cerpac_expiry != old_cerpac_expiry) ||
                    // if (value == old_cerpac_no && ((cerpac_front_doc != old_cerpac_front_doc) || (cerpac_back_doc != old_cerpac_back_doc))) {
                    //     return createError({ message: "please enter new CERPAC number." });
                    // }

                    // if (value && inputDate && inputDate <= currentDate) {
                    //     return createError({ message: "Expiry date should not be less than Today." });
                    // }
                    else if (value && pasportDate && inputDate && inputDate >= pasportDate) {
                        return createError({ message: "Expiry date should not be greater than passport expiry date." });
                    }
                    else if (value && quotaExpiryDate && inputDate && inputDate > quotaExpiryDate) {
                        return createError({ message: "Expiry date should not be greater than Quota expiry date." });
                    }
                }

                return true;
            })
        }
    }),
    cerpac_expiry: yup.string().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test('cerpac_expiry', (value, validationcontext) => {
                const { createError, parent: { visa_type, cerpac_no, old_cerpac_no, passport_expiry, quota_expiry, cerpac_back_doc, old_cerpac_back_doc, cerpac_front_doc, old_cerpac_front_doc, cerpac_expiry, old_cerpac_expiry } } = validationcontext;
                const inputDate = value && new Date(value);
                const pasportDate = passport_expiry && new Date(passport_expiry) || undefined;
                const quotaExpiryDate = quota_expiry && new Date(quota_expiry?.split("/")?.reverse().join("-"));

                const currentDate = new Date();

                if (visa_type == "Residence Visa") {
                    if (!value && cerpac_no) {
                        return createError({ message: "CERPAC expiry is required" });
                    }
                    // if (value == old_cerpac_expiry && ((cerpac_no != old_cerpac_no) || (cerpac_front_doc != old_cerpac_front_doc) || (cerpac_back_doc != old_cerpac_back_doc))) {
                    //     return createError({ message: "please select new CERPAC expiry." });
                    // }
                    // if (value && inputDate && inputDate <= currentDate) {
                    //     return createError({ message: "Expiry date should not be less than Today." });
                    // }
                    else if (value && pasportDate && inputDate && inputDate >= pasportDate) {
                        return createError({ message: "Expiry date should not be greater than passport expiry date." });
                    }
                    else if (value && quotaExpiryDate && inputDate && inputDate > quotaExpiryDate) {
                        return createError({ message: "Expiry date should not be greater than Quota expiry date." });
                    }
                }

                return true;
            })
        }
    }),
    cerpac_front_doc: yup.mixed().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test("cerpac_front_doc", (value: any, validationcontext) => {
                const { createError, parent: { visa_type, cerpac_no, old_cerpac_no, passport_expiry, quota_expiry, cerpac_back_doc, old_cerpac_back_doc, cerpac_front_doc, old_cerpac_front_doc, cerpac_expiry, old_cerpac_expiry } } = validationcontext;
                // Check if the value is a File object

                if (visa_type == "Residence Visa") {
                    
                    if (!value && (cerpac_no || cerpac_expiry)) {
                        return createError({ message: "Please upload file" });
                    }

                    // || (cerpac_expiry != old_cerpac_expiry) 
                    // if (value == old_cerpac_front_doc && ((cerpac_no != old_cerpac_no) || (cerpac_back_doc != old_cerpac_back_doc))) {
                    //     return createError({ message: "please select new CERPAC front document." });
                    // }

                    const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                    if (value && value instanceof File && fileSize >= 5) {
                        return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
                    }
                    if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                        return createError({ message: "File name not include underscore,dot,comma" });
                    }
                    if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                        return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                    }
                }

                return true;
            })
        }
    }),
    cerpac_back_doc: yup.mixed().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test("cerpac_back_doc", (value, validationcontext) => {
                const { createError, parent: { visa_type, cerpac_no, old_cerpac_no, passport_expiry, quota_expiry, cerpac_back_doc, old_cerpac_back_doc, cerpac_front_doc, old_cerpac_front_doc, cerpac_expiry, old_cerpac_expiry } } = validationcontext;

                // if (visa_type == "Residence Visa") {
                if (!value && (cerpac_no || cerpac_expiry)) {
                    return createError({ message: "Please upload file" });
                }

                //|| (cerpac_expiry != old_cerpac_expiry)
                // if (value == old_cerpac_back_doc && ((cerpac_no != old_cerpac_no) || (cerpac_front_doc != old_cerpac_front_doc))) {
                //     return createError({ message: "please select new CERPAC back document." });
                // }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }
                // }

                return true;
            })
        }
    }),
    visa_type: yup.string().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test('visa_expiry', (value, validationcontext) => {
                const { createError, parent: { visa_type, visa_number, old_visa_number, old_visa_doc, visa_doc, visa_expiry, old_visa_expiry, old_visa_type } } = validationcontext;

                if (!value) {
                    return createError({ message: "VISA type is required" });
                }

                // if (value == old_visa_type && ((visa_number != old_visa_number) || (visa_expiry != old_visa_expiry) || (visa_doc != old_visa_doc))) {
                //     return createError({ message: "please select new VISA type" });
                // }

                return true;
            })
        }
    }),
    visa_number: yup.string().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.min(8, "Visa number at least 8 digits").max(10, "Visa number maximum 10 digits").test('visa_expiry', (value, validationcontext) => {
                const { createError, parent: { visa_type, visa_number, old_visa_number, old_visa_doc, visa_doc, visa_expiry, old_visa_expiry, old_visa_type } } = validationcontext;

                if (!value) {
                    return createError({ message: "VISA number is required" });
                }

                //|| (visa_expiry != old_visa_expiry)
                // if (value == old_visa_number && ((visa_type != old_visa_type) || (visa_doc != old_visa_doc))) {
                //     return createError({ message: "please enter new VISA number" });
                // }

                return true;
            })
        }
    }),
    visa_expiry: yup.string().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test('visa_expiry', (value, validationcontext) => {
                const { createError, parent: { visa_type, visa_number, old_visa_number, old_visa_doc, visa_doc, visa_expiry, old_visa_expiry, old_visa_type } } = validationcontext;
                const inputDate = value && new Date(value);
                const currentDate = new Date();

                if (!value && (visa_number)) {
                    return createError({ message: "Visa expiry is required" });
                }
                // else if (value == old_visa_expiry && ((visa_number != old_visa_number) || (visa_type != old_visa_type) || (visa_doc != old_visa_doc))) {
                //     return createError({ message: "please select new VISA expiry" });
                // }
                // else if (value && inputDate && inputDate <= currentDate) {
                //     return createError({ message: "Expiry date should not be less than current date." });
                // }
                return true;
            })
        }
    }),
    visa_doc: yup.mixed().when("documentType", {
        is: "VISA",
        then(schema) {
            return schema.test("visa_doc", (value, validationcontext) => {
                const { createError, parent: { visa_type, visa_number, old_visa_number, old_visa_doc, visa_doc, visa_expiry, old_visa_expiry, old_visa_type } } = validationcontext;
                // Check if the value is a File object

                if (!value && visa_type) {
                    return createError({ message: "Please upload file" });
                }

                // || (visa_expiry != old_visa_expiry)
                // if (value == old_visa_doc && ((visa_number != old_visa_number) || (visa_type != old_visa_type))) {
                //     return createError({ message: "please select new VISA document" });
                // }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                return true;
            })
        }
    }),
})

const documentTypeOptions = [
    {
        label: "Passport Document",
        value: "PASSPORT"
    },
    {
        label: "CERPAC Document",
        value: "CERPAC"
    }, {
        label: "VISA Document",
        value: "VISA"
    },
]

export const update_employee_kyc_form_fileds = {
    documentType: { key: "documentType", field_type: "options", options: documentTypeOptions, data_key: "documentType", label: "Document Type" },
    passport_no: {
        key: "passport_no", field_type: "text", data_key: "passport_no", label: "Passport Number",
        maxLength: 12,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,
        showIf: (formik: any) => formik.values.documentType == "PASSPORT"
    },
    passport_expiry: { key: "passport_expiry", field_type: "date", data_key: "passport_expiry", label: "Passport Expiry", showIf: (formik: any) => formik.values.documentType == "PASSPORT" },
    passport_doc: { key: "passport_doc", field_type: "file", data_key: "passport_doc", label: "Passport Document", showIf: (formik: any) => formik.values.documentType == "PASSPORT" },
    visa_type: { key: "visa_type", field_type: "options", options: VISA_TYPES_OPTIONS, data_key: "visa_type", label: "Visa Type", showIf: (formik: any) => formik.values.documentType == "VISA" },
    visa_number: {
        key: "visa_number", field_type: "text", data_key: "visa_number", label: "Visa Number",
        maxLength: 10,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,
        showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type != "Residence Visa"
    },
    visa_expiry: { key: "visa_expiry", field_type: "date", data_key: "visa_expiry", label: "Visa Expiry", showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type != "Residence Visa" },
    visa_doc: { key: "visa_doc", field_type: "file", data_key: "visa_doc", label: "Visa Document", showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type != "Residence Visa" },
    cerpac_no: {
        key: "cerpac_no", field_type: "text", data_key: "cerpac_no", label: "CERPAC NO / TEMPORARY RECEIPT NO.",
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g,
        blockletters: true,
        showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type == "Residence Visa"
    },
    cerpac_expiry: { key: "cerpac_expiry", field_type: "date", data_key: "cerpac_expiry", label: "CERPAC Expiry", showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type == "Residence Visa" },
    cerpac_front_doc: { key: "cerpac_front_doc", field_type: "file", data_key: "cerpac_front_doc", label: "CERPAC Front Document", showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type == "Residence Visa" },
    cerpac_back_doc: { key: "cerpac_back_doc", field_type: "file", data_key: "cerpac_back_doc", label: "CERPAC Back Document", showIf: (formik: any) => formik.values.documentType == "VISA" && formik.values.visa_type == "Residence Visa" },

};