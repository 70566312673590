import { Button } from "src/components/ui/button";
import { useRegistrationMainContext } from "../../RegistrationMainContext";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import LightBtn from "src/components/Common/LightBtn";
import { company_registration } from "src/services/services";
import { useDispatch } from "react-redux";
import {
  company_registrationAsync,
  partialReset,
  save,
  setPreviousActiveTab,
} from "src/Silcers/companyRegistrationSlicer";
import { cn } from "src/lib/utils";
import { Loader2, ShieldClose } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { format } from "date-fns";

function StepThreeDetail() {
  const { registrationData, error, isLoading, message } = useSelector(
    (state: RootState) => state.company_registration
  );
  const dispatch = useDispatch();

  const { updateState } = useRegistrationMainContext();

  //  const modifyString=(string:string)=>{
  //     const maskedString = string.replace(/^*$/, (_, firstTwoChars, lastTwoChars) => {
  //         const middleStars = '*'.repeat(string.length - 4); // Replace all characters between the first two and last two
  //         return firstTwoChars + middleStars + lastTwoChars;
  //       });
  //     return maskedString
  //  }

  // const [loading, setloading] = useState(second);

  const saveCompanyRegistration = async () => {

    var oldData:any = registrationData
    var newData = Object.keys(registrationData).reduce((acc:any, key:any) => {
      if (oldData[key]) {
        acc[key] = oldData[key];
      }
      return acc;
    }, {});

    console.log({newData})
   
    dispatch(company_registrationAsync({
      ...newData,
      is_moi_verified:registrationData.is_moi_verified
    }));
    updateState({
      activeTab: 4,
    });
  };

  return (
    <div className="p-7">
      <div className="md:p-7 grid grid-cols-1 gap-3">
        {/* type of company and rc number */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">rc number</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.rc_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">State Of Company</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.state_of_company}
            </p> */}
          </div>
        </div>
        {/* Name of company */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Type Of Company</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.type_of_company}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Name of Company</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.company_name}
            </p>
          </div>
        </div>
        {/* bussiness email and phone  */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Business email</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.business_email}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Business phone number</h4>
            <p className="text-slate-400 text-sm">
              +{registrationData && registrationData.business_phone_code}-
              {registrationData && registrationData.business_phone_number}
            </p>
          </div>
        </div>
        {/* Company Address 1 / 2 */}
        <div>
          <h4 className=" uppercase">Address Line 1</h4>
          <p className="text-slate-400 text-sm">
            {registrationData && registrationData.company_address1}
          </p>
        </div>
        <div>
          <h4 className=" uppercase">Address Line 2</h4>
          <p className="text-slate-400 text-sm">
            {registrationData && registrationData.company_address2}
          </p>
        </div>
        {/* Type of representative and designation  */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Type Of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.type_of_representative}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Designation Of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData &&
                registrationData.designation_of_representative}
            </p>
          </div>
        </div>
        {/* Name of representative and national identification no */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Name of representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.name_of_representative}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">
              National Identification Number of Representative (NIN)
            </h4>
            <p className="text-slate-400 text-sm">
              {registrationData &&
                registrationData.national_identification_number}
            </p>
          </div>
        </div>
        {/* Bank verification no */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Nationality of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData &&
                registrationData.nationality_of_representative}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Bank Verification Number (BVN) </h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.bank_verification_number}
            </p>
          </div>
        </div>
        {/* PAssport no of representative and  Passport expiry */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Passport No. of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.passport_number}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Passport Expiry of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData &&
                registrationData.passport_expiry &&
                format(
                  new Date(registrationData.passport_expiry),
                  "dd/MM/yyyy"
                )}
            </p>
          </div>
        </div>
        {/* crepc NO and expiry */}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Cerpac No. of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.cerpac_number}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Cerpac Expiry of Representative</h4>
            <p className="text-slate-400 text-sm">
              {registrationData &&
                registrationData.cerpac_expiry &&
                format(new Date(registrationData.cerpac_expiry), "dd/MM/yyyy")}
            </p>
          </div>
        </div>
        {/* password / confirm password  */}
        {/* <div className="grid grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Password</h4>
            <p className="text-slate-400 text-sm">
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Confirm password</h4>
            <p className="text-slate-400 text-sm">              
            </p>
          </div>
        </div> */}
        {/* Security  1 2 3*/}
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Security Question 1</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_question1}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Answer 1</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_answer1}
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Security Question 2</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_question2}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Answer 2</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_answer2}
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Security Question 3</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_question3}
            </p>
          </div>
          <div>
            <h4 className=" uppercase">Answer 3</h4>
            <p className="text-slate-400 text-sm">
              {registrationData && registrationData.security_answer3}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-center w-full">
        <LightBtn
          onclick={() => dispatch(setPreviousActiveTab())}
          title="Edit"
        />
        {error && error ? (
          <Button
            variant={"destructive"}
            disabled
            className={cn("mb-2 px-10 md:w-44 mx-5")}
            onClick={saveCompanyRegistration}
            qa-data="registration-form-review-retry-btn"
          >
            Continue
          </Button>
        ) : (
          <Button
            className={cn("mb-2 px-10 md:w-44 mx-5")}
            onClick={saveCompanyRegistration}
            disabled={isLoading}
            qa-data="registration-form-review-btn"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        )}
      </div>

      <Dialog open={error} onOpenChange={() => { }}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-destructive text-lg font-bold">
                    ERROR{" "}
                  </div>
                </div>
                <ShieldClose size={24} className="text-destructive" />
                <div className="my-2">{message}</div>
                <Button
                  variant="secondary"
                  className="focus:outline-none"
                  onClick={() => dispatch(partialReset())}
                >
                  Cancel
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StepThreeDetail;
