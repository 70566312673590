import axios, { AxiosError } from 'axios'
import React, { useState } from 'react'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import LoadingDialog from 'src/components/LoadingDialog'
import SuccessDialog from 'src/components/SuccessDialog'
import { Label } from 'src/components/ui/Label'
import { createCardPrintingRequestApi } from 'src/services/services'
import { IRequestUsestate } from 'src/types'


type IEmployeeDetails = {
    _id: string,
    surname: string,
    userid: string,
    givenname: string,
    mereg_no: string,
    dob: string,
    sex: string,
    cerpac_number: string,
    comapnyname: string,
    empnumber: string,
    empcode: string,
    empemail: string,
    empadress1: string,
    empaddress2: string,
    country: string,
    state: string,
    city: string,
    employee_profile_photo: string,
    position: string,
    quota_expiry: string,
}

type Props = {
    data: IEmployeeDetails,
    isOpen: boolean,
    resetIsOpen: (e: boolean) => void
}

const formKey = {
    employee_name: { key: "employee_name", type: "text", data_key: ["givenname", "surname"], data_join_by: " ", label: "Employee Name" },
    mereg_no: { key: "mereg_no", type: "text", data_key: "mereg_no", label: "M.e.R.G. Number" },
    dob: { key: "dob", type: "text", data_key: "dob", label: "Date of Birth" },
    sex: { key: "sex", type: "text", data_key: "sex", label: "Gender" },
    position: { key: "position", type: "text", data_key: "position", label: "Position" },
    cerpac_number: { key: "cerpac_number", type: "text", data_key: "cerpac_number", label: "CERPAC Number" },
    company_name: { key: "comapnyname", type: "text", data_key: "comapnyname", label: "Company Name" },
    employee_phone_no: { key: "employee_phone_no", type: "text", data_key: ["empcode", "empnumber"], data_join_by: "-", label: "Employee Phone Number", prefix: "+" },
    employee_email: { key: "employee_email", type: "text", data_key: "empemail", label: "Employee Email" },
    employee_address: { key: "employee_address", type: "text", data_key: ["empaddress1", "empaddress2", "city", "state", "country"], data_join_by: " ", label: "Employee Address ", classNames: "col-span-2" },
    // employee_profile_photo: { key: "employee_profile_photo", type: "file", data_key: "employee_profile_photo", label: "Employee Profile Photo" },
};

type formkeyType = {
    key: string,
    type: string,
    data_key: string,
    data_join_by?: string,
    prefix?: string,
    label: string,
    classNames?: string
}

const RenderedFields = ({ label, value }: { label: string, value: string }) => {
    return <div className='flex flex-col space-y-1'>
        <Label>{label}</Label>
        <div>{value}</div>
    </div>
}

const CardPrintingRequestDialog = ({ data, isOpen, resetIsOpen }: Props) => {

    const [requestState, setrequestState] = useState<IRequestUsestate>({
        isError: false,
        isLoading: false,
        status: undefined,
        message: "",
    })
    const resetErrorState = () => setrequestState({ ...requestState, isError: false, message: "", })
    const resetState = () => setrequestState({ ...requestState, isError: false, message: "", isLoading: false, status: undefined })

    const handleCardPrintingRequest = async () => {
        try {

            setrequestState({
                ...requestState,
                isError: false,
                isLoading: true,
                message: "",
            })

            const postData = {
                "userid": data.userid,
                "employee_id": data._id
            }

            const apiResp: any = data.userid && data._id && await createCardPrintingRequestApi(postData);

            if (apiResp.status == 201 || apiResp.status == 200 ) {
                setrequestState({
                    ...requestState,
                    isLoading: false,
                    status: apiResp?.status,
                })
            }

        } catch (error) {
            let err = error as Error | AxiosError
            if (axios.isAxiosError(err)) {
                setrequestState({
                    ...requestState,
                    isError: true,
                    isLoading: false,
                    message: err.response?.data?.message,
                })
            }
            else {
                setrequestState({
                    ...requestState,
                    isError: true,
                    isLoading: false,
                    message: err.message,
                })
            }
        }
    }


    return (
        <div>
            <CustomeDialog isOpen={isOpen} headerTitle="Card Printing Request" submitBtnTitle='Continue' onSubmit={handleCardPrintingRequest} onCancle={() => resetIsOpen(false)}  >
                <div className='grid grid-cols-2 gap-4 mb-8'>
                    {
                        (Object.entries(formKey).map(([key, key_value]: [string, any]) => {
                            let value = Array.isArray(key_value.data_key) ? key_value.data_key.map((keys: string) => data[keys as keyof object]).join(key_value.data_join_by) : data[key_value.data_key as keyof object];
                            return <div className={key_value.classNames}><RenderedFields label={key_value.label} value={value} /></div>
                        }))
                    }
                </div>
            </CustomeDialog>
            <LoadingDialog isOpen={requestState.isLoading} message={"Sending Request"} />
            <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={resetErrorState} />
            <SuccessDialog isOpen={requestState?.status == 201 || requestState?.status == 200} onClickOkBtn={() => {
                resetState()
                resetIsOpen(false)
            }} title='Request Submitted' >
                <div>You'll receive notification on your registered email.</div>
            </SuccessDialog>
        </div>
    )
}

export default CardPrintingRequestDialog