import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
// import { useAuth } from '../Components/auth/core';
import FaqMian from "src/Modules/Faq/FaqMian";
import ForgotPassword from "src/Modules/ForgotPAssword";
import UploadDocuments from "src/Modules/ForgotPAssword/UploadDocuments";
import AuthLayoutMain from "src/Modules/Layout/AuthLayoutMain";
import EllOverview from "src/Modules/ReadMore/EllOverview";
import LevyDetails from "src/Modules/ReadMore/LevyDetails";
import PrivacyStatement from "src/Modules/ReadMore/PrivacyStatement";
import Requirements from "src/Modules/ReadMore/Requirements";
import App from "../App";
import { AuthPage } from "../Modules/Auth/AuthPage";
import { useAuth } from "../Modules/Auth/Core";
import VerifyEmail from "src/Modules/Verify/VerifyEmail";
import LayoutMain from "src/Modules/Layout/LayoutMain";
import SetNewPassword from "src/Modules/Auth/SetNewPassword";
import TermsOfUse from "src/Modules/TermOfUSe";
import EmployeeEElCard from "src/Modules/mobile/Employee/EeelCard";
import DependentEelCard from "src/Modules/mobile/Dependent/EelCard";
import ResetSecurityQuations from "src/Modules/ResetSecurityQuations";
import LandinPage from "src/Modules/Auth/LandingPage";
import { useEffect } from "react";
import ChangeRepresentative from "src/Modules/ChangeRepresentative";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { getLoginReducerState } from "src/Silcers/loginSlicer";
import UpdateNewPassword from "src/Modules/Auth/ChangeRepresentative/ResetPassword";



const { PUBLIC_URL } = process.env;

type Props = {};

const ErrorsPage = () => {
  return <div>Errror</div>;
};

const Logout = () => {
  return <div>Logout</div>;
};

const AuthRoutes = (props: Props) => {
  const { currentUser } = useAuth();
  const { user } = useSelector((state: RootState) => getLoginReducerState(state))
  // console.log({ currentUser });

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path='/mobile/employee/eel-card/:employee_id' element={<EmployeeEElCard />} />
        <Route path='/mobile/dependent/eel-card/:dependent_id' element={<DependentEelCard />} />
        <Route element={<App />}>
          <Route element={<LayoutMain />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="logout" element={<Logout />} />
            <Route path="email-verified/:token" element={<VerifyEmail />} />
            <Route path="forgot-validate" element={<ForgotPassword />} />
            <Route path="forgot-upload" element={<UploadDocuments />} />
            <Route path="company/reset-password/:token" element={<SetNewPassword />} />
            <Route path='company/reset/:token' element={<ResetSecurityQuations />} />
            {/* <Route path="forgot-password" element={<ForgotPass />} />
            <Route path="forgot-completed" element={<ForgotPassCompleted />} /> */}
            <Route path="ell-overview" element={<EllOverview />} />
            <Route path="ell-requirement" element={<Requirements />} />
            <Route path="ell-fee" element={<LevyDetails />} />
            <Route path='/privacy-statement' element={<PrivacyStatement />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='faq' element={<FaqMian />} />
            <Route path='/change-representative' element={<ChangeRepresentative />} />
          </Route>
          {currentUser ? (
            currentUser && user && user?.is_temperory_password_active ?
              <>
                <Route element={<LayoutMain />}>
                  <Route path="/change-password" element={<UpdateNewPassword />} />
                  <Route index element={<Navigate to="/change-password" />} />
                </Route>
              </> :
              <>
                <Route element={<AuthLayoutMain />}>
                  {/* <Route element={<LayoutMain />}> */}
                  <Route path="/*" element={<AppRoutes />} />
                  <Route index element={<Navigate to="/dashboard" />} />
                  {/* </Route> */}
                </Route>
              </>
          ) : (
            <>
              <Route element={<LayoutMain />}>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="/welcome" element={<LandinPage />} />
                <Route index element={<Navigate to="/welcome" />} />
                <Route path="/*" element={<Navigate to="/auth" />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AuthRoutes;
