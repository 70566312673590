import { Plus } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/components/ui/button'

type Props = {}

const AddNewQuota = (props: Props) => {

    const navigate = useNavigate()

    return (
        <Button onClick={()=> navigate('/quota/create')}><Plus size={16} className='me-2' /> Add Quota</Button>
    )
}

export default AddNewQuota