import { Loader2 } from 'lucide-react'
import React from 'react'
import { cn } from 'src/lib/utils'
import { Button } from '../ui/button'
import { ClassValue } from 'clsx'

type Props = {
    isLoading: boolean,
    isDisabled?: boolean,
    title?: string,
    onClick?: () => void,
    className?: ClassValue
}

const SubmitBtn = ({ isDisabled, isLoading, title, onClick, className }: Props) => {
    return (
        <Button type='submit' disabled={isDisabled || isLoading} onClick={onClick} className={cn("w-44",className)}>
            {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            {title ?? "Submit"}
        </Button>
    )
}

export default SubmitBtn