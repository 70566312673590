import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import qs from "qs";
import { ID, QueryResponseContextProps, QueryState } from "./models";
import { checkIsValidEmail, getUpladedFile, uploadfile, uploadfileByEmail } from "src/services/services";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiResponse, IregistrationRequest, IRequestUsestate } from "src/types";
import { InterfaceType } from "typescript";
import { property } from "lodash";
import { SECURITY_QUATIONS } from "./consts";
import { format } from "date-fns";

function createResponseContext<T>(initialState: any) {
  return createContext(initialState);
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== "";
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ["page", "items_per_page"],
    skipNulls: true,
  });
  const sort = qs.stringify(state, {
    filter: ["sort", "order"],
    skipNulls: true,
  });
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ["search"], skipNulls: true })
    : "";

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`;
      })
      .join("&")
    : "";

  return [pagination, sort, search, filter].filter((f) => f).join("&");
  // .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query);
  return cache as QueryState;
}

function calculatedGroupingIsDisabled<T>(
  isLoading: boolean,
  data: Array<T> | undefined
): boolean {
  if (isLoading) {
    return true;
  }

  return !data || !data.length;
}

function calculateIsAllDataSelected<T>(
  data: Array<T> | undefined,
  selected: Array<ID>
): boolean {
  if (!data) {
    return false;
  }

  return data.length > 0 && data.length === selected.length;
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return;
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id));
  } else {
    const updatedSelected = [...selected];
    updatedSelected.push(id);
    setSelected(updatedSelected);
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([]);
    return;
  }

  if (!data || !data.length) {
    return;
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id));
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

function stringifyObjectToRequestQuery(object: any): string {
  const filter = object
    ? Object.entries(object as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`;
      })
      .join("&")
    : "";

  return [filter].filter((f) => f).join("&");
  // .toLowerCase()
}

function toUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return undefined;
  }
  return url;
  //return url.protocol === "http:" || url.protocol === "https:";
}

const formatAmt = (amt: number) => new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(amt)

const addition_of_key_from_Array_of_object = (items: any[], key: string) => {
  return items.reduce((total, curVal) => {
    // console.log({ total, current : curVal[key] });
    return total + curVal[key];
  }, 0);
}

const subtraction_of_key_from_Array_of_object = (items: any[], key: string) => {
  return items.reduce((total, curVal) => {
    return total - curVal[key];
  }, 0);
}


const checkIfAnyFilesToBeUpload = async (data: any, uploadProgress?: (totalFilesCount: number, totalUploaded: number, isError: boolean, message: string) => void) => {
  let newData = { ...data };
  let totalFilesCount = Object.entries(data).filter(([key, value]) => value instanceof File).length;
  let totalUploadedFiles = 0;

  uploadProgress && uploadProgress(totalFilesCount, totalUploadedFiles, false, "");

  try {
    for (const [key, value] of Object.entries(data)) {
      if (value instanceof File) {
        let upladedFilesCount = totalUploadedFiles;
        const uploadResp = await uploadfile([value], key);
        console.log({ uploadResp });
        if (uploadResp?.status == 400) {
          uploadProgress && uploadProgress(totalFilesCount, upladedFilesCount, true, uploadResp?.message);
          return false;
        }
        upladedFilesCount = ++totalUploadedFiles
        uploadProgress && uploadProgress(totalFilesCount, upladedFilesCount, false, "");
        newData = { ...newData, [key]: uploadResp.filename };
      }
    }

    return newData;

  } catch (error) {
    let err = error as Error || AxiosError;
    console.log({ fileError: err });

    if (axios.isAxiosError(err)) {
      uploadProgress && uploadProgress(totalFilesCount, totalUploadedFiles, true, err.response?.data?.message);
      return false;
    }
    else {
      uploadProgress && uploadProgress(totalFilesCount, totalUploadedFiles, true, err?.message);
      return false;
    }
  }

}

const checkIfAnyFilesToBeUploadByEmail = async (data: any, email: string, uploadProgress?: (totalFilesCount: number, totalUploaded: number, isError: boolean, message: string) => void) => {
  let newData = { ...data };
  let totalFilesCount = Object.entries(data).filter(([key, value]) => value instanceof File).length;
  let totalUploadedFiles = 0;

  uploadProgress && uploadProgress(totalFilesCount, totalUploadedFiles, false, "");

  for (const [key, value] of Object.entries(data)) {
    if (value instanceof File) {
      let upladedFilesCount = totalUploadedFiles;
      const uploadResp = await uploadfileByEmail([value], email);
      upladedFilesCount = ++totalUploadedFiles
      uploadProgress && uploadProgress(totalFilesCount, upladedFilesCount, false, "");
      newData = { ...newData, [key]: uploadResp.filename };
    }
  }

  return newData;

}

const getUploadFileData = async (filetype: string, doc_id: string, file_key: string) => {
  try {
    let apiData = await getUpladedFile(filetype, doc_id, file_key);
    const blob = new Blob([apiData.data], { type: apiData.headers["content-type"] });
    const reader = new FileReader();

    const resultPromise = new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });

    reader.readAsDataURL(blob);
    return resultPromise;
  } catch (error) {
    // Handle errors here
    console.error(error);
    throw error;
  }

}

async function handleApiCall<T>(apiCall: () => Promise<AxiosResponse<any>>, expectedStatusCode: number): Promise<ApiResponse<T>> {
  try {
    const apiResp: any = await apiCall();
    // console.log({ apiResp })
    if (apiResp && apiResp.status === expectedStatusCode) {
      return { data: apiResp.data, isSuccess: true, message: apiResp?.message || undefined, status: apiResp.status };
    }
  } catch (e) {
    const error = e as AxiosError | Error;
    if (axios.isAxiosError(error)) {
      return {
        message: error.response?.data?.message,
        status: error.response?.status,
        isError: true
      };
    } else {
      return {
        message: error?.message,
        isError: true
      };
    }
  }
  return {}; // Return an empty object if no valid response or error is encountered
}

const checkIsBusinessEmailAlreadyExists = async (email: string) => {
  try {

    let apiResp = await checkIsValidEmail({
      businessEmail: email,
    });
    if (apiResp?.status == 200) {
      return {
        status: apiResp?.status,
        isEmailExists: true
      };
    }
  } catch (e) {
    const error = e as AxiosError | Error;
    if (axios.isAxiosError(error)) {
      return {
        isError: true,
        status: error.response?.status,
        message: error.response?.data?.message
      }
    }
    else {
      return {
        isError: true,
        message: error?.message
      }
    }
  }
}

const format_Date_To_DDMMYYYY = (date: string | undefined) => {
  if (date && new Date(date)) {
    return format(new Date(date), "dd/MM/yyyy")
  }
  return date;
}

const format_DDMMYYYY_To_Date = (date: string | undefined) => {
  if (date && date.includes('/')) {
    return date.split("/").reverse().join("-")
  }
  return date;
}

export {
  createResponseContext,
  stringifyRequestQuery,
  stringifyObjectToRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  toUrl,
  formatAmt,
  addition_of_key_from_Array_of_object,
  subtraction_of_key_from_Array_of_object,
  checkIfAnyFilesToBeUpload,
  getUploadFileData,
  handleApiCall,
  checkIfAnyFilesToBeUploadByEmail,
  checkIsBusinessEmailAlreadyExists,
  format_Date_To_DDMMYYYY,
  format_DDMMYYYY_To_Date
};
