import { useFormik } from 'formik'
import { ChangeEvent, useEffect } from 'react'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import { Button } from 'src/components/ui/button'
import { AddPosition_Fields, AddPosition_INIT, AddPosition_Schema } from '../module'

type Props = {
    onSubmit: (e: any) => void,
    onCancel: () => void,
    total_available_quota: string
}

const AddPositionsForm = ({ onSubmit, onCancel, total_available_quota }: Props) => {

    useEffect(() => {
        if (total_available_quota) {
            // console.log({ total_available_quota });
            formik.setFieldValue("total_quota", total_available_quota)
        }
        return () => { }
    }, [])


    const formik = useFormik({
        initialValues: AddPosition_INIT,
        validationSchema: AddPosition_Schema,
        onSubmit(values, formikHelpers) {
            if (formik.isValid) {
                onSubmit({
                    position_name: values.position_name,
                    position_count: values.position_count
                })
            }
        },
    })

    // console.log({ errors: formik.errors, values: formik.values, touched: formik.touched })

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-col space-y-2 mb-4'>
                    <RenderFormikFiled
                        formik={formik}
                        value={formik.values.position_name}
                        formObject={AddPosition_Fields.position_name}
                        required={false}
                        onchange={(e: any) =>
                            formik.setFieldValue("position_name", e)
                        }
                    />
                    <RenderFormikFiled
                        formik={formik}
                        value={formik.values.position_count}
                        formObject={AddPosition_Fields.position_count}
                        required={false}
                        onchange={(e: any) =>
                            formik.setFieldValue("position_count", e)
                        }
                    />
                    <div className='flex justify-center space-x-2'>
                        <Button type='button' className='px-16' variant={'secondary'} onClick={onCancel}>Cancel</Button>
                        <Button className='px-16'>Submit</Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddPositionsForm