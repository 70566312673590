import { checkIfAnyFilesToBeUpload, getUploadFileData, handleApiCall, optionsType } from "src/Helpers";
import { useFormik } from "formik";
import { Loader2, ShieldClose } from "lucide-react";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";
import {
  fetchCountriesAsync
} from "src/Silcers/CountryStateCityPIckerSlicer";
import {
  fetchEmployeeDetailsAsync,
  resetParial,
  setError,
  updateEmployeeAsync,
} from "src/Silcers/employeeDetailsSlicer";
import LightBtn from "src/components/Common/LightBtn";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import FormikCityPicker from "src/components/ui/CityPIcker/FormikCityPicker";
import FormikCountrycode from "src/components/ui/CountrySelect/FormikCountrycode";
import { Label } from "src/components/ui/Label";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
import FormikStatePicker from "src/components/ui/StatePicker/FormikStatePicker";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Progress } from "src/components/ui/progress";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radiogroup";
import { cn } from "src/lib/utils";
import { getDDQuotaPositionsList, getProfilePic, getUpladedFile, uploadfile, validateEmployeeCERPACApi, validateEmployeeEmailApi } from "src/services/services";
import { RootState } from "src/store";
import {
  CREATE_EMPLOYEE_INIT,
  IUpdate_employee_schema_yup,
  Icreate_employee,
  Icreate_employee_schema_yup,
  countriesNotAllowed,
  createEmployeeFields
} from "../../modules";
import { IApiResponse } from "src/types";
import { format } from "date-fns";
import ErrorDialog from "src/components/ErrorDialog";
import axios, { AxiosError } from "axios";
import LoadingDialog from "src/components/LoadingDialog";
import { ENUM_KYC_STATUS } from "../../../../Helpers/crud-helper/consts";

function EmployeeViewDetails() {
  const { auth } = useAuth();
  const { id } = useParams();
  const dispath = useDispatch();
  const navigate = useNavigate();
  const e_profile_img_input_ref = useRef<any>(null);

  const {
    employeeDetails: {
      employeeViewDetail,
      status,
      isLoading,
      error,
      errorMessage,
    },
    CountryStateCityPIcker: { countryList },
    login: { user }
  } = useSelector((state: RootState) => state);

  const [profileImage, setProfileImage] = useState<string>("");
  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });

  const [quotaPositionsList, setquotaPositionsList] = useState<optionsType[]>([])
  const [quotaPositionsExpiryList, setquotaPositionsExpiryList] = useState<optionsType[]>([])
  const [showQuotaEmptyError, setshowQuotaEmptyError] = useState<boolean>(false)
  const [isLoadingImage, setisLoadingImage] = useState(false)
  const [apiRequestState, setApiRequestState] = useState<{
    isLoading: boolean,
    isError: boolean | undefined,
    message: string | undefined,
  }>({
    isLoading: false,
    isError: false,
    message: ""
  })

  useEffect(() => {
    if (status == 200) {
      if (formik.values.is_dependants) {
        navigate("/dependent/create");
      } else {
        navigate(`/employee-view/${id}`);
      }
    }

    return () => { };
  }, [status]);

  useEffect(() => {
    if (!employeeViewDetail) {
      if (id) {
        dispath(fetchEmployeeDetailsAsync(id));
      }
    }

    if (employeeViewDetail) {
      formik.setValues(employeeViewDetail);
    }
    return () => { };
  }, [employeeViewDetail, id]);

  useEffect(() => {
    if (
      employeeViewDetail?.employee_profile_photo &&
      typeof employeeViewDetail?.employee_profile_photo === "string"
    ) {
      getProfilePicUrl(employeeViewDetail?._id);
    }
    return () => { };
  }, [employeeViewDetail?.employee_profile_photo]);

  useEffect(() => {
    dispath(fetchCountriesAsync());
    fetchQuotaList()
    return () => { };
  }, []);

  const newEmployeeForm = useMemo(
    () => employeeViewDetail,
    [employeeViewDetail]
  );

  useEffect(() => {
    if (newEmployeeForm?.employee_profile_photo_urls) {
      if (newEmployeeForm?.employee_profile_photo_data instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(newEmployeeForm?.employee_profile_photo_data);
        reader.onload = (e) => {
          setProfileImage(e.target?.result as string);
        };
      }
      formik.setFieldValue(
        "employee_profile_photo",
        newEmployeeForm?.employee_profile_photo_urls
      );
    }

    if (newEmployeeForm?.passport_doc_url) {
      formik.setFieldValue("passport_doc", newEmployeeForm?.passport_doc_url);
    }

    if (newEmployeeForm?.cerpac_back_doc_url) {
      formik.setFieldValue(
        "cerpac_back_doc",
        newEmployeeForm?.cerpac_back_doc_url
      );
    }

    if (newEmployeeForm?.cerpac_front_doc_url) {
      formik.setFieldValue(
        "cerpac_front_doc",
        newEmployeeForm?.cerpac_front_doc_url
      );
    }
    formik.setFieldValue("is_dependants", false);
    return () => { };
  }, [
    newEmployeeForm?.employee_profile_photo_urls,
    newEmployeeForm?.passport_doc_url,
    newEmployeeForm?.cerpac_back_doc_url,
    newEmployeeForm?.cerpac_front_doc_url,
  ]);

  const formik = useFormik<Partial<Icreate_employee>>({
    initialValues: employeeViewDetail || CREATE_EMPLOYEE_INIT,
    validationSchema: IUpdate_employee_schema_yup,
    onSubmit: (value: Partial<Icreate_employee>) => {
      submitHandler();
    },
  });

  const fetchQuotaList = async () => {
    const apiResp: IApiResponse = await getDDQuotaPositionsList(user.rcNumber);
    if (apiResp.status == 200) {
      if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length > 0) {
        setquotaPositionsList(apiResp.data.map((d: any) => {
          return {
            label: d.position,
            value: d._id
          }
        }))

        setquotaPositionsExpiryList(apiResp.data.map((d: any) => {
          return {
            label: d.expirationDate,
            value: d._id
          }
        }))
      }
      else if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length == 0) {
        setshowQuotaEmptyError(true)
      }
    }
    else {
      setquotaPositionsList([])
    }

  }


  const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
    setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
    if (isError) {
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
      dispath(resetParial())
      return
    }
  };

  const submitHandler = async () => {
    try {
      let data: any = { ...formik.values };

      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
      data = await checkIfAnyFilesToBeUpload(data,uploadProgress)
      if (data) {
        setTimeout(() => {
          setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          
          const compareValues = Object.keys(newEmployeeForm)
          .filter(key => ( newEmployeeForm[key])?.toString()?.trim() !== (data[key as keyof object])?.toString()?.trim())
          .reduce((acc: any, key) => {
            acc[key] = data[key as keyof object];
            return acc;
          }, {});

          data = {
            ...compareValues,
            id: id,
          };

          dispath(updateEmployeeAsync(data));
        }, 500);
      }

    } catch (error) {
      const err: any = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        let errormsg = err.response?.data && err.response.data?.message;
        dispath(setError(err.response?.data?.message))
      }
      else {
        dispath(setError(err.message))
      }

    }
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("employee_profile_photo", file);
    }
    else {
      setProfileImage("");
      formik.setFieldValue("employee_profile_photo", "");
    }
  };

  const getProfilePicUrl = async (id: string) => {
    try {
      setisLoadingImage(true)
      // const response = await getUpladedFile("employeedocs",id,"employee_profile_photo");
      const response: any = await getUploadFileData("employeedocs", id, "employee_profile_photo");
      if (response) {
        setProfileImage(response)
        setisLoadingImage(false)
      }
    } catch (error) {
      setisLoadingImage(false)
    }

    // console.log({ apiResp });
  };

  const handleValidateEmployeeEmail = async (email: string) => {

    setApiRequestState({
      isLoading: true,
      isError: false,
      message: "Validating Email"
    })

    const apiFunc = () => validateEmployeeEmailApi(email)
    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);

    if (status === 200) {
      formik.setFieldError("employee_email", "Email already exists")
    }

    setApiRequestState({
      isLoading: false,
      message: message,
      isError: isError,
    })

  }

  const handleValidateEmployeeCerpac = async (cerpac_no: string) => {

    setApiRequestState({
      isLoading: true,
      isError: false,
      message: "Validating CERPAC No."
    })

    const apiFunc = () => validateEmployeeCERPACApi(cerpac_no)
    const { isError, message, status } = await handleApiCall<any>(apiFunc, 200);

    if (status === 200) {
      formik.setFieldError("cerpac_no", "CERPAC No. already exists")
    }

    setApiRequestState({
      isLoading: false,
      message: message,
      isError: isError,
    })

  }

  const isEditabelPasspportNo = useMemo(() =>  {
    return employeeViewDetail?.kyc_status != ENUM_KYC_STATUS.PENDING
  }, [employeeViewDetail,formik]) 

  useEffect(() => {
    if(formik.values.passport_no != employeeViewDetail?.passport_no){
      formik.setFieldValue("passport_doc","")
      formik.setFieldValue("passport_expiry","")
    } else {
      formik.setFieldValue("passport_doc",employeeViewDetail?.passport_doc)
      formik.setFieldValue("passport_expiry",employeeViewDetail?.passport_expiry)
    } 

    if(formik.values.cerpac_no != employeeViewDetail?.cerpac_number){
      formik.setFieldValue("cerpac_expiry","")
      formik.setFieldValue("cerpac_front_doc","")
      formik.setFieldValue("cerpac_back_doc_url","")
    } else {
      formik.setFieldValue("cerpac_expiry",employeeViewDetail?.cerpac_expiry)
      formik.setFieldValue("cerpac_front_doc",employeeViewDetail?.cerpac_front_doc)
      formik.setFieldValue("cerpac_back_doc_url",employeeViewDetail?.cerpac_back_doc_url)
    } 
    return () => {}
  }, [formik.values.passport_no, formik.values.cerpac_no])
  


  // console.log("UPDATE",{ formData: formik.errors, employeeViewDetail });

  return (
    <div className="p-4 lg:mx-auto lg:w-4/4 border rounded">
      <form onSubmit={formik.handleSubmit}>
        {/* <TopHeadlogo
                        mainContent={'Create Employee/Expatriate'}
                        subContent={''}
                    /> */}
        <PageHeadingComponent
          mainContent={"Edit Employee/Expatriate"}
          subContent={""}
        />
        <div className="card bg-base-100 shadow-sm rounded-md">
          <div className="grid grid-cols-4 gap-4">
            <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
              {/* Surename And GIVEN NAME */}
              {/* <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_no}
                    required
                    // disabled={isEditabelPasspportNo}
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_no", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_expiry}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_expiry", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.passport_doc}
                    required
                    value={formik.values.passport_doc}
                    onchange={(e: any) =>
                      formik.setFieldValue("passport_doc", e)
                    }
                  />
                </div>
              </div> */}
              {/* Surename And GIVEN NAME */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.surname}
                    required
                    onchange={(e: any) => formik.setFieldValue("surname", e)}
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.given_name}
                    required
                    onchange={(e: any) => formik.setFieldValue("given_name", e)}
                  />
                </div>
              </div>
              {/* M EREG NO Type of Emp*/}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.mereg_number}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("mereg_number", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.type_of_employee}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("type_of_employee", e)
                    }
                  />
                </div>
              </div>


              {/* Quota position and quota expiry  */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  {/* <RenderFormikFiled
                    formik={formik}
                    disabled={quotaPositionsList.length == 0}
                    // options={quotaPositionsList}
                    formObject={createEmployeeFields.quota_position_id}
                    required
                    onchange={(e: any) => {
                      formik.setFieldValue("quota_position_id", e)
                      formik.setFieldValue("quota_position", employeeViewDetail.quota_position)
                      const date: any = quotaPositionsExpiryList.find((d: optionsType) => d.value === e)?.label
                      date && formik.setFieldValue("quota_expiry", format(new Date(date), "dd/MM/yyyy"))
                    }}
                  /> */}
                  <RenderFormikFiled
                    formik={formik}
                    formObject={{
                      key: "quota_position",
                      label: "Quota Position",
                      field_type: "text",
                      readonly: true,
                      placeholder: "Quota Position",
                    }}
                    required
                  />
                  <div>
                    <span>Note : Kindly check the Quota expiry date before proceeding further</span>
                  </div>
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    disabled={quotaPositionsExpiryList.length == 0}
                    formObject={createEmployeeFields.quota_expiry}
                    required
                    options={quotaPositionsExpiryList}
                    onchange={(e: any) =>
                      formik.setFieldValue("quota_expiry", e)
                    }
                  />
                </div>
              </div>
              {/* type of Employee and designation Country of origin  and  */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.country_of_origin}
                    required
                    options={countryList?.filter((d: optionsType) => !countriesNotAllowed.includes(d.value)) || []}
                    onchange={(e: any) =>
                      formik.setFieldValue("country_of_origin", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.name_of_company}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("name_of_company", e)
                    }
                  />

                  {/* <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.quota_position}
                    required
                    onchange={(e: any) =>
                      formik.setFieldValue("quota_position", e)
                    }
                  /> */}
                </div>
              </div>
              {/* Nationality  */}
              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.nationality}
                    required
                    options={countryList || []}
                    onchange={(e: any) =>
                      formik.setFieldValue("nationality", e)
                    }
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    formObject={createEmployeeFields.dob}
                    required
                    onchange={(e: any) => formik.setFieldValue("dob", e)}
                  />
                </div>
              </div>
            </div>
            <div className="col col-span-1 p-4 flex justify-center items-center">
              <div className="flex flex-col">
                <div
                  onClick={() => e_profile_img_input_ref.current.click()}
                  className={cn(
                    "relative w-40 h-40 rounded-full overflow-hidden bg-secondary",
                    {
                      "border border-destructive bg-red-50 ":
                        // form.getFieldState("employee_profile_photo").invalid,
                        formik.errors.employee_profile_photo,
                    }
                  )}
                >
                  <label
                    htmlFor="profile-upload"
                    className="absolute inset-0 flex items-center justify-center cursor-pointer"
                  >
                    {
                      isLoadingImage ? <>
                        <div className="flex flex-col items-center">
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          <div>Loading..</div>
                        </div>
                      </> :
                        <>
                          <input
                            type="file"
                            ref={e_profile_img_input_ref}
                            key={"e-profile-image-input"}
                            accept={"image/jpeg,image/png"}
                            className="hidden"
                            onChange={(e: any) => {
                              handleImageUpload(e)
                            }}
                          />
                          <div className="text-center">
                            {profileImage && (
                              <img
                                src={profileImage}
                                key={"e-profile-image"}
                                alt=""
                                className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                                  ? "opacity-50"
                                  : ""
                                  }`}
                              />
                            )}
                            {profileImage === "" && (
                              <p className="mt-2 sub-styles">
                                Click to upload your Photo
                              </p>
                            )}
                          </div>
                        </>
                    }
                  </label>
                </div>
                <div className={"text-xs text-destructive w-40 my-2"}>
                  {
                    formik.touched.employee_profile_photo &&
                    formik.errors.employee_profile_photo && (
                      <p className={cn("text-xs font-medium text-destructive")}>
                        {formik.errors.employee_profile_photo}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-1 gap-4 p-4">
            {/* Sex and Marital status */}
            <div className="grid grid-cols-2 gap-x-3">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.sex}
                  required
                  onchange={(e: any) => formik.setFieldValue("sex", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.marital_status}
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("marital_status", e)
                  }
                />
              </div>
            </div>
            {/* Passport Number and expiry upload documnet */}
            {/* <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.passport_no}
                  required
                  onchange={(e: any) => formik.setFieldValue("passport_no", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.passport_expiry}
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("passport_expiry", e)
                  }
                />
              </div>
            </div> */}
            {/* Passport NO and   Crepac no/Temporary recipt no */}
            {/* Visa type and uvisa no*/}
            {/* <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_type}
                  required
                  onchange={(e: any) => formik.setFieldValue("visa_type", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_number}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  onchange={(e: any) => formik.setFieldValue("visa_number", e)}
                />
              </div>
            </div> */}
            {/* ate of expiry  Name of Company  */}
            {/* <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_expiry}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  onchange={(e: any) => formik.setFieldValue("visa_expiry", e)}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.visa_doc}
                  disabled={formik.values.visa_type == "Residence Visa"}
                  required={formik.values.visa_type != "Residence Visa"}
                  value={formik.values.visa_doc}
                  onchange={(e: any) =>
                    formik.setFieldValue("visa_doc", e)
                  }
                />
              </div>
            </div> */}
            {/* CERPAC NO & EXPIRY */}
            {/* <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_no}
                  disabled={isEditabelPasspportNo}
                  required
                  onchange={(e: any) => {
                    formik.setFieldValue("cerpac_no", e)
                    if (formik.values.cerpac_no) {
                      handleValidateEmployeeCerpac(formik.values.cerpac_no as string)
                    }
                  }}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_expiry}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_expiry", e)
                  }
                />
              </div>
            </div> */}
            {/* upload cerpac front and BAck */}
            {/* <div className=" grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_front_doc}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required
                  value={formik.values.cerpac_front_doc}
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_front_doc", e)
                  }
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.cerpac_back_doc}
                  disabled={formik.values.visa_type != "Residence Visa"}
                  required
                  value={formik.values.cerpac_back_doc}
                  onchange={(e: any) =>
                    formik.setFieldValue("cerpac_back_doc", e)
                  }
                />
              </div>
            </div> */}

            {/* Work status and */}
            {/* <div className=" grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.work_status}
                    key={createEmployeeFields.work_status.key}
                    required
                  />
                </div>

              </div> */}
            {/* Employee phone no and employee email */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <Label className="required uppercase">
                  Employee Phone Number
                </Label>
                <div className="flex items-start mt-2">
                  <FormikCountrycodePicker
                    formik={formik}
                    nameKey={createEmployeeFields.employee_phone_code.key}
                    placeholder={
                      createEmployeeFields.employee_phone_code.placeholder
                    }
                    onchange={(e: any) =>
                      formik.setFieldValue("employee_phone_code", e)
                    }
                  />
                  <FormikPhonenumber
                    classname="grow"
                    formik={formik}
                    nameKey={createEmployeeFields.employee_phone_number.key}
                    placeholder={
                      createEmployeeFields.employee_phone_number.placeholder
                    }
                    onchange={(e: any) => {
                      formik.setFieldValue("employee_phone_number", e);
                    }}
                  />
                </div>
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.employee_email}
                  disabled
                  required
                  onchange={(e: any) => {
                    formik.setFieldValue("employee_email", e)
                    if (formik.values.employee_email) {
                      handleValidateEmployeeEmail(formik.values.employee_email as string)
                    }
                  }}
                />
              </div>
            </div>
            {/* Employee Address 1 / 2 */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createEmployeeFields.employee_address_1}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("employee_address_1", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createEmployeeFields.employee_address_2}
                required={false}
                onchange={(e: any) =>
                  formik.setFieldValue("employee_address_2", e)
                }
              />
            </div>
            {/* Country and state  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.country}
                    key={createEmployeeFields.country.key}
                    required
                  /> */}
                {/* <CountryPickerFormFiled
                  form={form}
                  nameKey={createEmployeeFields.country.key}
                  required
                /> */}
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.country}
                  required
                  options={countryList || []}
                  onchange={(e: any) =>
                    formik.setFieldValue("country", e)
                  }
                />
              </div>
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.state}
                    key={createEmployeeFields.state.key}
                    required
                  /> */}
                {/* <StatepickerFormFiled
                  countryname={form.getValues("country")}
                  form={form}
                  nameKey={createEmployeeFields.state.key}
                  required
                /> */}
                <FormikStatePicker
                  formik={formik}
                  nameKey={createEmployeeFields.state.key}
                  required
                  countryname={formik.values.country}
                  onchange={(e: any) => {
                    formik.setFieldValue("state", e);
                  }}
                />
              </div>
            </div>
            {/* City and Zip code  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <RenderFormFiled
                    form={form}
                    formObject={createEmployeeFields.city}
                    key={createEmployeeFields.city.key}
                    required
                  /> */}
                {/* <CityFormFiledPicker
                  form={form}
                  stateName={form.getValues("state")}
                  nameKey={createEmployeeFields.city.key}
                  required
                /> */}
                <FormikCityPicker
                  formik={formik}
                  nameKey={createEmployeeFields.city.key}
                  required
                  stateName={formik.values.state}
                  onchange={(e: any) => {
                    formik.setFieldValue("city", e);
                  }}
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createEmployeeFields.zipcode}
                  required={false}
                  onchange={(e: any) => formik.setFieldValue("zipcode", e)}
                />
              </div>
            </div>
            {/* ADD more dependent Fields */}
            <div className="flex items-center space-x-4">
              {/* <div className="flex justify-between">
                <FormField
                  control={form.control}
                  name={"is_dependants"}
                  render={({ field }) => {
                    // console.log({ fieldvalue: field.value });

                    return (
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <Label className="uppercase">
                            Do you have any dependent ?
                          </Label>
                          <div>
                            <RadioGroup
                              value={field.value ? "yes" : "no"}
                              onValueChange={(e: any) => {
                                if (e == "yes") {
                                  form.setValue("is_dependants", true);
                                  //setShowDependantDialog(true);
                                  //setdependent("yes")
                                } else {
                                  form.setValue("is_dependants", false);
                                  //setShowDependantDialog(false);
                                  //setdependent("no")
                                }
                              }}
                              //className={"mt-2"}
                            >
                              <div className="flex">
                                <div className="flex items-center space-x-2">
                                  <RadioGroupItem value="yes" id="r1" />
                                  <Label htmlFor="r1">YES</Label>
                                </div>
                                <div className="flex items-center space-x-2 mx-8">
                                  <RadioGroupItem value="no" id="r2" />
                                  <Label htmlFor="r2">NO</Label>
                                </div>
                              </div>
                            </RadioGroup>
                          </div>
                        </div>
                        <FormMessage />
                      </div>
                    );
                  }}
                />
              </div> */}
              <div className="flex">
                <Label className="uppercase">Do you have any dependent ?</Label>
                <RadioGroup
                  value={formik.values.is_dependants ? "yes" : "no"}
                  onValueChange={(e: any) => {
                    if (e == "yes") {
                      formik.setFieldValue("is_dependants", true);
                    } else {
                      formik.setFieldValue("is_dependants", false);
                    }
                  }}
                //className={"mt-2"}
                >
                  <div className="flex">
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="yes" id="r1" />
                      <Label htmlFor="r1">YES</Label>
                    </div>
                    <div className="flex items-center space-x-2 mx-8">
                      <RadioGroupItem value="no" id="r2" />
                      <Label htmlFor="r2">NO</Label>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <LightBtn
              onclick={(e: any) => {
                navigate(-1);
              }}
              title="Cancel"
            />
            <Button
              disabled={uploadingFileStatus.isLoading || isLoading}
              variant={uploadingFileStatus.isLoading ? "secondary" : "default"}
              className="mb-2 px-10 w-44 mx-5"
            >
              {(uploadingFileStatus.isLoading || isLoading) && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </Button>
          </div>
        </div>
      </form>



      <ErrorDialog isOpen={showQuotaEmptyError} message="Quota not available please add new quota" onCancelClick={() => {
        setshowQuotaEmptyError(false)
        navigate('/employee')
      }} />

      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading || apiRequestState.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Fetching Request" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
            {apiRequestState.isLoading ? "Sending Request" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={error} message={errorMessage} onCancelClick={() => dispath(resetParial())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => submitHandler()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />
      <ErrorDialog isOpen={apiRequestState.isError || false} message={apiRequestState.message} onCancelClick={() => setApiRequestState({
        isError: false,
        message: "",
        isLoading: false
      })} />

    </div>
  );
}

export default EmployeeViewDetails;
