import React, { ChangeEvent, useMemo } from "react";
import { cn } from "src/lib/utils";
import { Input } from "../Input";
import { Label } from "../Label";
import { ClassNameValue } from "tailwind-merge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";
import { InfoIcon } from "lucide-react";

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  inputMode?:
  | "search"
  | "text"
  | "email"
  | "tel"
  | "url"
  | "none"
  | "numeric"
  | "decimal"
  | undefined;
  caseSensetiveLabel?: boolean;
  maxLength?: number;
  length?: number;
  regex?: any;
  blockletters?: boolean;
  onchange?: (e: any) => void;
  readonly?: boolean;
  prefix?: string;
  onBlur?: (e: any) => void;
  sufix?: boolean;
  showMaxLength?: boolean,
  error_className?: ClassNameValue,
  showInfoIcon?: boolean
  infoContent?: string
};

const FormikInput = ({
  formik,
  placeholder,
  nameKey,
  label,
  value,
  type,
  inputMode,
  disabled = false,
  caseSensetiveLabel = false,
  maxLength,
  length,
  regex,
  blockletters,
  readonly,
  prefix,
  onchange,
  lableClass,
  required,
  onBlur,
  sufix,
  showMaxLength,
  error_className,
  showInfoIcon,
  infoContent
}: Props) => {

  // console.log({formik : formik.errors[nameKey] , nameKey , touched : formik.touched[nameKey] ,touchedfiled : formik.touched});
  // console.log({nameKey,placeholder})
  let newValue = useMemo(() => formik.values[nameKey], [formik.values[nameKey]])

  return (
    <>
      <div className="flex justify-between  mt-2">
        <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
          {label}
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event:any) => event.preventDefault()} className="">
                 <InfoIcon className="w-[16px] h-[16px] text-gray-500 " />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <div className="mt-2">
        <Input
          name={nameKey}
          onBlur={onBlur}
          qa-data={nameKey}
          disabled={disabled}
          type={type || "text"}
          inputMode={inputMode}
          placeholder={placeholder || label}
          maxLength={maxLength || length || 225}
          className={cn("", { uppercase: formik.values[nameKey] && blockletters, "bg-gray-100": readonly })}
          readOnly={readonly}
          prefix={prefix}
          sufix={sufix}
          // showInfoIcon={showInfoIcon}
          // infoContent={infoContent}
          value={formik.values[nameKey]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (regex && regex) {
              let cleanedValue = e.target.value.replace(regex, "");
              if (blockletters) {
                cleanedValue = cleanedValue.toUpperCase();
              }
              onchange && onchange(cleanedValue);
            } else {
              let val = e.target.value;
              if (blockletters) {
                val = val.toUpperCase();
              }
              onchange && onchange(val);
            }
          }}
        />
      </div>

      {showMaxLength && <div className="text-xs text-end text-gray-500 mt-1">{newValue?.length || 0}/{maxLength}</div>}


      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <div className={cn("text-xs font-medium text-destructive", error_className)}>
          {formik.errors[nameKey]}
        </div>
      )}
    </>
  );
};

export default FormikInput;
