import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { getAuth } from "src/Modules/Auth/Core";
import {
  CREATE_EMPLOYEE_INIT,
  EMPLOYEE_INIT,
  IDependant,
  INIT_Dependant,
  Icreate_employee,
} from "src/Modules/EmpExpatriate/modules";
import { createNewEmployee } from "src/services/services";
import { ICreateNewEmployeePostData } from "src/types";
import { format_Date_To_DDMMYYYY } from "../Helpers";

const defaultValues = CREATE_EMPLOYEE_INIT;

// Define a type for the slice state
interface IemployeeReducer {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  newEmployeeForm: any | undefined;
  dependents: IDependant;
  isDependent: boolean;
  status: number | undefined;
  newEmployeeID: any | undefined
}

// Define the initial state using that type
const initialState: IemployeeReducer = {
  error: false,
  message: undefined,
  isLoading: false,
  isDependent: false,
  newEmployeeForm: defaultValues,
  dependents: { ...INIT_Dependant },
  status: undefined,
  newEmployeeID: undefined
};

// Create an async thunk for making the API call
export const createEmployeeAsync: any = createAsyncThunk(
  "employees/createEmployee",
  async (employeeData: ICreateNewEmployeePostData, thunkApi) => {
    try {
      const { user_id, api_token }: any = getAuth();
      let formData: Partial<Icreate_employee> = employeeData;

      // Make the API call using Axios
      const postData = {
        employee_profile_photo: formData?.employee_profile_photo,
        surname: formData.surname,
        givenname: formData.given_name,
        mereg_no: formData.mereg_number,
        typeOfEmployee: formData.type_of_employee,
        designation: formData.quota_position,
        countryorigin: formData.country_of_origin,
        nationality: formData.nationality,
        dob: format_Date_To_DDMMYYYY(formData.dob),
        sex: formData.sex,
        ismarried: formData.marital_status,
        passportnumber: formData.passport_no,
        passportexpiry: format_Date_To_DDMMYYYY(formData.passport_expiry),
        uploadpassport: formData.passport_doc,
        cerpac_number: formData.cerpac_no || "",
        cerpac_expiry: format_Date_To_DDMMYYYY(formData.cerpac_expiry),
        cerpac_front: formData.cerpac_front_doc,
        cerpac_back: formData.cerpac_back_doc,
        visatype: formData.visa_type,
        visanumber: formData.visa_number,
        visaexpiracydate: format_Date_To_DDMMYYYY(formData.visa_expiry),
        visa_document: formData.visa_doc,
        comapnyname: formData.name_of_company,
        empnumber: formData.employee_phone_number,
        empcode: formData.employee_phone_code,
        empemail: formData.employee_email,
        empadress1: formData.employee_address_1,
        empaddress2: formData.employee_address_2,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        zipcode: formData.zipcode,
        // dependent: formData.dependants,
        userid: user_id,
        position: formData.quota_position,
        quota_expiry: formData.quota_expiry,
        quotaId: formData.quota_position_id,
      };

      // console.log({ user_id, api_token });

      const response: any = await createNewEmployee(postData);
      if (response?.status && response?.status == 201) {
        // console.log({ createEmpResponse: response });
        thunkApi.dispatch(setNewEmployeeId(response.data._id));
        return response;
      }
      else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      else {
        thunkApi.dispatch(setError(response.message));
        return thunkApi.rejectWithValue(response.message);
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createEmployeeSlicer = createSlice({
  name: "createEmployee",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload;
      state.status = action.payload?.status || undefined;
    },
    setNewEmployee: (state, action: PayloadAction<any>) => {
      state.newEmployeeForm = action.payload;
    },
    setNewEmployeeId: (state, action: PayloadAction<any>) => {
      state.newEmployeeID = action.payload;
    },
    setIfDepended: (state, action: PayloadAction<any>) => {
      state.isDependent = action.payload;
    },
    setDependents: (state, action: PayloadAction<any>) => {
      state.dependents = action.payload;
    },
    // addDependent: (state, action: PayloadAction<any>) => {
    //   let items = [...state.dependents];
    //   items[items.length - 1] = action.payload;
    //   items.push(INIT_Dependant);
    //   state.dependents = items;
    // },
    // removeDependentByIndex: (state, action: PayloadAction<any>) => {
    //   // let newArray = state.dependents.filter(
    //   //   (_, i: number) => i != action.payload
    //   // );
    //   const index = action.payload;
    //   state.dependents.splice(index,1)
    // },
    partialReset: (state) => {
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
    },
    resetState: (state) => {
      state.dependents = { ...INIT_Dependant };
      state.error = false;
      state.isLoading = false;
      state.message = undefined;
      state.newEmployeeForm = defaultValues;
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createEmployeeAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.message = undefined;
          state.isLoading = true;
          state.status = undefined;
        }
      )
      .addCase(
        createEmployeeAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.newEmployeeForm = {
            ...state.newEmployeeForm,
            _id: action.payload?.data?._id,
          };
          state.isLoading = false;
          state.error = false;
          state.message = undefined;
          state.status = action.payload?.status || undefined;
        }
      )
      .addCase(
        createEmployeeAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = true;
          state.message = action.payload;
          state.status = action.payload?.status || undefined;
        }
      );
  },
});

export const {
  setError,
  setNewEmployee,
  setDependents,
  setIfDepended,
  resetState,
  partialReset,
  setNewEmployeeId
} = createEmployeeSlicer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default createEmployeeSlicer.reducer;
//  export default []
