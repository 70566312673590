import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setActiveTab } from "src/Silcers/companyRegistrationSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Button } from "src/components/ui/button";

const TABLE_DATA = [
  {
    "S/N": 1,
    "Offence": "Failure of a corporate entity to file EEL.",
    "Details": "Failure of a corporate entity to file EEL within thirty (30) days.",
    "Penalty": "Liable to a fine of three million Naira."
  },
  {
    "S/N": 2,
    "Offence": "Failure to register new employees within the stipulated time.",
    "Details": "Failure to register employee within thirty (30) days.",
    "Penalty": "Liable to a fine of three million Naira."
  },
  {
    "S/N": 3,
    "Offence": "Submitting forged information in the EEL",
    "Details": "Falsification of information on EEL",
    "Penalty": "Liable to a fine of three million Naira"
  },
  {
    "S/N": 4,
    "Offence": "Failure in renew EEL before the expiry date",
    "Details": "Failure of a corporate entity to renew EEL within thirty (30) days.",
    "Penalty": "Liable to a fine of three million Naira."
  }
]


function LevyDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="p-6 flex flex-col justify-center items-center">
      <div className=""></div>
      <div className="md:w-4/6">
        <div></div>
        <div className=" h-[280px] overflow-auto bg-white flex flex-col  mt-5 ">
          <div className=" flex justify-center">
            <PageHeadingComponent mainContent={"LEVY DETAILS"} />
          </div>

          <div className="mt-5">
            <p className="mb-2">
              <b>
                The Levy will be automatically calculated based on the
                Designation of the Expatriate(s) selected.
              </b>
            </p>
            <p className="text-primary mb-2">
              <b>Amount of Expatriate Levy payable according to designations is as below: </b>
            </p>
            <div>• Director - USD 15,000 </div>
            <div>• Non-Director - USD 10,000 </div>
            <div>• Non working dependants - Gratis (no levy payable)</div>
            <div>• A CERPAC Number is not required for dependents under 18 years old.</div>
            <div>• If the Quota is valid for less than a year, the EEL fee will be calculated proportionally.</div>
            <p className="mt-4">
              <b>Details of Offences and Sanctions/ Penalties  </b>
            </p>
            <div className="c-table mt-5">
              <table >
                <thead >
                  <tr >
                    {["S/N", "OFFENCE", "DETAILS", "PENALTY"].map(
                      (d, indx: number) => (
                        <th>{d}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {
                    TABLE_DATA.map((d:any)=>{
                        return <tr>
                          <td>{d["S/N"]}</td>
                          <td>{d["Offence"]}</td>
                          <td>{d["Details"]}</td>
                          <td>{d["Penalty"]}</td>
                        </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div > 
        <div className="flex flex-col-reverse md:flex-row justify-center w-full  mt-5 ">
          {/* <Button
            onClick={(e) => {
              dispatch(setActiveTab(1))
            }}
            className="my-2 px-24 mx-4 border border-primary"
            variant={"outline"}
          >
            Register Now
          </Button> */}
            <Button
              onClick={(e) => {
                dispatch(setActiveTab(1));
              }}
              className="my-2 px-24 mx-4 border border-primary"
              variant={"outline"}
            >
              Back
            </Button>
 
            <Button
              onClick={() => {
                 dispatch(setActiveTab(1));
          }}
        className="my-2 px-24 mx-4"
      >
        Continue
            </Button>
    </div>
      </div >   
    </div >
  ); 
} 

export default LevyDetails;
