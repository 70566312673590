import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { setAuth } from 'src/Modules/Auth/Core';
import { getUserByToken, login } from 'src/services/services';
import { RootState } from 'src/store';
import { IgetUserByTokenRequest, ILogin, ILoginRequestData } from 'src/types';


export const initaiState: ILogin = {
    user_id: undefined,
    isLoggedIn: false,
    isLoading: false,
    bussienssEmail: undefined,
    error: false,
    errorMessasge: undefined,
    accessToken: '',
    user: undefined,
    refreshToken: '',
    attempts: undefined,
    isNewUser: false,
    status: 0,
    isVerified: false
}


export const loginAsync: any = createAsyncThunk(
    'auth/login',
    async (userCredentials: ILoginRequestData, thunkApi: any) => {
        // console.log(userCredentials);
        try {
            thunkApi.dispatch(resetError());
            const response = await login(userCredentials);
            // console.log("While LOGIN", response.data.comapnyName);

            if (response.status === 200 && response.data?.accessToken) {
                setAuth({
                    api_token: response.data.accessToken,
                    user_id: response.data._id,
                    user_name: response.data.comapnyName,
                });
                return response; // Resolve the Promise with the successful response
            }
            else {
                // Handle the case where the response is not successful

                const errorMessage = response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }
        } catch (_error) {
            // Handle other errors, such as network errors
            console.error('Login failed:', _error);
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                if (error.response?.status == 401 || error.response?.data?.status == 401) {
                    const count = error.response?.data?.message?.replace(/[^0-9]+/g, "");
                    if (count !== 0) {
                        thunkApi.dispatch(setCounter(parseInt(count)));
                    }
                    else {
                        thunkApi.dispatch(setCounter(3));
                    }
                    thunkApi.dispatch(setUserEmail(userCredentials.businessEmail));
                }

                thunkApi.dispatch(setError({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                }));
                return thunkApi.rejectWithValue({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                });
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const getUserByTokenAsync: any = createAsyncThunk(
    'auth/getUserByToken',
    async (_, thunkApi: any) => {
        // console.log(getUserByTokenAsyncCredentials);
        try {
            const response = await getUserByToken();
            // console.log({ getUserByTokenAsync: response });

            if (response?.status == 200) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                }));
                return thunkApi.rejectWithValue({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                });
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const loginSlicer = createSlice({
    name: 'auth',
    initialState: initaiState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.errorMessasge = action.payload?.message;
            state.status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.errorMessasge = undefined;
        },
        refreshToken: (state, { payload }) => {
            state.user.accessToken = payload.acessToken;
            state.user.refreshToken = payload.refreshToken;
        },
        setUser: (state, { payload }) => {
            state.user = payload
        },
        setIsVerified: (state, { payload }) => {
            state.isVerified = payload
        },
        setIsNewUser: (state, { payload }) => {
            state.isNewUser = payload
        },
        reseteAttempt: (state) => {
            state.attempts = 0
        },
        setUserEmail: (state, { payload }) => {
            state.bussienssEmail = payload
        },
        reseteState: (state) => {
            state.user_id = undefined
            state.isLoggedIn = false
            state.isLoading = false
            state.bussienssEmail = undefined
            state.error = false;
            state.errorMessasge = undefined;
            state.accessToken = '';
            state.user = undefined;
            state.refreshToken = '';
            state.attempts = 0;
            state.isNewUser = false;
            state.status = 0;
            state.isVerified = false;
        },
        setCounter: (state, action: PayloadAction<any>) => {
            state.attempts = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.fulfilled, (state, { payload }) => {
                // console.log({ payload });
                state.isLoggedIn = true;
                state.accessToken = payload.data.accessToken;
                state.user_id = payload.data._id;
                state.user = payload.data
                state.error = false;
                state.isLoading = false;
                state.isNewUser = payload.newuser
                state.status = payload.status
                state.isVerified = payload.data.kyc_status
            })
            .addCase(loginAsync.pending, (state) => {
                state.isLoggedIn = false;
                state.isLoading = true;
                state.isNewUser = false
            })
            .addCase(loginAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.errorMessasge = payload.message;
                state.isLoggedIn = false;
                state.isLoading = false
                state.status = payload.status
                state.isNewUser = false
            })
            .addCase(getUserByTokenAsync.fulfilled, (state, { payload }) => {
                state.isLoggedIn = true;
                //state.accessToken = payload.data.accessToken;
                state.user = payload.data;
                state.isNewUser = payload.newuser
                state.error = false;
                state.isLoading = false
            })
            .addCase(getUserByTokenAsync.pending, (state) => {
                state.isLoggedIn = false;
                state.isLoading = true;
                state.isNewUser = false;
            })
            .addCase(getUserByTokenAsync.rejected, (state) => {
                state.isLoggedIn = false;
                state.isLoading = false;
                state.isNewUser = false;
            })
    }

});

export const { setError, refreshToken, resetError, setUser, reseteAttempt, setUserEmail, reseteState, setIsNewUser, setCounter, setIsVerified } = loginSlicer.actions;

export const getLoginReducerState = (state: RootState) => state.login;

export default loginSlicer.reducer;