import React from "react";
import { Button } from "../ui/button";

type Props = {
  onclick: (e:any) => void;
  title: string;
};

const LightBtn = ({ onclick, title, ...props }: Props) => {
  return (
    <Button
      type={'button'}
      onClick={(e:any)=>onclick(e)}
      className="mb-2 px-10 md:w-44 mx-5 bg-light-primary border-2 border-primary hover:bg-primary hover:text-white"
      {...props}
    >
      {title}
    </Button>
  );
};

export default LightBtn;
