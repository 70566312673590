import { useEffect, useMemo, useState } from "react";
import { Button } from "src/components/ui/button";

import { Loader2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { format } from "date-fns";
import { Label } from "src/components/ui/Label";
import { columns } from "../QuotaList/Table/components/columns";
import { DataTable } from "../QuotaList/Table/components/data-table";
import { ExpatriateQuotaDetail } from "src/types";
import { IQuotaDetailsView, IQuotaDetailsViewPositions, IQuotaSetupForm, QuotaDetailsViewInit, QuotaSetupFormFileds, tabelHeaders, tabelHeadersForViewQuotaDetails } from "../module";
import TableComponent from "src/components/Common/TableComponent";
import { fetchQuotaDetailsByIdAsync } from "src/Silcers/CompanyQuota/QuotaListSlicer";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import InformDialog from "src/components/InformDialog";
import ViewFIleDialog from "src/components/ViewFIleDialog";
// import DependentsList from "src/Modules/Dependents/DependentsList";


const RenderFileds = ({ formObject: { key, label, description }, formdata, value }: {
  formObject: { key: string; label: string, description?: string }, formdata: any, value?: any
}) => {

  console.log({ formdata, key, value: formdata[key],label});


  value = formdata[key]
  
  return <div className='flex flex-col'>
    <Label className="uppercase">{label}</Label>
    {description && <div className=' text-xs text-gray-600'>{description}</div>}
    <p className=" text-sm text-gray-500">
      {
        value ?
          ["expirationDate", "approvedDate", "approvalLetterDate"].includes(key) ?
            <>
              {!value.includes("/") && format(new Date(value), "dd/MM/yyyy")}
              {value.includes("/") && value}
            </> :
            ["additional_doc", "expatriate_doc"].includes(key) ?
            <ViewFIleDialog doc_id={formdata._id} fileType={"quotadoc"} file_key={key} title={label} file_ext={formdata[key] as string} />:
              <>
                {value && (typeof value === "string" || typeof value === "number") && value}
                {value && (value instanceof File) && value.name}
              </>
          : ""
      }
    </p>
  </div>
}


function QuotaView() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  const data = useSelector((state: RootState) => state?.quotaList.QuotaDetail);

  // console.log({ data });

  useEffect(() => {
    if (id) {
      dispatch(fetchQuotaDetailsByIdAsync(id));
    }
    return () => { };
  }, [id]);

  useEffect(() => {
    // dispatch(resetState());
    return () => { };
  }, []);

  const TableData: {
    Position: string | undefined,
    SlotApproved: string | undefined,
    utillizedslots: string | undefined,
  }[] | undefined = useMemo(() => data.position && data.position.map((d: IQuotaDetailsViewPositions) => ({
    Position: d.Position,
    SlotApproved: d.SlotApproved,
    utillizedslots: d.utillizedslots
  })), [data.position])


  // console.log({data})


  return (
    <div className="p-4 lg:mx-auto lg:w-4/4 border rounded">
      <PageHeadingComponent
        mainContent={"view Quota Details"}
        subContent={""}
      />

      {/*  */}

      <div className="card bg-base-100 shadow-sm rounded-md">
        <div className=" grid grid-cols-1 gap-4 p-4">
          {/* Marital Status and Sex */}
          <div className="grid grid-cols-2 gap-x-2">
            <RenderFileds formObject={QuotaSetupFormFileds.approvalNumber} formdata={data} />
            <RenderFileds formObject={QuotaSetupFormFileds.approvedDate} formdata={data} />
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            <RenderFileds formObject={QuotaSetupFormFileds.approvalLetterDate} formdata={data} />
            <RenderFileds formObject={QuotaSetupFormFileds.totalSlotApproved} formdata={data} />
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            <RenderFileds formObject={QuotaSetupFormFileds.companyName} formdata={data} />
            <RenderFileds formObject={QuotaSetupFormFileds.RCNumber} formdata={data} />
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            <RenderFileds formObject={QuotaSetupFormFileds.expirationDate} formdata={data} />
            <div>
              {/* empty */}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            <RenderFileds formObject={QuotaSetupFormFileds.expatriateType} formdata={data} />
            <RenderFileds formObject={QuotaSetupFormFileds.expatriate_doc} formdata={data} />
          </div>
          <div className="flex flex-col">
            <RenderFileds formObject={{...QuotaSetupFormFileds.additional_name,label:"Additional Document"}} formdata={data} />
            <RenderFileds formObject={QuotaSetupFormFileds.additional_doc} formdata={data} />
          </div>
        </div>

        <div className="p-2">
          <div className="uppercase text-primary font-semibold my-2">Positions </div>
          {/* <DependentsList /> */}
          <TableComponent data={TableData || []} headers={tabelHeadersForViewQuotaDetails} showSRNO={true} />
        </div>

        <div className="flex justify-center w-full">
          {/* <Button
            onClick={() => navigate({ pathname: `/employee-update/${id}` })}
            variant="outline"
            className="mb-2 px-10 w-44 mx-5"
          >
            Edit
          </Button> */}
          <Button
            disabled={isLoading}
            variant={
              isLoading ? "secondary" : isError ? "destructive" : "default"
            }
            onClick={() => {
              navigate("/employee");
            }}
            className="mb-2 px-10 w-44 mx-5"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuotaView;
