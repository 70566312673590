import React, { useEffect, useState } from 'react'
import CustomeDialog from '../Common/CustomeDialog'
import { getUpladedFile } from 'src/services/services'
import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { blob } from 'stream/consumers';
import axios, { AxiosError } from 'axios';
import { getUploadFileData } from 'src/Helpers';
import { ImageOff } from 'lucide-react';

type Props = {
    title: string,
    fileType: string,
    doc_id: string,
    file_key: string,
    file_ext: string
}

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ViewFIleDialog = ({ title, fileType, doc_id, file_key, file_ext }: Props) => {

    // console.log({  title, fileType, doc_id, file_key, file_ext  })

    const [isLoading, setisLoading] = useState(false)
    const [numPages, setNumPages] = useState<any>(null);
    const [pdfBlob, setPdfBlob] = useState<any>(null);
    const [imageBlob, setimageBlob] = useState<any>(null);
    const [isOpen, setisOpen] = useState("hide")
    const [isErrorOnDcoumentLoad, setisErrorOnDcoumentLoad] = useState(false)

    useEffect(() => {
        if (isOpen === "show" && fileType && doc_id && file_key && file_ext)
            fetchDocument()
        return () => {
            setPdfBlob(null);
            setimageBlob(null)
        }
    }, [isOpen])

    const fetchDocument = async () => {
        try {
            if (isOpen === "show") {
                setisErrorOnDcoumentLoad(false)
                setisLoading(true)
                // console.log({fileType, doc_id, file_key});
                
                const apiResp: any = await getUploadFileData(fileType, doc_id, file_key)
                if (apiResp && apiResp) {
                    if (file_ext && /\.(png|jpg|jpeg)$/i.test(file_ext)) {
                        setimageBlob(apiResp)
                        setPdfBlob(null);
                        setisLoading(false)
                    }
                    else {
                        setimageBlob(null)
                        setPdfBlob(apiResp);
                        setisLoading(false)
                    }
                }
            }

        } catch (error) {
            let err = error as Error | AxiosError;
            if (axios.isAxiosError(err)) {
                // console.log(err.response?.data?.message)
                setisLoading(false)
                setisErrorOnDcoumentLoad(true)
            }
            else {
                // console.log(err.message)
                setisLoading(false)
                setisErrorOnDcoumentLoad(true)
            }
        }
    }

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    return (
        <div className="">
            <div className='text-sm font-normal text-primary cursor-pointer uppercase' onClick={() => setisOpen("show")}>View Document</div>
            <CustomeDialog isOpen={isOpen == "show"} headerTitle={title} hideDefaultSubmit={true} onCancle={() => setisOpen("hide")}  >
                <div className='p-4 w-100 h-100 overflow-scroll ' >
                    
                    {isLoading && <div className=' w-100 flex justify-center'>Loading....</div>}
                    {isErrorOnDcoumentLoad && <div className=' w-100 flex flex-col justify-center items-center space-y-2'>
                        <ImageOff className='text-gray-500' size={20} /> <span>Error on image load</span>
                    </div>}
                    <div className='w-100 max-h-[70vh] scroll-auto'>
                        {imageBlob && <img src={`${imageBlob}`} />}
                    </div>

                    {pdfBlob && (
                        <div style={{ width: '100%', height: '500px' }}>
                            <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                        </div>
                    )}

                </div>
            </CustomeDialog>
        </div>
    )
}

export default ViewFIleDialog