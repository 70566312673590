import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const taskSchema = z.object({
  _id: z.any(),
  uploadprofileimage: z.any(),
  surname: z.any(),
  sex: z.any(),
  designation: z.any(),
  nationality: z.any(),
  passportnumber: z.any(),
  passportexpiry: z.any(),
  visanumber: z.any(),
  cerpac_number: z.any(),
  cerpac_expiry: z.any(),
  visatype: z.any(),
  visaexpiracydate: z.any(),
  paymentstatus: z.any(),
  emp_insertstatus: z.any(),
  kyc_status: z.any(),
  quota_expiry:z.any(),
  card_expiry:z.any(),
  empstatus:z.any(),
})

export type Task = z.infer<typeof taskSchema>
