import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addition_of_key_from_Array_of_object } from 'src/Helpers'
import { setActiveTab, setQuotaDetails } from 'src/Silcers/CompanyQuota/createQuotaSetupSlicer'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import FormikSingleFileUpload from 'src/components/ui/FileUploadInput/FormikSingleFileUpload'
import { Button } from 'src/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'
import { IQuotaSetupForm, QuotaSetupFormFileds, QuotaSetupForm_INIT, QuotaSetupForm_Schema, positionsData, tabelHeaders } from '../module'
import AddPositionsForm from './AddPositionsForm'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { Label } from 'src/components/ui/Label'


type Props = {}

const SetupForm = (props: Props) => {

    const dispatch = useDispatch()
    const { login: { user }, createQuotaSetup: { quotaDetails } } = useSelector((state: RootState) => state)
    const [openAddPositionDialog, setopenAddPositionDialog] = useState<boolean>(false)
    const toggleOpenAddPositionDialog = () => setopenAddPositionDialog(!openAddPositionDialog)

    const formik = useFormik({
        initialValues: QuotaSetupForm_INIT,
        validationSchema: QuotaSetupForm_Schema,
        onSubmit(values, formikHelpers) {
            dispatch(setQuotaDetails(values))
            dispatch(setActiveTab(2))
        },
    })

    useEffect(() => {
        if (user && user.comapnyName && user.rcNumber) {
            formik.setFieldValue("companyName", user.comapnyName)
            formik.setFieldValue("RCNumber", user.rcNumber)
        }
        return () => { }
    }, [user.comapnyName, user.rcNumber])

    useEffect(() => {
        if (quotaDetails) {
            formik.setValues(quotaDetails as any)
            if (!quotaDetails.companyName && !quotaDetails.RCNumber) {
                formik.setFieldValue("companyName", user.comapnyName)
                formik.setFieldValue("RCNumber", user.rcNumber)
            }
        }

        return () => { }
    }, [])



    // console.log({ values: formik.values, errors: formik.errors });


    return (
        <>
            {/* Headers */}
            <div className='px-4 py-2 font-semibold text-primary uppercase'>Quota Setup</div>
            {/* Quota Setup form  */}

            <form onSubmit={formik.handleSubmit} className='mb-4'>
                <div className='grid grid-cols-2 gap-3'>
                    <div className="px-4 py-2 ">
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.companyName}
                                required
                                onchange={(e: any) => formik.setFieldValue("companyName", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.RCNumber}
                                required
                                onchange={(e: any) => formik.setFieldValue("RCNumber", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.approvalNumber}
                                required
                                onchange={(e: any) => formik.setFieldValue("approvalNumber", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.expatriateType}
                                required
                                onchange={(e: any) => formik.setFieldValue("expatriateType", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.expirationDate}
                                required
                                onchange={(e: any) => formik.setFieldValue("expirationDate", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.totalSlotApproved}
                                required
                                onchange={(e: any) => {
                                    const position_count = formik.values.position ? addition_of_key_from_Array_of_object(formik.values.position, "position_count") : 0
                                    formik.setFieldValue("totalSlotApproved", e);
                                    formik.setFieldValue("availableQuota", Number(e) - Number(position_count));
                                }}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.approvedDate}
                                required
                                onchange={(e: any) => formik.setFieldValue("approvedDate", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={QuotaSetupFormFileds.approvalLetterDate}
                                required
                                onchange={(e: any) => formik.setFieldValue("approvalLetterDate", e)}
                            />
                        </div>
                        <div>
                            <RenderFormikFiled
                                formik={formik}
                                value={formik.values.expatriate_doc}
                                formObject={QuotaSetupFormFileds.expatriate_doc}
                                required
                                onchange={(e: any) => formik.setFieldValue("expatriate_doc", e)}
                            />
                        </div>
                        <div className='my-2'>
                            <Label className='uppercase ' >Additional Document</Label>
                            <p className='text-xs text-gray-500' >Other additional document related to Quota approval or Quota return certificate.</p>
                        </div>
                        <div className='grid grid-cols-2 gap-x-2'>
                            <div>
                                <RenderFormikFiled
                                    formik={formik}
                                    formObject={QuotaSetupFormFileds.additional_name}
                                    onchange={(e: any) => formik.setFieldValue("additional_name", e)}
                                />
                            </div>
                            <div>
                                <RenderFormikFiled
                                    formik={formik}
                                    value={formik.values.additional_doc}
                                    formObject={QuotaSetupFormFileds.additional_doc}
                                    onchange={(e: any) => formik.setFieldValue("additional_doc", e)}
                                />
                            </div>
                        </div>

                    </div>
                    <div className=''>
                        <div className='border p-2'>
                            <div>
                                {formik.touched.position && formik.errors.position && <p className='text-xs text-destructive'>
                                    {formik.errors.position}
                                </p>}
                            </div>
                            <div className='flex justify-between'>
                                <div>
                                    <div className='text-primary uppercase font-semibold text-sm'>Positions</div>
                                    <div className=' text-xs text-gray-400'>Available Quota : {formik.values.availableQuota}</div>
                                </div>
                                <Button disabled={Number(formik.values.availableQuota) === 0} type='button' onClick={toggleOpenAddPositionDialog}>
                                    Add Position
                                </Button>
                            </div>
                            <div className='my-4'>
                                <Table>
                                    <TableHeader>
                                        <TableRow className='py-2'>
                                            {
                                                tabelHeaders.map((headerName: string) => {
                                                    return <TableHead className="uppercase font-semibold text-gray-500 text-xs bg-secondary h-8">{headerName}</TableHead>
                                                })
                                            }
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            formik.values.position && formik.values.position.length > 0 ? formik.values.position.map(({ position_name, position_count }: positionsData, indx: number) => {
                                                return <TableRow >
                                                    <TableCell  >{indx + 1}</TableCell>
                                                    <TableCell >{position_name}</TableCell>
                                                    <TableCell >{position_count}</TableCell>
                                                    <TableCell >
                                                        <Button
                                                            onClick={() => {
                                                                let newData: any = [...formik.values.position].filter((o: any, ndx: number) => ndx !== indx);
                                                                formik.setFieldValue("availableQuota", Number(formik.values.availableQuota) + Number(position_count))
                                                                formik.setFieldValue("position", newData)
                                                            }}
                                                            className='h-7 text-xs bg-secondary text-secondary-foreground hover:text-white hover:bg-destructive '>Remove</Button>
                                                    </TableCell>
                                                </TableRow>
                                            }) :
                                                <TableRow>
                                                    <TableCell className='text-center' colSpan={tabelHeaders.length} >No Records</TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <Button
                        // disabled={isSubmiting || isLoading}
                        variant={Object.values(formik.errors).length > 0 && Object.values(formik.touched).length > 0 ? "destructive" : "default"}
                        className="mb-2 px-10 w-44 mx-5"
                    // onClick={SubmitForm}
                    >
                        {/* {(isSubmiting || isLoading) && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )} */}
                        Continue
                    </Button>
                </div>
            </form>

            <CustomeDialog
                isOpen={openAddPositionDialog}
                headerTitle='Add Position'
                isLoading={false}
                hideDefaultCancle
                hideDefaultSubmit
            >
                <AddPositionsForm onCancel={toggleOpenAddPositionDialog} onSubmit={(e: positionsData) => {
                    toggleOpenAddPositionDialog()
                    // console.log(formik.values.position.filter((d: positionsData) => d.position_name == e.position_name))
                    if (formik.values.position.filter((d: positionsData) => d.position_name == e.position_name).length > 0) {
                        let oldData: any = formik.values.position.find((d: positionsData) => d.position_name == e.position_name);
                        let findIndexofobject = formik.values.position.findIndex((d: positionsData) => d.position_name == e.position_name);
                        oldData = {
                            position_name: oldData?.position_name,
                            position_count: Number(oldData?.position_count) + Number(e.position_count)
                        }
                        let newData: any = [...formik.values.position];
                        newData[findIndexofobject] = oldData;
                        formik.setFieldValue("position", newData)
                    }
                    else {
                        formik.setFieldValue('position', [...formik.values.position, e])
                    }


                    const totalAvailable = Number(formik.values.availableQuota) == Number(formik.values.totalSlotApproved) ? Number(formik.values.totalSlotApproved) - Number(e.position_count) : Number(formik.values.availableQuota) - Number(e.position_count)
                    formik.setFieldValue('availableQuota', totalAvailable)
                }} total_available_quota={formik.values.availableQuota} />
            </CustomeDialog >
        </>
    )
}

export default SetupForm