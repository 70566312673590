import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { createNewEmployee, createNewPaymentRequest, getAllEmployees, getEmployeeDetails, getPaymentLists, sendPaymentStatus } from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'

// Define a type for the slice state
interface IPaymentsListReducer {
    allPayments: any[],
    isLoading: boolean,
    selectedTransactions:any,
    isError: boolean,
    errorMessage: string | undefined
}

// Define the initial state using that type
const initialState: IPaymentsListReducer = {
    allPayments: [],
    selectedTransactions:null,
    isLoading: false,
    isError: false,
    errorMessage: undefined
}


export const getAllPaymentsAsync: any = createAsyncThunk(
    'employees/getAllPayments',
    async (_, thunkApi) => {
        try {
            const user = getAuth();
            const response: any = user?.user_id && await getPaymentLists(user?.user_id);
            // console.log({ response })
            if (response) {
                return response?.paymenobj;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const paymentsListSlicer = createSlice({
    name: 'paymentsList',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.allPayments = [];
            state.errorMessage = action.payload
        },
        setTransaction: (state, action: PayloadAction<any>) => {
            state.selectedTransactions = action.payload;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(getAllPaymentsAsync.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = true;
                state.isError = false;
                state.allPayments = [];
                state.errorMessage = undefined
            })
            .addCase(getAllPaymentsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.allPayments = action.payload;
                state.errorMessage = undefined
            })
            .addCase(getAllPaymentsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.allPayments = [];
                state.errorMessage = action.payload;
            })
    }
})

export const {
    setError,
    setTransaction,
} = paymentsListSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default paymentsListSlicer.reducer