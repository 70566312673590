import { toAbsoluteUrl } from "src/Helpers";
import { SVGC } from "src/Helpers/SVGC";
import { cn } from "src/lib/utils";

function StepCircle({ children, current, previous }: any) {
  // console.log({ current, previous });

  return (
    // <div className={`w-16 h-16 ${fill ? 'bg-primary':''} rounded-full border bottom-1 border-primary flex items-center justify-center text-white text-lg mr-4`}>
    //     <div className="w-8 h-8 text-primary bg-white rounded-full border bottom-1 border-primary flex items-center justify-center text-gray-600 font-medium text-base">
    //         {children}
    //     </div>
    // </div>
    <div
      className={cn(
        "w-[50px] h-[50px] rounded-full border bottom-1 flex items-center justify-center text-white text-lg mr-4",
        "",
        { "border-primary": current }
      )}
    >
      {
        previous ? (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/tick-circle.svg")}
            className="fill-primary"
          ></SVGC>
        ) : (
          <SVGC
            path={toAbsoluteUrl(`/media/icons/svg/step-${children}.svg`)}
            className={cn("fill-slate-400 w-[32px] h-[32px]", "", {
              "fill-primary": current,
            })}
          ></SVGC>
        )
        // <div className="w-[32px] h-[32px] text-primary bg-white rounded-full border bottom-1 border-primary flex items-center justify-center text-gray-600 font-medium text-base">
        //     {children}
        // </div>
      }
    </div>
  );
}

export default StepCircle;
