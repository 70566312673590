import { useFormik } from 'formik'
import React, { useState } from 'react'
import { EXEMPT_REQUEST_FORM_FILEDS, EXEMPT_REQUEST_FORM_ValidationSchema, INIT_EXEMPT_REQUEST_FORM } from './modules'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import LightBtn from 'src/components/Common/LightBtn'
import SubmitBtn from 'src/components/Common/SubmitBtn'
import LoadingDialog from 'src/components/LoadingDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import axios, { AxiosError } from 'axios'
import { exemptRequestApi } from 'src/services/services'
import { Dialog, DialogContent, DialogHeader } from 'src/components/ui/dialog'
import { checkIfAnyFilesToBeUpload } from 'src/Helpers'

type Props = {
    isOpen: "show" | "hide",
    onChangeIsOpen: (e: "show" | "hide") => void,
    rowData: any
}

const ExemptRequestsMain = ({ isOpen, onChangeIsOpen, rowData }: Props) => {

    console.log({ isOpen });


    const [apiRequestState, setApiRequestState] = useState<{
        isLoading: boolean,
        isError: boolean | undefined,
        message: string | undefined,
    }>({
        isLoading: false,
        isError: false,
        message: ""
    })

    const formik = useFormik({
        initialValues: INIT_EXEMPT_REQUEST_FORM,
        validationSchema: EXEMPT_REQUEST_FORM_ValidationSchema,
        onSubmit(values, formikHelpers) {
            handleRequestApi()
        },
    })

    const handleRequestApi = async () => {
        try {
            setApiRequestState({ isLoading: true, message: "Request Sending", isError: false })
            let data = formik.values
            data = await checkIfAnyFilesToBeUpload(data)
            if (data) {
                const apiResp = await exemptRequestApi({
                    ...data,
                    _id: rowData._id,
                });
            }
            setApiRequestState({ isLoading: false, message: "", isError: false })
            onChangeIsOpen("hide")
        } catch (e) {
            const error = e as Error | AxiosError
            if (axios.isAxiosError(error)) {
                setApiRequestState({ isLoading: false, message: error.response?.data?.message || "", isError: true })
            }
            else {
                setApiRequestState({ isLoading: false, message: error.message || "", isError: true })
            }
        }
    }

    return (
        <div>
            <CustomeDialog isOpen={isOpen == "show"} hideDefaultCancle hideDefaultSubmit headerTitle="Apply Exempt Request" >
                {/* <Dialog
                open={isOpen == "show"}
                onOpenChange={(e: any) => onChangeIsOpen(e)}
            >
                <DialogContent className="sm:max-w-[525px]">
                    <DialogHeader>Apply Exempt Request</DialogHeader> */}
                <div className=''>
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            {
                                Object.entries(EXEMPT_REQUEST_FORM_FILEDS).map(([key, value]) => {
                                    return <RenderFormikFiled
                                        formik={formik}
                                        formObject={value}
                                        value={formik.values[key as keyof Object]}
                                        onchange={(e: any) => formik.setFieldValue(key, e)}
                                    />
                                })
                            }
                            <div className='flex justify-center space-x-4 my-4'>
                                <LightBtn title='Cancel' onclick={() => { onChangeIsOpen("hide") }} />
                                <SubmitBtn isLoading={apiRequestState.isLoading} title='Submit' />
                            </div>
                        </form>
                    </div>
                </div>
            </CustomeDialog>
            {/* </DialogContent>
            </Dialog> */}

            <LoadingDialog isOpen={apiRequestState.isLoading} message={apiRequestState.message} />

            <ErrorDialog isOpen={apiRequestState.isError || false} message={apiRequestState.message} onCancelClick={() => setApiRequestState({
                isError: false,
                message: "",
                isLoading: false
            })} />
        </div>
    )
}

export default ExemptRequestsMain