import React from "react";
import CountryCodePIcker from "./CountryCodePIcker";
import { Label } from "@radix-ui/react-label";
import { cn } from "src/lib/utils";
import FormikCountryCodePIcker2 from "./FormikCountryCodePIcker2";

type Props = {
  formik: any;
  label?: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  type?: string;
  onchange?: (e: any) => void;
  disabled?: boolean
  displayCode?: boolean;
};

const FormikCountrycodePicker = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  type,
  onchange,
  displayCode,
  disabled
}: Props) => {
  return (
    <>
      <Label className={cn(`${required ? "required" : ""}`)}>{label}</Label>
      <div className="me-2" >
        <FormikCountryCodePIcker2
          formik={formik}
          qa-data={nameKey}
          displayCode={displayCode}
          placeholder={placeholder}
          label={label}
          nameKey={nameKey}
          disabled={disabled}
          value={formik.values[nameKey] || value}
          onchange={(e: any) => {
            // console.log({ e });
            onchange && onchange(e.replace(/\D+/g, ""));
          }}
        />
      </div>
      {/* {formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive")}>
          {formik.errors[nameKey]}
        </p>
      )} */}
    </>
  );
};

export default FormikCountrycodePicker;
