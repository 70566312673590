import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { createNewEmployee, createNewPaymentRequest, getAllEmployees, getEmployeeDetails, makePaymeny, sendPaymentStatus } from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'
import CryptoJS from 'crypto-js'


interface IPaymentReference {
    "statuscode": string,
    "RRR": string,
    "status": string
}

// Define a type for the slice state
interface IPaymentReducer {
    createPayment: {
        isLoading: boolean,
        isError: boolean,
        message: string,
        data: IPaymentReference | undefined,
        success: boolean,
        failure: boolean
    }
    createPaymentSuccess: boolean,
    createPaymentResponse: any,
    createPaymentIsLoading: boolean,
    createPaymentIsError: boolean,
    createPaymentErrorMessage: string | undefined,
    sendPaymentStatusResponse: any,
    sendPaymentStatusIsLoading: boolean,
    sendPaymentStatusIsError: boolean,
    sendPaymentStatusErrorMessage: string | undefined,
}

export interface IcreatePaymentPostData {
    registration_Object_id: string,
    employees: {
        _id: string,
        name: string,
        amount: string | number,
        email: string
    }[],
    total_amount: string | number
}

export interface ISendPaymentStatusPostData {
    status: string,
    _ids: string[]
}

// Define the initial state using that type
const initialState: IPaymentReducer = {
    createPayment: {
        isLoading: false,
        isError: false,
        message: "",
        data: undefined,
        success: false,
        failure: false
    },
    createPaymentSuccess: false,
    createPaymentResponse: undefined,
    createPaymentIsLoading: false,
    createPaymentIsError: false,
    createPaymentErrorMessage: undefined,
    sendPaymentStatusResponse: undefined,
    sendPaymentStatusIsLoading: false,
    sendPaymentStatusIsError: false,
    sendPaymentStatusErrorMessage: undefined,
}


export const createEmployeePaymentAsync: any = createAsyncThunk(
    'employees/createEmployeePayment',
    async (employeeData: any, thunkApi) => {
        try {

            // console.log("Start Paymnet ");

            var merchantId = process.env.REACT_APP_PAYMENT_MERCHANT_ID;
            var apiKey = process.env.REACT_APP_PAYMENT_API_KEY;
            var serviceTypeId = process.env.REACT_APP_PAYMENT_SERVICE_TYPE_ID;
            var d = new Date();
            var orderId = d.getTime();
            var totalAmount = "20000";


            // console.log({ merchantId, apiKey, serviceTypeId });

            // if (merchantId && serviceTypeId && apiKey) {
            //     var apiHash = String(CryptoJS.SHA512(merchantId + serviceTypeId + orderId + totalAmount + apiKey));
            //     let postData = {
            //         "serviceTypeId": serviceTypeId,
            //         "amount": totalAmount,
            //         "orderId": orderId,
            //         "payerName": "John Doe",
            //         "payerEmail": "doe@gmail.com",
            //         "payerPhone": "09062067384",
            //         "description": "Payment for Septmeber Fees",
            //     }
            //     var headerKey = `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`
            //     const paymentResponse: any = await makePaymeny(postData, headerKey)
            //     console.log({ paymentResponseOG: paymentResponse });
            //     console.log({ paymentResponse: JSON.parse(paymentResponse.replace("jsonp (", "").replace(")", "")) });
            //     return paymentResponse
            // }

            const response: AxiosResponse = await createNewPaymentRequest(employeeData);
            // console.log({ response })
            if (response.status == 201) {
                return response.data;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setCreatPaymentError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setCreatPaymentError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const sendPaymentStatusAsync: any = createAsyncThunk(
    'employees/sendPaymentStatus',
    async (employeeData: ISendPaymentStatusPostData, thunkApi) => {
        try {
            const response: AxiosResponse = await sendPaymentStatus(employeeData);
            if (response.status == 200) {
                return response.data;
            }


        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setCreatPaymentError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setCreatPaymentError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const paymentSlicer = createSlice({
    name: 'payments',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCreatPaymentError: (state, action: PayloadAction<any>) => {
            state.createPaymentIsLoading = false;
            state.createPaymentIsError = true;
            state.createPaymentErrorMessage = action.payload;
            state.createPaymentResponse = undefined;
        },
        setSendPaymentStatusError: (state, action: PayloadAction<any>) => {
            state.sendPaymentStatusIsError = true;
            state.sendPaymentStatusIsLoading = false;
            state.sendPaymentStatusErrorMessage = action.payload;
            state.sendPaymentStatusResponse = undefined
        }
    }, extraReducers: (builder) => {
        builder
            .addCase(createEmployeePaymentAsync.pending, (state, action: PayloadAction<any>) => {
                state.createPaymentIsLoading = true;
                state.createPaymentSuccess = false;
                state.createPaymentIsError = false;
                state.createPaymentErrorMessage = undefined
                state.createPaymentResponse = undefined;
            })
            .addCase(createEmployeePaymentAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.createPaymentIsLoading = false;
                state.createPaymentSuccess = true;
                state.createPaymentIsError = false;
                state.createPaymentErrorMessage = undefined;
                state.createPaymentResponse = action.payload;

                state.createPayment.isLoading = false;
                state.createPayment.isError = false;
                state.createPayment.message = "done";
                state.createPayment.success = true
                state.createPayment.failure = false
                state.createPayment.data = action.payload;

            })
            .addCase(createEmployeePaymentAsync.rejected, (state, action: PayloadAction<any>) => {
                state.createPaymentIsLoading = false;
                state.createPaymentSuccess = false;
                state.createPaymentIsError = true;
                state.createPaymentErrorMessage = action.payload;
                state.createPaymentResponse = undefined;
            })
            .addCase(sendPaymentStatusAsync.pending, (state, action: PayloadAction<any>) => {
                state.sendPaymentStatusIsError = false;
                state.sendPaymentStatusIsLoading = true;
                state.sendPaymentStatusErrorMessage = undefined;
                state.sendPaymentStatusResponse = undefined
            })
            .addCase(sendPaymentStatusAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.sendPaymentStatusIsError = false;
                state.sendPaymentStatusIsLoading = false;
                state.sendPaymentStatusResponse = action.payload;
                state.sendPaymentStatusErrorMessage = undefined
                state.createPaymentSuccess = false
            })
            .addCase(sendPaymentStatusAsync.rejected, (state, action: PayloadAction<any>) => {
                state.sendPaymentStatusIsError = true;
                state.sendPaymentStatusIsLoading = false;
                state.sendPaymentStatusErrorMessage = action.payload;
                state.sendPaymentStatusResponse = undefined
            })

    }
})

export const {
    setCreatPaymentError
} = paymentSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
export const getPaymentState = (state: RootState) => state.paymentReducer

export default paymentSlicer.reducer