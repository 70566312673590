import { AsyncThunk, AsyncThunkAction, AsyncThunkOptions, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../../store/store';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { setAuth } from 'src/Modules/Auth/Core';
import { IRegistration, REGISTRATION_INIT, REGISTRATION_INIT_VALUES } from 'src/Modules/Auth/Regsitration/Components/steps/modules';
import { company_registration, getUserByToken, login } from 'src/services/services';
import { IgetUserByTokenRequest, ILogin, ILoginRequestData, IregistrationRequest } from 'src/types';
import { format_Date_To_DDMMYYYY } from '../Helpers';


const defaultFormValues = REGISTRATION_INIT

export const initaiState: IregistrationRequest = {
    registrationData: defaultFormValues,
    isLoading: false,
    error: false,
    message: undefined,
    activeTab: 1,
    totalTabs: 7
}


export const company_registrationAsync: any = createAsyncThunk(
    'auth/registration',
    async (data: IRegistration, thunkApi: any) => {
        try {
            const postData = {
                typeOfCompany: data.type_of_company,
                // stateOfCompany: data.state_of_company,
                rcNumber: data.rc_number,
                comapnyName: data.company_name,
                countrycode: data.business_phone_code,
                businessPhoneNumber: data.business_phone_number,
                businessEmail: data.business_email,
                comapnyAddress1: data.company_address1,
                comapnyAddress2: data.company_address2,
                typeOfRepresentative: data.type_of_representative,
                nameOfRepresentive: data.name_of_representative,
                designationOfRepresentative: data.designation_of_representative,
                NationalIdentificationNumber: data.national_identification_number,
                nationality: data.nationality_of_representative,
                bankVerificationNumber: data.bank_verification_number,
                passportNumber: data.passport_number,
                passportExpiry: format_Date_To_DDMMYYYY(data.passport_expiry),
                cerpacNumber: data.cerpac_number,
                cerpacExpiry: format_Date_To_DDMMYYYY(data.cerpac_expiry),
                password: data.password,
                securityQuestion1: data.security_question1,
                securityAnswer1: data.security_answer1,
                securityQuestion2: data.security_question2,
                securityAnswer2: data.security_answer2,
                securityQuestion3: data.security_question3,
                securityAnswer3: data.security_answer3,
                is_moi_verified:data.is_moi_verified
                // quotaDetails: data.quotaDetails

            };

            const response: any = await company_registration(postData);
            // console.log({ response });

            if (response.status == 200 || response.status == 201) {
                return response;
            }
            else {
                thunkApi.dispatch(setError(response?.message));
                return thunkApi.rejectWithValue(response?.message);
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log("registration Error ", error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const companyRegistrationSlicer = createSlice({
    name: 'auth',
    initialState: initaiState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload?.error;
        },
        save: (state, action: PayloadAction<IRegistration>) => {
            // console.log({ payload: action.payload });
            state.registrationData = action.payload
            state.activeTab = 6
            state.error = false
        },
        setActiveTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload
        },
        setNextActiveTab: (state) => {
            if (state.activeTab == state.totalTabs) {
                state.activeTab = 1
            }
            else {
                state.activeTab = state.activeTab + 1
            }

        },
        setPreviousActiveTab: (state) => {
            if (state.activeTab == 1) {
                state.activeTab = 1
            }
            else {
                state.activeTab = state.activeTab - 1
            }
        },
        resetstate: (state) => {
            state.activeTab = 1;
            state.error = false;
            state.isLoading = false;
            state.message = undefined;
            state.registrationData = defaultFormValues;
        },
        partialReset: (state) => {
            state.error = false;
            state.isLoading = false;
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(company_registrationAsync.fulfilled, (state, { payload }) => {
                // state.registrationData = payload.data;
                // console.log("registration fulfilled ");
                state.isLoading = false
                // if (payload.status == 200) {
                //     state.error = payload.message
                // }
                // else {
                state.activeTab = 7
                // }
            })
            .addCase(company_registrationAsync.pending, (state) => {
                state.error = false;
                state.isLoading = true
            })
            .addCase(company_registrationAsync.rejected, (state, { payload }) => {
                // console.log("registration rejected ");
                state.error = true;
                state.message = payload;
                state.isLoading = false
            })

    }

});

export const { setError, save, setPreviousActiveTab, setNextActiveTab, setActiveTab, partialReset, resetstate } = companyRegistrationSlicer.actions;

// export const selectAuth = (state: RootState) => state.auth;

export default companyRegistrationSlicer.reducer;