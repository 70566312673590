import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchActiveQuotasAsync, getQuotaReducerState } from 'src/Silcers/CompanyQuota/QuotaListSlicer'
import PageHeadingComponent from 'src/components/Common/PageHeadingComponent'
import { RootState } from 'src/store'
import FiltersAndActions from './Components/FiltersAndActions'
import { columns } from './Table/components/columns'
import { DataTable } from './Table/components/data-table'

type Props = {}

const QuotaList = (props: Props) => {

    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state?.login?.user)
    const { quotas, activeTab, isLoading, quotaType } = useSelector((state: RootState) => getQuotaReducerState(state))

    useEffect(() => {
        if (user.rcNumber || quotaType) {
            dispatch(fetchActiveQuotasAsync(user.rcNumber))
        }
        return () => { }
    }, [user, quotaType])


    return (
        <div className='space-y-4 py-2'>
            <PageHeadingComponent mainContent='Quota List' />
            <FiltersAndActions />
            <DataTable columns={columns} data={quotas} isLoading={isLoading} />
        </div>
    )
}

export default QuotaList