import {
  ColumnDef
} from "@tanstack/react-table";
import { Task } from "../data/schema";
import { format } from "date-fns";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";

export const columns: ColumnDef<Task>[] = [
  {
    id: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => (
      <div className="w-[80px]">
        {row.original.surname + " " + row.original.given_name}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "dob",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="DOB/Sex" />
    ),
    cell: ({ row, table }) => {
      return (
        <div className="flex flex-col">
          <div>{row.original.sex}</div>
          <div>{row.original.dob && format(new Date(row.original.dob), "dd/MM/yyyy")}</div>
        </div>
      );
    },
  },

  {
    accessorKey: "relationship",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Relationship" />
    ),
    cell: ({ row, table }) => {
      return (
        <div>
          <div>{row.original.relationship}</div>
        </div>
      );
    },
  },

  {
    accessorKey: "passport_no",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Passport" />
    ),
    cell: ({ row, table }) => {
      return (
        <div className="flex flex-col">
          <div>{row.original.passport_no}</div>
          <div>
            {row.original.passport_expiry &&
              format(new Date(row.original.passport_expiry), "dd/MM/yyyy")}
          </div>
        </div>
      );
    },
  },

  {
    accessorKey: "visa_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Visa" />
    ),
    cell: ({ row, table }) => {
      return (
        <div className="flex flex-col">
          <div>{row.original.visa_number}</div>
          <div className="flex">
            <div>
              {row.original.visa_issue &&
                format(new Date(row.original.visa_issue), "dd/MM/yyyy")}
            </div>
            <span className="mx-1">-</span>
            <div>
              {row.original.visa_expiry &&
                format(new Date(row.original.visa_expiry), "dd/MM/yyyy")}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "cerpac_no",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="CERPAC" />
    ),
    cell: ({ row, table }) => {
      return (
        <div className="flex flex-col">
          <div>{row.original.cerpac_no}</div>
          <div>
            {row.original.cerpac_expiry &&
              format(new Date(row.original.cerpac_expiry), "dd/MM/yyyy")}
          </div>
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
  },
];
