import { REGSITRATOPM_STEP_1_OPTIONS } from "src/Helpers";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Redirect from "src/Modules/Auth/Regsitration/Components/Redirect";
import { useRegistrationMainContext } from "src/Modules/Auth/Regsitration/RegistrationMainContext";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { useDispatch } from "react-redux";
import {
  resetstate,
  setActiveTab,
  setNextActiveTab,
} from "src/Silcers/companyRegistrationSlicer";
import LightBtn from "src/components/Common/LightBtn";

function StepOneComp() {
  const [iaccepted, setiaccepted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    state: { activeTab },
    updateState,
  } = useRegistrationMainContext();

  return (
    <div className="">
      {REGSITRATOPM_STEP_1_OPTIONS.map(
        ({ title, sub_title, link }: any, indx: number) => {
          return (
            <div
              key={`stepone-${indx}`}
              className={`my-2 flex justify-between p-2 max-w-[600px] border hover:border-primary trasition duration-100 rounded-lg mx-auto`}
            >
              <div className="p-2 mr-4">
                <span className="text-md font-medium">{title}</span>
                <p className="text-sm text-muted-foreground">{sub_title}</p>
                <p
                  // to={link ?? "#"}
                  onClick={() => dispatch(setActiveTab(indx + 2))}
                  className="text-sm inline-block text-primary hover:underline hover:cursor-pointer transition duration-200"
                >
                  Read more
                </p>
              </div>
              <div className="flex items-center">
                <div onClick={() => dispatch(setActiveTab(indx + 2))}>
                  <Redirect link={link} />
                </div>
              </div>
            </div>
          );
        }
      )}

      {/* <div className={`flex justify-between p-2 max-w-[600px] border hover:border-primary rounded-lg mx-auto my-1`}>
                <div className="p-2 mr-4">
                    <span className="text-md font-medium">REQUIREMENT</span>
                    <p className="text-sm text-muted-foreground">Requirement to know the necessary document you would need to complete application</p>
                    <Link to="#" className='text-sm inline-block text-primary hover:underline hover:cursor-pointer transition duration-200'>Read more</Link>
                </div>
                <div className='flex items-center'>
                    <StepCircle children={<></>} fill={false} />
                </div>
            </div>
            <div className={`flex justify-between p-2 max-w-[600px] border hover:border-primary rounded-lg mx-auto`}>
                <div className="p-2 mr-4">
                    <span className="text-md font-medium">FEE DETAILS</span>
                    <p className="text-sm text-muted-foreground">Fee details to view the amount for each service</p>
                    <Link to="#" className='text-sm inline-block text-primary hover:underline hover:cursor-pointer transition duration-200'>Read more</Link>
                </div>
                <div className='flex items-center'>
                    <StepCircle children={<></>} fill={false} />
                </div>
            </div> */}
      <div className="max-w-[600px] mx-auto my-1 flex items-center">
        <Checkbox id="terms" qa-data={'registration-step-1-accept-btn'} onCheckedChange={(e: any) => setiaccepted(e)} />
        <label
          htmlFor="terms"
          className="mx-2 text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          I Accept <Link target="_blank" to={process.env.REACT_APP_TERMS_OF_USE || ""} className="underline text-primary">Terms Of Use</Link> and <Link target="_blank" to={process.env.REACT_APP_PRIVECY_POLICY || ""} className="text-primary underline">Privacy Statement</Link>
        </label>
      </div>
      <div className="flex justify-center my-4">
        <LightBtn title="Back" onclick={() => navigate(-1)} />
          {!iaccepted ? (
            <Button className="w-44" variant={"secondary"} disabled={!iaccepted}>
              Continue
            </Button>
          ) : (
            <Button className="w-44" variant={"default"} onClick={() => dispatch(setActiveTab(5))}>
              Continue
            </Button>
          )}
       
      </div>
    </div>
  );
}

export default StepOneComp;
