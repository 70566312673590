import React, { useEffect, useState } from "react";
import FormkiVirtualSelect from "../VirtualizedSelect/FormkiVirtualSelect";
import { getAllCountries } from "src/services/services";
import axios, { AxiosError } from "axios";
import { optionsType } from "@/helper/index";

type Props = {
  formik: any;
  nameKey: string;
  required?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  onchange?: (e: any) => void;
  optionsWidth?:number,
  disabled?:boolean
};

const FormikCountrycode = ({
  formik,
  nameKey,
  required,
  name,
  label,
  placeholder,
  onchange,
  optionsWidth,
  disabled
}: Props) => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [data, setdata] = useState<optionsType[] | undefined>(undefined);
  const [isError, setisError] = useState<boolean>(false);
  const [errorMesg, seterrorMesg] = useState<string | undefined>(undefined);
  const [isDisabled, setisDisabled] = useState<boolean>(false);

  // console.log({disabled});
  

  useEffect(() => {
    fetchCoutry();
    return () => {};
  }, []);

  const fetchCoutry = async () => {
    try {
      setisLoading(true);
      setisDisabled(true);
      setdata(undefined);
      let apiResp: any = await getAllCountries();
      if (apiResp && apiResp) {
        setisLoading(false);
        setisDisabled(false);
        setdata(
          apiResp?.map((o: any) => ({
            value: o,
            label: o,
            icons: undefined,
            disabled: false,
          }))
        );
        // console.log({ apiResp });
      }
    } catch (e: any) {
      const error = e as Error | AxiosError;
      // console.log("registration Error ", error);
      if (axios.isAxiosError(error)) {
        seterrorMesg(error.response?.data.message);
        setisError(true);
        setisDisabled(true);
        setisLoading(false);
        setdata(undefined);
      } else {
        seterrorMesg(error.message);
        setisError(true);
        setisDisabled(true);
        setisLoading(false);
        setdata(undefined);
      }
    }
  };

  return (
    <FormkiVirtualSelect
      nameKey={nameKey}
      disabled={disabled || false}
      //value={form.getValues(nameKey)}
      formik={formik}
      placeholder={placeholder || "Select country"}
      options={data ?? []}
      label={label || "Country"}
      key={nameKey}
      onChange={(e: any) => {
        // console.log({e});
        onchange && onchange(e);
      }}
      required={required}
      optionsWidth={optionsWidth || 400}
    />
  );
};

export default FormikCountrycode;
