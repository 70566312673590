import { configureStore } from '@reduxjs/toolkit'
import errorSlicer from './Silcers/errorSlicer'
import employeesSlicer from './Silcers/employeesSlicer'
import registrationSlicer from './Silcers/registrationSlicer'
import loginSlicer from './Silcers/loginSlicer'
import companyRegistrationSlicer from './Silcers/companyRegistrationSlicer'
import companySlicer from './Silcers/companySlicer'
import employeeDetailsSlicer from './Silcers/employeeDetailsSlicer'
import CountryStateCityPIckerSlicer from './Silcers/CountryStateCityPIckerSlicer'
import paymentSlicer from './Silcers/paymentSlicer'
import paymentsListSlicer from './Silcers/paymentsListSlicer'
import createEmployeeSlicer from './Silcers/createEmployeeSlicer'
import forgotPasswordSlicer from './Silcers/forgotPasswordSlicer'
import createDependentSlicer from './Silcers/createDependentSlicer'
import completeEmployeeProfileSlicer from './Silcers/completeEmployeeProfileSlicer'
import emailVerifySlicer from './Silcers/emailVerifySlicer'
import createQuotaSetupSlicer from './Silcers/CompanyQuota/createQuotaSetupSlicer'
import setNewPasswordSlicer from './Silcers/setNewPasswordSlicer'
import QuotaListSlicer from './Silcers/CompanyQuota/QuotaListSlicer'
import setPasswordAndSecurityQtnSlicer from './Silcers/setPasswordAndSecurityQtnSlicer'
import changeRepresentativeReducer from './Silcers/changeRepresentativeSlicer'
import assigneEmployeeToEmptyCardSlicer from './Silcers/assigneEmployeeToEmptyCardSlicer'
// import { useDispatch } from 'react-redux'
// import { configureAxiosInterceptor } from './services/axiosSetup'
// // ...

// const dispatch = useDispatch();
// configureAxiosInterceptor(dispatch);

export const store = configureStore({
  reducer: {
    login: loginSlicer,
    employees: employeesSlicer,
    registration: registrationSlicer,
    company_registration: companyRegistrationSlicer,
    compayProfile: companySlicer,
    error: errorSlicer,
    employeeDetails: employeeDetailsSlicer,
    CountryStateCityPIcker: CountryStateCityPIckerSlicer,
    paymentReducer: paymentSlicer,
    paymentsList: paymentsListSlicer,
    createEmployee: createEmployeeSlicer,
    forgotPassword: forgotPasswordSlicer,
    createDependent: createDependentSlicer,
    completeEmployeeProfile: completeEmployeeProfileSlicer,
    emailVerifyByToken: emailVerifySlicer,
    createQuotaSetup: createQuotaSetupSlicer,
    setNewPassword: setNewPasswordSlicer,
    quotaList: QuotaListSlicer,
    passwordAndSecurityQtn: setPasswordAndSecurityQtnSlicer,
    changeRepresentativeReducer: changeRepresentativeReducer,
    assigneEmployeeToEmptyCardReducer:assigneEmployeeToEmptyCardSlicer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch