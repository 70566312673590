import React from "react";
import { MainContextProvider } from "./MainContext";
import EmpUpload from "./EmpUpload";

type Props = {};

const EmployeeMain = (props: Props) => {
  return (
    <MainContextProvider>
      <EmpUpload />
    </MainContextProvider>
  );
};

export default EmployeeMain;
