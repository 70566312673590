import { useEffect, useMemo, useState } from "react";
import { Button } from "src/components/ui/button";

import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";

import { checkIfAnyFilesToBeUpload } from "src/Helpers";
import { partialReset, resetState, setError, updateEmployeeAsync } from "src/Silcers/completeEmployeeProfileSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import ErrorDialog from "src/components/ErrorDialog";
import LoadingDialog from "src/components/LoadingDialog";
import { Label } from "src/components/ui/Label";
import { getProfilePic } from "src/services/services";
import { RootState } from "src/store";
import { cn } from "src/lib/utils";
import { completeEmployeeProfileAsync,getEmployeeDetailsReducerState,resetParial } from "src/Silcers/employeeDetailsSlicer";

function EmployeeCompleteProfileReview() {
  const { completeEmployeeProfile: { newEmployeeForm, error, message, isLoading, status } } = useSelector(
    (state: RootState) => state
  );

  const { employeeViewDetail , error:cpRequestError,errorMessage: cpRequestMessage,status:cpRequestStatus } = useSelector((state:RootState) => getEmployeeDetailsReducerState(state))

  // console.log({ newEmployeeForm });

  const [isError, setisError] = useState(false);

  const formData: any = useMemo(() => newEmployeeForm, [newEmployeeForm]);
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const [profileImage, setProfileImage] = useState<string>("");
  const navigate = useNavigate();
  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });

  useEffect(() => {
    const file = newEmployeeForm?.employee_profile_photo;
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
    else if (
      employeeViewDetail?.employee_profile_photo &&
      typeof employeeViewDetail?.employee_profile_photo === "string"
    ) {
      getProfilePicUrl(employeeViewDetail?._id);
    }
    return () => { };
  }, [newEmployeeForm?.employee_profile_photo]);

  useEffect(() => {
    if (cpRequestStatus == 200) {
      if (formData.is_dependants) navigate("/dependent/create");
      else {
        dispatch(resetState());
        navigate(`/employee`);
      }
    }
    return () => { };
  }, [cpRequestStatus]);

  const getProfilePicUrl = async (id: string) => {
    const apiResp =
      auth?.user_id &&
      id &&
      (await getProfilePic({
        userid: auth?.user_id,
        _id: id,
      }));

    if (apiResp?.photo) {
      setProfileImage(apiResp?.photo);
    }

    // console.log({ apiResp });
  };



  const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
    setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
    if (isError) {
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
      dispatch(partialReset())
      return
    }
  };

  const onSubmit = async () => {
    try {

      // console.log({ newEmployeeForm });
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
      let data: any = newEmployeeForm;
      const compareValues = Object.keys(employeeViewDetail)
        .filter(key => employeeViewDetail[key] !== newEmployeeForm[key as keyof object])
        .reduce((acc: any, key) => {
          acc[key] = newEmployeeForm[key as keyof object];
          return acc;
        }, {});
      data = await checkIfAnyFilesToBeUpload(compareValues, uploadProgress)

      if (data) {
        setTimeout(() => {
          setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          dispatch(
            completeEmployeeProfileAsync({
              ...data,
              id: employeeViewDetail._id
            })
          );
        }, 500);
      }


    } catch (error) {
      const err: any = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        let errormsg = err.response?.data && err.response.data?.message;
        dispatch(setError({
          message: err.response?.data?.message,
          status: err.response?.data?.status || 404,
        }))
      }
      else {
        dispatch(setError({
          message: err?.message,
          status: err?.status || 404,
        }))
      }
    }
  };

  return (
    <div className="p-4 mx-auto w-4/4 border rounded">
      <PageHeadingComponent
        mainContent={"Complete Employee/EXPATRIATE Review"}
        subContent={""}
      />
      <div className="card bg-base-100 shadow-sm rounded-md">
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Passport NO and Passport Expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Passport No</h4> */}
                <Label className="uppercase">Passport No</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_no}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Passport Expiry</h4> */}
                <Label className="uppercase">Passport Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_expiry && !formData?.passport_expiry.includes("/") && format(new Date(formData.passport_expiry), "dd/MM/yyyy")}
                  {formData?.passport_expiry && formData?.passport_expiry.includes("/") && formData.passport_expiry}

                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">PASSPORT DOCUMENT</h4> */}
                <Label className="uppercase">PASSPORT DOCUMENT</Label>
                <p className=" text-sm  text-primary hover:underline">
                  {/* {typeof formData?.passport_doc_url === "string"
                  ? formData?.passport_doc_url
                  : null}*/}
                  {typeof formData?.passport_doc === "string"
                    ? formData?.passport_doc
                    : null}
                  {formData?.passport_doc &&
                    formData?.passport_doc instanceof File
                    ? formData?.passport_doc.name
                    : null}
                </p>
              </div>
            </div>
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Surname</h4> */}
                <Label className="uppercase">Surname</Label>
                <p className=" text-sm text-gray-500">{formData?.surname}</p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Given Name</h4> */}
                <Label className="uppercase">Given Name</Label>
                <p className=" text-sm text-gray-500">{formData?.given_name}</p>
              </div>
            </div>
            {/* M EREG NO  and Type Of Employee*/}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">M. e Reg No.</h4> */}
                <Label className="uppercase">M. e Reg No.</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.mereg_number}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Type Of Employee</h4> */}
                <Label className="uppercase">Type Of Employee</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.type_of_employee}
                </p>
              </div>
            </div>

            {/*  Quota Position and Quota Expiry  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Designation</h4> */}
                <Label className="uppercase">Quota Position</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.quota_position}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Country of origin</h4> */}
                <Label className="uppercase">Quota Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.quota_expiry}
                </p>
              </div>
            </div>

            {/* Country of origin  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Country of origin</h4> */}
                <Label className="uppercase">Country of origin</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.country_of_origin}
                </p>
              </div>
              <div>
                <Label className="uppercase">Name of Company</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.name_of_company}
                </p>
              </div>
            </div>

            {/* Nationality and DOB  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Nationality</h4> */}
                <Label className="uppercase">Nationality</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.nationality}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Date of Birth</h4> */}
                <Label className="uppercase">Date of Birth</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.dob && !formData?.dob.includes("/") && format(new Date(formData.dob), "dd/MM/yyyy")}
                  {formData?.dob && formData?.dob.includes("/") && formData.dob}
                </p>
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="relative w-40 h-40 rounded-full overflow-hidden bg-secondary">
              <label
                htmlFor="profile-upload"
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
              >
                <div className="text-center">
                  <img
                    src={profileImage}
                    alt=""
                    className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                      ? "opacity-50"
                      : ""
                      }`}
                  />
                  {profileImage === "" && (
                    <p className="mt-2 ">Click to upload your Photo</p>
                  )}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 gap-4 p-4">
          {/* Marital Status and Sex */}
          <div className="grid grid-cols-2 gap-x-3">
            <div>
              {/* <h4 className=" uppercase">Marital Status</h4> */}
              <Label className="uppercase">Marital Status</Label>
              <p className=" text-sm text-gray-500">
                {formData?.marital_status}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Sex</h4> */}
              <Label className="uppercase">Sex</Label>
              <p className=" text-sm text-gray-500">{formData?.sex}</p>
            </div>
          </div>
          {/* Maretial Status */}
          <div className="grid grid-cols-2 gap-x-3">
            <div></div>
          </div>

          {/* passport expiry and cerpac expiry date  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Cerpac no / Temporary receipt no</h4> */}
              <Label className="uppercase">
                Cerpac no / Temporary receipt no
              </Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_no}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Cerpac Expiry date</h4> */}
              <Label className="uppercase">Cerpac Expiry date</Label>
              <p className=" text-sm text-gray-500">
                {formData?.cerpac_expiry && !formData?.cerpac_expiry.includes("/") &&
                  format(new Date(formData.cerpac_expiry), "dd/MM/yyyy")}
                {formData?.cerpac_expiry && formData?.cerpac_expiry.includes("/") &&
                  formData.cerpac_expiry}
              </p>
            </div>
          </div>
          {/* upload passport and upload cerpac front */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">upload crepac front </h4> */}
              <Label className="uppercase">upload crepac front</Label>
              <p className=" text-sm  text-primary hover:underline">
                {/* {formData?.cerpac_front_doc_url as any} */}
                {/* {typeof formData?.cerpac_front_doc_url === "string"
                  ? formData?.cerpac_front_doc_url
                  : null} */}
                {typeof formData?.cerpac_front_doc === "string"
                  ? formData?.cerpac_front_doc
                  : null}
                {formData?.cerpac_front_doc &&
                  formData?.cerpac_front_doc instanceof File
                  ? formData?.cerpac_front_doc.name
                  : null}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">upload crepac Back </h4> */}
              <Label className="uppercase">upload crepac Back</Label>
              <p className=" text-sm = text-primary hover:underline">
                {/* {formData?.cerpac_back_doc_url as any} */}
                {/* {typeof formData?.cerpac_back_doc_url === "string"
                  ? formData?.cerpac_back_doc_url
                  : null} */}
                {typeof formData?.cerpac_back_doc === "string"
                  ? formData?.cerpac_back_doc
                  : null}
                {formData?.cerpac_back_doc &&
                  formData?.cerpac_back_doc instanceof File
                  ? formData?.cerpac_back_doc.name
                  : null}
              </p>
            </div>
          </div>
          {/* Visa type and upload cerpac back */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Visa type</h4> */}
              <Label className="uppercase">Visa type</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_type}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Visa Number</h4> */}
              <Label className="uppercase">Visa Number</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_number}</p>
            </div>
          </div>
          {/* Visa no and date of expiry  */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">date of expiry visa</h4> */}
              <Label className="uppercase">visa expiry date</Label>
              <p className=" text-sm text-gray-500">
                {formData?.visa_expiry && !formData?.visa_expiry.includes("/") && format(new Date(formData.visa_expiry), "dd/MM/yyyy")}
                {formData?.visa_expiry && formData?.visa_expiry.includes("/") && formData.visa_expiry}

              </p>
            </div>
            <div>
              <Label className="uppercase">visa document</Label>
              <p className=" text-sm = text-primary hover:underline">
                {typeof formData?.visa_doc === "string"
                  ? formData?.visa_doc
                  : null}
                {formData?.visa_doc &&
                  formData?.visa_doc instanceof File
                  ? formData?.visa_doc.name
                  : null}
              </p>
            </div>
          </div>
          {/* Work status and  Name of Company */}
          <div className=" grid grid-cols-2 gap-x-2">
            {/* <div>
              <h4 className=" uppercase">Work status</h4>
              <p className=" text-sm text-gray-500">{formData?.work_status}</p>
            </div> */}
          </div>
          {/* Employee phone no and employee email */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Employee phone number</h4> */}
              <Label className="uppercase">Employee phone number</Label>
              <p className=" text-sm text-gray-500">
                +{formData?.employee_phone_code}{"-"}
                {formData?.employee_phone_number}
              </p>
            </div>
            <div>
              <Label className="uppercase">Employee email</Label>
              {/* <h4 className=" uppercase">Employee email</h4> */}
              <p className=" text-sm text-gray-500">
                {formData?.employee_email}
              </p>
            </div>
          </div>
          {/* Employee Address 1 / 2 */}
          <div>
            {/* <h4 className=" uppercase">Employee Address 1</h4> */}
            <Label className="uppercase">Employee Address 1</Label>
            <p className=" text-sm text-gray-500">
              {formData?.employee_address_1}
            </p>
          </div>
          <div>
            <Label className="uppercase">Employee Address 2</Label>
            {/* <h4 className=" uppercase">Employee Address 2</h4> */}
            <p className=" text-sm text-gray-500">
              {formData?.employee_address_2}
            </p>
          </div>
          {/* Country and state  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Country</h4> */}
              <Label className="uppercase">Country</Label>
              <p className=" text-sm text-gray-500">{formData?.country}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">State</h4> */}
              <Label className="uppercase">State</Label>
              <p className=" text-sm text-gray-500">{formData?.state}</p>
            </div>
          </div>
          {/* City and Zip code  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">City</h4> */}
              <Label className="uppercase">City</Label>
              <p className=" text-sm text-gray-500">{formData?.city}</p>
            </div>
            <div>
              <Label className="uppercase">Zip Code</Label>
              {/* <h4 className=" uppercase">Zip Code</h4> */}
              <p className=" text-sm text-gray-500">{formData?.zipcode}</p>
            </div>
          </div>

          {/* <div className="grid grid-cols-2 gap-x-2">
            <div>
              <h4 className=" uppercase">Dependents</h4>
              <p className=" text-sm text-gray-500">{formData.is_dependants}</p>
            </div>
          
          </div> */}
        </div>

        <div className="flex justify-center w-full">
          <Button
            onClick={() => navigate({ pathname: `/employee/profile/update/${employeeViewDetail?._id}` })}
            variant="outline"
            className="mb-2 px-10 w-44 mx-5"
          >
            Edit
          </Button>
          <Button
            disabled={isLoading}
            variant={
              isLoading || uploadingFileStatus.isLoading
                ? "secondary"
                : isError
                  ? "destructive"
                  : "default"
            }
            onClick={() => onSubmit()}
            className="mb-2 px-10 w-44 mx-5"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </div>
      {/* <LoadingDialog isOpen={isLoading || isuploadingFiles} />
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(partialReset())} ></ErrorDialog> */}
      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Sending Requests" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={cpRequestError} message={cpRequestMessage} onCancelClick={() => dispatch(resetParial())} />
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(partialReset())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => onSubmit()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />
    </div>
  );
}

export default EmployeeCompleteProfileReview;
