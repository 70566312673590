import * as yup from 'yup'

export interface IExemptRequests {
    exempt_document_one: string | File | undefined,
    exempt_document_two: string | File | undefined
}

export const INIT_EXEMPT_REQUEST_FORM = {
    exempt_document_one: undefined,
    exempt_document_two: undefined
}


export const EXEMPT_REQUEST_FORM_ValidationSchema = yup.object({
    exempt_document_one: yup.mixed().test('exempt_document_one', (value, validationcontext) => {
        const { createError, parent: {  } } = validationcontext;

        if (!value ) {
            return createError({ message: "Please select document" });
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    exempt_document_two: yup.mixed().test('exempt_document_two', (value, validationcontext) => {
        const { createError, parent: {  } } = validationcontext;

        if (!value ) {
            return createError({ message: "Please select document" });
        }

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    })
})

export const EXEMPT_REQUEST_FORM_FILEDS = {
    exempt_document_one: {
        key: "exempt_document_one",
        label: "Select Document 1",
        field_type: "file",
        placeholder: "Select Document",
    },
    exempt_document_two: {
        key: "exempt_document_two",
        label: "Select Document 2",
        field_type: "file",
        placeholder: "Select Document",
    },
}
