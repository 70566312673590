import { IdCardIcon, DotsVerticalIcon } from "@radix-ui/react-icons";
import axios, { AxiosError } from "axios";
import { Loader2 } from "lucide-react";
import { ChangeEvent, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "src/Helpers";
import { SVGC } from "src/Helpers/SVGC";
import StepCircle from "src/Modules/Auth/Regsitration/Components/StepCircle";
import { fetchEmployeeDependentsAsync, fetchEmployeeDetailsAsync } from "src/Silcers/employeeDetailsSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import ErrorDialog from "src/components/ErrorDialog";
import { Input } from "src/components/ui/Input";
import { Label } from "src/components/ui/Label";
import ListActionsDropdowns, {
  ITableActionMenuOptions,
} from "src/components/ui/ListActionsDropdowns";
import TooltipComponent from "src/components/ui/NewYork/TooltipComponent";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  employeeDelete,
  employeeDeleteRequest,
  generateDependentCard,
  uploadfile,
} from "src/services/services";
import { RootState } from "src/store";
import * as z from "zod";

type Props = {
  row: any;
};

const DeleteEmployee = ({ row }: Props) => {
  const { original } = row;
  let { id } = useParams();
  // console.log({ original });


  const navigate = useNavigate();
  const [openFileUploadDialog, setopenFileUploadDialog] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openSubmitted, setopenSubmitted] = useState(false);
  const [openSubmitted2, setopenSubmitted2] = useState(false);
  const [dependedCardGenerateSuccessDialod, setdependedCardGenerateSuccessDialod] = useState(false);


  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, seterrorMessage] = useState<string | undefined>("");
  const [selectedFile, setselectedFile] = useState<any>(undefined);

  const dispatch = useDispatch();

  const { employeeViewDetail } = useSelector(
    (state: RootState) => state.employeeDetails
  );

  const sendDeleteRequest = async () => {
    try {
    } catch (e: any) {
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // console.log({ error: error.response?.data.message });
      }
    }

    setisLoading(true);

    let postData: any = {
      _id: original?._id,
    };

    if (selectedFile && selectedFile instanceof File) {
      const fileUrl = await uploadfile([selectedFile],"quota_doc");
      postData = { ...postData, deletedocuments: fileUrl.data };
    }

    if (original?.paymentstatus == "complete") {
      // const apiResp = await employeeDeleteRequest(postData);
      // if (apiResp?.status == 200) {
      //   setopenSubmitted(true);
      //   setopenDeleteModal(false);
      //   setopenFileUploadDialog(false);
      //   setselectedFile(undefined);
      // }
      // setopenSubmitted(true);
      // setopenDeleteModal(false);
      // setopenFileUploadDialog(false);
      // setselectedFile(undefined);
    } else {
      const apiResp = await employeeDelete(postData);
      if (apiResp?.status == 200) {
        setopenDeleteModal(false);
        setopenSubmitted2(true);
      }
      setopenDeleteModal(false);
      setopenSubmitted2(true);
    }
    setisLoading(false);
  };

  const prepareOptions: ITableActionMenuOptions[] = useMemo(() => {
    let opt: ITableActionMenuOptions[] = [
      {
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/trash.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => { },
        title: "Delete",
      },
      {
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => navigate("/dependent/view/" + original._id),
        title: "View",
      },
      {
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => navigate(`/dependent/edit/${id}/${original._id}`),
        title: "Edit",
      },
    ];

    if (original.employee_payment_status == "complete") {
      if (original.card_generated) {
        opt.push({
          icon: (
            <SVGC
              path={toAbsoluteUrl("/media/icons/svg/list-dependent-card.svg")}
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            />
          ),
          onClick: () => { navigate(`/dependentcards/${id}/${original._id}`) },
          title: "Dependent Card",
        });
      }
      else {
        opt.push({
          icon: (
            <SVGC
              path={toAbsoluteUrl("/media/icons/svg/list-dependent-card.svg")}
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            />
          ),
          onClick: () => generateCard(),
          title: "Generate Card",
        });
      }
    }



    return opt;

  }, [original]);

  const generateCard = async () => {
    try {
      setisLoading(true);
      let postData = {
        _id: original._id,
        employee_id: original.employee_id,
      };
      const apiResp = await generateDependentCard(postData);
      if (apiResp.status == 201) {
        setdependedCardGenerateSuccessDialod(true)
        dispatch(fetchEmployeeDependentsAsync(employeeViewDetail._id))
      }
      // console.log({ apiResp });
      setisLoading(false);
    }
    catch (e) {
      setisLoading(false);
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // console.log({ error: error.response?.data.message });
        seterrorMessage( error.response?.data.message)
      }
    }
  };

  return (
    <div>
      <ListActionsDropdowns
        title="Action"
        options={prepareOptions}
        isLoading={isLoading}
      />

      <div>
        <Dialog
          open={openDeleteModal}
          onOpenChange={(e: any) => setopenDeleteModal(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Delete Confirm"
                subContent={`Are you sure, you want to delete the employee/expatriate`}
              />
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex justify-around">
                <DialogTrigger asChild>
                  <Button variant={"outline"} className=" inline-block px-16">
                    No
                  </Button>
                </DialogTrigger>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    if (original?.paymentstatus == "complete") {
                      setopenFileUploadDialog(true);
                    } else {
                      sendDeleteRequest();
                    }
                  }}
                  className=" inline-block px-16"
                >
                  <div className="flex space-x-2">
                    {isLoading && isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Yes
                  </div>
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openFileUploadDialog}
          onOpenChange={(e: any) => setopenFileUploadDialog(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Request for Quota Deletion"
                subContent={``}
              />
            </DialogHeader>
            <DialogDescription>
              <Label className=" uppercase mb-4">
                please upload quota deletion letter
              </Label>
              <Input
                type="file"
                placeholder="Select Document"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  //console.log(e.target.files)
                  e.target.files && e.target.files.length > 0
                    ? setselectedFile(e.target.files[0])
                    : setselectedFile(undefined)
                }
              />

              <p
                onClick={() => { }}
                className=" text-right uppercase text-primary text-base font-medium my-3 cursor-pointer hover:underline"
              >
                + Add more
              </p>

              <div className="w-full flex justify-around">
                <DialogTrigger asChild>
                  <Button variant={"outline"} className=" inline-block px-16">
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    // setopenFileUploadDialog(false);
                    // setopenDeleteModal(false);
                    // setopenSubmitted(true);

                    sendDeleteRequest();
                  }}
                  className=" inline-block px-16"
                >
                  <div className="flex space-x-2">
                    {isLoading && isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Upload
                  </div>
                </Button>
              </div>
            </DialogDescription>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSubmitted}
          onOpenChange={(e: any) => setopenSubmitted(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Quota deletion request submitted"
                subContent={``}
              />
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex flex-col justify-center text-center">
                <div className="flex justify-center my-2">
                  <StepCircle previous={true} />
                </div>
                <p className="sub-styles text-center mb-3">
                  Thank you for submitting the request. Our team will review the
                  document and notify you via email once the review process is
                  complete.
                </p>
                <div className="flex justify-center">
                  <Button
                    className="w-[150px] inline-block"
                    variant={"default"}
                    onClick={() => {
                      setopenFileUploadDialog(false);
                      setopenDeleteModal(false);
                      setopenSubmitted(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSubmitted2}
          onOpenChange={(e: any) => setopenSubmitted2(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              {/* <PageHeadingComponent
                mainContent="Employee Deleted"
                subContent={``}
              /> */}
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex flex-col justify-center text-center">
                <div className="flex justify-center my-2">
                  <StepCircle current previous={true} />
                </div>
                <p className="sub-styles text-center mb-3">
                  Employee Deleted Successfully
                </p>
                <div className="flex justify-center">
                  <Button
                    className="w-[150px] inline-block"
                    variant={"default"}
                    onClick={() => {
                      setopenSubmitted2(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={dependedCardGenerateSuccessDialod}
          onOpenChange={(e: any) => setdependedCardGenerateSuccessDialod(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              {/* <PageHeadingComponent
                mainContent="Employee Deleted"
                subContent={``}
              /> */}
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex flex-col justify-center text-center">
                <div className="flex justify-center my-2">
                  <StepCircle current previous={true} />
                </div>
                <p className="sub-styles text-center mb-3">
                  Dependent card generated successfully
                </p>
                <div className="flex justify-center">
                  <Button
                    className="w-[150px] inline-block"
                    variant={"default"}
                    onClick={() => {
                      setdependedCardGenerateSuccessDialod(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <ErrorDialog isOpen={isError} message={errorMessage} onCancelClick={() => {
          setisError(false);
          seterrorMessage(undefined)
        }} />
      </div>
    </div>
  );
};

export default DeleteEmployee;
