import {
  ColumnDef
} from "@tanstack/react-table";
import { IQuotaList, IQuotaPositionsList } from "../data/schema";
import Actions from "./Actions";
import { DataTableColumnHeader } from "./data-table-column-header";
import { Badge } from "src/components/ui/badge";
import DocumentLink from "./DocumentLink";
import { ExpatriateQuotaPosition } from "src/types";
import { addition_of_key_from_Array_of_object } from "src/Helpers";
import { format } from "date-fns";

export const columns: ColumnDef<IQuotaList>[] = [
  {
    accessorKey: "approvalNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Approval Number" />
    ),
    enableSorting: false,
    enableHiding: false,

  },

  {
    accessorKey: "expatriateType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Expatriate Type" />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "totalSlotApproved",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Approved Slots" />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    id: "utilizedSlots",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Utilized Slots" />
    ),
    cell({ row }) {
      const data: IQuotaPositionsList = row.original.position;
      if (data && Array.isArray(data) && data.length > 0) {
        return addition_of_key_from_Array_of_object(data, "utillizedslots")
      }
      else return 0
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "untilizedSlots",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Un-Utilized Slots" />
    ),
    enableSorting: false,
    enableHiding: false,
    cell({ row }) {
      const data: IQuotaPositionsList = row.original.position;
      if (data && Array.isArray(data) && data.length > 0) {
        return Number(row.original.totalSlotApproved) - addition_of_key_from_Array_of_object(data, "utillizedslots")
      }
      else return 0
    },
  },

  // {
  //   id: "approvedDate",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Approved Date" />
  //   ),
  //   cell: ({ row }) => row.original.approvedDate && format(new Date(row.original.approvedDate),"dd/MM/yyyy") ,
  //   enableSorting: false,
  //   enableHiding: false,

  // },

  // {
  //   accessorKey: "approvalLetterDate",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Approval Letter Date" />
  //   ),
  //   cell: ({ row }) => row.original.approvalLetterDate && format(new Date(row.original.approvalLetterDate),"dd/MM/yyyy") ,
  //   enableSorting: false,
  //   enableHiding: false,
  // },

  // {
  //   accessorKey: "expatriate_doc",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Expatriate Doc" />
  //   ),
  //   cell({ row }) {
  //     return <DocumentLink title="Document" link={row.original.expatriate_doc} />
  //   },
  //   enableSorting: false,
  //   enableHiding: false,
  // },


  // {
  //   id: "positions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Positions" />
  //   ),
  //   cell({ row }) {
  //     let data = row.original.position;
  //     let positionCounts: any = {}
  //     if (data && Array.isArray(data) && data.length > 0) {
  //       data.forEach((item) => {
  //         const position = item.Position;
  //         if (positionCounts[position]) {
  //           positionCounts[position] += 1;
  //         } else {
  //           positionCounts[position] = 1;
  //         }
  //       });

  //       return Object.keys(positionCounts).map((position) => (
  //         <Badge  className="p-1" variant={"secondary"}>{position} <span className="bg-slate-900 text-white p-2">{positionCounts[position]}</span></Badge>
  //       ));
  //     }
  //   },
  //   enableSorting: false,
  //   enableHiding: false,
  // },

  {
    accessorKey: "expirationDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Expiry Date" />
    ),
    cell: ({ row }) => row.original.expirationDate ,
    enableSorting: false,
    enableHiding: false,
  },

  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
  },
];
