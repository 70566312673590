import { FC, createContext, useContext, useReducer, useState } from 'react';
import { WithChildren } from 'src/Helpers/react18MigrationHelpers';
import { ACTION_TYPES, ICompanyProfile, INIT_COMPANY_PROFILE_FORM, INIT_STATE, MainContextProps, Reducer, company_profile, initialProps } from './modules';


const MainContext = createContext<MainContextProps>(initialProps)

const MainContextProvider: FC<WithChildren> = ({ children }) => {

    const [state, dispatch]: [any, any] = useReducer(Reducer, INIT_STATE, () => INIT_STATE)

    const setActiveTab = (activeTab: number) => {
        dispatch({ type: ACTION_TYPES.SET_ACTIVE_TAB, payload: activeTab })
    }
    const setFormData = (data: ICompanyProfile) => {
        dispatch({ type: ACTION_TYPES.SET_FORM_DATA, payload: data })
    }
    const ResetFormData = () => {
        dispatch({ type: ACTION_TYPES.SET_RESET_FORM_DATA })
    }

    return (
        <MainContext.Provider value={{ state, setActiveTab, setFormData, ResetFormData }}>
            {children}
        </MainContext.Provider>
    )
}

const useMainContext = () => useContext(MainContext)
export { MainContextProvider, useMainContext };
