import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import { Loader2 } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { checkIfAnyFilesToBeUpload, getUploadFileData } from "src/Helpers";
import {
  fetchDependentAsync,
  partialReset,
  resetStatus,
  setDependents,
  setError,
  updateDependentAsync
} from "src/Silcers/createDependentSlicer";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import ErrorDialog from "src/components/ErrorDialog";
import LoadingDialog from "src/components/LoadingDialog";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { cn } from "src/lib/utils";
import { RootState } from "src/store";
import {
  IDependant,
  createDependantSchema,
  createDependentFields,
} from "../modules";
// import RenderFormikFiled from "src/components/Common/RenderFormikFiled";

type Props = {};

const EditDependent = ({ }: Props) => {
  const [isError, setisError] = useState(false);

  const [openDependentSubmitted, setdependentSubmitted] = useState(false);
  const [isLoadingImage, setisLoadingImage] = useState(false)
  const [isCerpacRequired, setisCerpacRequired] = useState(false)
  const { dependents, isLoading, error, message, status } = useSelector(
    (state: RootState) => state.createDependent
  );
  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });

  // console.log({ formData });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, empid } = useParams();

  useEffect(() => {
    if (!dependents?._id && id) {
      dispatch(fetchDependentAsync(id));
      getProfilePicUrl(dependents._id);
    }
    return () => { };
  }, [id]);

  useEffect(() => {
    formik.setValues(dependents)
    return () => {
    }
  }, [dependents])

  useEffect(() => {
    if (status == 200) {
      dispatch(resetStatus())
      navigate(-1)
    }
    return () => { }
  }, [status])


  const [dprofileImage, setdProfileImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (dependents?.dependent_profile_image) {
      const file = dependents?.dependent_profile_image;
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setdProfileImage(e.target?.result as string);
        };
      } else {
      }
    }
    return () => { };
  }, [dependents]);

  const formik = useFormik<IDependant>({
    initialValues: dependents,
    validationSchema: createDependantSchema,
    onSubmit: async (values: IDependant) => {
      // console.log("dependend", values);
      await dispatch(setDependents(formik.values));
      await onSubmit();

    },
  });

  useEffect(() => {
    const file = dependents?.dependent_profile_image;
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setdProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else if (typeof file === "string") {
      // console.log({ dependents });
      //setProfileImage(dependents?.dependent_profile_image);
      getProfilePicUrl(dependents._id);
    }
    return () => { };
  }, [dependents?.dependent_profile_image, dependents?.employee_id]);

  const getProfilePicUrl = async (id: string) => {
    try {
      setisLoadingImage(true)
      const response: any = await getUploadFileData("dependentdocs", id, "dependant_profile_photo");
      if (response) {
        setdProfileImage(response)
      }
      setisLoadingImage(false)
    } catch (error) {
      setisLoadingImage(false)
    }

    // console.log({ apiResp });
  };

  const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
    setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
    if (isError) {
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
      dispatch(partialReset())
      return
    }
  };

  // console.log("DEPENDENTS", dependents);
  const onSubmit = async () => {
    try {

      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })

      let data: any = {
        ...formik.values,
      };
      data = await checkIfAnyFilesToBeUpload(data, uploadProgress)
      if (data) {
        setTimeout(() => {
          setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          // console.log("FINAL SEND DEPENDE", data);
          dispatch(
            updateDependentAsync({
              ...dependents,
              ...data,
              _id: id
            })
          );
        }, 500);
      }

      // navigate(`/dependent/view/${id}`);
    } catch (error) {
      const err = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        dispatch(setError({
          message: err.response?.data?.message,
          status: err.response?.data?.status,
        }))
      }
      else {
        dispatch(setError({
          message: err.message,
          status: 404,
        }))
      }

    }
  };

  const d_profile_img_input_ref = useRef<any>(null);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setdProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("dependent_profile_image", file);
    }
    else {
      setdProfileImage("");
      formik.setFieldValue("dependent_profile_image", "");
    }
  };

  useEffect(() => {
    var _dob = formik.values.dob ? new Date(formik.values.dob) : undefined;
    var today = new Date();
    var eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (_dob && _dob > eighteenYearsAgo) {
      formik.setFieldValue("cerpac_no", "MINOR");
      setisCerpacRequired(false)
    }
    else {
      if (formik.values.cerpac_no?.toLowerCase() === "MINOR") {
        formik.setFieldValue("cerpac_no", "");
      }
      setisCerpacRequired(true)
    }

    // if (formik.values.visa_type == "Residence Visa") {
    //   setisCerpacRequired(false)
    // }

    return () => { }
  }, [formik.values.dob])

  return (
    <div className="p-4 w-4/4 mx-auto">
      {/* <PageHeadingComponent mainContent="Edit dependent" /> */}
      <div className="text-primary text-xl font-semibold uppercase ">Edit Dependent</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.surname}
                  key={createDependentFields.surname.key}
                  onchange={(e: any) => formik.setFieldValue("surname", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.given_name}
                  key={createDependentFields.given_name.key}
                  onchange={(e: any) => formik.setFieldValue("given_name", e)}
                  required
                />
              </div>
            </div>
            {/* Date of birth and Sex */}
            <div className="grid grid-cols-2 gap-x-3">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.dob}
                  key={createDependentFields.dob.key}
                  onchange={(e: any) => formik.setFieldValue("dob", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.sex}
                  key={createDependentFields.sex.key}
                  onchange={(e: any) => formik.setFieldValue("sex", e)}
                  required
                />
              </div>
            </div>
            {/* PAssport no  and  Passport expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.passport_no}
                  key={createDependentFields.passport_no.key}
                  onchange={(e: any) => formik.setFieldValue("passport_no", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.passport_expiry}
                  key={createDependentFields.passport_expiry.key}
                  onchange={(e: any) =>
                    formik.setFieldValue("passport_expiry", e)
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="flex flex-col">
              <div
                onClick={() => d_profile_img_input_ref.current.click()}
                className={cn(
                  "relative w-40 h-40 rounded-full overflow-hidden bg-secondary",
                  {
                    "border border-destructive bg-red-50 ":
                      formik.getFieldProps("dependent_profile_image"),
                  }
                )}
              >
                <label
                  htmlFor="profile-upload"
                  className="absolute inset-0 flex items-center justify-center cursor-pointer"
                >
                  {isLoadingImage ? <>
                    <div className="flex flex-col items-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      <div>Loading..</div>
                    </div>
                  </> :
                    <>
                      <input
                        hidden
                        type="file"
                        ref={d_profile_img_input_ref}
                        name="dependent-profile-pic-input"
                        accept={"image/jpeg,image/png"}
                        className="hidden"
                        onChange={(e: any) => { handleImageUpload(e) }}
                      />
                      <div className="text-center">
                        <img
                          key={"dependent-profile-pic"}
                          src={dprofileImage}
                          alt=""
                          className={`w-full h-full object-cover rounded-full ${dprofileImage === "default-profile-image.jpg"
                            ? "opacity-50"
                            : ""
                            }`}
                        />
                        {!dprofileImage && (
                          <p className="mt-2 sub-styles">
                            Click to upload your Photo
                          </p>
                        )}
                      </div>
                    </>
                  }
                </label>
              </div>
              <div className={"text-xs text-destructive w-40 my-2"}>
                {
                  formik.touched.dependent_profile_image &&
                  formik.errors.dependent_profile_image && (
                    <p className={cn("text-xs font-medium text-destructive")}>
                      {formik.errors.dependent_profile_image as string}
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 p-4">
          <div className="grid xl:grid-cols-3 lg:grid-cols-2 gap-x-2 gap-y-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.passport_doc}
                key={createDependentFields.passport_doc.key}
                value={formik.values.passport_doc}
                onchange={(e: any) => {
                  // console.log({ e });
                  formik.setFieldValue("passport_doc", e);
                }}
                required
              />
            </div>
            {/* Accompany with and relationship  */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.accompany_with}
                key={createDependentFields.accompany_with.key}
                onchange={(e: any) => formik.setFieldValue("accompany_with", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.relationship}
                key={createDependentFields.relationship.key}
                onchange={(e: any) => formik.setFieldValue("relationship", e)}
                required
              />
            </div>

            {/* Visa Type and Visa No  */}

            {/* <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_type}
                key={createDependentFields.visa_type.key}
                onchange={(e: any) => formik.setFieldValue("visa_type", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_number}
                key={createDependentFields.visa_number.key}
                onchange={(e: any) => formik.setFieldValue("visa_number", e)}
                required
              />
            </div> */}
            {/* visa issue date and visa expiry date  */}
            {/* <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_issue}
                key={createDependentFields.visa_issue.key}
                onchange={(e: any) => formik.setFieldValue("visa_issue", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_expiry}
                key={createDependentFields.visa_expiry.key}
                onchange={(e: any) => formik.setFieldValue("visa_expiry", e)}
                required
              />
            </div> */}

            {/* Cerpac number and cerpac issue date  */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_no}
                key={createDependentFields.cerpac_no.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_no", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_issue}
                key={createDependentFields.cerpac_issue.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_issue", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              /> */}
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_expiry}
                key={createDependentFields.cerpac_expiry.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              />
            </div>

            {/* cerpac expiry date */}
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_expiry}
                key={createDependentFields.cerpac_expiry.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              /> */}
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Button disabled={isLoading || uploadingFileStatus.isLoading || isLoadingImage} type="submit" className="mb-2 px-10 w-44 mx-5">
            {(uploadingFileStatus.isLoading || isLoading) && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </form>

      <Dialog open={openDependentSubmitted} onOpenChange={(e) => { }}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle className="flex justify-center mb-5">
              Do you want to add More Dependents ?
            </DialogTitle>
            <DialogDescription className=" flex justify-center ">
              <div className="flex flex-row items-center">
                <Button
                  onClick={() => {
                    setdependentSubmitted(false);
                    // navigate('/employee')
                  }}
                  variant={"secondary"}
                  className="px-8"
                >
                  Cancel
                </Button>
                <Button
                  className="focus:outline-none ml-4 px-8"
                  onClick={() => { }}
                >
                  Add More
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Sending Requests" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(partialReset())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => onSubmit()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />

    </div>
  );
};

export default EditDependent;
