import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from './AssetHelpers'

type Props = {
  className?: string
  path: string
  svgClassName?: string
  skipAbsolute?: boolean
}

const SVGC: React.FC<Props> = ({ className = '', path, skipAbsolute }) => {
  return (
    <span className={`svg-icon `}>
      <SVG src={skipAbsolute ? path : toAbsoluteUrl(path)} className={className} />
    </span>
  )
}

export { SVGC }
