function PrivacyStatement() {
    return (
        <div className=' p-10'>
            <div className="flex flex-col">
                <h1 className="text-3xl text-primary font-semibold mb-4">PRIVACY STATEMENT FOR EEL ONLINE PORTAL</h1>
                <div className=" bg-white shadow-lg rounded-lg p-10 text-black mb-4">
                    <h6 className=" text-primary text-lg font-medium mt-6">Terms of Service (ToS) and user agreement: </h6>
                    <p className=' text-base'>
                        PRIVACY STATEMENT for EEL online portal this privacy statement outlines
                        the practices of EEL online portal operationalized by the Federal Government of
                        Nigeria through the Ministry of Interior/Nigeria Immigration Service referred to as "us,"
                        "we," or "our," operates the Mobile Web Service(MWS) or Web Service (WS), which
                        includes a suite of personal identity services, the EEL website www.eel.interior.gov.ng
                        (and the EEL mobile application, collectively known as the "Service." regarding the
                        collection, use, and protection of personal information provided by visitors to our
                        website.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Privacy statement:</h6>
                    <p className=' text-base'>
                        We may collect the following types of information from visitors to our website:
                        <strong> Personal Information :</strong> This includes but not limited to your name, email address, and
                        any other information you voluntarily provide through contact forms, registration
                        processes, or online services.
                        <strong> Non-Personal Information :</strong> We may collect non-personal information such as browser
                        type, IP address, referring website, pages visited, and the date and time of your visit.
                        This information helps us analysis trends and improve our website's performance.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Security policy:</h6>
                    <p className=' text-base'>
                        We take reasonable measures to protect your personal information from unauthorized
                        access, disclosure, or alteration.
                        The security of your data is important to us, but remember that no method of
                        transmission over the internet or method of electronic storage is 100% secure. While we
                        strive to use commercially acceptable means to protect your personal data, we cannot
                        guarantee its absolute security.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Payment and refund policy:</h6>
                    <p className=' text-base'>
                        The payment method is online and no refunds will be issued.
                        <ul className=" list-disc ml-5 mt-3">
                            <li>Users are not eligible to apply for refunds as per policy.</li>
                            <li>If an employee resigns before the card expiry date, the quota and EEL card will be issued to the new employee joining on the same approved quota. When an employee resigns before the end date of their employment, the company is required to follow certain procedures.</li>
                            <li>If User fails to pay on time or before due date a late fee will be charged. (Reference for late fee are available in handbook)</li>
                            <li>EEL System will not store any kind of financial information like(Card Details, Bank account details or any other details related to banking or finance)</li>
                            <li>In EELMS there is no auto debit facility available for making EEL payments.</li>
                            <li>User can renew the EEL card maximum one month before the expiry date.</li>
                        </ul>
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6"> Application scope:</h6>
                    <p className=' text-base'>
                        This application is categorized into two sections as mentioned below:
                        <ul className=" list-disc ml-5 mt-3">
                            <li><strong>Employee:</strong> The application allows employees to access and download their digital EEL card.</li>
                            <li><strong>Nigeria Immigration Service:</strong> The application enables immigration officers to
                                scan employee cards. The scanning functionality is designed for the
                                sole purpose of verifying card status. If a card is active, the officer will allow
                                travel. If the card is expired, travel will not be permitted by Nigeria Immigration
                                Service (NIS a border control agency of Federal Government of Nigeria).
                            </li>
                        </ul>
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Card issuance policy:</h6>
                    <p className=' text-base'>
                        EEL card is valid only till the quota position is valid for a minimum of 3 months or a
                        maximum for one year. EEL card is applicable for non-citizen of Nigeria or non-resident
                        expatriates who hold quota positions or are employed through a temporary work permit,
                        usually falling within EEL's scope.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Legal compliance:</h6>
                    <p className=' text-base'>
                        EEL may disclose your personal data in the good faith belief that such action is
                        necessary to:
                        <ul className=" list-disc ml-5 mt-3">
                            <li>To comply with a legal obligation.</li>
                            <li>To provide personal identity verification.</li>
                            <li>To protect and defend the rights or property of EEL and/or the Federal Republic
                                Government of Nigeria.</li>
                            <li>To prevent or investigate possible wrongdoing in connection with the Service.</li>
                            <li>To protect the personal safety of users of the Service or the public.</li>
                            <li>To protect against legal liability.</li>
                        </ul>
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Accessibility policy:</h6>
                    <p className=' text-base'>
                        While using our Service, we may ask you to provide us with certain personally
                        identifiable information (PII) that can be used to contact or identify you ("Personal
                        Data"). Personally identifiable information may include, but is not limited to:
                        Phone number Email id, Location, Cookies, and Usage Data.
                        <br /><br />
                        <strong>Data usage: </strong>
                        We may also collect information that your browser sends whenever you visit our
                        Service or when you access the Service by or through a mobile device ("Data usage").
                        This Usage Data may include information such as your device’s Internet Protocol
                        address (e.g. IP address), browser type, browser version, the type of the service that
                        you downloaded (iOS, Android, or Web App), the time and date of your visit, the time
                        spent on those pages. When you access the Service with a mobile device, this Usage
                        Data may include information such as the type of mobile device you use, your mobile
                        device unique ID, the IP address of your mobile device, your mobile operating system,
                        the type of mobile Internet browser you use, unique device identifiers and other
                        diagnostic data.
                        <br /><br />
                        <strong>Tracking & cookies data: </strong>
                        We use cookies and similar tracking technologies to track the activity on our Service
                        and hold certain information. Cookies are files with small amount of data which may
                        include an anonymous unique identifier. Cookies are sent to your browser from a
                        website and stored on your device. Tracking technologies also used are beacons, tags,
                        and scripts to collect and track information and to improve and analyse our Service. You
                        can instruct your browser to refuse all cookies to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our
                        Service. You can learn more how to manage cookies in the Browser Cookies Guide.
                        Examples of Cookies we use:
                        <ul className=" list-disc ml-5 mt-3">
                            <li>Session Cookies: We use Session Cookies to operate our Service.</li>
                            <li>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li>
                            <li>Security cookies: We use Security Cookies for security purposes.</li>
                        </ul>
                        <br /><br />
                        <strong>Use of data : </strong>
                        EEL Mobile uses the collected data for various purposes:
                        <ul className=" list-disc ml-5 mt-3">
                            <li>To provide and maintain the Service.</li>
                            <li>To provide a secure platform to verify your identity to an authorized entity.</li>
                            <li>To keep a log of anyone who may have used this service to verify the identity of another.</li>
                            <li>To notify you about changes to our Service.</li>
                            <li>To allow you to participate in interactive features of our Service when you choose to do so.</li>
                            <li>To provide customer care and support.</li>
                            <li>To provide analysis or valuable information so that we can improve the Service.</li>
                            <li>To monitor the usage of the Service.</li>
                            <li>To detect, prevent and address technical issues.</li>
                        </ul>
                        When personal information (such as a credit card number) is transmitted to other
                        websites, it is protected through the use of encryption, such as the secure socket layer
                        (SSL) protocol.
                        <br /><br />
                        <strong>Transfer of data : </strong>
                        Your information, including Personal Data (such as your email or Mobile Number), may
                        be transferred to—and maintained on — computers located outside of your state,
                        province, country, or other governmental jurisdiction where the data protection laws may
                        differ than those from your jurisdiction. If you are located outside Nigeria and choose to
                        provide information to us, please note that we transfer the data, including Personal
                        Data, to Nigeria and process it there. Your consent to this Privacy Policy followed by
                        your submission of such information represents your agreement to that transfer. NIMC
                        and the app developers will take all steps reasonably necessary to ensure that your
                        data is treated securely and in accordance with this Privacy Policy and no transfer of
                        your Personal Data will take place to an organization or a country unless there are
                        adequate controls in place including the security of your data and other personal
                        information
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Dispute resolution:</h6>
                    <p className=' text-base'>
                        The responsibility for ensuring the accuracy and completeness of payment instructions,
                        including recipient details and account information, rests with the user. EEL bears
                        no liability for any errors since transactions are directed to a third-party payment
                        gateway (REMITA Payment Gateway) owned and overseen by the Central Bank of
                        Nigeria (CBN), a financial institution under the control of the Nigerian government.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Updates and changes:</h6>
                    <p className=' text-base'>
                        We may update our Privacy Statement from time to time. <br />
                        We may update our Privacy Statement from time to time. We will notify you of any
                        changes by posting the new Privacy Statement on this page. We will let you know via
                        email and/or a prominent notice on our Service, prior to the change becoming effective
                        update the "effective date" at the top of this Privacy Statement. Changes to this Term
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Consent and age verification:</h6>
                    <p className=' text-base'>
                        Our Service is not intended for individuals under the age of 16 ("Children"). We do not
                        knowingly collect personal information from anyone under the age of 16 unless they
                        have enrolled for an EEL-dependent card. If you are a parent or guardian and you
                        become aware that your child has provided us with Personal Data, please contact us
                        immediately. If we become aware that this app has collected Personal Data from
                        children without verifying parental consent, we will take prompt action to remove that
                        information from our servers.
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Legal disclaimer:</h6>
                    <p className=' text-base'>
                        Our Service may contain links to other sites that are not operated by us. If you click on a
                        third-party link, you will be directed to that third-party's site. We strongly advise you to
                        review the Privacy Policy of every site you visit. We have no control over and assume
                        no responsibility for the content, Terms of Use, or practices of any third-party sites or
                        services
                    </p>
                    <h6 className=" text-primary text-lg font-medium mt-6">Contact information:</h6>
                    <p className=' text-base'>
                        If you have any questions about this Privacy Statement, please contact us:<br />
                        <strong>By Email: </strong><span className=" text-primary">
                        <a target="_blank" href={`mailto:contactus.eel@interior.gov.ng`}>
                        contactus.eel@interior.gov.ng
                  </a>
                            </span><br />
                        <strong>Phone Number:</strong><span className=" text-primary">+234-7080647200</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyStatement