import React from "react";
import Virtualizedselect from "./Virtualizedselect";
import { optionsType } from "@/helper/index";
import { cn } from "src/lib/utils";

import FVirtualizedselect from "./FVirtualizedselect";
import { Label } from "../Label";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";
import { InfoIcon } from "lucide-react";

type Props = {
  formik: any;
  label?: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  type?: string;
  options: optionsType[] | [];
  onChange?: (e: any) => void;
  optionsWidth?: number,
  disabled?: boolean,
  showInfoIcon?: boolean
  infoContent?: string
};

const FormkiVirtualSelect = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  type,
  options,
  onChange,
  optionsWidth,
  disabled,
  showInfoIcon,
  infoContent
}: Props) => {
  // console.log({ [nameKey]: formik.values[nameKey]});

  return (
    <div>
      <div className="flex justify-between  mt-2">
        <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
          {label}
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event:any) => event.preventDefault()}>
                <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <div className="mt-2">
        <FVirtualizedselect
          disabled={disabled || false}
          qa-data={nameKey}
          options={options}
          nameKey={nameKey}
          placeholder={placeholder}
          onchange={(e: any) => {
            onChange && onChange(e);
          }}
          optionsWidth={optionsWidth}
          value={formik.values[nameKey]}
        />
      </div>
      {formik.touched && formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive")}>
          {formik.errors[nameKey]}
        </p>
      )}
    </div>
  );
};

export default FormkiVirtualSelect;
