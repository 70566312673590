import { useFormik } from 'formik'
import { ChangeEvent, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeRepresentativeRequestAsync, getChnageRepresentativeReducerState, resetChangeRepresentativeRequestState, resetChangeRepresentativeRequestStateErrorState } from 'src/Silcers/changeRepresentativeSlicer'
import LightBtn from 'src/components/Common/LightBtn'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import SubmitBtn from 'src/components/Common/SubmitBtn'
import ErrorDialog from 'src/components/ErrorDialog'
import LoadingDialog from 'src/components/LoadingDialog'
import SuccessDialog from 'src/components/SuccessDialog'
import { checkIsValidEmail, getAllCountryList } from 'src/services/services'
import { RootState } from 'src/store'
import { IFormFiledType } from 'src/types'
import { ChangeRepresentativeFormFileds, ChangeRepresentativeFormFiledsSchema, IDisabledFields, INIT_CHANGE_REPRENTATIVE_DEFAULT_FORM, IRepresentativeForm } from '../modules'
import { SECURITY_QUATIONS_LIST, checkIfAnyFilesToBeUpload, checkIfAnyFilesToBeUploadByEmail, checkIsBusinessEmailAlreadyExists, optionsType } from 'src/Helpers'
import { OptionsType } from 'embla-carousel-autoplay/components/Options'
import { Label } from '@radix-ui/react-label'
import { cn } from 'src/lib/utils'
import FormikCountrycodePicker from 'src/components/ui/Phonenumber/FormikCountrycodePicker'
import FormikPhonenumber from 'src/components/ui/Phonenumber/FormikPhonenumber'

type Props = {}

const ChangeRepresentativeForm = (props: Props) => {

    const {
        emailValidationRequestState: { enableRepresentativeForm, business_email },
        changeRepresentativeRequestState: { isError, isLoading: apiRequestIsLoading, message, status }
    } = useSelector((state: RootState) => getChnageRepresentativeReducerState(state))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isFileUploading, setisFileUploading] = useState(false)
    const [isOherServiceLoading, setIsOherServiceLoading] = useState(false)
    const { data: countries } = useQuery(["country-list"], () => getAllCountryList(), { staleTime: 6000 })
    const formik = useFormik<IRepresentativeForm>({
        initialValues: INIT_CHANGE_REPRENTATIVE_DEFAULT_FORM,
        validationSchema: ChangeRepresentativeFormFiledsSchema,
        onSubmit(values) { handleSubmitForm(values) },
    })

    const handleOnChangeValue = (key: string, value: string) => {
        formik.setFieldValue(key, value)
        if (key == "business_email" && value) {
            formik.setFieldTouched("business_email", true)
        }
        else if (key == "confirm_password" && value) {
            formik.setFieldTouched("confirm_password", true)
        }

        switch (key) {
            case "type_of_representative":
                return value?.toLowerCase() == "nigerian" ? formik.setFieldValue("nationality_of_representative", "nigeria") : formik.setFieldValue("nationality_of_representative", "")
                break;
            default:
                break;
        }
    }

    const handleSubmitForm = async (values: IRepresentativeForm) => {
        setisFileUploading(true)
        values = await checkIfAnyFilesToBeUploadByEmail(values, business_email as string)
        let newPostData: any = {
            ...values,
            new_business_email: values.business_email,
            old_business_email: business_email
        };
        delete newPostData["business_email"];
        setisFileUploading(false)
        dispatch(changeRepresentativeRequestAsync(newPostData))
    }

    const isLoading = useMemo(() => isFileUploading || apiRequestIsLoading, [isFileUploading, apiRequestIsLoading])

    const disableFileds = useMemo<IDisabledFields>(() => {

        let defaultValues: IDisabledFields = {
            business_email: true,
            country_code: true,
            phone_number: true,
            type_of_representative: true,
            designation_of_representative: true,
            name_of_representative: true,
            national_identification_number: true,
            nationality_of_representative: true,
            bank_verification_number: true,
            passport_number: true,
            passport_expiry: true,
            cerpac_number: true,
            cerpac_expiry: true,
            security_question1: true,
            security_answer1: true,
            security_question2: true,
            security_answer2: true,
            security_question3: true,
            security_answer3: true,
            cac_file_url: true,
            other_file_url: true,
        }


        const true_keys_for_nigerian_representative = ["passport_number", "passport_expiry", "cerpac_number", "cerpac_expiry", "nationality_of_representative"]
        const true_keys_for_foreigner_representative = ["national_identification_number"]


        return Object.keys(defaultValues).reduce((acc, key) => {
            let currentState = !enableRepresentativeForm;
            if (formik.values.type_of_representative?.toLowerCase() == "nigerian" && key == "nationality_of_representative") {
                currentState = true;
            }
            // if (formik.values.type_of_representative?.toLowerCase() == "nigerian") {
            //     currentState = false
            //     if (true_keys_for_nigerian_representative.includes(key)) {
            //         currentState = true
            //     }
            // }
            // else if (formik.values.type_of_representative?.toLowerCase() == "foreigner") {
            //     currentState = false
            //     if (true_keys_for_foreigner_representative.includes(key)) {
            //         currentState = true
            //     }
            // }
            acc[key as keyof IDisabledFields] = currentState;
            return acc;
        }, {} as IDisabledFields);


    }, [formik.values.type_of_representative, enableRepresentativeForm])

    const requiredFileds = useMemo<IDisabledFields>(() => {

        let defaultValues: IDisabledFields = {
            business_email: true,
            country_code: true,
            phone_number: true,
            type_of_representative: true,
            designation_of_representative: true,
            name_of_representative: true,
            national_identification_number: true,
            nationality_of_representative: true,
            bank_verification_number: true,
            passport_number: true,
            passport_expiry: true,
            cerpac_number: true,
            cerpac_expiry: true,
            security_question1: true,
            security_answer1: true,
            security_question2: true,
            security_answer2: true,
            security_question3: true,
            security_answer3: true,
            cac_file_url: true,
            other_file_url: true,
        }

        const false_keys_for_nigerian_representative = ["passport_number", "passport_expiry", "cerpac_number", "cerpac_expiry"]
        const false_keys_for_foreigner_representative = ["national_identification_number"]


        return Object.keys(defaultValues).reduce((acc, key) => {
            acc[key as keyof IDisabledFields] = true
            if (formik.values.type_of_representative?.toLowerCase() == "nigerian") {
                if (false_keys_for_nigerian_representative.includes(key)) {
                    acc[key as keyof IDisabledFields] = false;
                }
            }
            else if (formik.values.type_of_representative?.toLowerCase() == "foreigner") {
                if (false_keys_for_foreigner_representative.includes(key)) {
                    acc[key as keyof IDisabledFields] = false;
                }
            }
            return acc;
        }, {} as IDisabledFields);


    }, [formik.values.type_of_representative, enableRepresentativeForm])

    // console.log({ errors: formik.errors, values: formik.values, touched: formik.touched })

    return (
        <div>
            <form className='p-10' onSubmit={formik.handleSubmit}>
                <div className=' grid grid-cols-2 gap-6'>
                    {Object.values(ChangeRepresentativeFormFileds).map((fields: IFormFiledType) => {

                        if (fields.key == "nationality_of_representative") {
                            fields = { ...fields, options: countries ?? [] }
                        } else if (fields.key == "security_question2") {
                            fields = {
                                ...fields, options: SECURITY_QUATIONS_LIST && SECURITY_QUATIONS_LIST.filter((o: optionsType) => {
                                    if (![formik.values.security_question1, formik.values.security_question3].includes(o.value)) {
                                        return o
                                    }
                                }) || []
                            }
                        } else if (fields.key == "security_question3") {
                            fields = {
                                ...fields, options: SECURITY_QUATIONS_LIST && SECURITY_QUATIONS_LIST.filter((o: optionsType) => {
                                    if (![formik.values.security_question1, formik.values.security_question2].includes(o.value)) {
                                        return o
                                    }
                                }) || []
                            }
                        }

                        if (fields.key == "phone_number_and_code") {
                            return <>
                                <div>
                                    <Label className={cn(" uppercase required")}>
                                        Representative Phone Number
                                    </Label>
                                    <div className="flex items-start mt-2">
                                        <FormikCountrycodePicker
                                            formik={formik}
                                            nameKey={"phone_code"}
                                            placeholder={"select"}
                                            value={formik.values.country_code}
                                            onchange={(e: any) => {
                                                formik && formik.setFieldValue("country_code", e)
                                            }}
                                            disabled={disableFileds[fields.key as keyof IDisabledFields]}
                                        />
                                        <FormikPhonenumber
                                            classname="grow"
                                            formik={formik}
                                            value={formik.values.phone_number}
                                            nameKey={"phone_number"}
                                            placeholder={"Phone number"}

                                            onchange={(e: any) => {
                                                formik && formik.setFieldValue("phone_number", e);
                                            }}
                                            disabled={disableFileds[fields.key as keyof IDisabledFields]}
                                        />
                                    </div>
                                </div>
                            </>
                        }

                        return fields.key != "empty" ?
                            requiredFileds[fields.key as keyof object] ?
                                <div>
                                    <RenderFormikFiled
                                        disabled={disableFileds[fields.key as keyof IDisabledFields]}
                                        formik={formik}
                                        value={formik.values[fields.key as keyof IRepresentativeForm]}
                                        formObject={fields}
                                        required={requiredFileds[fields.key as keyof object]}
                                        key={fields.key}
                                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                            if (fields.key == "business_email") {
                                                //handleCheckEmail(e.target.value)
                                            }
                                        }}
                                        onchange={(e: any) => {
                                            if (!["cac_file_url", "other_file_url"].includes(fields.key)) {
                                                handleOnChangeValue(fields.key, e && e)
                                            }
                                            else handleOnChangeValue(fields.key, e)
                                        }}
                                    />
                                </div> : null : <div></div>
                    })}
                </div>

                <div className='flex justify-center mt-4'>
                    <LightBtn onclick={() => navigate('/')} title={'Cancel'} ></LightBtn>
                    <SubmitBtn isDisabled={!enableRepresentativeForm} isLoading={isLoading} />
                </div>

            </form>

            <LoadingDialog isOpen={isLoading} />
            <ErrorDialog isOpen={isError} message={message} onCancelClick={() => dispatch(resetChangeRepresentativeRequestStateErrorState())} />
            <SuccessDialog isOpen={status == 200 || status == 201} onClickOkBtn={() => {
                dispatch(resetChangeRepresentativeRequestState())
                navigate("/")
            }} title='Change representative request submited.' >
                <div>You will receive notification on your registered email.</div>
            </SuccessDialog>

        </div>

    )
}

export default ChangeRepresentativeForm