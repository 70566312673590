import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllEmptyCardRequestAsync, getAssigneEmployeeToEmptyCardReducerState } from 'src/Silcers/assigneEmployeeToEmptyCardSlicer'
import PageHeadingComponent from 'src/components/Common/PageHeadingComponent'
import { RootState } from 'src/store'
import { columns } from './AllEmptyCards/components/columns'
import { DataTable } from './AllEmptyCards/components/data-table'
import AssignEmployee from './AssigneEmployee'

type Props = {}

const AssignEmployeeToEmptyCards = (props: Props) => {

    const { allCardsList } = useSelector((state: RootState) => getAssigneEmployeeToEmptyCardReducerState(state))
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAllEmptyCardRequestAsync())
        return () => { }
    }, [])


    return (
        <div className="p-4">
            <div className="card bg-base-100 shadow-sm rounded-md">
                <PageHeadingComponent
                    mainContent={"Cards List"}
                    subContent={""}
                />
                <DataTable columns={columns} data={allCardsList} />
                <AssignEmployee />
            </div>
        </div>
    )
}

export default AssignEmployeeToEmptyCards