import { useMemo, useState, useEffect, ChangeEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
// import { Icons } from "../../../components/Icons"

import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from "formik";
import { Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import LightBtn from "src/components/Common/LightBtn";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { Button } from "src/components/ui/button";
import FormikCityPicker from "src/components/ui/CityPIcker/FormikCityPicker";
import FormikCountrycode from "src/components/ui/CountrySelect/FormikCountrycode";
import { Label } from "src/components/ui/Label";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
import FormikStatePicker from "src/components/ui/StatePicker/FormikStatePicker";
import { RootState } from "src/store";
import { useMainContext } from "../MainContext";
import {
  companyProfileSchema_yup,
  ICompanyProfile,
  INIT_COMPANY_PROFILE_FORM,
  keys_and_labels,
} from "../modules";
import { IEcitibizApiResponse, IsomeFiledDisabled, IsomeFiledRequired } from "src/types";
import { getCompanyDetailsByRCNumber, uploadfile } from "src/services/services";
import {
  fetchCompanyDetailsAsync,
  setInitials,
  setSelectedCompany,
} from "src/Silcers/companySlicer";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import LoadingDialog from "src/components/LoadingDialog";
import { format_DDMMYYYY_To_Date } from "src/Helpers";

type Props = {};

export default function StepOneDetail({ }: Props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [someFiledsDisabled, setsomeFiledsDisabled] =
    useState<IsomeFiledDisabled>({
      company_name: false,
      business_email: false,
      business_phone_code: false,
      business_phone_number: false,
      name_of_representative: false,
      quota_position: false,
      rc_number: false,
      country: false,
    });

  const {
    compayProfile: { companyProfileForm, error, message, isLoading },
    login: { user, isNewUser },
  }: any = useSelector((state: RootState) => state);

  const PreviousFromData:
    | Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        // expatriate_quota_positionfiles: File | undefined;
      }
    >
    | undefined = useMemo(() => companyProfileForm, [companyProfileForm]);

  const formik = useFormik<
    Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        // expatriate_quota_positionfiles: File | undefined;
      }
    >
  >({
    initialValues: companyProfileForm,
    validationSchema: companyProfileSchema_yup,
    onSubmit: async (
      values: Partial<
        ICompanyProfile & {
          cacfiles: File | undefined;
          tccfiles: File | undefined;
          // expatriate_quota_positionfiles: File | undefined;
        }
      >
    ) => {
      await onSubmitActions(values);
    },
  });

  const [isFetchingQuotaDetails, setisFetchingQuotaDetails] = useState<boolean>(false)

  useEffect(() => {
    // dispatch(setInitials(user));
    if (companyProfileForm?.cac instanceof File) {
      Object.keys(INIT_COMPANY_PROFILE_FORM).map((k: any) => {
        formik.setFieldValue(k, typeof companyProfileForm[k] == "string" ? (companyProfileForm[k])?.trim() : companyProfileForm[k] );
      });
    } else {

      const userData: any = user;
      // console.log({ userData });

      const keyMapping: any = {
        type_of_company: "typeOfCompany",
        rc_number: "rcNumber",
        company_name: "comapnyName",
        business_phone_country_code: "countrycode",
        business_phone_number: "businessPhoneNumber",
        business_email: "businessEmail",
        company_address1: "comapnyAddress1",
        company_address2: "comapnyAddress2",
        type_of_representative: "typeOfRepresentative",
        designation_of_representative: "designationOfRepresentative",
        name_of_representative: "nameOfRepresentive",
        national_identification_number: "NationalIdentificationNumber",
        nationality_of_representative: "nationality",
        bank_verification_number: "bankVerificationNumber",
        passport_number: "passportNumber",
        passport_expiry: "passportExpiry",
        cerpac_number: "cerpacNumber",
        cerpac_expiry: "cerpacExpiry",
        state_of_company: "stateOfCompany",
      };

      updateRequiredFiled(user?.typeOfRepresentative)

      let newState: any = {};

      Object.keys(INIT_COMPANY_PROFILE_FORM).map((key: string) => {
        if (userData[keyMapping[key]]) {
          if (["cerpac_expiry", "passport_expiry"].includes(key)) {
            newState[key] = format_DDMMYYYY_To_Date(userData[keyMapping[key]]);
          } else newState[key] = userData[keyMapping[key]];
        } else {
          newState[key] = undefined;
        }
      });


      formik.setValues(newState);

      let disabledFileds = { ...someFiledsDisabled }

      if (user?.typeOfRepresentative?.trim() == "Nigerian") {
        formik.setFieldValue("country", "Nigeria")
        disabledFileds = { ...disabledFileds, country: true }
      }

      // if (userData?.rcNumber) {
      //   disabledFileds = { ...disabledFileds, rc_number: true }
      //   verifyRCNumber(userData?.rcNumber)
      // }

      setsomeFiledsDisabled(disabledFileds);

    }

    return () => { };
  }, [user, companyProfileForm]);

  const onSubmitActions = async (
    data: Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        expatriate_quota_positionfiles: File | undefined;
      }
    >
  ) => {
    // console.log({ data });
    setuploadingFiles(true);
    dispatch(setSelectedCompany(data));
    setActiveTab(2);
    setuploadingFiles(false);
  };

  const { setActiveTab } = useMainContext();
  const [uploadingFiles, setuploadingFiles] = useState(false);

  const verifyRCNumber = async (e: any) => {
    try {

      setisFetchingQuotaDetails(true)
      // console.log({ beforeChnage: someFiledsDisabled })

      const apiRdsp: IEcitibizApiResponse = await getCompanyDetailsByRCNumber(e);
      // if(apiRdsp.status === 200){
      if (apiRdsp && apiRdsp?.Data) {

        const { CompanyName, NameOfRepresentative, Email, PhoneNumber } = apiRdsp?.Data.companyData;

        const withoutspace = PhoneNumber.replace(/[^0-9]/g, "");
        const [code, number] = withoutspace.split(/(?<=\d)(?=\d{10}$)/);

        // formik.setFieldValue("name_of_representative", NameOfRepresentative);
        formik.setFieldValue("company_name", CompanyName);
        // formik.setFieldValue("business_email", Email);
        // formik.setFieldValue("business_phone_country_code", code);
        // formik.setFieldValue("business_phone_number", number);
        formik.setFieldValue("quota_details", apiRdsp.Data.quotaDetailData);

        setsomeFiledsDisabled({
          ...someFiledsDisabled,
          //rc_number: true,
          company_name: CompanyName ? true : false,
          business_email: Email ? true : false,
          business_phone_code: code ? true : false,
          business_phone_number: number ? true : false,
          // name_of_representative: NameOfRepresentative ? true : false,
          //quota_position: !!totalQuota || totalQuota == 0 ? false : true,
        });
      }
      else {

        const { name_of_representative, company_name } = formik.values;

        // console.log({ business_phone_country_code: user?.countrycode });

        formik.setFieldValue("name_of_representative", user?.nameOfRepresentive || "");
        formik.setFieldValue("company_name", user?.comapnyName || "");
        formik.setFieldValue("business_email", user?.businessEmail || "");
        formik.setFieldValue("business_phone_country_code", user.countrycode || "");
        formik.setFieldValue("business_phone_number", user?.businessPhoneNumber || "");
        formik.setFieldValue("quota_details", []);

        let newObject = {
          ...someFiledsDisabled, company_name: false,
          business_email: false,
          business_phone_code: false,
          business_phone_number: false,
          name_of_representative: false,
        }

        if (user?.rcNumber) {
          newObject = { ...newObject, rc_number: true }
        }

        setsomeFiledsDisabled(newObject);
      }
      setisFetchingQuotaDetails(false)
    }

    catch (e) {
      const error = e as Error | AxiosError
      // console.log(error?.message)
      if (axios.isAxiosError(error)) {
        // console.log(error.response?.data)
        setisFetchingQuotaDetails(true)
      }
    }
    // }
    // setisLoading(true);
    // setTimeout(() => {
    //   setisLoading(false);
    // }, 5000);
  };

  const [someFiledsRequired, setsomeFiledsRequired] =
    useState<IsomeFiledRequired>({
      designation_of_representative: true,
      name_of_representative: true,
      national_identification_number: false,
      nationality_of_representative: true,
      bank_verification_number: true,
      passport_number: false,
      passport_expiry: false,
      cerpac_number: false,
      cerpac_expiry: false,
    });

  const updateRequiredFiled = (e: string) => {
    if (e == "Nigerian") {
      formik.setFieldValue("national_identification_number","")
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: true,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: false,
        passport_expiry: false,
        cerpac_number: false,
        cerpac_expiry: false,
      });
    } else if (e == "Foreigner") {
      formik.setFieldValue("national_identification_number","")
      setsomeFiledsRequired({
        designation_of_representative: true,
        name_of_representative: true,
        national_identification_number: false,
        nationality_of_representative: true,
        bank_verification_number: true,
        passport_number: true,
        passport_expiry: true,
        cerpac_number: true,
        cerpac_expiry: true,
      });
    }
  };

  console.log("COM setups", { formikValues: formik.errors, values: formik.values });

  return (
    <>
      <form onError={(e: any) => console.log(e)} onSubmit={formik.handleSubmit}>
        <div className="p-7 grid grid-cols-1 gap-3">
          {/* RC number state of company */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.rc_number}
                required
                disabled={someFiledsDisabled.rc_number}
                onchange={(e: any) => {
                  formik.setFieldValue("rc_number", e);
                }}
                onBlur={(e: ChangeEvent<HTMLInputElement>) =>
                  verifyRCNumber(e.target.value)
                }
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.state_of_company}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("state_of_company", e)
                }
              /> */}
            </div>
          </div>
          {/* Type of company name of company */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.type_of_company}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("type_of_company", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.company_name}
                required
                disabled={someFiledsDisabled.company_name}
                onchange={(e: any) => formik.setFieldValue("company_name", e)}
              />
            </div>
          </div>
          {/* Nature of Bussiness */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.sector}
                required
                onchange={(e: any) => formik.setFieldValue("sector", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.nature_of_business}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("nature_of_business", e)
                }
              />
            </div>
          </div>
          {/* SECtor and Nature of Business  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.tin_number}
                required
                onchange={(e: any) => formik.setFieldValue("tin_number", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.tcc_number}
                required={formik.values.tcc ? true : false}
                onchange={(e: any) => formik.setFieldValue("tcc_number", e)}
              />
            </div>
          </div>
          {/* bussiness email and phone  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.business_email}
                required
                disabled={someFiledsDisabled.business_email}
                onchange={(e: any) => formik.setFieldValue("business_email", e)}
              />
            </div>
            <div className="space-y-2">
              <Label className="required uppercase">
                Business Phone Number
              </Label>
              <div className="flex items-start">
                <FormikCountrycodePicker
                  formik={formik}
                  // /value={formik.values.business_phone_country_code}
                  nameKey={keys_and_labels.business_phone_country_code.key}
                  placeholder={
                    keys_and_labels.business_phone_country_code.placeholder
                  }
                  disabled={someFiledsDisabled.business_phone_code || keys_and_labels.business_phone_country_code.readonly}
                  onchange={(e: any) =>
                    formik.setFieldValue("business_phone_country_code", e)
                  }
                />
                <FormikPhonenumber
                  classname="grow"
                  formik={formik}
                  nameKey={keys_and_labels.business_phone_number.key}
                  disabled={someFiledsDisabled.business_phone_number || keys_and_labels.business_phone_number.readonly}
                  placeholder={
                    keys_and_labels.business_phone_number.placeholder
                  }
                  onchange={(e: any) => {
                    formik.setFieldValue("business_phone_number", e);
                  }}
                />
              </div>
            </div>
          </div>
          {/* Country and state  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <CountryPickerFormFiled
                form={form}
                nameKey={keys_and_labels.country.key}
                required
                onchange={(e: any) =>
                  setformState({ ...formState, country: e })
                }
              /> */}
              <FormikCountrycode
                formik={formik}
                nameKey={keys_and_labels.country.key}
                required
                disabled={someFiledsDisabled.country || user?.typeOfRepresentative?.trim() == "Nigerian"}
                onchange={(e: any) => {
                  formik.setFieldValue("country", e);
                }}
              />
            </div>
            <div>
              {/* <StatepickerFormFiled
                form={form}
                nameKey={formfields.state.key}
                countryname={form.getValues("country")}
                required
                onchange={(e: any) => setformState({ ...formState, state: e })}
              /> */}
              <FormikStatePicker
                formik={formik}
                nameKey={keys_and_labels.state.key}
                required
                countryname={formik.values.country}
                onchange={(e: any) => {
                  formik.setFieldValue("state", e);
                }}
              />
            </div>
          </div>
          {/* City and Zip code  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <CityFormFiledPicker
                form={form}
                nameKey={formfields.city.key}
                stateName={form.getValues("state")}
                required
                onchange={(e: any) => setformState({ ...formState, city: e })}
              /> */}
              <FormikCityPicker
                formik={formik}
                nameKey={keys_and_labels.city.key}
                required
                stateName={formik.values.state}
                onchange={(e: any) => {
                  formik.setFieldValue("city", e);
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.zipcode}
                required={false}
                onchange={(e: any) => formik.setFieldValue("zipcode", e)}
              />
            </div>
          </div>
          {/* Company Address 1 / 2 */}
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={keys_and_labels.company_address1}
              required
              onchange={(e: any) => formik.setFieldValue("company_address1", e)}
            />
          </div>
          <div>
            <RenderFormikFiled
              formik={formik}
              formObject={keys_and_labels.company_address2}
              required={false}
              onchange={(e: any) => formik.setFieldValue("company_address2", e)}
            />
          </div>
          {/* Type of representative and designation  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.type_of_representative}
                required
                onchange={(e: any) => {
                  formik.setFieldValue("type_of_representative", e);
                  updateRequiredFiled(e);
                }}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.designation_of_representative}
                required={someFiledsRequired.designation_of_representative}
                onchange={(e: any) =>
                  formik.setFieldValue("designation_of_representative", e)
                }
              />
            </div>
          </div>
          {/* Name of representative and national identification no */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.name_of_representative}
                required={someFiledsRequired.name_of_representative}
                disabled={someFiledsDisabled.name_of_representative}
                onchange={(e: any) =>
                  formik.setFieldValue("name_of_representative", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.national_identification_number}
                required={someFiledsRequired.national_identification_number}
                onchange={(e: any) =>
                  formik.setFieldValue("national_identification_number", e)
                }
              />
            </div>
          </div>
          {/* Bank verification no  and Passport NO of Represntative*/}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.bank_verification_number}
                required={true}
                onchange={(e: any) =>
                  formik.setFieldValue("bank_verification_number", e)
                }
              />
            </div>
            <div>

            </div>
          </div>
          {/* crepc NO and PAsport expiry */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.passport_number}
                required={someFiledsRequired.passport_number}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_number", e)
                }
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.passport_expiry}
                required={someFiledsRequired.passport_expiry}
                onchange={(e: any) =>
                  formik.setFieldValue("passport_expiry", e)
                }
              />
            </div>
          </div>
          {/* CERPAC EXPIRY & quota position  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.cerpac_number}
                required={someFiledsRequired.cerpac_number}
                onchange={(e: any) => formik.setFieldValue("cerpac_number", e)}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.cerpac_expiry}
                required={someFiledsRequired.cerpac_expiry}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
              />
              {/* <RenderFormikFiled
                formik={formik}
                disabled={someFiledsDisabled.quota_position}
                formObject={keys_and_labels.quota_position}
                required
                onchange={(e: any) => formik.setFieldValue("quota_position", e)}
              /> */}
            </div>
          </div>
          {/* UN-Utilized qutoa Upload CAC TCC ,reveewal Additional Document*/}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <RenderFormFiled
                form={form}
                formObject={formfields.quota_utilized}
                required
                onchange={(e: any) => {
                  if (e && Number(e)) {
                    setformState({ ...formState, quota_utilized: e });
                    if (formState.quota_position) {
                      var calc =
                        Number(formState.quota_position) - Number(e) > 0
                          ? Number(formState.quota_position) - Number(e)
                          : 0;
                      form.setValue("quota_remaining", calc);
                      setformState({ ...formState, quota_remaining: calc });
                      if (Number(e) > Number(formState.quota_position)) {
                        form.setError("quota_utilized", {
                          message:
                            "Quota utilized should not be less than quota_position",
                        });
                      } else {
                        form.clearErrors("quota_utilized");
                      }
                    }
                  }
                }}
              /> */}
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.quota_utilized}
                required
                onchange={(e: any) => {
                  console.log({
                    e,
                    quota_position: formik.values.quota_position,
                  });

                  if (e && formik.values.quota_position) {
                    var calc =
                      parseInt(formik.values.quota_position as any) -
                        parseInt(e) >
                        0
                        ? parseInt(formik.values.quota_position as any) -
                        parseInt(e)
                        : 0;

                    formik.setFieldValue("quota_remaining", calc);
                    if (
                      parseInt(e) >
                      parseInt(formik.values.quota_position as any)
                    ) {
                      formik.setFieldError(
                        "quota_utilized",
                        "Quota utilized should not be greater than quota_position"
                      );
                    }
                  }
                  formik.setFieldValue("quota_utilized", e);
                }}
              /> */}
            </div>
            <div>
              {/* <RenderFormFiled
                form={form}
                formObject={formfields.quota_remaining}
                required
                disabled={!formState.quota_remaining}
                onchange={(e: any) => {
                  var calc =
                    Number(formState.quota_position) - Number(e) > 0
                      ? Number(formState.quota_position) - Number(e)
                      : 0;
                  setformState({
                    ...formState,
                    quota_remaining: e,
                    quota_utilized: calc,
                  });
                  form.setValue("quota_utilized", calc);
                }}
              /> */}
              {/* <RenderFormikFiled
                formik={formik}
                formObject={keys_and_labels.quota_remaining}
                required
                onchange={(e: any) =>
                  formik.setFieldValue("quota_remaining", e)
                }
              /> */}
            </div>
          </div>

          {/* CAC TCC EXPIRATE */}

          <RenderFormikFiled
            formik={formik}
            formObject={keys_and_labels.cac}
            value={formik.values.cac}
            required
            onchange={(e: any) => formik.setFieldValue("cac", e)}
          />

          {/* tcc EXPIRATE */}

          <RenderFormikFiled
            formik={formik}
            formObject={keys_and_labels.tcc}
            value={formik.values.tcc}
            required={formik.values.tcc_number ? true : false}
            onchange={(e: any) => formik.setFieldValue("tcc", e)}
          />

          {/* expatriate_quota_position */}

          {/* <RenderFormikFiled
            formik={formik}
            value={formik.values.expatriate_quota_position}
            formObject={keys_and_labels.expatriate_quota_position}
            required
            onchange={(e: any) =>
              formik.setFieldValue("expatriate_quota_position", e)
            }
          /> */}
          <RenderFormikFiled
            formik={formik}
            value={formik.values.itf}
            formObject={keys_and_labels.itf}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("itf", e)
            }
          />

          <RenderFormikFiled
            formik={formik}
            value={formik.values.pancom}
            formObject={keys_and_labels.pancom}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("pancom", e)
            }
          />
          <RenderFormikFiled
            formik={formik}
            value={formik.values.additional_document}
            formObject={keys_and_labels.additional_document}
            required={false}
            onchange={(e: any) =>
              formik.setFieldValue("additional_document", e)
            }
          />
          <LoadingDialog isOpen={isFetchingQuotaDetails} message="Quota details are fetching" />
          <div className="flex justify-center w-full">
            {/* <Button className="mb-2 px-10 w-44 mx-5 bg-secondary-foreground">
            Save as Draft
          </Button> */}
            <LightBtn
              onclick={(e: any) => {
                navigate(-1)
                // e.preventDefault();
                // form.trigger();
                // saveDraft(form.getValues());
              }}
              title="Cancel"
            />
            <Button
              disabled={uploadingFiles}
              type="submit"
              className="mb-2 px-10 w-44 mx-5"
            >
              {uploadingFiles && uploadingFiles && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
