import * as yup from "yup";

export type IDocumentType = "CAC" | "ITF" | "TCC" | "PANCOM"

export type IcurrentCompanyDetails = {
    _id: string | undefined,
    tccnumber: string | undefined,
    tcc: string | undefined,
    cac: string | undefined,
    pancom: string | undefined,
    itf: string | undefined
}

export interface IUPDATE_COMPANY_KYC_DOCUMENT {
    documentType: IDocumentType | undefined,
    cac: File | string | undefined;
    tcc: File | string | undefined;
    tcc_number: string | undefined,
    itf: File | string | undefined;
    pancom: File | string | undefined;

    old_cac: File | string | undefined;
    old_tcc: File | string | undefined;
    old_tcc_number: string | undefined,
    old_itf: File | string | undefined;
    old_pancom: File | string | undefined;
}

export const INIT_UPDATE_COMPANY_KYC_DOCUMENT = {
    cac: "",
    tcc: "",
    tcc_number: "",
    itf: "",
    pancom: "",
    documentType: ""
}

export const transform_to_form_keys = {
    cac: "cac",
    tcc: "tcc",
    tcc_number: "tccnumber",
    itf: "itf",
    pancom: "pancom"
}

export const documentTypeOptions = [
    {
        label: "CAC Document",
        value: "CAC"
    },
    {
        label: "ITF Document",
        value: "ITF"
    }, {
        label: "TCC Document",
        value: "TCC"
    },
    {
        label: "PANCOM Document",
        value: "PANCOM"
    },
]


export const update_company_kyc_form_fileds = {
    documentType: { key: "documentType", field_type: "options", data_key: "documentType", label: "Document Type" },
    cac: { key: "cac", field_type: "file", data_key: "cac", label: "CAC Document", showIf: (formik: any) => formik.values.documentType == "CAC" },
    tcc: { key: "tcc", field_type: "file", data_key: "tcc", label: "TCC Document", showIf: (formik: any) => formik.values.documentType == "TCC" },
    tcc_number: {
        key: "tcc_number", field_type: "text", data_key: "tcc_number", label: "TCC Number", showIf: (formik: any) => formik.values.documentType == "TCC", blockletters: true,
        maxLength: 15,
        regex: /[^0-9-]+/g
    },
    itf: { key: "itf", field_type: "file", data_key: "itf", label: "ITF Document", showIf: (formik: any) => formik.values.documentType == "ITF" },
    pancom: { key: "pancom", field_type: "file", data_key: "pancom", label: "Pancom Document", showIf: (formik: any) => formik.values.documentType == "PANCOM" },
};


export const update_company_kyc_validation_schema = yup.object().shape({
    documentType: yup.string().required(),
    cac: yup.mixed().when("documentType", {
        is: "CAC",
        then(schema) {
            return schema.test('cac', (value, validationcontext) => {
                const { createError, parent: { quota_position } } = validationcontext;


                if (!value) {
                    return createError({ message: "CAC Documnet is Mandatory" });
                }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File &&  value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    console.log(value.name)
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                return true;
            })
        },
    }),
    tcc_number: yup.string().when("documentType", {
        is: "TCC",
        then(schema) {
            return schema.matches(/^([0-9]+)$/, "TCC Number must be digits only").min(12, "TCC Number must be atleast 12 digits").max(15, "TCC Number max 15 digits").test('tcc', (value, validationcontext) => {

                const { createError, parent: { tcc_number, tcc, old_tcc,old_tcc_number } } = validationcontext;

                if (!value) {
                    return createError({ message: "Enter TCC number" });
                }

                if (tcc_number == old_tcc_number && tcc !== old_tcc) {
                    return createError({ message: "You changed tcc file, please enter new tcc number" });
                }

                return true;
            })
        }
    }),
    tcc: yup.mixed().when("documentType", {
        is: "TCC",
        then(schema) {
            return schema.test('tcc', (value, validationcontext) => {

                const { createError, parent: { tcc_number, tcc, old_tcc, old_tcc_number } } = validationcontext;

                if (!value && tcc_number) {
                    return createError({ message: "Select TCC." });
                }

                if (tcc_number !== old_tcc_number && value == old_tcc) {
                    return createError({ message: "You changed tcc number, please select new TCC file" });
                }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && tcc_number && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && tcc_number && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                return true;
            })
        }
    }),
    itf: yup.mixed().when("documentType", {
        is: "ITF",
        then(schema) {
            return schema.test('itf', (value, validationcontext) => {

                const { createError, parent: { quota_position } } = validationcontext;

                if (!value) {
                    return true
                }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                return true;
            })
        }
    }),
    pancom: yup.mixed().when("documentType", {
        is: "pancom",
        then(schema) {
            return schema.test('pancom', (value, validationcontext) => {

                const { createError, parent: { quota_position } } = validationcontext;

                if (!value) {
                    return true;
                }

                const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
                if (value && value instanceof File && fileSize >= 5) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
                    return createError({ message: "File name not include underscore,dot,comma" });
                }

                if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
                    return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
                }

                return true;
            })
        }
    }),
})