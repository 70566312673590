import * as yup from 'yup'

interface ILogin {
    email: string | undefined,
    password: string | undefined,
    is_remember_me: boolean | undefined
}

export const INIT_LOGIN: ILogin = {
    email: undefined,
    password: undefined,
    is_remember_me: false
}

export const DEFAULT_ilOGIN: ILogin = {
    email: "",
    password: "",
    is_remember_me: false
}

export const errorMessages = {
    email: {
        required: "please enter E-mail ID",
        maxLength: "Maximum E-mail ID length is 250 characteres only",
        invalid: "Please enter valid E-mail ID"
    },
    password: {
        required: "please enter password",
        minMaxlength: "Minimum 8 and maximum 18 characters only",
        invalid: 'Password must be at least 8 characters, contain at least one digit, one lowercase letter, one uppercase letter, one special character.'
    }
}

export const ILOGIN_SCHEMA = yup.object().shape({
    email: yup.string().email(errorMessages.email.invalid).max(250, errorMessages.email.maxLength).required(errorMessages.email.required),
    password: yup.string()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { } } = validationcontext;
            if (!value) {
                return createError({ message: errorMessages.password.required });
            }

            if (!value.match(/^.{8,18}$/)) {
                return createError({ message: errorMessages.password.minMaxlength });
            }

            // if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/)) {
            //     return createError({ message: errorMessages.password.invalid });
            // }
            return true;
        }),
    is_remember_me: yup.boolean().optional()
})

export const FORGOT_PASSWORD_SCHEMA = yup.object().shape({
    confirm_password: yup.string()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { } } = validationcontext;
            if (!value) {
                return createError({ message: errorMessages.password.required });
            }

            if (!value.match(/^.{8,18}$/)) {
                return createError({ message: errorMessages.password.minMaxlength });
            }

            // if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/)) {
            //     return createError({ message: errorMessages.password.invalid });
            // }
            return true;
        }),
    password: yup.string()
        .test('password', (value, validationcontext) => {
            const { createError, parent: { } } = validationcontext;
            if (!value) {
                return createError({ message: errorMessages.password.required });
            }

            if (!value.match(/^.{8,18}$/)) {
                return createError({ message: errorMessages.password.minMaxlength });
            }

            // if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/)) {
            //     return createError({ message: errorMessages.password.invalid });
            // }
            return true;
        }),
})

export const LoginFormFields = {
    email: {
        key: "email",
        label: "Email address",
        field_type: "email",
        field_mode: "email",
        placeholder: "Email",
    },
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "password",
        placeholder: "Password",
    },
}

export const ForgotPassword = {
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "password",
        placeholder: "Password",
    },
    confirm_password: {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "password",
        placeholder: "Confirm Password",
    },
}