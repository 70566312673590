import PageHeadingComponent from 'src/components/Common/PageHeadingComponent';
import ChangeRepresentativeForm from './Components/ChangeRepresentativeForm';
import EmailValidationForm from './Components/EmailValidationForm';
import { Separator } from 'src/components/ui/separator';

type Props = {}

const ChangeRepresentative = (props: Props) => {
    return (
        <div className="p-4 lg:w-3/5 lg:mx-auto">
            <div className="card bg-base-100 rounded-md px-9">
                <PageHeadingComponent
                    mainContent={"Change Representative Request"}
                    subContent={""}
                />
            </div>
            <EmailValidationForm />
            <ChangeRepresentativeForm />
        </div>
    )
}

export default ChangeRepresentative

