import React, { HTMLProps, ReactNode } from "react";
import { cn } from "src/lib/utils";
import { ClassNameValue } from "tailwind-merge";

type Props = {
  mainContent: string;
  mainContentClassName?: string;
  subContent?: string | ReactNode;
  logo?: React.ReactNode;
  rightContent?: string;
  subContentClassName?: string;
  mainTextClassName?: HTMLProps<HTMLElement>["className"];
};

const PageHeadingComponent = ({
  mainContent,
  mainContentClassName,
  subContent,
  logo = <></>,
  rightContent,
  subContentClassName,
  mainTextClassName='',
}: Props) => {
  return (
    <div className="">
      <div className={cn("flex flex-col", mainContentClassName)}>
        <span className={cn("text-primary font-medium text-xl uppercase", mainTextClassName)}>
          {mainContent} <span>{logo}</span>
        </span>
      </div>
      <div className="flex justify-between mt-2">
        <div className={cn("text-sm text-gray-600", subContentClassName)}>
          {subContent}
        </div>
        <div className="text-sm text-gray-600">{rightContent}</div>
      </div>
    </div>
  );
};

export default PageHeadingComponent;
