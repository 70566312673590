import { IdCardIcon } from "@radix-ui/react-icons";
import axios, { AxiosError } from "axios";
import { Loader2 } from "lucide-react";
import { ChangeEvent, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "src/Helpers";
import { SVGC } from "src/Helpers/SVGC";
import StepCircle from "src/Modules/Auth/Regsitration/Components/StepCircle";
import { fetchAllEmployeesAsync } from "src/Silcers/employeesSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import SuccessDialog from "src/components/SuccessDialog";
import { Input } from "src/components/ui/Input";
import { Label } from "src/components/ui/Label";
import ListActionsDropdowns, {
  ITableActionMenuOptions,
} from "src/components/ui/ListActionsDropdowns";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  employeeDelete,
  employeeDeleteRequest,
  uploadfile,
} from "src/services/services";
import { IRequestUsestate } from "src/types";
import CardPrintingRequestDialog from "./CardPrintingRequestDialog";
import CustomeDialog from "src/components/Common/CustomeDialog";
import { Select, SelectContent, SelectTrigger, SelectValue } from "src/components/ui/select";
import { SelectItem } from "@radix-ui/react-select";
import UpdateKYCDocumentMain from "../../UpdateKYCDocument";
import ErrorDialog from "src/components/ErrorDialog";
import ExemptRequestsMain from "./ExemptRequests";

type Props = {
  row: any;
};

const DeleteEmployee = ({ row }: Props) => {
  const { original } = row;
  const dispatch = useDispatch()
  // console.log({ original });


  const navigate = useNavigate();
  const [openFileUploadDialog, setopenFileUploadDialog] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openSubmitted, setopenSubmitted] = useState(false);
  const [openSubmitted2, setopenSubmitted2] = useState(false);
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);
  const [requestStatus, setrequestStatus] = useState<IRequestUsestate>({
    isError: false,
    isLoading: false,
    message: "",
    status: undefined,
    data: undefined
  })
  const [selectedFile, setselectedFile] = useState<any>(undefined);
  const [fileError, setfileError] = useState<any>(undefined);
  const [showCardPrintingRequestDialog, setshowCardPrintingRequestDialog] = useState(false)
  const [isOpenUpdateKYCDialog, setisOpenUpdateKYCDialog] = useState<"show" | "hide">("hide")
  const [isOpenExemptRequestDialog, setisOpenExemptRequestDialog] = useState<"show" | "hide">("hide")

  const sendDeleteRequest = async () => {
    try {

      setrequestStatus({ ...requestStatus, isLoading: true, isError: false, message: "" })

      let postData: any = {};

      if (selectedFile && selectedFile instanceof File) {

        const fileSize = selectedFile && selectedFile instanceof File && selectedFile?.size / 1024 / 1024 || 0; // MB
        if (selectedFile && selectedFile instanceof File && fileSize >= 5) {
          setfileError("Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB");
          setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "" })
          return
        }

        if (selectedFile && selectedFile instanceof File && selectedFile.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
          setfileError("File name not include underscore,dot,comma");
          setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "File name not include underscore,dot,comma" });
          return;
        }


        if (selectedFile && selectedFile instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(selectedFile.type)) {
          setfileError("Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB");
          setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "" })
          return
        }

        const fileUrl = await uploadfile([selectedFile], 'quota_doc');
        postData = { ...postData, quota_doc: fileUrl?.filename };
      }

      if (original?.paymentstatus == "complete" || original?.paymentstatus == "card-re-assigned") {

        if (!selectedFile) {
          setfileError("please select quota letter");
          setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "" })
          return
        }

        if (!postData?.quota_doc) {
          setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "" })
          return
        }

        // console.log({ postData });


        const apiResp = original?._id && await employeeDeleteRequest(postData, original?._id);
        if (apiResp?.status == 200) {
          setopenSubmitted(true);
          setopenDeleteModal(false);
          setopenFileUploadDialog(false);
          setselectedFile(undefined);
          setshowSuccessDialog(true)

        }

        setopenSubmitted(true);
        setopenDeleteModal(false);
        setopenFileUploadDialog(false);
        setselectedFile(undefined);


      } else {

        postData = {
          _id: original?._id
        }

        const apiResp = await employeeDelete(postData);
        if (apiResp?.status == 200) {
          setopenDeleteModal(false);
          setopenSubmitted2(true);
          dispatch(fetchAllEmployeesAsync())
        }
        setopenDeleteModal(false);
        setopenSubmitted2(true);
      }

      setrequestStatus({ ...requestStatus, isLoading: false, isError: false, message: "" })

    } catch (e: any) {
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: error.response?.data?.message })
        // console.log({ error: error.response?.data.message });
      }
      else {
        setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: error?.message })
      }
    }

  };

  const prepareOptions: ITableActionMenuOptions[] = useMemo(() => {
    let opt: ITableActionMenuOptions[] = [

      // {
      //   icon: (
      //     <SVGC
      //       path={toAbsoluteUrl("/media/icons/svg/trash.svg")}
      //       className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
      //     ></SVGC>
      //   ),
      //   onClick: () => setopenDeleteModal(true),
      //   title: "Delete",
      // },
      {
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/employee-view/${original?._id}`);
          }
        },
        title: "View",
      },
    ];

    if (original?.emp_insertstatus == "completed") {
      opt.push({
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/employee-view/${original?._id}`);
          }
        },
        title: "Edit",
      },
        {
          icon: (
            <SVGC
              path={toAbsoluteUrl("/media/svg/document-text.svg")}
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            ></SVGC>
          ),
          onClick: () => setisOpenUpdateKYCDialog("show"),
          title: "Update KYC Document",
        },
        {
          icon: (
            <SVGC
              path={toAbsoluteUrl("/media/svg/document-text.svg")}
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            ></SVGC>
          ),
          onClick: () => setisOpenExemptRequestDialog("show"),
          title: "Apply Exempt Request",
        })
    }

    if (original.paymentstatus === "complete" || original.paymentstatus === "card-re-assigned") {
      opt.push({
        icon: (
          <IdCardIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/cards/${original?._id}`);
          }
        },
        title: "Employee Card",
      });
      opt.push({
        icon: (
          <IdCardIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
        ),
        onClick: () => {
          if (original?._id) { setshowCardPrintingRequestDialog(true) }
        },
        title: "Card Printing Request",
      });
      // opt.push({
      //   icon: (
      //     <SVGC path={toAbsoluteUrl("/media/icons/svg/list-dependent-card.svg")} className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
      //   ),
      //   onClick: () => {
      //     if (original?._id) {
      //       navigate(`/cards/${original?._id}`);
      //     }
      //   },
      //   title: "Dependent Card",
      // });
    }


    if (original.paymentstatus == "pending" && original?.emp_insertstatus == "incomplete") {
      opt.push({
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/employee/profile/update/${original?._id}`);
          }
        },
        title: "Complete Profile",
      })
    }

    // if (original.paymentstatus == "pending" && original?.emp_insertstatus == "completed") {
    //   opt.push({
    //     icon: (
    //       <SVGC
    //         path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
    //         className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
    //       ></SVGC>
    //     ),
    //     onClick: () => {
    //       if (original?._id) {
    //         navigate(`/employee-view/${original?._id}`);
    //       }
    //     },
    //     title: "Edit",
    //   });
    // }

    if (original?.emp_insertstatus == "completed") {
      opt.push({
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/dependent/add/${original?._id}`);
          }
        },
        title: "Add Dependent",
      })
    }

    if (original?.empstatus && Array.isArray(original.empstatus) && (original?.empstatus[0] == "ACTIVE" || original?.empstatus[0].toLowerCase() == "active")) {
      opt.push({
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/trash.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => setopenDeleteModal(true),
        title: "Delete",
      })
    }

    return opt;
  }, [original]);

  let employeeStatus = useMemo(() => {
    // getvalue
    if (original?.empstatus && Array.isArray(original?.empstatus)) {
      return original?.empstatus[0];
    } else if (typeof original?.empstatus == "string") {
      return original?.empstatus;
    }
  }, [original?.empstatus]);


  const showUpdateKYCOption = useMemo(() => original?.emp_insertstatus == "completed" && original?.empstatus && Array.isArray(original.empstatus) && (original?.empstatus[0] == "ACTIVE" || original?.empstatus[0].toLowerCase() == "active"), [original?.empstatus])


  return (
    <div>

      <ListActionsDropdowns title="Action" isDisabled={employeeStatus == "partial active"} options={prepareOptions} />


      <div>
        <Dialog
          open={openDeleteModal}
          onOpenChange={(e: any) => setopenDeleteModal(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Delete Confirm"
                subContent={`Are you sure, you want to delete the employee/expatriate`}
              />
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex justify-around">
                <DialogTrigger asChild>
                  <Button variant={"outline"} className=" inline-block px-16">
                    No
                  </Button>
                </DialogTrigger>
                <Button
                  disabled={requestStatus.isLoading}
                  onClick={() => {
                    if (original?.paymentstatus == "complete" || original?.paymentstatus == "card-re-assigned") {
                      setopenFileUploadDialog(true);
                    } else {
                      sendDeleteRequest();
                    }
                  }}
                  className=" inline-block px-16"
                >
                  <div className="flex space-x-2">
                    {requestStatus.isLoading && requestStatus.isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Yes
                  </div>
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openFileUploadDialog}
        >
          <DialogContent className="sm:max-w-[525px]" hideClose>
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Request for Quota Deletion"
                subContent={``}
              />
            </DialogHeader>
            <DialogDescription>
              <div className="mb-4">
                <Label className=" uppercase mb-4">
                  please select quota letter
                </Label>
                <Input
                  type="file"
                  placeholder="Select Document"
                  accept="application/pdf,image/jpeg,image/jpg,image/png"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setfileError("");
                    e.target.files && e.target.files.length > 0
                      ? setselectedFile(e.target.files[0])
                      : setselectedFile(undefined)
                  }}
                />
                {fileError && <p className="text-destructive text-xs">{fileError}</p>}
              </div>
              <div className="w-full flex justify-around">

                <Button onClick={() => {
                  setopenFileUploadDialog(false)
                  setopenDeleteModal(true)
                }} variant={"outline"} className=" inline-block px-16">
                  Cancel
                </Button>

                <Button
                  disabled={requestStatus.isLoading}
                  onClick={() => {
                    // setopenFileUploadDialog(false);
                    // setopenDeleteModal(false);
                    // setopenSubmitted(true);

                    sendDeleteRequest();
                  }}
                  className=" inline-block px-16"
                >
                  <div className="flex space-x-2">
                    {requestStatus.isLoading && requestStatus.isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Upload
                  </div>
                </Button>
              </div>
            </DialogDescription>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSubmitted}
          onOpenChange={(e: any) => setopenSubmitted(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="Quota deletion request submitted"
                subContent={``}
              />
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex flex-col justify-center text-center">
                <div className="flex justify-center my-2">
                  <StepCircle previous={true} />
                </div>
                <p className="sub-styles text-center mb-3">
                  Thank you for submitting the request. Our team will review the
                  document and notify you via email once the review process is
                  complete.
                </p>
                <div className="flex justify-center">
                  <Button
                    className="w-[150px] inline-block"
                    variant={"default"}
                    onClick={() => {
                      setopenFileUploadDialog(false);
                      setopenDeleteModal(false);
                      setopenSubmitted(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSubmitted2}
          onOpenChange={(e: any) => setopenSubmitted2(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              {/* <PageHeadingComponent
                mainContent="Employee Deleted"
                subContent={``}
              /> */}
            </DialogHeader>
            <DialogFooter>
              <div className="w-full flex flex-col justify-center text-center">
                <div className="flex justify-center my-2">
                  <StepCircle current previous={true} />
                </div>
                <p className="sub-styles text-center mb-3">
                  Employee Deleted Successfully
                </p>
                <div className="flex justify-center">
                  <Button
                    className="w-[150px] inline-block"
                    variant={"default"}
                    onClick={() => {
                      setopenSubmitted2(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {showUpdateKYCOption && <UpdateKYCDocumentMain currentUserDetails={original} isOpen={isOpenUpdateKYCDialog} onChangeOpenState={(e: any) => setisOpenUpdateKYCDialog(e)} />}
        {<ExemptRequestsMain rowData={original} isOpen={isOpenExemptRequestDialog} onChangeIsOpen={(e: any) => setisOpenExemptRequestDialog(e)} />}

        <CardPrintingRequestDialog data={original} isOpen={showCardPrintingRequestDialog} resetIsOpen={(e: boolean) => setshowCardPrintingRequestDialog(e)} key={'card-printing-request-dialog-component'} />
        <SuccessDialog isOpen={showSuccessDialog} onClickOkBtn={() => {
          dispatch(fetchAllEmployeesAsync())
          setshowSuccessDialog(false)
        }} title="Request Submitted" >
          <p>Your employee deletion request has been submitted, you will receive notification on your registered email. </p>
        </SuccessDialog>
        <ErrorDialog isOpen={requestStatus.isError} message={requestStatus.message} onCancelClick={() => setrequestStatus({ ...requestStatus, isError: false, message: "" })} />
      </div>
    </div>
  );
};

export default DeleteEmployee;
