import { useFormik } from "formik";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setDependents } from "src/Silcers/createDependentSlicer";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { cn } from "src/lib/utils";
import { RootState } from "src/store";
import {
  IDependant,
  createDependantSchema,
  createDependentFields,
} from "../modules";
import { fetchEmployeeDetailsAsync } from "src/Silcers/employeeDetailsSlicer";
// import RenderFormikFiled from "src/components/Common/RenderFormikFiled";

type Props = {
  // currentIndex: number;
  formData: IDependant;
};

const DependForm = ({ formData }: Props) => {

  const { id } = useParams();

  const [openDependentSubmitted, setdependentSubmitted] = useState(false);
  const { createEmployee: { newEmployeeForm }, employeeDetails: { employeeViewDetail } } = useSelector(
    (state: RootState) => state
  );
  // console.log({ formData });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCerpacRequired, setisCerpacRequired] = useState(false)

  const buttonRef = useRef(null);

  const [dprofileImage, setdProfileImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchEmployeeDetailsAsync(id));
    }
    return () => { };
  }, [id]);



  useEffect(() => {
    if (formData?.dependent_profile_image) {
      const file = formData?.dependent_profile_image;
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setdProfileImage(e.target?.result as string);
        };
      }
      return () => { };
    }
  }, [formData.dependent_profile_image]);

  // console.log({formData,currentIndex})

  useEffect(() => {
    if (newEmployeeForm?.given_name) {
      formik.setFieldValue("accompany_with", newEmployeeForm?.given_name + " " + newEmployeeForm?.surname);
    }
    else if (employeeViewDetail?.given_name) {
      formik.setFieldValue("accompany_with", employeeViewDetail?.given_name + " " + employeeViewDetail?.surname);
    }
    return () => { };
  }, [newEmployeeForm?.given_name, newEmployeeForm?.surname, employeeViewDetail?.given_name, employeeViewDetail?.surname]);


  const formik = useFormik<IDependant>({
    initialValues: formData,
    validationSchema: createDependantSchema,
    onSubmit: (values: IDependant) => {
      // console.log("dependend", values);
      dispatch(setDependents({
        ...formik.values,
        employee_id: id
      }));
      navigate("/dependent/review");
    },
  });

  const d_profile_img_input_ref = useRef<any>(null);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setdProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("dependent_profile_image", file);
    }
    else {
      setdProfileImage("");
      formik.setFieldValue("dependent_profile_image", "");
    }
  };

  // const isCerpacRequired = useMemo(() => {
  //   var _dob = formik.values.dob ? new Date(formik.values.dob) : undefined;
  //   var today = new Date();
  //   var eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

  //   if (_dob && _dob > eighteenYearsAgo) {
  //     formik.setFieldValue("cerpac_no", "MINOR");
  //     return true
  //   }
  //   else {
  //     return false
  //   }

  // }, [formik.values.dob, formik])

  useEffect(() => {
    var _dob = formik.values.dob ? new Date(formik.values.dob) : undefined;
    var today = new Date();
    var eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (_dob && _dob > eighteenYearsAgo) {
      formik.setFieldValue("cerpac_no", "MINOR");
      setisCerpacRequired(false)
    }
    else {
      if (formik.values.cerpac_no?.toLowerCase() === "MINOR") {
        formik.setFieldValue("cerpac_no", "");
      }
      setisCerpacRequired(true)
    }

    // if (formik.values.visa_type == "Residence Visa") {
    //   setisCerpacRequired(false)
    // }

    return () => { }
  }, [formik.values.dob])


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.surname}
                  key={createDependentFields.surname.key}
                  onchange={(e: any) => formik.setFieldValue("surname", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.given_name}
                  key={createDependentFields.given_name.key}
                  onchange={(e: any) => formik.setFieldValue("given_name", e)}
                  required
                />
              </div>
            </div>
            {/* Date of birth and Sex */}
            <div className="grid grid-cols-2 gap-x-3">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.dob}
                  key={createDependentFields.dob.key}
                  onchange={(e: any) => formik.setFieldValue("dob", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.sex}
                  key={createDependentFields.sex.key}
                  onchange={(e: any) => formik.setFieldValue("sex", e)}
                  required
                />
              </div>
            </div>
            {/* PAssport no  and  Passport expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.passport_no}
                  key={createDependentFields.passport_no.key}
                  onchange={(e: any) => formik.setFieldValue("passport_no", e)}
                  required
                />
              </div>
              <div>
                <RenderFormikFiled
                  formik={formik}
                  formObject={createDependentFields.passport_expiry}
                  key={createDependentFields.passport_expiry.key}
                  onchange={(e: any) =>
                    formik.setFieldValue("passport_expiry", e)
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="flex flex-col">
              <div
                onClick={() => d_profile_img_input_ref.current.click()}
                className={cn(
                  "relative w-40 h-40 rounded-full overflow-hidden bg-secondary",
                  {
                    "border border-destructive bg-red-50 ":
                      formik.getFieldProps("dependent_profile_image"),
                  }
                )}
              >
                <label
                  htmlFor="profile-upload"
                  className="absolute inset-0 flex items-center justify-center cursor-pointer"
                >
                  <input
                    hidden
                    type="file"
                    ref={d_profile_img_input_ref}
                    name="dependent-profile-pic-input"
                    accept={"image/jpeg,image/png"}
                    className="hidden"
                    onChange={(e: any) => { handleImageUpload(e) }}
                  />
                  <div className="text-center">
                    <img
                      key={"dependent-profile-pic"}
                      src={dprofileImage}
                      alt=""
                      className={`w-full h-full object-cover rounded-full ${dprofileImage === "default-profile-image.jpg"
                        ? "opacity-50"
                        : ""
                        }`}
                    />
                    {!dprofileImage && (
                      <p className="mt-2 sub-styles">
                        Click to upload your Photo
                      </p>
                    )}
                  </div>
                </label>
              </div>
              <div className={"text-xs text-destructive w-40 my-2"}>
                {
                  formik.touched.dependent_profile_image &&
                  formik.errors.dependent_profile_image && (
                    <p className={cn("text-xs font-medium text-destructive")}>
                      {formik.errors.dependent_profile_image as string}
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 p-4">
          <div className="grid xl:grid-cols-3 lg:grid-cols-2 gap-x-2 gap-y-2">
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.passport_doc}
                key={createDependentFields.passport_doc.key}
                value={formik.values.passport_doc}
                onchange={(e: any) => {
                  // console.log({ e });
                  formik.setFieldValue("passport_doc", e);
                }}
                required
              />
            </div>
            {/* Accompany with and relationship  */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.accompany_with}
                key={createDependentFields.accompany_with.key}
                onchange={(e: any) => formik.setFieldValue("accompany_with", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.relationship}
                key={createDependentFields.relationship.key}
                onchange={(e: any) => formik.setFieldValue("relationship", e)}
                required
              />
            </div>

            {/* Visa Type and Visa No  */}

            {/* <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_type}
                key={createDependentFields.visa_type.key}
                onchange={(e: any) => formik.setFieldValue("visa_type", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_number}
                key={createDependentFields.visa_number.key}
                onchange={(e: any) => formik.setFieldValue("visa_number", e)}
                required
              />
            </div> */}
            {/* visa issue date and visa expiry date  */}
            {/* <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_issue}
                key={createDependentFields.visa_issue.key}
                onchange={(e: any) => formik.setFieldValue("visa_issue", e)}
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.visa_expiry}
                key={createDependentFields.visa_expiry.key}
                onchange={(e: any) => formik.setFieldValue("visa_expiry", e)}
                required
              />
            </div> */}

            {/* Cerpac number and cerpac issue date  */}
            <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_no}
                key={createDependentFields.cerpac_no.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_no", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              />
            </div>
            <div>
              {/* <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_issue}
                key={createDependentFields.cerpac_issue.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_issue", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              /> */}
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_expiry}
                key={createDependentFields.cerpac_expiry.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              />
            </div>

            {/* cerpac expiry date */}
            {/* <div>
              <RenderFormikFiled
                formik={formik}
                formObject={createDependentFields.cerpac_expiry}
                key={createDependentFields.cerpac_expiry.key}
                onchange={(e: any) => formik.setFieldValue("cerpac_expiry", e)}
                required={isCerpacRequired}
                disabled={!isCerpacRequired}
              />
            </div> */}
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Button type="submit" className="mb-2 px-10 w-44 mx-5">
            Continue
          </Button>
        </div>
      </form>

      <Dialog open={openDependentSubmitted} onOpenChange={(e) => console.log()}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle className="flex justify-center mb-5">
              Do you want to add More Dependents ?
            </DialogTitle>
            <DialogDescription className=" flex justify-center ">
              <div className="flex flex-row items-center">
                <Button
                  onClick={() => {
                    setdependentSubmitted(false);
                    // navigate('/employee')
                  }}
                  variant={"secondary"}
                  className="px-8"
                >
                  Cancel
                </Button>
                <Button
                  className="focus:outline-none ml-4 px-8"
                  onClick={() => { }}
                >
                  Add More
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DependForm;
