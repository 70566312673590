import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getQuotaReducerState, setQuotaType } from 'src/Silcers/CompanyQuota/QuotaListSlicer'
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs'
import { RootState } from 'src/store'

type Props = {}

const QuotaTypesFilter = (props: Props) => {

    const dispatch = useDispatch()
    const { quotaType } = useSelector((state: RootState) => getQuotaReducerState(state))

    return (
        <Tabs defaultValue="all" value={quotaType} onValueChange={(e:any) => dispatch(setQuotaType(e))} className="mb-2 space-y-6">
            <div className="space-between flex items-center">
                <TabsList>
                    <TabsTrigger value="Active" className="relative">Active</TabsTrigger>
                    <TabsTrigger value="Expired">Expired</TabsTrigger>
                </TabsList>
            </div>
        </Tabs>
    )
}

export default QuotaTypesFilter