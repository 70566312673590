import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { IDependant } from 'src/Modules/EmpExpatriate/modules'
import { getAllEmptyCardRequestApi } from 'src/services/services'
import type { RootState } from '../store'


export interface IDependantDetails extends IDependant {
    _id: string, employee_id: string
}

// Define a type for the slice state
interface IAssigneEmployeeToEmptyCardReducerReducer {
    error: boolean,
    errorMessage: string | undefined
    isLoading: boolean
    allEmployessList: any[],
    allCardsList: any[],
    selectedEmployee: any,
    selectedCard: string,
    assinedEmployeeAndCardsPostData: any,
    assignDialogIsOpen: boolean,
    selectedDesignation: string
}

// Define the initial state using that type
const initialState: IAssigneEmployeeToEmptyCardReducerReducer = {
    error: false,
    errorMessage: undefined,
    isLoading: false,
    allEmployessList: [],
    allCardsList: [],
    selectedEmployee: {},
    selectedCard: "",
    assinedEmployeeAndCardsPostData: [],
    assignDialogIsOpen: false,
    selectedDesignation: ""
}

export const fetchAllEmptyCardRequestAsync: any = createAsyncThunk(
    'employeeDetails/fetch',
    async (id: any, thunkApi: any) => {
        try {

            let response: any = await getAllEmptyCardRequestApi();
            // console.log({ response });
            if (response?.status == 200 && response?.data && Array.isArray(response?.data)) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const assigneEmployeeToEmptyCardSlicer = createSlice({
    name: 'assigneEmployeeToEmptyCardReducer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.isLoading = false;
            state.errorMessage = action.payload
        },
        resetParial: (state) => {
            state.error = false;
            state.errorMessage = undefined;
            state.isLoading = false;
        },
        resetState: (state) => {
            state.error = false;
            state.errorMessage = undefined;
            state.isLoading = false;
            state.allEmployessList = [];
            state.allCardsList = [];
            state.selectedEmployee = {};
            state.selectedCard = ""
            state.assinedEmployeeAndCardsPostData = []
        },
        setSelectedEmployee: (state, action: PayloadAction<any>) => {
            state.selectedEmployee = action.payload;
        },
        setSelectedCard: (state, action: PayloadAction<any>) => {
            state.selectedCard = action.payload;
        },
        setAssignDialogIsOpenStatus: (state, action: PayloadAction<any>) => {
            state.assignDialogIsOpen = action.payload;
        },
        setSelectedDesignation: (state, action: PayloadAction<any>) => {
            state.selectedDesignation = action.payload;
        },
        preparePostData: (state, action: PayloadAction<any>) => {
            let previousList = [...state.assinedEmployeeAndCardsPostData]
            let currentObject = action.payload ? action.payload : {
                selectedEmployee: state.selectedEmployee,
                selectedCard: state.selectedCard,
            }
            let checkCardIDAlreadyExists = previousList && previousList.length > 0 ? previousList.find((card: any) => card.card_id == currentObject.selectedCard) : undefined;
            let newObject = {};
            if (checkCardIDAlreadyExists) {
                let indexOfExistingObject = previousList && previousList.length > 0 && previousList.findIndex((card: any) => card.card_id == currentObject.selectedCard)
                newObject = {
                    ...checkCardIDAlreadyExists,
                    "employee": currentObject.selectedEmployee,
                    "card_id": currentObject.selectedCard
                }
                previousList[indexOfExistingObject as number] = newObject;
            }
            else {
                newObject = {
                    "employee": currentObject.selectedEmployee,
                    "card_id": currentObject.selectedCard
                }
                previousList.push(newObject)
            }

            state.assinedEmployeeAndCardsPostData = previousList;
            state.selectedCard = "";
            state.selectedEmployee = {}
        },
        updatePostData: (state, action: PayloadAction<any>) => {
            let previousList = [...state.assinedEmployeeAndCardsPostData]
            previousList.push({
                "employee": state.selectedEmployee,
                "card_id": state.selectedCard
            })
            state.assinedEmployeeAndCardsPostData = previousList;
            state.selectedCard = "";
            state.selectedEmployee = {}
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(fetchAllEmptyCardRequestAsync.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchAllEmptyCardRequestAsync.fulfilled, (state, action) => {
                state.allCardsList = action.payload?.data;
                state.isLoading = false;
            })
            .addCase(fetchAllEmptyCardRequestAsync.rejected, (state, action) => {
                state.error = true;
                state.isLoading = false;
                state.errorMessage = action.payload;
            })
    }
})

export const {
    setError, resetState, resetParial, setSelectedEmployee, setSelectedCard, preparePostData, setAssignDialogIsOpenStatus, setSelectedDesignation } = assigneEmployeeToEmptyCardSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global
export const getAssigneEmployeeToEmptyCardReducerState = (state: RootState) => state.assigneEmployeeToEmptyCardReducer

export default assigneEmployeeToEmptyCardSlicer.reducer