import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionInstance,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";

import { DataTablePagination } from "../components/data-table-pagination";
import { DataTableToolbar } from "../components/data-table-toolbar";
import { useMainContext } from "src/Modules/EmpExpatriate/MainContext";
import { useDispatch } from "react-redux";
import { setSelectedEmployee } from "src/Silcers/employeesSlicer";
import { cn } from "src/lib/utils";
import { IQuotaList } from "../data/schema";
import { Loader2 } from "lucide-react";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isLoading?: boolean
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isLoading = false
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({});
  const dispatch = useDispatch();
  const {
    state: { selectedEmployees },
  } = useMainContext();
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  React.useEffect(() => {
    // setSelectedEmployee(table.getSelectedRowModel().flatRows);

    let emps: any = [];
    table.getSelectedRowModel().flatRows &&
      table.getSelectedRowModel().flatRows.forEach((e: any) => {
        //
        if (e?.original?.paymentstatus != "complete" && e.original?.emp_insertstatus == "completed") {
          emps[emps.length] = e?.original;
        }
      });
    dispatch(setSelectedEmployee(emps));
    return () => { };
  }, [table.getSelectedRowModel().flatRows]);

  return (
    <div className="space-y-4">
      {/* <DataTableToolbar table={table} /> */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {
              isLoading ? <>
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className=" h-24 text-center"
                  >

                    <div className="w-100 flex justify-center items-center h-100">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Loading....
                    </div>

                  </TableCell>
                </TableRow>
              </> :
                <>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => {
                      const original: any = row.original;
                      let employeeStatus = undefined;

                      // getvalue
                      if (original?.empstatus && Array.isArray(original?.empstatus)) {
                        employeeStatus = original?.empstatus[0];
                      } else if (typeof original?.empstatus == "string") {
                        employeeStatus = original?.empstatus;
                      }

                      return (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                          className={cn({
                            "bg-muted text-gray-400":
                              employeeStatus && employeeStatus == "partial active",
                          })}
                        >
                          {row.getVisibleCells().map((cell) => {
                            // console.log("finding penny",cell)
                            return (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </>
            }
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
