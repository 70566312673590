import React, { ChangeEvent, useMemo } from 'react'
import { Label } from '../Label';
import { Textarea } from '../textarea';
import { cn } from 'src/lib/utils';
import { ClassNameValue } from 'tailwind-merge';

type Props = {
    formik: any;
    label: string;
    nameKey: string;
    placeholder?: string;
    lableClass?: string;
    defaultValue?: string;
    value?: string;
    required?: boolean;
    type?: string;
    disabled?: boolean;
    inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
    caseSensetiveLabel?: boolean;
    maxLength?: number;
    length?: number;
    regex?: any;
    blockletters?: boolean;
    onchange?: (e: any) => void;
    readonly?: boolean;
    prefix?: string;
    onBlur?: (e: any) => void;
    sufix?: boolean;
    showMaxLength?: boolean
    error_className?: ClassNameValue
};


const FormikTextarea = ({
    formik,
    placeholder,
    nameKey,
    label,
    value,
    type,
    inputMode,
    disabled = false,
    caseSensetiveLabel = false,
    maxLength,
    length,
    regex,
    blockletters,
    readonly,
    prefix,
    onchange,
    lableClass,
    required,
    onBlur,
    sufix,
    showMaxLength,
    error_className
}: Props) => {


    let newValue = useMemo(() => formik.values[nameKey], [formik.values[nameKey]])

    return (
        <>
            <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
                {label}
            </Label>
            <div className="mt-2">
                <Textarea
                    name={nameKey}
                    onBlur={onBlur}
                    qa-data={nameKey}
                    disabled={disabled}
                    inputMode={inputMode}
                    placeholder={placeholder ?? label}
                    maxLength={maxLength || length || 225}
                    className={cn("", { uppercase: blockletters, "bg-gray-100": readonly })}
                    readOnly={readonly}
                    prefix={prefix}
                    value={formik.values[nameKey]}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        onchange && onchange(e.target.value);
                    }}
                />
            </div>

            {showMaxLength && <div className="text-xs text-end text-gray-500 mt-1">{newValue?.length || 0}/{maxLength}</div>}


            {formik.touched[nameKey] && formik.errors[nameKey] && (
                <div className={cn("text-xs font-medium text-destructive", error_className)}>
                    {formik.errors[nameKey]}
                </div>
            )}
        </>
    )
}

export default FormikTextarea