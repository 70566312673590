import React, { useEffect, useState } from "react";
import { DataTable } from "./EmployeeTable/components/data-table";

import { columns } from "./EmployeeTable/components/columns";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { resetState as employeeResetState } from "src/Silcers/employeeDetailsSlicer";
import { resetState as dependentResetState } from "src/Silcers/createDependentSlicer";

type Props = {
  allEmployee:any[],
  loadingEmployeeList:any,
};

type EmployueeeListSchema = {
  id: string;
  user_profile_photo: string;
  user_name: string;
  gender: string;
  designation: string;
  nationality: string;
  passport_number: string;
  passport_expiry: string;
  visa_number: string;
  visa_type: string;
  visa_expiry: string;
};

const EmployeeList = ({allEmployee,loadingEmployeeList}: Props) => {
  // const { allEmployee,loadingEmployeeList } = useSelector((state: RootState) => state.employees);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(employeeResetState());
    dispatch(dependentResetState());
    return () => {};
  }, []);

  return (
    <div>
      { loadingEmployeeList ? <>Loading...</>  : <DataTable data={allEmployee} columns={columns} /> }
    </div>
  );
};

export default EmployeeList;
