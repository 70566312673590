import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { toAbsoluteUrl } from "src/Helpers";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import LoadingDialog from "src/components/LoadingDialog";
import { Button } from "src/components/ui/button";
import { getCardDetails, getUpladedFile } from "src/services/services";
import { useAuth } from "../Auth/Core";
import ErrorDialog from "src/components/ErrorDialog";
import axios, { AxiosError } from "axios";
interface IEmployeeCardDetails {
  _id: string | undefined;
  registration_Object_id: string | undefined;
  emp_registration_id: string | undefined;
  company_name: string | undefined;
  card_issue_date: string | undefined;
  card_expiry_date: string | undefined;
  mereg_no: string | undefined;
  card_number: string | undefined;
  card_holder_name: string | undefined;
  nationality: string | undefined;
  passport_number: string | undefined;
  designation: string | undefined;
  cerpac_number: string | undefined;
  cerpac_validity: string | undefined;
  passport_validaity: string | undefined;
  card_type: string | undefined;
  photo: string | undefined;
}

function EllCard() {
  // const user_id = localStorage.getItem('user_id');
  const { auth } = useAuth();
  const [isLOading, setisLOading] = useState(false)
  const [errorState, seterrorState] = useState<{
    isError: boolean,
    message: string
  }>({
    isError: false,
    message: ""
  })
  const resetErrorState = () => seterrorState({ isError: false, message: "" })
  const [cardDetails, setCardDetails] = useState<
    IEmployeeCardDetails | undefined
  >({
    _id: undefined,
    registration_Object_id: undefined,
    emp_registration_id: undefined,
    company_name: undefined,
    card_issue_date: undefined,
    card_expiry_date: undefined,
    mereg_no: undefined,
    card_number: undefined,
    card_holder_name: undefined,
    nationality: undefined,
    passport_number: undefined,
    cerpac_number: undefined,
    cerpac_validity: undefined,
    passport_validaity: undefined,
    card_type: undefined,
    designation: undefined,
    photo: undefined,
  });
  let { id } = useParams();
  const bodyWrapRef = useRef(null);

  useEffect(() => {
    if (id) {
      fetchCardDetails();

    }
    return () => { };
  }, [id]);

  const fetchCardDetails = async () => {
    try {
      setisLOading(true)
      if (auth?.user_id && id) {
        const apiResp = await getCardDetails(id);
        // const pfp = await getProfilePic({ userid: auth?.user_id, _id: id })
        const response = await getUpladedFile("employeedocs", id, "employee_profile_photo");
        let blobData = null
        if (response.data) {
          blobData = URL.createObjectURL(response.data);
        }
        if (apiResp.status == 200) {
          setCardDetails({
            ...apiResp.data,
            photo: blobData
          });
        }
      }
      setisLOading(false)
    } catch (err) {
      let error = err as Error | AxiosError
      if (axios.isAxiosError(error)) {
        seterrorState({ isError: true, message: error.response?.data?.message })
        setisLOading(false)
      }
      else {
        seterrorState({ isError: true, message: error.message })
        setisLOading(false)
      }
    }
  };

  interface ColorChangingDivProps {
    expiryDate: String | undefined;
  }

  const ColorChangingDiv: React.FC<ColorChangingDivProps> = ({ expiryDate }: ColorChangingDivProps) => {
    const [bgColor, setBgColor] = useState<string>('bg-primary');

    useEffect(() => {
      setBgColor(getColorBasedOnExpiry(expiryDate));
    }, [expiryDate]);

    return <div className={`w-9 h-9 rounded-full ${bgColor}`}></div>;
  };

  const getColorBasedOnExpiry = (expiryDate: String | undefined) => {
    // Split the date into day, month, and year components
    if (expiryDate) {

      const [day, month, year] = expiryDate.split("/").map(Number);

      // Create a JavaScript Date object from the provided components
      const currentDate = new Date();
      const providedDate = new Date(year, month - 1, day); // Month is 0-based

      const twoMonthsAway = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 2,
        currentDate.getDate()
      );
      const thirtyDaysAway = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 30
      );

      if (providedDate < currentDate) {
        return "bg-red-500"; // Expired
      } else if (providedDate < thirtyDaysAway) {
        return "bg-orange-500"; // Less than 30 days
      } else if (providedDate < twoMonthsAway) {
        return "bg-yellow-500"; // Less than 2 months
      } else {
        return "bg-primary"; // Default color
      }
    } else return "bg-primary"
  };

  const generatePDF = () => {
    // console.log("Asdasd")

    const report = new jsPDF('landscape', 'pt', 'a4');
    const reportElement = document.getElementById('#report') as HTMLElement;
    if (reportElement) {
      html2canvas(reportElement, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4');
        const width = pdf.internal.pageSize.getWidth();
        const height = (canvas.height * width) / canvas.width;
        // console.log(height, "asd")
        pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        pdf.save('Employee-eel-card.pdf');
      });
    } else {
      console.error('Report element not found.');
    }
    // if (bodyWrapRef.current) {
    //   html2canvas(bodyWrapRef.current).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'pt', 'a4');
    //     const width = pdf.internal.pageSize.getWidth();
    //     const height = (canvas.height * width) / canvas.width;
    //     pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    //     pdf.save('report.pdf');
    //   });
    // } else {
    //   console.error('Report element not found.');
    // }
  };


  // console.log("card details", getColorBasedOnExpiry(cardDetails?.card_expiry_date || "15/10/2024"))
  return (
    <div className="p-4 flex flex-col justify-center items-center">
      <div className=""></div>
      <div className=" w-3/4">
        <div>
          <PageHeadingComponent
            mainContent={"Expatriate Employment Levy Digital Card"}
            subContent={""}
          />
        </div>
        <Button onClick={() => {
          generatePDF()
        }}>Download PDF</Button>

        <div ref={bodyWrapRef} id="#report" className="flex flex-col justify-between mt-9 p-2 shadow-md  w-[800px]">
          <div
            className="h-[500px] w-[778.5px] bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/svg/ell-front.svg")})`,
            }}
          >
            <div className=" h-20"></div>
            <div className="grid grid-cols-8 gap-x-1 px-5">
              <div className="col-span-2">
                <img className=" ml-4 h-40 w-40" src={cardDetails?.photo}></img>
                {/* <div className=" bg-slate-500 h-36 w-36"></div> */}
              </div>
              {/* MID DETAILS */}
              <div className=" col-span-3 pl-3">
                {/* <p className=" font-semibold text-base text-red-600">
                  D - 7 9 8 7 6 5 2 1 2 2 3 8{" "}
                </p> */}
                <div>
                  <h2 className="font-medium text-base text-primary">NAME</h2>
                  <p className=" font-semibold text-lg uppercase ">
                    {cardDetails?.card_holder_name}
                  </p>
                </div>
                <div>
                  <h2 className="font-medium text-base text-primary">
                    NATIONALITY
                  </h2>
                  <p className=" font-semibold text-lg uppercase">
                    {cardDetails?.nationality}
                  </p>
                </div>
                <div className=" grid grid-cols-10">
                  <div className=" col-span-6">
                    <h2 className="font-medium text-base text-primary mt-2">
                      PASSPORT
                    </h2>
                    <p className=" font-semibold text-lg uppercase">
                      {cardDetails?.passport_number}
                    </p>
                  </div>
                  <div className=" col-span-4 ">
                    <h2 className="font-medium text-base text-primary mt-2 ">
                      VALID TILL
                    </h2>
                    <p className=" font-semibold text-lg uppercase">
                      {cardDetails?.passport_validaity ?
                        cardDetails?.passport_validaity.includes("/") ? cardDetails?.passport_validaity : format(new Date(cardDetails?.passport_validaity), "dd/MM/yyyy")
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              {/* QR CODE */}
              <div className="col-span-3 flex flex-col justify-start mt-1">
                <div className="flex justify-evenly">
                  <QRCode
                    size={256}
                    style={{ height: "180px", maxWidth: "180px", width: "180px" }}
                    value={cardDetails?.card_number ? cardDetails?.card_number : 'default'}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <div className="w-9 h-9 rounded-full bg-primary mr-2"></div>
                  {/* <div className={`w-16 h-16 rounded-full bg-${getColorBasedOnExpiry(cardDetails?.card_expiry_date || "15/10/2024")} `}></div> */}
                  <ColorChangingDiv expiryDate={cardDetails?.card_expiry_date} />
                </div>
              </div>
            </div>
            {/* LAST PART */}
            <div>
              <div className="grid grid-cols-8 px-11">
                <div className="col-span-4">
                  <div>
                    <h2 className="font-medium text-xl text-primary">
                      EEL CARD NUMBER
                    </h2>
                    <p className=" font-semibold text-3xl uppercase tracking-widest">
                      P-{cardDetails?.card_number}
                    </p>
                  </div>
                </div>
                <div className=" col-span-1"></div>
                {/* ISSUE DATE VALID DATE */}
                <div className="mt-3 col-span-3 ml-7">
                  <div>
                    <h2 className=" text-center font-semibold uppercase text-lg mb-6">Validity</h2>
                  </div>
                  <div className=" grid grid-cols-2">
                    <div>
                      <p className=" font-semibold text-base uppercase ml-8">
                        FROM
                      </p>
                    </div>
                    <div className=" ">
                      <h2 className="font-medium text-xl text-primary ">
                        {cardDetails?.card_issue_date}
                      </h2>
                    </div>
                  </div>
                  <div className=" grid grid-cols-2 mt-2">
                    <div>
                      <p className=" font-semibold text-base uppercase ml-8">
                        TILL
                      </p>
                    </div>
                    <div className=" ">
                      <h2 className="font-medium text-xl text-primary ">
                        {cardDetails?.card_expiry_date}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BACK */}
          <div
            className="h-[500px] w-[778.5px] bg-no-repeat bg-cover bg-center mt-5"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/svg/ell-back.svg")})`,
            }}
          >
            <div className=" h-11"></div>
            <div className="grid grid-cols-8 gap-x-1 px-5">

              {/* MID DETAILS */}
              <div className=" col-span-5 pl-6">
                <div className=" grid grid-cols-2">
                  <div>
                    <h2 className="font-medium text-lg text-primary mt-2">
                      CERPAC
                    </h2>
                    <p className=" font-semibold text-xl uppercase">
                      {cardDetails?.cerpac_number}
                    </p>
                  </div>
                  <div>
                    <h2 className="font-medium text-lg text-primary mt-2">
                      VALID TILL
                    </h2>
                    <p className=" font-semibold text-xl uppercase">
                      {cardDetails?.cerpac_validity ?
                        cardDetails?.cerpac_validity.includes("/") ? cardDetails?.cerpac_validity : format(new Date(cardDetails?.cerpac_validity), "dd/MM/yyyy")
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              {/* QR CODE */}
              <div className="col-span-3 flex flex-col justify-start mt-[-7px]">

              </div>
            </div>
            {/* LAST PART */}
            <div>
              <div className="grid grid-cols-8 px-11">
                <div className="col-span-3">
                  <div>
                    <h2 className="font-medium text-lg text-primary mt-5">
                      M.E.REG. NUMBER
                    </h2>
                    <p className=" font-semibold text-xl uppercase">
                      {cardDetails?.mereg_no}
                    </p>
                    <h2 className="font-medium text-lg text-primary mt-5">
                      COMPANY NAME
                    </h2>
                    <p className=" font-semibold text-xl uppercase">
                      {cardDetails?.company_name}
                    </p>
                    <h2 className="font-medium text-lg text-primary mt-5">
                      DESIGNATION
                    </h2>
                    <p className=" font-semibold text-xl uppercase">{cardDetails?.designation}</p>
                    <h2 className="font-medium text-lg text-primary mt-5">
                      PLACE OF ISSUE
                    </h2>
                    <p className=" font-semibold text-xl uppercase">FCT ABUJA</p>
                  </div>
                </div>
                <div className=" col-span-2"></div>
                {/* ISSUE DATE VALID DATE */}
                <div className="mt-3 col-span-3">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingDialog isOpen={isLOading} />
      <ErrorDialog isOpen={errorState.isError} message={errorState.message} onCancelClick={resetErrorState} />
    </div>
  );
}

export default EllCard;
