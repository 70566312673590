import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InformDialog from 'src/components/InformDialog'
import { RootState } from 'src/store'
import SetupForm from '../Components/SetupForm'
import SetupFormReview from '../Components/SetupFormReview'

type Props = {}

const CreateQuotaSetup = (props: Props) => {

    const { activeTab } = useSelector((state: RootState) => state.createQuotaSetup)
    return (
        <div>
            {activeTab == 1 ? <SetupForm /> : null}
            {activeTab == 2 ? <SetupFormReview /> : null}
        </div>
    )
}

export default CreateQuotaSetup