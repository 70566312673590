import { optionsType } from "@/helper/index";
import React, { useEffect, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select";

import { cn } from "src/lib/utils";
import { Label } from "../Label";
import { Info, InfoIcon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

type Props = {
  formik: any;
  nameKey: string;
  options?: optionsType[] | undefined;
  label: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onchange?: (e: any) => void;
  showInfoIcon?: boolean
  infoContent?: string
};

const FormikSelect = ({
  formik,
  nameKey,
  options = ["type1", "type2"].map((o: any) => ({ label: o, value: o })),
  placeholder,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  disabled = false,
  showInfoIcon,
  infoContent,
  onchange,
}: Props) => {
  const ref = useRef<any>();


  // console.log({nameKey,disabled});


  useEffect(() => {
    ref.current.innerText = formik.values[nameKey] || "Select";
    return () => { }
  }, [formik.values[nameKey]]);

  return (
    <>
      <div className="flex justify-between  mt-2">
        <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
          {label}
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event:any) => event.preventDefault()}>
                 <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>

      <div className="mt-2">
        <Select
          name={nameKey}
          onValueChange={onchange}
          // value={}
          //defaultValue={field.value}
          //disabled={disabled}
          qa-data={nameKey}
          disabled={disabled}

        >
          <SelectTrigger ref={ref} value={formik.values[nameKey] || ""}>
            <div className="flex justify-between">
              <SelectValue placeholder={`Select `} />
            </div>
          </SelectTrigger>
          <SelectContent>
            {options &&
              options.map(({ label, value }: optionsType) => (
                <SelectItem value={value}>{label}</SelectItem>
              ))}
          </SelectContent>

        </Select>
        {formik.touched[nameKey] && formik.errors[nameKey] && (
          <p className={cn("text-xs font-medium text-destructive")}>
            {formik.errors[nameKey]}
          </p>
        )}

      </div>
    </>
  );
};

export default FormikSelect;
