import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "./EmployeeTable/components/data-table";
import tasks from "./EmployeeTable/data/tasks.json";
import { columns } from "./EmployeeTable/components/columns";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { getCurruncyRate } from "src/services/services";
import { useDispatch } from "react-redux";
import { fetchAmtOfSelectedEmployeesAsync, setSelectedEmployeesWithPayment } from "src/Silcers/employeesSlicer";

type Props = {};

const EmployeeList = (props: Props) => {

  const dispatch = useDispatch();
  const { selectedEmployee, selectedEmployeesWithPayment } = useSelector(
    (state: RootState) => state.employees
  );

  useEffect(() => {
    if (selectedEmployee && selectedEmployee) {
      const ids = selectedEmployee.map((emp: any) => emp._id)
      // console.log({ ids })
      dispatch(fetchAmtOfSelectedEmployeesAsync(ids))
    }
    return () => { }
  }, [])



  return (
    <div>
      <DataTable data={selectedEmployeesWithPayment || []} columns={columns} />
    </div>
  );
};

export default EmployeeList;
