import { ShieldClose } from "lucide-react";
import React from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../ui/dialog";
import { Button } from "../ui/button";

type Props = {
    isOpen: boolean;
    onCancelClick?: () => void;
    message?: string;
    title?: string;
    extraBtn?: boolean,
    extraBtnClick?: () => void,
    extraBtnVarient?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | null | undefined,
    extraBtnTitle?: string
};

const ErrorDialog = ({ isOpen, onCancelClick, message, title, extraBtnClick, extraBtn, extraBtnVarient, extraBtnTitle }: Props) => {
    return (
        <Dialog open={isOpen}
            onOpenChange={
                () => { }
            }>
            <DialogContent hideClose>
                <DialogHeader>
                    <DialogTitle></DialogTitle>
                    <DialogDescription>
                        <div className="flex flex-col items-center">
                            <div className="flex space-x-2 items-center ">
                                <div className="text-destructive text-lg font-bold">ERROR
                                </div>
                            </div>
                            <ShieldClose size={24}
                                className="text-destructive" />
                            <div className="my-2">
                                {message}</div>
                            <div>
                                <Button variant="secondary" className="focus:outline-none"
                                    onClick={onCancelClick}>
                                    {title || "Cancel"}
                                </Button>
                               {extraBtn && <Button variant={extraBtnVarient} onClick={()=> extraBtnClick && extraBtnClick()} >{extraBtnTitle || "Submit"}</Button>}
                            </div>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorDialog;
