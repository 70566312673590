import RenderFormikFiled from 'src/components/Common/RenderFormikFiled';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card';
import { cn } from 'src/lib/utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { resetError, resetState, setNewPasswordAsync } from 'src/Silcers/setNewPasswordSlicer';
import ErrorDialog from 'src/components/ErrorDialog';
import { Button } from 'src/components/ui/button';
import { RootState } from 'src/store';
import PageHeadingComponent from 'src/components/Common/PageHeadingComponent';
import { SET_NEW_PASSWORD_SCHEMA, SetNewPasswordFileds } from './module';
import SuccessDialog from 'src/components/SuccessDialog';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setPasswordAsync } from 'src/Silcers/forgotPasswordSlicer';
import { removeAuth, useAuth } from '../Core';

const SetNewPassword = () => {

    const { token } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading, isValid, isError, message, status } = useSelector(((state: RootState) => state.setNewPassword))

    useEffect(() => {
        removeAuth();
        return () => { }
    }, [])


    useEffect(() => {
        if (status == 200) {
            navigate("/")
        }
        return () => { }
    }, [status])


    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema: SET_NEW_PASSWORD_SCHEMA,
        onSubmit(values) {
            dispatch(setNewPasswordAsync({
                token,
                password: values.password
            }))
        },
    })

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
    }, [])


    return (
        <div className=' flex justify-center items-center h-[85vh] bg-secondary '>
            <div className={cn("grid gap-6 w-1/4")}>
                <form onSubmit={formik.handleSubmit}>
                    <Card className="my-8 rounded-none border-none">
                        <CardHeader className="space-y-1">
                            <CardTitle className="text-2xl text-primary uppercase">
                                <PageHeadingComponent mainContent='Reset Password' />
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="grid gap-2">
                            {isError && (
                                <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
                                    <span className="font-bold text-destructive">{message}</span>
                                </div>
                            )}
                            <RenderFormikFiled
                                formik={formik}
                                formObject={SetNewPasswordFileds.password}
                                required={true}
                                key={SetNewPasswordFileds.password.key}
                                onchange={(e: any) =>
                                    formik.setFieldValue("password", e)
                                }
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={SetNewPasswordFileds.confirm_password}
                                required={true}
                                key={SetNewPasswordFileds.confirm_password.key}
                                onchange={(e: any) =>
                                    formik.setFieldValue("confirm_password", e)
                                }
                            />
                        </CardContent>
                        <CardFooter>
                            <div className="flex flex-col justify-center w-full">
                                <Button disabled={isLoading} className="w-full">Submit</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </form>
            </div>
            <ErrorDialog isOpen={isError} message={message} onCancelClick={() => dispatch(resetError())} />
            <SuccessDialog isOpen={isValid} onClickOkBtn={() => {
                dispatch(resetState())
                navigate("/")
            }} title='Password Changed Successfully' >
                <div></div>
            </SuccessDialog>
        </div>
    )
}

export default SetNewPassword