import React from 'react'


type Props = {}

const Splashscreen = (props: Props) => {
    return (
        <div id="root" className='container' style={{ height: '100vh' }}>
            <div className="d-flex align-items-center justify-content-center bg-light " style={{}}>
                Loading
            </div>
        </div>
    )
}

export default Splashscreen