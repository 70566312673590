import { useEffect, useMemo, useState } from "react";
import { Button } from "src/components/ui/button";

import axios, { AxiosError, AxiosResponse } from "axios";
import { Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import {
  createNewEmployee,
  getProfilePic,
  getUpladedFile,
  uploadfile,
} from "src/services/services";
import { RootState } from "src/store";
import { IDependant, Icreate_employee } from "../../modules";
import RenderDependentProfileImg from "../EmployeeForm/RenderDependentProfileImg";
import {
  fetchEmployeeDependentsAsync,
  fetchEmployeeDetailsAsync,
  resetState,
} from "src/Silcers/employeeDetailsSlicer";
import { Label } from "src/components/ui/Label";
import { format } from "date-fns";
import DependentsList from "src/Modules/Dependents/DependentsList";
import { getUploadFileData } from "src/Helpers";
import ViewFIleDialog from "src/components/ViewFIleDialog";

function EmployeeView() {
  const [profileImage, setProfileImage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auth } = useAuth();
  const { employeeViewDetail } = useSelector(
    (state: RootState) => state.employeeDetails
  );
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const formData: Icreate_employee = useMemo(
    () => employeeViewDetail,
    [employeeViewDetail]
  );

  const [isLoadingImage, setisLoadingImage] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(fetchEmployeeDetailsAsync(id));
      dispatch(fetchEmployeeDependentsAsync(id))
      getProfilePicUrl(id)
    }
    return () => { };
  }, [id]);

  useEffect(() => {
    dispatch(resetState());

    return () => { };
  }, []);

  useEffect(() => {
    const file = employeeViewDetail?.employee_profile_photo;
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
    // } else if (typeof ) {
    //   //setProfileImage(file);
    //   getProfilePicUrl(employeeViewDetail?._id);
    // }

    return () => { };
  }, [employeeViewDetail]);

  const getProfilePicUrl = async (id: string) => {
    try {
      setisLoadingImage(true)
      const response: any = await getUploadFileData("employeedocs", id, "employee_profile_photo");
      if (response) {
        setProfileImage(response)
        setisLoadingImage(false)
      }
    } catch (error) {
      setisLoadingImage(false)
    }
  };

  return (
    <div className="p-4 lg:mx-auto lg:w-4/4 border rounded">
      <PageHeadingComponent
        mainContent={"view Employee/Expatriate Details"}
        subContent={""}
      />

      {/*  */}

      <div className="card bg-base-100 shadow-sm rounded-md">
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Passport NO and Passport Expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Passport No</h4> */}
                <Label className="uppercase">Passport No</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_no}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Passport Expiry</h4> */}
                <Label className="uppercase">Passport Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_expiry && !formData?.passport_expiry.includes("/") && format(new Date(formData.passport_expiry), "dd/MM/yyyy")}
                  {formData?.passport_expiry && formData?.passport_expiry.includes("/") && formData.passport_expiry}

                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">PASSPORT DOCUMENT</h4> */}
                <Label className="uppercase">PASSPORT DOCUMENT</Label>
                <p className=" text-sm  text-primary hover:underline">
                  {/* {typeof formData?.passport_doc_url === "string"
                  ? formData?.passport_doc_url
                  : null}*/}
                  {/* {typeof formData?.passport_doc === "string"
                    ? formData?.passport_doc
                    : null} */}
                  {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
                  {formData && id && formData.passport_doc && typeof formData.passport_doc == "string"  && formData.passport_doc?.trim() && <ViewFIleDialog doc_id={id} fileType={"employeedocs"} file_key={"uploadpassport"} title="Passport Document" file_ext={formData.passport_doc as string} />}
                </p>
              </div>
            </div>
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Surname</h4> */}
                <Label className="uppercase">Surname</Label>
                <p className=" text-sm text-gray-500">{formData?.surname}</p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Given Name</h4> */}
                <Label className="uppercase">Given Name</Label>
                <p className=" text-sm text-gray-500">{formData?.given_name}</p>
              </div>
            </div>
            {/* M EREG NO  and Type Of Employee*/}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">M. e Reg No.</h4> */}
                <Label className="uppercase">M. e Reg No.</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.mereg_number}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Type Of Employee</h4> */}
                <Label className="uppercase">Type Of Employee</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.type_of_employee}
                </p>
              </div>
            </div>

            {/*  Quota Position and Quota Expiry  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Designation</h4> */}
                <Label className="uppercase">Quota Position</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.quota_position}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Country of origin</h4> */}
                <Label className="uppercase">Quota Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.quota_expiry}
                </p>
              </div>
            </div>

            {/* Country of origin  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Country of origin</h4> */}
                <Label className="uppercase">Country of origin</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.country_of_origin}
                </p>
              </div>
              <div>
                <Label className="uppercase">Name of Company</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.name_of_company}
                </p>
              </div>
            </div>


            {/* Nationality and DOB  */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Nationality</h4> */}
                <Label className="uppercase">Nationality</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.nationality}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Date of Birth</h4> */}
                <Label className="uppercase">Date of Birth</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.dob && !formData?.dob.includes("/") && format(new Date(formData.dob), "dd/MM/yyyy")}
                  {formData?.dob && formData?.dob.includes("/") && formData.dob}
                </p>
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="relative w-40 h-40 rounded-full overflow-hidden bg-secondary">
              <label
                htmlFor="profile-upload"
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
              >
                <div className="text-center">

                  {isLoadingImage ?
                    <div className="flex flex-col items-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      <div>Loading..</div>
                    </div> : <>
                      <img
                        src={profileImage}
                        alt=""
                        className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                          ? "opacity-50"
                          : ""
                          }`}
                      />
                      {profileImage === "" && (
                        <p className="mt-2 ">Click to upload your Photo</p>
                      )}
                    </>
                  }
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 gap-4 p-4">
          {/* Marital Status and Sex */}
          <div className="grid grid-cols-2 gap-x-3">
            <div>
              {/* <h4 className=" uppercase">Marital Status</h4> */}
              <Label className="uppercase">Marital Status</Label>
              <p className=" text-sm text-gray-500">
                {formData?.marital_status}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Sex</h4> */}
              <Label className="uppercase">Sex</Label>
              <p className=" text-sm text-gray-500">{formData?.sex}</p>
            </div>
          </div>
          {/* Maretial Status */}
          <div className="grid grid-cols-2 gap-x-3">
            <div></div>
          </div>

          {/* passport expiry and cerpac expiry date  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Cerpac no / Temporary receipt no</h4> */}
              <Label className="uppercase">
                Cerpac no / Temporary receipt no
              </Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_no}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Cerpac Expiry date</h4> */}
              <Label className="uppercase">Cerpac Expiry date</Label>
              <p className=" text-sm text-gray-500">
                {formData?.cerpac_expiry && !formData?.cerpac_expiry.includes("/") &&
                  format(new Date(formData.cerpac_expiry), "dd/MM/yyyy")}
                {formData?.cerpac_expiry && formData?.cerpac_expiry.includes("/") &&
                  formData.cerpac_expiry}
              </p>
            </div>
          </div>
          {/* upload passport and upload cerpac front */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">upload crepac front </h4> */}
              <Label className="uppercase">crepac front</Label>
              <p className=" text-sm  text-primary hover:underline">
                {/* {formData?.cerpac_front_doc_url as any} */}
                {/* {typeof formData?.cerpac_front_doc_url === "string"
                  ? formData?.cerpac_front_doc_url
                  : null}*/}
                {/* {typeof formData?.cerpac_front_doc === "string"
                  ? formData?.cerpac_front_doc
                  : null} */}
                {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
                {formData && id && formData.cerpac_front_doc && typeof formData.cerpac_front_doc == "string"  && formData.cerpac_front_doc?.trim() && <ViewFIleDialog doc_id={id} fileType={"employeedocs"} file_key={"cerpac_front"} title="Cerpack Front Document" file_ext={formData.cerpac_front_doc as string} />}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">upload crepac Back </h4> */}
              <Label className="uppercase">crepac Back</Label>
              <p className=" text-sm = text-primary hover:underline">
                {/* {formData?.cerpac_back_doc_url as any} */}
                {/* {typeof formData?.cerpac_back_doc_url === "string"
                  ? formData?.cerpac_back_doc_url
                  : null} */}
                {/* {typeof formData?.cerpac_back_doc === "string"
                  ? formData?.cerpac_back_doc
                  : null} */}
                {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
                {formData && id && formData.cerpac_back_doc && typeof formData.cerpac_back_doc == "string"  && formData.cerpac_back_doc?.trim() && <ViewFIleDialog doc_id={id} fileType={"employeedocs"} file_key={"cerpac_back"} title="Cerpack Back Document" file_ext={formData.cerpac_back_doc as string} />}
              </p>
            </div>
          </div>
          {/* Visa type and upload cerpac back */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Visa type</h4> */}
              <Label className="uppercase">Visa type</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_type}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Visa Number</h4> */}
              <Label className="uppercase">Visa Number</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_number}</p>
            </div>
          </div>
          {/* Visa no and date of expiry  */}
          <div className=" grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">date of expiry visa</h4> */}
              <Label className="uppercase">visa expiry date</Label>
              <p className=" text-sm text-gray-500">
                {formData?.visa_expiry && !formData?.visa_expiry.includes("/") && format(new Date(formData.visa_expiry), "dd/MM/yyyy")}
                {formData?.visa_expiry && formData?.visa_expiry.includes("/") && formData.visa_expiry}

              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Name of Company</h4> */}
              <Label className="uppercase">Visa Document</Label>
              <p className=" text-sm = text-primary hover:underline">
                {formData && id && formData.visa_doc  && formData.visa_doc?.trim()  && <ViewFIleDialog doc_id={id} fileType={"employeedocs"} file_key={"visa_document"} title="Visa Document" file_ext={formData.visa_doc as string} />}
              </p>
            </div>
          </div>
          {/* Work status and  Name of Company */}
          <div className=" grid grid-cols-2 gap-x-2">
            {/* <div>
              <h4 className=" uppercase">Work status</h4>
              <p className=" text-sm text-gray-500">{formData?.work_status}</p>
            </div> */}
          </div>
          {/* Employee phone no and employee email */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Employee phone number</h4> */}
              <Label className="uppercase">Employee phone number</Label>
              <p className=" text-sm text-gray-500">
                +{formData?.employee_phone_code}{"-"}
                {formData?.employee_phone_number}
              </p>
            </div>
            <div>
              <Label className="uppercase">Employee email</Label>
              {/* <h4 className=" uppercase">Employee email</h4> */}
              <p className=" text-sm text-gray-500">
                {formData?.employee_email}
              </p>
            </div>
          </div>
          {/* Employee Address 1 / 2 */}
          <div>
            {/* <h4 className=" uppercase">Employee Address 1</h4> */}
            <Label className="uppercase">HOUSE NO / AREA</Label>
            <p className=" text-sm text-gray-500">
              {formData?.employee_address_1}
            </p>
          </div>
          <div>
            <Label className="uppercase">STREET / LANDMARK</Label>
            {/* <h4 className=" uppercase">Employee Address 2</h4> */}
            <p className=" text-sm text-gray-500">
              {formData?.employee_address_2}
            </p>
          </div>
          {/* Country and state  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">Country</h4> */}
              <Label className="uppercase">Country</Label>
              <p className=" text-sm text-gray-500">{formData?.country}</p>
            </div>
            <div>
              {/* <h4 className=" uppercase">State</h4> */}
              <Label className="uppercase">State</Label>
              <p className=" text-sm text-gray-500">{formData?.state}</p>
            </div>
          </div>
          {/* City and Zip code  */}
          <div className="grid grid-cols-2 gap-x-2">
            <div>
              {/* <h4 className=" uppercase">City</h4> */}
              <Label className="uppercase">City</Label>
              <p className=" text-sm text-gray-500">{formData?.city}</p>
            </div>
            <div>
              <Label className="uppercase">Zip Code</Label>
              {/* <h4 className=" uppercase">Zip Code</h4> */}
              <p className=" text-sm text-gray-500">{formData?.zipcode}</p>
            </div>
          </div>

          {/* <div className="grid grid-cols-2 gap-x-2">
            <div>
              <h4 className=" uppercase">Dependents</h4>
              <p className=" text-sm text-gray-500">{formData.is_dependants}</p>
            </div>
          
          </div> */}
        </div>

        <div className="p-2">
          <div className="uppercase text-primary font-semibold my-2">Dependents </div>
          <DependentsList />
        </div>

        <div className="flex justify-center w-full">
          <Button
            onClick={() => navigate({ pathname: `/employee-update/${id}` })}
            variant="outline"
            className="mb-2 px-10 w-44 mx-5"
          >
            Edit
          </Button>
          <Button
            disabled={isLoading}
            variant={
              isLoading ? "secondary" : isError ? "destructive" : "default"
            }
            onClick={() => {
              navigate("/employee");
            }}
            className="mb-2 px-10 w-44 mx-5"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmployeeView;
