import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";

import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { Loader2, ShieldClose, PlusIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/Modules/Auth/Core";

import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Label } from "src/components/ui/Label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { uploadfile } from "src/services/services";
import { RootState } from "src/store";
import {
  createDependentAsync,
  partialReset,
  resetState,
  setError,
} from "src/Silcers/createDependentSlicer";

import { resetState as resetCreateEmployeeState } from "src/Silcers/createEmployeeSlicer";
import { SVGC } from "src/Helpers/SVGC";
import { checkIfAnyFilesToBeUpload, toAbsoluteUrl } from "src/Helpers";
import LoadingDialog from "src/components/LoadingDialog";
import ErrorDialog from "src/components/ErrorDialog";
import { cn } from "src/lib/utils";

function ReviewDependent() {
  const {
    createDependent: { error, message, isLoading, status, dependents },
    createEmployee: { newEmployeeForm },
    employeeDetails: { employeeViewDetail }
  } = useSelector((state: RootState) => state);


  const [isError, setisError] = useState(false);
  const [openSuccessModel, setopenSuccessModel] = useState(false)
  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });
  const formData: any = dependents;

  const dispatch = useDispatch();

  const { auth } = useAuth();

  const [profileImage, setProfileImage] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (status == 201) {
      setopenSuccessModel(true)
    }

    return () => { };
  }, [status]);

  useEffect(() => {
    const file = dependents?.dependent_profile_image;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
    return () => { };
  }, [dependents?.dependent_profile_image]);



  const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
    setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
    if (isError) {
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
      dispatch(partialReset())
      return
    }
  };

  const onSubmit = async () => {
    try {

      let data: any = { ...dependents }
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
      data = await checkIfAnyFilesToBeUpload(data, uploadProgress)

      if (data) {
        setTimeout(() => {
          setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          if (newEmployeeForm?._id) {
            data = {
              ...data,
              name_of_company: newEmployeeForm?.name_of_company,
              mereg_number: newEmployeeForm?.mereg_number,
              nationality: newEmployeeForm?.nationality,
              _id: newEmployeeForm._id,
            };
          }

          else if (employeeViewDetail?._id) {
            data = {
              ...data,
              name_of_company: employeeViewDetail?.name_of_company,
              mereg_number: employeeViewDetail?.mereg_number,
              nationality: employeeViewDetail?.nationality,
              _id: employeeViewDetail._id,
            };
          }

          // console.log("FINAL SEND DEPENDE", data);
          dispatch(
            createDependentAsync({
              ...data,
            })
          );
        }, 500);
      }

    } catch (error) {
      const err = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        dispatch(setError({
          message: err.response?.data?.message,
          status: err.response?.data?.status,
        }))
      }
      else {
        dispatch(setError({
          message: err.message,
          status: 404,
        }))
      }
      // console.log(err?.message);
    }
  };

  return (
    <div className="p-4 mx-auto w-4/4 border rounded">
      <PageHeadingComponent
        mainContent={"DEPENDENT/EXPATRIATE Review"}
        subContent={""}
      />
      <div className="card bg-base-100 shadow-sm rounded-md">
        <div className="grid grid-cols-4 gap-4">
          <div className="col col-span-3 p-4 grid grid-col-1 gap-3">
            {/* Surename And GIVEN NAME */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Surname</h4> */}
                <Label className="uppercase">Surname</Label>
                <p className=" text-sm text-gray-500">{formData?.surname}</p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Given Name</h4> */}
                <Label className="uppercase">Given Name</Label>
                <p className=" text-sm text-gray-500">{formData?.given_name}</p>
              </div>
            </div>

            {/* DOB And SEX */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Date of Birth</h4> */}
                <Label className="uppercase">Date of Birth</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.dob &&
                    format(new Date(formData.dob), "dd/MM/yyyy")}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Sex</h4> */}
                <Label className="uppercase">Sex</Label>
                <p className=" text-sm text-gray-500">{formData?.sex}</p>
              </div>
            </div>
            {/* Passport NO and Passport Expiry */}
            <div className="grid grid-cols-2 gap-x-2">
              <div>
                {/* <h4 className=" uppercase">Passport No</h4> */}
                <Label className="uppercase">Passport No</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_no}
                </p>
              </div>
              <div>
                {/* <h4 className=" uppercase">Passport Expiry</h4> */}
                <Label className="uppercase">Passport Expiry</Label>
                <p className=" text-sm text-gray-500">
                  {formData?.passport_expiry &&
                    format(new Date(formData.passport_expiry), "dd/MM/yyyy")}
                </p>
              </div>
            </div>
          </div>
          <div className="col col-span-1 p-4 flex justify-center items-center">
            <div className="relative w-40 h-40 rounded-full overflow-hidden bg-secondary">
              <label
                htmlFor="profile-upload"
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
              >
                <div className="text-center">
                  <img
                    src={profileImage}
                    alt=""
                    className={`w-full h-full object-cover rounded-full ${profileImage === "default-profile-image.jpg"
                      ? "opacity-50"
                      : ""
                      }`}
                  />
                  {profileImage === "" && (
                    <p className="mt-2 ">Click to upload your Photo</p>
                  )}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 gap-4 p-4">
          {/* Marital Status and Sex */}
          <div className="grid grid-cols-3 gap-x-3">
            <div>
              {/* <h4 className=" uppercase">PASSPORT DOCUMENT</h4> */}
              <Label className="uppercase">PASSPORT DOCUMENT</Label>
              <p className=" text-sm  text-gray-500">
                {/* {typeof formData?.passport_doc_url === "string"
                  ? formData?.passport_doc_url
                  : null}
                {typeof formData?.passport_doc === "string"
                  ? formData?.passport_doc
                  : null} */}
                {formData?.passport_doc &&
                  formData?.passport_doc instanceof File
                  ? formData?.passport_doc.name
                  : null}
              </p>
            </div>
            <div>
              {/* <h4 className=" uppercase">Sex</h4> */}
              <Label className="uppercase">ACCOMPANY WITH</Label>
              <p className=" text-sm text-gray-500">{formData?.accompany_with}</p>
            </div>

            <div>
              <Label className="uppercase">Relationship</Label>
              <p className=" text-sm text-gray-500">{formData?.relationship}</p>
            </div>
          </div>
          {/* Visa Type & Visa Number & Visa Issue Date */}
          {/* <div className="grid grid-cols-3 gap-x-3">
            <div>
              <Label className="uppercase">Visa type</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_type}</p>
            </div>
            <div>
              <Label className="uppercase">Visa Number</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_number}</p>
            </div>
            <div>
              <Label className="uppercase">Visa Issue Date</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_issue && format(new Date(formData?.visa_issue), "dd/MM/yyyy")}</p>
            </div>
          </div> */}
          {/* visa expiry &  CERPAC Number & CERPAC Isssue date */}
          <div className="grid grid-cols-3 gap-x-3">
            {/* <div>
              <Label className="uppercase">VISA EXPIRY</Label>
              <p className=" text-sm text-gray-500">{formData?.visa_expiry && format(new Date(formData?.visa_expiry), "dd/MM/yyyy")}</p>
            </div> */}
            <div>
              <Label className="uppercase">Cerpac Number</Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_no}</p>
            </div>
            {/* <div>
              <Label className="uppercase">CERPAC Issue</Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_issue && format(new Date(formData?.cerpac_issue), "dd/MM/yyyy")}</p>
            </div> */}
            <div>
              <Label className="uppercase">CERPAC EXPIRY</Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_expiry && format(new Date(formData?.cerpac_expiry), "dd/MM/yyyy")}</p>
            </div>
          </div>
          {/* CERPAC Expiry date */}
          {/* <div className="grid grid-cols-3 gap-x-3">
            <div>
              <Label className="uppercase">CERPAC EXPIRY</Label>
              <p className=" text-sm text-gray-500">{formData?.cerpac_expiry && format(new Date(formData?.cerpac_expiry), "dd/MM/yyyy")}</p>
            </div>
          </div> */}
        </div>



        <div className="flex justify-center w-full">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="mb-2 px-10 w-44 mx-5"
          >
            Edit
          </Button>
          <Button
            disabled={isLoading}
            variant={
              isLoading || uploadingFileStatus.isLoading
                ? "secondary"
                : isError
                  ? "destructive"
                  : "default"
            }
            onClick={() => onSubmit()}
            className="mb-2 px-10 w-44 mx-5"
          >
            {isLoading && isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </div>

      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Sending Requests" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(partialReset())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => onSubmit()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />

      <Dialog open={openSuccessModel} onOpenChange={() => console.log()}>
        <DialogContent hideClose>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 items-center ">
                  <div className="text-destructive text-lg font-bold"></div>
                </div>
                <div className="border border-primary rounded-full">
                  <SVGC
                    path={toAbsoluteUrl("/media/icons/svg/tick-circle.svg")}
                    className="h-[32px] w-[32px]"
                  />
                </div>
                <div className="my-2">Dependent Created</div>
                <div className="flex justify-center space-x-2">
                  <Button
                    size={"sm"}
                    variant="secondary"
                    className="focus:outline-none mt-2"
                    onClick={() => {
                      dispatch(resetState());
                      navigate("/dependent/create");
                    }}
                  >
                    <PlusIcon size={18} className="me-2" /> Dependent
                  </Button>
                  <Button
                    size={"sm"}
                    className="focus:outline-none mt-2"
                    onClick={() => {
                      dispatch(resetState());
                      dispatch(resetCreateEmployeeState());
                      navigate("/employee");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReviewDependent;
