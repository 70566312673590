import * as yup from 'yup'

export interface IEnquiryForm {
    name: string | undefined,
    email: string | undefined,
    description: string | undefined,
    contact: string | undefined,
    country_code: string | undefined

    // password: string | undefined,
    // is_remember_me: boolean | undefined
}


export const INIT_ENQUIRY_FORM: IEnquiryForm = {
    name: "",
    email: "",
    description: "",
    contact: "",
    country_code: "234"

}

export const errorMessages = {
    email: {
        required: "please enter E-mail ID",
        maxLength: "Maximum E-mail ID length is 250 characteres only",
        invalid: "Please enter valid E-mail ID"
    },
    password: {
        required: "please enter password",
        minMaxlength: "Minimum 8 and maximum 18 characters only",
        invalid: 'Password must be at least 8 characters, contain at least one digit, one lowercase letter, one uppercase letter, one special character.'
    }
}

export const ENQUIRY_FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    contact: yup.string().length(10, "Contact Number should be 10 digits").required(),
    email: yup.string().email(errorMessages.email.invalid).max(250, errorMessages.email.maxLength).required(errorMessages.email.required),
    description: yup.string().min(10, "At least 10 characters ").required(),
    // is_remember_me: yup.boolean().optional()
})


export const LoginFormFields = {
    name: {
        key: "name",
        label: "Full Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Full Name",
        regex: /[^A-Za-z ]+/g,
        error_className:"text-white"
    },
    contact: {
        key: "contact",
        label: "Contact Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Contact Number",
        maxLength: 10,
        regex: /[^0-9]+/g,
        
    },
    country_code: {
        key: "country_code",
        label: "Country Code",
        field_type: "text",
        field_mode: "text",
        placeholder: "Country Code",
        maxLength: 10,
        regex: /[^0-9]+/g,
    },
    email: {
        key: "email",
        label: "Email address",
        field_type: "email",
        field_mode: "email",
        placeholder: "Email",
        error_className:"text-white"
    },
    description: {
        key: "description",
        label: "description",
        field_type: "textarea",
        field_mode: "textarea",
        placeholder: "Description",
        maxLength: 250,
        showMaxLength: true,
        error_className:"text-white"
    },
}