import { Loader2, ShieldClose } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { resendVerifyEmailByTokenAsync, resetState, verifyEmailByTokenAsync } from 'src/Silcers/emailVerifySlicer'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'src/components/ui/dialog'
import { RootState } from 'src/store'
import { removeAuth } from '../Auth/Core'
import { checkEmailVerificationTokenStatusApi } from 'src/services/services'
import SuccessDialog from 'src/components/SuccessDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import axios, { AxiosError } from 'axios'


function VerifyEmail() {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { token } = useParams()
    const { status, isLoading, isError, messsage, resend } = useSelector((state: RootState) => state.emailVerifyByToken)
    const [isLinkExpired, setisLinkExpired] = useState(false)
    const [isEmailAlreadyVerfied, setisEmailAlreadyVerfied] = useState(false)

    useEffect(() => {
        handleCheckIsLinkExpired(token)
        removeAuth();
        return () => { }
    }, [])

    useEffect(() => {
        if (status == 200) {
            if (resend) {
                setopenDilaod3(true)
            }
            else {
                setopenDilaod1(true)
            }
        }
        else if (status == 404) {
            setopenDilaod2(true)
        }
        return () => {
           // dispatch(resetState())
        }
    }, [status, resend])


    const handleCheckIsLinkExpired = async (token: string | undefined) => {
        try {
            const apiResp = token && await checkEmailVerificationTokenStatusApi(token);
            setisLinkExpired(apiResp?.is_email_expired)
            setisEmailAlreadyVerfied(apiResp?.is_emial_verified)
        } catch (error) {
            
        }
    }

    const [openDilaod1, setopenDilaod1] = useState<boolean>(false)
    const [openDilaod2, setopenDilaod2] = useState<boolean>(false)
    const [openDilaod3, setopenDilaod3] = useState<boolean>(false)
    const [openDilaod4, setopenDilaod4] = useState<boolean>(false)

    return (
        <div className=' p-10'>
            <>
                {
                    !isEmailAlreadyVerfied && !isLinkExpired ?
                        <>
                            <div className="flex items-center justify-center h-[60vh]">
                                <div className=' w-3/4'>
                                    <h1 className="text-center text-3xl text-primary font-semibold mb-4 uppercase">Please Verify Your Email</h1>
                                    <div className="text-center bg-white shadow-lg rounded-lg p-10 text-black mb-4">
                                        <h6 className="text-lg font-medium mt-6">Click the button below to verify your email</h6>
                                        <Button
                                            disabled={isLoading}
                                            variant="default"
                                            className="focus:outline-none mt-9 px-16 uppercase"
                                            onClick={() => dispatch(verifyEmailByTokenAsync(token))}
                                        >
                                            {isLoading && isLoading && (
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            )}
                                            Verify Email
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <Dialog open={openDilaod1} onOpenChange={setopenDilaod1}>
                                <DialogContent hideClose>
                                    <DialogHeader>
                                        <DialogTitle></DialogTitle>
                                        <DialogDescription>
                                            <div className="flex flex-col items-center">
                                                <div className="flex space-x-2 items-center text-base ">
                                                    Account Verified, Please Login
                                                </div>
                                                <Button
                                                    variant="default"
                                                    className="focus:outline-none px-14 mt-7"
                                                    onClick={() => {
                                                        navigate('/auth')
                                                    }}
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                        </DialogDescription>
                                    </DialogHeader>
                                </DialogContent>
                            </Dialog>

                            <Dialog open={openDilaod2} onOpenChange={setopenDilaod2}>
                                <DialogContent hideClose>
                                    <DialogHeader>
                                        <DialogTitle></DialogTitle>
                                        <DialogDescription>
                                            <div className="flex flex-col items-center">
                                                <div className="flex space-x-2 items-center text-base text-center ">
                                                    Your Email Verification Failed , Please Click the below button to Verify Your Email Again
                                                </div>
                                                <Button
                                                    variant="default"
                                                    className="focus:outline-none px-14 mt-7"
                                                    onClick={() => dispatch(resendVerifyEmailByTokenAsync(token))}
                                                >
                                                    {isLoading && isLoading && (
                                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                    )}
                                                    Resend Email
                                                </Button>
                                            </div>
                                        </DialogDescription>
                                    </DialogHeader>
                                </DialogContent>
                            </Dialog>

                            <Dialog open={openDilaod3} onOpenChange={setopenDilaod3}>
                                <DialogContent hideClose>
                                    <DialogHeader>
                                        <DialogTitle></DialogTitle>
                                        <DialogDescription>
                                            <div className="flex flex-col items-center">
                                                <div className="flex space-x-2 items-center text-base text-center ">
                                                    Your verification email has been sent. <br />
                                                    Please check your email inbox.
                                                </div>
                                                <Button
                                                    variant="default"
                                                    className="focus:outline-none px-14 mt-7"
                                                    onClick={() => {
                                                        // navigate('/auth')
                                                    }}
                                                >
                                                    OK
                                                </Button>
                                            </div>
                                        </DialogDescription>
                                    </DialogHeader>
                                </DialogContent>
                            </Dialog>
                            <ErrorDialog isOpen={isError} message={messsage} onCancelClick={()=> navigate('/')} />
                        </>
                        : <></>
                }

            </>

            <SuccessDialog isOpen={isEmailAlreadyVerfied} onClickOkBtn={() => navigate("/")} title='Email Verifird' >
                <>
                    <div>Your email already verified, please continue login </div>
                </>
            </SuccessDialog>

            <ErrorDialog isOpen={isLinkExpired} message='Link expired, please contact with support.' onCancelClick={() => navigate("/")} ></ErrorDialog>

        </div>
    )
}

export default VerifyEmail