import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { IRegistration, REGISTRATION_INIT } from 'src/Modules/Auth/Regsitration/Components/steps/modules'

// Define a type for the slice state
interface IregistrationSlicer {
    registrationDetails: IRegistration
}

// Define the initial state using that type
const initialState: IregistrationSlicer = {
    registrationDetails: REGISTRATION_INIT,
}

export const registrationSlicer = createSlice({
    name: 'registration',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        save: (state, action: PayloadAction<IRegistration>) => {
            // console.log({ payload: action.payload });
            state.registrationDetails = action.payload
        }
    },
})

export const { save } = registrationSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const registratio = (state: RootState) => state.global

export default registrationSlicer.reducer