import React from "react";
import StepCircle from "./StepCircle";
import { cn } from "src/lib/utils";
import { useRegistrationMainContext } from "../RegistrationMainContext";
import { registration_headers, registration_steps_heading } from "src/Helpers";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const StepTabs: React.FC<any> = ({}) => {
  // const { state: { activeTab, totalTabs } } = useRegistrationMainContext()
  const { activeTab, totalTabs } = useSelector(
    (state: RootState) => state.company_registration
  );

  return (
    <div className=" rounded-sm my-3 flex flex-col md:flex-row gap-2">
   

      {/* step -1 */}
      <div
        key={"registration-step-1"}
        className={cn(
          "flex-1 p-4 mx-1 hover:cursor-pointer rounded-lg border border-primary  ",
          "",
          { "bg-green-50": activeTab > 1 }
        )}
      >
        <div className="flex">
          <div>
            <StepCircle
              children={1}
              previous={activeTab > 4 || activeTab == totalTabs}
              current={activeTab == 1 || activeTab == 2 || activeTab == 3 || activeTab == 4}
            />
          </div>

          <div className="">
            <span className="text-sm font-medium">Step 1</span>
            <p className="text-sm text-muted-foreground">
              {registration_steps_heading[0]}
            </p>
          </div>
        </div>
      </div>

      {/* step - 2 */}
      <div
        key={"registration-step-2"}
        className={cn(
          "flex-1 p-4 mx-1 hover:cursor-pointer rounded-lg border border-primary",
          "",
          { "bg-green-50": activeTab >= 5 }
        )}
      >
        <div className="flex">
          <div>
            <StepCircle
              children={2}
              previous={activeTab > 5 || activeTab == totalTabs}
              current={activeTab == 5}
            />
          </div>

          <div className="">
            <span className="text-sm font-medium">Step 2</span>
            <p className="text-sm text-muted-foreground">
              {registration_steps_heading[1]}
            </p>
          </div>
        </div>
      </div>

     {/* step - 3 */}
     <div
        key={"registration-step-3"}
        className={cn(
          "flex-1 p-4 mx-1 hover:cursor-pointer rounded-lg border border-primary  ",
          "",
          { "bg-green-50": activeTab >= 6 }
        )}
      >
        <div className="flex">
          <div>
            <StepCircle
              children={3}
              previous={activeTab > 6 || activeTab == totalTabs}
              current={activeTab == 6}
            />
          </div>

          <div className="">
            <span className="text-sm font-medium">Step 3</span>
            <p className="text-sm text-muted-foreground">
              {registration_steps_heading[2]}
            </p>
          </div>
        </div>
      </div>

       {/* step - 4 */}
     <div
        key={"registration-step-4"}
        className={cn(
          "flex-1 p-4 mx-1 hover:cursor-pointer rounded-lg border border-primary  ",
          "",
          { "bg-green-50": activeTab >= 7}
        )}
      >
        <div className="flex">
          <div>
            <StepCircle
              children={4}
              previous={activeTab > 7 || activeTab == totalTabs}
              current={activeTab == 7}
            />
          </div>

          <div className="">
            <span className="text-sm font-medium">Step 4</span>
            <p className="text-sm text-muted-foreground">
              {registration_steps_heading[3]}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default StepTabs;
