import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { createNewEmployee, getAllCities, getAllCountries, getAllEmployees, getAllStates, getEmployeeDetails } from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'
import { optionsType } from '../Helpers'

// Define a type for the slice state
interface ICountryStateCityReducer {
    countryList: optionsType[] | []
    country: string | undefined,
    city: string | undefined,
    state: string | undefined
    stateList: optionsType[] | []
    cityList: optionsType[] | []
}

// Define the initial state using that type
const initialState: ICountryStateCityReducer = {
    countryList: [],
    country: undefined,
    city: undefined,
    state: undefined,
    stateList: [],
    cityList: []
}

export const fetchCountriesAsync: any = createAsyncThunk(
    'countryStateCityReducer/fetchCountries',
    async (_, thunkApi: any) => {
        try {
            const response: any = await getAllCountries();
            // // // console.log('countries', response.data);
            if (response) {
                thunkApi.dispatch(setCountryList(response && response?.map((o: any) => ({
                    value: o,
                    label: o,
                    icons: undefined,
                    disabled: false,
                }))));
            }
        } catch (error) {
            
        }
    }
);

export const fetchStatesAsync: any = createAsyncThunk(
    'countryStateCityReducer/fetchStates',
    async (countryName: string, thunkApi: any) => {
        try {
            const response: any = await getAllStates(countryName);
            // // console.log('states', response.data);
            if (response) {
                thunkApi.dispatch(setStatesList(response && response?.map((o: any) => ({
                    value: o,
                    label: o,
                    icons: undefined,
                    disabled: false,
                }))));
            }
        } catch (error) {
            // if (axios.isAxiosError(error)) {
            //     thunkApi.dispatch(setError(error.response?.data.message));
            //     return thunkApi.rejectWithValue(error.response?.data.message);
            // }

            // thunkApi.dispatch(setError(error));
            // return thunkApi.rejectWithValue(error);
        }
    }
);


export const fetchCitiesAsync: any = createAsyncThunk(
    'countryStateCityReducer/fetchCitiess',
    async (stateName: string, thunkApi: any) => {
        try {
            const response: any = await getAllCities(stateName);
            // console.log('cities', response.data);
            if (response) {
                thunkApi.dispatch(setCitiesList(response && response?.map((o: any) => ({
                    value: o,
                    label: o,
                    icons: undefined,
                    disabled: false,
                }))));
            }
        } catch (error) {
            // if (axios.isAxiosError(error)) {
            //     thunkApi.dispatch(setError(error.response?.data.message));
            //     return thunkApi.rejectWithValue(error.response?.data.message);
            // }

            // thunkApi.dispatch(setError(error));
            // return thunkApi.rejectWithValue(error);
        }
    }
);


export const CountryStateCityPIckerSlicer = createSlice({
    name: 'countryStateCityPicker',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCountryList: (state, action: PayloadAction<any>) => {
            state.countryList = action.payload
        },
        setStatesList: (state, action: PayloadAction<any>) => {
            state.stateList = action.payload
        },
        setCitiesList: (state, action: PayloadAction<any>) => {
            state.cityList = action.payload
        },
        setCountry: (state, action: PayloadAction<any>) => {
            state.country = action.payload
        },
        setCity: (state, action: PayloadAction<any>) => {
            state.city = action.payload
        },
        setState: (state, action: PayloadAction<any>) => {
            state.state = action.payload
        },
        resetCountryStateCity: (state) => {
            state.state = undefined
            state.country = undefined
            state.city = undefined
        },

    },
})

export const {
    setCountry,
    setCity,
    setState,
    resetCountryStateCity,
    setCountryList,
    setStatesList,
    setCitiesList
} = CountryStateCityPIckerSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default CountryStateCityPIckerSlicer.reducer