import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
    createNewEmployee,
    getAllEmployees,
    getCompleteEmployeeDetails,
    getInCompleteEmployeeDetails,
    getEmployeeDetails,
    getPaidEmployeeDetails,
    updateEmployeeProfile,
    getAmountOfSelectedEmployees
} from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'


const defaultValues = CREATE_EMPLOYEE_INIT

// Define a type for the slice state
interface IemployeeReducer {
    selectedEmployee: any[],
    allEmployee: any[],
    // allCompleteEmployee: any[],
    // allInCompleteEmployee: any[],
    // allPaidEmployee: any[],
    empForm: Icreate_employee,
    toBeDeletedEmp: any,
    filter: any | undefined,
    error: boolean,
    errorMessage: string | undefined
    loadingEmployeeList: boolean
    newEmployeeForm: any | undefined
    employeeViewDetail: any
    selectedEmployeesWithPayment: any[] | undefined
    updateEmployeeResponseStatus: any
}

// Define the initial state using that type
const initialState: IemployeeReducer = {
    selectedEmployee: [],
    toBeDeletedEmp: null,
    empForm: CREATE_EMPLOYEE_INIT,
    allEmployee: [],
    // allCompleteEmployee: [],
    // allInCompleteEmployee: [],
    // allPaidEmployee: [],
    filter: undefined,
    error: false,
    errorMessage: undefined,
    loadingEmployeeList: false,
    newEmployeeForm: defaultValues,
    employeeViewDetail: undefined,
    selectedEmployeesWithPayment: undefined,
    updateEmployeeResponseStatus: undefined
}

// Create an async thunk for making the API call
export const createEmployee: any = createAsyncThunk(
    'employees/createEmployee',
    async (employeeData: ICreateNewEmployeePostData, thunkApi) => {
        try {

            const { user_id, api_token }: any = getAuth();
            // Make the API call using Axios
            const postData = {
                ...employeeData,
                userid: user_id
            }
            // console.log({ user_id, api_token });

            const response: AxiosResponse = await createNewEmployee(postData);

            if (response.status == 201) {
                thunkApi.dispatch(setSelectedEmployee(response.data));
                return response.data;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const updateEmployeeAsync: any = createAsyncThunk(
    'employees/updateEmployee',
    async (employeeData: ICreateNewEmployeePostData, thunkApi) => {
        try {
            const { user_id, api_token }: any = getAuth();
            // Make the API call using Axios
            const postData = {
                ...employeeData,
                userid: user_id
            }
            // console.log({ user_id, api_token });

            const response: AxiosResponse = await updateEmployeeProfile(postData);
            // console.log({ response })
            return response.data;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchAllEmployeesAsync: any = createAsyncThunk(
    'employees/fetchAllEmployeesAsync',
    async (thunkApi: any) => {
        try {
            // Make the API call using Axios
            const response: AxiosResponse = await getAllEmployees();
            // console.log({ fetchAllEmployeesAsync: response });
            return response.data;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchIncompleteEmployeesAsync: any = createAsyncThunk(
    'employees/fetchIncompleteEmployeesAsync',
    async (thunkApi: any) => {
        try {
            // Make the API call using Axios
            const response: AxiosResponse = await getInCompleteEmployeeDetails();
            // console.log({ fetchIncompleteEmployeesAsync: response });
            return response.data;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchCompleteEmployeesAsync: any = createAsyncThunk(
    'employees/fetchCompleteEmployeesAsync',
    async (thunkApi: any) => {
        try {
            // Make the API call using Axios
            const response: AxiosResponse = await getCompleteEmployeeDetails();
            // console.log({ fetchCompleteEmployeesAsync: response });
            return response.data;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchPaidEmployeesAsync: any = createAsyncThunk(
    'employees/fetchPaidEmployeesAsync',
    async (thunkApi: any) => {
        try {
            // Make the API call using Axios
            const response: AxiosResponse = await getPaidEmployeeDetails();
            // console.log({ fetchPaidEmployeesAsync: response });
            return response.data;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchAmtOfSelectedEmployeesAsync: any = createAsyncThunk(
    'employees/fetchAmtOfSelectedEmployees',
    async (ids: string[], thunkApi: any) => {
        try {
            // Make the API call using Axios
            const response: any = await getAmountOfSelectedEmployees(ids);
            // console.log({ getAmountOfSelectedEmployees: response });
            if (response.status == 200) {
                thunkApi.dispatch(setSelectedEmployeesWithPayment(response.data));
                return response.data;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const employeesSlicer = createSlice({
    name: 'employees',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSelectedEmployee: (state, action: PayloadAction<any[]>) => {
            state.selectedEmployee = action.payload
        },
        settoBeDeleteEmp: (state, action: PayloadAction<any>) => {
            // console.log({ payload: action.payload });
            state.toBeDeletedEmp = action.payload
        },
        reSetSelectedEmployee: (state, action: PayloadAction<any>) => {
            state.selectedEmployee = []
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.errorMessage = action.payload.message
        },
        setCreateNewEmployee: (state, action: PayloadAction<any>) => {
            state.newEmployeeForm = action.payload;
        },
        setDependents: (state, action: PayloadAction<any>) => {
            state.newEmployeeForm = { ...state.newEmployeeForm, dependants: action.payload };
        },
        setSelectedEmployeesWithPayment: (state, action: PayloadAction<any>) => {
            state.selectedEmployeesWithPayment = action.payload;
        },
        resetESelectedEmmployee: (state) => {
            state.selectedEmployeesWithPayment = undefined;
            state.selectedEmployee = [];
        }

    }, extraReducers: (builder) => {
        builder
            .addCase(createEmployee.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(createEmployee.fulfilled, (state, action: PayloadAction<any>) => {
                state.allEmployee = [...state.allEmployee, action.payload]
                state.loadingEmployeeList = false;
            })
            .addCase(createEmployee.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            })
            .addCase(fetchAllEmployeesAsync.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchAllEmployeesAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.allEmployee = action.payload;
                state.loadingEmployeeList = false;
                state.selectedEmployeesWithPayment = undefined;
                state.selectedEmployee = [];
            })
            .addCase(fetchAllEmployeesAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            })
            // fetchIncompleteEmployeesAsync
            .addCase(fetchIncompleteEmployeesAsync.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchIncompleteEmployeesAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.allEmployee = action.payload;
                state.loadingEmployeeList = false;
                state.selectedEmployeesWithPayment = undefined;
                state.selectedEmployee = [];
            })
            .addCase(fetchIncompleteEmployeesAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            })
            // fetchCompleteEmployeesAsync
            .addCase(fetchCompleteEmployeesAsync.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchCompleteEmployeesAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.allEmployee = action.payload;
                state.loadingEmployeeList = false;
                state.selectedEmployeesWithPayment = undefined;
                state.selectedEmployee = [];
            })
            .addCase(fetchCompleteEmployeesAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            })
            // fetchPaidEmployeesAsync
            .addCase(fetchPaidEmployeesAsync.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchPaidEmployeesAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.allEmployee = action.payload;
                state.loadingEmployeeList = false;
                state.selectedEmployeesWithPayment = undefined;
                state.selectedEmployee = [];
            })
            .addCase(fetchPaidEmployeesAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            })
            .addCase(fetchAmtOfSelectedEmployeesAsync.pending, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(fetchAmtOfSelectedEmployeesAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.selectedEmployeesWithPayment = action.payload
            })
            .addCase(fetchAmtOfSelectedEmployeesAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loadingEmployeeList = false;
                state.error = true;
                state.errorMessage = action.payload?.message
            });
    }
})

export const {
    setSelectedEmployee,
    reSetSelectedEmployee,
    settoBeDeleteEmp,
    setError,
    setCreateNewEmployee,
    setDependents,
    setSelectedEmployeesWithPayment,
    resetESelectedEmmployee
} = employeesSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default employeesSlicer.reducer