import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../Helpers'
import React from 'react'
import { getPaymentDetail } from 'src/services/services';
import { useEffect, useState } from 'react'
function PaymentCard() {


    const { id } = useParams();
    const [payData, setpayData] = useState<null | any>(null);

    useEffect(() => {
        if (id) {
            let data = getPaymentDetail(id);
            data.then((res: any) => {
                if (res.status === 200) {
                    setpayData(res.details[0])
                }
            });
        }
    }, [id])

    function ParseDate(data:Date){
        const inputDate = new Date(data);

        // Define month names
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr",
            "May", "Jun", "Jul", "Aug",
            "Sep", "Oct", "Nov", "Dec"
        ];

        // Get the year, month, and time components
        const year = inputDate.getFullYear();
        const month = monthNames[inputDate.getMonth()];
        const day = inputDate.getDate();

        // Get hours and minutes in 12-hour format
        const hours = (inputDate.getHours() % 12).toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        // Determine if it's AM or PM
        const ampm = inputDate.getHours() < 12 ? 'AM' : 'PM';

        // Create the formatted date and time string
        const formattedDateTime = `${month} ${day} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }



    return (
        <div className="p-4 flex flex-col justify-center items-center">
            {payData && <div className=" w-3/4">
                <div className="mt-9 p-5 shadow-md rounded-md">
                    <div className=' flex justify-between'>
                        <div>
                            <img src={toAbsoluteUrl('/media/logo/eel_logo.png')} className='w-[80px]' />
                        </div>
                        <div>
                            <p className=' sub-styles uppercase'>Report for ELL card</p>
                        </div>
                    </div>
                    <div className=' mb-1 mt-10 text-center font-medium text-base'>Total Payable Amount of Levy <span className=" text-lg text-primary">₦ {payData.amount?.toFixed(2)}</span></div>
                    <hr className='border-t border-dotted border-primary border-[2px] ml-[4px]' />
                    <div className="flex lg:flex-row sm:flex-col rounded-lg justify-around mt-3">
                        <div className="flex flex-col justify-center align-middle p-4">
                            <p className='text-center sub-styles'>Date & Time</p>
                            <div className="flex justify-start text-center">
                                <p>{payData.date}</p>
                            </div>
                        </div>
                        <div className=" w-[1px] h-[60px] bg-primary mx-6">
                        </div>
                        <div className="flex flex-col justify-center align-middle p-4">
                            <div className="text-center">
                                <p className='text-center sub-styles'>Transaction ID</p>
                                <p className='uppercase'># {id}</p>
                            </div>
                        </div>
                        <div className=" w-[1px] h-[60px] bg-primary mx-6">
                        </div>
                        <div className=" flex flex-col justify-center p-4">
                            <div className="text-center">
                                <p className='sub-styles'>Transaction Status</p>
                                <p className='uppercase'>{payData.status}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:flex-row sm:flex-col rounded-lg justify-around mt-3">
                        <div className="flex flex-col justify-center align-middle p-4">
                            <p className='text-center sub-styles'>Bank</p>
                            <div className="flex justify-start text-center">
                                <>#</>
                                <p>+234 xxx xxx xxxx</p>
                            </div>
                        </div>
                        <div className=" w-[1px] h-[60px] bg-primary mx-6">
                        </div>
                        <div className="flex flex-col justify-center align-middle p-4">
                            <p className='text-center sub-styles'>Payment mode</p>
                            <div className="flex justify-start">
                                <>#</>
                                <p>+234 xxx xxx xxxx</p>
                            </div>
                        </div>
                        <div className=" w-[1px] h-[60px] bg-primary mx-6">
                        </div>
                        <div className=" flex flex-col justify-center align-middle p-4">
                            <p className='text-center sub-styles'>Nummber of Users</p>
                            <div className="flex justify-start">
                                <>#</>
                                <p>+234 xxx xxx xxxx</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default PaymentCard