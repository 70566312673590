import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/Modules/Auth/Core';
import { IcreatePaymentPostData, createEmployeePaymentAsync } from 'src/Silcers/paymentSlicer';
import ErrorDialog from 'src/components/ErrorDialog';
import SuccessDialog from 'src/components/SuccessDialog';
import WarningDialog from 'src/components/WarningDialog';
import { Button } from 'src/components/ui/button';
import { checkPaymentStatusApi, generateRRR, getPaymentStatus } from 'src/services/services';
import { RootState } from 'src/store';
import { Task } from './EmployeeTable/data/schema';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { Label } from 'src/components/ui/Label';

interface RemitaProps { }

declare global {
    interface Window {
        makePayment: any
    }
}

type Props = {
    amt: string,
    selectedEmployeesWithPayment: any, user: any, totalDollers: any
}

const RemitaIntegration = ({ amt, selectedEmployeesWithPayment, user, totalDollers }: Props) => {

    // console.log({ amt });

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {
        employees: { selectedEmployee, selectedEmployeesWithPayment: selectedEmployeesForPayment },
    }: any = useSelector((state: RootState) => state);
    const [requestState, setrequestState] = useState<{
        isLoading: boolean,
        isError: boolean,
        message: string,
        paymentStatus: string,
        data: any
        status: number | undefined
    }>({
        isLoading: false,
        isError: false,
        message: "",
        paymentStatus: "",
        data: {},
        status: undefined
    })
    const [paymentChannel, setpaymentChannel] = useState("BRANCH")

    const postData: IcreatePaymentPostData = useMemo(() => {
        return {
            registration_Object_id: user._id,
            employees:
                selectedEmployeesWithPayment &&
                selectedEmployeesWithPayment?.map((e: Task) => ({
                    _id: e.employee_id,
                    amount: e.amount_niara,
                    email: e.empemail,
                    name: e.surname + " " + e.givenname,
                })),
            total_amount: totalDollers,
            employeecount: String(selectedEmployeesWithPayment?.length),
        };
    }, [selectedEmployeesWithPayment, user, totalDollers]);

    const employeeIdsForPayment: any = useMemo(() => selectedEmployeesForPayment && Array.isArray(selectedEmployeesForPayment) && selectedEmployeesForPayment.length > 0 && selectedEmployeesForPayment.map((d: any) => d.employee_id), [selectedEmployeesForPayment])

    const employeeamount_id: any = useMemo(() => selectedEmployeesWithPayment && Array.isArray(selectedEmployeesWithPayment) && selectedEmployeesWithPayment.length > 0 && selectedEmployeesWithPayment[0].employeeamount_id, [selectedEmployeesWithPayment])

    const postEmployeeList: any = useMemo(() => selectedEmployeesForPayment && Array.isArray(selectedEmployeesForPayment) && selectedEmployeesForPayment.length > 0 && selectedEmployeesForPayment.map((d: any) => {
        return {
            "employee_id": d.employee_id,
            "amount": d.amount_niara,
            "email": d.empemail,
            "name": d.givenname + " " + d.surname,
        }
    }), [selectedEmployeesForPayment])

    const userDetails = useSelector((state: RootState) => state?.login?.user)

    const callExternalFunction = async () => {

        try {
            setrequestState({ ...requestState, isLoading: true })

            // genrate RRR Number froom api
            let getRRRNumber = process.env.REACT_APP_PAYMENT_SERVICE_TYPE_ID && await generateRRR({
                employeeamount_id: employeeamount_id,
                // serviceTypeId: process.env.REACT_APP_PAYMENT_SERVICE_TYPE_ID,
                // totalAmount: amt,
                // payerEmail: userDetails?.businessEmail,
                // payerName: userDetails?.comapnyName,
                // payerPhone: "+" + userDetails.countrycode + "" + userDetails.businessPhoneNumber,
                // description: "Payment for Septmeber Fees",
                // expiryDate: format(new Date, "dd/MM/yyyy"),
                paymentmode: paymentChannel,
                bankname: "Nirvana",
                employees: postEmployeeList,
                "employeecount": postEmployeeList.length
            })

            const rrr = getRRRNumber?.RRR;
            const mapamount = getRRRNumber?.mapamount;

            if (rrr) {
                const transactionID = new Date().getTime();
                if (window?.makePayment && typeof window.makePayment === 'function') {
                    let resposne = await window.makePayment(rrr, transactionID, process.env.REACT_APP_PAYMENT_REMITA_KEY, paymentChannel); // Call the function with the desired argument
                    console.log({ resposne });
                    if (resposne && resposne?.status) {
                        // let checkStatus = await getPaymentStatus(rrr);
                        let checkStatus = employeeIdsForPayment && await checkPaymentStatusApi({
                            status: "sucess",
                            _ids: employeeIdsForPayment,
                            rrr: rrr
                        });

                        console.log({ checkStatus });

                        setrequestState({ ...requestState, isLoading: false, isError: false, message: checkStatus?.data?.message, paymentStatus: checkStatus?.data?.transaction_status, status: checkStatus?.status })
                        // if (checkStatus?.status == 200) {
                        //     dispatch(
                        //         createEmployeePaymentAsync({
                        //             ...postData,
                        //             paymentmode: "online",
                        //             bankname: "Nirvana",
                        //             rrr: rrr
                        //         })
                        //     );
                        // }

                    }
                } else {
                    console.error('Function not found or not a function');
                    setrequestState({ ...requestState, isLoading: false, isError: true, message: "Unable To initiate remita" })
                }
            }
            else {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: "Unable To get RRR Number" })
            }

            // console.log({ getRRRNumber })

        }
        catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error.response?.data?.message })
                // console.log(error.response?.data?.message)
            }
            else {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error?.message })
                // console.log(error?.message)
            }
        }
    };

    return (
        <>
            <Label className='required'>Select Payment Type</Label>
            <Tabs defaultValue="account" className="w-full" value={paymentChannel} onValueChange={(e: any) => setpaymentChannel(e)} >
                <TabsList className='w-full'>
                    <TabsTrigger className='flex-1 bg-red' value="BRANCH">Pay by Remita</TabsTrigger>
                    {/* <TabsTrigger className='flex-1' value="BANK">Online</TabsTrigger> */}
                </TabsList>
            </Tabs>

            <Button disabled={requestState.isLoading} className='bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 rounded' onClick={callExternalFunction}>
                {requestState.isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )} Make Payment
            </Button>
            <Button
                disabled={requestState.isLoading}
                onClick={() => navigate(-1)}
                variant="outline"
                className=" px-12 border-primary"
            >
                Edit
            </Button>
            <ErrorDialog isOpen={requestState.isError} message={requestState?.message} onCancelClick={() => {
                setrequestState({ ...requestState, isLoading: false, isError: false, message: "" })
            }} />
            {/* <ErrorDialog isOpen={requestState.isError && requestState?.status !== 200} message={requestState?.message} onCancelClick={() => {
                setrequestState({ ...requestState, isLoading: false, isError: false, message: "" })
            }} /> */}
            <SuccessDialog isOpen={requestState?.status == 200} title={'Payment ' + requestState?.paymentStatus} onClickOkBtn={() => navigate("/employee-payment")}  >
                <p className='text-center'>{requestState?.message}</p>
            </SuccessDialog >
            {/* <WarningDialog isOpen={requestState.paymentStatus == "pending"} message='Your payment status is pending.' onCancelClick={() => setrequestState({ ...requestState, paymentStatus: "" })} ></WarningDialog> */}
        </>
    )
}

export default RemitaIntegration