import React, { useEffect, useState } from "react";
import NavBar from "src/components/Common/NavBar";
import { Input } from "src/components/ui/Input";
import { Label } from "src/components/ui/Label";
import { zodResolver } from "@hookform/resolvers/zod";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "src/components/ui/button";
import { SECURITY_QUATIONS_OPTIONS, checkIfAnyFilesToBeUpload } from "src/Helpers";
import { Link, useNavigate } from "react-router-dom";
import StepCircle from "../Auth/Regsitration/Components/StepCircle";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Dialog, DialogContent, DialogHeader } from "src/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { cn } from "src/lib/utils";
import { useFormik } from "formik";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import LightBtn from "src/components/Common/LightBtn";
import { Loader2 } from "lucide-react";
import {
  forgotPasswordRequest,
  uploadfile,
  uploadfileByEmail,
} from "src/services/services";
import { resetState } from "src/Silcers/forgotPasswordSlicer";
import { useDispatch } from "react-redux";
import {
  IUplodDocument,
  uploadDocumentsSchema_yup,
  upload_documents_form_fileds,
} from "./modules";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import axios, { AxiosError } from "axios";
import ErrorDialog from "src/components/ErrorDialog";

function UploadDocuments() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadingFiles, setuploadingFiles] = useState(false);
  const [completed, setcompleted] = useState(false);
  const [isError, setisError] = useState(false);
  const [message, setmessage] = useState(undefined);

  const { bussienssEmail } = useSelector(
    (state: RootState) => state.forgotPassword
  );

  useEffect(() => {
    if (!bussienssEmail) {
      navigate("/forgot-validate");
    }
    return () => { };
  }, [bussienssEmail]);

  const formik = useFormik<IUplodDocument>({
    initialValues: {
      cac: undefined,
      tcc: undefined,
      expatriate_quota_position: undefined,
    },
    validationSchema: uploadDocumentsSchema_yup,
    onSubmit: async (values: IUplodDocument) => {
      try {
        // console.log("values", values);
        setuploadingFiles(true);
        setisError(false);
        setmessage(undefined);

        // const newData = await checkIfAnyFilesToBeUpload(values)

        const newcac = await uploadfileByEmail([values.cac], bussienssEmail)
        // console.log({ cacfiles, tccfiles, expatriate_quota_positionfiles });

        // if (tccfiles && tccfiles instanceof File) {
        //   const tccfilesUrl = await uploadfileByEmail(
        //     [tccfiles],
        //     bussienssEmail
        //   );
        //   newData = { ...newData, tcc: tccfiles, tccfiles: tccfilesUrl.data };
        // }

        // if (
        //   expatriate_quota_positionfiles &&
        //   expatriate_quota_positionfiles instanceof File
        // ) {
        //   const expatriate_quota_positionfilesUrl = await uploadfileByEmail(
        //     [expatriate_quota_positionfiles],
        //     bussienssEmail
        //   );
        //   newData = {
        //     ...newData,
        //     expatriate_quota_position: expatriate_quota_positionfiles,
        //     expatriate_quota_positionfiles:
        //       expatriate_quota_positionfilesUrl.data,
        //   };
        // }

        // if (additional_docfiles && additional_docfiles instanceof File) {
        //   const additional_docfilesUrl = await uploadfileByEmail(
        //     [additional_docfiles],
        //     bussienssEmail
        //   );
        //   newData = {
        //     ...newData,
        //     additional_doc: additional_docfiles,
        //     additional_docfiles: additional_docfilesUrl.data,
        //   };
        // }

        const postData = {
          email: bussienssEmail,
          cac_doc: newcac?.filename,
          // tcc_doc: newData.tccfiles,
          // expatriate_quota_position: newData.expatriate_quota_positionfiles,
          // additional_docname: values.additional_doc_name,
          // additional_doc: newData.additional_docfiles,
        };

        // console.log({ postData });


        // dispatch(createCompanyProfileAsync(newData));
        const apiResp = await forgotPasswordRequest(postData);

        // console.log({ apiResp });

        if (apiResp.status == 201) {
          setcompleted(true);
        } else {
          setisError(true);
          setmessage(apiResp?.message);
        }
        setuploadingFiles(false);
      } catch (e: any) {
        const error: any = e as Error | AxiosError;
        if (axios.isAxiosError(error)) {
          setisError(true);
          setmessage(error.response?.data.message);
          setuploadingFiles(false);
        }
        setisError(true);
        setmessage(error?.message);
        setuploadingFiles(false);
      }

      //   await onSubmitActions(values);
    },
  });

  useEffect(() => {
    if (formik.errors.additional_doc) {
      formik.setFieldTouched("additional_doc");
    }
    return () => { };
  }, [formik.errors.additional_doc]);

  // console.log({ errors: formik.errors, touched: formik.touched });

  return (
    <div className="">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center items-center h-[60vh]">
          <div>
            <PageHeadingComponent
              mainContent="Upload Document"
              mainContentClassName="text-center"
              mainTextClassName="capitalize"
              subContent="Please upload your documents"
              subContentClassName="mx-auto"
            />
          </div>
          <div className="mt-4 w-2/4 ">
            <div className="grid grid-cols-1 gap-2">
              <div>
                {/* CAC TCC EXPIRATE */}

                <RenderFormikFiled
                  formik={formik}
                  formObject={upload_documents_form_fileds.cac}
                  value={formik.values.cac}
                  required
                  onchange={(e: any) => formik.setFieldValue("cac", e)}
                />
              </div>
              {/* <div>
               
                <RenderFormikFiled
                  formik={formik}
                  formObject={upload_documents_form_fileds.tcc}
                  value={formik.values.tcc}
                  required
                  onchange={(e: any) => formik.setFieldValue("tcc", e)}
                />
              </div>
              <div>
                
                <RenderFormikFiled
                  formik={formik}
                  value={formik.values.expatriate_quota_position}
                  formObject={
                    upload_documents_form_fileds.expatriate_quota_position
                  }
                  required
                  onchange={(e: any) =>
                    formik.setFieldValue("expatriate_quota_position", e)
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-x-2">
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    value={formik.values.additional_doc_name}
                    formObject={
                      upload_documents_form_fileds.additional_doc_name
                    }
                    required={false}
                    onchange={(e: any) => {
                      formik.setFieldValue("additional_doc_name", e);
                    }}
                  />
                </div>
                <div>
                  <RenderFormikFiled
                    formik={formik}
                    value={formik.values.additional_doc}
                    formObject={upload_documents_form_fileds.additional_doc}
                    onchange={(e: any) =>
                      formik.setFieldValue("additional_doc", e)
                    }
                  />
                  <div>
                    {!formik.touched.additional_doc &&
                      formik.errors.additional_doc && (
                        <>
                          <p
                            className={cn(
                              "text-xs font-medium text-destructive"
                            )}
                          >
                            {formik.errors.additional_doc}
                          </p>
                        </>
                      )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full">
          <LightBtn
            onclick={(e: any) => {
              // e.preventDefault();
              // form.trigger();
              // saveDraft(form.getValues());
              dispatch(resetState());
              navigate("/auth");
            }}
            title="Cancel"
          />
          <Button
            disabled={uploadingFiles}
            onClick={() => formik.handleSubmit()}
            type="submit"
            className="mb-2 px-10 w-44 mx-5"
          >
            {uploadingFiles && uploadingFiles && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
        </div>
      </form>
      <Dialog
        open={completed}
        onOpenChange={(e: any) => {
          setcompleted(e);
        }}
      >
        <DialogContent className="min-w-[40%] overflow-auto">
          <DialogHeader className="ml-4">
            <PageHeadingComponent
              mainContent="PASSWORD RESET REQUEST SUBMITTED"
              mainContentClassName="text-center"
              subContentClassName="mx-auto"
            />
          </DialogHeader>
          <div className="flex flex-col justify-center items-center">
            <div>
              <StepCircle previous={true} />
            </div>
            <p className="sub-styles mt-4 text-center">
              Thank you for submitting the request. Our team will review the
              <br />
              document and notify you via email once the review process is
              complete.
              <br />
              We appreciate your patience and cooperation.
            </p>
          </div>
          <div className=" flex justify-center">
            <Button
              className=" my-6 px-24"
              onClick={() => {
                setcompleted(false);
                navigate("/");
              }}
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <ErrorDialog
        isOpen={isError}
        message={message}
        onCancelClick={() => {
          setisError(false);
          setmessage(undefined);
        }}
      ></ErrorDialog>
    </div>
  );
}

export default UploadDocuments;
