import { SVGC } from "src/Helpers/SVGC";
import { optionsType, toAbsoluteUrl } from "src/Helpers";
import React, { useState, useEffect } from "react";

type Props = {
  options: any;
  value: any;
  label: any;
  placeholder: any;
};

const RenderSelectedValue = ({ options, value, label, placeholder }: Props) => {
  const [selected, setSelected] = useState<optionsType | undefined>(undefined);

  // console.log({ value, selected,options });

  useEffect(() => {
    let selectedObject =
      options &&
      options.find((options: any) =>  options.value.replace(/[^\d]/g, "") == value );
    setSelected(selectedObject);
    return () => {};
  }, [value]);

  return (
    <>
      {selected && selected ? (
        <>
          <div className="flex w-full h-full">
            {selected.icons && selected.icons && (
              <span className="mx-2">
                <SVGC
                  path={toAbsoluteUrl("/" + selected.icons) || ""}
                  className="w-[20px] h-[20px]"
                />
              </span>
            )}
            <span>{selected.label}</span>
          </div>
        </>
      ) : (
        `Select `
      )}
    </>
  );
};

export default RenderSelectedValue;
