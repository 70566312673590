import { toAbsoluteUrl } from "src//Helpers";
import Forms from "./Components/Forms";
import RegistrationHeader from "./Components/RegistrationHeader";
import StepTabs from "./Components/StepTabs";
import { RegistrationMainContextProvider } from "./RegistrationMainContext";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetstate, setActiveTab } from "src/Silcers/companyRegistrationSlicer";

type Props = {};

const Main = (props: Props) => {
  const { error } = useSelector(
    (state: RootState) => state.company_registration
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetstate());
    return () => {};
  }, []);


  // useEffect(() => {
  //   dispatch(setActiveTab(6));
  //   return () => {};
  // }, []);

  return (
    <RegistrationMainContextProvider>
      <div
        className="p-8"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/images/eel_registration_bg.png"
          )})`,
          backgroundSize: "cover",
        }}
      >
        <div className="md:px-8">
          <RegistrationHeader />
          {/* Tabs Component */}
          <StepTabs />
          {/* Tabs Forms  */}
          {error && (
            <div className="font-bold text-destructive  bg-red-50 border border-destructive rounded p-2 mb-2">
              Error : {error}
            </div>
          )}
          <Forms />
        </div>
      </div>
    </RegistrationMainContextProvider>
  );
};

export default Main;
