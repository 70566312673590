import { Route, Routes, useNavigate } from "react-router-dom";
import AuthLayoutMain from "../Layout/AuthLayoutMain";
import Login from "./Login/Login";
import Main from "./Regsitration/Main";
import { useAuth } from "./Core";
import { useEffect } from "react";
import LandinPage from "./LandingPage";

const AuthPage = () => {
  // const { currentUser } = useAuth();
  // const navigate= useNavigate()

  // useEffect(() => {
  //   if(currentUser){
  //     navigate('/')
  //   }
  //   return () => {};
  // }, [currentUser]);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Main />} />
      <Route index element={<Login />} />
    </Routes>
  );
};

export { AuthPage };
