import React from "react";
import FooteremailIcon from "../IconComponents/FooterEmailOutlineIcon";
import CallIcon from "../IconComponents/CallOutlineIcon";
import LocationOutlineIcon from "../IconComponents/LocationOutlineIcon";
import InstagramIcon from "../IconComponents/InstagramIcon";
import YoutubeIcon from "../IconComponents/YoutubeIcon";
import FacebookIcon from "../IconComponents/FacebookIcon";
import LinkedinIcon from "../IconComponents/LinkedinIcon";
import TwitterIcon from "../IconComponents/TwitterIcon";
import { toAbsoluteUrl } from "../../Helpers";
import { Link } from "react-router-dom";

type Props = {};

const FooterComponent = (props: Props) => {
  return (
    <>
      <div className="w-full footer-backgroud-color">
        <div
          className={`w-100 flex flex-col md:flex-row justify-between gap-4 p-2`}
          style={{
            background: `url(${toAbsoluteUrl(
              "/media/images/flag/flag-full.png"
            )})`,
            backgroundSize: "300px 300px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className=" flex flex-col md:items-start">
            <div>
              <div className="flex items-center mb-2">
                <FooteremailIcon size={16} />
                <small className="text-sm font-normal leading-none mx-2">
                  <Link target="_blank" to={`mailto:${process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}`}>
                    {process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}
                  </Link>
                </small>
                {/* <span className='mx-2'>info@moi-eel.gov.ng</span> */}
              </div>
              <div className="flex items-center mb-2">
                <CallIcon size={16} />
                <small className="text-sm font-normal leading-none mx-2">
                  {process.env.REACT_APP_ENQUIRY_PHONE_NUMBER}
                </small>
                {/* <span className='mx-2'>  </span> */}
              </div>
            </div>
          </div>
          <div className=" flex flex-col">
            <div className="flex items-center mb-2">
              {/* <LocationOutlineIcon size={16} /> */}
              <div className="text-sm font-semibold leading-none mx-2 md:text-center">
                <div className="mb-2">Nigeria Immigration Service</div>
                <div>
                  Umar Musa Yar Adu'a Express Way, Airport Road, Sauka, Abuja,
                  FCT Nigeria
                </div>
              </div>
              {/* <span className='mx-2'>info@moi-eel.gov.ng</span> */}
            </div>
            <div className="flex items-center justify-center mb-2">
              {/* <InstagramIcon size={16} classname={'text-muted-foreground w-[16px] h-[16px]'} />
                            <YoutubeIcon size={16} classname={"mx-1 "} />
                            <FacebookIcon size={16} classname={"mx-1 "} />
                            <LinkedinIcon size={16} classname={"mx-1 "} />
                            <TwitterIcon size={16} classname={"mx-1 "} /> */}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex  items-center mb-2">
              <small className="text-sm font-normal leading-none mx-2">
                Copyright © 2023 <b>Ministry of Interior</b>
              </small>
              {/* <span className='mx-2'>info@moi-eel.gov.ng</span> */}
            </div>
            <div className="flex  items-center mb-2">
              <div>
                {/* <small className="text-sm hover:underline hover:text-primary cursor-pointer text-muted-foreground font-normal leading-none mx-2">
                  Terms Of Use
                </small> */}
                <Link target="_blank"
                  // to={process.env.REACT_APP_TERMS_OF_USE || ""}
                  to={process.env.REACT_APP_TERMS_OF_USE || ""}
                >
                  <small className="text-sm hover:underline hover:text-primary cursor-pointer text-muted-foreground font-normal leading-none mx-2">
                    Terms Of Use
                  </small>
                </Link>
                <Link target="_blank" to={process.env.REACT_APP_PRIVECY_POLICY || ""}>
                  <small className="text-sm hover:underline hover:text-primary cursor-pointer text-muted-foreground font-normal leading-none mx-2">
                    Privacy Statement
                  </small>
                </Link>
              </div>
              {/* <span className='mx-2'>  </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
