import React from "react";
import { cn } from "src/lib/utils";

type Props = {
  active: boolean;
  title: string;
  description: string;
};

const TimeLineSingleComponent = ({
  active = false,
  description,
  title,
}: Props) => {
  return (
    <div className="py-2 space-y-8 relative before:absolute before:inset-0 before:ml-[16px] before:-translate-x-px  md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
      <div className="relative flex  md:odd:flex-row-reverse group is-active">
        <div
          className={cn(
            "flex items-center justify-center w-8 h-8 rounded-full border border-white bg-slate-300  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1",
            { "group-[.is-active]:bg-emerald-500": active }
          )}
        >
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="10"
          >
            <path
              fill-rule="nonzero"
              d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
            ></path>
          </svg>
        </div>
        <div className="grow bg-white px-2">
          <div className="flex items-center justify-between space-x-2 mb-1">
            <div className="font-bold text-slate-900 text-sm">{title}</div>
            {/* <time className="font-caveat font-medium text-indigo-500 text-xs">
              08/06/2023
            </time> */}
          </div>
          <div className="text-slate-500 text-xs">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default TimeLineSingleComponent;
