import { DESIGNATION_OPTIONS, GENDER_OPTIONS, MARITAL_STATUS_OPTIONS, RELATIONS_OPTIONS, TYPE_OF_EMPLOYEE_OPTIONS, VISA_TYPES_OPTIONS, nationality_representative_OPTIONS } from "src/Helpers";
import { z } from "zod";
import * as yup from "yup";


export type IDependant = {
    surname: string | undefined;
    given_name: string | undefined;
    accompany_with: string | undefined;
    relationship: string | undefined;
    dob: string | undefined;
    sex: string | undefined;
    passport_no: string | undefined;
    passport_expiry: string | undefined;
    passport_doc: string | undefined | any;
    cerpac_no: string | undefined;
    cerpac_issue: string | undefined;
    cerpac_expiry: string | undefined;
    visa_type: string | undefined;
    visa_number: string | undefined;
    visa_expiry: string | undefined;
    visa_issue: string | undefined;
    dependent_profile_image: any;
};

export type Icreate_employee = {
    surname: string | undefined,
    employee_profile_photo: string | File | undefined,
    given_name: string | undefined,
    mereg_number: string | undefined,
    type_of_employee: string | undefined,
    designation: string | undefined,
    country_of_origin: string | undefined,
    nationality: string | undefined,
    dob: string | undefined,
    sex: string | undefined,
    marital_status: string | undefined,
    passport_no: string | undefined,
    passport_expiry: string | undefined,
    passport_doc: string | File | undefined,
    cerpac_no: string | undefined,
    cerpac_expiry: string | undefined,
    cerpac_front_doc: string | File | undefined
    cerpac_back_doc: string | File | undefined,
    visa_type: string | undefined,
    visa_number: string | undefined,
    visa_expiry: string | undefined,
    work_status: string | undefined,
    name_of_company: string | undefined,
    employee_phone_number: string | undefined,
    employee_phone_code: string | undefined,
    employee_email: string | undefined,
    employee_address_1: string | undefined,
    employee_address_2: string | undefined,
    country: string | undefined,
    city: string | undefined,
    state: string | undefined,
    zipcode: string | undefined,
    is_dependants: boolean,
    dependants: IDependant[] | undefined
}

export type IEmployeeDetails = {
    surname: string | undefined,
    employee_profile_photo: any,
    given_name: string | undefined,
    mereg_number: string | undefined,
    type_of_employee: string | undefined,
    designation: string | undefined,
    country_of_origin: string | undefined,
    nationality: string | undefined,
    dob: string | undefined,
    sex: string | undefined,
    marital_status: string | undefined,
    passport_no: string | undefined,
    passport_expiry: string | undefined,
    passport_doc: string,
    cerpac_no: string | undefined,
    cerpac_expiry: string | undefined,
    cerpac_front_doc: any
    cerpac_back_doc: any,
    visa_type: string | undefined,
    visa_number: string | undefined,
    visa_expiry: string | undefined,
    work_status: string | undefined,
    name_of_company: string | undefined,
    employee_phone_number: string | undefined,
    employee_phone_code: string | undefined,
    employee_email: string | undefined,
    employee_address_1: string | undefined,
    employee_address_2: string | undefined,
    country: string | undefined,
    city: string | undefined,
    state: string | undefined,
    zipcode: string | undefined,
    is_dependants: boolean,
    dependants: IDependant[] | undefined
}

const errorMessages = {
    surname: "Surname is required.",
    given_name: "Given Name is required.",
    accompany_with: "Accompany with is required.",
    relationship: "Relationship is required.",
    dob: "Date of Birth is required.",
    sex: "Sex is required.",
    passport_no: "Invalid Passport number",
    passport_expiry: "Passport Expiry is required.",
    passport_doc: "please upload passport",
    cerpac_no: "CERPAC Number is required.",
    cerpac_issue: "CERPAC Issue Date is required.",
    cerpac_expiry: "CERPAC Expiry Date is required.",
    visa_type: "Visa Type is required.",
    visa_number: "Visa Number is required.",
    visa_expiry: "Visa Expiry Date is required.",
    visa_issue: "Visa Issue Date is required.",
    dependent_profile_image: "Dependent Profile Image is required.",
    special_characters_error: "Special characters are not allowed"
};


function isValidDate(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

export const createDependantSchema = yup.object().shape({
    surname: yup.string().matches(/^([a-zA-Z _-]+)$/, { message: errorMessages.special_characters_error }).required(errorMessages.surname),
    given_name: yup.string().matches(/^([a-zA-Z _-]+)$/, { message: errorMessages.special_characters_error }).required(errorMessages.given_name),
    accompany_with: yup.string().required(errorMessages.accompany_with),
    relationship: yup.string().required(errorMessages.relationship),
    dob: yup.string().required(errorMessages.dob),
    sex: yup.string().required(errorMessages.sex),
    passport_no: yup.string().matches(/^[A-Z0-9]{6,20}$/, { message: "Invalid Passport number" }).required(errorMessages.passport_no),
    passport_expiry: yup.string().test('passport_expiry', (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && passport_no) {
            return createError({ message: errorMessages_for_create_employee.passport_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    passport_doc: yup.mixed().test("passport_doc", (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        // Check if the value is a File object

        if (!value && passport_no) {
            return createError({ message: "Please upload passport" });
        }

        if (!(value instanceof File)) {
            return createError({ message: "Please select valid file" });
        }

        return true;
    }).required(errorMessages.passport_doc),
    cerpac_no: yup.string().matches(/^[A-Z0-9]{6,20}$/, { message: "Invalid CERPAC number" }).required(errorMessages.cerpac_no),
    cerpac_issue: yup.string().matches(/^([0-9/-]+)$/, { message: "Invalid issue date" }).required(errorMessages.cerpac_issue).test('cerpac_issue', (value, validationcontext) => {
        const { createError, parent: { cerpac_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && cerpac_no) {
            return createError({ message: errorMessages.cerpac_expiry });
        }

        if (value && !isValidDate(value)) {
            return createError({ message: "Invalid Date" });
        }

        if (value && inputDate && inputDate >= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    cerpac_expiry: yup.string().test('cerpac_expiry', (value, validationcontext) => {
        const { createError, parent: { cerpac_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && cerpac_no) {
            return createError({ message: errorMessages.cerpac_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    visa_type: yup.string().required(errorMessages.visa_type),
    visa_number: yup.string().matches(/^4[0-9]{12}(?:[0-9]{3})?$/, { message: "Invalid visa number" }).required(errorMessages.visa_number),
    visa_expiry: yup.string().test('visa_expiry', (value, validationcontext) => {
        const { createError, parent: { visa_number } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && visa_number) {
            return createError({ message: errorMessages.visa_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    visa_issue: yup.string().required(errorMessages.visa_issue).test('visa_issue', (value, validationcontext) => {
        const { createError, parent: { visa_number } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && visa_number) {
            return createError({ message: errorMessages.visa_issue });
        }

        if (value && !isValidDate(value)) {
            return createError({ message: "Invalid Date" });
        }

        if (value && inputDate && inputDate >= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    dependent_profile_image: yup.mixed().required(errorMessages.dependent_profile_image),
});

export const INIT_Dependant = {
    surname: undefined,
    given_name: undefined,
    accompany_with: undefined,
    relationship: undefined,
    dob: undefined,
    sex: undefined,
    passport_no: undefined,
    passport_expiry: undefined,
    passport_doc: undefined,
    cerpac_no: undefined,
    cerpac_issue: undefined,
    cerpac_expiry: undefined,
    visa_type: undefined,
    visa_number: undefined,
    visa_expiry: undefined,
    visa_issue: undefined,
    dependent_profile_image: undefined,
};

export const INIT_Dependant_VALUES = {
    surname: "Doe",
    given_name: "John",
    accompany_with: "Jane Doe",
    relationship: undefined,
    dob: "1990-01-01", // Date of Birth in yyyy-mm-dd format
    sex: undefined,
    passport_no: "A1234567",
    passport_expiry: "2025-12-31", // Passport Expiry Date in yyyy-mm-dd format
    passport_doc: undefined, // Passport Document File Name
    cerpac_no: "C9876543",
    cerpac_issue: "2022-03-15", // CERPAC Issue Date in yyyy-mm-dd format
    cerpac_expiry: "2024-03-15", // CERPAC Expiry Date in yyyy-mm-dd format
    visa_type: undefined,
    visa_number: "4111111111111111",
    visa_expiry: "2023-06-30", // Visa Expiry Date in yyyy-mm-dd format
    visa_issue: "2023-06-15", // Visa Issue Date in yyyy-mm-dd format
    dependent_profile_image: "profile.jpg", // Profile Image File Name
};

export const createDependentFields = {

    surname: {
        key: "surname",
        label: "Surname",
        field_type: "text",
        field_mode: "text",
        placeholder: "Surname",
        regex: /[^A-Za-z ]+/g
    },
    given_name: {
        key: "given_name",
        label: "Given Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Given Name",
        regex: /[^A-Za-z ]+/g
    },
    accompany_with: {
        key: "accompany_with",
        label: "Accompany With",
        field_type: "text",
        field_mode: "text",
        placeholder: "Accompany With",
        regex: /[^A-Za-z ]+/g
    },
    relationship: {
        key: "relationship",
        label: "Relationship",
        field_type: "options",
        options: RELATIONS_OPTIONS,
        placeholder: "Relationship"
    },
    dob: {
        key: "dob",
        label: "Date of Birth",
        field_type: "date",
        field_mode: "text",
        placeholder: "Date of Birth"
    },
    sex: {
        key: "sex",
        label: "Sex",
        field_type: "options",
        options: GENDER_OPTIONS,
        placeholder: "Sex"
    },
    passport_no: {
        key: "passport_no",
        label: "Passport Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Passport Number",
        maxLength: 10,
        regex: /[^A-Za-z0-9]+/g
    },
    passport_expiry: {
        key: "passport_expiry",
        label: "Passport Expiry",
        field_type: "date",
        field_mode: "text",
        placeholder: "Passport Expiry"
    },
    passport_doc: {
        key: "passport_doc",
        label: "Passport Document",
        field_type: "file",
        field_mode: "text",
        placeholder: "Passport Document"
    },
    cerpac_no: {
        key: "cerpac_no",
        label: "CERPAC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "CERPAC Number",
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g
    },
    cerpac_issue: {
        key: "cerpac_issue",
        label: "CERPAC Issue",
        field_type: "date",
        field_mode: "text",
        placeholder: "CERPAC Issue"
    },
    cerpac_expiry: {
        key: "cerpac_expiry",
        label: "CERPAC Expiry",
        field_type: "date",
        field_mode: "text",
        placeholder: "CERPAC Expiry"
    },
    visa_type: {
        key: "visa_type",
        label: "Visa Type",
        field_type: "options",
        options: VISA_TYPES_OPTIONS,
        placeholder: "Visa Type"
    },
    visa_number: {
        key: "visa_number",
        label: "Visa Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Visa Number"
    },
    visa_expiry: {
        key: "visa_expiry",
        label: "Visa Expiry",
        field_type: "date",
        field_mode: "text",
        placeholder: "Visa Expiry"
    },
    visa_issue: {
        key: "visa_issue",
        label: "Visa Issue",
        field_type: "date",
        field_mode: "text",
        placeholder: "Visa Issue"
    }
}

export const createEmployeeFields = {
    "surname": {
        key: "surname",
        label: "Surname",
        field_type: "text",
        field_mode: "text",
        placeholder: "Surname",
        regex: /[^A-Za-z ]+/g
    },
    "given_name": {
        key: "given_name",
        label: "Given Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Given Name",
        regex: /[^A-Za-z ]+/g
    },
    "mereg_number": {
        key: "mereg_number",
        label: "M. e. Reg Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Mereg Number",
        caseSensetiveLabel: true
    },
    "type_of_employee": {
        key: "type_of_employee",
        label: "Type Of Employee",
        field_type: "options",
        options: TYPE_OF_EMPLOYEE_OPTIONS,
        placeholder: "Type Of Employee",
    },
    "designation": {
        key: "designation",
        label: "Designation",
        field_type: "options",
        options: DESIGNATION_OPTIONS,
        placeholder: "Designation",
    },
    "country_of_origin": {
        key: "country_of_origin",
        label: "Country Of Origin",
        field_type: "text",
        field_mode: "text",
        placeholder: "Country Of Origin",
    },
    "nationality": {
        key: "nationality",
        label: "Nationality",
        field_type: "options",
        options: nationality_representative_OPTIONS,
        placeholder: "Nationality",
    },
    "dob": {
        key: "dob",
        label: "Date of Birth",
        field_type: "date",
        placeholder: "Date of Birth",
    },
    "sex": {
        key: "sex",
        label: "Sex",
        field_type: "options",
        options: GENDER_OPTIONS,
        placeholder: "Sex",
    },
    "marital_status": {
        key: "marital_status",
        label: "Marital Status",
        field_type: "options",
        options: MARITAL_STATUS_OPTIONS,
        placeholder: "Marital Status",
    },
    "passport_no": {
        key: "passport_no",
        label: "Passport Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Passport Number",
        maxLength: 10,
        regex: /[^A-Za-z0-9]+/g
    },
    "passport_expiry": {
        key: "passport_expiry",
        label: "Passport Expiry",
        field_type: "date",
        placeholder: "Passport Expiry",
    },
    "passport_doc": {
        key: "passport_doc",
        label: "Passport Document",
        field_type: "file",
        placeholder: "Passport Document",
        
    },
    "cerpac_no": {
        key: "cerpac_no",
        label: "CERPAC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "CERPAC Number",
        maxLength: 8,
        regex: /[^A-Za-z0-9]+/g
    },
    "cerpac_expiry": {
        key: "cerpac_expiry",
        label: "CERPAC Expiry",
        field_type: "date",
        placeholder: "CERPAC Expiry",
    },
    "cerpac_front_doc": {
        key: "cerpac_front_doc",
        label: "CERPAC Front Document",
        field_type: "file",
        placeholder: "CERPAC Front Document",
    },
    "cerpac_back_doc": {
        key: "cerpac_back_doc",
        label: "CERPAC Back Document",
        field_type: "file",
        placeholder: "CERPAC Back Document",
    },
    "visa_type": {
        key: "visa_type",
        label: "Visa Type",
        field_type: "options",
        options: VISA_TYPES_OPTIONS,
        placeholder: "Visa Type",
    },
    "visa_number": {
        key: "visa_number",
        label: "Visa Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Visa Number",
    },
    "visa_expiry": {
        key: "visa_expiry",
        label: "Visa Expiry",
        field_type: "date",
        placeholder: "Visa Expiry",
    },
    "work_status": {
        key: "work_status",
        label: "Work Status",
        field_type: "text",
        field_mode: "text",
        placeholder: "Work Status",
    },
    "name_of_company": {
        key: "name_of_company",
        label: "Name Of Company",
        field_type: "text",
        field_mode: "text",
        placeholder: "Name Of Company",
        regex: /[^A-Za-z ]+/g
    },
    "employee_phone_number": {
        key: "employee_phone_number",
        label: "Employee Phone Number",
        field_type: "custome",
        field_mode: "text",
        placeholder: "Employee Phone Number",
    },
    "employee_phone_code": {
        key: "employee_phone_code",
        label: "Employee Phone Code",
        field_type: "text",
        field_mode: "text",
        placeholder: "Employee Phone Code",
    },
    "employee_email": {
        key: "employee_email",
        label: "Employee Email",
        field_type: "email",
        field_mode: "text",
        placeholder: "Employee Email",
    },
    "employee_address_1": {
        key: "employee_address_1",
        label: "Employee Address 1",
        field_type: "text",
        field_mode: "text",
        placeholder: "Employee Address 1",
        
    },
    "employee_address_2": {
        key: "employee_address_2",
        label: "Employee Address 2",
        field_type: "text",
        field_mode: "text",
        placeholder: "Employee Address 2",
    },
    "country": {
        key: "country",
        label: "Country",
        field_type: "options",
        options: [],
        placeholder: "Country",
    },
    "city": {
        key: "city",
        label: "City",
        field_type: "options",
        options: [],
        placeholder: "City",
    },
    "state": {
        key: "state",
        label: "State",
        field_type: "options",
        options: [],
        placeholder: "State",
    },
    "zipcode": {
        key: "zipcode",
        label: "Zip code",
        field_type: "text",
        field_mode: "text",
        placeholder: "Zip code",
        maxLength: 6,
        regex: /[^0-9]+/g
    },
};

export const CREATE_EMPLOYEE_INIT: Icreate_employee = {
    surname: undefined,
    given_name: undefined,
    employee_profile_photo: undefined,
    mereg_number: undefined,
    type_of_employee: undefined,
    designation: undefined,
    country_of_origin: undefined,
    nationality: undefined,
    dob: undefined,
    sex: undefined,
    marital_status: undefined,
    passport_no: undefined,
    passport_expiry: undefined,
    passport_doc: undefined,
    cerpac_no: undefined,
    cerpac_expiry: undefined,
    cerpac_front_doc: undefined,
    cerpac_back_doc: undefined,
    visa_type: undefined,
    visa_number: undefined,
    visa_expiry: undefined,
    work_status: undefined,
    name_of_company: undefined,
    employee_phone_number: undefined,
    employee_phone_code: "234",
    employee_email: undefined,
    employee_address_1: undefined,
    employee_address_2: undefined,
    country: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,
    is_dependants: false,
    dependants: undefined
}

export const EMPLOYEE_INIT = {
    surname: "Doe",
    given_name: "John",
    mereg_number: "12345",
    type_of_employee: "Director",
    designation: "Manager",
    country_of_origin: "United States",
    nationality: "American",
    dob: "1990-05-15",
    sex: "Male",
    marital_status: "Single",
    passport_no: "123456",
    passport_expiry: "2025-12-31",
    passport_doc: "",
    cerpac_no: "7890",
    cerpac_expiry: "2024-10-15",
    cerpac_front_doc: "",
    cerpac_back_doc: "",
    visa_type: "Work Visa",
    visa_number: "23456",
    visa_expiry: "2023-08-20",
    work_status: "Employed",
    name_of_company: "ABC Corporation",
    employee_phone_number: "1234567890",
    employee_phone_code: "+234",
    employee_email: "johndoe@example.com",
    employee_address_1: "123 Main St",
    employee_address_2: "Apt 4B",
    country: "Nigeria",
    city: "Lagos",
    state: "Lagos",
    zipcode: "100001",
    is_dependants:true
}   

export const Icreate_employee_schema = z.object({
    surname: z.string(),
    given_name: z.string(),
    employee_profile_photo: z.any(),
    mereg_number: z.string(),
    type_of_employee: z.string(),
    designation: z.string(),
    country_of_origin: z.string(),
    nationality: z.string(),
    dob: z.string(),
    sex: z.string(),
    marital_status: z.string(),
    passport_no: z.string(),
    passport_expiry: z.string(),
    passport_doc: z.any(),
    cerpac_no: z.string(),
    cerpac_expiry: z.string(),
    cerpac_front_doc: z.any(),
    cerpac_back_doc: z.any(),
    visa_type: z.string(),
    visa_number: z.string(),
    visa_expiry: z.string(),
    work_status: z.string(),
    name_of_company: z.string(),
    employee_phone_number: z.string(),
    employee_phone_code: z.string(),
    employee_email: z.string(),
    employee_address_1: z.string(),
    employee_address_2: z.string(),
    country: z.string(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
});


const errorMessages_for_create_employee = {
    surname: 'Surname is required',
    given_name: 'Given Name is required',
    employee_profile_photo: 'Employee profile photo is required and must be a valid file',
    mereg_number: 'Mereg number is required',
    type_of_employee: 'Type of employee is required',
    designation: 'Designation is required',
    country_of_origin: 'Country of origin is required',
    nationality: 'Nationality is required',
    dob: 'Date of birth is required',
    sex: 'Sex is required',
    marital_status: 'Marital status is required',
    passport_no: 'Passport number is required',
    passport_expiry: 'Passport expiry is required',
    passport_doc: 'Passport document is required and must be a valid file',
    cerpac_no: 'CERPAC number is required',
    cerpac_expiry: 'CERPAC expiry is required',
    cerpac_front_doc: 'CERPAC front document is required and must be a valid file',
    cerpac_back_doc: 'CERPAC back document is required and must be a valid file',
    visa_type: 'Visa type is required',
    visa_number: 'Invalid visa number',
    visa_expiry: 'Visa expiry is required',
    work_status: 'Work status is required',
    name_of_company: 'Name of company is required',
    employee_phone_number: 'Employee phone number is required',
    employee_phone_code: 'Employee phone code is required',
    employee_email: 'Employee email is required',
    employee_address_1: 'Employee address 1 is required',
    employee_address_2: 'Employee address 2 is required',
    country: 'Country is required',
    city: 'City is required',
    state: 'State is required',
    zipcode: 'Zipcode is required',
    is_dependants: 'Is dependants is required',
    special_characters_error: "Special characters are not allowed"

};

export const Icreate_employee_schema_yup = yup.object().shape({
    surname: yup.string().matches(/^([a-zA-Z _-]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.surname),
    given_name: yup.string().matches(/^([a-zA-Z _-]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.given_name),
    employee_profile_photo: yup.mixed().test("fileType1", "Invalid file type", (value) => {
        // Check if the value is a File object
        if (value instanceof File) {
            return true
        }
        return false;
    }).required(errorMessages_for_create_employee.employee_profile_photo),
    mereg_number: yup.string().matches(/^([a-zA-Z0-9]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.mereg_number),
    type_of_employee: yup.string().required(errorMessages_for_create_employee.type_of_employee),
    designation: yup.string().required(errorMessages_for_create_employee.designation),
    country_of_origin: yup.string().required(errorMessages_for_create_employee.country_of_origin),
    nationality: yup.string().required(errorMessages_for_create_employee.nationality),
    dob: yup.string().required(errorMessages_for_create_employee.dob),
    sex: yup.string().required(errorMessages_for_create_employee.sex),
    marital_status: yup.string().required(errorMessages_for_create_employee.marital_status),
    passport_no: yup.string().matches(/^[A-Z0-9]{8,10}$/, { message: "Invalid Passport number" }).required(errorMessages_for_create_employee.passport_no),
    passport_expiry: yup.string().test('passport_expiry', (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && passport_no) {
            return createError({ message: errorMessages_for_create_employee.passport_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    passport_doc: yup.mixed().test("passport_doc", (value, validationcontext) => {
        const { createError, parent: { passport_no } } = validationcontext;
        // Check if the value is a File object

        if (!value && passport_no) {
            return createError({ message: "Please upload passport" });
        }

        if (!(value instanceof File)) {
            return createError({ message: "Please select valid file" });
        }

        return true;
    }).required(errorMessages_for_create_employee.passport_doc),
    cerpac_no: yup.string().matches(/^[A-Z0-9]{8,20}$/, { message: "Invalid CERPAC number" }).required(errorMessages_for_create_employee.cerpac_no),
    cerpac_expiry: yup.string().test('cerpac_expiry', (value, validationcontext) => {
        const { createError, parent: { cerpac_no } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && cerpac_no) {
            return createError({ message: errorMessages_for_create_employee.cerpac_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    cerpac_front_doc: yup.mixed().test("cerpac_front_doc", (value, validationcontext) => {
        const { createError, parent: { cerpac_no } } = validationcontext;
        // Check if the value is a File object

        if (!value && cerpac_no) {
            return createError({ message: "Please upload passport" });
        }

        if (!(value instanceof File)) {
            return createError({ message: "Please select valid file" });
        }

        return true;
    }).required(errorMessages_for_create_employee.cerpac_front_doc),
    cerpac_back_doc: yup.mixed().test("cerpac_back_doc", (value, validationcontext) => {
        const { createError, parent: { cerpac_no } } = validationcontext;
        // Check if the value is a File object

        if (!value && cerpac_no) {
            return createError({ message: "Please upload passport" });
        }

        if (!(value instanceof File)) {
            return createError({ message: "Please select valid file" });
        }

        return true;
    }).required(errorMessages_for_create_employee.cerpac_back_doc),
    visa_type: yup.string().required(errorMessages_for_create_employee.visa_type),
    visa_number: yup.string().matches(/^4[0-9]{12}(?:[0-9]{3})?$/, { message: "Invalid visa number" }).required(errorMessages_for_create_employee.visa_number),
    visa_expiry: yup.string().test('visa_expiry', (value, validationcontext) => {
        const { createError, parent: { visa_number } } = validationcontext;
        const inputDate = value && new Date(value);
        const currentDate = new Date();

        if (!value && visa_number) {
            return createError({ message: errorMessages_for_create_employee.visa_expiry });
        }

        if (value && inputDate && inputDate <= currentDate) {
            return createError({ message: "Expiry date should not be less than current date." });
        }

        return true;
    }),
    work_status: yup.string().required(errorMessages_for_create_employee.work_status),
    name_of_company: yup.string().matches(/^([a-zA-Z _-]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.name_of_company),
    employee_phone_number: yup.string()
        .matches(/^([0-9]+)$/, 'Invalid phone')
        .length(10)
        .required(errorMessages_for_create_employee.employee_phone_number),
    employee_phone_code: yup.string().optional(),
    employee_email: yup.string().email().required(errorMessages_for_create_employee.employee_email),
    employee_address_1: yup.string().matches(/^([a-zA-Z0-9\s_,-]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.employee_address_1),
    employee_address_2: yup.string().matches(/^([a-zA-Z0-9\s_,-]+)$/, { message: errorMessages_for_create_employee.special_characters_error }).required(errorMessages_for_create_employee.employee_address_2),
    country: yup.string().required(errorMessages_for_create_employee.country),
    city: yup.string().required(errorMessages_for_create_employee.city),
    state: yup.string().required(errorMessages_for_create_employee.state),
    zipcode: yup.string().matches(/^([0-9]+)$/, { message: "Invalid zip code" }).required(errorMessages_for_create_employee.zipcode),
    is_dependants: yup.boolean().required(errorMessages_for_create_employee.is_dependants),
    dependants: yup.array(createDependantSchema).optional()
});


export type Employee = {
    selectedEmployees: any[],
}

export const INIT_STATE = {
    selectedEmployees: [],
}

export type MainContextProps = {
    state: Employee,
    setSelectedEmployee: (e: any) => void,
    resetSelectedEmployee: () => void
}

export const initialProps: MainContextProps = {
    state: INIT_STATE,
    setSelectedEmployee: () => { },
    resetSelectedEmployee: () => { }
}

export const ACTION_TYPES = {
    INIT: "INIT",
    SET_SELECTED_EMPLOYEES: "SET_SELECTED_EMPLOYEES",
    RESET_SELECTED_EMPLOYEES: "SET_RESET_SET_SELECTED_EMPLOYEES",
}

export const Reducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.INIT:
            return INIT_STATE
            break;
        case ACTION_TYPES.SET_SELECTED_EMPLOYEES:
            return { ...state, selectedEmployees: action.payload }
            break;
        case ACTION_TYPES.RESET_SELECTED_EMPLOYEES:
            return { ...state, selectedEmployees: [] }
            break;
        default:
            break;
    }
}
