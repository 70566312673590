import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import { INIT_RENEW_qUOTA_FORM, RENEW_QUOTA_FORM_FILEDS, renewQuotaValidationSchema } from './modules'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import { optionsType } from 'src/Helpers'
import { IApiResponse } from 'src/types'
import { getDDQuotaPositionsList, quotaUpdateRequestApi, updateEmployeeProfile } from 'src/services/services'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { getLoginReducerState } from 'src/Silcers/loginSlicer'
import axios, { AxiosError } from 'axios'
import LoadingDialog from 'src/components/LoadingDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import SubmitBtn from 'src/components/Common/SubmitBtn'
import LightBtn from 'src/components/Common/LightBtn'
import { Badge } from 'src/components/ui/badge'
import { useDispatch } from 'react-redux'
import { fetchAllEmployeesAsync } from 'src/Silcers/employeesSlicer'

type Props = {
  rowData: any
}

const UpdateQuota = ({ rowData }: Props) => {


  const { user } = useSelector((state: RootState) => getLoginReducerState(state))
  const [quotaPositionsList, setquotaPositionsList] = useState<optionsType[]>([])
  const [quotaPositionsExpiryList, setquotaPositionsExpiryList] = useState<optionsType[]>([])
  const [showQuotaEmptyError, setshowQuotaEmptyError] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<"show" | "hide">("hide")
  const dispatch = useDispatch()
  const [apiRequestState, setApiRequestState] = useState<{
    isLoading: boolean,
    isError: boolean | undefined,
    message: string | undefined,
  }>({
    isLoading: false,
    isError: false,
    message: ""
  })

  useEffect(() => {
    if (user?.rcNumber) {
      fetchQuotaList()
    }
    return () => { }
  }, [])

  // useEffect(() => {
  //   if (showForm == "show") {
  //     // formik.resetForm()
  //   }
  //   return () => { }
  // }, [showForm])


  const fetchQuotaList = async () => {
    try {
      setApiRequestState({ isLoading: true, message: "Fetching Quota Details", isError: false })
      const apiResp: IApiResponse = await getDDQuotaPositionsList(user?.rcNumber);
      setApiRequestState({ isLoading: false, message: apiResp?.message || "", isError: false })
      // console.log({ apiResp })
      if (apiResp.status == 200) {
        if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length > 0) {
          let newQuotaPositions = apiResp.data.map((d: any) => {
            return {
              label: d.position,
              value: d._id
            }
          })

          setquotaPositionsList([{ label: "select", value: "" }, ...newQuotaPositions])

          setquotaPositionsExpiryList(apiResp.data.map((d: any) => {
            return {
              label: d.expirationDate,
              value: d._id
            }
          }))
        }
        else if (apiResp.data && Array.isArray(apiResp.data) && apiResp.data.length == 0) {
          setshowQuotaEmptyError(true)
        }
      } else if (apiResp?.data && Array.isArray(apiResp?.data) && apiResp?.data.length == 0) {
        setshowQuotaEmptyError(true)
      }
      else {
        setquotaPositionsList([])
      }
    } catch (e) {
      const error = e as Error | AxiosError
      if (axios.isAxiosError(error)) {
        // console.log(error.response?.data)
        setApiRequestState({ isLoading: false, message: error.response?.data?.message || "", isError: true })
      }
      else {
        setApiRequestState({ isLoading: false, message: error.message || "", isError: true })
      }
    }
  }

  const formik = useFormik({
    initialValues: INIT_RENEW_qUOTA_FORM,
    validationSchema: renewQuotaValidationSchema,
    onSubmit(values, formikHelpers) {
      handleUpdateQuotaRequest()
    },
  })


  const handleUpdateQuotaRequest = async () => {
    try {

      setApiRequestState({ isLoading: true, message: "Request Sending", isError: false })
      let postdata: any = { ...formik.values }
      delete postdata.selected_position_id
      const apiResp = await quotaUpdateRequestApi({
        ...postdata,
        _id: rowData._id,
      });

      setApiRequestState({ isLoading: false, message: "", isError: false })
      dispatch(fetchAllEmployeesAsync())
      setShowForm("hide")


    } catch (e) {
      const error = e as Error | AxiosError
      if (axios.isAxiosError(error)) {
        setApiRequestState({ isLoading: false, message: error.response?.data?.message || "", isError: true })
      }
      else {
        setApiRequestState({ isLoading: false, message: error.message || "", isError: true })
      }
    }
  }

  console.log("test", { values: formik.values })

  return (
    <div>
      <Badge onClick={() => setShowForm("show")} className='cursor-pointer' variant={'secondary'}>Renew Quota</Badge>
      <CustomeDialog isOpen={showForm == "show"} hideDefaultCancle hideDefaultSubmit headerTitle="Assign/Renew Quota" >
        <div className=''>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <RenderFormikFiled formik={formik} options={quotaPositionsList} formObject={RENEW_QUOTA_FORM_FILEDS.selected_position_id} value={formik.values.selected_position_id} onchange={(e: any) => {
                  if (e) {
                    console.log({ e });
                    formik.setFieldValue("selected_position_id", e)
                    formik.setFieldValue("position", e ? quotaPositionsList.find((d: optionsType) => d.value === e)?.label : "")
                    formik.setFieldValue("quotaId", e ? e : "")
                    formik.setFieldValue("quota_expiry", e ? quotaPositionsExpiryList.find((d: optionsType) => d.value === e)?.label : "")
                  }

                }} />
              </div>
              <div>
                <RenderFormikFiled formik={formik} options={quotaPositionsExpiryList} formObject={RENEW_QUOTA_FORM_FILEDS.quota_expiry} value={formik.values.quota_expiry} onchange={(e: any) => {
                  formik.setFieldValue("quota_expiry", e)
                }} />
              </div>
              <div className='flex justify-center space-x-4 my-4'>
                <LightBtn title='Cancel' onclick={() => { setShowForm("hide") }} />
                <SubmitBtn isDisabled={!formik.values.quotaId} isLoading={apiRequestState.isLoading} title='Submit' />
              </div>
            </form>
          </div>
        </div>
      </CustomeDialog>

      <LoadingDialog isOpen={apiRequestState.isLoading} message={apiRequestState.message} />

      <ErrorDialog isOpen={apiRequestState.isError || false} message={apiRequestState.message} onCancelClick={() => setApiRequestState({
        isError: false,
        message: "",
        isLoading: false
      })} />

      <ErrorDialog isOpen={showQuotaEmptyError} message="Quota not available please add new quota" onCancelClick={() => {
        setshowQuotaEmptyError(false)
      }} />
    </div>
  )
}

export default UpdateQuota