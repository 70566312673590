import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SVGC } from 'src/Helpers/SVGC';
import { resetState as forgotPasswordResetState } from "src/Silcers/forgotPasswordSlicer";
import { reseteState as loginResetState } from "src/Silcers/loginSlicer";
import { toAbsoluteUrl } from "../../../Helpers";
import UserAuthForm from "./UserAuthForm";

type Props = {};

const Login = (props: Props) => {

  const dispatch = useDispatch()

  const [showpopup, setshowpopup] = useState(true)


  useEffect(() => {
    dispatch(loginResetState());
    dispatch(forgotPasswordResetState())
    return () => { }
  }, [])


  return (
    <>
      <div className="flex flex-col md:flex-row justify-start items-start self-stretch md:h-[600px] md:mx-0 ">
        <div className="grow w-full h-full">
          <div className="flex justify-center w-full items-center h-full">
            <div
              className="flex flex-col md:w-3/4 md:h-3/4 justify-center bg-opacity-10 "
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/svg/eel_watermark_grey_10.svg"
                )})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "left",
              }}
            >
              <div className="flex flex-col mb-4">
                <h1 className="scroll-m-20 font-normal tracking-tight lg:text-3xl mb-2">
                  Manage your  <br /><span className="font-medium text-primary">Expatriate Employment </span>
                  <span className="font-medium text-primary">
                    Levies
                  </span>{" "}
                  with <span className="font-medium">EEL</span>
                </h1>
              </div>
              <div className="flex lg:flex-row sm:flex-col lg:mt-8">
                <div className="grow p-1 bg-white">
                  <div onClick={() => window.open(process.env.REACT_APP_MOI_LINK, '_blank')} className=" border-solid border border-gray-300 p-2 rounded-sm  hover:border-primary transition-all duration-200 cursor-pointer">
                    <img
                      src={toAbsoluteUrl("/media/logo/mis_logo.png")}
                      className="h-[60px]"
                    />
                    <div className="font-normal cursor-pointer hover:text-primary text-end ">
                      <a href={process.env.REACT_APP_MOI_LINK} target="_blank" rel="noreferrer">Read more info</a>
                    </div>
                  </div>
                </div>
                <div className="grow p-1 bg-white">
                  <div onClick={() => window.open(process.env.REACT_APP_NIS_LINK, '_blank')} className="border-solid border border-gray-300 p-2 rounded-sm  hover:border-primary transition-all duration-200 cursor-pointer">
                    <img
                      src={toAbsoluteUrl("/media/logo/moi_logo.png")}
                      className="h-[60px]"
                    />
                    <div className="font-normal cursor-pointer hover:text-primary text-end ">
                      <a href={process.env.REACT_APP_NIS_LINK} target="_blank" rel="noreferrer">Read more info</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* LINKS */}
              <div className="lg:flex-row hidden md:flex ">
                <div className="md:grow p-1">
                  <Link to='/faq' target="_blank">
                    <div className=" p-1 mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300 rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/faq.svg')} className=' w-[23px] h-[23px] mt-1 mr-2 fill-primary' ></SVGC>
                      <p>FAQ</p>
                    </div>
                  </Link>
                </div>
                <div className="md:grow p-1">
                  <Link to={process.env.REACT_APP_HANDBOOK_URL || ""} target="_blank">
                    <div className=" p-1 mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300  rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/handbook.svg')} className=' w-[23px] h-[23px] mt-1 mr-2' ></SVGC>
                      <p>Handbook</p>
                    </div>
                  </Link>
                </div>
                <div className="md:grow p-1">
                  <Link to={process.env.REACT_APP_USER_MANUAL || ""} target="_blank">
                    <div className=" p-1 mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300 rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/usermanual.svg')} className=' w-[23px] h-[23px] mt-1 mr-2' ></SVGC>
                      <p>User Manual</p>
                    </div>
                  </Link>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        
        <div className="grow md:w-full h-full ">
          <div className="flex justify-center items-center h-full md:mt-7 w-5/10">
            <UserAuthForm />
          </div>
        </div>
      </div >
    </>
  );
};

export default Login;
