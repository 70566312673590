import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Button } from "src/components/ui/button";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import NavBar from "src/components/Common/NavBar";
import { fetchAllEmployeesAsync } from "src/Silcers/employeesSlicer";
import { useDispatch } from "react-redux";
import { SVGC } from "src/Helpers/SVGC";
import PaymentList from "./Components/PaymentList";
import { MainContextProvider } from "./MainContext";

type Props = {}

function Main() {
    const navigate = useNavigate();

    const { selectedEmployee, toBeDeletedEmp, allEmployee, loadingEmployeeList } =
        useSelector((state: RootState) => state.employees);

    const dispatch = useDispatch();

    // const totalAmt = state
    // console.log(selectedEmployee, "slected emp start ", toBeDeletedEmp);

    return (
        <div className="p-7">
            <div className="card bg-base-100 shadow-sm rounded-md">
                {/* <TopHeadlogo
                    mainContent={'Employee/Expatriate'}
                    subContent={''} 
                /> */}
                {/* <NavBar/> */}
                <PageHeadingComponent
                    mainContent={"Payment History"}
                    subContent={""}
                />

                {/* <div className="flex flex-col items-end my-4">
          <div className="flex">
            <Button
              className="border-primary text-primary hover:bg-primary hover:text-white"
              variant="outline"
            >
              Upload CSV
              <span className="ml-2">
                <SVGC
                  path={"/media/icons/svg/upload.svg"}
                  className="w-[16px] h-[16px]"
                />
              </span>
            </Button>
            <Button
              className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
              variant="outline"
            >
              Download CSV Template
              <span className="ml-2">
                <SVGC
                  path={"/media/icons/svg/download.svg"}
                  className="w-[16px] h-[16px]"
                />
              </span>
            </Button>
            <Button
              hidden={allEmployee.length == 0}
              onClick={() => navigate({ pathname: "/employee-add" })}
              className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
              variant="outline"
            >
              + Add Employee
            </Button>
            <Button
              hidden={allEmployee.length == 0}
              className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
              variant="outline"
            >
              Download All Employes
            </Button>
          </div>
          <p className="text-right mr-4 mt-3 text-xs">
            If you have multiple employees, kindly download the CSV template,
            fill and upload
          </p>
        </div> */}

                {/* {allEmployee && allEmployee.length == 0 && !loadingEmployeeList && (
          <>
            <div className=" bg-secondary flex justify-center h-[57vh] items-center">
              <div className="flex flex-col justify-center items-center">
                <span className="sub-styles">
                  No Employee / expatriate please add
                </span>
                <Link to="/employee-add">
                  <Button className="mt-3">+ Add Employee</Button>
                </Link>
              </div>
            </div>
          </>
        )} */}

                {/* {allEmployee && allEmployee.length == 0 && loadingEmployeeList && (
          <>
            <div className=" bg-gray-50 flex justify-center h-[57vh] items-center">
              <div className="flex flex-col justify-center items-center">
                Loading....
              </div>
            </div>
          </>
        )} */}
                {/* 
        {allEmployee && allEmployee.length > 0 && !loadingEmployeeList && (
          <EmployeeList />
        )} */}

                {/* {allEmployee && allEmployee.length > 0 && (
          <div className="w-full flex justify-center my-2">
            {allEmployee.length > 0 && selectedEmployee.length > 0 ? (
              <Button
                onClick={() => navigate({ pathname: "/employee-total" })}
                variant={"default"}
                className="mb-2 px-10 w-44 mx-auto"
              >
                Continue
              </Button>
            ) : (
              <Button
                disabled
                variant={"secondary"}
                className="mb-2 px-10 w-44 mx-auto"
              >
                Continue
              </Button>
            )}
          </div>
        )} */}
                {/* <CompanyStepTab activeTab={activeTab} handleTabClick={handleTabClick} steps={steps} />
                {activeTab === 1 && <StepOneDetail/>}
                {activeTab === 2 && <StepTwoReview/>} */}

                <PaymentList />
            </div>
        </div>
    );
}

const PaymentMain = (props: Props) => {
    return (
        <MainContextProvider>
            <Main />
        </MainContextProvider>
    );
};

export default PaymentMain;
