import { Link } from 'react-router-dom'
import { SVGC } from 'src/Helpers/SVGC'
import { cn } from 'src/lib/utils'

type Props = {
    link:string
}

const Redirect = (props: Props) => {
    return (
        <div className="w-[65px] h-[65px] rounded-full border bottom-1 flex items-center justify-center border-primary hover:bg-primary">
            <div className="w-[35px] h-[35px] rounded-full border bottom-1 flex items-center justify-center text-white text-lg border-primary bg-white">
                <p 
                // to={props.link}
                ><SVGC path={'/media/icons/svg/redirect.svg'} className="hover:text-primary cursor-pointer" /></p>
            </div>
        </div>
    )
}

export default Redirect