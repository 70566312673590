import {
  ColumnDef
} from "@tanstack/react-table";

import { Checkbox } from "src/components/ui/checkbox";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import Actions from "./Actions";

export const columns: ColumnDef<Task>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={table.getIsAllPageRowsSelected()}
  //       onCheckedChange={(value) => {
  //         table.toggleAllPageRowsSelected(!!value);
  //       }}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => <></>,
  //   // row.original.paymentstatus != "complete" && row.original?.emp_insertstatus == "completed" && (
  //   //   <Checkbox
  //   //     disabled={row.original.paymentstatus == "complete"}
  //   //     checked={row.getIsSelected()}
  //   //     onCheckedChange={(value) => row.toggleSelected(!!value)}
  //   //     aria-label="Select row"
  //   //     className={cn("translate-y-[2px]", {
  //   //       "border-1 bg-slate-100": row.original.paymentstatus == "complete",
  //   //     })}
  //   //   />
  //   // ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "card_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Card Number" />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "card_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Card Type" />
    ),
  },
  {
    accessorKey: "designation",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Card Designation" />
    ),
  },
  {
    accessorKey: "card_expiry_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Card Expiry" />
    ),
  },
  {
    accessorKey: "quota_expiry_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Quota Expiry" />
    ),
  },
  {
    id: "action",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Action" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];
