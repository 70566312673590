import { useEffect, useState, useRef, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";

import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Button } from "src/components/ui/button";
import EmployeeList from "./Components/EmployeeList";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import NavBar from "src/components/Common/NavBar";
import { fetchAllEmployeesAsync, fetchCompleteEmployeesAsync, fetchIncompleteEmployeesAsync, fetchPaidEmployeesAsync, setSelectedEmployee } from "src/Silcers/employeesSlicer";
import { useDispatch } from "react-redux";
import { SVGC } from "src/Helpers/SVGC";
import { Separator } from "src/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTrigger } from "src/components/ui/dialog";
import { Label } from "src/components/ui/Label";
import { Input } from "src/components/ui/Input";
import { Loader2 } from "lucide-react";
import { uploadCsv } from "src/services/services";
import ErrorDialog from "src/components/ErrorDialog";
import axios, { AxiosError } from "axios";
import { toAbsoluteUrl } from "src/Helpers";
import InformDialog from "src/components/InformDialog";
import { resetState } from "src/Silcers/createEmployeeSlicer";
import { resetState as resetCompleteEmployeeProfile } from "src/Silcers/completeEmployeeProfileSlicer";
import { resetState as resetemployeeDetails } from "src/Silcers/employeeDetailsSlicer";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip";
import SuccessDialog from "src/components/SuccessDialog";

function EmpUpload() {
  const navigate = useNavigate();
  const [tabstate, setTabSwitch] = useState('all')
  const {
    selectedEmployee,
    toBeDeletedEmp,
    allEmployee,
    loadingEmployeeList
  } = useSelector((state: RootState) => state.employees);

  const dispatch = useDispatch();
  const [showCSVMessage, setshowCSVMessage] = useState<boolean>(false)

  useEffect(() => {
    dispatch(resetState())
    dispatch(resetCompleteEmployeeProfile())
    dispatch(resetemployeeDetails())
    return () => { }
  }, [])


  const [openUploadCSVDialog, setopenUploadCSVDialog] = useState<boolean>(false)
  const [uploadingCSV, setuploadingCSV] = useState<boolean>(false)
  const [uploadingCSVError, setuploadingCSVError] = useState<boolean>(false)
  const [uploadingCSVMessage, setuploadingCSVMessage] = useState<string | undefined>(undefined)
  const [selectCSV, setselectCSV] = useState<File | undefined>(undefined)
  const downloadFile = useRef<any>(null)
  const downloadGuidelinesFile = useRef<any>(null)

  useEffect(() => {
    if (tabstate === 'all') dispatch(fetchAllEmployeesAsync());
    else if (tabstate === 'incomplete') dispatch(fetchIncompleteEmployeesAsync());
    else if (tabstate === 'not-paid') dispatch(fetchCompleteEmployeesAsync());
    else if (tabstate === 'paid') dispatch(fetchPaidEmployeesAsync());
    return () => { };
  }, [tabstate]);

  // const totalAmt = state
  // console.log(selectedEmployee, "slected emp start ", toBeDeletedEmp);

  const uploadCSVFile = async () => {
    try {
      setuploadingCSV(true)
      const apiResp = await uploadCsv([selectCSV])
      // console.log({ apiResp });
      if (apiResp?.status == 201) {
        if (tabstate === 'all') dispatch(fetchAllEmployeesAsync());
        else if (tabstate === 'incomplete') dispatch(fetchIncompleteEmployeesAsync());
        else if (tabstate === 'not-paid') dispatch(fetchCompleteEmployeesAsync());
        else if (tabstate === 'paid') dispatch(fetchPaidEmployeesAsync());
        setTabSwitch("all")
        setopenUploadCSVDialog(false)
        setuploadingCSV(false)
        setuploadingCSVError(false)
        setuploadingCSVMessage(undefined)
        setselectCSV(undefined)
        setshowCSVMessage(true)
      }
      else {
        setuploadingCSVError(true)
        setuploadingCSVMessage(apiResp?.message || undefined)
      }
      setuploadingCSV(false)
    }
    catch (e) {
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // console.log({ error: error.response?.data.message });
        setuploadingCSVError(true)
        setuploadingCSVMessage(error.response?.data.message)
      }
      else {
        setuploadingCSVError(true)
        setuploadingCSVMessage(error.message)
      }
    }
  }

  return (
    <div className="p-4">
      <div className="card bg-base-100 shadow-sm rounded-md">
        {/* <TopHeadlogo
              mainContent={'Employee/Expatriate'}
              subContent={''} 
                /> */}
        {/* <NavBar/> */}
        <PageHeadingComponent
          mainContent={"EMPLOYEE/EXPATRIATE LIST"}
          subContent={""}
        />
        <div className="flex flex-col items-end my-4">
          <div className="flex">
            
            <div className="flex flex-col">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <a hidden ref={downloadGuidelinesFile} target="_blank" href={toAbsoluteUrl('/Files/csv/CSV upload Guidelines.xlsx')} download ></a>
                    <Button
                      className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
                      variant="outline"
                      onClick={() => downloadGuidelinesFile.current.click()}
                    >
                      Download CSV Guidelines
                      <span className="ml-2">
                        <SVGC
                          path={"/media/icons/svg/download.svg"}
                          className="w-[16px] h-[16px]"
                        />
                      </span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Bulk Employee CSV Guidelines</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <a hidden ref={downloadFile} target="_blank" href={toAbsoluteUrl('/Files/csv/employee_template.csv')} download ></a>
                    <Button
                      className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
                      variant="outline"
                      onClick={() => downloadFile.current.click()}
                    >
                      Download CSV Template
                      <span className="ml-2">
                        <SVGC
                          path={"/media/icons/svg/download.svg"}
                          className="w-[16px] h-[16px]"
                        />
                      </span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Bulk Employee CSV Template</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      className="border-primary text-primary hover:bg-primary hover:text-white"
                      variant="outline"
                      onClick={() => setopenUploadCSVDialog(true)}
                    >
                      Upload CSV
                      <span className="ml-2">
                        <SVGC
                          path={"/media/icons/svg/upload.svg"}
                          className="w-[16px] h-[16px]"
                        />
                      </span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Bulk Employee CSV</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      hidden={allEmployee.length == 0}
                      onClick={() => navigate({ pathname: "/employee-add" })}
                      className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
                      variant="outline"
                    >
                      + Add Employee
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Individual Employee Creation</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            {/* <Button
              hidden={allEmployee.length == 0}
              className="mx-2 border-primary text-primary hover:bg-primary hover:text-white"
              variant="outline"
            >
              Download All Employees
            </Button> */}
          </div>
          <p className="text-right mr-4 mt-3 text-xs">
            If you have multiple employees, kindly download the CSV template,
            fill and upload
          </p>
        </div>
        <Tabs defaultValue="all" className="mb-2 space-y-6">
          <div className="space-between flex items-center">
            <TabsList>
              <TabsTrigger onClick={() => setTabSwitch('all')} value="all" className="relative">All Profiles</TabsTrigger>
              <TabsTrigger onClick={() => setTabSwitch('incomplete')} value="incomplete">Incomplete Profiles</TabsTrigger>
              <TabsTrigger onClick={() => setTabSwitch('not-paid')} value="not-paid">Profile Complete</TabsTrigger>
              <TabsTrigger onClick={() => setTabSwitch('paid')} value="paid">Paid</TabsTrigger>
            </TabsList>
          </div>
        </Tabs>

        {allEmployee && allEmployee.length == 0 && !loadingEmployeeList && (
          <>
            <div className=" bg-secondary flex justify-center h-[57vh] items-center">
              <div className="flex flex-col justify-center items-center">
                <span className="sub-styles">
                  No Employee / expatriate please add
                </span>
                <Link to="/employee-add">
                  <Button className="mt-3">+ Add Employee</Button>
                </Link>
              </div>
            </div>
          </>
        )}
        {allEmployee && allEmployee.length == 0 && loadingEmployeeList && (
          <>
            <div className=" bg-gray-50 flex justify-center h-[57vh] items-center">
              <div className="flex flex-col justify-center items-center">
                Loading....
              </div>
            </div>
          </>
        )}
        {allEmployee && allEmployee.length > 0 && !loadingEmployeeList && (
          <EmployeeList allEmployee={allEmployee} loadingEmployeeList={loadingEmployeeList} />
        )}

        {allEmployee && allEmployee.length > 0 && (
          <div className="w-full flex justify-center my-2">
            {allEmployee.length > 0 && selectedEmployee.length > 0 ? (
              <Button
                onClick={() => navigate({ pathname: "/employee-total" })}
                variant={"default"}
                className="mb-2 px-10 w-44 mx-auto"
              >
                Continue
              </Button>
            ) : (
              <Button
                disabled
                variant={"secondary"}
                className="mb-2 px-10 w-44 mx-auto"
              >
                Continue
              </Button>
            )}
          </div>
        )}

        <Dialog
          open={openUploadCSVDialog}
          onOpenChange={(e: any) => setopenUploadCSVDialog(e)}
        >
          <DialogContent className="sm:max-w-[525px]">
            <DialogHeader>
              <PageHeadingComponent
                mainContent="UPload CSV"
                subContent={``}
              />
            </DialogHeader>
            <DialogDescription>
              <Label className=" uppercase mb-4">
                please select csv
              </Label>
              <Input
                type="file"
                accept=".csv,.xlsx"
                placeholder="Select Document"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  //console.log(e.target.files)
                  e.target.files && e.target.files.length > 0
                    ? setselectCSV(e.target.files[0])
                    : setselectCSV(undefined)
                }
              />
              <div className="w-full flex justify-around mt-4">
                <DialogTrigger asChild>
                  <Button variant={"outline"} className=" inline-block px-16">
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button
                  disabled={uploadingCSV}
                  onClick={() => {
                    // setopenFileUploadDialog(false);
                    // setopenDeleteModal(false);
                    // setopenSubmitted(true);

                    if (selectCSV instanceof File) {
                      uploadCSVFile()
                    }
                  }}
                  className=" inline-block px-16"
                >
                  <div className="flex space-x-2">
                    {uploadingCSV && uploadingCSV && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Upload
                  </div>
                </Button>
              </div>
            </DialogDescription>
          </DialogContent>
        </Dialog>
        <ErrorDialog isOpen={uploadingCSVError} message={uploadingCSVMessage} onCancelClick={() => {
          setuploadingCSVError(false);
          setuploadingCSVMessage(undefined)
          setselectCSV(undefined)
          setopenUploadCSVDialog(false)
        }} />
        <SuccessDialog title="CSV Document uploaded" isOpen={showCSVMessage}  onClickOkBtn={()=>setshowCSVMessage(false)}>
          <p>Kindly complete the employee profile individually</p>
        </SuccessDialog>

      </div>


    </div>
  );
}

export default EmpUpload;
