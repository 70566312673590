import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setActiveTab } from "src/Silcers/companyRegistrationSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Button } from "src/components/ui/button";

function Requirements() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  return (
    <div className="p-6 flex flex-col justify-center items-center">
      <div className=""></div>
      <div className="md:w-5/6">
        <div></div>
        <div className="h-[280px] overflow-auto bg-white flex flex-col  mt-5 ">
          <div className="flex justify-center">
            <PageHeadingComponent mainContent={"REQUIREMENTS"} />
          </div>

          <div className="mt-5">
            <p className="">
              Companies applying for an Employee Expatriate Levy Card for their employees must provide the following documents:
            </p>
            <div className=" flex flex-col md:flex-row justify-around mt-4 ">
              <div>
                <p>
                  {" "}
                  <b>Company documents:</b>
                </p>
                <ul className=" list-disc">
                  <li>C.A.C : Corporate Affairs Commission </li>
                  <li>T.C.C : Tax Clearance Certificate </li>
                  <li>PenCom </li>
                  <li>I.T.F : Industrial Training Fund </li>
                  <li>Renewal of Expatriate Quota Positions  </li>
                </ul>
              </div>
              <div>
                <p>
                  <b>Employee documents</b>:
                </p>
                <ul className=" list-disc">
                  <li>Employee International Passport</li>
                  <li>Digital Passport size Photograph ( 51 mm x 51 mm, maximum size limit 2 MB)</li>
                  <li>Employee CERPAC</li>
                </ul>
              </div>
            </div>
            <p className=" mt-4">
              <span className=" font-medium text-red-500">Note:
              </span>The document satisfies the requirements, A maximum of 5 MB for files in the JPEG, PNG, and PDF formats,<br />
              <span className="ml-10">kindly upload passport size photograph in white background.
              </span>
            </p>
          </div>

          {/* <div>
            <p className=" text-center text-2xl mb-2">
              <span className=" font-semibold">
                The Expatriate Employment Levy (EEL)
              </span>{" "}
              is a contribution imposed by the <br />{" "}
              <span className=" font-semibold">
                Federal Government of Nigeria (FGN)
              </span>{" "}
              on employers of expatriates working in Nigeria.
              <br />
              <br />
              The <span className=" font-semibold">EEL</span> is being
              implemented as a multi-faceted policy instrument that seeks to
              balance economic growth, social equity, and workforce development
              within <span className=" font-semibold">Nigeria</span>.
            </p>
          </div> */}
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-center w-full  mt-2">
          {/* <Button
            onClick={(e) => {
              dispatch(setActiveTab(1))
            }}
            className="my-6 px-24 mx-4 border border-primary"
            variant={"outline"}
          >
            Register Now
          </Button> */}
          <Button
            onClick={(e) => {
              dispatch(setActiveTab(1));
            }}
            className="my-6 px-24 mx-4 border border-primary"
            variant={"outline"}
          >
            Back
          </Button>

          <Button
            onClick={() => {
              dispatch(setActiveTab(4));
            }}
            className="my-6 px-14 mx-4"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Requirements;
