import axios, { AxiosError, AxiosResponse } from "axios";
import { getAuth } from "src/Modules/Auth/Core";
import { IRegistration } from "src/Modules/Auth/Regsitration/Components/steps/modules";
import { ICompanyProfile } from "src/Modules/CompanyProfile/modules";
import { ISendPaymentStatusPostData, IcreatePaymentPostData } from "src/Silcers/paymentSlicer";
import { IApiResponse, ICreateNewEmployeePostData, ICreateQuotaPostData, ILoginRequestData, IgetUserByTokenRequest, UserProfile } from "src/types";
import { COMPANY_PROFILE_UPDATE, COMPANY_PROFILE_URL, CREATE_DEPENDENT_URL, CREATE_EMPLOYEE_URL, CREATE_PAYMENT_URL, CURRUNCY_CONVERSION_URL, DELETE_DIRECT_EMPLOYEE_URL, DELETE_EMPLOYEE_URL, EMAIL_VALIDATE_URL, FORGOT_PASSWORD_AFTER_LOGIN, FORGOT_PASSWORD_REQUEST_URL, FORGOT_PASSWORD_URL, GENERATE_DEPENDENT_CARD_URL, GET_ALL_CITIES_URL, GET_ALL_COUNTRIES_URL, GET_ALL_EMPLOYEES_URL, GET_ALL_STATES_URL, GET_CARD_DETAILS_URL, GET_COMPANY_PROFILE_DETAILS, GET_DEPENDENT_CARD_DETAILS_URL, GET_DEPENDENT_DETAILS_URL, GET_DEPENDENT_PROFILE_PIC, GET_EMPLOYEES_DEPENDENTS_URL, GET_EMPLOYEES_DETAILS_URL, GET_PAYMENT_LIST_URL, GET_PROFILE_PIC, GET_PROFILE_URL, GET_QUOTA_SUMMURY_URL, GET_TRANSACTION_URL, GET_USER_DETAILS_URL, LOGIN_URL, REGISTRATION_URL, SEND_PAYMENT_STATUS_URL, UPDATE_DEPENDENT_DETAILS_URL, UPDATE_EMPLOYEES_DETAILS_URL, VERIFY_EMAIL_URL, VERIFY_RC_NUMBER_URL, GET_COMPLETE_EMPLOYEES_DETAILS_URL, GET_INCOMPLETE_EMPLOYEES_DETAILS_URL, GET_PAID_EMPLOYEES_DETAILS_URL, UPLOAD_FILES_URL, UPLOAD_CSV_URL, VERIFY_EMAIL_BY_TOKEN_URL, RESEND_VERIFY_EMAIL_BY_TOKEN_URL, QUOTA_CREATE_URL, QUOTA_LIST_URL, DD_QUOTA_POSITIONS_LIST_URL, GET_QUOPTA_DETAILS_URL, RESET_PASSWORD_URL, CONTACT_US_POST_DETAILS, GENEREATE_RRR_URL, GET_PAYMENT_STATUS, GET_AMT_OF_SELECTED_EMPLOYEES_URL, GET_FILE_URL, VERIFY_RC_NUMBER_BY_TOKEN_URL, CHECK_EMAIL_VERFICATION_TOKEN_STATUS_URL, VALIDATE_EMEPLOYEE_EMAIL_URL, VALIDATE_EMEPLOYEE_CERPAC_URL, VALIDATE_EMEPLOYEE_PASSPORT_URL, SET_SECURITY_QUATION_ANDPASSWORD_URL, ENQUIY_REQUEST_URL, COMPANY_KYC_STATUS_URL, CHANGE_REPRENTATIVE_EMAIL_VALIDATION_URL, CHANGE_REPRENTATIVE_FORM_SUBMIT_URL, GET_ALL_EMPTY_CARDS_REQUESTS_URL, URL_CHECK_PAYMENT_STATUS, CREATE_CARD_PRINTING_REQUEST_URL, BASE_URL, GET_ALL_CARD_UN_ASSIGNED_EMPLOYEES, ASSIGN_EMPLOYEE_TO_EMPTY_CARD_URL, URL_UPDATE_COMPANY_KYC_DETAILS, URL_UPDATE_EMPLOYEE_KYC_DETAILS, QUOTA_UPDATE_URL, EXEMPT_REQUEST_URL, COMPLETE_EMPLOYEES_PROFILE_URL } from "./api_urls";
import { axiosWithToken, axiosWithoutToken } from "./axiosSetup";


export function login({ businessEmail, password }: ILoginRequestData): Promise<AxiosResponse<any>> {
  return axiosWithoutToken.post(LOGIN_URL, { businessEmail, password }).then((d: AxiosResponse) => d.data);
}

// ===========================================================================================
// ||                                   COMPANY                                             ||
// ===========================================================================================  

export function company_registration(data: Partial<IRegistration>): Promise<AxiosResponse<any>> {
  return axiosWithoutToken.post(REGISTRATION_URL, data)
    .then((d: AxiosResponse) => d.data)
    .catch((e: AxiosError) => e.response?.data);
}

export function update_company_kyc_details_request_api(data: Partial<IRegistration>): Promise<AxiosResponse<any>> {
  return axiosWithToken.put(URL_UPDATE_COMPANY_KYC_DETAILS, data)
    .then((d: AxiosResponse) => d.data)
}

// ===========================================================================================
// ||                                   EMPLOYEE                                             ||
// ===========================================================================================  

export function update_employee_kyc_details_request_api(data: Partial<IRegistration>): Promise<AxiosResponse<any>> {
  return axiosWithToken.put(URL_UPDATE_EMPLOYEE_KYC_DETAILS, data)
    .then((d: AxiosResponse) => d.data)
}


export function getUserByToken(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.post(GET_USER_DETAILS_URL, { _id: user_id, accessToken: api_token }).then((d: AxiosResponse) => d.data)
}

export function validateEmail(email: string): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(EMAIL_VALIDATE_URL, { "businessEmail": email }).then((d: AxiosResponse) => d.data);
}

export function forgotPasswordAfterLogin(data: any): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(FORGOT_PASSWORD_AFTER_LOGIN, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);;
}

export function forgotPassword(data: any): Promise<AxiosResponse<UserProfile>> {
  return axios.post(FORGOT_PASSWORD_URL, data,).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);;
}

export function resetPassword(data: any): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(RESET_PASSWORD_URL, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);;
}


export function resetPasswordAndSecurityQuationApi(data: any, token: string): Promise<AxiosResponse<UserProfile>> {
  return axios.put(SET_SECURITY_QUATION_ANDPASSWORD_URL, data, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);;
}


export function uploadfile(files: any, key: string): Promise<{
  message: string,
  data: string,
  comapnyUuid: string
} | undefined | any> {

  // console.log({ files })

  let formData: any = new FormData();
  const { user_id, api_token }: any = getAuth()
  files && Array.isArray(files) && files.forEach((file, indx) => {
    formData.append(`file`, file, file.name)
  });

  // console.table(Object.fromEntries(formData))

  const newUrl = UPLOAD_FILES_URL + "?userid=" + user_id + "&key=" + key;

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${api_token}`);

  var requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  };

  return fetch(newUrl, requestOptions)
    .then(response => response.json())
    .then(result => result)
}


export function uploadfileByEmail(files: any, businessEmail: any): Promise<{
  message: string,
  data: string,
  comapnyUuid: string
} | undefined | any> {

  // console.log({ files })

  let formData: any = new FormData();

  files && Array.isArray(files) && files.forEach((file, indx) => {
    formData.append(`file`, file, file.name)
  });

  console.table(Object.fromEntries(formData))

  const newUrl = UPLOAD_FILES_URL + "?businessEmail=" + businessEmail

  var myHeaders = new Headers();

  var requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  };

  return fetch(newUrl, requestOptions)
    .then(response => response.json())
    .then(result => result)
}


export function getUpladedFile(fileType: string, doc_id: string, file_key: string): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(`${GET_FILE_URL}/${fileType}?_id=${doc_id}&type=${file_key}`, {
    responseType: "blob"
  }).then((d: AxiosResponse) => d)
}

export function uploadCsv(files: any): Promise<{
  message: string,
  data: string,
  comapnyUuid: string
} | undefined | any> {

  // console.log({ files })

  let formData: any = new FormData();

  files && Array.isArray(files) && files.forEach((file, indx) => {
    formData.append(`excelFile`, file, file.name)
  });

  console.table(Object.fromEntries(formData))
  const { user_id, api_token }: any = getAuth();

  const newUrl = UPLOAD_CSV_URL + "?userid=" + user_id;
  var myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${api_token}`)

  var requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  };

  return fetch(newUrl, requestOptions)
    .then(response => response.json())
    .then(result => result)
}


export function submitCompanyProfile(data: Partial<ICompanyProfile>): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(COMPANY_PROFILE_URL, { ...data }).then((d: AxiosResponse) => d.data);
}

export function updateCompnayProfile(data: Partial<ICompanyProfile>): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.put(COMPANY_PROFILE_UPDATE, { ...data }).then((d: AxiosResponse) => d.data);
}

export function createNewEmployee(data: Partial<ICreateNewEmployeePostData>): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(CREATE_EMPLOYEE_URL, { ...data }).then((d: AxiosResponse) => d.data);
}

export function createNewDependent(data: Partial<ICreateNewEmployeePostData>): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(CREATE_DEPENDENT_URL, { ...data }).then((d: AxiosResponse) => d.data);
}

export function getAllEmployees(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.get(`${GET_ALL_EMPLOYEES_URL}/${user_id}`).then((d: AxiosResponse) => d.data);
}

export function getEmployeeDetails(_id: string): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.get(`${GET_EMPLOYEES_DETAILS_URL}/${_id}`).then((d: AxiosResponse) => d.data);
}

export function getCompleteEmployeeDetails(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.get(`${GET_COMPLETE_EMPLOYEES_DETAILS_URL}/${user_id}`).then((d: AxiosResponse) => d.data);
}

export function getInCompleteEmployeeDetails(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.get(`${GET_INCOMPLETE_EMPLOYEES_DETAILS_URL}/${user_id}`).then((d: AxiosResponse) => d.data);
}

export function getPaidEmployeeDetails(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.get(`${GET_PAID_EMPLOYEES_DETAILS_URL}/${user_id}`).then((d: AxiosResponse) => d.data);
}

export function getAmountOfSelectedEmployees(ids: string[]): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.post(`${GET_AMT_OF_SELECTED_EMPLOYEES_URL}`, {
    _ids: ids
  }).then((d: AxiosResponse) => d.data);
}
export function getEmployeeDependents(_id: string): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.get(`${GET_EMPLOYEES_DEPENDENTS_URL}/${_id}`).then((d: AxiosResponse) => d.data);
}

export function getDependentDetails(_id: string): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.get(`${GET_DEPENDENT_DETAILS_URL}/${_id}`).then((d: AxiosResponse) => d.data);
}

export function generateDependentCard(data: any): Promise<any> {
  return axiosWithToken.post(GENERATE_DEPENDENT_CARD_URL, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function getCompanyDetails(): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.get(`${GET_COMPANY_PROFILE_DETAILS}/${user_id}`).then((d: AxiosResponse) => d.data);
}

export function updateCompanyDetails(data: ICreateNewEmployeePostData): Promise<AxiosResponse<UserProfile>> {
  const { user_id, api_token }: any = getAuth();
  return axiosWithToken.post(`${COMPANY_PROFILE_UPDATE}`, {
    user_id: user_id,
    ...data,
  }).then((d: AxiosResponse) => d.data);
}

export function getAllCountries(): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(GET_ALL_COUNTRIES_URL).then((d: any) => d.data?.countries);
}

export function getAllCountryList(): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(GET_ALL_COUNTRIES_URL).then((d: any) => {
    let data = d.data?.countries;
    if (data) {
      return data && data.map((o: any) => ({
        value: o,
        label: o,
        icons: undefined,
        disabled: false,
      }))
    }
  });
}

export function getAllStates(country_name: string): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(GET_ALL_STATES_URL(country_name)).then((d: any) => d.data?.state);
}

export function getAllCities(state_name: string): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(GET_ALL_CITIES_URL(state_name)).then((d: any) => d.data?.city);
}

// getCurruncyRate : response 
// {
//   "date": "2023-09-30",
//   "ngn": 9.26359305
// }

export function getCurruncyRate(): Promise<any> {
  return axiosWithoutToken.get(CURRUNCY_CONVERSION_URL).then((d: AxiosResponse) => d.data.conversion_rate);
}

export function createNewPaymentRequest(data: IcreatePaymentPostData): Promise<any> {
  return axiosWithToken.post(CREATE_PAYMENT_URL, data).then((d: AxiosResponse) => d.data);
}

export function sendPaymentStatus(data: ISendPaymentStatusPostData): Promise<any> {
  return axiosWithToken.post(SEND_PAYMENT_STATUS_URL, data).then((d: AxiosResponse) => d.data);
}

export function employeeDeleteRequest(data: any, id: string): Promise<any> {
  return axiosWithToken.post(DELETE_EMPLOYEE_URL(id), data).then((d: AxiosResponse) => d.data)
}

export function contactUsDetails(data: any): Promise<any> {
  return axiosWithToken.post(CONTACT_US_POST_DETAILS, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);;
}

export function employeeDelete(data: any): Promise<any> {
  return axiosWithToken.post(DELETE_DIRECT_EMPLOYEE_URL, data).then((d: AxiosResponse) => d.data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);;
}

export function getCardDetails(id: string): Promise<any> {
  return axiosWithToken.get(`${GET_CARD_DETAILS_URL}/${id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getDependentCardDetails(id: string): Promise<any> {
  return axiosWithToken.get(`${GET_DEPENDENT_CARD_DETAILS_URL}/${id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function updateDependentDetails(data: any): Promise<any> {
  return axiosWithToken.put(`${UPDATE_DEPENDENT_DETAILS_URL}/`, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getProfilePic({ userid, _id }: { userid: string, _id: string }): Promise<any> {
  return axiosWithToken.post(GET_PROFILE_PIC, { userid, _id }).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getDependentProfilePic({ employee_id, _id }: { employee_id: string, _id: string }): Promise<any> {
  return axiosWithToken.post(GET_DEPENDENT_PROFILE_PIC, { employee_id, _id }).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}


export function getPaymentLists(id: string): Promise<any> {
  return axiosWithToken.get(`${GET_PAYMENT_LIST_URL}/${id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getPaymentDetail(id: string): Promise<any> {
  return axiosWithToken.get(`${GET_TRANSACTION_URL}/${id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getQuotaSummury(): Promise<any> {
  const user = getAuth()
  return axiosWithToken.get(`${GET_QUOTA_SUMMURY_URL}/${user?.user_id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function updateEmployeeProfile(data: any): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.put(UPDATE_EMPLOYEES_DETAILS_URL, { ...data }).then((d: AxiosResponse) => d.data);
}

export function completeEmployeeProfileApi(data: any): Promise<AxiosResponse<UserProfile>> {
  return axiosWithToken.put(COMPLETE_EMPLOYEES_PROFILE_URL, { ...data }).then((d: AxiosResponse) => d.data);
}

export function getProfileUrl(data: any): Promise<any> {
  return axiosWithToken.post(`${GET_PROFILE_URL}`, {
    "userid": data.id,
    "_id": data.document_id
  }).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function checkIsValidEmail(data: any): Promise<any> {
  return axiosWithoutToken.post(VERIFY_EMAIL_URL, data).then((d: AxiosResponse) => d.data);
}

export function forgotPasswordRequest(data: any): Promise<any> {
  return axiosWithoutToken.post(FORGOT_PASSWORD_REQUEST_URL, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function verifyEmailByTokenRequest(data: any): Promise<any> {
  return axiosWithoutToken.post(VERIFY_EMAIL_BY_TOKEN_URL, data).then((d: AxiosResponse) => d.data);
}

export function verifyRcNumberByTokenRequest(rcno: string): Promise<any> {
  return axiosWithoutToken.get(`${VERIFY_RC_NUMBER_BY_TOKEN_URL}/${rcno}`).then((d: AxiosResponse) => d.data);
}

export function resendVerifyEmailByTokenRequest(data: any): Promise<any> {
  return axiosWithoutToken.post(RESEND_VERIFY_EMAIL_BY_TOKEN_URL, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}


export function getCompanyDetailsByRCNumber(rc_number: RegExp = /[^0-9]+/g) {
  // /console.log({ "secretKey": process.env.REACT_APP_ECITIBIZ_SECRET_KEY });
  return axios.get(`${BASE_URL}/verifiedrcnumber=${rc_number}`, {
    headers: {
      "SecretKey": process.env.REACT_APP_ECITIBIZ_SECRET_KEY
    }
  }).then((d: AxiosResponse) => d.data);
}


// Quota 
export function createQuota(data: ICreateQuotaPostData): Promise<IApiResponse> {
  return axiosWithToken.post(QUOTA_CREATE_URL, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function getAllQuotas(rc_number: string, status: "Active" | "Expired"): Promise<IApiResponse> {
  return axiosWithToken.get(`${QUOTA_LIST_URL}?status=${status}&rcnumber=${rc_number}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function getDDQuotaPositionsList(rc_number: string): Promise<IApiResponse> {
  return axiosWithToken.get(`${DD_QUOTA_POSITIONS_LIST_URL}/${rc_number}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function getQuotaDetails(id: string): Promise<IApiResponse> {
  return axiosWithToken.get(`${GET_QUOPTA_DETAILS_URL}/${id}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}

export function generateRRR(data: {
  "employeeamount_id": string | undefined
  paymentmode: string | undefined
  bankname: string | undefined,
  employees: any,
  employeecount: number
}): Promise<any> {
  return axiosWithToken.post(`${GENEREATE_RRR_URL}`, data).then((d: AxiosResponse) => d.data.fetchData);
}

export function getPaymentStatus(rrr: string): Promise<any> {
  return axiosWithToken.get(`${GET_PAYMENT_STATUS}/${rrr}`).then((d: AxiosResponse) => d.data);
}

export function checkPaymentStatusApi(data: {
  "status": string, "_ids": string[], "rrr": string
}): Promise<any> {
  return axiosWithToken.post(`${URL_CHECK_PAYMENT_STATUS}`, data).then((d: AxiosResponse) => d.data);
}


export function makePaymeny(postData: any, headerKey: any): Promise<any> {
  const PAYMENT_URL = process.env.REACT_APP_PAYMENT_BASE_URL + "" + process.env.REACT_APP_PAYMENT_MAKE_PAYMENT_URL;
  return axios.post(PAYMENT_URL, postData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: headerKey
    }
  }).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e);
}


export function checkEmailVerificationTokenStatusApi(token: string): Promise<any> {
  return axios.get(`${CHECK_EMAIL_VERFICATION_TOKEN_STATUS_URL}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((d: AxiosResponse) => d.data.data);
}


export function validateEmployeeEmailApi(email: string): Promise<any> {
  return axiosWithToken.get(`${VALIDATE_EMEPLOYEE_EMAIL_URL}/${email}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function validateEmployeeCERPACApi(cerpac_no: string): Promise<any> {
  return axiosWithToken.get(`${VALIDATE_EMEPLOYEE_CERPAC_URL}/${cerpac_no}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}

export function validateEmployeePassportApi(passport_no: string): Promise<any> {
  return axiosWithToken.get(`${VALIDATE_EMEPLOYEE_PASSPORT_URL}/${passport_no}`).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}


export function enquiryRequestApi(data: any): Promise<any> {
  return axios.post(`${ENQUIY_REQUEST_URL}`, data).then((d: AxiosResponse) => d.data).catch((e: AxiosError) => e.response?.data);
}


export function getCompnayKycStatus(): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(COMPANY_KYC_STATUS_URL).then((d: AxiosResponse) => d.data);
}

/// change Representative Request
export function changeRepresentativeEmailValidationApi(data: any): Promise<any> {
  return axiosWithoutToken.post(`${CHANGE_REPRENTATIVE_EMAIL_VALIDATION_URL}`, data).then((d: AxiosResponse) => d.data)
}

export function changeRepresentativeRequestFormSubmitApi(data: any): Promise<any> {
  return axiosWithoutToken.post(`${CHANGE_REPRENTATIVE_FORM_SUBMIT_URL}`, data).then((d: AxiosResponse) => d.data)
}

export function getAllEmptyCardRequestApi(): Promise<AxiosResponse<any>> {
  return axiosWithToken.get(GET_ALL_EMPTY_CARDS_REQUESTS_URL).then((d: AxiosResponse) => d.data);
}

// CARD -PRINTING REQUEST -API
export function createCardPrintingRequestApi(data: any): Promise<any> {
  return axiosWithToken.post(`${CREATE_CARD_PRINTING_REQUEST_URL}`, data).then((d: AxiosResponse) => d.data)
}

export function getAllCardUnAssignedEmployeesRequestApi(query: any): Promise<any> {
  return axiosWithToken.get(`${GET_ALL_CARD_UN_ASSIGNED_EMPLOYEES}?${query}`).then((d: AxiosResponse) => d.data)
}

export function assignEmployeeToEMptyCardRequestApi(data: any): Promise<any> {
  return axiosWithToken.post(`${ASSIGN_EMPLOYEE_TO_EMPTY_CARD_URL}`,data).then((d: AxiosResponse) => d.data)
}

export function quotaUpdateRequestApi(data: any): Promise<any> {
  return axiosWithToken.put(`${QUOTA_UPDATE_URL}`,data).then((d: AxiosResponse) => d.data)
}

export function exemptRequestApi(data: any): Promise<any> {
  return axiosWithToken.post(`${EXEMPT_REQUEST_URL}`,data).then((d: AxiosResponse) => d.data)
}