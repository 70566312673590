import { IdCardIcon, DotsVerticalIcon } from "@radix-ui/react-icons";
import axios, { AxiosError } from "axios";
import { Loader2 } from "lucide-react";
import { ChangeEvent, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "src/Helpers";
import { SVGC } from "src/Helpers/SVGC";
import StepCircle from "src/Modules/Auth/Regsitration/Components/StepCircle";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Input } from "src/components/ui/Input";
import { Label } from "src/components/ui/Label";
import ListActionsDropdowns, {
  ITableActionMenuOptions,
} from "src/components/ui/ListActionsDropdowns";
import TooltipComponent from "src/components/ui/NewYork/TooltipComponent";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  employeeDelete,
  employeeDeleteRequest,
  uploadfile,
} from "src/services/services";
import * as z from "zod";

type Props = {
  row: any;
};

const Actions = ({ row }: Props) => {
  const { original } = row;
  const navigate = useNavigate();



  const prepareOptions: ITableActionMenuOptions[] = useMemo(() => {
    let opt: ITableActionMenuOptions[] = [
      // {
      //   icon: (
      //     <SVGC
      //       path={toAbsoluteUrl("/media/icons/svg/trash.svg")}
      //       className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
      //     ></SVGC>
      //   ),
      //   onClick: () => setopenDeleteModal(true),
      //   title: "Delete",
      // },
      {
        icon: (
          <SVGC
            path={toAbsoluteUrl("/media/icons/svg/eye.svg")}
            className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
          ></SVGC>
        ),
        onClick: () => {
          if (original?._id) {
            navigate(`/quota/${original?._id}`);
          }
        },
        title: "View",
      },
    ];

    return opt;
  }, [original]);

  return (
    <div>
       <ListActionsDropdowns title="Action"  options={prepareOptions} />
    </div>
  );
};

export default Actions;
