import { CaretSortIcon } from "@radix-ui/react-icons";
import { Search } from "lucide-react";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { optionsType } from "src/Helpers";
import VirtualCommandItems from "src/components/Common/Vertualize/VirtualCommandItems";
import { cn } from "src/lib/utils";
import { Button } from "../button";
import { FormControl, FormItem, FormMessage } from "../form";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import RenderSelectedValue from "./RenderSelectedValue";
import { Label } from "@radix-ui/react-label";

type Props = {
  formik: any
  placeholder?: string;
  label?: string;
  nameKey: string;
  lableClass?: string;
  value?: string;
  options?: optionsType[] | undefined;
  onchange: (e: any) => void;
  required?: boolean
  disabled?: boolean
  displayCode?: boolean;
};

const FormikSearchDropdown = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  value,
  options = ["type1", "type2"].map((o: any) => ({ label: o, value: o })),
  onchange,
  required,
  disabled,
  displayCode,
}: Props) => {
  // console.log({ value });

  const [search, setsearch] = useState("");

  const popupref = useRef<any>(null);

  const newData = useMemo(
    () =>
      !!search
        ? options && options.filter((o: any) => {
          if (displayCode) {
            return o.label.includes(search.toUpperCase())
          } else {
            return o.value.includes(search)
          }
        })
        : options,
    [search, options, displayCode]
  );

  return (
    <>
      <Label className={cn(`${required ? "required" : ""}`)}>{label}</Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            key={nameKey}
            ref={popupref}
            disabled={disabled}
            variant="outline"
            role="combobox"
            className={cn(
              "w-auto justify-between px-2",
              !value && "text-muted-foreground"
            )}
          >
            <div className="">
              {/* {value && value
                    ?
                    : `Select ${label || placeholder}`} */}

              <RenderSelectedValue
                value={value}
                options={options}
                label={label}
                placeholder={placeholder}
              />
            </div>
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
        // className={cn(
        //   "relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        // )}
        >
          <div
            className="flex items-center border-b px-3"
            cmdk-input-wrapper=""
          >
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              type="text"
              className="focus:outline-none hover:outline-none py-2 text-xs w-full my-2 "
              placeholder="Search"
              value={search ?? value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setsearch(e.target.value)
              }}
            />
          </div>
          <VirtualCommandItems
            data={newData}
            qa-data={nameKey}
            onchange={(e: any) => {
              let newText = e.replace(/\D+/g, "");
              onchange && onchange(newText);
              setsearch("");
              popupref.current?.click();
            }}
            value={value}
          />
        </PopoverContent>
      </Popover>
      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive")}>
          {formik.errors[nameKey]}
        </p>
      )}
    </>
  );
};

export default FormikSearchDropdown;
