import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { createNewEmployee, createNewPaymentRequest, getAllEmployees, getEmployeeDetails, getPaymentLists, resendVerifyEmailByTokenRequest, sendPaymentStatus, verifyEmailByTokenRequest } from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'

// Define a type for the slice state
interface IEmailVerificationReducer {
    token: string | undefined,
    isLoading: boolean,
    isError: boolean,
    messsage: string | undefined,
    status: number,
    resend: boolean
}

// Define the initial state using that type
const initialState: IEmailVerificationReducer = {
    token: undefined,
    isLoading: false,
    isError: false,
    messsage: undefined,
    status: 0,
    resend: false
}


export const verifyEmailByTokenAsync: any = createAsyncThunk(
    'emailVerify/verify',
    async (token: string, thunkApi) => {
        try {

            const response: any = await verifyEmailByTokenRequest({
                token
            });
            // console.log({ response })
            if (response.status == 200) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                }));
                return thunkApi.rejectWithValue({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                });
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const resendVerifyEmailByTokenAsync: any = createAsyncThunk(
    'emailVerify/re-verify',
    async (token: string, thunkApi) => {
        try {
            thunkApi.dispatch(setResend());
            const response: any = await resendVerifyEmailByTokenRequest({
                token
            });
            // console.log({ response })
            if (response.status == 200) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                }));
                return thunkApi.rejectWithValue({
                    message: error.response?.data.message,
                    status: error.response?.data?.status
                });
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const emailVerifySlicer = createSlice({
    name: 'emailVerify',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.messsage = action.payload.message;
            state.status = action.payload.status
        },
        setResend: (state) => {
            state.resend = true;
        },
        resetState: (state) => {
            state = initialState
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(verifyEmailByTokenAsync.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = true;
                state.isError = false;
                state.token = undefined;
                state.messsage = undefined;
                state.status = 0;
            })
            .addCase(verifyEmailByTokenAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.token = undefined;
                state.status = action.payload?.status;
                state.messsage = action.payload?.message
            })
            .addCase(verifyEmailByTokenAsync.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.token = undefined;
                state.messsage = action.payload.message;
                state.status = action.payload.status;
            })
            .addCase(resendVerifyEmailByTokenAsync.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = true;
                state.isError = false;
                state.token = undefined;
                state.messsage = undefined;
                state.status = 0;
            })
            .addCase(resendVerifyEmailByTokenAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = false;
                state.token = undefined;
                state.status = action.payload?.status;
                state.messsage = action.payload?.message
            })
            .addCase(resendVerifyEmailByTokenAsync.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isError = true;
                state.token = undefined;
                state.messsage = action.payload.message;
                state.status = action.payload.status;
            })
    }
})

export const {
    setError, setResend, resetState
} = emailVerifySlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const employees = (state: RootState) => state.global

export default emailVerifySlicer.reducer