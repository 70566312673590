import RenderFormikFiled from 'src/components/Common/RenderFormikFiled';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card';
import { cn } from 'src/lib/utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { resetError, resetState, setNewPasswordAsync } from 'src/Silcers/setNewPasswordSlicer';
import ErrorDialog from 'src/components/ErrorDialog';
import { Button } from 'src/components/ui/button';
import { RootState } from 'src/store';
import PageHeadingComponent from 'src/components/Common/PageHeadingComponent';
import { SET_NEW_PASSWORD_SCHEMA, SetNewPasswordFileds } from './module';
import SuccessDialog from 'src/components/SuccessDialog';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { setPasswordAsync } from 'src/Silcers/forgotPasswordSlicer';
import { IRequestUsestate } from 'src/types';
import { forgotPasswordAfterLogin } from 'src/services/services';
import { getLoginReducerState } from 'src/Silcers/loginSlicer';
import axios, { AxiosError } from 'axios';
import { removeAuth, useAuth } from '../../Core';
import LoadingDialog from 'src/components/LoadingDialog';

const UpdateNewPassword = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state: RootState) => getLoginReducerState(state))
    const { logout } = useAuth()
    const [requestState, setrequestState] = useState<IRequestUsestate>({
        isError: false,
        isLoading: false,
        message: "",
        status: undefined,
        data: undefined
    })

    const formik = useFormik({
        initialValues: {
            old_password: "",
            password: "",
            confirm_password: "",
        },
        validationSchema: SET_NEW_PASSWORD_SCHEMA,
        onSubmit(values) {
            handlePaswordReset(values)
        },
    })

    const handlePaswordReset = async (values: any) => {

        try {
            setrequestState({ ...requestState, isLoading: true, isError: false, message: "", status: undefined })
            const apiResp = await forgotPasswordAfterLogin({
                "businessEmail": user.businessEmail,
                "oldpassword": values?.old_password,
                "newpassword": values.confirm_password,
            })
            if (apiResp?.status == 200) {
                setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: apiResp?.status })
            }

        } catch (err) {
            const error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error.response?.data?.message, status: undefined })
            }
            else {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error?.message, status: undefined })
            }
        }

    }

    return (
        <div className=' flex justify-center items-center h-[85vh] bg-secondary '>
            <div className={cn("grid gap-6 w-1/4")}>
                <form onSubmit={formik.handleSubmit}>
                    <Card className="my-8 rounded-none border-none">
                        <CardHeader className="space-y-1">
                            <CardTitle className="text-2xl text-primary uppercase">
                                <PageHeadingComponent mainContent='Reset Password' />
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="grid gap-2">
                            {requestState.isError && (
                                <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
                                    <span className="font-bold text-destructive">{requestState.message}</span>
                                </div>
                            )}

                            <RenderFormikFiled
                                formik={formik}
                                formObject={SetNewPasswordFileds.old_password}
                                required={true}
                                key={SetNewPasswordFileds.old_password.key}
                                onchange={(e: any) =>
                                    formik.setFieldValue("old_password", e)
                                }
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={SetNewPasswordFileds.password}
                                required={true}
                                key={SetNewPasswordFileds.password.key}
                                onchange={(e: any) =>
                                    formik.setFieldValue("password", e)
                                }
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={SetNewPasswordFileds.confirm_password}
                                required={true}
                                key={SetNewPasswordFileds.confirm_password.key}
                                onchange={(e: any) =>
                                    formik.setFieldValue("confirm_password", e)
                                }
                            />
                        </CardContent>
                        <CardFooter>
                            <div className="flex flex-col justify-center w-full">
                                <Button disabled={requestState.isLoading} className="w-full">Submit</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </form>
            </div>
            <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={() => setrequestState({ ...requestState, isError: false, message: "" })} />
            <SuccessDialog isOpen={requestState.status == 200} onClickOkBtn={() => {
                removeAuth();
                navigate("/auth");
                window.location.reload();
            }} title='Password Changed Successfully' >
                <div></div>
            </SuccessDialog>
            <LoadingDialog isOpen={requestState.isLoading} />
        </div>
    )
}

export default UpdateNewPassword