import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import { Button } from "../../../components/ui/button";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../../../components/ui/card";
import { cn } from "../../../lib/utils";
import { useAuth } from "../Core";
// @ts-ignore
// import ClientCaptcha from "src/Modules/ReactCaptcha";
import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import ErrorDialog from "src/components/ErrorDialog";
import SuccessDialog from "src/components/SuccessDialog";
import { enquiryRequestApi } from "src/services/services";
import { ENQUIRY_FORM_SCHEMA, IEnquiryForm, INIT_ENQUIRY_FORM, LoginFormFields } from "./modules";
import { Loader2 } from "lucide-react";
import { Label } from "src/components/ui/Label";
import FormikCountrycodePicker from "src/components/ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "src/components/ui/Phonenumber/FormikPhonenumber";
type Props = {};

interface EnquiryFormProps extends React.HTMLAttributes<HTMLDivElement> { }

const EnquiryForm = ({
    className,
    ...props
}: EnquiryFormProps) => {
    const { accessToken, user_id, user, error, errorMessasge, attempts, status } =
        useSelector((state: RootState) => state.login);
    const dispatch = useDispatch();
    const { saveAuth, setCurrentUser, currentUser } = useAuth();
    const [requestState, setrequestState] = React.useState<{
        isloading: boolean,
        isError: boolean,
        message: string,
        status: undefined | number
    }>({
        isloading: false,
        isError: false,
        message: "",
        status: undefined
    });

    const navigate = useNavigate();

    React.useEffect(() => {
        if (accessToken) {
            saveAuth({
                api_token: accessToken,
                refreshToken: "",
                user_id: user_id,
            });
        }
        return () => { };
    }, [accessToken]);

    React.useEffect(() => {
        if (user) {
            setCurrentUser(user);
            navigate("/");
        }
        return () => { };
    }, [user]);


    const formik = useFormik({
        initialValues: INIT_ENQUIRY_FORM,
        validationSchema: ENQUIRY_FORM_SCHEMA,
        onSubmit(values, formikHelpers) {
            handleSubmit(values)
        },
    })

    const handleSubmit = async (values: IEnquiryForm) => {

        try {
            setrequestState({ ...requestState, isloading: true, isError: false, message: "", status: undefined })
            const postData: any = {
                contact_number: values.contact,
                description: values.description,
                user_email: values.email,
                fullname: values.name,
                country_code: values.country_code
            };
            const apiResp = await enquiryRequestApi(postData)
            setrequestState({ ...requestState, isloading: false, status: apiResp?.status })

        }
        catch (err) {
            const error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                setrequestState({ ...requestState, isloading: false, isError: true, message: error.response?.data?.message, status: undefined })
            }
            else {
                setrequestState({ ...requestState, isloading: false, isError: true, message: error?.message, status: undefined })
            }
        }
    }


    return (
        <>
            {/* <Form {...form}> */}
            <div className={cn("", className)} {...props}>
                <form onSubmit={formik.handleSubmit}>
                    <Card className="rounded-3xl border-none bg-primary md:w-[450px] w-[350px]">
                        <CardHeader className="space-y-1">
                            <CardTitle className="text-2xl text-primary uppercase p-0 text-center text-white">
                                Enquiry EELMS
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="grid gap-2 gap-y-2">
                            {error && (
                                <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
                                    {status == 404 ? <span className="font-bold text-destructive">{errorMessasge}</span> : <span className="font-bold text-destructive">{errorMessasge && "Your email ID or password is incorrect "}</span>}

                                    {attempts && attempts > 0 && attempts < 3 ? (
                                        <>
                                            <br />
                                            <span className="text-xs font-light text-destructive ">
                                                You have {attempts} attempts left out of 3
                                            </span>
                                        </>
                                    ) : null}

                                    {status == 401 && attempts == 0 ? (
                                        <>
                                            <br />
                                            <span className="text-xs text-destructive ">
                                                you have completed 3 attempts please
                                                <span
                                                    className="text-blue font-semibold underline mx-2 cursor-pointer"
                                                    onClick={() => {
                                                        navigate("/forgot-validate")
                                                    }}
                                                >Click Link</span>
                                            </span>
                                        </>
                                    ) : null}

                                </div>
                            )}
                            <RenderFormikFiled
                                formik={formik}
                                formObject={LoginFormFields.name}
                                required
                                onchange={(e: any) => formik.setFieldValue("name", e)}
                            />
                            <div>
                                <Label className="required uppercase">
                                    Business Phone Number
                                </Label>
                                <div className="flex items-start">
                                    <FormikCountrycodePicker
                                        formik={formik}
                                        displayCode={true}
                                        // /value={formik.values.business_phone_country_code}
                                        nameKey={LoginFormFields.country_code.key}
                                        placeholder={
                                            LoginFormFields.country_code.placeholder
                                        }
                                        onchange={(e: any) => {
                                            formik.setFieldValue("country_code", e)
                                        }}
                                    />
                                    <FormikPhonenumber
                                        classname="grow"
                                        formik={formik}
                                        nameKey={LoginFormFields.contact.key}

                                        placeholder={
                                            LoginFormFields.contact.placeholder
                                        }
                                        error_className={"text-white"}
                                        onchange={(e: any) => {
                                            formik.setFieldValue("contact", e);
                                        }}
                                    />
                                </div>
                            </div>
                            <RenderFormikFiled
                                formik={formik}
                                formObject={LoginFormFields.email}
                                required
                                onchange={(e: any) => formik.setFieldValue("email", e)}
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={LoginFormFields.description}
                                required
                                onchange={(e: any) => formik.setFieldValue("description", e)}
                            />
                            <div className="flex flex-col justify-center w-full">
                                <Button disabled={requestState.isloading} className="w-full my-2 hover:bg-secondary bg-slate-100 text-primary rounded-3xl">
                                    {requestState.isloading && requestState.isloading && (
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    )}
                                    Submit
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </form>
            </div>
            <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={() => setrequestState({ ...requestState, isError: false, message: "" })} />
            <SuccessDialog isOpen={requestState.status == 200} title="Request Send" onClickOkBtn={() => {
                setrequestState({ ...requestState, status: undefined });
                formik.resetForm()
            }} >
                <p>Request sent successfully, our support team will contact you shortly.</p>
            </SuccessDialog>
            {/* </Form> */}
        </>
    );
};

export default EnquiryForm;
