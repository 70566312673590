import React, { useEffect, useMemo } from "react";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import EmployeeList from "./EmployeeList";
import { RootState } from "src/store";
import { useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "src/components/Common/NavBar";
import { formatAmt } from "src/Helpers";

export default function EmployeeTotalPay() {
  const navigate = useNavigate();
  const { selectedEmployee, toBeDeletedEmp,selectedEmployeesWithPayment } = useSelector(
    (state: RootState) => state.employees
  );

  useEffect(() => {
    if(!selectedEmployee || (selectedEmployee && selectedEmployee.length == 0)){
        navigate('/employee')
    }
    return () => { }
  }, [selectedEmployee])
  
  // const totalAmt = state
  // console.log(selectedEmployee, "slected emp", toBeDeletedEmp);

  const totalAmt = useMemo(
    () =>
      selectedEmployeesWithPayment && selectedEmployeesWithPayment.length > 0
        ? selectedEmployeesWithPayment?.reduce(function (
            acc: number,
            obj: any
          ) {
            // console.log(acc , obj.amount_niara)
            return acc + obj.amount_niara;
          },
          0)
        : 0,
    [selectedEmployeesWithPayment]
  );

  return (
    <div className="p-7">
      {/* <NavBar/> */}
      <div className=" grid grid-cols-8 gap-x-6">
        <div className="col-span-8 h-full border border-gray-600 rounded-lg p-7">
          <div className=" mb-4">
            <PageHeadingComponent
              mainContent="SELECTED Employee/EXPATRIATE"
              subContent={``}
              rightContent="USD to NAIRA conversion"
            />
          </div>
          <EmployeeList />
        </div>
      </div>
      <div className=" flex justify-between p-7 mt-4 bg-green-50">
        <div className="">
          <span className=" uppercase text-2xl font-medium">TOTAL payment</span>
          <span className=" uppercase text-primary mx-3 text-2xl font-medium">
            ₦ {totalAmt && formatAmt(totalAmt)}
          </span>
        </div>
        <div>
          <Button
            onClick={() => navigate(-1)}
            variant={"outline"}
            className=" px-20 text-primary"
          >
            Back
          </Button>
          <Link to="/employee-convert">
            <Button className="ml-4 px-24">Continue</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
