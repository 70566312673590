import React from 'react'

function TermsOfUse() {
    return (
        <div className="container ">
            <div className=" bg-white shadow-lg rounded-lg p-10 text-black mb-4 mt-4">
                <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>
                <p className="mb-4"><strong>Effective date:</strong> 24th September, 2023</p>

                <p>The Expatriate Employment Levy (EEL), referred to as "us," "we," or "our," operates the Mobile Web Service
                    (MWS) or Web Service (WS), which includes a suite of Personal Identity Services, the EEL Website
                    <a target='_blank' href='www.eel.interior.gov.ng' className=' text-primary'> www.eel.interior.gov.ng </a>(and the EEL Mobile application, collectively known as the "Service." This page
                    outlines our policies regarding the collection, use, and disclosure of personal data when you use the
                    Service, as well as the choices you have concerning that data. We utilize your data to deliver and enhance
                    the Service. By utilizing the Service, you agree to the collection and utilization of information in
                    accordance with this policy. Unless otherwise specified in this Privacy Policy, the terms used here in have
                    the same meanings as those in our Terms of use.</p>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Information Collection And Use</h2>

                    <p>We collect several different types of information for various purposes to provide and improve our
                        Service to you.</p>

                    <div className="mt-4">
                        <h3 className="text-lg font-bold mb-2">Types of Data Collected</h3>

                        <div className="ml-4">
                            <h4 className="text-md font-bold mb-2">Personal Data</h4>
                            <p>While using our Service, we may ask you to provide us with certain personally identifiable
                                information (PII) that can be used to contact or identify you ("Personal Data").</p>
                            <ul className="list-disc ml-8">
                                <li>Phone number</li>
                                <li>Email id</li>
                                <li>Location</li>
                                <li>Cookies and Usage Data</li>
                            </ul>
                        </div>

                        <div className="ml-4 mt-4">
                            <h4 className="text-md font-bold mb-2">Usage Data</h4>
                            <p>We may also collect information that your browser sends
                                whenever you visit our Service or when you access the Service by or through a mobile device ("UsageData").
                                This Usage Data may include information such as
                                your device’s Internet Protocol address (e.g. IP address),browser type, browser version, the type of the Service
                                that you downloaded (iOS, Android or Web App), thetimeand date of your visit, the time spent on thosepages. When you access the Service with a mobile
                                device, this Usage Data may include information such as the type of mobile device you use, your mobile device uniqueID, the IP address of your mobile device,
                                your mobile operating system, the type of mobile Internet browser you use,unique device identifiers and other diagnostic data.</p>
                        </div>

                        {/* <!-- Tracking & Cookies Data --> */}
                        <div className="ml-4 mt-4">
                            <h4 className="text-md font-bold mb-2">Tracking & Cookies Data</h4>
                            <p>
                                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored onyour device.Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improveand analyse our Service.
                                You can instruct your browser to refuse all cookiesortoindicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some
                                portions of our Service. You can learn more how to manage cookies in the <a target="_blank" href='https://www.privacypolicies.com/blog/how-to-delete-cookies/' className=' text-primary'>Browser Cookies Guide. </a>
                                Examples of Cookies we use:
                                <ul className="list-disc ml-8">
                                    <li><strong>Session Cookies:</strong> We use Session Cookies too perateour Service. </li>
                                    <li><strong>Preference Cookies:</strong> We use Preference Cookiestoremember your preferences and various settings.</li>
                                    <li><strong>Security cookies:</strong> We use Security Cookies for securitypurposes.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!-- Use of Data --> */}
                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Use of Data</h2>
                    <p>
                        EEL Mobile uses the collected data for various purposes:To provide and maintain the Service
                        <ul className="list-disc ml-8">
                            <li>To provide a secure platform to verify your identity to anauthorized entity</li>
                            <li>To keep a log of anyone who may have used this service to verify the identity of another</li>
                            <li>To notify you about changes to our Service</li>
                            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                            <li>To provide customer care and support</li>
                            <li>To provide analysis or valuable information so that we can improve the Service</li>
                            <li>To monitor the usage of the Service</li>
                            <li>To detect, prevent and address technical issues</li>
                        </ul>
                    </p>
                </section>


                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Transfer Of Data</h2>
                    <p>
                        Your information, including Personal Data (such as your email or Mobile Number), may be transferred to—and maintained on — computers located out side of yours tate, province , country or other governmental jurisdiction where the
                        data protection laws may differ than those from your jurisdiction.
                        If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data,
                        including Personal Data, to Nigeria and process it there.
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                        NIMC and the app developers will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there a read equate controls in place including the security of your data and other personal information.
                    </p>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold">Disclosure Of Data</h2>
                    <div className="mt-4 ml-4">
                        <section className="mt-8">
                            <h3 className="text-xl font-bold mb-4">Legal Requirements</h3>
                            <p>
                                EEL may disclose your personal data in the good faith belief that such action is necessary to:
                                <li>To comply with a legal obligation</li>
                                <li>To provide personal identity verification.</li>
                                <li>To protect and defend the rights or property of EEL and/or the <strong>Federal Government of Nigeria.</strong></li>
                                <li>To prevent or investigate possible wrong doing in connection with the Service</li>
                                <li>To protect the personal safety of users of theServiceorthe public</li>
                                <li>To protect against legal liability</li>
                            </p>
                        </section>

                        <section className="mt-8">
                            <h3 className="text-xl font-bold mb-4">Security Of Data</h3>
                            <p>
                                The security of your data is important to us, but remember that
                                no method of transmission over the Internet, or method of electronic storage is 100% secure.
                                While we strive to use commercially acceptable means to protect your Personal Data,
                                we cannot guarantee its absolute security.
                            </p>
                        </section>

                        <section className="mt-8">
                            <h3 className="text-xl font-bold mb-4">Service Providers</h3>
                            <p>
                                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the
                                Service on our behalf, to perform Service-related services or to assist us in analysing
                                how our Service is used. These third parties have access to your Personal Data only
                                to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                            </p>
                        </section>


                        <section className="mt-8">
                            <h3 className="text-xl font-bold mb-4">Links To Other Sites</h3>
                            <p>
                                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will
                                be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                                We have no control over and assume no responsibility for the content,
                                Terms of Use or practices of any third party sites or services.
                            </p>

                        </section>

                        <section className="mt-8">
                            <h3 className="text-xl font-bold mb-4">Changes To This Terms of Use</h3>
                            <p>
                                We may update our Terms of Use from time to time. We will notify you of any changes by posting the new Terms
                                of use on this page. We will let you know via email and/or a prominent notice on
                                our Service, prior to the change becoming effective and update the "effective date" at the top of
                                this Terms of Use. Changes to this Terms of Use are effective when they are posted on this page.
                            </p>
                        </section>
                        <section className="mt-8 mb-8">
                            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
                            <p>
                                If you have any questions about this Terms of Use, please contact us:<br />
                                <strong>By Email: </strong><a target="_blank" href={`mailto:contactus.eel@interior.gov.ng`}>
                        contactus.eel@interior.gov.ng
                  </a> <br />
                                <strong>Phone Number: </strong>+234-7080647200<br />
                            </p>
                        </section>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default TermsOfUse