import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import { Button } from 'src/components/ui/button'
import { INIT_UPDATE_COMPANY_KYC_DOCUMENT, documentTypeOptions, transform_to_form_keys, update_company_kyc_form_fileds, update_company_kyc_validation_schema } from './modules'
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled'
import LightBtn from 'src/components/Common/LightBtn'
import SubmitBtn from 'src/components/Common/SubmitBtn'
import FormikMUltiFIleUpload from 'src/components/ui/FileUploadInput/FormikMUltiFIleUpload'
import axios, { AxiosError } from 'axios'
import { IRequestUsestate } from 'src/types'
import { update_company_kyc_details_request_api } from 'src/services/services'
import { checkIfAnyFilesToBeUpload } from 'src/Helpers'
import LoadingDialog from 'src/components/LoadingDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import { cn } from 'src/lib/utils'
import { useDispatch } from 'react-redux'
import { fetchCompanyDetailsAsync } from 'src/Silcers/companySlicer'

type Props = {
    currentCompanyDetails: any
}

const UpdateCompanyKYC = ({ currentCompanyDetails }: Props) => {

    const [isOpen, setIsOpen] = useState<"show" | "hide">("hide")
    const [uploadingFileStatus, setuploadingFileStatus] = useState<{
        isLoading: boolean,
        isError: boolean,
        message: string | undefined
    }>({
        isLoading: false,
        isError: false,
        message: undefined
    });
    const [uploadedFileStatus, setuploadedFileStatus] = useState<{
        totalFiles: number,
        uploadedFiles: number,
    }>({
        totalFiles: 0,
        uploadedFiles: 0,
    });
    const dispatch = useDispatch()

    useEffect(() => {
        if (isOpen == "show") {
            formik.setFieldValue("documentType", "")
            formik.resetForm()
        }
        return () => { }
    }, [isOpen])

    const [requestState, setrequestState] = useState<IRequestUsestate>({
        isError: false,
        isLoading: false,
        message: "",
        status: undefined
    })


    const new_currentUserDetails = useMemo(() => Object.keys(transform_to_form_keys).reduce((acc: any, key) => {
        acc[key] = currentCompanyDetails[key as keyof object];
        acc["old_" + key] = currentCompanyDetails[key as keyof object];
        return acc;
    }, {}), [transform_to_form_keys, currentCompanyDetails])


    const initial_values = useMemo(() => {
        if (new_currentUserDetails) return new_currentUserDetails
        return INIT_UPDATE_COMPANY_KYC_DOCUMENT
    }, [new_currentUserDetails, INIT_UPDATE_COMPANY_KYC_DOCUMENT])

    const formik = useFormik({
        initialValues: INIT_UPDATE_COMPANY_KYC_DOCUMENT,
        validationSchema: update_company_kyc_validation_schema,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
        setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
        if (isError) {
            setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
            setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: undefined })
            return
        }
    };


    const handleSubmit = async () => {
        try {
            setrequestState({ ...requestState, isLoading: true, isError: false, message: "", status: undefined })
            const newdata = await checkIfAnyFilesToBeUpload(isAnyValueChanged, uploadProgress)
            console.log({ newdata });
            if (newdata) {
                const trasformkeys = Object.keys(transform_to_form_keys).reduce((acc: any, key) => {
                    if (isAnyValueChanged && Object.keys(isAnyValueChanged).includes(key)) {
                        acc[transform_to_form_keys[key as keyof object]] = newdata[key as keyof object];
                    }
                    return acc;
                }, {})

                const apiResp = await update_company_kyc_details_request_api({
                    _id: currentCompanyDetails._id,
                    ...trasformkeys
                })
                console.log({ apiResp })
                if (apiResp?.status == 200) {
                    setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: apiResp?.status })
                    setIsOpen("hide")
                    dispatch(fetchCompanyDetailsAsync());
                }
            }

        } catch (err) {
            const error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error.response?.data?.message, status: undefined })
            }
            else {
                setrequestState({ ...requestState, isLoading: false, isError: true, message: error?.message, status: undefined })
            }
        }
    }

    const { documentType, ...rest_form_fileds } = update_company_kyc_form_fileds;

    const isAnyValueChanged = useMemo(() => {

        const { _id, ...restUserDetails } = new_currentUserDetails;
        const { documentType, ...restFormDetails } = formik.values;

        return Object.keys(new_currentUserDetails).reduce((acc: any, key) => {
            if (restUserDetails[key] != formik.values[key as keyof object]) {
                if (!key.startsWith("old_")) {
                    acc[key] = formik.values[key as keyof object]
                }
            }
            return acc;
        }, {})

    }, [new_currentUserDetails, formik.values])

    const isDisabledSubmitBtn = useMemo(() => isAnyValueChanged && Object.keys(isAnyValueChanged).length == 0, [isAnyValueChanged])

    console.log({ values: formik.values });


    return (
        <div>
            <div className='flex justify-end'>
                <Button onClick={() => setIsOpen("show")} >Update KYC Document</Button>
            </div>
            <CustomeDialog headerTitle="Update KYC Document" isOpen={isOpen == "show"} hideDefaultCancle hideDefaultSubmit  >
                <form onSubmit={formik.handleSubmit} >
                    <div className='space-y-2 mb-4'>
                        <div><RenderFormikFiled formik={formik} options={documentTypeOptions} value={formik.values.documentType} formObject={documentType} onchange={(e: any) => {
                            formik.setValues(initial_values)
                            formik.setFieldValue("documentType", e)
                        }} /></div>
                        <>
                            {
                                rest_form_fileds && Object.entries(rest_form_fileds).map(([key, value]: [any, any]) => {
                                    if (value && value?.showIf && value?.showIf(formik)) {

                                        return <div className='flex flex-col '>
                                            <div><RenderFormikFiled formik={formik} value={formik.values[value.key as keyof object]} formObject={value} onchange={(e: any) => {
                                                formik.setFieldValue(value.key, e)
                                            }} /></div>
                                            <div className='flex justify-end'><span className='text-primary text-xs cursor-pointer underline underline-offset-1' onClick={() => formik.setFieldValue(value.key, formik.values["old_" + value.key as keyof object])} >Reset</span></div>
                                        </div>
                                        // }
                                        // return <div><RenderFormikFiled formik={formik} value={formik.values[value.key as keyof object]} formObject={value} onchange={(e: any) => formik.setFieldValue(value.key, e)} /></div>
                                    }
                                })
                            }
                        </>
                    </div>

                    <div className='flex justify-center space-x-4 mt-4' > <LightBtn title='Cancel' onclick={() => setIsOpen("hide")} /><SubmitBtn isDisabled={isDisabledSubmitBtn} title='Submit' isLoading={false} /> </div>
                </form>
            </CustomeDialog>
            <LoadingDialog isOpen={requestState.isLoading || uploadingFileStatus.isLoading} message='' >
                <div className="flex flex-col w-full space-y-2">
                    <div className="text-center">
                        {requestState.isLoading ? "Sending Requests" : null}
                        {uploadingFileStatus.isLoading ? "Uploading Files" : null}
                    </div>
                    {uploadingFileStatus.isLoading && <div className="flex space-x-1">
                        {
                            uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
                            })
                        }
                    </div>}
                </div>
            </LoadingDialog>
            <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={() => setrequestState({ ...requestState, isError: false, message: "", status: undefined })} />
            <ErrorDialog isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => setuploadingFileStatus({ ...requestState, isError: false, message: "" })} />
        </div>
    )
}

export default UpdateCompanyKYC