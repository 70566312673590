import { SVGC } from "src/Helpers/SVGC";
import { cn } from "../../lib/utils";
import * as React from "react";
import { toAbsoluteUrl } from "src/Helpers";
import { Info, Eye, EyeOff } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & { prefix?: string | undefined; sufix?: boolean, showInfoIcon?: boolean, infoContent?: string }
>(({ className, type, prefix, sufix, showInfoIcon = false, infoContent, ...props }, ref) => {


  const [inputType, setInputType] = React.useState<any>(type);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState<any>(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState: any) => !prevState);
    setInputType((prevType: any) => (prevType === 'password' ? 'text' : 'password'));
  };

  const EyeIcon: any = isPasswordVisible ? Eye : EyeOff;


  return (
    <>
      <div
        className={cn(
          "flex items-center h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      >
        {prefix ? <span className="me-2">{prefix}</span> : null}
        <input
          type={inputType}
          autoComplete="off"
          className={cn(
            "h-full w-full outline-none focus:outline-none",
            className
          )}
          ref={ref}
          {...props}
        />
        {sufix && sufix ? (
          <span>
            <SVGC path={toAbsoluteUrl("/media/icons/svg/tick-circle.svg")} />{" "}
          </span>
        ) : null}
        {type === 'password' && (
          <span className="cursor-pointer" onClick={togglePasswordVisibility}>
            <EyeIcon size={20} className="text-gray-400"  />
          </span>
        )}

      </div>
    </>
  );
});
Input.displayName = "Input";

export { Input };
