import { Outlet } from "react-router-dom";
import "./App.css";
import { AuthInit, removeAuth } from "./Modules/Auth/Core";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const message = 'Are you sure you want to leave EEL portal? You will be logged out.';
      event.returnValue = message;
      removeAuth()
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthInit>
          <Outlet />
        </AuthInit>
      </QueryClientProvider>
    </>
  );
}

export default App;
