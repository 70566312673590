import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AsyncThunkAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { CREATE_EMPLOYEE_INIT, IDependant, Icreate_employee } from 'src/Modules/EmpExpatriate/modules'
import { axiosWithToken } from 'src/services/axiosSetup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { completeEmployeeProfileApi, createNewEmployee, getAllEmployees, getEmployeeDependents, getEmployeeDetails, updateEmployeeProfile } from 'src/services/services'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { getAuth } from 'src/Modules/Auth/Core'
import { ICreateNewEmployeePostData } from 'src/types'
import { setNewEmployee } from './completeEmployeeProfileSlicer'
import { format_DDMMYYYY_To_Date, format_Date_To_DDMMYYYY } from '../Helpers'


export interface IDependantDetails extends IDependant {
    _id: string, employee_id: string
}


const defaultDependent = {
    error: false,
    errorMessage: undefined,
    isLoading: false,
    dependents: []
};

// Define a type for the slice state
interface IemployeeReducer {
    error: boolean,
    errorMessage: string | undefined
    isLoading: boolean
    employeeViewDetail: any | undefined,
    dependentState: {
        error: boolean,
        errorMessage: string | undefined
        isLoading: boolean
        dependents: IDependantDetails[] | undefined
    }
    status: number | undefined
}

// Define the initial state using that type
const initialState: IemployeeReducer = {
    error: false,
    errorMessage: undefined,
    isLoading: false,
    employeeViewDetail: undefined,
    dependentState: defaultDependent,
    status: undefined
}

export const fetchEmployeeDetailsAsync: any = createAsyncThunk(
    'employeeDetails/fetch',
    async (id: any, thunkApi: any) => {
        try {
            let employeeDetails = undefined;
            let dependents = undefined;

            let response: any = await getEmployeeDetails(id);
            // console.log({ response });


            if (response && response.status == 200) {
                let emp: any = response.data
                employeeDetails = {
                    given_name: emp['givenname'],
                    surname: emp['surname'],
                    mereg_number: emp['mereg_no'],
                    type_of_employee: emp['typeOfEmployee'],
                    // designation: emp['designation'],
                    country_of_origin: emp['countryorigin'],
                    nationality: emp['nationality'],
                    dob: format_DDMMYYYY_To_Date(emp['dob']),
                    sex: emp['sex'],
                    marital_status: emp['ismarried'],
                    passport_no: emp['passportnumber'],
                    passport_expiry: format_DDMMYYYY_To_Date(emp['passportexpiry']),
                    passport_doc: emp['uploadpassport']?.trim(),
                    cerpac_no: emp['cerpac_number'],
                    cerpac_expiry: format_DDMMYYYY_To_Date(emp['cerpac_expiry']),
                    cerpac_front_doc: emp['cerpac_front']?.trim(),
                    cerpac_back_doc: emp['cerpac_back']?.trim(),
                    visa_type: emp['visatype'],
                    visa_number: emp['visanumber'],
                    visa_expiry: format_DDMMYYYY_To_Date(emp['visaexpiracydate']),
                    visa_doc: emp['visa_document']?.trim(),
                    work_status: emp['workstatus'],
                    name_of_company: emp['comapnyname'],
                    employee_phone_number: emp['empnumber'],
                    employee_phone_code: emp['empcode'],
                    employee_email: emp['empemail'],
                    employee_address_1: emp['empadress1'],
                    employee_address_2: emp['empaddress2'],
                    payment_status: emp["paymentstatus"],
                    country: emp['country'],
                    city: emp['city'],
                    state: emp['state'],
                    zipcode: emp['zipcode'],
                    employee_profile_photo: emp['employee_profile_photo'],
                    _id: emp['_id'],
                    quota_position: emp["position"],
                    quota_expiry: emp["quota_expiry"],
                    quota_position_id: emp["quotaId"],
                    kyc_status:emp["kyc_status"]
                }

                if (emp?.dependent && emp?.dependent?.length > 0) {
                    dependents = emp?.dependent
                }

                thunkApi.dispatch(setNewEmployee(employeeDetails))

                return {
                    employeeDetails,
                    dependents
                };
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchEmployeeDependentsAsync: any = createAsyncThunk(
    'employeeDetails/employeeDependentsAsync',
    async (id: any, thunkApi: any) => {
        try {

            let response: any = await getEmployeeDependents(id);
            let employee_payment_status = thunkApi?.getState()?.employeeDetails?.employeeViewDetail?.payment_status || undefined
            // console.log({ getEmployeeDependentsResponse: response, state: employee_payment_status });

            if (response && response.status == 200) {

                let dependent = response.data;

                let transformedDependent: IDependantDetails[] = []

                if (dependent && Array.isArray(dependent)) {
                    transformedDependent = dependent && dependent.map((d: any) => {
                        return {
                            surname: d["surname"],
                            given_name: d["given_name"],
                            accompany_with: undefined,
                            relationship: d["relation"],
                            dob: format_DDMMYYYY_To_Date(d["date_of_birth"]),
                            sex: d["sex"],
                            passport_no: d["passportnumber"],
                            passport_expiry: format_DDMMYYYY_To_Date(d["passportexpiry"]),
                            passport_doc: d["passport_photo"],
                            cerpac_no: d["cerpac_number"],
                            cerpac_issue: undefined,
                            cerpac_expiry: format_DDMMYYYY_To_Date(d["cerpac_expiry_date"]),
                            // visa_type: d["visatype"],
                            // visa_number: d["visanumber"],
                            // visa_expiry: format_DDMMYYYY_To_Date(d["visa_expiry_date"]),
                            // visa_issue: d["visa_issue_date"],
                            dependent_profile_image: d["dependant_profile_photo"],
                            _id: d["_id"],
                            employee_id: d["employee_id"],
                            employee_payment_status: d["employee_payment_status"],
                            card_generated: d["card_generated"]
                        }
                    })
                }




                thunkApi.dispatch(setDependents(transformedDependent));
                return transformedDependent

            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const completeEmployeeProfileAsync: any = createAsyncThunk(
    "employeeDetails/completeEmployeeProfile",
    async (employeeData: ICreateNewEmployeePostData, thunkApi) => {
        try {
            const { user_id, api_token }: any = getAuth();
            let formData: any = employeeData;
            // Make the API call using Axios
            const postData = {
                employee_profile_photo: formData?.employee_profile_photo,
                surname: formData.surname,
                givenname: formData.given_name,
                mereg_no: formData.mereg_number,
                typeOfEmployee: formData.type_of_employee,
                designation: formData.quota_position,
                countryorigin: formData.country_of_origin,
                nationality: formData.nationality,
                dob: format_Date_To_DDMMYYYY(formData.dob),
                sex: formData.sex,
                ismarried: formData.marital_status,
                passportnumber: formData.passport_no,
                passportexpiry: format_Date_To_DDMMYYYY(formData.passport_expiry),
                uploadpassport: formData.passport_doc,
                cerpac_number: formData.cerpac_no || "",
                cerpac_expiry: format_Date_To_DDMMYYYY(formData.cerpac_expiry),
                cerpac_front: formData.cerpac_front_doc,
                cerpac_back: formData.cerpac_back_doc,
                visatype: formData.visa_type,
                visanumber: formData.visa_number,
                visaexpiracydate: format_Date_To_DDMMYYYY(formData.visa_expiry),
                visa_document: formData.visa_doc,
                comapnyname: formData.name_of_company,
                empnumber: formData.employee_phone_number?.toString(),
                empcode: formData.employee_phone_code,
                empemail: formData.employee_email,
                empadress1: formData.employee_address_1,
                empaddress2: formData.employee_address_2,
                country: formData.country,
                state: formData.state,
                city: formData.city,
                zipcode: formData.zipcode,
                dependent: formData.dependants,
                position: formData.quota_position,
                quota_expiry: formData.quota_expiry,
                quotaId: formData.quota_position_id,
                _id: formData.id,
                //userid: user_id,
            };
            // console.log({ user_id, api_token });

            const response: any = await completeEmployeeProfileApi(postData);
            if (response.status == 200) {
                // console.log({ updateemployee: response })
                return response;
            } else if ([401, 400, 500, 404, 403].includes(response.status)) {
                thunkApi.dispatch(setError(response.message));
                return thunkApi.rejectWithValue(response.message);
            }
            //   return response.data;
        } catch (_error) {
            // console.log("catch errorr");
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

// Create an async thunk for making the API call
export const updateEmployeeAsync: any = createAsyncThunk(
    "employeeDetails/updateEmployee",
    async (employeeData: ICreateNewEmployeePostData, thunkApi) => {
        try {
            const { user_id, api_token }: any = getAuth();
            let formData: any = employeeData;
            // Make the API call using Axios
            const postData = {
                employee_profile_photo: formData?.employee_profile_photo,
                surname: formData.surname,
                givenname: formData.given_name,
                mereg_no: formData.mereg_number,
                typeOfEmployee: formData.type_of_employee,
                designation: formData.quota_position,
                countryorigin: formData.country_of_origin,
                nationality: formData.nationality,
                dob: format_Date_To_DDMMYYYY(formData.dob),
                sex: formData.sex,
                ismarried: formData.marital_status,
                passportnumber: formData.passport_no,
                passportexpiry: format_Date_To_DDMMYYYY(formData.passport_expiry),
                uploadpassport: formData.passport_doc,
                cerpac_number: formData.cerpac_no || "",
                cerpac_expiry: format_Date_To_DDMMYYYY(formData.cerpac_expiry),
                cerpac_front: formData.cerpac_front_doc,
                cerpac_back: formData.cerpac_back_doc,
                visatype: formData.visa_type,
                visanumber: formData.visa_number,
                visaexpiracydate: format_Date_To_DDMMYYYY(formData.visa_expiry),
                visa_document: formData.visa_doc,
                comapnyname: formData.name_of_company,
                empnumber: formData.employee_phone_number?.toString(),
                empcode: formData.employee_phone_code,
                empemail: formData.employee_email,
                empadress1: formData.employee_address_1,
                empaddress2: formData.employee_address_2,
                country: formData.country,
                state: formData.state,
                city: formData.city,
                zipcode: formData.zipcode,
                dependent: formData.dependants,
                position: formData.quota_position,
                quota_expiry: formData.quota_expiry,
                quotaId: formData.quota_position_id,
                _id: formData.id,
                //userid: user_id,
            };
            // console.log({ user_id, api_token });

            const response: any = await updateEmployeeProfile(postData);
            if (response.status == 200) {
                // console.log({ updateemployee: response })
                return response;
            } else if ([401, 400, 500, 404, 403].includes(response.status)) {
                thunkApi.dispatch(setError(response.message));
                return thunkApi.rejectWithValue(response.message);
            }
            //   return response.data;
        } catch (_error) {
            // console.log("catch errorr");
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);



export const employeeDetailsSlicer = createSlice({
    name: 'employeeDetails',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.isLoading = false;
            state.errorMessage = action.payload
            state.status = undefined
        },
        resetParial: (state) => {
            state.error = false;
            state.errorMessage = undefined;
            state.isLoading = false;
            state.status = undefined
        },
        resetState: (state) => {
            state.error = false;
            state.errorMessage = undefined;
            state.isLoading = false;
            state.status = undefined
            state.employeeViewDetail = undefined
            state.dependentState = {
                error: false,
                errorMessage: undefined,
                isLoading: false,
                dependents: []
            }
        },
        setDependents: (state, action: PayloadAction<any>) => {
            state.dependentState.dependents = action.payload;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(fetchEmployeeDetailsAsync.pending, (state) => {
                // Handle pending state if needed
                state.isLoading = true;
                state.error = false;
                state.errorMessage = undefined
                state.status = undefined
            })
            .addCase(fetchEmployeeDetailsAsync.fulfilled, (state, action) => {
                // Update your state with the fetched employee data
                state.employeeViewDetail = action.payload?.employeeDetails;
                state.isLoading = false;
                state.status = undefined
            })
            .addCase(fetchEmployeeDetailsAsync.rejected, (state, action) => {
                // Handle rejected state if needed
                state.error = true;
                state.isLoading = false;
                state.errorMessage = action.payload;
                state.status = undefined
            }).addCase(updateEmployeeAsync.pending, (state) => {
                // Handle pending state if needed
                state.isLoading = true;
                state.error = false;
                state.errorMessage = undefined
            })
            .addCase(updateEmployeeAsync.fulfilled, (state, action) => {
                // Update your state with the fetched employee data
                //state.employeeViewDetail = action.payload?.employeeDetails;

                state.isLoading = false;
                state.status = 200
            })
            .addCase(updateEmployeeAsync.rejected, (state, action) => {
                // Handle rejected state if needed
                state.error = true;
                state.isLoading = false;
                state.errorMessage = action.payload;
                state.status = undefined
            })

            .addCase(fetchEmployeeDependentsAsync.pending, (state) => {
                // Handle pending state if needed
                state.dependentState.isLoading = true;
                state.dependentState.error = false;
                state.dependentState.errorMessage = undefined

            })
            .addCase(fetchEmployeeDependentsAsync.fulfilled, (state, action) => {
                // Update your state with the fetched employee data
                // console.log({ payload: action.payload });
                state.dependentState.dependents = action.payload
                state.dependentState.isLoading = false;

            })
            .addCase(fetchEmployeeDependentsAsync.rejected, (state, action) => {
                // Handle rejected state if needed
                state.dependentState.error = true;
                state.dependentState.isLoading = false;
                state.dependentState.errorMessage = action.payload;
                state.dependentState.dependents = []
            })
            .addCase(completeEmployeeProfileAsync.pending, (state) => {
                // Handle pending state if needed
                state.isLoading = true;
                state.error = false;
                state.errorMessage = undefined

            })
            .addCase(completeEmployeeProfileAsync.fulfilled, (state, action) => {
                // Update your state with the fetched employee data
                // console.log({ payload: action.payload });
                state.status = action.payload?.status || undefined
                state.isLoading = false;

            })
            .addCase(completeEmployeeProfileAsync.rejected, (state, action) => {
                // Handle rejected state if needed
                state.error = true;
                state.isLoading = false;
                state.errorMessage = action.payload;
            })
    }
})

export const {
    setError, resetState, resetParial, setDependents
} = employeeDetailsSlicer.actions

// // Other code such as selectors can use the imported `RootState` type
export const getEmployeeDetailsReducerState = (state: RootState) => state.employeeDetails

export default employeeDetailsSlicer.reducer