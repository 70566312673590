import { EXPATRIATE_TYPES_OPTIONS, EXPATRIATE_TYPES_OPTIONS_2, optionsType } from 'src/Helpers';
import * as yup from 'yup';

export interface positionsData {
    "position_name": string,
    "position_count": number | undefined
}

export interface IQuotaSetupForm {
    approvalNumber: string | undefined,
    expatriateType: string | undefined,
    expirationDate: string | undefined,
    totalSlotApproved: string | undefined,
    approvedDate: string | undefined,
    approvalLetterDate: string | undefined,
    expatriate_doc: File | undefined,
    additional_name: string | undefined,
    additional_doc: File | undefined,
    position: positionsData[] | undefined,
    availableQuota: string | undefined,
    companyName: string | undefined,
    RCNumber: String | undefined,
    _id?: string | undefined
}


export const QuotaSetupForm_INIT = {
    approvalNumber: undefined,
    expatriateType: undefined,
    expirationDate: undefined,
    totalSlotApproved: undefined,
    approvedDate: undefined,
    approvalLetterDate: undefined,
    expatriate_doc: undefined,
    additional_name: undefined,
    additional_doc: undefined,
    position: [],
    availableQuota: "0",
    companyName: undefined,
    RCNumber: undefined,
    _id: undefined
}

export const AddPosition_Schema = yup.object().shape({
    position_name: yup.string().required("Enter position name"),
    position_count: yup.string().required("Enter number of position").test('position_count', (value, validationcontext) => {
        const { createError, parent: { total_quota } } = validationcontext;
        // console.log({ total_quota });

        if (value && Number(total_quota) !== 0 && Number(value) > Number(total_quota)) {
            return createError({ message: "position count should not be more than Total Quota" });
        }
        if (value && Number(total_quota) === 0) {
            return createError({ message: "Available Quota empty." });
        }
        return true;
    }),
})

export const QuotaSetupForm_Schema = yup.object().shape({
    approvalNumber: yup.string().min(5, "Approval Number should be at least 5 characters").required("Enter approval number "),
    expatriateType: yup.string().required("Select Quota Document"),
    expirationDate: yup.string().required("Select expiration date").test('expirationDate', (value, { createError }) => {

        // console.log({ approvalLetterDate: value });
        var currentDate = new Date();

        if (value && new Date(value) <= currentDate) {
            return createError({ message: "Can't accept past date." });
        }
        return true;
    }),
    totalSlotApproved: yup.string().required("Enter total approved slots").test('totalSlotApproved', (value, { createError }) => {
        // console.log({ approvalLetterDate: value });
        if (value && Number(value) > 1000) {
            return createError({ message: "Total slots should not be more than 1000 " });
        }
        return true;
    }),
    approvedDate: yup.string().required("Select approved date").test('approvedDate', (value, { createError }) => {

        // console.log({ approvalLetterDate: value });
        var currentDate = new Date();
        if (value && new Date(value) > currentDate) {
            return createError({ message: "Can't accept future date. " });
        }
        return true;
    }),
    approvalLetterDate: yup.string().required("Select approval letter date").test('approvalLetterDate', (value, { createError }) => {

        // console.log({ approvalLetterDate: value });
        var currentDate = new Date();

        if (value && new Date(value) > currentDate) {
            return createError({ message: "Can't accept future date." });
        }
        return true;
    }),
    expatriate_doc: yup.mixed().required("Upload quota document").test('expatriate_doc', (value, { createError }) => {

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }
        return true;
    }),
    additional_name: yup.string().optional(),
    additional_doc: yup.mixed().optional().test('additional_doc', (value, { createError }) => {

        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }

        return true;
    }),
    position: yup.array().of(AddPosition_Schema).test('position', (value, { createError, parent }) => {

        const { availableQuota, totalSlotApproved } = parent;
        // console.log({ additional_doc });

        if (totalSlotApproved && value && Array.isArray(value)) {
            if (value.length > 0) {
                if (Number(availableQuota) != 0) {
                    return createError({ message: "Total positions and total approved slots should be equal" });
                }
            }
            else return createError({ message: "Please add quota positions" });
        }

        return true;
    }),
    companyName: yup.string().required(),
    RCNumber: yup.string().required(),
})

export const QuotaSetupFormFileds = {

    approvalNumber: {
        key: "approvalNumber",
        label: "Approval Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "Approval Number",
        regex: /[^a-zA-Z0-9-./,]+/g,
        maxLength: 15,
        showInfoIcon:true,
        infoContent:"Approval number is the number of quota approval letters approved by the Ministry of Interior"
    },
    expatriateType: {
        key: "expatriateType",
        label: "Quota Document Type",
        field_type: "options_with_search",
        options: EXPATRIATE_TYPES_OPTIONS_2,
        placeholder: "Quota Type",
        optionsWidth: 500,
        showInfoIcon:true,
        infoContent:"Select where the recent quota approval letter is new additional, Renewal or Quota position Grant."
    },
    expirationDate: {
        key: "expirationDate",
        label: "Expiration Date",
        field_type: "date",
        placeholder: "Expiration Date",
        showInfoIcon:true,
        infoContent:"The expiration date is the quota expiry date as per the approval letter. This should be calculated from the w.e.f. date in the quota approval file."
    },
    totalSlotApproved: {
        key: "totalSlotApproved",
        label: "Total Slot Approved",
        field_type: "text",
        field_mode: "text",
        placeholder: "Total Slot Approved",
        regex: /[^0-9]+/g,
        showInfoIcon:true,
        infoContent:"The number of Quota positions approved in the attached document."
       
    },
    approvedDate: {
        key: "approvedDate",
        label: "Approved Date",
        field_type: "date",
        placeholder: "Approved Date",
        showInfoIcon:true,
        infoContent:"Approved Date is the date of Quota Validity Starts"
    },
    approvalLetterDate: {
        key: "approvalLetterDate",
        label: "Approval Letter Date",
        field_type: "date",
        placeholder: "Approval Letter Date",
        showInfoIcon:true,
        infoContent:"Date of Quota approval letter."
    },
    expatriate_doc: {
        key: 'expatriate_doc',
        label: 'EXPATRIATE QUOTA POSITIONS LETTER',
        field_type: 'file',
        field_mode: '',
        placeholder: 'UPLOAD Attachment 1'
    },
    additional_name: {
        key: "additional_name",
        label: "",
        field_type: "text",
        field_mode: "text",
        placeholder: "Enter Document Name",
        regex: /[^A-z0-9 ]+/g,
        description: "Other additional document related to Quota approval or Quota return certificate."
    },
    additional_doc: {
        key: 'additional_doc',
        label: 'Additional Doc',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Additional Document'
    },
    position: {
        key: "position",
        label: "Add Position",
        field_type: "text",
        placeholder: "Position",
        regex: /[^A-z- ]+/g,
    },
    companyName: {
        key: "companyName",
        label: "Name of Company",
        field_type: "text",
        field_mode: "text",
        placeholder: "Company Name",
        readonly: true
    },
    RCNumber: {
        key: "RCNumber",
        label: "RC Number",
        field_type: "text",
        field_mode: "text",
        placeholder: "RC Number",
        readonly: true
    }
}

interface IAddPosition {
    position_name: string | undefined,
    position_count: number | undefined,
    total_quota: number | undefined
}

export const AddPosition_INIT = {
    position_name: "",
    position_count: undefined,
    total_quota: 10
}



export const AddPosition_Fields = {

    position_name: {
        key: "position_name",
        label: "Position Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Position Name",
        regex: /[^A-z /-]+/g,
    },
    position_count: {
        key: "position_count",
        label: "Positions (#)",
        field_type: "text",
        field_mode: "text",
        placeholder: "Position Count",
        regex: /[^0-9]+/g,
    },

}


export const tabelHeaders = ["Sr.No", "Designation", "Positions (#)", "Action"]
export const tabelHeadersForViewQuotaDetails = ["Sr.No", "Position", "Slot Approved (#)", "Utillized Slots (#)"]
export const tableData: positionsData[] = []

export interface IQuotaDetailsViewPositions {
    Position: string | undefined,
    SlotApproved: string | undefined,
    quotaId: string | undefined,
    utillizedslots: string | undefined,
    _id: string | undefined,
}

export interface IQuotaDetailsView {
    approvalNumber: string | undefined,
    expatriateType: string | undefined,
    expirationDate: string | undefined,
    totalSlotApproved: string | undefined,
    approvedDate: string | undefined,
    approvalLetterDate: string | undefined,
    expatriate_doc: string | undefined,
    additional_name: string | undefined,
    additional_doc: string | undefined,
    position: IQuotaDetailsViewPositions[] | undefined,
    availableQuota: string | undefined,
    companyName: string | undefined,
    RCNumber: String | undefined
}

export const QuotaDetailsViewInit = {
    approvalNumber: "",
    expatriateType: "",
    expirationDate: "",
    totalSlotApproved: "",
    approvedDate: "",
    approvalLetterDate: "",
    expatriate_doc: "",
    additional_name: "",
    additional_doc: "",
    position: [],
    availableQuota: "",
    companyName: "",
    RCNumber: ""
}

