import React from 'react'
import AddNewQuota from './AddNewQuota'
import QuotaTypesFilter from './QuotaTypesFilter'

type Props = {}

const FiltersAndActions = (props: Props) => {
  return (
    <div className='flex justify-between'>
      <QuotaTypesFilter />
      <AddNewQuota />
    </div>
  )
}

export default FiltersAndActions