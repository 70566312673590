import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setSelectedEmployee } from 'src/Silcers/employeesSlicer';
import { Badge } from "src/components/ui/badge";
import UpdateQuota from './UpdateQuota/UpdateQuota';


const checkQuotaExpired = (quota_expiry: string) => {
  if (quota_expiry && quota_expiry.includes('/')) {
    let splitQuotaExpiry = quota_expiry.split("/");
    let [dd, mm, yyyy] = splitQuotaExpiry;
    let newDateOfQuotaExpiry = new Date(splitQuotaExpiry.reverse().join("-"))
    //console.log({ quota_expiry, splitQuotaExpiry, newDateOfQuotaExpiry, isQuotaExpired: newDateOfQuotaExpiry < new Date() });
    return newDateOfQuotaExpiry < new Date()
  }
  return false;
}

const checkCardExpired = (card_expiry: string) => {
  if (card_expiry && card_expiry.includes('/')) {
    let splitCardExpiry = card_expiry.split("/");
    let [dd, mm, yyyy] = splitCardExpiry;
    let newDateOfCardExpiry = new Date(splitCardExpiry.reverse().join("-"))
    //console.log({ quota_expiry, splitCardExpiry, newDateOfCardExpiry, isQuotaExpired: newDateOfCardExpiry < new Date() });
    return newDateOfCardExpiry < new Date()
  }
  return false;
}


function Batch({ row }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  return (
    <div className="flex ">

      {row.original.paymentstatus == "complete" ? <>
        {row.original.card_expiry && checkCardExpired(row.original.card_expiry) ?
          <>
            {row.original.quota_expiry && checkQuotaExpired(row.original.quota_expiry) ?
              <>
                <UpdateQuota rowData={row.original} />
              </> : <>
                <Badge
                  onClick={() => {
                    let data: any = row.original;
                    dispatch(setSelectedEmployee([data]));
                    navigate('/employee-total')
                  }}
                  className=" cursor-pointer hover:bg-green-200"
                  variant={"secondary"}
                >Renew EEL Card</Badge>
              </>}
          </> : <>
            <Badge className="bg-green-200 text-green-900">Completed</Badge>
          </>}
      </> : <></>}
      {row.original.paymentstatus == "pending" ? (<Badge
        onClick={() => {
          let data: any = row.original;
          dispatch(setSelectedEmployee([data]));
          navigate('/employee-total')
        }}
        className=" cursor-pointer hover:bg-green-200"
        variant={"secondary"}
      >Pay Now</Badge>) : null}
      {row.original.paymentstatus == "card-re-assigned" ? (<Badge className="bg-green-200 text-green-900">Card Re-Assigned</Badge>) : null}
    </div>
  )
}

export default Batch