import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { changeRepresentativeEmailValidationApi, changeRepresentativeRequestFormSubmitApi, company_registration } from 'src/services/services';
import { RootState } from 'src/store';

interface IChangeRepresentativeSlicerState {
    emailValidationRequestState: {
        business_email: string | undefined,
        isLoading: boolean,
        isError: boolean,
        message: string,
        status: number | undefined,
        enableRepresentativeForm: boolean
    },
    changeRepresentativeRequestState: {
        isLoading: boolean,
        isError: boolean,
        message: string,
        status: number | undefined,
    }
}


export const initaiState: IChangeRepresentativeSlicerState = {
    emailValidationRequestState: {
        business_email: undefined,
        isLoading: false,
        isError: false,
        message: "",
        status: undefined,
        enableRepresentativeForm: false
    },
    changeRepresentativeRequestState: {
        isLoading: false,
        isError: false,
        message: "",
        status: undefined,
    }
}


export const emailValidationRequestAsync: any = createAsyncThunk(
    'changeRepresentativeReducer/emailValidationRequest',
    async (data: {
        business_email: string
    }, thunkApi: any) => {
        try {

            thunkApi.dispatch(setBusinessEmail(data.business_email))
            const response: any = await changeRepresentativeEmailValidationApi(data);
            if (response.status == 200) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setEmailValidationRequestError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setEmailValidationRequestError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const changeRepresentativeRequestAsync: any = createAsyncThunk(
    'changeRepresentativeReducer/changeRepresentativeRequest',
    async (data: any, thunkApi: any) => {
        try {

            const { emailValidationRequestState: { business_email } } = thunkApi.getState().changeRepresentativeReducer;
            delete data.confirm_password;
            const response: any = await changeRepresentativeRequestFormSubmitApi({
                // business_email,
                ...data
            });
            if (response.status == 200 || response.status == 201) {
                return response;
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setChangeRepresentativeRequestStateError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setChangeRepresentativeRequestStateError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const changeRepresentativeReducer = createSlice({
    name: 'changeRepresentativeReducer',
    initialState: initaiState,
    reducers: {
        setEmailValidationRequestError: (state, action: PayloadAction<any>) => {
            state.emailValidationRequestState.isError = true;
            state.emailValidationRequestState.message = action.payload
        },
        setChangeRepresentativeRequestStateError: (state, action: PayloadAction<any>) => {
            state.changeRepresentativeRequestState.isError = true;
            state.changeRepresentativeRequestState.message = action.payload
        },
        setBusinessEmail: (state, action: PayloadAction<any>) => {
            state.emailValidationRequestState.business_email = action.payload;
        },
        resetEmailValidationRequestState: (state) => {
            state.emailValidationRequestState = initaiState.emailValidationRequestState
        },
        resetChangeRepresentativeRequestState: (state) => {
            state.changeRepresentativeRequestState = initaiState.changeRepresentativeRequestState
        },
        resetEmailValidationRequestErrorState: (state) => {
            state.emailValidationRequestState.isError = false;
            state.emailValidationRequestState.isLoading = false;
            state.emailValidationRequestState.message = "";
        },
        resetChangeRepresentativeRequestStateErrorState: (state) => {
            state.changeRepresentativeRequestState.isError = false;
            state.changeRepresentativeRequestState.isLoading = false;
            state.changeRepresentativeRequestState.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(emailValidationRequestAsync.fulfilled, (state, { payload }) => {
                state.emailValidationRequestState.isLoading = false
                state.emailValidationRequestState.enableRepresentativeForm = true
                state.emailValidationRequestState.status = payload?.status
            })
            .addCase(emailValidationRequestAsync.pending, (state) => {
                state.emailValidationRequestState.isLoading = true
                state.emailValidationRequestState.isError = false
                state.emailValidationRequestState.message = ""
            })
            .addCase(emailValidationRequestAsync.rejected, (state, { payload }) => {
                state.emailValidationRequestState.isLoading = false
                state.emailValidationRequestState.isError = true
                state.emailValidationRequestState.message = payload
            })
            .addCase(changeRepresentativeRequestAsync.fulfilled, (state, { payload }) => {
                state.changeRepresentativeRequestState.isLoading = false
                state.changeRepresentativeRequestState.status = payload?.status || undefined
            })
            .addCase(changeRepresentativeRequestAsync.pending, (state) => {
                state.changeRepresentativeRequestState.isLoading = true
                state.changeRepresentativeRequestState.isError = false
                state.changeRepresentativeRequestState.message = ""
            })
            .addCase(changeRepresentativeRequestAsync.rejected, (state, { payload }) => {
                state.changeRepresentativeRequestState.isLoading = false
                state.changeRepresentativeRequestState.isError = true
                state.changeRepresentativeRequestState.message = payload
            })

    }

});

export const {
    setEmailValidationRequestError,
    setChangeRepresentativeRequestStateError,
    setBusinessEmail,
    resetEmailValidationRequestState,
    resetChangeRepresentativeRequestState,
    resetEmailValidationRequestErrorState,
    resetChangeRepresentativeRequestStateErrorState
} = changeRepresentativeReducer.actions;

export const getChnageRepresentativeReducerState = (state: RootState) => state.changeRepresentativeReducer;

export default changeRepresentativeReducer.reducer;