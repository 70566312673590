import React from "react";
import { DataTable } from "./components/data-table";
import { columns } from "./components/columns";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

type Props = {};

const DependentsList = (props: Props) => {
  const {
    dependentState: { dependents },
  } = useSelector((state: RootState) => state.employeeDetails);
  // console.log("dependents", dependents)

  return <DataTable data={dependents || []} columns={columns} />;
};

export default DependentsList;
