import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import FooterComponent from '../../components/FooterComponent'
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent'
import { cn } from 'src/lib/utils'
import { removeAuth, useAuth } from '../Auth/Core'
import NavBar from 'src/components/Common/NavBar'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import ErrorBar from 'src/components/Common/ErrorBar'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import NavBarVertical from 'src/components/Common/NavBarVeritcal'
import { TextAlignJustifyIcon } from '@radix-ui/react-icons'

type Props = {}

const AuthLayoutMain = (props: Props) => {
    const { error, status } = useSelector((state: RootState) => state.error)
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const { currentUser } = useAuth();
    const flexgrow_routes = ['/auth/']
    const [state, setState] = useState<string>('Active')
    const [count, setCount] = useState<number>(0)
    const [remaining, setRemaining] = useState<number>(0)
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const onIdle = () => {
        if (currentUser) {
            removeAuth();
            navigate('/auth');
            window.location.reload();
        }
        setState('Idle')
    }

    const onActive = () => {
        setState('Active')
    }

    const onAction = () => {
        setCount(count + 1)
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 300_000,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        const handleResize = () => {
            // Check the screen width here (e.g., switching at 'md' breakpoint)
            const isAboveSmallResolution = window.innerWidth > 768; // Assuming 'md' breakpoint at 768px
            if (isAboveSmallResolution) {
                setIsSidebarOpen(true); // Close sidebar if open and screen size is above 'md'
            }
        };

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isSidebarOpen]);

    return (
        <>

            <div className="flex flex-col min-h-screen bg-white">
                <div className="w-full sticky top-0 z-50 bg-white shadow-md">
                    {/* <p>{remaining} seconds remaining</p> */}
                    <HeaderComponent />
                    {/* {currentUser && <NavBar currentUser={currentUser} />} */}
                    {/* {error && <ErrorBar error={error} status={status} />} */}
                </div>
                {/* <div className={cn('w-full flex-grow', '', { 'flex-grow': pathname == '/auth/' })}>
                    <div className="grid lg:grid-cols-6">
                        <div>
                            {currentUser && <NavBarVertical currentUser={currentUser} />}
                        </div>
                        <div className="col-span-5 h-full">
                            <Outlet />
                        </div>
                    </div>
                </div> */}
                <div className="flex flex-grow sm:flex-col">
                    <button
                        className="block md:hidden lg:hidden" // Show button only on small screens
                        onClick={toggleSidebar}
                    >
                        {isSidebarOpen ? 'Close' : 'Open'} Sidebar
                    </button>
                    <div
                        className={` z-50 md:w-1/6 fixed left-0 h-full top-14 bg-white z-8 shadow shadow-right-md pr-3 pb-3 transition-transform duration-300 
                        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                    // className="md:w-1/6 fixed left-0 h-full top-14 bg-white z-8 shadow shadow-right-md pr-3 pb-3"
                    >
                        {currentUser && <NavBarVertical currentUser={currentUser} />}
                    </div>
                    <div className="md:w-6/6 overflow-y-auto mb-14 xl:ml-72 lg:ml-64 md:ml-52 sm:ml-4 pr-5">
                        <Outlet />
                    </div>
                </div>
                <div className="w-full md:fixed bottom-0 z-50 bg-white">
                    <FooterComponent />
                </div>
            </div >
        </>
    )
}

export default AuthLayoutMain