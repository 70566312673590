import DataTableMain from "./Table/DataTableMain"
import { QueryRequestProvider } from "./Table/core/QueryRequestProvider"
import { QueryResponseProvider } from "./Table/core/QueryResponseProvider"

type Props = {
   
}

const AllEmployeesList = ({  }: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <DataTableMain />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllEmployeesList