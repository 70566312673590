import React, { useCallback, useMemo, useRef } from "react";
import { VariableSizeList as List } from "react-window";
import { useWindowResize } from "./useWindowResize";
import { CommandItem } from "src/components/ui/command";
import { cn } from "src/lib/utils";
import { CheckIcon } from "lucide-react";
import { SVGC } from "src/Helpers/SVGC";
import { toAbsoluteUrl } from "src/Helpers";

type Props = {
  data: any;
  onchange: (e: any) => void;
  value: any;
};

const Row = ({ data, index, setSize, windowWidth, onchange, value }: any) => {
  const rowRef: any = useRef();
  const isEven = index % 2 === 0;

  React.useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);

  return (
    <div
      ref={rowRef}
      className={cn("flex items-center px-2 py-1 cursor-pointer", {
        "bg-secondary": data[index].value === value,
      })}
      onClick={() => {
        // console.log({ data: data[index] }, 's');
        onchange(data[index].value)
      }}
    >
      <CheckIcon
        className={cn(
          "mr-2 h-4 w-4",
          data[index].value === value ? "opacity-100" : "opacity-0"
        )}
      />
      {data[index].icons && (
        <span className="me-2">
          <SVGC path={toAbsoluteUrl("/" + data[index].icons) || ""} className="w-[20px] h-[20px]" />
        </span>
      )}
      {data[index].label}
    </div>
  );
};

const VirtualCommandItems = ({ data, onchange, value }: Props) => {
  const listRef = useRef<any>(null);
  const newdata = useMemo(() => data, [data]);
  const sizeMap = useRef<any>({});
  const setSize = useCallback((index: any, size: any) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index: any) => sizeMap.current[index] || 50;
  const [windowWidth] = useWindowResize();

  return (
    <List
      ref={listRef}
      height={200}
      width="100%"
      itemCount={data.length}
      itemSize={getSize}
      itemData={data}
    >
      {({ data: item, index, style }) => (
        <div style={style}>
          <Row
            data={item}
            index={index}
            setSize={setSize}
            windowWidth={windowWidth}
            onchange={onchange}
            value={value}
          />
        </div>
      )}
    </List>
  );
};

export default VirtualCommandItems;
