import { toAbsoluteUrl } from '../../Helpers'
import React from 'react'
import { cn } from '../../lib/utils'
import { ClassValue } from 'clsx'

type Props = {
    size: number,
    color?: string,
    classname?: any,
}

const CallOutlineIcon = ({ size, color, classname }: Props) => {
    return (
        <img src={toAbsoluteUrl('/media/icons/svg/call-outline.svg')} className={classname} style={{ width: size + "px", height: size + "px" }} />
    )
}

export default CallOutlineIcon