import React from 'react'
import { ChevronDownIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from 'src/components/ui/dropdown-menu';
import { useDispatch } from 'react-redux';
import { SelectContent, SelectItem, SelectTrigger, Select } from "src/components/ui/select";
import { setTransaction } from 'src/Silcers/paymentsListSlicer';
import { Link, useNavigate } from 'react-router-dom';
import { PersonIcon } from '@radix-ui/react-icons';
type Props = {
    id:string,
}
function Batch({id}:Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    {/* <Button variant="ghost" className="relative h-8 w-8 rounded-full"> */}
                    <div className=" w-24 flex justify-center sub-styles cursor-pointer border border-gray-500 p-1">
                        <p className=" text-sm" >
                            Select
                        </p>
                        <ChevronDownIcon className="mx-2 h-6 w-5 rounded-full cursor-pointer" />
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="" align="end" forceMount>
                    <DropdownMenuGroup>
                        <DropdownMenuItem 
                        onClick={() => navigate(`/payment_recipt/${id}`)} 
                        className=' text-base hover:cursor-pointer'>
                            View
                            {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
                        </DropdownMenuItem>
                        {/* <DropdownMenuItem onClick={() => navigate(`/payment_recipt/${row.original.transaction_id}`)} className=' text-base hover:cursor-pointer'>
                            Dispute
                        </DropdownMenuItem> */}
                    </DropdownMenuGroup>

                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
}

export default Batch