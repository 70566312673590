import { toAbsoluteUrl } from '../../Helpers'
import { cn } from '../../lib/utils'
import React from 'react'
import { ClassValue } from 'clsx'

type Props = {
    size: number,
    color?: string,
    classname?: any
}

const FooterEmailOutlineIcon = ({ size, color, classname }: Props) => {
    return (

        <img src={toAbsoluteUrl('/media/icons/svg/sms-tracking-outline.svg')} className={classname} style={{ width: size + "px", height: size + "px" }} />

    )
}


export default FooterEmailOutlineIcon