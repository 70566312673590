import { SVGC } from "src/Helpers/SVGC";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "src/lib/utils";
import { Button } from "../button";
import { Search } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { optionsType, toAbsoluteUrl } from "src/Helpers";
import { ViewportList } from "react-viewport-list";
import {
  useRef,
  useEffect,
  ChangeEvent,
  useMemo,
  useState,
  useInsertionEffect,
} from "react";
import VirtualList from "src/components/Common/Vertualize/VirtualList";
import VirtualCommandItems from "src/components/Common/Vertualize/VirtualCommandItems";
import { Input } from "../Input";
import VirtualItems from "src/components/Common/Vertualize/VirtualItems";
import SelectValue from "./SelectValue";

type Props = {
  placeholder?: string;
  label?: string;
  nameKey: string;
  lableClass?: string;
  value?: string;
  options?: optionsType[] | undefined;
  onchange: (e: any) => void;
  optionsWidth?: number
  disabled?: boolean
};

const FVirtualizedselect = ({
  placeholder,
  nameKey,
  label,
  lableClass,
  value,
  onchange,
  options = ["type1", "type2"].map((o: any) => ({ label: o, value: o })),
  optionsWidth,
  disabled
}: Props) => {
  // console.log();

  const [search, setsearch] = useState("");
  const [newData, setnewData] = useState<optionsType[]>([])
  const [newValue, setnewValue] = useState<string | undefined>("")

  const popupref = useRef<any>(null);
  const [width, setwidth] = useState(0)

  useEffect(() => {
    setwidth(popupref?.current?.clientWidth)
    return () => { }
  }, [popupref])


  useEffect(() => {
    setnewData(options)
    return () => { }
  }, [options])


  useEffect(() => {
    setnewValue(value || "")
    return () => { }
  }, [value, options])


  let isDisabled = useMemo(() => options?.length == 0, [options]);

  // console.log({ value, nameKey, newValue, options });

  return (
    <div className="w-100">
      <Popover >
        <PopoverTrigger asChild>
          <Button
            disabled={isDisabled || disabled}
            key={nameKey}
            ref={popupref}
            variant="outline"
            role="combobox"
            className={cn(
              "w-full justify-between px-2 font-normal",
              !value && "text-muted-foreground"
            )}
          >
            <div className="">
              {value ? <SelectValue options={options} value={newValue || value || ""} ogValue={value} onchange={(e: any) => onchange(e)} /> : `Select `}
            </div>
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        {width && <PopoverContent className={cn("p-0 ")} style={{ width: width }} align="start">
          <div className={cn(`relative p-1 z-50 overflow-hidden rounded-md border bg-popover text-popover-foreground  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`, { width: width })}
            cmdk-input-wrapper=""
          >
            <div className='flex items-center space-x-1 m-2'>
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <input
                type="text"
                className="focus:outline-none hover:outline-none py-2 text-sm w-full  "
                placeholder="Search"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setsearch(e.target.value.toLowerCase())
                  if (e.target.value.toLowerCase() !== "") {
                    let listData =[];
                    listData = options && options.filter((d: optionsType) => d.value.toLowerCase().includes(e.target.value.toLowerCase()))
                    if (!listData || listData?.length == 0) {
                      listData = options && options.filter((d: optionsType) => d.label.toLowerCase().includes(e.target.value.toLowerCase()))
                    }
                    setnewData(listData)
                  }
                  else setnewData(options)
                }}
              />
            </div>
            <VirtualItems
              data={newData}
              onchange={(e: any) => {
                onchange && onchange(e);
                setsearch("");
                popupref.current?.click();
              }}
              value={value}
            />
          </div>
        </PopoverContent>
        }
      </Popover>

    </div>
  );
};

export default FVirtualizedselect;
