import { getAuth } from "src/Modules/Auth/Core";
import { BASE_URL } from "./api_urls";
import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";

// Create an instance of Axios with custom configuration
export const axiosWithToken = axios.create({
    baseURL: BASE_URL, // Replace with your API base URL
});

// You can also set up request interceptors or other configurations
axiosWithToken.interceptors.request.use(
    (config: any) => {
        const token = getAuth();
        // Do something before each request, like adding authentication headers
        config.headers['Authorization'] = `Bearer ${token?.api_token}`;
        return config;
    },
    (error: any) => {
        // Handle request error
        return Promise.reject(error);
    }
);

axiosWithToken.interceptors.response.use(
    (response: any) => {
        // Do something with the response data
        return response;
    },
    (error: any) => {
        // Handle response error
        return Promise.reject(error);
    }
);

// Create an instance of Axios with custom configuration
// You can also set up request interceptors or other configurations
export const axiosWithoutToken = axios.create({
    baseURL: BASE_URL, // Replace with your API base URL
    timeout: 5000, // Request timeout in milliseconds (optional)
    headers: {
        'Content-Type': 'application/json', // Set default content type (optional)
        // You can add other headers here if needed
    },
});

axiosWithoutToken.interceptors.request.use(
    (config: any) => {
        // Do something before each request, like adding authentication headers
        // config.headers['Authorization'] = `Bearer ${yourAuthToken}`            
        return config;
    },
    (error: any) => {
        // Handle request error
        return Promise.reject(error);
    }
);


