import { useFormik } from "formik";
import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { passwordAndSecurityQtnState, resetError, setPasswordAndSecurityQtnAsync, resetState } from "src/Silcers/setPasswordAndSecurityQtnSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import ErrorDialog from "src/components/ErrorDialog";
import SuccessDialog from "src/components/SuccessDialog";
import { Button } from "src/components/ui/button";
import { RootState } from "src/store";
import { removeAuth } from "../Auth/Core";
import {
  ResetPasswordSchema,
  ResetPassword_Fileds,
  ResetPassword_INIT,
  Quations
} from "./modules";

import { optionsType } from "src/Helpers";

type Props = {};

const ResetSecurityQuations = (props: Props) => {

  const { token } = useParams()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, message, status } = useSelector((state: RootState) => passwordAndSecurityQtnState(state))


  useEffect(() => {
    removeAuth();
    dispatch(resetState())
    return () => { }
  }, [])

  const resetPassworformik = useFormik({
    initialValues: ResetPassword_INIT,
    validationSchema: ResetPasswordSchema,
    onSubmit(values, formikHelpers) {
      dispatch(
        setPasswordAndSecurityQtnAsync({
          security_question1: values.security_question1,
          security_answer1: values.security_answer1,
          security_question2: values.security_question2,
          security_answer2: values.security_answer2,
          security_question3: values.security_question3,
          security_answer3: values.security_answer3,
          password: values.password,
          token: token
        })
      );
    },
  });

  const rendemoveKeysFromAObject = (keys: string[], object: optionsType[]) => {
    // console.log({ keys, object });

    return object.filter((o: optionsType) => !keys.includes(o.value));

    // return Object.keys(object)
    //   .filter((key:any) => !keys.includes(key.value))
  };

  return (
    <>
      <div>
        <PageHeadingComponent
          mainContent="Forgot Password"
          mainContentClassName="text-center"
          mainTextClassName="capitalize"
          subContent="Please enter your registered email id"
          subContentClassName="mx-auto"
        />
      </div>

      <div className="w-4/5 md:w-3/5 mx-auto py-4">
        <form onSubmit={resetPassworformik.handleSubmit}>
          <div className="grid md:grid-cols-2 grid-rows-3 gap-4 ">
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_question1}
                key={ResetPassword_Fileds.security_question1.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question1", e)
                }
                required
                disabled={false}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_answer1}
                key={ResetPassword_Fileds.security_answer1.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer1", e)
                }
                required
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={{
                  ...ResetPassword_Fileds.security_question2,
                  options: (resetPassworformik.values.security_question1 || resetPassworformik.values.security_question3) && rendemoveKeysFromAObject(
                    [
                      //formik.getValues("security_question1"),
                      //form.getValues("security_question3"),
                      resetPassworformik.values.security_question1 as any,
                      resetPassworformik.values.security_question3 as any,
                    ],
                    Quations
                  ),
                }}
                key={ResetPassword_Fileds.security_question2.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question2", e)
                }
                required
                disabled={false}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_answer2}
                key={ResetPassword_Fileds.security_answer2.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer2", e)
                }
                required

              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={{
                  ...ResetPassword_Fileds.security_question3,
                  options: (resetPassworformik.values.security_question1 || resetPassworformik.values.security_question2) && rendemoveKeysFromAObject(
                    [
                      resetPassworformik.values.security_question1 as any,
                      resetPassworformik.values.security_question2 as any,
                    ],
                    Quations
                  ),
                }}
                key={ResetPassword_Fileds.security_question3.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question3", e)
                }
                required
                disabled={false}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_answer3}
                key={ResetPassword_Fileds.security_answer3.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer3", e)
                }
                required

              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.password}
                key={ResetPassword_Fileds.password.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("password", e)
                }
                required

              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.confirm_password}
                key={ResetPassword_Fileds.confirm_password.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("confirm_password", e)
                }
                required

              />
            </div>
          </div>

          <div className="flex justify-center w-100 my-4">
            <Button disabled={isLoading} className="w-1/4">
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
              Submit
            </Button>
          </div>
        </form>
        <ErrorDialog isOpen={isError} message={message} onCancelClick={() => dispatch(resetError())} />
        <SuccessDialog isOpen={status == 200} title="Password Reset Successfully" onClickOkBtn={() => {
          dispatch(resetState())
          navigate("/auth")
        }} >
          <div>Please continue with login</div>
        </SuccessDialog>
      </div>
    </>
  );
};

export default ResetSecurityQuations;
