import { SECURITY_QUATIONS, SECURITY_QUATIONS_OPTIONS } from 'src/Helpers';
import * as yup from 'yup'


export interface IEmailValidate {
    bussienssEmail: string | undefined
}

export const Quations = SECURITY_QUATIONS && SECURITY_QUATIONS?.map((o: any) => ({
    value: o,
    label: o
}));

export interface IResetPassword {
    security_question1: string | undefined;
    security_answer1: string | undefined;
    security_question2: string | undefined;
    security_answer2: string | undefined;
    security_question3: string | undefined;
    security_answer3: string | undefined;
    password: string | undefined;
    confirm_password: string | undefined;
}

export const EmailValidate_INIT = {
    bussienssEmail: undefined
}

export const EmailValidateSchema = yup.object().shape({
    bussienssEmail: yup.string().email().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/).required()
})

export const ResetPassword_INIT = {
    security_question1: undefined,
    security_answer1: undefined,
    security_question2: undefined,
    security_answer2: undefined,
    security_question3: undefined,
    security_answer3: undefined,
    password: undefined,
    confirm_password: undefined,
}


export const ResetPasswordSchema = yup.object().shape({
    security_question1: yup.string().required(),
    security_answer1: yup.string().required(),
    security_question2: yup.string().required(),
    security_answer2: yup.string().required(),
    security_question3: yup.string().required(),
    security_answer3: yup.string().required(),
    password: yup.string().matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/, "Password length should be between 8-18 characters, including capital letters, numbers, and special characters.").required(),
    confirm_password: yup.string().required().test("confirm_password", (value, validationcontext) => {
        const { createError, parent: { password } } = validationcontext;

        if (!value && password) {
            return createError({ message: "Please enter confirm password" });
        }

        if (value != password) {
            return createError({ message: "Password not match" });
        }

        return true;
    }),
})


export const EmailValidate_Filed = {
    bussienssEmail: {
        key: "bussienssEmail",
        label: "Email",
        field_type: "email",
        field_mode: "email",
        placeholder: "Enter Email ID",
    }
}

export const ResetPassword_Fileds = {
    security_question1: {
        key: "security_question1",
        label: "Security Question 1",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 1",
    },
    security_answer1: {
        key: "security_answer1",
        label: "Security Answer 1",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 1",
    },
    security_question2: {
        key: "security_question2",
        label: "Security Question 2",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 2",
    },
    security_answer2: {
        key: "security_answer2",
        label: "Security Answer 2",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 2",
    },
    security_question3: {
        key: "security_question3",
        label: "Security Question 3",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 3",
    },
    security_answer3: {
        key: "security_answer3",
        label: "Security Answer 3",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 3",
    },
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Enter Password",
    },
    confirm_password: {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Enter Password",
    },
};

export interface IUplodDocument {
    cac: undefined,
    tcc: File | string | undefined;
    expatriate_quota_position: File | string | undefined;
    additional_doc_name?: string | undefined;
    additional_doc?: File | string | undefined;
}

export const INIT_UPLOAD_DOCUMENTS: IUplodDocument = {
    cac: undefined,
    tcc: undefined,
    expatriate_quota_position: undefined,
    additional_doc_name: undefined,
    additional_doc: undefined,
}

export const upload_documents_form_fileds = {
    cac:
    {
        key: 'cac',
        label: 'UPLOAD C.A.C DOCUMENT: CERTIFICATE OF INCORPORATION',
        field_type: 'file',
        field_mode: '',
        placeholder: 'Corporate Affairs Commission'
    },

    // tcc:
    // {
    //     key: 'tcc',
    //     label: 'UPLOAD T.C.C :  TAX CLEARANCE CERTIFICATE (TCC)',
    //     field_type: 'file',
    //     field_mode: '',
    //     placeholder: 'TAX CLEARANCE CERTIFICATE (TCC)'
    // }
    // ,
    // expatriate_quota_position:
    // {
    //     key: 'expatriate_quota_position',
    //     label: 'UPLOAD RENEWAL OF EXPATRIATE QUOTA POSITIONS',
    //     field_type: 'file',
    //     field_mode: '',
    //     placeholder: 'RENEWAL OF EXPATRIATE QUOTA POSITIONS'
    // },
    // additional_doc_name:
    // {
    //     key: 'additional_doc_name',
    //     label: 'Additional Document Name',
    //     field_type: 'text',
    //     field_mode: '',
    //     placeholder: 'Docuemnt Name'
    // },
    // additional_doc:
    // {
    //     key: 'additional_doc',
    //     label: 'Select Additional Document',
    //     field_type: 'file',
    //     field_mode: '',
    //     placeholder: 'Select Additional Document'
    // }

}

const customErrorMessages = {
    cac: "Select CAC file",
    tcc: "Select TCC file.",
    expatriate_quota_position: "Select Expatriate quota position file.",
};

export const uploadDocumentsSchema_yup = yup.object().shape({
    cac: yup.mixed().required("Select document").test('cac', (value, validationcontext) => {
        const { createError, parent: { } } = validationcontext;
        const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
        if (value && value instanceof File && fileSize >= 5) {
            return createError({ message: "Document should not be more than 5 MB " + fileSize?.toFixed(2) + "MB" });
        }

        if (value && value instanceof File && value.name.split(/\.([^.]+)$/)[0].match(/[.,_]/)) {
            return createError({ message: "File name not include underscore,dot,comma" });
        }

        if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
            return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : " + fileSize?.toFixed(2) + "MB" });
        }
        return true;
    }),
    // tcc: yup.mixed().required("Select document").test('tcc', (value, validationcontext) => {
    //     const { createError, parent: {  } } = validationcontext;
    //     const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
    //     if (value && value instanceof File && fileSize >= 5) {
    //         return createError({ message: "Document should not be more than 5 MB " });
    //     }

    //     if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
    //         return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : "  + fileSize?.toFixed(2) + "MB"});
    //     }
    //     return true;
    // }),
    // expatriate_quota_position: yup.mixed().required("Select document").test('expatriate_quota_position', (value, validationcontext) => {
    //     const { createError, parent: {  } } = validationcontext;
    //     const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
    //     if (value && value instanceof File && fileSize >= 5) {
    //         return createError({ message: "Document should not be more than 5 MB " });
    //     }

    //     if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
    //         return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : "  + fileSize?.toFixed(2) + "MB"});
    //     }
    //     return true;
    // }),
    // additional_doc_name: yup.string().optional(),
    // additional_doc: yup.mixed().optional().test('additional_doc', (value, validationcontext) => {
    //     const { createError, parent: {  } } = validationcontext;
    //     const fileSize = value && value instanceof File && value?.size / 1024 / 1024 || 0; // MB
    //     if (value && value instanceof File && fileSize >= 5) {
    //         return createError({ message: "Document should not be more than 5 MB " });
    //     }

    //     if (value && value instanceof File && !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)) {
    //         return createError({ message: "Document invalid or unsupported. Accepted formats: PDF, PNG, JPG, JPEG (up to 5 MB). current file size : "  + fileSize?.toFixed(2) + "MB"});
    //     }
    //     return true;
    // })
});


