import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainCompanyDetail from "src/Modules/CompanyProfile/MainCompanyDetail";
import EmpReview from "src/Modules/EmpExpatriate/Components/EmployeeForm/EmpReview";
import EmployeeMain from "src/Modules/EmpExpatriate/EmployeeMain";
import Main from "src/Modules/Employeepayment/DashbaordMain";
import { useAuth } from "../Modules/Auth/Core";

import CompanyProfileView from "src/Modules/CompanyProfile/CompanyProfileView/CompanyProfileView";
import DashbaordMain from "src/Modules/Dashboard/Main";
import EmployeeTotalPay from "src/Modules/Employeepayment/Components/EmployeeTotalPay";
import EmplyoeeConvert from "src/Modules/Employeepayment/Components/EmplyoeeConvert";
import Contactus from "src/Modules/Faq/ContactUs";
import EllCard from "src/Modules/Reports/EllCard";

import ProfileEdit from "src/Modules/CompanyProfile/CompanyProfileEdit/ProfileEdit";
import CreateDependents from "src/Modules/Dependents/Create";
import ReviewDependent from "src/Modules/Dependents/Review";
import EmpAddNew from "src/Modules/EmpExpatriate/Components/EmployeeForm/EmpAddNew";
import EmployeeEdit from "src/Modules/EmpExpatriate/Components/EmployeeProfile/EmployeeEdit.new";
import EmployeeView from "src/Modules/EmpExpatriate/Components/EmployeeProfile/EmployeeView";
import PaymentCard from "src/Modules/PaymentCard/PaymentCard";
import PaymentMain from "src/Modules/Reports/PaymentMain";
import ViewDependent from "src/Modules/Dependents/View";
import EditDependent from "src/Modules/Dependents/Edit";
import DependentCard from "src/Modules/Dependents/Card/DependentCard";
import EmployeeCompleteProfile from "src/Modules/EmpExpatriate/Components/EmployeeCompleteProfile";
import EmployeeCompleteProfileReview from "src/Modules/EmpExpatriate/Components/EmployeeCompleteProfile/EmployeeCompleteProfileReview";
import CreateQuotaSetup from "src/Modules/QuotaSetup/Create/CreateQuotaSetup";
import QuotaList from "src/Modules/QuotaSetup/QuotaList";
import QuotaView from "src/Modules/QuotaSetup/View/QuotaView";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import InformDialog from "src/components/InformDialog";
import { useEffect, useState } from "react";
import FaqMian from "src/Modules/Faq/FaqMian";
import ResetSecurityQuations from "src/Modules/ResetSecurityQuations";
import AssignEmployeeToEmptyCards from "src/Modules/Cards/Employees/AssigneEmployeeToEmptyCards";

type Props = {};

const ErrorsPage = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate()
  // console.log({ pathname });

  useEffect(() => {
    if (pathname.includes("welcome")) {
      navigate("/")
    }
    return () => { }
  }, [pathname])

  return <div>Errror</div>;
};

const CheckAuthorizeRoute = () => {
  const isNewUser = useSelector((state: RootState) => state?.login?.isNewUser)

  const [show, setshow] = useState<boolean>(false)

  // useEffect(() => {
  //   if (isNewUser) {
  //     setshow(true)
  //   }
  //   return () => { }
  // }, [])


  const navigate = useNavigate()

  return <>
    <Outlet />
    <InformDialog isOpen={show} message={"Please complete company profile"} onCancelClick={() => {
      navigate("/company-profile")
      setshow(false)
    }} ></InformDialog>
  </>
}


const AppRoutes = (props: Props) => {
  const { currentUser } = useAuth();
  // const { role }: any = currentUser;

  return (
    <Routes>
      <Route index element={<Navigate to="/dashboard" />} />
      <Route path='company-profile' element={<MainCompanyDetail />} />
      <Route element={<CheckAuthorizeRoute />}>
        <Route path='employee' element={<EmployeeMain />} />
        <Route path='employee-add' element={<EmpAddNew />} />
        <Route path='employee/profile/update/:id' element={<EmployeeCompleteProfile />} />
        <Route path='employee/profile/view/' element={<EmployeeCompleteProfileReview />} />
        <Route path='dependent/create' element={<CreateDependents />} />
        <Route path='dependent/add/:id' element={<CreateDependents />} />
        <Route path='dependent/review' element={<ReviewDependent />} />
        <Route path='dependent/view/:id' element={<ViewDependent />} />
        <Route path='dependent/edit/:empid/:id' element={<EditDependent />} />
        <Route path='employee-review' element={<EmpReview />} />
        <Route path='company-profile-view' element={<CompanyProfileView />} />
        <Route path='company-profile-edit' element={<ProfileEdit />} />
        {/* <Route path='employee-detail' element={<EmpDetail />}/> */}
        <Route path='employee-view/:id' element={<EmployeeView />} />
        <Route path='employee-update/:id' element={<EmployeeEdit />} />
        <Route path='employee-payment' element={<Main />} />
        <Route path='employee-total' element={<EmployeeTotalPay />} />
        <Route path='employee-convert' element={<EmplyoeeConvert />} />
        <Route path='dashboard' element={<DashbaordMain />} />
        <Route path='help' element={<Contactus />} />
        {/* FAQ MOVED OUTSIDE THE AUTH */}
        {/* <Route path='faq' element={<FaqMian />}/> */}
        <Route path='payment_recipt/:id' element={<PaymentCard />} />
        <Route path='reports' element={<PaymentMain />} />
        <Route path='cards/:id' element={<EllCard />} />
        <Route path='dependentcards/:empid/:id' element={<DependentCard />} />
        <Route path='quota/create' element={<CreateQuotaSetup />} />
        <Route path='quota/' element={<QuotaList />} />
        <Route path='quota/:id' element={<QuotaView />} />
        <Route path='/frequentry-ask' element={<FaqMian />} />
        <Route path='/cards' element={<AssignEmployeeToEmptyCards />} />
        {/* Deleted EEL Cards  */}
       
      </Route>
      <Route path='*' element={<ErrorsPage />} />
      {/* Deleted */}
      {/* <Route path='employee-edit' element={<EmpEdit />}/> */}
    </Routes>
  )
}

export default AppRoutes;
