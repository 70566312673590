import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { emailValidationRequestAsync, getChnageRepresentativeReducerState, resetEmailValidationRequestErrorState } from 'src/Silcers/changeRepresentativeSlicer';
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/lib/utils';
import { RootState } from 'src/store';
import * as yup from 'yup';
import { EmailValidate_Filed } from '../modules';
import LoadingDialog from 'src/components/LoadingDialog';
import ErrorDialog from 'src/components/ErrorDialog';
import { useDispatch } from 'react-redux';
import { Loader2 } from 'lucide-react';
import SubmitBtn from 'src/components/Common/SubmitBtn';

type Props = {}

const EmailValidationForm = (props: Props) => {

    const { emailValidationRequestState: { isError, isLoading, message, status } } = useSelector((state: RootState) => getChnageRepresentativeReducerState(state))
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            business_email: ''
        },
        validationSchema: yup.object().shape({
            business_email: yup.string().email().required()
        }),
        onSubmit(values, formikHelpers) { handleSubmitForm(values) },
    });

    const handleSubmitForm = async (values: { business_email: string }) => {
        dispatch(emailValidationRequestAsync(values))
    }


    return (
        <div>
            <form className='px-10 mt-5' onSubmit={formik.handleSubmit}>
                <div className=" flex items-end space-x-2">
                    <div className="md:w-3/4">
                        <RenderFormikFiled
                            disabled={status == 200}
                            formik={formik}
                            formObject={EmailValidate_Filed.business_email}
                            key={EmailValidate_Filed.business_email.key}
                            onchange={(e: any) =>
                                formik.setFieldValue("business_email", e)
                            }
                            required
                        />
                    </div>
                    <SubmitBtn isDisabled={status == 200} isLoading={isLoading} />
                </div>
            </form>

            <LoadingDialog isOpen={isLoading} message='Email validarion is in progress' />
            <ErrorDialog isOpen={isError} message={message} onCancelClick={() => dispatch(resetEmailValidationRequestErrorState())} />
        </div>
    )
}

export default EmailValidationForm