// import StepCircle from "src/Modules/Auth/Regsitration/Components/StepCircle";
import axios, { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAllEmptyCardRequestAsync, getAssigneEmployeeToEmptyCardReducerState, preparePostData, setAssignDialogIsOpenStatus, setSelectedCard, setSelectedEmployee } from "src/Silcers/assigneEmployeeToEmptyCardSlicer";
import SubmitBtn from "src/components/Common/SubmitBtn";
import ErrorDialog from "src/components/ErrorDialog";
import { Button } from "src/components/ui/button";
import { assignEmployeeToEMptyCardRequestApi } from "src/services/services";
import { RootState } from "src/store";

type Props = {
  row: any;
};

const Actions = ({ row }: Props) => {

  const dispatch = useDispatch()
  const { selectedCard, assinedEmployeeAndCardsPostData, selectedEmployee } = useSelector((state: RootState) => getAssigneEmployeeToEmptyCardReducerState(state))

  const isThisEmployeeisAlreadyAssigned = useMemo(() => assinedEmployeeAndCardsPostData && Array.isArray(assinedEmployeeAndCardsPostData) && assinedEmployeeAndCardsPostData.find((emp: any) => emp.employee_id == row._id), [assinedEmployeeAndCardsPostData])
  const isThisEmployeeSelected = useMemo(() => selectedEmployee && assinedEmployeeAndCardsPostData?._id == row._id, [assinedEmployeeAndCardsPostData])

  const [requestStatus, setrequestStatus] = useState({
    isLoading: false,
    isError: false,
    message: "",
  })

  const assignUser = async () => {
    try {
      setrequestStatus({ ...requestStatus, isLoading: true })
      const postData = [{
        "employee_id": row?._id,
        "card_id": selectedCard,
      }]


      // await assignUserToKycRequestApi(postData)
      const apiResps = await assignEmployeeToEMptyCardRequestApi(postData);
      dispatch(setSelectedCard(""))
      setrequestStatus({ ...requestStatus, isLoading: false })
      dispatch(setAssignDialogIsOpenStatus(false))
      dispatch(fetchAllEmptyCardRequestAsync())
    } catch (error) {
      let err = error as Error | AxiosError;
      if (axios.isAxiosError(err)) {
        console.log({ err: err.response?.data?.message })
        setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: err.response?.data?.message })
      }
      else {
        console.log({ err: err.message })
        setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: err.message })
      }
    }
  }

  // dispatch(setSelectedEmployee({
  //   employee_id: row?._id,
  //   card_id: selectedCard,
  //   employee_name: ""
  // }))
  // dispatch(preparePostData({
  //   selectedEmployee: row,
  //   selectedCard: selectedCard,
  // }))
  // dispatch(setAssignDialogIsOpenStatus(false))


  return (
    <div>
      <SubmitBtn isLoading={requestStatus.isLoading} title="Assign" className="text-xs w-[100px] h-8  bg-transparent border border-primary  text-primary hover:text-white" onClick={() => assignUser()} />
      <ErrorDialog isOpen={requestStatus.isError} message={requestStatus.message} onCancelClick={() => setrequestStatus({ ...requestStatus, isError: false, message: "" })} />
    </div>

  );
};

export default Actions;
