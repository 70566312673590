import { FC, createContext, useContext, useReducer } from "react";
import { WithChildren } from "src/Helpers/react18MigrationHelpers";
import {
    ACTION_TYPES,
    INIT_STATE,
    MainContextProps,
    Reducer,
    initialProps
} from "./modules";

const MainContext = createContext<MainContextProps>(initialProps);

const MainContextProvider: FC<WithChildren> = ({ children }) => {
  const [state, dispatch]: [any, any] = useReducer(
    Reducer,
    INIT_STATE,
    () => INIT_STATE
  );

  const setSelectedEmployee = (data: any) => {    
    dispatch({ type: ACTION_TYPES.SET_SELECTED_EMPLOYEES, payload: data });
  };
  const resetSelectedEmployee = () => {
    dispatch({ type: ACTION_TYPES.RESET_SELECTED_EMPLOYEES });
  };

  return (
    <MainContext.Provider
      value={{ state, setSelectedEmployee, resetSelectedEmployee }}
    >
      {children}
    </MainContext.Provider>
  );
};

const useMainContext = () => useContext(MainContext);
export { MainContextProvider, useMainContext };

