import React from "react";
import TimeLineSingleComponent from "./TimeLineSingleComponent";
import TimeLineWithProgress from "./TimeLineSingleComponent copy";

type Props = {};

const Timeline = (props: Props) => {
  return (
    <div>
      <TimeLineSingleComponent active={true} title="RRR Number Generated" description="Successful." />
      <TimeLineSingleComponent active={false} title="Bank Confirmation" description="Pending." />
      {/* <TimeLineWithProgress active={false} title="Bank Confirmation " description="" /> */}
      <TimeLineSingleComponent active={false} title="Completed" description="An EEL Digital Card will be generate and you will receive an email notification at your provided email address." />
    </div>
  );
};

export default Timeline;
