import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import axios, { AxiosError } from 'axios'
import CustomeDialog from 'src/components/Common/CustomeDialog'
import ErrorDialog from 'src/components/ErrorDialog'
import { getAssigneEmployeeToEmptyCardReducerState, setAssignDialogIsOpenStatus } from 'src/Silcers/assigneEmployeeToEmptyCardSlicer'
import AllEmployeesList from './AllEmployeesList'



type Props = {

}

const AssignEmployee = ({ }: Props) => {


    const dispatch = useDispatch()
    // const { selectedUser, selectedRequests, showAgentDialog } = useSelector((state: RootState) => getkycRequestReducer(state))
    const { selectedCard, selectedEmployee, assignDialogIsOpen } = useSelector((state: RootState) => getAssigneEmployeeToEmptyCardReducerState(state))

    const [requestStatus, setrequestStatus] = useState({
        isLoading: false,
        isError: false,
        message: "",
    })

    const assignUser = async () => {
        try {
            setrequestStatus({ ...requestStatus, isLoading: true })
            // const postData = {
            //     "request_ids": selectedRequests,
            //     "_id": selectedUser
            // }
            // await assignUserToKycRequestApi(postData)
            // setrequestStatus({ ...requestStatus, isLoading: false })
            // setAgentDialogStatus(false)
            // dispatch(setSelectedUser(""))
            // dispatch(setSelectedRequests([]))
            // dispatch(setAgentDialogStatus(false))
            // refetch()

        } catch (error) {
            let err = error as Error | AxiosError;
            if (axios.isAxiosError(err)) {
                console.log({ err: err.response?.data?.message })
                setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: err.response?.data?.message })
            }
            else {
                console.log({ err: err.message })
                setrequestStatus({ ...requestStatus, isLoading: false, isError: true, message: err.message })
            }
        }
    }


    return (
        <>
            <CustomeDialog isOpen={assignDialogIsOpen} headerTitle='Select Agent' hideDefaultSubmit={true} isLoading={requestStatus.isLoading} onCancle={() => {
                dispatch(setAssignDialogIsOpenStatus(false))
            }} >
                <div className='mb-4'>
                    <AllEmployeesList />
                </div>
            </CustomeDialog>
            <ErrorDialog isOpen={requestStatus.isError} message={requestStatus.message} onCancelClick={() => setrequestStatus({ ...requestStatus, isError: false, message: "" })} />
        </>
    )
}

export default AssignEmployee