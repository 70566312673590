import React from "react";
import InputFormFiled from "../ui/InputeFiled/InputFormFiled";
import DateFormField from "../ui/DateInpute/DateFormField";
import SelectFormFiled from "../ui/SelectFiled/SelectFormFiled";
import FormFIledSearchDropdown from "../ui/SearchDropdown/FormFIledSearchDropdown";
import FileUploadFormFiled from "../ui/FileUploadInput/FileUploadFormFiled";
import FormfieldVirtualizedselect from "../ui/VirtualizedSelect/FormfieldVirtualizedselect";
import FormikInput from "../ui/InputeFiled/FormikInput";
import FormikSelect from "../ui/SelectFiled/FormikSelect";
import FormikDateField from "../ui/DateInpute/FormikDateField";
import FormkiVirtualSelect from "../ui/VirtualizedSelect/FormkiVirtualSelect";
import FormikSingleFileUpload from "../ui/FileUploadInput/FormikSingleFileUpload";
import { optionsType } from "@/helper/index";
import FormikTextarea from "../ui/FormikTextarea";
import FormikCountrycodePicker from "../ui/Phonenumber/FormikCountrycodePicker";
import FormikPhonenumber from "../ui/Phonenumber/FormikPhonenumber";
import { Label } from "../ui/Label";
import { cn } from "src/lib/utils";

type Props = {
  formObject: any;
  formik: any;
  required?: boolean;
  disabled?: boolean;
  onchange?: (e: any) => void;
  onBlur?: (e: any) => void;
  sufix?: boolean;
  value?: any;
  options?: optionsType[] | [];
};

const RenderFormikFiled = ({
  formObject,
  formik,
  required,
  disabled,
  onchange,
  onBlur,
  sufix,
  value,
  options,
  // ...props
}: // value,
  Props) => {
  return (
    <>
      {formObject.field_type == "textarea" ? (
        <FormikTextarea
          nameKey={formObject.key}
          inputMode={formObject.field_mode}
          placeholder={formObject.placeholder}
          formik={formik}
          label={formObject.label}
          required={required}
          disabled={disabled}
          caseSensetiveLabel={formObject.caseSensetiveLabel}
          maxLength={formObject.maxLength}
          regex={formObject.regex}
          blockletters={formObject.blockletters || false}
          onchange={onchange}
          readonly={formObject.readonly}
          prefix={formObject.prefix}
          onBlur={onBlur}
          sufix={sufix}
          length={formObject.length}
          showMaxLength={formObject.showMaxLength}
          error_className={formObject.error_className}
        // {...props}
        //value={value}
        />
      ) : null}
      {formObject.field_type == "text" ? (
        <FormikInput
          nameKey={formObject.key}
          inputMode={formObject.field_mode}
          placeholder={formObject.placeholder}
          formik={formik}
          label={formObject.label}
          required={required}
          disabled={disabled}
          caseSensetiveLabel={formObject.caseSensetiveLabel}
          maxLength={formObject.maxLength}
          regex={formObject.regex}
          blockletters={formObject.blockletters || false}
          onchange={onchange}
          readonly={formObject.readonly}
          prefix={formObject.prefix}
          onBlur={onBlur}
          sufix={sufix}
          length={formObject.length}
          showMaxLength={formObject.showMaxLength}
          error_className={formObject.error_className}
          showInfoIcon={formObject.showInfoIcon}
          infoContent={formObject.infoContent}

        // {...props}
        //value={value}
        />
      ) : null}
      {formObject.field_type == "password" ? (
        <FormikInput
          type="password"
          nameKey={formObject.key}
          inputMode={formObject.field_mode}
          placeholder={formObject.placeholder}
          formik={formik}
          label={formObject.label}
          required={required}
          onchange={onchange}
          readonly={formObject.readonly}
          onBlur={onBlur}
          length={formObject.length}
          disabled={disabled}
          showMaxLength={formObject.showMaxLength}
          error_className={formObject.error_className}
        />
      ) : // <InputFormFiled
        //   type="password"
        //   nameKey={formObject.key}
        //   inputMode={formObject.field_mode}
        //   placeholder={formObject.placeholder}
        //   form={form}
        //   label={formObject.label}
        //   required={required}
        //   onchange={onchange}
        //   readonly={formObject.readonly}
        // />
        null}
      {formObject.field_type == "email" ? (
        <FormikInput
          type="email"
          nameKey={formObject.key}
          inputMode={formObject.field_mode}
          placeholder={formObject.placeholder}
          formik={formik}
          label={formObject.label}
          required={required}
          onchange={onchange}
          readonly={formObject.readonly}
          onBlur={onBlur}
          sufix={sufix}
          length={formObject.length}
          disabled={disabled}
          showMaxLength={formObject.showMaxLength}
          error_className={formObject.error_className}
        />
      ) : // <InputFormFiled
        //   type="email"
        //   nameKey={formObject.key}
        //   inputMode={formObject.field_mode}
        //   placeholder={formObject.placeholder}
        //   form={form}
        //   label={formObject.label}
        //   required={required}
        //   onchange={onchange}
        //   readonly={formObject.readonly}
        // />
        null}
      {formObject.field_type == "date" ? (
        <>
          <FormikDateField
            nameKey={formObject.key}
            formik={formik}
            placeholder={formObject.placeholder}
            label={formObject.label}
            required={required}
            onchange={onchange}
            disabled={disabled || formObject.readonly}
            showInfoIcon={formObject.showInfoIcon}
            infoContent={formObject.infoContent}
            toYear={formObject.toYear}
            fromYear={formObject.fromYear}
          />
        </>
      ) : // <DateFormField
        //   nameKey={formObject.key}
        //   form={form}
        //   placeholder={formObject.placeholder}
        //   label={formObject.label}
        //   required={required}
        //   onchange={onchange}
        // />
        null}
      {formObject.field_type == "phone_no" ? <>
        <div>

        </div>
      </> : null}
      {formObject.field_type == "options" ? (
        <FormikSelect
          options={options || formObject.options}
          nameKey={formObject.key}
          formik={formik}
          placeholder={formObject.placeholder}
          label={formObject.label}
          required={required}
          disabled={disabled || formObject.readonly}
          onchange={(e: any) => onchange && onchange(e)}
          showInfoIcon={formObject.showInfoIcon}
          infoContent={formObject.infoContent}

        />
      ) : // <SelectFormFiled
        //   options={formObject.options}
        //   nameKey={formObject.key}
        //   form={form}
        //   placeholder={formObject.placeholder}
        //   label={formObject.label}
        //   required={required}
        //   disabled={disabled}
        //   onchange={(e: any) => onchange && onchange(e)}
        // />
        null}
      {formObject.field_type == "options_with_search" ? (
        <>
          <FormkiVirtualSelect
            options={options || formObject.options}
            nameKey={formObject.key}
            placeholder={formObject.placeholder}
            formik={formik}
            label={formObject.label}
            required={required}
            onChange={(e: any) => onchange && onchange(e)}
            optionsWidth={formObject.optionsWidth}
            disabled={formObject.readonly || disabled}
            showInfoIcon={formObject.showInfoIcon}
            infoContent={formObject.infoContent}
          />
        </>
      ) : // <FormfieldVirtualizedselect
        //   options={formObject.options}
        //   nameKey={formObject.key}
        //   placeholder={formObject.placeholder}
        //   form={form}
        //   label={formObject.label}
        //   required={required}
        //   onChange={(e: any) => onchange && onchange(e)}
        // />
        null}
      {/* {formObject.field_type == "options_with_search" ? (
        // <FormFIledSearchDropdown
        //   nameKey={formObject.key}
        //   placeholder={formObject.placeholder}
        //   form={form}
        //   label={formObject.label}
        //   required={required}
        // />
        <FormfieldVirtualizedselect
          nameKey={formObject.key}
          placeholder={formObject.placeholder}
          form={form}
          label={formObject.label}
          required={required}
          options={formObject.options}
          onChange={onchange}
        />
      ) : null} */}
      {formObject.field_type == "file" ? (
        <>
          <FormikSingleFileUpload
            nameKey={formObject.key}
            placeholder={formObject.placeholder}
            formik={formik}
            label={formObject.label}
            required={required}
            value={value}
            onchange={onchange}
            description={formObject.description}
            disabled={disabled}
          />
        </>
      ) : // <FileUploadFormFiled
        //   nameKey={formObject.key}
        //   placeholder={formObject.placeholder}
        //   form={form}
        //   label={formObject.label}
        //   required={required}
        //   onchange={onchange}
        // />
        null}
    </>
  );
};

export default RenderFormikFiled;
