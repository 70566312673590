import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import * as z from "zod";
import { useState } from "react";
import { cn } from "src/lib/utils";
import { Button } from "../button";
import { Calendar } from "../calendar";
import { FormControl } from "../form";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";

const FormSchema = z.object({
  dob: z.date({
    required_error: "A date of birth is required.",
  }),
});

type Props = {
  date: any;
  defaultValue?: Date;
  selectedDate: (e: any) => void;
  disabled?: boolean,
  toYear?: number
  fromYear?:number
};

const DateInput = ({ date, defaultValue, selectedDate, disabled, toYear,fromYear  }: Props) => {

  console.log({toYear});
  

  const [isOpen, setisOpen] = useState(false);

  const footer = (
    <div
      className=" flex text-xs cursor-pointer hover:text-primary text-gray-500 my-2"
      onClick={() => {
        setisOpen(false);
        selectedDate(undefined);
      }}
    >
      Close
    </div>
  );

  return (
    <>
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => setisOpen(true)}
            variant={"outline"}
            className={cn(
              "w-full pl-3 text-left font-normal",
              !date && "text-muted-foreground",
              { "disabled:bg-gray-100 disabled:opacity-100": disabled }
            )}
            disabled={disabled}
          >

            {date && date.includes("/") && date}
            {date && !date.includes("/") && format(new Date(date), "dd/MM/yyyy")}
            {!date && <span>Select Date</span>}
            {/* {date && !date.includes("/") ? (
              format(new Date(date), "dd/MM/yyyy")
            ) : (
              <span>Select Date</span>
            )} */}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>

        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={new Date(date)}
            onSelect={(e: any) => {
              // console.log({ Calendar: e });
              setisOpen(false);
              selectedDate(e);
            }}
            initialFocus
            footer={footer}
            fromYear={fromYear}
            toYear={toYear}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateInput;
