import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'

type Props = {
    headers: string[],
    data: any[],
    showSRNO?: boolean
}

const TableComponent = ({ headers, data, showSRNO = false }: Props) => {

    // console.log({data});


    return (
        < >
            <Table>
                <TableHeader>
                    <TableRow className='py-2'>
                        {
                            headers && headers.map((headerName: string) => {
                                return <TableHead className="uppercase font-semibold text-gray-500 text-xs bg-secondary h-8">{headerName}</TableHead>
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        data && data.length > 0 ? data.map((d: any, indx: number) => {
                            const d_values = d && Object.values(d)
                            return <TableRow key={`table-row-${indx}`} >
                                {showSRNO && <TableCell  >{indx + 1}</TableCell>}
                                {
                                    headers && headers.map((_, indx2: number) => {
                                        if (typeof d_values[indx2] == "string" || typeof d_values[indx2] == "number" || typeof d_values[indx2] == "function") {
                                            return <TableCell>{d_values[indx2]}</TableCell>
                                        }
                                    })
                                }
                            </TableRow>
                        }) :
                            <TableRow>
                                <TableCell className='text-center' colSpan={headers.length} >No Records</TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default TableComponent