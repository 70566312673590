import { Formik, useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import {
  EmailValidateSchema,
  EmailValidate_Filed,
  EmailValidate_INIT,
  Quations,
  ResetPasswordSchema,
  ResetPassword_Fileds,
  ResetPassword_INIT,
} from "./modules";
import { Separator } from "src/components/ui/separator";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { Button } from "src/components/ui/button";
import { RESET_PASSWORD_FORM_FIELDS } from "../PasswordRest/modules";
import { useDispatch } from "react-redux";
import {
  setPasswordAsync,
  verifyEmailAsync,
  resetState,
  resetSetPasswordState,
  partialResetEmailState,
  resetEmailState,
} from "src/Silcers/forgotPasswordSlicer";
import { cn } from "src/lib/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import SuccessDialog from "src/components/SuccessDialog";
import { reseteState as loginResetState } from "src/Silcers/loginSlicer";
import { removeAuth } from "../Auth/Core";
import { SECURITY_QUATIONS, optionsType } from "src/Helpers";
import { errorMessages } from "../Auth/Login/modules";

type Props = {};

const ForgotPAssword = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    forgotPassword: {
      bussienssEmail,
      emailState: {
        isError: emailIsError,
        isValid: emailIsValid,
        isLoading: emailIsLoading,
        quations,
        message: emailMessage,
        status: emailRespStatus

      },
      setPasswordState: {
        isError: paswordIsError,
        isValid: paswordIsValid,
        isLoading: paswordIsLoading,
        message: paswordMessage,
        status,
        attempts,
      },
      user_id,
    },
    login: { bussienssEmail: userEmail },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    removeAuth();
    dispatch(resetState())
    return () => { }
  }, [])

  // useEffect(() => {
  //   if (paswordIsValid) {
  //     navigate("/auth");
  //   }
  //   return () => { };
  // }, [paswordIsValid]);

  useEffect(() => {
    if (userEmail) {
      emailformik.setFieldValue("bussienssEmail", userEmail);
    }
    return () => { };
  }, [userEmail]);

  const emailformik = useFormik({
    initialValues: EmailValidate_INIT,
    validationSchema: EmailValidateSchema,
    onSubmit(values, formikHelpers) {
      dispatch(verifyEmailAsync(values.bussienssEmail));
    },
  });

  const resetPassworformik = useFormik({
    initialValues: ResetPassword_INIT,
    validationSchema: ResetPasswordSchema,
    onSubmit(values, formikHelpers) {
      dispatch(
        setPasswordAsync({
          businessEmail: bussienssEmail,
          securityAnswer1: values.security_answer1,
          securityAnswer2: values.security_answer2,
          securityAnswer3: values.security_answer3,
          password: values.password,
        })
      );
    },
  });

  useEffect(() => {
    if (user_id) {
      resetPassworformik.setFieldValue(
        "security_question1",
        quations.securityQuestion1
      );
      resetPassworformik.setFieldValue(
        "security_question2",
        quations.securityQuestion2
      );
      resetPassworformik.setFieldValue(
        "security_question3",
        quations.securityQuestion3
      );
    }
    return () => {
      resetPassworformik.setFieldValue("security_question1", "");
      resetPassworformik.setFieldValue("security_question2", "");
      resetPassworformik.setFieldValue("security_question3", "");
    };
  }, [user_id]);

  //   const isDisabledSecurityQuation = useMemo(
  //     () => (user_id && user_id ? false : true),
  //     [user_id]
  //   );

  const isDisabledSecurityAnswer = useMemo(
    () => (user_id && user_id ? false : true),
    [user_id]
  );

  const disabledEmailValidateBtn = useMemo(
    () => (emailIsLoading || user_id ? true : false),
    [emailIsLoading, user_id]
  );

  const disableResetPasswordBtn = useMemo(
    () => (paswordIsLoading || !user_id || (status == 403 && attempts == 0) ? true : false),
    [paswordIsLoading, user_id, status, attempts]
  );

  const rendemoveKeysFromAObject = (keys: string[], object: optionsType[]) => {
    // console.log({ keys, object });

    return object.filter((o: optionsType) => !keys.includes(o.value));

    // return Object.keys(object)
    //   .filter((key:any) => !keys.includes(key.value))
  };


  return (
    <>
      <div>
        <PageHeadingComponent
          mainContent="Forgot Password"
          mainContentClassName="text-center"
          mainTextClassName="capitalize"
          subContent="Please enter your registered email id"
          subContentClassName="mx-auto"
        />
      </div>

      <div className="w-4/5 md:w-3/5 mx-auto py-4">
        {emailMessage && <p className="text-base  text-red-600 text-left mt-8 font-medium">
          {<> {
            emailRespStatus == 400 ? <>
              <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
                <div className="flex flex-col">
                  <span className="text-base  text-red-600 text-left font-medium">
                    Your account has been locked..
                  </span>
                  <span className="text-xs text-destructive ">
                    please submit the necessary documents to unlock it
                    <Link to="/forgot-upload"><span className=" text-primary"> Click here</span></Link>
                  </span>
                </div>
              </div>
            </> : <div>{emailMessage}</div>
          } </>}
        </p>}
        {paswordIsError && (
          <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
            {
              status !== 401 && status !== 403 && <p className="text-base  text-red-600 text-left mt-8 font-medium">
                {paswordMessage}
              </p>
            }
            {
              status == 403 && (attempts == 1 || attempts == 2) && <>
                <p>Your security answer is incorrect</p>
                <p className="text-base  text-red-600 text-left font-medium">
                  {paswordMessage}
                </p>
              </>
            }
            {
              (status == 403 || status == 401) && (attempts == 0) && <div className="flex flex-col">
                <span className="text-base  text-red-600 text-left font-medium">
                  Your account has been locked..
                </span>
                <span className="text-xs text-destructive ">
                  please submit the necessary documents to unlock it
                  <Link to="/forgot-upload"><span className=" text-primary"> Click here</span></Link>
                </span>
              </div>
            }
          </div>
        )}
        <form onSubmit={emailformik.handleSubmit}>
          <div className=" flex items-end space-x-2">
            <div className="md:w-3/4">
              <RenderFormikFiled
                formik={emailformik}
                formObject={EmailValidate_Filed.bussienssEmail}
                key={EmailValidate_Filed.bussienssEmail.key}
                onchange={(e: any) =>
                  emailformik.setFieldValue("bussienssEmail", e)
                }
                required
              />
            </div>
            <Button
              disabled={disabledEmailValidateBtn}
              className={cn("w-1/4 ", {
                "mb-4": emailformik.errors.bussienssEmail,
              })}
            >
              Validate
              {emailIsLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
            </Button>
          </div>
        </form>

        <Separator className="my-6" />

        <form onSubmit={resetPassworformik.handleSubmit}>
          <div className="grid md:grid-cols-2 grid-rows-3 gap-4 ">
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={{
                  ...ResetPassword_Fileds.security_question1,
                }}
                key={ResetPassword_Fileds.security_question1.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question1", e)
                }
                required
                disabled={true}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_answer1}
                key={ResetPassword_Fileds.security_answer1.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer1", e)
                }
                required
                disabled={isDisabledSecurityAnswer}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={{
                  ...ResetPassword_Fileds.security_question2,
                  options: resetPassworformik.values.security_question1 && resetPassworformik.values.security_question3 && rendemoveKeysFromAObject(
                    [
                      //formik.getValues("security_question1"),
                      //form.getValues("security_question3"),
                      resetPassworformik.values.security_question1 as string,
                      resetPassworformik.values.security_question3 as string,
                    ],
                    Quations
                  ),
                }}
                key={ResetPassword_Fileds.security_question2.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question2", e)
                }
                required
                disabled={true}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                //formObject={ResetPassword_Fileds.security_answer2}
                formObject={ResetPassword_Fileds.security_answer2}
                key={ResetPassword_Fileds.security_answer2.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer2", e)
                }
                required
                disabled={isDisabledSecurityAnswer}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_question3}
                key={ResetPassword_Fileds.security_question3.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_question3", e)
                }
                required
                disabled={true}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.security_answer3}
                key={ResetPassword_Fileds.security_answer3.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("security_answer3", e)
                }
                required
                disabled={isDisabledSecurityAnswer}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.password}
                key={ResetPassword_Fileds.password.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("password", e)
                }
                required
                disabled={isDisabledSecurityAnswer}
              />
            </div>
            <div>
              <RenderFormikFiled
                formik={resetPassworformik}
                formObject={ResetPassword_Fileds.confirm_password}
                key={ResetPassword_Fileds.confirm_password.key}
                onchange={(e: any) =>
                  resetPassworformik.setFieldValue("confirm_password", e)
                }
                required
                disabled={isDisabledSecurityAnswer}
              />
            </div>
          </div>

          <div className="flex justify-center w-100 my-4">
            <Button disabled={disableResetPasswordBtn} className="w-1/4">
              {paswordIsLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
              Submit
            </Button>
          </div>
        </form>
      </div>
      <SuccessDialog isOpen={paswordIsValid} title={"Password Reset Successfully"} onClickOkBtn={() => {
        dispatch(resetState());
        dispatch(loginResetState());
        navigate("/auth")
      }}  >
        <p>

        </p>
      </SuccessDialog>


    </>
  );
};

export default ForgotPAssword;
