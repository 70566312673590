import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { SVGC } from 'src/Helpers/SVGC';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import * as z from "zod";
import { Input } from "../../../components/ui/Input";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Checkbox } from "../../../components/ui/checkbox";
import { cn } from "../../../lib/utils";
import { useAuth } from "../Core";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../Helpers";
import { getUserByTokenAsync, loginAsync, reseteState } from "src/Silcers/loginSlicer";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
// @ts-ignore
// import ClientCaptcha from "src/Modules/ReactCaptcha";
import ClientCaptcha from "react-client-captcha";
import RenderFormFiled from "src/components/Common/RenderFormFiled";
import { DEFAULT_ilOGIN, ILOGIN_SCHEMA, LoginFormFields } from "./modules";
import { ILogin } from "src/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { useFormik } from "formik";
import { Loader2 } from "lucide-react";
type Props = {};

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultValues?: Partial<ILogin>;
}

const UserAuthForm = ({
  className,
  defaultValues,
  ...props
}: UserAuthFormProps) => {
  const { accessToken, user_id, user, error, errorMessasge, attempts, status, isLoading } =
    useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const { saveAuth, setCurrentUser, currentUser } = useAuth();
  const [code, setCode] = React.useState<{
    genCode: string;
    inputCode: string;
    error: string;
  }>({
    genCode: "",
    inputCode: "",
    error: "",
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (accessToken) {
      saveAuth({
        api_token: accessToken,
        refreshToken: "",
        user_id: user_id,
      });

      dispatch(getUserByTokenAsync())
    }
    return () => { };
  }, [accessToken]);

  React.useEffect(() => {
    if (user) {
      setCurrentUser(user);
      navigate("/");
    }
    return () => { };
  }, [user]);


  const formik = useFormik({
    initialValues: DEFAULT_ilOGIN,
    validationSchema: ILOGIN_SCHEMA,
    onSubmit(values, formikHelpers) {
      if (code.genCode !== code.inputCode) {
        setCode((prev) => ({ ...prev, error: "Invalid Captcha" }));
      } else {
        setCode((prev) => ({ ...prev, error: "" }));
        const { email, password } = values;
        dispatch(
          loginAsync({
            businessEmail: email,
            password: password,
          })
        );
      }
    },
  })

  // businessEmail: "fddfd@mail.com",
  // password: "hurfsiubsvuiv8347",

  const onSubmit = async (values: InferType<typeof ILOGIN_SCHEMA>) => {
    if (code.genCode !== code.inputCode) {
      setCode((prev) => ({ ...prev, error: "Invalid Captcha" }));
    } else {
      setCode((prev) => ({ ...prev, error: "" }));
      const { email, password } = values;
      dispatch(
        loginAsync({
          businessEmail: email && email?.trim(),
          password: password && password?.trim(),
        })
      );
    }
  };

  const redirectOnForgotPage = () => {
    navigate({ pathname: "/forgot-validate" });
  };

  // console.log({ accessToken, currentUser });

  return (
    <>
      {/* <Form {...form}> */}
      <div className={cn("", className)} {...props}>
        <form onSubmit={formik.handleSubmit}>
          <Card className=" md:my-8 rounded-none border-none">
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl text-primary uppercase p-0">
                Login
              </CardTitle>
              <CardDescription>
                Enter Registered Email Id and Password for Login
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-2">
              {error && (
                <div className="  bg-red-50 border border-destructive rounded p-2 mb-2">
                  {status == 404 ? <span className="font-bold text-destructive">{errorMessasge}</span> : <span className="font-bold text-destructive">{errorMessasge && "Your email ID or password is incorrect "}</span>}

                  {attempts && attempts > 0 && attempts < 3 ? (
                    <>
                      <br />
                      <span className="text-xs font-light text-destructive ">
                        You have {attempts} attempts left out of 3
                      </span>
                    </>
                  ) : null}

                  {status == 401 && attempts == 0 ? (
                    <>
                      <br />
                      <span className="text-xs text-destructive ">
                        you have completed 3 attempts please
                        <span
                          className="text-blue font-semibold underline mx-2 cursor-pointer"
                          onClick={() => {
                            navigate("/forgot-validate")
                          }}
                        >Click Link</span>
                      </span>
                    </>
                  ) : null}

                </div>
              )}

              <RenderFormikFiled
                formik={formik}
                formObject={LoginFormFields.email}
                required
                onchange={(e: any) => formik.setFieldValue("email", e)}
              />
              <RenderFormikFiled
                formik={formik}
                formObject={LoginFormFields.password}
                required
                onchange={(e: any) => formik.setFieldValue("password", e)}
              />

              <div className="flex items-center justify-between ">
                <div className="flex items-center">
                  <Checkbox
                    id="is_me signin"
                    onChange={(e: any) =>
                      formik.setFieldValue("is_remember_me", e.target.value)
                    }
                  />
                  {/* <Input type='checkbox'  {...field} /> */}
                  <label
                    htmlFor="terms"
                    className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ml-1"
                  >
                    Remember Me
                  </label>
                </div>
                <div>
                  <span
                    onClick={redirectOnForgotPage}
                    className=" hover:underline text-sm text-primary font-medium cursor-pointer"
                  >
                    {" "}
                    Forgot password
                  </span>
                </div>
              </div>
              <div className=" flex justify-center flex-col items-center">
                <div className="flex ">
                  <div className=" border  mx-2">
                    <Input
                      className="w-60 border border-1 rounded text-center text-lg"
                      value={code.inputCode}
                      onChange={(e) =>
                        setCode((prevCode) => ({
                          ...prevCode,
                          inputCode: e.target.value,
                        }))
                      }
                      onFocus={() =>
                        setCode((prevCode) => ({ ...prevCode, error: "" }))
                      }
                      placeholder="Enter captcha text "
                    />
                  </div>
                  <ClientCaptcha
                    captchaCode={(code: any) =>
                      setCode((prevCode) => ({ ...prevCode, genCode: code }))
                    }
                  />
                </div>
                <p className=" text-red-500 capitalize">{code.error}</p>
              </div>

              <div className="flex flex-col justify-center w-full">
                <Button disabled={(!formik.values.email && !formik.values.password) || (status == 401 && (attempts == 0 || errorMessasge == "your account is locked") || isLoading)} className="w-full mb-2">
                  {isLoading && isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Login
                </Button>
                <div className="text-sm text-center">
                  Don't have an account?
                  <Link to="/auth/register">
                    <span className="font-medium text-primary cursor-pointer ml-2">
                      New User Registration
                    </span>
                  </Link>
                </div>
                <div className="text-sm text-center">
                  Change Representative Request?
                  <Link to="/change-representative">
                    <span className="font-medium hover:text-primary hover:underline cursor-pointer ml-2">
                    Click Here
                    </span>
                  </Link>
                </div>
              </div>
              <div className="flex lg:flex-row md:hidden">
                <div className="md:grow p-1">
                  <Link to='/faq' target="_blank">
                    <div className=" p-1 md:mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300 rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/faq.svg')} className=' w-[23px] h-[23px] mt-1 mr-2 fill-primary' ></SVGC>
                      <p>FAQ</p>
                    </div>
                  </Link>
                </div>
                <div className="md:grow p-1">
                  <Link to={process.env.REACT_APP_HANDBOOK_URL || ""} target="_blank">
                    <div className=" p-1 md:mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300  rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/handbook.svg')} className=' w-[23px] h-[23px] mt-1 mr-2' ></SVGC>
                      <p>Handbook</p>
                    </div>
                  </Link>
                </div>
                <div className="md:grow p-1">
                  <Link to={process.env.REACT_APP_USER_MANUAL || ""} target="_blank">
                    <div className=" p-1 md:mt-[80px] flex justify-center cursor-pointer border-solid border border-gray-300 rounded-sm text-primary">
                      <SVGC path={toAbsoluteUrl('/media/svg/usermanual.svg')} className=' w-[23px] h-[23px] mt-1 mr-2' ></SVGC>
                      <p>User Manual</p>
                    </div>
                  </Link>
                </div>
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
      {/* </Form> */}
    </>
  );
};

export default UserAuthForm;

///change-representative
