import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { useMainContext } from "../MainContext";
import LightBtn from "src/components/Common/LightBtn";
import { ICompanyProfile } from "../modules";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { getCompanyDetails, uploadfile } from "src/services/services";
import { useDispatch } from "react-redux";
import {
  createCompanyProfileAsync,
  fetchCompanyDetailsAsync,
  resetSave,
  resetUPdateResponse,
  setSelectedCompany,
} from "src/Silcers/companySlicer";
import { Loader2 } from "lucide-react";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { ENUM_KYC_STATUS, company_profile_view_headings, toAbsoluteUrl } from "src/Helpers";
import { Label } from "src/components/ui/Label";
import { format } from "date-fns";
import ViewFIleDialog from "src/components/ViewFIleDialog";
import { SVGC } from "src/Helpers/SVGC";
import { Badge } from "src/components/ui/badge";
import UpdateCompanyKYC from "./UpdateCompanyKYC";

type Props = {};

function CompanyProfileView({ }: Props) {
  const {
    compayProfile: {
      companyProfileForm: formData,
      isLoading,
      error,
      message,
      saved,
    },
    login: {
      isNewUser
    }
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUPdateResponse());
    dispatch(fetchCompanyDetailsAsync());
    return () => { };
  }, []);

  useEffect(() => {
    if (isNewUser) {
      navigate('/company-profile')
    }

    return () => { }
  }, [isNewUser])


  const kycStatusIcon = (status: string | undefined) => {
    if (status == ENUM_KYC_STATUS.APPROVED) {
      return <Badge className="text-xs"  variant={"default"}>Approved</Badge>
    }
    else if (status == ENUM_KYC_STATUS.REJECTED) {
      return <Badge className="text-xs"  variant={"destructive"}>Rejected</Badge>
    }
    else if (status == ENUM_KYC_STATUS.PENDING) {
      return <Badge className="text-xs"  variant={"secondary"}>Pending</Badge>
    }
  }

  return (
    <div className="p-4 lg:w-4/4 lg:mx-auto">
      <div className="px-6">
        <div className="card bg-base-100 rounded-md">
          <PageHeadingComponent
            mainContent={company_profile_view_headings[0]["mainHeading"]}
            subContent={""}
          />
        </div>
      </div>
      <div><UpdateCompanyKYC currentCompanyDetails={formData} /></div>
      <div className="p-7 grid grid-cols-1 gap-3">
        {/* type of company and rc number */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">Rc Number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.rc_number}{" "}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">State of Company</h4> */}
            {/* <Label className="uppercase">State of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.state_of_company}{" "}
            </p> */}
          </div>
        </div>
        {/* Type of Company and Name of Company  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Type Of Company</h4> */}
            <Label className="uppercase">Type Of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.type_of_company}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Name of Company</h4> */}
            <Label className="uppercase">Name of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.company_name}
            </p>
          </div>
        </div>
        {/* SECtor and Nature of Business  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Sector</h4> */}
            <Label className="uppercase">Sector</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.sector}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Nature of Business</h4> */}
            <Label className="uppercase">Nature of Business</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.nature_of_business}
            </p>
          </div>
        </div>
        {/* Name of company And TIN NUMBER */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Tin number</h4> */}
            <Label className="uppercase">Tin number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.tin_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Bank Verification Number (BVN) </h4> */}
            <Label className="uppercase">TCC Number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.tcc_number}
            </p>
          </div>
        </div>

        {/* bussiness email and phone  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Business email</h4> */}
            <Label className="uppercase">Business email</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.business_email}{" "}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Business phone number</h4> */}
            <Label className="uppercase">Business phone number</Label>
            <p className=" sub-style text-sm mt-1">
              +{formData && formData.business_phone_country_code}
              {"-"}
              {formData && formData.business_phone_number}
            </p>
          </div>
        </div>
        {/* Country and state  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Country</h4> */}
            <Label className="uppercase">Country</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.country}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">State</h4> */}
            <Label className="uppercase">State</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.state}{" "}
            </p>
          </div>
        </div>
        {/* City and Zip code  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">City</h4> */}
            <Label className="uppercase">City</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.city}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Zip Code</h4> */}
            <Label className="uppercase">Zip Code</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.zipcode}
            </p>
          </div>
        </div>
        {/* Company Address 1 / 2 */}
        <div>
          {/* <h4 className=" uppercase">Company Address 1</h4> */}
          <Label className="uppercase">Address Line 1</Label>
          <p className=" sub-style text-sm mt-1">
            {formData && formData.company_address1}
          </p>
        </div>
        <div>
          {/* <h4 className=" uppercase">Company Address 2</h4> */}
          <Label className="uppercase">Address Line 2</Label>
          <p className=" sub-style text-sm mt-1">
            {formData && formData.company_address2}{" "}
          </p>
        </div>
        {/* Type of representative and designation  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Type Of Representative</h4> */}
            <Label className="uppercase">Type Of Representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.type_of_representative}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Designation Of Representative</h4> */}
            <Label className="uppercase">Designation Of Representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.designation_of_representative}
            </p>
          </div>
        </div>
        {/* Name of representative and national identification no */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Name of representative</h4> */}
            <Label className="uppercase">Name of representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.name_of_representative}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">
              National Identification Number of Representative (NIN)
            </h4> */}
            <Label className="uppercase">
              National Identification Number of Representative (NIN)
            </Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.national_identification_number}
            </p>
          </div>
        </div>
        {/* Bank verification no  and Passport NO of Represntative*/}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Bank Verification Number (BVN) </h4> */}
            <Label className="uppercase">Bank Verification Number (BVN)</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.bank_verification_number}
            </p>
          </div>
          <div>

          </div>
        </div>
        {/* crepc NO and PAsport expiry */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">
              Passport no. of representative{" "}
            </Label>
            {/* <h4 className=" uppercase">Passport number of representative </h4> */}
            <p className=" sub-style text-sm mt-1">
              {formData && formData.passport_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Passport Expiry</h4> */}
            <Label className="uppercase">Passport Expiry OF REPRESENTATIVE</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.passport_expiry}
            </p>
          </div>
        </div>
        {/* Quota position utilzed quota  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Cerpac no.</h4> */}
            <Label className="uppercase">CERPAC No. Of Representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.cerpac_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Quota positions</h4> */}
            <Label className="uppercase">CERPAC EXPIRY OF REPRESENTATIVE</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.cerpac_expiry}
            </p>
          </div>
          {/* <div>
            <Label className="uppercase">Quota positions</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_position}
            </p>
          </div> */}
        </div>
        {/* UN-Utilized qutoa Upload CAC TCC ,reveewal Additional Document*/}
        {/* <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">Utilized quota</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_utilized}
            </p>
          </div>
          <div>
            <Label className="uppercase">UN-Utilized quota</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_remaining}
            </p>
          </div>
          <div></div>
        </div> */}
        <div>
          {/* <h4 className=" uppercase">
            upload cac : corporate Affairs commission
          </h4> */}
          <Label className="uppercase">
          UPLOAD C.A.C DOCUMENT: CERTIFICATE OF INCORPORATION
          </Label>

          <p className=" sub-style text-sm mt-1">
            {/* {formData && formData?.cac && formData?.cac instanceof File
                ? formData?.cac?.name
                : null} */}
            {/* {typeof formData?.cac === "string"
                ? formData?.cac
                : null} */}
            {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
            {formData && formData._id && formData.cac && <div className=" flex items-center space-x-2">
              <ViewFIleDialog doc_id={formData._id} fileType={"companydocs"} file_key={"cac"} title="CAC Document" file_ext={formData.cac as string} />
              <div>{kycStatusIcon(formData?.cacdocument)}</div>
            </div>}
            {/* {JSON.stringify(formData && formData.cac) as string} */}
          </p>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload T.c.c : Tax clearance certificate
          </Label>

          <p className=" sub-style text-sm mt-1">
            {/* {formData && formData?.tcc && formData?.tcc instanceof File
                ? formData?.tcc?.name
                : null} */}
            {/* {typeof formData?.tcc === "string"
                ? formData?.tcc
                : null} */}
            {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
            {formData && formData._id && formData.tcc && <div className="flex items-center space-x-2">
              <ViewFIleDialog doc_id={formData._id} fileType={"companydocs"} file_key={"tcc"} title="TCC Document" file_ext={formData.tcc as string} />
              <div>{kycStatusIcon(formData?.tccdocument)}</div>
            </div>}
          </p>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload I.T.F : Industrial Training Fund
          </Label>

          <div className=" sub-style text-sm mt-1">
            {/* {formData && formData?.tcc && formData?.tcc instanceof File
                ? formData?.tcc?.name
                : null} */}
            {/* {typeof formData?.tcc === "string"
                ? formData?.tcc
                : null} */}
            {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
            {formData && formData._id && formData.itf && <div className="flex items-center space-x-2 ">
              <ViewFIleDialog doc_id={formData._id} fileType={"companydocs"} file_key={"itf"} title="ITF Document" file_ext={formData.itf as string} />
              <div>{kycStatusIcon(formData?.itfdocument)}</div>
            </div>}
          </div>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload Pencom
          </Label>

          <div className=" sub-style text-sm mt-1">
            {/* {formData && formData?.tcc && formData?.tcc instanceof File
                ? formData?.tcc?.name
                : null} */}
            {/* {typeof formData?.tcc === "string"
                ? formData?.tcc
                : null} */}
            {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
            {formData && formData._id && formData.pancom && <div className="flex items-center space-x-2">
              <ViewFIleDialog doc_id={formData._id} fileType={"companydocs"} file_key={"pancom"} title="Pencom Document" file_ext={formData.pancom as string} />
              <div>{kycStatusIcon(formData?.pancomdocument)}</div>
            </div>}
          </div>

        </div>

        <div>
          {/* <h4 className=" uppercase">
            upload Renewal of expatritate quota positions{" "}
          </h4> */}
          {/* <Label className="uppercase">
            upload Renewal of expatritate quota positions
          </Label>
          <Link className=" text-primary font-medium uppercase" to="*">
            <p className=" sub-style text-sm mt-1">
              {typeof formData?.expatriate_quota_position === "string"
                ? formData?.expatriate_quota_position
                : null}
            </p>
          </Link> */}
        </div>

        <div>
          {/* <h4 className=" uppercase">
            upload Renewal of expatritate quota positions{" "}
          </h4> */}
          <div className="flex flex-col space-y-1">
            <Label className="uppercase">
            UPLOAD C.A.C : CERTIFICATE OF INCORPORATION STATUS
            </Label>
            {/* <span className="text-xs">Other additional document related to Quota approval or Quota return certificate.</span> */}
          </div>

          <p className=" sub-style text-sm mt-1">
            {/* {
                JSON.stringify(
                  formData && formData.expatriate_quota_position
                ) as string
              } */}
            {/* {formData &&
              formData?.expatriate_quota_position &&
              formData?.expatriate_quota_position instanceof File
                ? formData?.expatriate_quota_position?.name
                : null} */}
            {/* {typeof formData?.additional_document === "string"
                ? formData?.additional_document
                : null} */}
            {/* <div className="text-sm  text-primary cursor-pointer">View Document</div> */}
            {formData && formData._id && formData.additional_document && <ViewFIleDialog doc_id={formData._id} fileType={"companydocs"} file_key={"additional_document"} title="Additional Document" file_ext={formData.additional_document as string} />}
          </p>

        </div>

        {/* <div className="grid grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Upload addiotional document</h4>
            <Link className=" text-primary font-medium uppercase" to="*">
              <p className=" sub-style text-sm">{formData.rc_number}</p>
            </Link>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center w-full">
        {/* <Button
          className="mb-2 px-10 w-44 mx-5 bg-secondary-foreground"
          onClick={() => setActiveTab(1)}
        >
          Edit
        </Button> */}
        <LightBtn
          onclick={() => {
            navigate("/company-profile-edit");
          }}
          title="Edit"
        />
        {/* <Button
                    disabled={isLoading}
                    variant={error ? "secondary" : "default"}
                    className="mb-2 px-10 w-44 mx-5"
                    onClick={SubmitForm}
                >
                    {isLoading && isLoading && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Continue
                </Button> */}
      </div>
    </div>
  );
}

export default CompanyProfileView;
