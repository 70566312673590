import React, { useState } from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from "src/components/ui/dialog";
import TooltipComponent from "src/components/ui/NewYork/TooltipComponent";
import { Button } from "src/components/ui/button";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Loader2 } from 'lucide-react';
import { Label } from '@radix-ui/react-label';
import { useFormik } from 'formik';
import * as yup from 'yup';
import RenderFormikFiled from 'src/components/Common/RenderFormikFiled';
import { forgotPasswordAfterLogin } from 'src/services/services';
import { cn } from 'src/lib/utils';
import SuccessDialog from 'src/components/SuccessDialog';
import { removeAuth, useAuth } from '../Auth/Core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getLoginReducerState } from 'src/Silcers/loginSlicer';
import { IRequestUsestate } from 'src/types';
import axios, { AxiosError } from 'axios';
import ErrorDialog from 'src/components/ErrorDialog';
import LoadingDialog from 'src/components/LoadingDialog';

const formField = {
    "old_password": {
        key: "old_password",
        label: "Old Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Password",
        regex: /[^A-Za-z]+/g,
        maxLength: 30
    },
    "new_password": {
        key: "new_password",
        label: "New Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "New Password",
        // regex: /[^A-Za-z ]+/g,
        maxLength: 30
    },
    "confirm_password": {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Confirm Password",
        caseSensetiveLabel: true,
        maxLength: 20,
        // regex: /[^A-Za-z0-9]+/g,
        //blockletters: true
    },
}
const errorMessages = {
    password: "Please enter password",
    confirm_password: "Please enter confirm password",
    confirm_password_not_match: "Password and confirm password not match",
}
type PassReset = {
    old_password: string,
    new_password: string,
    confirm_password: string,
}

const yupSchema = yup.object().shape({
    old_password: yup.string().matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/, "Password length should be between 8-18 characters, including capital letters, numbers, and special characters. ").required("Old Password is required").min(8).max(18),
    new_password: yup.string().required("New Password is Required").test('password', (value, validationcontext) => {
        const { createError, parent: { password } } = validationcontext;
        if (!value) {
            return createError({ message: errorMessages.password });
        }
        if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/)) {
            return createError({ message: "Password length should be between 8-18 characters, including capital letters, numbers, and special characters. " });
        }
        return true;
    }),
    confirm_password: yup.string().required("Confirm Password is Required").test('confirm_password', (value, validationcontext) => {
        const { createError, parent: { new_password } } = validationcontext;
        if (!value) {
            return createError({ message: errorMessages.password });
        }
        if (new_password != value) {
            return createError({ message: errorMessages.confirm_password_not_match });
        }
        return true;
    }),
})

const DialogReset = ({ openDeleteModal, setopenDeleteModal, currentUser }: any) => {
    const [paswordIsValid, setPaswordIsValid] = useState(false);
    const { user } = useSelector((state: RootState) => getLoginReducerState(state))
    const navigate = useNavigate();
    const [requestState, setrequestState] = useState<IRequestUsestate>({
        isLoading: false,
        isError: false,
        message: "",
        status: undefined
    })

    const formik = useFormik<PassReset>({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: yupSchema,
        onSubmit: (value: PassReset) => {
            submitHandler(value);
        },
    });
    const submitHandler = async (value: any) => {
        try {
            setrequestState({ ...requestState, isError: false, message: "", isLoading: true })
            let data: any = value;
            data = {
                "businessEmail": user.businessEmail,
                "oldpassword": data.old_password,
                "newpassword": data.new_password,
            }
            let apiRes = await forgotPasswordAfterLogin(data);
            if (apiRes.status === 200) {
                setPaswordIsValid(true);
                setopenDeleteModal(false);
            } else {
                formik.setFieldError("old_password", "Old password doesn't match");
            }
            // console.log("RES", apiRes)
            setrequestState({ ...requestState, isLoading: false, status: apiRes.status })
        } catch (error) {
            let err = error as Error | AxiosError
            if (axios.isAxiosError(err)) {
                setrequestState({ ...requestState, isError: true, message: err.response?.data?.message, isLoading: false, status: err.response?.status })
            }
            else {
                setrequestState({ ...requestState, isError: true, message: err.message, isLoading: false })
            }
        }

    };

    return <>
        <Dialog
            open={openDeleteModal}
            onOpenChange={(e: any) => setopenDeleteModal(e)}
        >
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <PageHeadingComponent
                        mainContent="Reset Password"
                        subContent={`Please set appropriate password and confirm password`}
                    />
                </DialogHeader>
                <DialogDescription>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid grid-cols-1 gap-x-2">
                            <div>
                                <RenderFormikFiled
                                    formik={formik}
                                    formObject={formField.old_password}
                                    required
                                    onBlur={(e) => e && e && formik.setFieldTouched("old_password")}
                                    onchange={(e: any) =>
                                        formik.setFieldValue("old_password", e)
                                    }
                                />
                            </div>
                            <div>
                                <RenderFormikFiled
                                    formik={formik}
                                    formObject={formField.new_password}
                                    required
                                    {...formik.getFieldProps("new_password")}
                                    // onBlur={()=>formik.setFieldTouched('new_password')}
                                    onchange={(e: any) =>
                                        formik.setFieldValue("new_password", e)
                                    }
                                />
                            </div>
                            <div>
                                <RenderFormikFiled
                                    formik={formik}
                                    formObject={formField.confirm_password}
                                    required
                                    {...formik.getFieldProps("confirm_password")}
                                    // onBlur={()=>formik.setFieldTouched('confirm_password')}
                                    onchange={(e: any) =>
                                        formik.setFieldValue("confirm_password", e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="w-full flex justify-center mt-4">
                            {/* <DialogTrigger asChild> */}
                            <Button
                                type="submit"
                                variant="default"
                                onClick={() => {
                                    formik.submitForm()
                                }}
                                className=" inline-block px-16">
                                Update
                            </Button>
                            {/* </DialogTrigger> */}
                        </div>
                    </form>
                </DialogDescription>
                <DialogFooter>
                </DialogFooter>
            </DialogContent>
        </Dialog>
        <LoadingDialog isOpen={requestState.isLoading} />
        <ErrorDialog isOpen={requestState.isError} message={requestState.message} onCancelClick={() => setrequestState({ ...requestState, isError: false, message: "" })} />
        <SuccessDialog isOpen={paswordIsValid} title={"Password Changed Successfully"} onClickOkBtn={() => {

            removeAuth();
            navigate("/auth");
            window.location.reload();
        }}  >
            <p>

            </p>
        </SuccessDialog>
    </>
}
const PasswordReset = ({ }: any) => {
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const { user } = useSelector((state: RootState) => getLoginReducerState(state))
    return (
        <div>
            <div>
                <Button
                    onClick={() => setopenDeleteModal(true)}
                    variant={"ghost"}
                    className={cn("w-full justify-start  text-gray-500 active:text-white ", {})}>
                    Password Reset
                </Button>
            </div>
            <DialogReset currentUser={user?.businessEmail} openDeleteModal={openDeleteModal} setopenDeleteModal={setopenDeleteModal} />

        </div>
    )
}

export default PasswordReset