import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import {
  CREATE_EMPLOYEE_INIT
} from "src/Modules/EmpExpatriate/modules";
import {
  forgotPassword, resetPassword, resetPasswordAndSecurityQuationApi
} from "src/services/services";
import { RootState } from "src/store";


// Define a type for the slice state
interface ISetNewPassWordReducer {
  isLoading: boolean;
  isValid: boolean;
  isError: boolean;
  message: string | undefined;
  status: number | undefined;
}

// Define the initial state using that type
const initialState: ISetNewPassWordReducer = {
  isLoading: false,
  isValid: false,
  isError: false,
  message: undefined,
  status: undefined,
};

// Create an async thunk for making the API call
export const setPasswordAndSecurityQtnAsync: any = createAsyncThunk(
  "setPasswordAndSecurityQtn/set",
  async (
    data: {
      security_question1: string | undefined;
      security_answer1: string | undefined;
      security_question2: string | undefined;
      security_answer2: string | undefined;
      security_question3: string | undefined;
      security_answer3: string | undefined;
      password: string | undefined;
      token: string
    },
    thunkApi
  ) => {
    try {
      const postData = {
        security_question1: data.security_question1,
        security_answer1: data.security_answer1,
        security_question2: data.security_question2,
        security_answer2: data.security_answer2,
        security_question3: data.security_question3,
        security_answer3: data.security_answer3,
        password:data.password
      }
      const token = data.token

      const response: any = await resetPasswordAndSecurityQuationApi(postData, token);
      // console.log({ response });
      if (response.status == 200) {
        return response;
      } 
      // else if ([401, 400, 500, 404, 403].includes(response.status)) {
      //   thunkApi.dispatch(setError({
      //     status: response.status,
      //     message: response.message
      //   }));
      //   return thunkApi.rejectWithValue(response);
      // }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError({
          status: error.response?.data.status,
          message: error.response?.data.message
        }));
        return thunkApi.rejectWithValue({
          status: error.response?.data.status,
          message: error.response?.data.message
        });
      }
      thunkApi.dispatch(setError({
        status: undefined,
        message: error.message
      }));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const setPasswordAndSecurityQtnSlicer = createSlice({
  name: "setPasswordAndSecurityQtn",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<any>) => {
      state.isError = true;
      state.message = action.payload?.message;
      state.status = action.payload?.status;
    },
    resetError: (state) => {
      state.isError = false;
      state.status = 0;
      state.message = undefined;
    },
    resetState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = undefined;
      state.isValid = false;
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setPasswordAndSecurityQtnAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.isLoading = true;
          state.isError = false;
          state.message = undefined;
          state.isValid = false;
        }
      )
      .addCase(
        setPasswordAndSecurityQtnAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.isError = false;
          state.message = undefined;
          state.isValid = true;
          state.status = action.payload?.status
        }
      )
      .addCase(
        setPasswordAndSecurityQtnAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload.message;
          state.isValid = false;
          state.status = action.payload.status;
        }
      );
  },
});

export const {
  resetState,
  setError,
  resetError
} = setPasswordAndSecurityQtnSlicer.actions;


export const passwordAndSecurityQtnState = (state: RootState) => state.passwordAndSecurityQtn

export default setPasswordAndSecurityQtnSlicer.reducer;
//  export default []
