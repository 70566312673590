import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import { useDispatch } from "react-redux";
import { Button } from "src/components/ui/button"
import { taskSchema } from "../data/schema"
import { settoBeDeleteEmp } from "src/Silcers/employeesSlicer";
import { EyeNoneIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { SubmitHandler, useForm } from "react-hook-form"
import * as z from "zod"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog"
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { useEffect, useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form"
import { Input } from "src/components/ui/Input"
import DeleteEmployee from "./DeleteEmployee";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {

  const [next, setNext] = useState<boolean>(false);
  const task = taskSchema.parse(row.original)
  const dispatch = useDispatch();

  const formSchema = z.object({
    delLetter: z.string().min(1, "Please upload a valid file"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    // resolver: zodResolver(formSchema)
  })
  const onSubmit = (data: any) => {
    // Handle form submission with valid data
    // console.log(data);
  };

  useEffect(() => {
    return () => {
      setNext(false)
    }
  }, [])
  // <div onClick={() => {
  //   dispatch(settoBeDeleteEmp(row.original))
  // }}>
  //   Delete
  // </div>
  return (
    // <Dialog>
    //   <div className="flex items-center space-x-4">
    //     <button className="text-blue-500 hover:text-blue-700">
    //       <EyeNoneIcon />
    //     </button>
    //     <button className="text-green-500 hover:text-green-700">
    //       <Pencil1Icon />
    //     </button>
    //     <button onClick={() => dispatch(settoBeDeleteEmp(row.original))} className="text-red-500 hover:text-red-700">
    //       <DialogTrigger asChild>
    //         <TrashIcon />
    //       </DialogTrigger>
    //     </button>
    //   </div>
    //   {!next ? <DialogContent className="sm:max-w-[525px]">
    //     <DialogHeader>
    //       <PageHeadingComponent
    //         mainContent="Delete Confirm"
    //         subContent={`Are you sure, you want to delete the employee/expatriate`}
    //       />
    //     </DialogHeader>
    //     <DialogFooter>
    //       <div className="w-full flex justify-around">
    //         <DialogTrigger asChild>
    //           <Button variant={'outline'} className=" inline-block px-16">No</Button>
    //         </DialogTrigger>
    //         <Button onClick={() => { setNext(true) }} className=" inline-block px-16">Yes</Button>
    //       </div>
    //     </DialogFooter>
    //   </DialogContent> : 
    //    <DialogContent className="sm:max-w-[525px]">
    //     <DialogHeader>
    //       <PageHeadingComponent
    //         mainContent="Request for Quota Deletion"
    //         subContent={``}
    //       />
    //     </DialogHeader>
    //     <Form {...form}>
    //       <form onSubmit={form.handleSubmit(onSubmit)}>
    //         <DialogFooter>
    //           <div className="w-full">
    //             <FormField
    //               control={form.control}
    //               name="delLetter"
    //               render={({ field }) => (
    //                 <FormItem>
    //                   <FormLabel className=" uppercase">please upload quota deletion letter</FormLabel>
    //                   <FormControl>
    //                     <Input type='file' placeholder="Select Document" {...field} />
    //                   </FormControl>
    //                   <FormMessage />
    //                 </FormItem>
    //               )}
    //             />
    //             <p onClick={() => { }} className=' text-right uppercase text-primary text-base font-medium my-3 cursor-pointer hover:underline'>+ Add more</p>
    //           </div>
    //         </DialogFooter>
    //         <div className="w-full flex justify-around">
    //           <DialogTrigger asChild>
    //             <Button variant={'outline'} className=" inline-block px-16">Cancel</Button>
    //           </DialogTrigger>
    //           <Button onClick={() => { setNext(true) }} className=" inline-block px-16">Upload</Button>
    //         </div>
    //       </form>
    //     </Form>

    //   </DialogContent>
    //   }
    // </Dialog>  
    <>
      <DeleteEmployee row={row} />
    </>
  )
}

