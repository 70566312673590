export const country_codes= [
  {
    "name": "Afghanistan",
    "dial_code": "+93",
    "emoji": "🇦🇫",
    "code": "AF",
    "flag": "Files/flags/af.svg"
  },
  {
    "name": "Aland Islands",
    "dial_code": "+358",
    "emoji": "🇦🇽",
    "code": "AX",
    "flag": "Files/flags/ax.svg"
  },
  {
    "name": "Albania",
    "dial_code": "+355",
    "emoji": "🇦🇱",
    "code": "AL",
    "flag": "Files/flags/al.svg"
  },
  {
    "name": "Algeria",
    "dial_code": "+213",
    "emoji": "🇩🇿",
    "code": "DZ",
    "flag": "Files/flags/dz.svg"
  },
  {
    "name": "AmericanSamoa",
    "dial_code": "+1684",
    "emoji": "🇦🇸",
    "code": "AS",
    "flag": "Files/flags/as.svg"
  },
  {
    "name": "Andorra",
    "dial_code": "+376",
    "emoji": "🇦🇩",
    "code": "AD",
    "flag": "Files/flags/ad.svg"
  },
  {
    "name": "Angola",
    "dial_code": "+244",
    "emoji": "🇦🇴",
    "code": "AO",
    "flag": "Files/flags/ao.svg"
  },
  {
    "name": "Anguilla",
    "dial_code": "+1264",
    "emoji": "🇦🇮",
    "code": "AI",
    "flag": "Files/flags/ai.svg"
  },
  {
    "name": "Antarctica",
    "dial_code": "+672",
    "emoji": "🇦🇶",
    "code": "AQ",
    "flag": "Files/flags/aq.svg"
  },
  {
    "name": "Antigua and Barbuda",
    "dial_code": "+1268",
    "emoji": "🇦🇬",
    "code": "AG",
    "flag": "Files/flags/ag.svg"
  },
  {
    "name": "Argentina",
    "dial_code": "+54",
    "emoji": "🇦🇷",
    "code": "AR",
    "flag": "Files/flags/ar.svg"
  },
  {
    "name": "Armenia",
    "dial_code": "+374",
    "emoji": "🇦🇲",
    "code": "AM",
    "flag": "Files/flags/am.svg"
  },
  {
    "name": "Aruba",
    "dial_code": "+297",
    "emoji": "🇦🇼",
    "code": "AW",
    "flag": "Files/flags/aw.svg"
  },
  {
    "name": "Australia",
    "dial_code": "+61",
    "emoji": "🇦🇺",
    "code": "AU",
    "flag": "Files/flags/au.svg"
  },
  {
    "name": "Austria",
    "dial_code": "+43",
    "emoji": "🇦🇹",
    "code": "AT",
    "flag": "Files/flags/at.svg"
  },
  {
    "name": "Azerbaijan",
    "dial_code": "+994",
    "emoji": "🇦🇿",
    "code": "AZ",
    "flag": "Files/flags/az.svg"
  },
  {
    "name": "Bahamas",
    "dial_code": "+1242",
    "emoji": "🇧🇸",
    "code": "BS",
    "flag": "Files/flags/bs.svg"
  },
  {
    "name": "Bahrain",
    "dial_code": "+973",
    "emoji": "🇧🇭",
    "code": "BH",
    "flag": "Files/flags/bh.svg"
  },
  {
    "name": "Bangladesh",
    "dial_code": "+880",
    "emoji": "🇧🇩",
    "code": "BD",
    "flag": "Files/flags/bd.svg"
  },
  {
    "name": "Barbados",
    "dial_code": "+1246",
    "emoji": "🇧🇧",
    "code": "BB",
    "flag": "Files/flags/bb.svg"
  },
  {
    "name": "Belarus",
    "dial_code": "+375",
    "emoji": "🇧🇾",
    "code": "BY",
    "flag": "Files/flags/by.svg"
  },
  {
    "name": "Belgium",
    "dial_code": "+32",
    "emoji": "🇧🇪",
    "code": "BE",
    "flag": "Files/flags/be.svg"
  },
  {
    "name": "Belize",
    "dial_code": "+501",
    "emoji": "🇧🇿",
    "code": "BZ",
    "flag": "Files/flags/bz.svg"
  },
  {
    "name": "Benin",
    "dial_code": "+229",
    "emoji": "🇧🇯",
    "code": "BJ",
    "flag": "Files/flags/bj.svg"
  },
  {
    "name": "Bermuda",
    "dial_code": "+1441",
    "emoji": "🇧🇲",
    "code": "BM",
    "flag": "Files/flags/bm.svg"
  },
  {
    "name": "Bhutan",
    "dial_code": "+975",
    "emoji": "🇧🇹",
    "code": "BT",
    "flag": "Files/flags/bt.svg"
  },
  {
    "name": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "emoji": "🇧🇴",
    "code": "BO",
    "flag": "Files/flags/bo.svg"
  },
  {
    "name": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "emoji": "🇧🇦",
    "code": "BA",
    "flag": "Files/flags/ba.svg"
  },
  {
    "name": "Botswana",
    "dial_code": "+267",
    "emoji": "🇧🇼",
    "code": "BW",
    "flag": "Files/flags/bw.svg"
  },
  {
    "name": "Brazil",
    "dial_code": "+55",
    "emoji": "🇧🇷",
    "code": "BR",
    "flag": "Files/flags/br.svg"
  },
  {
    "name": "British Indian Ocean Territory",
    "dial_code": "+246",
    "emoji": "🇮🇴",
    "code": "IO",
    "flag": "Files/flags/io.svg"
  },
  {
    "name": "Brunei Darussalam",
    "dial_code": "+673",
    "emoji": "🇧🇳",
    "code": "BN",
    "flag": "Files/flags/bn.svg"
  },
  {
    "name": "Bulgaria",
    "dial_code": "+359",
    "emoji": "🇧🇬",
    "code": "BG",
    "flag": "Files/flags/bg.svg"
  },
  {
    "name": "Burkina Faso",
    "dial_code": "+226",
    "emoji": "🇧🇫",
    "code": "BF",
    "flag": "Files/flags/bf.svg"
  },
  {
    "name": "Burundi",
    "dial_code": "+257",
    "emoji": "🇧🇮",
    "code": "BI",
    "flag": "Files/flags/bi.svg"
  },
  {
    "name": "Cambodia",
    "dial_code": "+855",
    "emoji": "🇰🇭",
    "code": "KH",
    "flag": "Files/flags/kh.svg"
  },
  {
    "name": "Cameroon",
    "dial_code": "+237",
    "emoji": "🇨🇲",
    "code": "CM",
    "flag": "Files/flags/cm.svg"
  },
  {
    "name": "Canada",
    "dial_code": "+1",
    "emoji": "🇨🇦",
    "code": "CA",
    "flag": "Files/flags/ca.svg"
  },
  {
    "name": "Cape Verde",
    "dial_code": "+238",
    "emoji": "🇨🇻",
    "code": "CV",
    "flag": "Files/flags/cv.svg"
  },
  {
    "name": "Cayman Islands",
    "dial_code": "+345",
    "emoji": "🇰🇾",
    "code": "KY",
    "flag": "Files/flags/ky.svg"
  },
  {
    "name": "Central African Republic",
    "dial_code": "+236",
    "emoji": "🇨🇫",
    "code": "CF",
    "flag": "Files/flags/cf.svg"
  },
  {
    "name": "Chad",
    "dial_code": "+235",
    "emoji": "🇹🇩",
    "code": "TD",
    "flag": "Files/flags/td.svg"
  },
  {
    "name": "Chile",
    "dial_code": "+56",
    "emoji": "🇨🇱",
    "code": "CL",
    "flag": "Files/flags/cl.svg"
  },
  {
    "name": "China",
    "dial_code": "+86",
    "emoji": "🇨🇳",
    "code": "CN",
    "flag": "Files/flags/cn.svg"
  },
  {
    "name": "Christmas Island",
    "dial_code": "+61",
    "emoji": "🇨🇽",
    "code": "CX",
    "flag": "Files/flags/cx.svg"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "emoji": "🇨🇨",
    "code": "CC",
    "flag": "Files/flags/cc.svg"
  },
  {
    "name": "Colombia",
    "dial_code": "+57",
    "emoji": "🇨🇴",
    "code": "CO",
    "flag": "Files/flags/co.svg"
  },
  {
    "name": "Comoros",
    "dial_code": "+269",
    "emoji": "🇰🇲",
    "code": "KM",
    "flag": "Files/flags/km.svg"
  },
  {
    "name": "Congo",
    "dial_code": "+242",
    "emoji": "🇨🇬",
    "code": "CG",
    "flag": "Files/flags/cg.svg"
  },
  {
    "name": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "emoji": "🇨🇩",
    "code": "CD",
    "flag": "Files/flags/cd.svg"
  },
  {
    "name": "Cook Islands",
    "dial_code": "+682",
    "emoji": "🇨🇰",
    "code": "CK",
    "flag": "Files/flags/ck.svg"
  },
  {
    "name": "Costa Rica",
    "dial_code": "+506",
    "emoji": "🇨🇷",
    "code": "CR",
    "flag": "Files/flags/cr.svg"
  },
  {
    "name": "Cote d'Ivoire",
    "dial_code": "+225",
    "emoji": "🇨🇮",
    "code": "CI",
    "flag": "Files/flags/ci.svg"
  },
  {
    "name": "Croatia",
    "dial_code": "+385",
    "emoji": "🇭🇷",
    "code": "HR",
    "flag": "Files/flags/hr.svg"
  },
  {
    "name": "Cuba",
    "dial_code": "+53",
    "emoji": "🇨🇺",
    "code": "CU",
    "flag": "Files/flags/cu.svg"
  },
  {
    "name": "Cyprus",
    "dial_code": "+357",
    "emoji": "🇨🇾",
    "code": "CY",
    "flag": "Files/flags/cy.svg"
  },
  {
    "name": "Czech Republic",
    "dial_code": "+420",
    "emoji": "🇨🇿",
    "code": "CZ",
    "flag": "Files/flags/cz.svg"
  },
  {
    "name": "Denmark",
    "dial_code": "+45",
    "emoji": "🇩🇰",
    "code": "DK",
    "flag": "Files/flags/dk.svg"
  },
  {
    "name": "Djibouti",
    "dial_code": "+253",
    "emoji": "🇩🇯",
    "code": "DJ",
    "flag": "Files/flags/dj.svg"
  },
  {
    "name": "Dominica",
    "dial_code": "+1767",
    "emoji": "🇩🇲",
    "code": "DM",
    "flag": "Files/flags/dm.svg"
  },
  {
    "name": "Dominican Republic",
    "dial_code": "+1849",
    "emoji": "🇩🇴",
    "code": "DO",
    "flag": "Files/flags/do.svg"
  },
  {
    "name": "Ecuador",
    "dial_code": "+593",
    "emoji": "🇪🇨",
    "code": "EC",
    "flag": "Files/flags/ec.svg"
  },
  {
    "name": "Egypt",
    "dial_code": "+20",
    "emoji": "🇪🇬",
    "code": "EG",
    "flag": "Files/flags/eg.svg"
  },
  {
    "name": "El Salvador",
    "dial_code": "+503",
    "emoji": "🇸🇻",
    "code": "SV",
    "flag": "Files/flags/sv.svg"
  },
  {
    "name": "Equatorial Guinea",
    "dial_code": "+240",
    "emoji": "🇬🇶",
    "code": "GQ",
    "flag": "Files/flags/gq.svg"
  },
  {
    "name": "Eritrea",
    "dial_code": "+291",
    "emoji": "🇪🇷",
    "code": "ER",
    "flag": "Files/flags/er.svg"
  },
  {
    "name": "Estonia",
    "dial_code": "+372",
    "emoji": "🇪🇪",
    "code": "EE",
    "flag": "Files/flags/ee.svg"
  },
  {
    "name": "Ethiopia",
    "dial_code": "+251",
    "emoji": "🇪🇹",
    "code": "ET",
    "flag": "Files/flags/et.svg"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "emoji": "🇫🇰",
    "code": "FK",
    "flag": "Files/flags/fk.svg"
  },
  {
    "name": "Faroe Islands",
    "dial_code": "+298",
    "emoji": "🇫🇴",
    "code": "FO",
    "flag": "Files/flags/fo.svg"
  },
  {
    "name": "Fiji",
    "dial_code": "+679",
    "emoji": "🇫🇯",
    "code": "FJ",
    "flag": "Files/flags/fj.svg"
  },
  {
    "name": "Finland",
    "dial_code": "+358",
    "emoji": "🇫🇮",
    "code": "FI",
    "flag": "Files/flags/fi.svg"
  },
  {
    "name": "France",
    "dial_code": "+33",
    "emoji": "🇫🇷",
    "code": "FR",
    "flag": "Files/flags/fr.svg"
  },
  {
    "name": "French Guiana",
    "dial_code": "+594",
    "emoji": "🇬🇫",
    "code": "GF",
    "flag": "Files/flags/gf.svg"
  },
  {
    "name": "French Polynesia",
    "dial_code": "+689",
    "emoji": "🇵🇫",
    "code": "PF",
    "flag": "Files/flags/pf.svg"
  },
  {
    "name": "Gabon",
    "dial_code": "+241",
    "emoji": "🇬🇦",
    "code": "GA",
    "flag": "Files/flags/ga.svg"
  },
  {
    "name": "Gambia",
    "dial_code": "+220",
    "emoji": "🇬🇲",
    "code": "GM",
    "flag": "Files/flags/gm.svg"
  },
  {
    "name": "Georgia",
    "dial_code": "+995",
    "emoji": "🇬🇪",
    "code": "GE",
    "flag": "Files/flags/ge.svg"
  },
  {
    "name": "Germany",
    "dial_code": "+49",
    "emoji": "🇩🇪",
    "code": "DE",
    "flag": "Files/flags/de.svg"
  },
  {
    "name": "Ghana",
    "dial_code": "+233",
    "emoji": "🇬🇭",
    "code": "GH",
    "flag": "Files/flags/gh.svg"
  },
  {
    "name": "Gibraltar",
    "dial_code": "+350",
    "emoji": "🇬🇮",
    "code": "GI",
    "flag": "Files/flags/gi.svg"
  },
  {
    "name": "Greece",
    "dial_code": "+30",
    "emoji": "🇬🇷",
    "code": "GR",
    "flag": "Files/flags/gr.svg"
  },
  {
    "name": "Greenland",
    "dial_code": "+299",
    "emoji": "🇬🇱",
    "code": "GL",
    "flag": "Files/flags/gl.svg"
  },
  {
    "name": "Grenada",
    "dial_code": "+1473",
    "emoji": "🇬🇩",
    "code": "GD",
    "flag": "Files/flags/gd.svg"
  },
  {
    "name": "Guadeloupe",
    "dial_code": "+590",
    "emoji": "🇬🇵",
    "code": "GP",
    "flag": "Files/flags/gp.svg"
  },
  {
    "name": "Guam",
    "dial_code": "+1671",
    "emoji": "🇬🇺",
    "code": "GU",
    "flag": "Files/flags/gu.svg"
  },
  {
    "name": "Guatemala",
    "dial_code": "+502",
    "emoji": "🇬🇹",
    "code": "GT",
    "flag": "Files/flags/gt.svg"
  },
  {
    "name": "Guernsey",
    "dial_code": "+44",
    "emoji": "🇬🇬",
    "code": "GG",
    "flag": "Files/flags/gg.svg"
  },
  {
    "name": "Guinea",
    "dial_code": "+224",
    "emoji": "🇬🇳",
    "code": "GN",
    "flag": "Files/flags/gn.svg"
  },
  {
    "name": "Guinea-Bissau",
    "dial_code": "+245",
    "emoji": "🇬🇼",
    "code": "GW",
    "flag": "Files/flags/gw.svg"
  },
  {
    "name": "Guyana",
    "dial_code": "+595",
    "emoji": "🇬🇾",
    "code": "GY",
    "flag": "Files/flags/gy.svg"
  },
  {
    "name": "Haiti",
    "dial_code": "+509",
    "emoji": "🇭🇹",
    "code": "HT",
    "flag": "Files/flags/ht.svg"
  },
  {
    "name": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "emoji": "🇻🇦",
    "code": "VA",
    "flag": "Files/flags/va.svg"
  },
  {
    "name": "Honduras",
    "dial_code": "+504",
    "emoji": "🇭🇳",
    "code": "HN",
    "flag": "Files/flags/hn.svg"
  },
  {
    "name": "Hong Kong",
    "dial_code": "+852",
    "emoji": "🇭🇰",
    "code": "HK",
    "flag": "Files/flags/hk.svg"
  },
  {
    "name": "Hungary",
    "dial_code": "+36",
    "emoji": "🇭🇺",
    "code": "HU",
    "flag": "Files/flags/hu.svg"
  },
  {
    "name": "Iceland",
    "dial_code": "+354",
    "emoji": "🇮🇸",
    "code": "IS",
    "flag": "Files/flags/is.svg"
  },
  {
    "name": "India",
    "dial_code": "+91",
    "emoji": "🇮🇳",
    "code": "IN",
    "flag": "Files/flags/in.svg"
  },
  {
    "name": "Indonesia",
    "dial_code": "+62",
    "emoji": "🇮🇩",
    "code": "ID",
    "flag": "Files/flags/id.svg"
  },
  {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "emoji": "🇮🇷",
    "code": "IR",
    "flag": "Files/flags/ir.svg"
  },
  {
    "name": "Iraq",
    "dial_code": "+964",
    "emoji": "🇮🇷",
    "code": "IQ",
    "flag": "Files/flags/iq.svg"
  },
  {
    "name": "Ireland",
    "dial_code": "+353",
    "emoji": "🇮🇪",
    "code": "IE",
    "flag": "Files/flags/ie.svg"
  },
  {
    "name": "Isle of Man",
    "dial_code": "+44",
    "emoji": "🇮🇲",
    "code": "IM",
    "flag": "Files/flags/im.svg"
  },
  {
    "name": "Israel",
    "dial_code": "+972",
    "emoji": "🇮🇱",
    "code": "IL",
    "flag": "Files/flags/il.svg"
  },
  {
    "name": "Italy",
    "dial_code": "+39",
    "emoji": "🇮🇹",
    "code": "IT",
    "flag": "Files/flags/it.svg"
  },
  {
    "name": "Jamaica",
    "dial_code": "+1876",
    "emoji": "🇯🇲",
    "code": "JM",
    "flag": "Files/flags/jm.svg"
  },
  {
    "name": "Japan",
    "dial_code": "+81",
    "emoji": "🇯🇵",
    "code": "JP",
    "flag": "Files/flags/jp.svg"
  },
  {
    "name": "Jersey",
    "dial_code": "+44",
    "emoji": "🇯🇪",
    "code": "JE",
    "flag": "Files/flags/je.svg"
  },
  {
    "name": "Jordan",
    "dial_code": "+962",
    "emoji": "🇯🇴",
    "code": "JO",
    "flag": "Files/flags/jo.svg"
  },
  {
    "name": "Kazakhstan",
    "dial_code": "+77",
    "emoji": "🇰🇿",
    "code": "KZ",
    "flag": "Files/flags/kz.svg"
  },
  {
    "name": "Kenya",
    "dial_code": "+254",
    "emoji": "🇰🇪",
    "code": "KE",
    "flag": "Files/flags/ke.svg"
  },
  {
    "name": "Kiribati",
    "dial_code": "+686",
    "emoji": "🇰🇮",
    "code": "KI",
    "flag": "Files/flags/ki.svg"
  },
  {
    "name": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "emoji": "🇰🇵",
    "code": "KP",
    "flag": "Files/flags/kp.svg"
  },
  {
    "name": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "emoji": "🇰🇷",
    "code": "KR",
    "flag": "Files/flags/kr.svg"
  },
  {
    "name": "Kuwait",
    "dial_code": "+965",
    "emoji": "🇰🇼",
    "code": "KW",
    "flag": "Files/flags/kw.svg"
  },
  {
    "name": "Kyrgyzstan",
    "dial_code": "+996",
    "emoji": "🇰🇬",
    "code": "KG",
    "flag": "Files/flags/kg.svg"
  },
  {
    "name": "Laos",
    "dial_code": "+856",
    "emoji": "🇱🇦",
    "code": "LA",
    "flag": "Files/flags/la.svg"
  },
  {
    "name": "Latvia",
    "dial_code": "+371",
    "emoji": "🇱🇻",
    "code": "LV",
    "flag": "Files/flags/lv.svg"
  },
  {
    "name": "Lebanon",
    "dial_code": "+961",
    "emoji": "🇱🇧",
    "code": "LB",
    "flag": "Files/flags/lb.svg"
  },
  {
    "name": "Lesotho",
    "dial_code": "+266",
    "emoji": "🇱🇸",
    "code": "LS",
    "flag": "Files/flags/ls.svg"
  },
  {
    "name": "Liberia",
    "dial_code": "+231",
    "emoji": "🇱🇷",
    "code": "LR",
    "flag": "Files/flags/lr.svg"
  },
  {
    "name": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "emoji": "🇱🇾",
    "code": "LY",
    "flag": "Files/flags/ly.svg"
  },
  {
    "name": "Liechtenstein",
    "dial_code": "+423",
    "emoji": "🇱🇮",
    "code": "LI",
    "flag": "Files/flags/li.svg"
  },
  {
    "name": "Lithuania",
    "dial_code": "+370",
    "emoji": "🇱🇹",
    "code": "LT",
    "flag": "Files/flags/lt.svg"
  },
  {
    "name": "Luxembourg",
    "dial_code": "+352",
    "emoji": "🇱🇺",
    "code": "LU",
    "flag": "Files/flags/lu.svg"
  },
  {
    "name": "Macao",
    "dial_code": "+853",
    "emoji": "🇲🇴",
    "code": "MO",
    "flag": "Files/flags/mo.svg"
  },
  {
    "name": "Macedonia",
    "dial_code": "+389",
    "emoji": "🇲🇰",
    "code": "MK",
    "flag": "Files/flags/mk.svg"
  },
  {
    "name": "Madagascar",
    "dial_code": "+261",
    "emoji": "🇲🇬",
    "code": "MG",
    "flag": "Files/flags/mg.svg"
  },
  {
    "name": "Malawi",
    "dial_code": "+265",
    "emoji": "🇲🇼",
    "code": "MW",
    "flag": "Files/flags/mw.svg"
  },
  {
    "name": "Malaysia",
    "dial_code": "+60",
    "emoji": "🇲🇾",
    "code": "MY",
    "flag": "Files/flags/my.svg"
  },
  {
    "name": "Maldives",
    "dial_code": "+960",
    "emoji": "🇲🇻",
    "code": "MV",
    "flag": "Files/flags/mv.svg"
  },
  {
    "name": "Mali",
    "dial_code": "+223",
    "emoji": "🇲🇱",
    "code": "ML",
    "flag": "Files/flags/ml.svg"
  },
  {
    "name": "Malta",
    "dial_code": "+356",
    "emoji": "🇲🇹",
    "code": "MT",
    "flag": "Files/flags/mt.svg"
  },
  {
    "name": "Marshall Islands",
    "dial_code": "+692",
    "emoji": "🇲🇭",
    "code": "MH",
    "flag": "Files/flags/mh.svg"
  },
  {
    "name": "Martinique",
    "dial_code": "+596",
    "emoji": "🇲🇶",
    "code": "MQ",
    "flag": "Files/flags/mq.svg"
  },
  {
    "name": "Mauritania",
    "dial_code": "+222",
    "emoji": "🇲🇷",
    "code": "MR",
    "flag": "Files/flags/mr.svg"
  },
  {
    "name": "Mauritius",
    "dial_code": "+230",
    "emoji": "🇲🇺",
    "code": "MU",
    "flag": "Files/flags/mu.svg"
  },
  {
    "name": "Mayotte",
    "dial_code": "+262",
    "emoji": "🇾🇹",
    "code": "YT",
    "flag": "Files/flags/yt.svg"
  },
  {
    "name": "Mexico",
    "dial_code": "+52",
    "emoji": "🇲🇽",
    "code": "MX",
    "flag": "Files/flags/mx.svg"
  },
  {
    "name": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "emoji": "🇫🇲",
    "code": "FM",
    "flag": "Files/flags/fm.svg"
  },
  {
    "name": "Moldova",
    "dial_code": "+373",
    "emoji": "🇲🇩",
    "code": "MD",
    "flag": "Files/flags/md.svg"
  },
  {
    "name": "Monaco",
    "dial_code": "+377",
    "emoji": "🇲🇨",
    "code": "MC",
    "flag": "Files/flags/mc.svg"
  },
  {
    "name": "Mongolia",
    "dial_code": "+976",
    "emoji": "🇲🇳",
    "code": "MN",
    "flag": "Files/flags/mn.svg"
  },
  {
    "name": "Montenegro",
    "dial_code": "+382",
    "emoji": "🇲🇪",
    "code": "ME",
    "flag": "Files/flags/me.svg"
  },
  {
    "name": "Montserrat",
    "dial_code": "+1664",
    "emoji": "🇲🇸",
    "code": "MS",
    "flag": "Files/flags/ms.svg"
  },
  {
    "name": "Morocco",
    "dial_code": "+212",
    "emoji": "🇲🇦",
    "code": "MA",
    "flag": "Files/flags/ma.svg"
  },
  {
    "name": "Mozambique",
    "dial_code": "+258",
    "emoji": "🇲🇿",
    "code": "MZ",
    "flag": "Files/flags/mz.svg"
  },
  {
    "name": "Myanmar",
    "dial_code": "+95",
    "emoji": "🇲🇲",
    "code": "MM",
    "flag": "Files/flags/mm.svg"
  },
  {
    "name": "Namibia",
    "emoji": "🇳🇦",
    "dial_code": "+264",
    "code": "NA",
    "flag": "Files/flags/na.svg"
  },
  {
    "name": "Nauru",
    "dial_code": "+674",
    "emoji": "🇳🇷",
    "code": "NR",
    "flag": "Files/flags/nr.svg"
  },
  {
    "name": "Nepal",
    "dial_code": "+977",
    "emoji": "🇳🇵",
    "code": "NP",
    "flag": "Files/flags/np.svg"
  },
  {
    "name": "Netherlands",
    "dial_code": "+31",
    "emoji": "🇳🇱",
    "code": "NL",
    "flag": "Files/flags/nl.svg"
  },
  {
    "name": "Netherlands Antilles",
    "dial_code": "+599",
    "emoji": "🇧🇶",
    "code": "AN",
    "flag": "Files/flags/an.svg"
  },
  {
    "name": "New Caledonia",
    "dial_code": "+687",
    "emoji": "🇳🇨",
    "code": "NC",
    "flag": "Files/flags/nc.svg"
  },
  {
    "name": "New Zealand",
    "dial_code": "+64",
    "emoji": "🇳🇿",
    "code": "NZ",
    "flag": "Files/flags/nz.svg"
  },
  {
    "name": "Nicaragua",
    "dial_code": "+505",
    "emoji": "🇳🇮",
    "code": "NI",
    "flag": "Files/flags/ni.svg"
  },
  {
    "name": "Niger",
    "dial_code": "+227",
    "emoji": "🇳🇪",
    "code": "NE",
    "flag": "Files/flags/ne.svg"
  },
  {
    "name": "Nigeria",
    "dial_code": "+234",
    "emoji": "🇳🇬",
    "code": "NG",
    "flag": "Files/flags/ng.svg"
  },
  {
    "name": "Niue",
    "dial_code": "+683",
    "emoji": "🇳🇺",
    "code": "NU",
    "flag": "Files/flags/nu.svg"
  },
  {
    "name": "Norfolk Island",
    "dial_code": "+672",
    "emoji": "🇳🇫",
    "code": "NF",
    "flag": "Files/flags/nf.svg"
  },
  {
    "name": "Northern Mariana Islands",
    "dial_code": "+1670",
    "emoji": "🇲🇵",
    "code": "MP",
    "flag": "Files/flags/mp.svg"
  },
  {
    "name": "Norway",
    "dial_code": "+47",
    "emoji": "🇳🇴",
    "code": "NO",
    "flag": "Files/flags/no.svg"
  },
  {
    "name": "Oman",
    "dial_code": "+968",
    "emoji": "🇴🇲",
    "code": "OM",
    "flag": "Files/flags/om.svg"
  },
  {
    "name": "Pakistan",
    "dial_code": "+92",
    "emoji": "🇵🇰",
    "code": "PK",
    "flag": "Files/flags/pk.svg"
  },
  {
    "name": "Palau",
    "dial_code": "+680",
    "emoji": "🇵🇼",
    "code": "PW",
    "flag": "Files/flags/pw.svg"
  },
  {
    "name": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "emoji": "🇵🇸",
    "code": "PS",
    "flag": "Files/flags/ps.svg"
  },
  {
    "name": "Panama",
    "dial_code": "+507",
    "emoji": "🇵🇦",
    "code": "PA",
    "flag": "Files/flags/pa.svg"
  },
  {
    "name": "Papua New Guinea",
    "dial_code": "+675",
    "emoji": "🇵🇬",
    "code": "PG",
    "flag": "Files/flags/pg.svg"
  },
  {
    "name": "Paraguay",
    "dial_code": "+595",
    "emoji": "🇵🇾",
    "code": "PY",
    "flag": "Files/flags/py.svg"
  },
  {
    "name": "Peru",
    "dial_code": "+51",
    "emoji": "🇵🇪",
    "code": "PE",
    "flag": "Files/flags/pe.svg"
  },
  {
    "name": "Philippines",
    "dial_code": "+63",
    "emoji": "🇵🇭",
    "code": "PH",
    "flag": "Files/flags/ph.svg"
  },
  {
    "name": "Pitcairn",
    "dial_code": "+872",
    "emoji": "🇵🇳",
    "code": "PN",
    "flag": "Files/flags/pn.svg"
  },
  {
    "name": "Poland",
    "dial_code": "+48",
    "emoji": "🇵🇱",
    "code": "PL",
    "flag": "Files/flags/pl.svg"
  },
  {
    "name": "Portugal",
    "dial_code": "+351",
    "emoji": "🇵🇹",
    "code": "PT",
    "flag": "Files/flags/pt.svg"
  },
  {
    "name": "Puerto Rico",
    "dial_code": "+1939",
    "emoji": "🇵🇷",
    "code": "PR",
    "flag": "Files/flags/pr.svg"
  },
  {
    "name": "Qatar",
    "dial_code": "+974",
    "emoji": "🇶🇦",
    "code": "QA",
    "flag": "Files/flags/qa.svg"
  },
  {
    "name": "Romania",
    "dial_code": "+40",
    "emoji": "🇷🇴",
    "code": "RO",
    "flag": "Files/flags/ro.svg"
  },
  {
    "name": "Russia",
    "dial_code": "+7",
    "emoji": "🇷🇺",
    "code": "RU",
    "flag": "Files/flags/ru.svg"
  },
  {
    "name": "Rwanda",
    "dial_code": "+250",
    "emoji": "🇷🇼",
    "code": "RW",
    "flag": "Files/flags/rw.svg"
  },
  {
    "name": "Reunion",
    "dial_code": "+262",
    "emoji": "🇷🇪",
    "code": "RE",
    "flag": "Files/flags/re.svg"
  },
  {
    "name": "Saint Barthelemy",
    "dial_code": "+590",
    "emoji": "🇧🇱",
    "code": "BL",
    "flag": "Files/flags/bl.svg"
  },
  {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "emoji": "🇸🇭",
    "code": "SH",
    "flag": "Files/flags/sh.svg"
  },
  {
    "name": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "emoji": "🇰🇳",
    "code": "KN",
    "flag": "Files/flags/kn.svg"
  },
  {
    "name": "Saint Lucia",
    "dial_code": "+1758",
    "emoji": "🇱🇨",
    "code": "LC",
    "flag": "Files/flags/lc.svg"
  },
  {
    "name": "Saint Martin",
    "dial_code": "+590",
    "emoji": "🇲🇫",
    "code": "MF",
    "flag": "Files/flags/mf.svg"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "emoji": "🇵🇲",
    "code": "PM",
    "flag": "Files/flags/pm.svg"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "emoji": "🇻🇨",
    "code": "VC",
    "flag": "Files/flags/vc.svg"
  },
  {
    "name": "Samoa",
    "dial_code": "+685",
    "emoji": "🇼🇸",
    "code": "WS",
    "flag": "Files/flags/ws.svg"
  },
  {
    "name": "San Marino",
    "dial_code": "+378",
    "emoji": "🇸🇲",
    "code": "SM",
    "flag": "Files/flags/sm.svg"
  },
  {
    "name": "Sao Tome and Principe",
    "dial_code": "+239",
    "emoji": "🇸🇹",
    "code": "ST",
    "flag": "Files/flags/st.svg"
  },
  {
    "name": "Saudi Arabia",
    "dial_code": "+966",
    "emoji": "🇸🇦",
    "code": "SA",
    "flag": "Files/flags/sa.svg"
  },
  {
    "name": "Senegal",
    "dial_code": "+221",
    "emoji": "🇸🇳",
    "code": "SN",
    "flag": "Files/flags/sn.svg"
  },
  {
    "name": "Serbia",
    "dial_code": "+381",
    "emoji": "🇷🇸",
    "code": "RS",
    "flag": "Files/flags/rs.svg"
  },
  {
    "name": "Seychelles",
    "dial_code": "+248",
    "emoji": "🇸🇨",
    "code": "SC",
    "flag": "Files/flags/sc.svg"
  },
  {
    "name": "Sierra Leone",
    "dial_code": "+232",
    "emoji": "🇸🇱",
    "code": "SL",
    "flag": "Files/flags/sl.svg"
  },
  {
    "name": "Singapore",
    "dial_code": "+65",
    "emoji": "🇸🇬",
    "code": "SG",
    "flag": "Files/flags/sg.svg"
  },
  {
    "name": "Slovakia",
    "dial_code": "+421",
    "emoji": "🇸🇰",
    "code": "SK",
    "flag": "Files/flags/sk.svg"
  },
  {
    "name": "Slovenia",
    "dial_code": "+386",
    "emoji": "🇸🇮",
    "code": "SI",
    "flag": "Files/flags/si.svg"
  },
  {
    "name": "Solomon Islands",
    "dial_code": "+677",
    "emoji": "🇸🇧",
    "code": "SB",
    "flag": "Files/flags/sb.svg"
  },
  {
    "name": "Somalia",
    "dial_code": "+252",
    "emoji": "🇸🇴",
    "code": "SO",
    "flag": "Files/flags/so.svg"
  },
  {
    "name": "South Africa",
    "dial_code": "+27",
    "emoji": "🇿🇦",
    "code": "ZA",
    "flag": "Files/flags/za.svg"
  },
  {
    "name": "South Sudan",
    "dial_code": "+211",
    "emoji": "🇸🇸",
    "code": "SS",
    "flag": "Files/flags/ss.svg"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "emoji": "🇬🇸",
    "code": "GS",
    "flag": "Files/flags/gs.svg"
  },
  {
    "name": "Spain",
    "dial_code": "+34",
    "emoji": "🇪🇸",
    "code": "ES",
    "flag": "Files/flags/es.svg"
  },
  {
    "name": "Sri Lanka",
    "dial_code": "+94",
    "emoji": "🇱🇰",
    "code": "LK",
    "flag": "Files/flags/lk.svg"
  },
  {
    "name": "Sudan",
    "dial_code": "+249",
    "emoji": "🇸🇩",
    "code": "SD",
    "flag": "Files/flags/sd.svg"
  },
  {
    "name": "Suriname",
    "dial_code": "+597",
    "emoji": "🇸🇷",
    "code": "SR",
    "flag": "Files/flags/sr.svg"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "emoji": "🇸🇯",
    "code": "SJ",
    "flag": "Files/flags/sj.svg"
  },
  {
    "name": "Swaziland",
    "dial_code": "+268",
    "emoji": "🇸🇿",
    "code": "SZ",
    "flag": "Files/flags/sz.svg"
  },
  {
    "name": "Sweden",
    "dial_code": "+46",
    "emoji": "🇸🇪",
    "code": "SE",
    "flag": "Files/flags/se.svg"
  },
  {
    "name": "Switzerland",
    "dial_code": "+41",
    "emoji": "🇨🇭",
    "code": "CH",
    "flag": "Files/flags/ch.svg"
  },
  {
    "name": "Syrian Arab Republic",
    "dial_code": "+963",
    "emoji": "🇸🇾",
    "code": "SY",
    "flag": "Files/flags/sy.svg"
  },
  {
    "name": "Taiwan",
    "dial_code": "+886",
    "emoji": "🇹🇼",
    "code": "TW",
    "flag": "Files/flags/tw.svg"
  },
  {
    "name": "Tajikistan",
    "dial_code": "+992",
    "emoji": "🇹🇯",
    "code": "TJ",
    "flag": "Files/flags/tj.svg"
  },
  {
    "name": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "emoji": "🇹🇿",
    "code": "TZ",
    "flag": "Files/flags/tz.svg"
  },
  {
    "name": "Thailand",
    "dial_code": "+66",
    "emoji": "🇹🇭",
    "code": "TH",
    "flag": "Files/flags/th.svg"
  },
  {
    "name": "Timor-Leste",
    "dial_code": "+670",
    "emoji": "🇹🇱",
    "code": "TL",
    "flag": "Files/flags/tl.svg"
  },
  {
    "name": "Togo",
    "dial_code": "+228",
    "emoji": "🇹🇬",
    "code": "TG",
    "flag": "Files/flags/tg.svg"
  },
  {
    "name": "Tokelau",
    "dial_code": "+690",
    "emoji": "🇹🇰",
    "code": "TK",
    "flag": "Files/flags/tk.svg"
  },
  {
    "name": "Tonga",
    "dial_code": "+676",
    "emoji": "🇹🇴",
    "code": "TO",
    "flag": "Files/flags/to.svg"
  },
  {
    "name": "Trinidad and Tobago",
    "dial_code": "+1868",
    "emoji": "🇹🇹",
    "code": "TT",
    "flag": "Files/flags/tt.svg"
  },
  {
    "name": "Tunisia",
    "dial_code": "+216",
    "emoji": "🇹🇳",
    "code": "TN",
    "flag": "Files/flags/tn.svg"
  },
  {
    "name": "Turkey",
    "dial_code": "+90",
    "emoji": "🇹🇷",
    "code": "TR",
    "flag": "Files/flags/tr.svg"
  },
  {
    "name": "Turkmenistan",
    "dial_code": "+993",
    "emoji": "🇹🇲",
    "code": "TM",
    "flag": "Files/flags/tm.svg"
  },
  {
    "name": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "emoji": "🇹🇨",
    "code": "TC",
    "flag": "Files/flags/tc.svg"
  },
  {
    "name": "Tuvalu",
    "dial_code": "+688",
    "emoji": "🇹🇻",
    "code": "TV",
    "flag": "Files/flags/tv.svg"
  },
  {
    "name": "Uganda",
    "dial_code": "+256",
    "emoji": "🇺🇬",
    "code": "UG",
    "flag": "Files/flags/ug.svg"
  },
  {
    "name": "Ukraine",
    "dial_code": "+380",
    "emoji": "🇺🇦",
    "code": "UA",
    "flag": "Files/flags/ua.svg"
  },
  {
    "name": "United Arab Emirates",
    "dial_code": "+971",
    "emoji": "🇦🇪",
    "code": "AE",
    "flag": "Files/flags/ae.svg"
  },
  {
    "name": "United Kingdom",
    "dial_code": "+44",
    "emoji": "🇬🇧",
    "code": "GB",
    "flag": "Files/flags/gb.svg"
  },
  {
    "name": "United States",
    "dial_code": "+1",
    "emoji": "🇺🇸",
    "code": "US",
    "flag": "Files/flags/us.svg"
  },
  {
    "name": "Uruguay",
    "dial_code": "+598",
    "emoji": "🇺🇾",
    "code": "UY",
    "flag": "Files/flags/uy.svg"
  },
  {
    "name": "Uzbekistan",
    "dial_code": "+998",
    "emoji": "🇺🇿",
    "code": "UZ",
    "flag": "Files/flags/uz.svg"
  },
  {
    "name": "Vanuatu",
    "dial_code": "+678",
    "emoji": "🇻🇺",
    "code": "VU",
    "flag": "Files/flags/vu.svg"
  },
  {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "emoji": "🇻🇪",
    "code": "VE",
    "flag": "Files/flags/ve.svg"
  },
  {
    "name": "Vietnam",
    "dial_code": "+84",
    "emoji": "🇻🇳",
    "code": "VN",
    "flag": "Files/flags/vn.svg"
  },
  {
    "name": "Virgin Islands, British",
    "dial_code": "+1284",
    "emoji": "🇻🇬",
    "code": "VG",
    "flag": "Files/flags/vg.svg"
  },
  {
    "name": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "emoji": "🇻🇮",
    "code": "VI",
    "flag": "Files/flags/vi.svg"
  },
  {
    "name": "Wallis and Futuna",
    "dial_code": "+681",
    "emoji": "🇼🇫",
    "code": "WF",
    "flag": "Files/flags/wf.svg"
  },
  {
    "name": "Yemen",
    "dial_code": "+967",
    "emoji": "🇾🇪",
    "code": "YE",
    "flag": "Files/flags/ye.svg"
  },
  {
    "name": "Zambia",
    "dial_code": "+260",
    "emoji": "🇿🇲",
    "code": "ZM",
    "flag": "Files/flags/zm.svg"
  },
  {
    "name": "Zimbabwe",
    "dial_code": "+263",
    "emoji": "🇿🇼",
    "code": "ZW",
    "flag": "Files/flags/zw.svg"
  }
]