import { SECURITY_QUATIONS, SECURITY_QUATIONS_OPTIONS } from 'src/Helpers';
import * as yup from 'yup'

export const Quations = SECURITY_QUATIONS && SECURITY_QUATIONS?.map((o: any) => ({
    value: o,
    label: o
  }));


export interface IResetPassword {
    security_question1: string | undefined;
    security_answer1: string | undefined;
    security_question2: string | undefined;
    security_answer2: string | undefined;
    security_question3: string | undefined;
    security_answer3: string | undefined;
    password: string | undefined;
    confirm_password: string | undefined;
}


export const ResetPassword_INIT = {
    security_question1: undefined,
    security_answer1: undefined,
    security_question2: undefined,
    security_answer2: undefined,
    security_question3: undefined,
    security_answer3: undefined,
    password: undefined,
    confirm_password: undefined,
}


export const ResetPasswordSchema = yup.object().shape({
    security_question1: yup.string().required(),
    security_answer1: yup.string().required(),
    security_question2: yup.string().required(),
    security_answer2: yup.string().required(),
    security_question3: yup.string().required(),
    security_answer3: yup.string().required(),
    password: yup.string().matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/, "Password length should be between 8-18 characters, including capital letters, numbers, and special characters.").required(),
    confirm_password: yup.string().required().test("confirm_password", (value, validationcontext) => {
        const { createError, parent: { password } } = validationcontext;

        if (!value && password) {
            return createError({ message: "Please enter confirm password" });
        }

        if (value != password) {
            return createError({ message: "Password not match" });
        }

        return true;
    }),
})


export const ResetPassword_Fileds = {
    security_question1: {
        key: "security_question1",
        label: "Security Question 1",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 1",
    },
    security_answer1: {
        key: "security_answer1",
        label: "Security Answer 1",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 1",
    },
    security_question2: {
        key: "security_question2",
        label: "Security Question 2",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 2",
    },
    security_answer2: {
        key: "security_answer2",
        label: "Security Answer 2",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 2",
    },
    security_question3: {
        key: "security_question3",
        label: "Security Question 3",
        field_type: "options_with_search",
        options: Quations,
        placeholder: "Security Question 3",
    },
    security_answer3: {
        key: "security_answer3",
        label: "Security Answer 3",
        field_type: "text",
        field_mode: "text",
        placeholder: "Security Answer 3",
    },
    password: {
        key: "password",
        label: "Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Enter Password",
    },
    confirm_password: {
        key: "confirm_password",
        label: "Confirm Password",
        field_type: "password",
        field_mode: "text",
        placeholder: "Enter Password",
    },
};

export interface IUplodDocument {
    cac: undefined,
    tcc: File | string | undefined;
    expatriate_quota_position: File | string | undefined;
    additional_doc_name?: string | undefined;
    additional_doc?: File | string | undefined;
}

