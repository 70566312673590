import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { useMainContext } from "../MainContext";
import LightBtn from "src/components/Common/LightBtn";
import { ICompanyProfile } from "../modules";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { uploadfile } from "src/services/services";
import { useDispatch } from "react-redux";
import {
  createCompanyProfileAsync,
  reSetError,
  resetSave,
  setError,
} from "src/Silcers/companySlicer";
import { Loader2, ShieldClose } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Label } from "src/components/ui/Label";
import { getLoginReducerState, getUserByTokenAsync } from "src/Silcers/loginSlicer";
import { format } from "date-fns";
import { checkIfAnyFilesToBeUpload } from "src/Helpers";
import axios, { AxiosError } from "axios";
import LoadingDialog from "src/components/LoadingDialog";
import ErrorDialog from "src/components/ErrorDialog";
import { cn } from "src/lib/utils";

type Props = {};

function StepTwoReview({ }: Props) {
  const {
    companyProfileForm: formData,
    isLoading,
    error,
    message,
    saved,
  } = useSelector((state: RootState) => state.compayProfile);
  const { user } = useSelector((state: RootState) => getLoginReducerState(state));
  const navigation = useNavigate();
  const { setActiveTab } = useMainContext();
  const dispatch = useDispatch();

  const [uploadingFileStatus, setuploadingFileStatus] = useState<{
    isLoading: boolean,
    isError: boolean,
    message: string | undefined
  }>({
    isLoading: false,
    isError: false,
    message: undefined
  });
  const [uploadedFileStatus, setuploadedFileStatus] = useState<{
    totalFiles: number,
    uploadedFiles: number,
  }>({
    totalFiles: 0,
    uploadedFiles: 0,
  });

  const PreviousFromData:
    | Partial<
      ICompanyProfile & {
        cacfiles: File | undefined;
        tccfiles: File | undefined;
        expatriate_quota_positionfiles: File | undefined;
        additional_documentilesfiles: File | undefined;
      }
    >
    | undefined = useMemo(() => formData, [formData]);


  useEffect(() => {
    if (saved) {
      dispatch(resetSave());
      dispatch(getUserByTokenAsync());
      // console.log({ formData })
      if (user?.is_moi_verified) {
        navigation({ pathname: "/employee" });
      } else {
        navigation({ pathname: "/quota/create" });
      }
    }
    return () => { };
  }, [saved]);

  const [isSubmiting, setisSubmiting] = useState(false);
  // const [isUploading, setisUploading] = useState(false)

  const uploadProgress = (total: number, uploaded: number, isError: boolean, message: string) => {
    setuploadedFileStatus({ totalFiles: total, uploadedFiles: uploaded })
    if (isError) {
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false, isError: true, message: message })
      dispatch(reSetError())
      // setrequestState({ ...requestState, isLoading: false, isError: false, message: "", status: undefined })
      return
    }
  };


  const SubmitForm = async () => {
    try {
      const data: any = formData;
      let newData: any = {
        ...data,
      };
      setuploadingFileStatus({ ...uploadingFileStatus, isLoading: true })
      newData = await checkIfAnyFilesToBeUpload(newData, uploadProgress);
      if (newData) {
        setTimeout(() => {
          setuploadingFileStatus({ ...uploadingFileStatus, isLoading: false })
          dispatch(createCompanyProfileAsync(newData));
        }, 500);
      }

    } catch (err) {
      let error = err as Error | AxiosError
      if (axios.isAxiosError(error)) {
        dispatch(setError(error.response?.data?.message))
      }
      else {
        dispatch(setError(error?.message))
      }
    }

  };


  // console.log(formData, "sad");
  return (
    <div className="p-7">
      <div className="p-7 grid grid-cols-1 gap-3">
        {/* type of company and rc number */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">Rc Number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.rc_number}{" "}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">State of Company</h4> */}
            {/* <Label className="uppercase">State of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.state_of_company}{" "}
            </p> */}
          </div>
        </div>
        {/* Type of Company and Name of Company  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Type Of Company</h4> */}
            <Label className="uppercase">Type Of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.type_of_company}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Name of Company</h4> */}
            <Label className="uppercase">Name of Company</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.company_name}
            </p>
          </div>
        </div>
        {/* SECtor and Nature of Business  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Sector</h4> */}
            <Label className="uppercase">Sector</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.sector}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Nature of Business</h4> */}
            <Label className="uppercase">Nature of Business</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.nature_of_business}
            </p>
          </div>
        </div>
        {/* Name of company And TIN NUMBER */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Tin number</h4> */}
            <Label className="uppercase">Tin number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.tin_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Tin number</h4> */}
            <Label className="uppercase">TCC number</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.tcc_number}
            </p>
          </div>

        </div>

        {/* bussiness email and phone  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Business email</h4> */}
            <Label className="uppercase">Business email</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.business_email}{" "}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Business phone number</h4> */}
            <Label className="uppercase">Business phone number</Label>
            <p className=" sub-style text-sm mt-1">
              +{formData && formData.business_phone_country_code}
              {"-"}
              {formData && formData.business_phone_number}
            </p>
          </div>
        </div>
        {/* Country and state  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Country</h4> */}
            <Label className="uppercase">Country</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.country}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">State</h4> */}
            <Label className="uppercase">State</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.state}{" "}
            </p>
          </div>
        </div>
        {/* City and Zip code  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">City</h4> */}
            <Label className="uppercase">City</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.city}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Zip Code</h4> */}
            <Label className="uppercase">Zip Code</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.zipcode}
            </p>
          </div>
        </div>
        {/* Company Address 1 / 2 */}
        <div>
          {/* <h4 className=" uppercase">Company Address 1</h4> */}
          <Label className="uppercase">Address Line 1</Label>
          <p className=" sub-style text-sm mt-1">
            {formData && formData.company_address1}
          </p>
        </div>
        <div>
          {/* <h4 className=" uppercase">Company Address 2</h4> */}
          <Label className="uppercase">Address Line 2</Label>
          <p className=" sub-style text-sm mt-1">
            {formData && formData.company_address2}{" "}
          </p>
        </div>
        {/* Type of representative and designation  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Type Of Representative</h4> */}
            <Label className="uppercase">Type Of Representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.type_of_representative}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Designation Of Representative</h4> */}
            <Label className="uppercase">Designation Of Representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.designation_of_representative}
            </p>
          </div>
        </div>
        {/* Name of representative and national identification no */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Name of representative</h4> */}
            <Label className="uppercase">Name of representative</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.name_of_representative}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">
              National Identification Number of Representative (NIN)
            </h4> */}
            <Label className="uppercase">
              National Identification Number of Representative (NIN)
            </Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.national_identification_number}
            </p>
          </div>
        </div>
        {/* Bank verification no  and Passport NO of Represntative*/}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Bank Verification Number (BVN) </h4> */}
            <Label className="uppercase">Bank Verification Number (BVN)</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.bank_verification_number}
            </p>
          </div>
          <div>

          </div>
        </div>
        {/* crepc NO and PAsport expiry */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">
              Passport number of representative{" "}
            </Label>
            {/* <h4 className=" uppercase">Passport number of representative </h4> */}
            <p className=" sub-style text-sm mt-1">
              {formData && formData.passport_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Passport Expiry</h4> */}
            <Label className="uppercase">Passport Expiry OF REPRESENTATIVE</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.passport_expiry && format(new Date(formData.passport_expiry), "dd/MM/yyyy")}
            </p>
          </div>
        </div>
        {/* Quota position utilzed quota  */}
        <div className="grid grid-cols-2 gap-x-2">
          <div>
            {/* <h4 className=" uppercase">Cerpac no.</h4> */}
            <Label className="uppercase">CERPAC NO. OF REPRESENTATIVE</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.cerpac_number}
            </p>
          </div>
          <div>
            {/* <h4 className=" uppercase">Quota positions</h4> */}
            <Label className="uppercase">CERPAC EXPIRY OF REPRESENTATIVE</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.cerpac_expiry && format(new Date(formData.cerpac_expiry), "dd/MM/yyyy")}
            </p>
          </div>
          {/* <div>
            <Label className="uppercase">Quota positions</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_position}
            </p>
          </div> */}
        </div>
        {/* UN-Utilized qutoa Upload CAC TCC ,reveewal Additional Document*/}
        {/* <div className="grid grid-cols-2 gap-x-2">
          <div>
            <Label className="uppercase">Utilized quota</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_utilized}
            </p>
          </div>
          <div>
            <Label className="uppercase">UN-Utilized quota</Label>
            <p className=" sub-style text-sm mt-1">
              {formData && formData.quota_remaining}
            </p>
          </div>
          <div></div>
        </div> */}
        <div>
          {/* <h4 className=" uppercase">
            upload cac : corporate Affairs commission
          </h4> */}
          <Label className="uppercase">
            upload c.a.c : corporate Affairs commission
          </Label>

          <p className=" sub-style text-sm mt-1">
            {formData && formData?.cac && formData?.cac instanceof File
              ? formData?.cac?.name
              : null}
            {/* {JSON.stringify(formData && formData.cac) as string} */}
          </p>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload T.c.c : Tax clearance certificate
          </Label>

          <p className=" sub-style text-sm mt-1">
            {formData && formData?.tcc && formData?.tcc instanceof File
              ? formData?.tcc?.name
              : null}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
          </p>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            upload Renewal of expatritate quota positions{" "}
          </h4> */}
          {/* <Label className="uppercase">
            upload Renewal of expatritate quota positions
          </Label>
          <Link className=" text-primary font-medium uppercase" to="*">
            <p className=" sub-style text-sm mt-1">
              {formData &&
                formData?.expatriate_quota_position &&
                formData?.expatriate_quota_position instanceof File
                ? formData?.expatriate_quota_position?.name
                : null}
            </p>
          </Link> */}
        </div>

        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload I.T.F: Industrial Training Fund
          </Label>

          <p className=" sub-style text-sm mt-1">
            {formData && formData?.itf && formData?.itf instanceof File
              ? formData?.itf?.name
              : null}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
          </p>

        </div>
        <div>
          {/* <h4 className=" uppercase">
            Upload T.c.c : Tax clearance certificate{" "}
          </h4> */}
          <Label className="uppercase">
            Upload Pencom
          </Label>

          <p className=" sub-style text-sm mt-1">
            {formData && formData?.pancom && formData?.pancom instanceof File
              ? formData?.pancom?.name
              : null}
            {/* {JSON.stringify(formData && formData.tcc) as string}{" "} */}
          </p>

        </div>
        <div>
          <div className="flex flex-col space-y-1">
            <Label className="uppercase">
            UPLOAD C.A.C : CERTIFICATE OF INCORPORATION STATUS
            </Label>
            {/* <span className="text-xs">Other additional document related to Quota approval or Quota return certificate.</span> */}
          </div>

          <p className=" sub-style text-sm mt-1">
            {formData &&
              formData?.additional_document &&
              formData?.additional_document instanceof File
              ? formData?.additional_document?.name
              : null}
          </p>

        </div>
        {/* <div className="grid grid-cols-2 gap-x-2">
          <div>
            <h4 className=" uppercase">Upload addiotional document</h4>
            <Link className=" text-primary font-medium uppercase" to="*">
              <p className=" sub-style text-sm">{formData.rc_number}</p>
            </Link>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center w-full">
        {/* <Button
          className="mb-2 px-10 w-44 mx-5 bg-secondary-foreground"
          onClick={() => setActiveTab(1)}
        >
          Edit
        </Button> */}
        <LightBtn onclick={() => setActiveTab(1)} title="Edit" />
        <Button
          disabled={isSubmiting || isLoading}
          variant={error ? "secondary" : "default"}
          className="mb-2 px-10 w-44 mx-5"
          onClick={SubmitForm}
        >
          {(isSubmiting || isLoading) && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Continue
        </Button>
      </div>


      <LoadingDialog isOpen={isLoading || uploadingFileStatus.isLoading} message='' >
        <div className="flex flex-col w-full space-y-2">
          <div className="text-center">
            {isLoading ? "Sending Requests" : null}
            {uploadingFileStatus.isLoading ? "Uploading Files" : null}
          </div>
          {uploadingFileStatus.isLoading && <div className="flex space-x-1">
            {
              uploadedFileStatus.totalFiles && [...new Array(uploadedFileStatus.totalFiles)].map((_, indx) => {
                return <div className={cn("h-1.5 w-100 rounded-full flex-1 bg-gray-300", { 'bg-primary': ((indx + 1) <= uploadedFileStatus.uploadedFiles) })}></div>
              })
            }
          </div>}
        </div>
      </LoadingDialog>
      <ErrorDialog isOpen={error} message={message} onCancelClick={() => dispatch(reSetError())} />
      <ErrorDialog title='Cancle' extraBtn extraBtnTitle='Retry' extraBtnClick={() => SubmitForm()} extraBtnVarient={'default'} isOpen={uploadingFileStatus.isError} message={uploadingFileStatus.message} onCancelClick={() => {
        setuploadingFileStatus({ ...uploadingFileStatus, isError: false })
      }} />
    </div>
  );
}

export default StepTwoReview;
