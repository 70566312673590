import React from 'react';
import { Dialog, DialogContent, DialogHeader } from "src/components/ui/dialog";
import { Button } from '../ui/button';
import StepCircle from 'src/Modules/Auth/Regsitration/Components/StepCircle';


type Props = {
    isOpen: boolean,
    onChangeOpenState?: (e: boolean) => void,
    onClickOkBtn: () => void,
    title: string,
    children: React.ReactChild
}

const SuccessDialog = ({ isOpen, onChangeOpenState, onClickOkBtn, children, title }: Props) => {
    return (
        <Dialog
            open={isOpen}
            onOpenChange={(e: any) => {
                onChangeOpenState && onChangeOpenState(e);
            }}
        >
            <DialogContent className="min-w-[40%] overflow-auto">
                <DialogHeader className="ml-4 ">
                    <div className='flex flex-col justify-center items-center space-y-2 '>
                        <div className=''>
                            <StepCircle previous={true} />
                        </div>
                        <div className='text-primary uppercase text-lg '>{title}</div>
                    </div>
                </DialogHeader>
                {children && children && <div className="flex flex-col justify-center items-center">
                    {children}
                </div>}
                <div className=" flex justify-center">
                    <Button
                        className=" px-24"
                        onClick={onClickOkBtn}
                    >
                        Done
                    </Button>
                </div>
            </DialogContent>
        </Dialog>

    )
}

export default SuccessDialog