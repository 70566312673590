import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { useRegistrationMainContext } from "../RegistrationMainContext";
import { registration_headers, registration_subHeaders } from "src/Helpers";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

type Props = {};

const RegistrationHeader = (props: Props) => {
  // const {
  //   state: { activeTab },
  // } = ();

  const { activeTab } = useSelector(
    (state: RootState) => state.company_registration
  );

  return (
    <div>
      <PageHeadingComponent
        key={activeTab}
        mainContent={registration_headers[activeTab - 1]}
        subContent={
          registration_subHeaders[
            activeTab >= 1 && activeTab <= 4 ? 1 : activeTab - 4
          ]
        }
      />
    </div>
  );
};

export default RegistrationHeader;
