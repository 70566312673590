import {
  ColumnDef,
  RowSelectionRow,
  RowSelectionState,
  RowSelectionTableState,
  Updater,
} from "@tanstack/react-table";

import { Checkbox } from "src/components/ui/checkbox";

import { Task } from "../data/schema";

import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Badge } from "src/components/ui/badge";
import { cn } from "src/lib/utils";
import Batch from "./Batch";
import { PersonIcon } from "@radix-ui/react-icons";
import { ENUM_KYC_STATUS, toUrl } from "src/Helpers";
import { format } from "date-fns";
import { ShieldCheck, ShieldOff } from "lucide-react";
import UpdateQuota from "./UpdateQuota/UpdateQuota";


const checkQuotaExpired = (quota_expiry: string) => {
  if (quota_expiry && quota_expiry.includes('/')) {
    let splitQuotaExpiry = quota_expiry.split("/");
    let [dd, mm, yyyy] = splitQuotaExpiry;
    let newDateOfQuotaExpiry = new Date(splitQuotaExpiry.reverse().join("-"))
    //console.log({ quota_expiry, splitQuotaExpiry, newDateOfQuotaExpiry, isQuotaExpired: newDateOfQuotaExpiry < new Date() });
    return newDateOfQuotaExpiry < new Date()
  }
  return false;
}

const kycStatusIcon = (status: string | undefined) => {
  if (status == ENUM_KYC_STATUS.APPROVED) {
    return <div className="flex space-x-1 items-center"><Badge><ShieldOff size={14} className="text-white me-1" /> KYC Verified</Badge> </div>
  }
  else if (status == ENUM_KYC_STATUS.REJECTED) {
    return <div className="flex space-x-1 items-center"><Badge className="text-xs" variant={"destructive"} > <ShieldOff size={14} className="text-gray-500 me-1" /> KYC Reject</Badge> </div>
  }
  else if (status == ENUM_KYC_STATUS.PENDING)  {
    return <Badge className="text-xs"  variant={"secondary"}>Pending</Badge>
  }
}

export const columns: ColumnDef<Task>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => {
          table.toggleAllPageRowsSelected(!!value);
        }}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) =>
      // 
      row.original.paymentstatus != "complete" && row.original?.emp_insertstatus == "completed" && !checkQuotaExpired(row.original.quota_expiry) && (
        <Checkbox
          disabled={row.original.paymentstatus == "complete"}
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className={cn("translate-y-[2px]", {
            "border-1 bg-slate-100": row.original.paymentstatus == "complete",
          })}
        />
      ),
    enableSorting: false,
    enableHiding: false,
  },
  // {
  //   accessorKey: "id",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Task" />
  //   ),
  //   cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "user_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }: any) => {
      // console.log({ row: row.original });
      return (
        <div className="flex space-x-2">
          <div>
            <PersonIcon className=" h-6 w-6 mt-2" />
          </div>
          <div className="flex flex-col">
            <div>
              {row.original.surname} {row.original.givenname}
            </div>
            <div className="flex space-x-1 items-center">
              <div>{row.original.sex}</div><div>
                {kycStatusIcon(row.original.kyc_status) }</div>
            </div>
          </div>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "position",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Position" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          <span>{row.original.designation}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "nationality",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Nationality" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex w-auto items-center">
          <span>{row.original.nationality}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "passport_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Passport Details" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col w-auto items-start">
          <div>{row.original.passportnumber}</div>
          <div className=" flex space-x-1">
            <div>Expiry On :</div>
            <div> {row.original?.passportexpiry && !row.original?.passportexpiry.includes("/") && Date.parse(row.original?.passportexpiry) && format(new Date(row.original?.passportexpiry), "dd/MM/yyyy")}
              {row.original?.passportexpiry && row.original?.passportexpiry.includes("/") && row.original?.passportexpiry}</div>
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "cerpac_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Cerpac Details" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col w-auto items-start">
          <div>{row.original.cerpac_number}</div>
          <div className=" flex space-x-1">
            <div>Expiry On :</div>
            <div>{row.original?.cerpac_expiry && !row.original?.cerpac_expiry.includes("/") && Date.parse(row.original?.cerpac_expiry) && format(new Date(row.original?.cerpac_expiry), "dd/MM/yyyy")}
              {row.original?.cerpac_expiry && row.original?.cerpac_expiry.includes("/") && row.original?.cerpac_expiry}</div>
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "paymentstatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payment Status" />
    ),
    cell: ({ row, table }) => {
      //!checkQuotaExpired(row.original.quota_expiry) ? <Batch row={row} /> : <Badge variant={"destructive"}>Quota Expired</Badge> 
      return row.original?.empstatus && row.original?.empstatus.find((x :any) => x.toLowerCase() == "active") ? row.original?.emp_insertstatus == "completed" && row.original.quota_expiry ? <Batch row={row} /> : <Badge variant={"secondary"}>Quota Not Assigned</Badge> : <></>;
    },
  },

  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
  },
];
