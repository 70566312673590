import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./Modules/Auth/Core";
import AppRoutes from "./Navigations/AppRoutes";
import AuthRoutes from "./Navigations/AuthRoutes";
import { Provider } from "react-redux";
import axios from "axios";
import { store } from "./store";
import { axiosWithToken, axiosWithoutToken } from "./services/axiosSetup";
import { setError, resetError } from "./Silcers/errorSlicer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const unauthorized = 401;
const { dispatch } = store; // direct access to redux store.
// axiosWithoutToken.interceptors.response.use(
//   response => response,
//   error => {
//     const { status } = error.response;
//     if (status !== 200) {
//       dispatch(setError(error.response));
//       setTimeout(() => {
//         dispatch(resetError());
//       }, 4000);
//     }
//     return Promise.reject(error);
//   }
// );

axiosWithToken.interceptors.response.use(
  (response: any) => {
    // Do something with the response data
    return response;
  },
  (error: any) => {
    // Handle response error
    const { status } = error.response;
    if (status !== 200) {
      dispatch(setError(error.response));
      setTimeout(() => {
        dispatch(resetError());
      }, 4000);
    }
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <AuthRoutes />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
