import { PersonIcon } from "@radix-ui/react-icons";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SVGC } from "src/Helpers/SVGC";
import { UserModel } from "src/Modules/Auth/Core";
import PasswordReset from "src/Modules/PasswordRest/PasswordReset";
import { cn } from "src/lib/utils";
import { toAbsoluteUrl } from "../../Helpers";
import { removeAuth } from "../../Modules/Auth/Core/AuthHelpers";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { Button } from "../ui/button";
import { ScrollArea } from "@radix-ui/react-scroll-area";

const removeNavRoutes = ["/faq", "/privacy-statement"];
interface NavHead {
  title: string;
  link: string | React.ReactNode;
  subNav?: NavHead[],
  icon?: string | React.ReactNode;
  shouldOpenInNewTab?: boolean;
  // Assuming the icon is a React element
}

const navHeads: NavHead[] = [
  {
    title: "Dashboard",
    link: "/dashboard",
    // subNav: [
    //   {
    //     title: "Dashboard",
    //     link: '/dashboard'
    //   },
    // ],
    icon: toAbsoluteUrl("/media/svg/nav_home.svg"),
  },
  // {
  //   title: "Profile",
  //   link: "/dashboard",
  //   subNav: [
  //     {
  //       title: "View Profile",
  //       link: '/company-profile-view'
  //     },
  //     {
  //       title: "Password Reset",
  //       link: ''
  //     },
  //   ],
  //   icon: toAbsoluteUrl("/media/svg/nav_home.svg"),
  // },
  {
    title: "Company",
    link: "/company-profile",
    subNav: [
      {
        title: "Company Profile",
        link: '/company-profile'
      },
      {
        title: "Quota Setup",
        link: '/quota/create'
      },
      {
        title: "Quota List",
        link: '/quota '
      },
    ],
    icon: toAbsoluteUrl("/media/svg/nav_company.svg"),
  },
  {
    title: "Employee",
    link: "/employee",
    subNav: [
      // {
      //   title: "Add Employee",
      //   link: '/employee-add'
      // },
      {
        title: "Employee List",
        link: '/employee'
      },
    ],
    icon: toAbsoluteUrl("/media/svg/nav_employee.svg"),
  },
  {
    title: "Reports",
    link: "/reports",
    icon: toAbsoluteUrl("/media/svg/nav_reports.svg"),
  },
  {
    title: "Cards",
    link: "/cards",
    icon: toAbsoluteUrl("/media/svg/nav_reports.svg"),
  },
  {
    title: "Profile",
    link: "/company-profile-view",
    icon: <PersonIcon className="w-[20px] h-[20px] mt-[3px] mr-2 rounded-full cursor-pointer " />,
    subNav: [
      {
        title: "Profile",
        link: '/company-profile-view'
      },
      {
        title: "Reset Pasword",
        link: <PasswordReset />

      },
    ],
  },
  {
    title: "Contact US",
    link: "/help",
    icon: toAbsoluteUrl("/media/svg/nav_contactus.svg"),
  },
  {
    title: 'Help',
    link: '/cards',
    subNav: [
      {
        title: "Handbook",
        link: process.env.REACT_APP_HANDBOOK_URL,
        shouldOpenInNewTab: true,
      },
      {
        title: "User Manual",
        link: process.env.REACT_APP_USER_MANUAL,
        shouldOpenInNewTab: true,
      },
      {
        title: "FAQ",
        link: '/frequentry-ask',
      },
    ],
    icon: toAbsoluteUrl('/media/svg/nav_help.svg'),
  },
];

// Home ->dashboard,
// company -> drpdwn ->  (company setup, quota setup, quota list ),
// employee -> (all employee, deleted employee, add employee, ),
// report ,
// contact us,
// Help -> drpdwn ( handbook, etc)
// FAQ and Logout -> bottom of the sidebar

type Props = {
  currentUser: UserModel | undefined;
};

function NavBarVertical({ currentUser }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const logedIn = currentUser ? true : false;

  return (
    <>
      {logedIn && <>

        <Accordion type="single" collapsible className="w-full h-[70vh] overflow-y-auto " >
          <nav className="flex flex-col ">
            <ul className="flex flex-col ml-4 mt-5">
              {navHeads.map((nav, index) => {
                let isCurrentPath = typeof nav.link == "string" ? location.pathname.includes(nav.link) : null;
                if (nav.subNav && nav.subNav.length > 0) {
                  return (
                    <li key={index} className=" text-sm font-medium">
                      <AccordionItem value={`item-${index}`} className={"border-b-0"} >
                        <AccordionTrigger className="py-2">
                          <div className="flex items-center">
                            {
                              typeof nav.icon == "string" && <SVGC
                                path={nav.icon}
                                className={cn("w-[20px] h-[20px] mt-[3px] mr-2", {
                                  "border-primary": isCurrentPath,
                                })}
                              />
                            }
                            {
                              React.isValidElement(nav.icon) && nav.icon
                            }
                            <p
                              className={`uppercase text-sm ${isCurrentPath ? "text-primary" : "hover:underline"
                                }`}
                            >
                              {nav.title}
                            </p>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {nav.subNav && nav.subNav.length > 0 && nav.subNav.map((subnav, subindex) =>
                            <div key={subindex} className=" ml-5 space-y-1">
                              {
                                typeof subnav.link == "string" && <Link to={subnav.link} target={subnav.shouldOpenInNewTab ? "_blank" : "_self"}>
                                  <Button
                                    variant={"ghost"}
                                    className={cn("w-full justify-start  text-gray-500 active:text-white ", {})}>
                                    {subnav.title}
                                  </Button>
                                </Link>
                              }
                              {
                                React.isValidElement(subnav.link) && subnav.link
                              }
                            </div>
                          )}
                        </AccordionContent>
                      </AccordionItem>
                      {/* <Link to={nav.link}>
        </Link> */}
                    </li>
                  );
                } else {
                  return <li key={index} className=" text-sm font-medium hover:cursor-pointer">
                    <div
                      onClick={() => typeof nav.link == "string" && navigate(nav.link)}
                      className="flex items-center mt-3 mb-1">
                      {nav.icon && typeof nav.icon == "string" && <SVGC
                        path={nav.icon}
                        className={cn("w-[20px] h-[20px] mt-[3px] mr-2", {
                          "border-primary": isCurrentPath,
                        })}

                      />}
                      <p
                        className={`uppercase text-sm ${isCurrentPath ? "text-primary" : "hover:underline"}`}
                      >
                        {nav.title}
                      </p>
                    </div>
                    {/* <hr className="mt-2" /> */}
                  </li>
                }
              })}

            </ul>
          </nav>
        </Accordion>

        <div className="h-[20vh]">
          <Button
            variant={"destructive"}
            onClick={() => {
              removeAuth();
              navigate("/");
              window.location.reload();
            }}
            className=" mx-4 w-20 text-white"
          >
            Logout
          </Button>
        </div>
      </>}
    </>
  );
}

export default NavBarVertical;

