import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../Helpers";
import { useAuth } from "../../Modules/Auth/Core";
import React, { useEffect, useState } from 'react'
import { cn } from "../../lib/utils"
import { Button } from '../../components/ui/button'
import { SVGC } from 'src/Helpers/SVGC';
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useFormik } from "formik";
import * as yup from 'yup'
import RenderFormikFiled from "src/components/Common/RenderFormikFiled";
import { contactUsDetails } from "src/services/services";
import SuccessDialog from "src/components/SuccessDialog";
import { TimerIcon } from "@radix-ui/react-icons";
import _ from 'lodash'

export const QUERY_OPTIONS = _.sortBy([
    "Account Issue",
    "Billing And Payment",
    "Technical Problem",
    "Product Information",
    "Feedback And Suggestions",
]).reduce((a, v) => ({ ...a, [v]: v }), {});

export const QUERY_OPTIONS_2 = _.sortBy([
    "Account Issue",
    "Billing And Payment",
    "Technical Problem",
    "Product Information",
    "Feedback And Suggestions",
]).map((o: any) => ({
    label: o,
    value: o
}));

type Props = {};

type ContactusType = {
    email: string,
    full_name: string,
    query: string,
    query_type: string,
}

const errorMessages = {
    email: {
        required: "please enter email",
        maxLength: "Maximum email length is 250 characteres only",
        invalid: "Please enter valid e-mail"
    },
    full_name: {
        required: "Please enter Full name",
        minMaxlength: "Minimum 3 and maximum 100 characters only",
    },
    query_type: {
        required: "Please Select Query Type",
        // minMaxlength: "Minimum 3 and maximum 100 characters only",
    },
    query: {
        required: "Please enter your Query",
        minMaxlength: "Minimum 5 and maximum 500 characters only",
        invalid: 'Password must be at least 8 characters, contain at least one digit, one lowercase letter, one uppercase letter, one special character.'
    }
}

export const ContactUsFormfield = {
    email: {
        key: "email",
        label: "Email address",
        field_type: "email",
        field_mode: "email",
        placeholder: "Email",
    },
    full_name: {
        key: "full_name",
        label: "Company Name",
        field_type: "text",
        field_mode: "text",
        placeholder: "Full Name",
    },
    query_type: {
        key: "query_type",
        label: "Query Type",
        field_type: "options",
        options: QUERY_OPTIONS_2,
        placeholder: "Query Type",
    },
    query: {
        key: "query",
        label: "Description",
        field_type: "textarea",
        field_mode: "text",
        placeholder: "Enter your Description",
    },
}

const ContactUsSchema = yup.object().shape({
    email: yup.string().email(errorMessages.email.invalid).max(250, errorMessages.email.maxLength).required(errorMessages.email.required),
    full_name: yup.string().min(3, errorMessages.full_name.minMaxlength).max(100, errorMessages.full_name.minMaxlength).required(errorMessages.full_name.required),
    query_type: yup.string().required("Select Query Type"),
    query: yup.string().min(5, errorMessages.query.minMaxlength).max(500, errorMessages.query.minMaxlength).required(errorMessages.query.required),
})




const Contactus = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate();
    const [status, setStatus] = useState(0);
    const { user: currentUser } = useSelector((state: RootState) => state.login)

    // useEffect(() => {
    //     if (status === 200) {
    //         navigate('/dashboard')
    //     }
    //     return () => {
    //         setStatus(0);
    //     }
    // }, [status]);

    const formik = useFormik({
        initialValues: {
            email: currentUser?.businessEmail || "",
            full_name: currentUser?.comapnyName || "",
            query: "",
            query_type: '',
        },
        validationSchema: ContactUsSchema,
        onSubmit: async (values: ContactusType) => {
            try {
                let data = {
                    "fullname": values.full_name,
                    "user_email": values.email,
                    "userid": currentUser._id,
                    "message": values.query,
                    "query_type": values.query_type,
                }
                const response = await contactUsDetails(data);
                setStatus(response.status);
            } catch (err) {
                console.error("An error occurred:", err);
            }
        },
    })

    return (
        <>
            <div className="flex justify-center items-center h-[70vh] px-2">
                <div className="w-2/2 ">
                    <div className="flex justify-center items-center">
                        <div
                            className="flex flex-col w-3/4 h-3/4 justify-center bg-opacity-10 "
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/svg/eel_watermark_grey_10.svg"
                                )})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                height: "50vh",
                            }}
                        >
                            <div className="flex flex-col">
                                <h1 className="scroll-m-20 font-normal tracking-tight lg:text-3xl mb-2">

                                    <span className="font-normal">
                                        Contact
                                    </span>{" "}
                                    <span className="font-medium text-primary">US</span>
                                </h1>
                                {/* <div>Employment Levies</div>
                            <div>With <span className="font-medium">EEL</span></div> */}
                            </div>
                            <div className="text-wrap text-md mb-2 ">
                                Need to get in touch with us? Either fill out the form with your
                                inquiry or find the <span className=" text-primary font-medium">
                                    <Link target="_blank" to={`mailto:${process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}`}>
                                        {process.env.REACT_APP_ENQUIRY_PHONE_EMAIL}
                                    </Link>
                                </span> email you'd like
                                to contact below.
                            </div>
                            <div className="flex lg:flex-row sm:flex-col rounded-lg text-white py-4 bg-[#10A251] ">
                                <div className=" w-1/2  flex flex-col justify-center align-middle p-1">
                                    <div className="text-end text-sm flex justify-center">
                                        <SVGC path={toAbsoluteUrl('/media/svg/eel_location.svg')} className=' w-[16px] h-[16px] mt-5 ml-3' ></SVGC>
                                        <p>
                                            NIS, Umar Musa Yar Adu'a Express Way, Airport Road, Sauka, Abuja, FCT Nigeria
                                        </p>
                                    </div>
                                </div>
                                <div className=" w-[2px] bg-slate-100 mx-6">
                                </div>
                                <div className=" w-1/2  flex flex-col justify-center align-middle p-1">

                                    <div className="flex justify-start">
                                        <SVGC path={toAbsoluteUrl('/media/svg/eel_call.svg')} className=' w-[16px] h-[16px] mt-1 mr-1 ' ></SVGC>
                                        <p>+234-7080647200</p>
                                    </div>

                                </div>
                            </div>
                            <div className="flex lg:flex-row sm:flex-col rounded-lg text-white py-4 bg-[#10A251] mt-2 ">
                                <TimerIcon className="w-[20px] h-[20px] mt-3 ml-4 border-primary" />
                                <p className="ml-[20px]">Weekdays: Mondays - Fridays: 9:00 AM - 5:00 PM
                                    <br />
                                    Weekends and National Holidays : we are available for <br />chat support only.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className=" w-48 flex justify-center">
                    <SVGC path={toAbsoluteUrl('/media/svg/right_arrow.svg')} className=' w-[50px] h-[50px] mt-1 mr-1 ' ></SVGC>
                    {/* Right pointing triangle */}
                </div>
                <div className="w-1/2 px-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid gap-3">
                            <RenderFormikFiled
                                formik={formik}
                                formObject={ContactUsFormfield.email}
                                required
                                onchange={(e: any) => formik.setFieldValue("email", e)}
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={ContactUsFormfield.full_name}
                                required
                                onchange={(e: any) => formik.setFieldValue("full_name", e)}
                            />

                            <RenderFormikFiled
                                formik={formik}
                                formObject={ContactUsFormfield.query_type}
                                required
                                onchange={(e: any) =>
                                    formik.setFieldValue("query_type", e)
                                }
                            />
                            <RenderFormikFiled
                                formik={formik}
                                formObject={ContactUsFormfield.query}
                                required
                                onchange={(e: any) => formik.setFieldValue("query", e)}
                            />
                        </div>
                        <div className='flex justify-center w-full my-4'>
                            {/* <Button variant={'outline'} className="border border-primary inline-block mb-2 mx-2 px-20">Cancel</Button> */}
                            <Button type='submit' className="inline-block mb-2 mx-2 px-20">Submit</Button>
                        </div>
                    </form>
                </div>
                <div>
                </div>
            </div>
            <SuccessDialog children={<></>} isOpen={status === 200} title="Query Submitted Successfully" onClickOkBtn={() => {
                navigate('/dashboard')
                setStatus(0);
            }} />
        </>
    );
};

export default Contactus;
