import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { ICompanyProfile, INIT_COMPANY_PROFILE_FORM, INIT_DATA } from 'src/Modules/CompanyProfile/modules'
import { getCompanyDetails, submitCompanyProfile, updateCompnayProfile } from 'src/services/services'
import axios, { AxiosError } from 'axios'
import { getAuth } from 'src/Modules/Auth/Core'
import { ExpatriateQuotaDetail, ExpatriateQuotaPosition } from 'src/types'
import { format_Date_To_DDMMYYYY } from '../Helpers'

// Define a type for the slice state
interface ICompanyReducer {
    companyProfileForm: Partial<ICompanyProfile & {
        cacfiles: File | undefined,
        tccfiles: File | undefined,
        expatriate_quota_positionfiles: File | undefined,
    }> | undefined,
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    activeTab: number,
    totalTabs: number,
    saved: boolean,
    updateCompanyResponseStatus: number | undefined
}

const defaultValus = INIT_COMPANY_PROFILE_FORM; // INIT_DATA;

// Define the initial state using that type
const initialState: ICompanyReducer = {
    companyProfileForm: defaultValus,
    isLoading: false,
    error: false,
    message: undefined,
    activeTab: 1,
    totalTabs: 2,
    saved: false,
    updateCompanyResponseStatus: undefined
}

let dataKeys: any = {
    type_of_company: "typeOfCompany",
    // state_of_company: "stateOfCompany",
    rc_number: "rcNumber",
    tin_number: "tinNumber",
    tcc_number: "tccnumber",
    company_name: "comapnyName",
    sector: "sector",
    nature_of_business: "NatureOfBusiness",
    business_phone_country_code: "country_code",
    business_phone_number: "phone_number",
    business_email: "business_email",
    country: "country",
    state: "state",
    city: "city",
    zipcode: "zipcode",
    company_address1: "comapnyAddress1",
    company_address2: "comapnyAddress2",
    type_of_representative: "typeOfRepresentative",
    name_of_representative: "nameOfRepresentive",
    designation_of_representative: "designationOfRepresentative",
    national_identification_number: "NationalIdentificationNumber",
    nationality_of_representative: "nationality",
    bank_verification_number: "bankVerificationNumber",
    passport_number: "passportNumber",
    passport_expiry: "passportExpiry",
    cerpac_number: "cerpacNumber",
    cerpac_expiry: "cerpacExpiry",
    cac: "cac",
    tcc: "tcc",
    itf: "itf",
    pancom: "pancom",
    additional_documen: "additional_document"
}

const transformDataToUpdatePostData = (data: any) => {
    return data && Object.entries(data).length > 0 && Object.keys(data).reduce((acc: any, key) => {
        acc[dataKeys[key]] = data[key];
        return acc;
    }, {});
}

export const createCompanyProfileAsync: any = createAsyncThunk(
    'companyProfile/create',
    async (data: ICompanyProfile, thunkApi: any) => {
        try {

            const { user_id, api_token }: any = getAuth();
            const { user } =  thunkApi.getState().login
            const postData: any = {
                typeOfCompany: data.type_of_company,
                // stateOfCompany: data.state_of_company,
                tccnumber: data.tcc_number,
                rcNumber: data.rc_number,
                tinNumber: data.tin_number,
                comapnyName: data.company_name,
                sector: data.sector,
                NatureOfBusiness: data.nature_of_business,
                country_code: data.business_phone_country_code,
                phone_number: data.business_phone_number,
                business_email: data.business_email,
                country: data.country,
                state: data.state,
                city: data.city,
                zipcode: data.zipcode,
                comapnyAddress1: data.company_address1,
                comapnyAddress2: data.company_address2,
                typeOfRepresentative: data.type_of_representative,
                nameOfRepresentive: data.name_of_representative,
                designationOfRepresentative: data.designation_of_representative,
                NationalIdentificationNumber: data.national_identification_number,
                nationality: data.nationality_of_representative,
                bankVerificationNumber: data.bank_verification_number,
                passportNumber: data.passport_number,
                passportExpiry: format_Date_To_DDMMYYYY(data.passport_expiry),
                cerpacNumber: data.cerpac_number,
                cerpacExpiry: format_Date_To_DDMMYYYY(data.cerpac_expiry),
                quotadetails: data.quota_details && data.quota_details.length > 0 ? data.quota_details.map((quota: ExpatriateQuotaDetail) => {
                    return {
                        "approvalNumber": quota.ApprovalNumber,
                        "expatriateType": quota.ExpatriateType,
                        "rcnumber": data.rc_number,
                        "expirationDate": quota.ExpirationDate,
                        "totalSlotApproved": quota.TotalSlotApproved,
                        "approvedDate": quota.ApprovedDate,
                        "approvalLetterDate": quota.ApprovalLetterDate,
                        "expatriate_doc": quota.ExpatriateQuotaDetailId,
                        "position": quota.PositionList && quota.PositionList.length > 0 ? quota.PositionList.map((position: ExpatriateQuotaPosition) => {
                            return {
                                "Position": position.Position,
                                "SlotApproved": position.SlotApproved
                            }
                        }) : [],
                        "userid": user_id,
                        "comanyname": data.company_name
                    }
                }) : [],
                cac: data.cac,
                tcc: data.tcc,
                itf: data.itf,
                pancom: data.pancom,
                save_draft: false,
                userid: user_id,
                additional_document: data.additional_document,
                is_moi_verified:user?.is_moi_verified || false
            };

            const newPostData = {
                ...transformDataToUpdatePostData(data),
                save_draft: false,
                userid: user_id,
            }

            const response: any = await submitCompanyProfile(postData);
            // console.log({ response });

            if (response?.status == 201) {
                return response;
            }
            else if ([401, 400, 500, 404, 403].includes(response.status)) {
                thunkApi.dispatch(setError(response.message));
                return thunkApi.rejectWithValue(response.message);
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const updateCompanyProfileAsync: any = createAsyncThunk(
    'companyProfile/update',
    async (data: ICompanyProfile, thunkApi: any) => {
        try {

            const { user_id, api_token }: any = getAuth();

            const newPostData = {
                ...transformDataToUpdatePostData(data),
                save_draft: false,
                userid: user_id,
            }

            const response: any = await updateCompnayProfile(newPostData);
            // console.log({ response });

            if (response.status == 200) {
                thunkApi.dispatch(setUpdateResponse(response.status));
            }
            else if ([401, 400, 500, 404].includes(response.status)) {
                thunkApi.dispatch(setError(response.message));
                return thunkApi.rejectWithValue(response.message);
            }
            //return response;

        } catch (_error) {
            const error = _error as Error | AxiosError;
            // console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const fetchCompanybyId = createAsyncThunk(
    'companyProfile/fetchCompanybyId',
    // Declare the type your function argument here:
    async () => {
        const response = await getCompanyDetails();
        // console.log("fetch company", response.data)
        // Inferred return type: Promise<MyData>
        return response;
    }
)

export const fetchCompanyDetailsAsync: any = createAsyncThunk(
    'companyProfile/fetchCompanyDetails',
    async (_, thunkApi: any) => {
        try {
            const response: any = await getCompanyDetails();
            // console.log('fetchCompanyDetails', response.data);

            const { user } =  thunkApi.getState().login

            if (response.data && response.status === 200) {
                const responseData = response.data;

                const postData: any = {
                    type_of_company: responseData.typeOfCompany,
                    // state_of_company: responseData.stateOfCompany,
                    rc_number: responseData.rcNumber,
                    tcc_number: responseData.tccnumber,
                    tin_number: responseData.tinNumber,
                    company_name: responseData.comapnyName,
                    sector: responseData.sector,
                    nature_of_business: responseData.NatureOfBusiness,
                    business_phone_country_code: responseData.country_code,
                    business_phone_number: responseData.phone_number,
                    business_email: responseData.business_email,
                    country: responseData.country,
                    state: responseData.state,
                    city: responseData.city,
                    zipcode: responseData.zipcode,
                    company_address1: responseData.comapnyAddress1,
                    company_address2: responseData.comapnyAddress2,
                    type_of_representative: responseData.typeOfRepresentative,
                    name_of_representative: responseData.nameOfRepresentive,
                    designation_of_representative: responseData.designationOfRepresentative,
                    national_identification_number: responseData.NationalIdentificationNumber,
                    nationality_of_representative: responseData.nationality,
                    bank_verification_number: responseData.bankVerificationNumber,
                    passport_number: responseData.passportNumber,
                    passport_expiry: responseData.passportExpiry,
                    cerpac_number: responseData.cerpacNumber,
                    cerpac_expiry: responseData.cerpacExpiry,
                    quota_position: responseData.quotaPositions,
                    quota_utilized: responseData.utilizedQuota,
                    quota_remaining: responseData.quota_remaining,
                    cac: responseData.cac,
                    cacdocument: responseData.cacdocument,
                    tcc: responseData.tcc,
                    tccdocument: responseData.tccdocument,
                    itf: responseData.itf,
                    itfdocument: responseData.itfdocument,
                    pancom: responseData.pancom,
                    pancomdocument: responseData.pancomdocument,
                    expatriate_quota_position: responseData.expatriate_quota_position,
                    save_draft: responseData.save_draft,
                    user_id: responseData.userid,
                    additional_document: responseData.additional_document,
                    _id: responseData._id,
                    is_moi_verified:user?.is_moi_verified || false
                };
                return postData;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }

            thunkApi.dispatch(setError(error));
            return thunkApi.rejectWithValue(error);
        }
    }
);


//Add more

export const companySlicer = createSlice({
    name: 'companyprofile',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSelectedCompany: (state, action: PayloadAction<Partial<ICompanyProfile>>) => {
            state.companyProfileForm = action.payload
        },
        reSetSelectedCompany: (state) => {
            state.companyProfileForm = defaultValus
        },
        resetSave: (state) => {
            state.saved = false
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload
        },
        setActiveTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload
        },
        setNextActiveTab: (state) => {
            if (state.activeTab == state.totalTabs) {
                state.activeTab = 1
            }
            else {
                state.activeTab = state.activeTab + 1
            }
        },
        setPreviousActiveTab: (state) => {
            if (state.activeTab == 1) {
                state.activeTab = 1
            }
            else {
                state.activeTab = state.activeTab - 1
            }
        },
        setUpdateResponse: (state, action: PayloadAction<any>) => {
            state.error = false;
            state.isLoading = false;
            state.message = undefined;
            state.updateCompanyResponseStatus = action.payload;
        },
        resetUPdateResponse: (state) => {
            state.updateCompanyResponseStatus = undefined
        },
        setInitials: (state, action: PayloadAction<any>) => {
            if (!state.companyProfileForm?.company_name) {

                const userData: any = action.payload;
                // console.log({ userData });

                const keyMapping: any = {
                    type_of_company: "typeOfCompany",
                    rc_number: "rcNumber",
                    company_name: "comapnyName",
                    business_phone_country_code: "countrycode",
                    business_phone_number: "businessPhoneNumber",
                    business_email: "businessEmail",
                    company_address1: "comapnyAddress1",
                    company_address2: "comapnyAddress2",
                    type_of_representative: "typeOfRepresentative",
                    designation_of_representative: "designationOfRepresentative",
                    name_of_representative: "nameOfRepresentive",
                    national_identification_number: "NationalIdentificationNumber",
                    nationality_of_representative: "nationality",
                    bank_verification_number: "bankVerificationNumber",
                    passport_number: "passportNumber",
                    passport_expiry: "passportExpiry",
                    cerpac_number: "cerpacNumber",
                    cerpac_expiry: "cerpacExpiry",
                    // state_of_company: "stateOfCompany",
                };

                let newState: any = {};

                Object.keys(INIT_COMPANY_PROFILE_FORM).map((key: string) => {
                    if (userData[keyMapping[key]]) {
                        newState[key] = userData[keyMapping[key]];
                    }
                });

                // Object.entries(keyMapping).map(([key, value]: [any, any]) => {
                //     if (userData[value]) {
                //         newState[key] = userData[value]
                //     }
                // })

                // console.log({ newState });


                state.companyProfileForm = newState
            }
            else {

                if (state.companyProfileForm?.cacfiles) {
                    state.companyProfileForm = {
                        ...state.companyProfileForm,
                        cac: state.companyProfileForm.cacfiles
                    }
                }
                if (state.companyProfileForm?.tccfiles) {
                    state.companyProfileForm = {
                        ...state.companyProfileForm,
                        tcc: state.companyProfileForm.tccfiles
                    }
                }
                // if (state.companyProfileForm?.expatriate_quota_positionfiles) {
                //     state.companyProfileForm = {
                //         ...state.companyProfileForm,
                //         expatriate_quota_position: state.companyProfileForm.expatriate_quota_positionfiles
                //     }
                // }

            }
        },
        reSetError: (state) => {
            state.error = false;
            state.message = undefined;
            state.isLoading = false
        },
        resetState: (state) => {
            state = initialState
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(createCompanyProfileAsync.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.message = undefined
            })
            .addCase(createCompanyProfileAsync.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.message = undefined
                state.saved = true
                // state.companyProfileForm = defaultValus
            })
            .addCase(createCompanyProfileAsync.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                state.error = true;
                state.message = payload
            })
            .addCase(updateCompanyProfileAsync.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.message = undefined
                state.saved = false
            })
            .addCase(updateCompanyProfileAsync.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.message = undefined
                state.saved = true
                state.companyProfileForm = defaultValus

            })
            .addCase(updateCompanyProfileAsync.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                state.error = true;
                state.message = payload.message
            })
            .addCase(fetchCompanyDetailsAsync.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.message = undefined
            })
            .addCase(fetchCompanyDetailsAsync.fulfilled, (state, { payload }: any) => {
                // console.log("Payload get company", { payload });
                state.isLoading = false;
                state.message = undefined
                state.saved = true
                state.companyProfileForm = payload
            })
            .addCase(fetchCompanyDetailsAsync.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                state.error = true;
                state.message = payload.message
            });


    },
})

export const { setSelectedCompany,
    reSetSelectedCompany,
    setError, setActiveTab,
    setNextActiveTab,
    setPreviousActiveTab,
    resetSave,
    setUpdateResponse,
    resetUPdateResponse,
    setInitials,
    reSetError
} = companySlicer.actions

// // Other code such as selectors can use the imported `RootState` type
// export const Companys = (state: RootState) => state.global

export default companySlicer.reducer