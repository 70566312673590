import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { unescape } from "lodash";
import { getAuth } from "src/Modules/Auth/Core";
import {
  CREATE_EMPLOYEE_INIT,
  EMPLOYEE_INIT,
  IDependant,
  Icreate_employee,
  INIT_Dependant,
} from "src/Modules/EmpExpatriate/modules";
import {
  createNewEmployee,
  forgotPassword,
  validateEmail,
} from "src/services/services";
import { RootState } from "src/store";
import { ICreateNewEmployeePostData } from "src/types";

const defaultValues = CREATE_EMPLOYEE_INIT;

// Define a type for the slice state
interface IForgotPassWordReducer {
  user_id: string | undefined;
  bussienssEmail: string | undefined;
  emailState: {
    isLoading: boolean;
    isValid: boolean;
    isError: boolean;
    message: string | undefined;
    status: number | undefined;
    quations: {
      securityQuestion1: string | undefined;
      securityQuestion2: string | undefined;
      securityQuestion3: string | undefined;
    };
  };
  setPasswordState: {
    isLoading: boolean;
    isValid: boolean;
    isError: boolean;
    message: string | undefined;
    attempts: number;
    status: number | undefined;
  };
}

// Define the initial state using that type
const initialState: IForgotPassWordReducer = {
  user_id: undefined,
  bussienssEmail: undefined,
  emailState: {
    isLoading: false,
    isValid: false,
    isError: false,
    message: undefined,
    status: undefined,
    quations: {
      securityQuestion1: undefined,
      securityQuestion2: undefined,
      securityQuestion3: undefined,
    },
  },
  setPasswordState: {
    isLoading: false,
    isValid: false,
    isError: false,
    message: undefined,
    attempts: 0,
    status: undefined,
  },
};

// Create an async thunk for making the API call
export const verifyEmailAsync: any = createAsyncThunk(
  "forgotPassword/verifyEmail",
  async (bussienssEmail: string, thunkApi) => {
    try {
      thunkApi.dispatch(setUserEmail(bussienssEmail));
      const response: any = await validateEmail(bussienssEmail);

      if (response.status == 200) {
        return response;
      } else if ([401, 400, 500, 404, 403].includes(response.status)) {
        thunkApi.dispatch(setEmailStateError({
          message: response.message,
          status: response.status
        }));
        return thunkApi.rejectWithValue({
          message: response?.message,
          status: response?.status
        });
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setEmailStateError({
          message:  error.response?.data.message,
          status: error.response?.data.status
        }));
        return thunkApi.rejectWithValue({
          message: error.response?.data.message,
          status: error.response?.data.status
        });
      }
      thunkApi.dispatch(setEmailStateError({
        message:  error.message,
        status: undefined
      }));
      return thunkApi.rejectWithValue({
        message:  error.message,
        status: undefined
      });
    }
  }
);

// Create an async thunk for making the API call
export const setPasswordAsync: any = createAsyncThunk(
  "forgotPassword/setPassword",
  async (
    data: {
      businessEmail: string;
      securityAnswer1: string;
      securityAnswer2: string;
      securityAnswer3: string;
      password: string;
      token: string
    },
    thunkApi
  ) => {
    try {

      const postData = {
        businessEmail: data.businessEmail,
        securityAnswer1: data.securityAnswer1,
        securityAnswer2: data.securityAnswer2,
        securityAnswer3: data.securityAnswer3,
        password: data.password
      }

      const response: any = await forgotPassword(data);
      // console.log({ response });

      if (response.status == 200) {
        return response;
      } else if ([401, 400, 500, 404, 403].includes(response.status)) {
        if (response?.status == 403) {
          const count = response?.message?.replace(/[^0-9]+/g, "");
          if (count !== 0) {
            thunkApi.dispatch(setCounter(parseInt(count)));
          }
          else {
            thunkApi.dispatch(setCounter(3));
          }

        }

        thunkApi.dispatch(setPasswordStateError(response.message));
        return thunkApi.rejectWithValue(response);
      }
      //   return response.data;
    } catch (_error) {
      // console.log("catch errorr");
      const error = _error as Error | AxiosError;
      // console.log(error);
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        if (
          error.response?.status == 403 ||
          error.response?.data?.status == 403
        ) {
          const count = error.response?.data?.message?.replace(/[^0-9]+/g, "");
          thunkApi.dispatch(setCounter(parseInt(count)));
          return thunkApi.rejectWithValue(error.response?.data.message);
        }
        thunkApi.dispatch(setPasswordStateError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setPasswordStateError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const forgotPasswordSlicer = createSlice({
  name: "forgotPassword",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEmailStateError: (state, action: PayloadAction<any>) => {
      state.emailState.isError = true;
      state.emailState.message = action.payload?.message;
      state.emailState.status = action.payload?.status;
      state.emailState.isValid = false;
    },
    setUserEmail: (state, action: PayloadAction<any>) => {
      state.bussienssEmail = action.payload;
    },
    setPasswordStateError: (state, action: PayloadAction<any>) => {
      state.setPasswordState.isError = true;
      state.setPasswordState.message = action.payload;
      state.setPasswordState.isValid = false;
    },
    partialResetEmailState: (state) => {
      state.emailState.isError = false;
      state.emailState.isLoading = false;
      state.emailState.message = undefined;
      state.emailState.isValid = false;
    },
    resetEmailState: (state) => {
      state.emailState.isError = false;
      state.emailState.isLoading = false;
      state.emailState.message = undefined;
      state.emailState.isValid = false;
    },
    partialResetSetPasswordState: (state) => {
      state.setPasswordState.isError = false;
      state.setPasswordState.isLoading = false;
      state.setPasswordState.message = undefined;
      state.setPasswordState.isValid = false;
    },
    resetSetPasswordState: (state) => {
      state.setPasswordState.isError = false;
      state.setPasswordState.isLoading = false;
      state.setPasswordState.message = undefined;
      state.setPasswordState.isValid = false;
      state.setPasswordState.attempts = 0;
    },
    setCounter: (state, action: PayloadAction<any>) => {
      state.setPasswordState.attempts = action.payload;
    },
    resetState: (state) => {
      state.emailState.isError = false;
      state.emailState.isLoading = false;
      state.emailState.message = undefined;
      state.emailState.isValid = false;
      state.setPasswordState.isError = false;
      state.setPasswordState.isLoading = false;
      state.setPasswordState.message = undefined;
      state.setPasswordState.isValid = false;
      state.setPasswordState.attempts = 0;
      state.setPasswordState.status = undefined;
      state.emailState.quations = {
        securityQuestion1: undefined,
        securityQuestion2: undefined,
        securityQuestion3: undefined,
      };
      state.user_id = undefined;
      state.bussienssEmail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        verifyEmailAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.emailState.isLoading = true;
          state.emailState.isError = false;
          state.emailState.message = undefined;
          state.emailState.isValid = false;
        }
      )
      .addCase(
        verifyEmailAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          const resp = action.payload?.data;
          state.emailState.isLoading = false;
          state.emailState.isError = false;
          state.emailState.message = undefined;
          state.emailState.isValid = true;
          if (resp) {
            state.emailState.quations = {
              securityQuestion1: resp?.securityQuestion1,
              securityQuestion2: resp?.securityQuestion2,
              securityQuestion3: resp?.securityQuestion3,
            };
            state.user_id = resp?._id;
          }
        }
      )
      .addCase(
        verifyEmailAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.emailState.isLoading = false;
          state.emailState.isError = true;
          state.emailState.message = action.payload?.message;
          state.emailState.isValid = false;
        }
      )
      .addCase(
        setPasswordAsync.pending,
        (state, action: PayloadAction<any>) => {
          state.setPasswordState.isLoading = true;
          state.setPasswordState.isError = false;
          state.setPasswordState.message = undefined;
          state.setPasswordState.isValid = false;
        }
      )
      .addCase(
        setPasswordAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.setPasswordState.isLoading = false;
          state.setPasswordState.isError = false;
          state.setPasswordState.message = undefined;
          state.setPasswordState.isValid = true;
          state.setPasswordState.status = action.payload?.status
        }
      )
      .addCase(
        setPasswordAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.setPasswordState.isLoading = false;
          state.setPasswordState.isError = true;
          state.setPasswordState.message = action.payload.message;
          state.setPasswordState.isValid = false;
          state.setPasswordState.status = action.payload.status;
        }
      );
  },
});

export const {
  setEmailStateError,
  setPasswordStateError,
  partialResetEmailState,
  resetEmailState,
  partialResetSetPasswordState,
  resetSetPasswordState,
  setUserEmail,
  setCounter,
  resetState
} = forgotPasswordSlicer.actions;

// // Other code such as selectors can use the imported `RootState` type
export const forgotPasswordState = (state: RootState) => state.forgotPassword

export default forgotPasswordSlicer.reducer;
//  export default []
