import React, { useEffect, useState } from "react";
import FormkiVirtualSelect from "../VirtualizedSelect/FormkiVirtualSelect";
import { getAllCities, getAllStates } from "src/services/services";
import axios, { AxiosError } from "axios";
import { optionsType } from "@/helper/index";
import { useDispatch } from "react-redux";
import { RootState } from "src/store";
import { useSelector } from "react-redux";

type Props = {
  formik: any;
  nameKey: string;
  required?: boolean;
  countryname?: string | undefined;
  onchange?: (e: any) => void;
};

const FormikStatePicker = ({
  formik,
  nameKey,
  required,
  countryname,
  onchange,
}: Props) => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [data, setdata] = useState<optionsType[] | undefined>(undefined);
  const [isError, setisError] = useState<boolean>(false);
  const [errorMesg, seterrorMesg] = useState<string | undefined>(undefined);
  const [isDisabled, setisDisabled] = useState<boolean>(false);

  // console.log({countryname})

  useEffect(() => {
    if (countryname && countryname) {
      fetchStates();
    }
    return () => {};
  }, [countryname]);

  const fetchStates = async () => {
    try {
      setisLoading(true);
      setisDisabled(true);
      setdata(undefined);
      let apiResp: any = countryname && (await getAllStates(countryname));
      if (apiResp && apiResp) {
        setisLoading(false);
        setisDisabled(false);
        setdata(
          apiResp.map((o: any) => ({
            value: o,
            label: o,
            icons: undefined,
            disabled: false,
          }))
        );
        // console.log({ apiResp });
      }
    } catch (e: any) {
      const error = e as Error | AxiosError;
      // console.log("registration Error ", error);
      if (axios.isAxiosError(error)) {
        seterrorMesg(error.response?.data.message);
        setisError(true);
        setisDisabled(true);
        setisLoading(false);
        setdata(undefined);
      } else {
        seterrorMesg(error.message);
        setisError(true);
        setisDisabled(true);
        setisLoading(false);
        setdata(undefined);
      }
    }
  };
  return (
    <>
      <FormkiVirtualSelect
        nameKey={nameKey}
        formik={formik}
        placeholder={"Select state"}
        options={data ?? []}
        label={"state"}
        key={nameKey}
        onChange={(e: any) => {
          onchange && onchange(e);
        }}
        required={required}
        optionsWidth={400}
      />
    </>
  );
};

export default FormikStatePicker;
