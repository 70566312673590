import React from "react";
import { FormField, FormItem, FormLabel, FormMessage } from "../form";
import DateInput from "./DateInput";
import { cn } from "src/lib/utils";
import { format } from "date-fns";
import { Label } from "../Label";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";
import { InfoIcon } from "lucide-react";


type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  onchange?: (e: any) => void;
  disabled?: boolean,
  showInfoIcon?: boolean
  infoContent?: string,
  toYear?:number
  fromYear?:number
};

const FormikDateField = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  onchange,
  disabled,
  showInfoIcon,
  infoContent,
  toYear,
  fromYear
}: Props) => {
  return (
    <>
      <div className="flex justify-between mt-2">
        <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
          {label}
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event:any) => event.preventDefault()} >
                <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <div className="mt-2">
        <DateInput
          qa-data={nameKey}
          disabled={disabled}
          date={formik.values[nameKey]}
          toYear={toYear}
          fromYear={fromYear}
          selectedDate={(e: any) => {
            // console.log(e);
            if (e && e) {
              onchange && onchange(format(new Date(e), "yyyy-MM-dd"));
            } else {
              onchange && onchange(undefined);
            }
          }}
        />
      </div>
      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <p className={cn("text-xs font-medium text-destructive")}>
          {formik.errors[nameKey]}
        </p>
      )}
    </>
  );
};

export default FormikDateField;
