import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setActiveTab } from "src/Silcers/companyRegistrationSlicer";
import PageHeadingComponent from "src/components/Common/PageHeadingComponent";
import { Button } from "src/components/ui/button";

function EllOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  return (
    <div className="p-6 flex flex-col justify-center items-center">
      <div className=""></div>
      <div className=" md:w-4/6 ">
        <div></div>
        <div className="h-[280px] overflow-auto bg-white flex flex-col  mt-5 ">
          <div className=" flex justify-center">
            <PageHeadingComponent
              mainContent={"OVERVIEW"}
            />
          </div>
          <div className="mt-5 flex justify-center">
            <div className="md:w-3/4 ">
              <p className=" mb-2 w-4/4">
                <b>The Expatriate Employment Levy (EEL)</b> is a contribution imposed
                by the <b>Federal Government of Nigeria (FGN)</b> on employers of
                expatriates working in Nigeria.
              </p>
              <p className=" mb-2 mt-10 w-4/4">
                The <b>EEL</b> is being implemented as a multi-faceted policy
                instrument that seeks to balance economic growth, social equity,
                and workforce development within <b>Nigeria</b>.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-center w-full  mt-5 ">
          {/* <Button
            onClick={(e) => {
              dispatch(setActiveTab(1))
            }}
            className="my-6 px-24 mx-4 border border-primary"
            variant={"outline"}
          >
            Register Now
          </Button> */}

          <Button
            onClick={(e) => {
              dispatch(setActiveTab(1))
            }}
            className="my-6 px-24 mx-4 border border-primary"
            variant={"outline"}
          >
            Back
          </Button>

          <Button onClick={() => {
            dispatch(setActiveTab(3))
          }} className="my-6 px-24 mx-4">
            Continue
          </Button>

        </div>
      </div>
    </div>
  );
}

export default EllOverview;
