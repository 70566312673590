import _ from 'lodash'
import { uploadfile } from 'src/services/services';

//.reduce((a, v) => ({ ...a, [v]: v }), {})

export const registration_headers = [
  'PRE-REGISTRATION',
  'PRE-REGISTRATION',
  'PRE-REGISTRATION',
  'PRE-REGISTRATION',
  'COMPANY REGISTRATION',
  'COMPANY REVIEW',
];

export const registration_subHeaders = [
  'Three things need to know before register',
  'Please follow the steps to complete registration ',
  'Review and Submit',
  'Registration completed '
];


export const registration_steps_heading = [
  'Please read following documents before continuing',
  'Please fill the company registration form',
  'Review and Submit',
  'Registration completed '
];



export const company_profile_heradings = [
  {
    heading: 'Company Detail',
    subHeading: 'Provide the complete details about your company',
    mainHeading: "Company Profile Setup "
  },
  {
    heading: 'Company Detail Review',
    subHeading: 'Review the complete details about your company.',
    mainHeading: "Company Profile Review "
  },
];

export const company_profile_view_headings = [
  {
    heading: 'Company Detail View',
    subHeading: 'Review the complete details about your company.',
    mainHeading: "Company Profile View "
  }
];
export const company_profile_edit_headings = [
  {
    heading: 'Company Detail Edit',
    subHeading: 'Edit details about your company',
    mainHeading: "Company Profile Edit "
  }
];

export type optionsType = {
  value: string;
  label: string;
  icons?: string;
  disabled?: boolean;
};


export const DESIGNATIONS = _.sortBy([
  "Director",
  "Chairman",
  "Managing Director",
  "Chief Executive Officer",
  "Chief Financial Officer",
  "Financial Officer",
  "Chief Operating Officer",
  "Chief Technology Officer",
  "Vice President",
  "Manager",
  "Manufacturing Engineer",
  "Quality Management",
  "Production Manager",
  "Operator",
  "Industrial Engineer",
  "Engineer",
  "Other"
])

export const EXPATRIATE_TYPES = _.sortBy([
  // "Grant of Establishment Quota",
  // "Renewal of Quota Positions",
  // "Additional Quota per Slot",
  // "Business Permits",
  // "Amendment of Business Permit",
  // "Upgrade of Quota to PUR",
  // "Re-Designation of PUR",
  // "Downgrading of PUR",
  // "Revalidation of PUR",
  // "Re-Designation of Expatriate Quota",
  // "Restoration of Lapsed Expatriate Quota Positions",
  // "Detagging of Expatriate Quota Positions",
  // "Project Tagged Expatriate Quota Positions",
  // "Extension of Tagged Quota Position",
  // "Extension of Project Tagged Expatriate Quota Position(s)",
  // "Revalidation of Expatriate Quota Position(s)",
  // "Appeal on Rejected Expatriate Quota Position(s)",
  // "Stay of Action",
  // "Withdrawal of Expatriate Quota Position(s)",
  // "Relinquishment of Expatriate Quota Position(s)"
  "Applications for the Grant of Establishment Quota",
  "Applications for the Renewal of Quota Positions",
  "Applications for Additional Quota per Slot"
])


export const EXPATRIATE_TYPES_OPTIONS = EXPATRIATE_TYPES.map((o: any) => ({
  label: o,
  value: o
}))

export const EXPATRIATE_TYPES_OPTIONS_2: optionsType[] = EXPATRIATE_TYPES.map((o: any) => ({
  label: o,
  value: o
}))


export const STATE_OF_COMPANY = _.sortBy([
  "State Government", "Wholly Foreign", "Indigenous", "NGO (Religious Organizations)", "Joint Ventures", "NGO (Humanitarian Services)", "Business Name, Non Limited Partnerships", "Other"
])

export const STATE_OF_COMPANY_OPTIONS = STATE_OF_COMPANY.map((o: any) => ({
  label: o,
  value: o
}))

export const NATIONALITY = _.sortBy(["Nigerian", "Foreigner"]);

export const NATURE_OF_BUSSINESS = _.sortBy([
  "Agriculture",
  "Airline Maintenance",
  "Aviation",
  "Aviation Nature of Business",
  "Banking",
  "Banking and Finance",
  "Building and Construction",
  "Construction",
  "Crude Oil",
  "Electric Company",
  "Engineering",
  "Finance and Banking",
  "Healthcare",
  "Hospital Services",
  "Hotel",
  "Humanitarian",
  "ICT",
  "IT",
  "Manufacturing",
  "Manufacturing Of Pharmaceutical and Drugs",
  "NGO",
  "Oil",
  "Oil and Gas",
  "Oil and Gas Exploration",
  "Oil Drilling",
  "Oil Trading",
  "Private",
  "Professional",
  "Profit Raising",
  "School",
  "Sky Daddy Duties",
  "Software Company",
  "Spice Trading",
  "Taxi",
  "Teaching",
  "Technology",
  "Testing",
  "Trading",
  "Other"
]);

export const NATURE_OF_BUSSINESS_OPTIONS_2: optionsType[] = NATURE_OF_BUSSINESS.map((o: any) => ({
  label: o,
  value: o
}))

export const TYPE_OF_COMPANY_OPTIONS = (["Private Unlimited Company",
"Private Limited Company",
"Public Company Limited by Guarantee",
"Public Limited Company",
"Sole Proprietorship",
"NGO(Humanitarian Service)",
"NGO(Religious Organizations)",
"Foundation",
"Incorporated Trustee",
"State Government",
"Others"]).map((o: any) => ({
  label: o,
  value: o
}))

export const type_of_representative_OPTIONS = NATIONALITY.map((o: any) => ({
  label: o,
  value: o
}))

export const type_of_designation_representative_OPTIONS = _.sortBy(DESIGNATIONS).map((o: any) => ({
  label: o,
  value: o
}))

export const nationality_representative_OPTIONS = NATIONALITY.map((o: any) => ({
  label: o,
  value: o
}))

export const TYPE_OF_EMPLOYEE_OPTIONS = _.sortBy(["Director", "Non-Director"]).map((o: any) => ({
  label: o,
  value: o
}))

export const MARITAL_STATUS_OPTIONS = _.sortBy(["Single", "Married", "Divorced", "Widowed"]).map((o: any) => ({
  label: o,
  value: o
}))

export const WORK_STATUS_OPTIONS = _.sortBy(["Permit", "Part-time"]).map((o: any) => ({
  label: o,
  value: o
}))

export const GENDER_OPTIONS = _.sortBy(["Male", "Female", "Other"]).map((o: any) => ({
  label: o,
  value: o
}))

export const SECURITY_QUATIONS = _.sortBy([
  "what is your first Bank Name ?",
  "In what city or town was your first job?",
  "What was your childhood nickname?",
  "What is your pet’s name?",
  "What was the name of your elementary / primary school?",
  "What is the name of your favorite childhood friend?",
  "In what city or town does your nearest sibling live?",
  "In what year was your father born?",
  "What is your favorite color?",
  "In what city were you born?"
], 'asc')



export const SECURITY_QUATIONS_OPTIONS = SECURITY_QUATIONS.map((o: any) => ({
  label: o,
  value: o
}))

export const SECTORS = _.sortBy([
  "Agriculture-Agro-Alled",
  "Aviation",
  "Commercial Banking",
  "Commercial (Insurance)",
  "Commercial (investment)",
  "Education",
  "Engineering/Construction",
  "Health Care",
  "Hospitality and Tourism",
  "Housing/Real Estate",
  "Humanitarian (NGOs)",
  "Humanitarian (Religious Organizations)",
  "ICT & Telecoms",
  "Manufacturing",
  "Oil and Gas",
  "Professional Services",
  "Transport (Courier/Logistics)",
  "Transport (Marine/Shipping)",
  "Other",
  "Mining",
  "Power/Energy/Electricity"
])

export const SECTORS_OPTIONs = SECTORS.map((o: any) => ({
  label: o,
  value: o
}))
export const SECTORS_OPTIONS_2: optionsType[] = SECTORS.map((o: any) => ({
  label: o,
  value: o
}))

export const VISA_TYPES = _.sortBy([
  "Business Visa",
  "Diplomatic Visa",
  "Residence Visa",
  "Student Visa",
  "Tourist Visa",
  "Transit Visa",
  "Work Visa",
  "Visit Visa",
  "Entry Ticket"
])

export const VISA_TYPES_OPTIONS = VISA_TYPES.map((o: any) => ({
  label: o,
  value: o
}))

export const RELATIONS = _.sortBy([
  "Mother",
  "Father",
  "Son",
  "Daughter",
  "Spouse"
])

export const RELATIONS_OPTIONS = RELATIONS.map((o: any) => ({
  label: o,
  value: o
}))

export const DESIGNATIONS_OPTIONS_DROPDOWN: optionsType[] = []

DESIGNATIONS.forEach((v: any, indx: number) => {
  DESIGNATIONS_OPTIONS_DROPDOWN[indx] = {
    value: v,
    label: v
  }
})


export const DESIGNATION_OPTIONS = DESIGNATIONS.map((o: any) => ({
  label: o,
  value: o
}))

export const DESIGNATION_OPTIONS_2: optionsType[] = DESIGNATIONS.map((o: any) => ({
  label: o,
  value: o
}))

export const REGSITRATOPM_STEP_1_OPTIONS = [
  {
    title: "OVERVIEW",
    sub_title: "To know more about expatriate employment levy (EEL) program.",
    link: "/ell-overview"
  },
  {
    title: "REQUIREMENTS",
    sub_title: "To know the necessary documents you would need to complete your application. ",
    link: "/ell-requirement"
  },
  {
    title: "LEVY DETAILS",
    sub_title: "To view the amount for each service.",
    link: "/ell-fee"
  }
]

export const SECURITY_QUATIONS_LIST = SECURITY_QUATIONS && SECURITY_QUATIONS?.map((o: any) => ({
  value: o,
  label: o
}));


export enum ENUM_KYC_STATUS {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export const PASSWORD_REGX: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/




