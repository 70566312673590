import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SVGC } from 'src/Helpers/SVGC';
import { resetState as forgotPasswordResetState } from "src/Silcers/forgotPasswordSlicer";
import { reseteState as loginResetState } from "src/Silcers/loginSlicer";
import { toAbsoluteUrl } from "../../../Helpers";
import { useEffect, useState } from "react";
import CustomeDialog from "src/components/Common/CustomeDialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRight, X } from "lucide-react";
import UserAuthForm from "../Login/UserAuthForm";
import { Button } from "src/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "src/components/ui/carousel";
import { PlayIcon } from "@radix-ui/react-icons";
import EnquiryForm from "./EnquiryForm";

type Props = {};


const imageArray = [
    {
        url: "/media/images/EncouragingLocalEmployment.jpeg",
        title: "Encouraging Local Employment",
        more: "By imposing a levy on the employment of expatriates, the government aims to encourage companies to prioritize hiring local talent. This can contribute to reducing unemployment rates among Nigerians."
    },
    {
        url: "/media/images/SkillsTransfer.png",
        title: "Skills Transfer",
        more: "When expatriates are employed, there's an opportunity for knowledge and skill transfer to local employees. This can enhance the skill set of the local workforce, potentially improving the overall productivity and competitiveness of Nigerian workers."
    },
    {
        url: "/media/images/RegulationandControl.jpg",
        title: "Regulation and Control",
        more: "The levy also serves as a regulatory tool to control the influx of expatriate workers into the country. By imposing this levy, the government can manage and monitor the number of expatriates being employed in different sectors, ensuring that their presence aligns with the country's economic and developmental goals."
    },
    {
        url: "/media/images/EncouragingInvestmentsinLocalWorkforce.jpeg",
        title: "Encouraging Investments in Local Workforce",
        more: "Companies might be incentivized to invest in training and developing local talent rather than relying on expatriate workers due to the cost implications of the levy. This can lead to a more skilled local workforce and reduce dependency on foreign labor in the long run."
    },
]

const LandinPage = (props: Props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showpopup, setshowpopup] = useState(true)


    // useEffect(() => {
    //     dispatch(loginResetState());
    //     dispatch(forgotPasswordResetState())
    //     return () => { }
    // }, [])


    return (
        <>
            <div className=" shadow-md py-10 bg-slate-40">
                {/* <Carousel>
                    <CarouselContent>
                        {[1, 2, 3].map((item) =>
                            <CarouselItem key={item}>

                            </CarouselItem>
                        )}
                    </CarouselContent>
                    <CarouselPrevious className=" bg-slate-500 z-50" />
                    <CarouselNext className=" bg-slate-500 z-50" />
                </Carousel> */}
                <div className="lg:flex lg:justify-around w-full px-4 lg:px-20 my-4">
                    <div className="lg:flex-1 p-4 lg:p-8">
                        <h2 className="text-4xl lg:text-6xl md:text-2xl font-bold mb-2">
                            Welcome to <span className="text-primary">EELMS</span> online portal
                        </h2>
                        <p className=" text-xl mt-4">This portal is designed to streamline and facilitate the process of managing Expatriates Employment Levy in Nigeria.</p>
                        <Button onClick={() => navigate('/auth')} className="w-full lg:w-60 my-4 rounded-xl p-4 text-lg uppercase font-normal">
                            Login / Register
                        </Button>
                    </div>
                    <div className="lg:flex-1 overflow-hidden p-4 lg:px-8 mt-4 lg:mt-0">
                        <img
                            src={toAbsoluteUrl("/media/images/welcome.jpg")}
                            alt="Image"
                            className="lg:w-4/4 h-80 lg:h-96 rounded-2xl w-full"
                        />
                    </div>
                </div>
            </div>
            <div className=" p-16">
                <h1 className=" font-semibold text-3xl text-center text-gray-500">
                    Expatriate Employment Levy (EEL) Nigeria</h1>
                <div className=" p-5 flex justify-center flex-wrap [1352px]:flex-nowrap mt-4">
                    {imageArray.map(((ele, index) => <div
                        key={index}
                        className="relative group mx-3 rounded-lg border-black"
                    >
                        <img
                            src={toAbsoluteUrl(ele.url)}
                            alt="Image"
                            className=" h-44 w-80  group-hover:opacity-80 rounded-sm"
                        />
                        <div
                            className="group-hover:opacity-80 transition-opacity duration-300  bg-primary w-80 h-12">
                            <p className="uppercase text-white text-center pt-2 text-wrap text-sm">{ele.title}</p>
                        </div>
                        <div
                            className="absolute inset-0 flex items-center justify-center hover:bg-opacity-100 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ">
                            <p className="text-white font-semibold p-2 text-sm mb-6 bg-black bg-opacity-50">{ele.more}</p>
                        </div>
                    </div>))}
                </div>
            </div>
            <div className="p-8 md:p-20 flex justify-start bg-slate-50">
                <div className="">
                    <h1 className="scroll-m-20 font-normal tracking-tight lg:text-3xl mb-2">
                        <span className="font-medium text-primary underline underline-offset-8">About EELMS</span>
                    </h1>
                    <p>The Expatriate Employment Levy Management System (EELMS) Program in Nigeria is established to oversee expatriate employment in the country, necessitating employers to pay a levy for hiring expatriates. Its primary goals include fostering the development of the local workforce, diminishing reliance on foreign skills, and encouraging companies to prioritize Nigerian citizens in their hiring practices. Through this levy, the government aims to find equilibrium between leveraging international expertise and nurturing indigenous talent in Nigeria's job market. Adherence to the EEL is compulsory for companies employing expatriates across sectors, with the funds collected often allocated to initiatives aimed at bolstering local job opportunities and advancing skills training programs.
                    </p>
                </div>
            </div>
            <hr />
            <div className="flex flex-col lg:flex-row items-start md:mx-0 mb-20 justify-between p-8 md:p-20">
                <div className="flex justify-center w-full items-center h-full">
                    <div>
                        <div className="flex flex-col mb-4">
                            <h1 className="scroll-m-20 font-normal tracking-tight text-xl lg:text-3xl mb-2">
                                <span className="font-medium text-primary underline underline-offset-8">EELMS Guidelines</span>
                            </h1>
                        </div>
                        <div className="flex flex-col md:flex-row sm:flex-col md:mt-8 md:w-3/4">
                            <div className=" p-1 bg-white md:mr-5">
                                <p>The document reference aims to provide users with guidance on effectively navigating through the portal, enhancing both user experience and operational efficiency.</p>
                                <div className="flex md:flex-row sm:flex-col md:mt-8">
                                    <div className="grow p-3 bg-white mx-1 md:mx-5 my-3 md:my-0 cursor-pointer hover:border-solid hover:border-2 hover:border-primary">
                                        <Link to={process.env.REACT_APP_HANDBOOK_URL || ""} target="_blank">
                                            <div>
                                                <SVGC
                                                    path={toAbsoluteUrl("/media/svg/newhandbook.svg")}
                                                    className="w-[40px] h-[40px] mr-3 mt-2"
                                                />
                                            </div>
                                            <div>
                                                <h1 className="text-xl md:text-2xl font-semibold text-gray-600">Handbook</h1>
                                                <p>Handbook</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="grow p-3 bg-white mx-1 md:mx-5 my-3 md:my-0 cursor-pointer hover:border-solid hover:border-2 hover:border-primary">
                                        <Link to={process.env.REACT_APP_USER_MANUAL || ""} target="_blank">
                                            <div>
                                                <SVGC
                                                    path={toAbsoluteUrl("/media/svg/newusermanual.svg")}
                                                    className="w-[40px] h-[40px] mr-3 mt-2"
                                                />
                                            </div>
                                            <div>
                                                <h1 className="text-xl md:text-2xl font-semibold text-gray-600">User Manual</h1>
                                                <p>EEL User Manual</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="grow p-3 bg-white mx-1 md:mx-5 my-3 md:my-0 cursor-pointer hover:border-solid hover:border-2 hover:border-primary">
                                        <Link to={process.env.REACT_APP_PLAYSTORE || ""} target="_blank">
                                            <div>
                                                <SVGC
                                                    path={toAbsoluteUrl("/media/svg/applogo.svg")}
                                                    className="w-[40px] h-[40px] mr-3 mt-2"
                                                />
                                            </div>
                                            <div>
                                                <h1 className="text-xl md:text-2xl font-semibold text-gray-600">App</h1>
                                                <p>EEL Play Store</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grow w-full md:w-auto h-full mt-4">
                    <div className="flex justify-center items-center h-full">
                        <EnquiryForm />
                    </div>
                </div>
            </div>
            <CustomeDialog
                isOpen={showpopup}
                hideDefaultSubmit
                headerTitle={false}
                submitBtnTitle="Accept"
                hideDefaultCancle
                className=" bg-no-repeat bg-cover lg:max-w-4xl " style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/svg/eel_bg_popup.svg")})`,
                }}  >
                <>
                    <div className="flex justify-end mb-5"><X size={16} className="cursor-pointer" onClick={() => setshowpopup(false)} /></div>
                    {/* <div className="space-y-10 mb-8">
                        <div className="flex justify-between px-10">
                            <img src={toAbsoluteUrl('/media/images/ministry.png')} className='w-[150px]' />
                            <img src={toAbsoluteUrl('/media/images/immigration.png')} className='w-[150px]' />
                        </div>
                        <hr className="border-t-2 border-green-500 border-dotted mx-12" />
                        <div className="mb-5 text-gray-900 text-lg px-5 text-center">
                            The President and Commander-in-Chief of the Armed Force of The Federal Republic of <br />
                            Nigeria has launched the Expatriate Employment Levy (EEL) on <br />
                            <span className=" text-primary font-medium"><strong>Tuesday, 27th February, 2024.</strong></span> <br /> <br />
                            For further details and registration of your company and expatriates working with you,<br />
                            kindly go through the Handbook and User Manual available on the portal.<br /><br />
                            The companies can do the registration for their expatriates working with them from now <br />
                            onwards however the effective date of implementation of Expatriate Employment Levy <br />
                            will start from <span className=" text-primary font-medium"><strong>Friday, 15th March, 2024.</strong></span><br />
                            The last date of compliance with EEL is <span className=" text-primary font-medium"><strong>Monday, 15th April, 2024.</strong></span><br /><br />

                            <span className=" text-red-700 font-medium"><strong>
                                EEL card is a mandatory document like passport, and will be required <br />
                                at the time of lawful exit and entry into the country.</strong>
                            </span>
                            <br />
                        </div>
                    </div> */}
                    <div className="space-y-10 md:mb-8 px-5 md:px-10">
                        <div className="flex md:flex-row justify-between items-center">
                            <img src={toAbsoluteUrl('/media/images/ministry.png')} className='w-full w-[40px] md:w-[150px]' />
                            <img src={toAbsoluteUrl('/media/images/immigration.png')} className='w-full w-[40px] md:w-[150px]' />
                        </div>

                        <hr className="border-t-2 border-green-500 border-dotted mx-auto md:mx-12" /> {/* Centered the line and made it responsive */}

                        <div className="text-gray-900 text-sm md:text-lg text-center overflow-y-auto max-h-[300px] md:max-h-full py-3"> {/* Adjusted text alignment */}
                            The President and Commander-in-Chief of the Armed Force of The Federal Republic of <br />
                            Nigeria has launched the Expatriate Employment Levy (EEL) on <br />
                            <span className="text-primary font-medium"><strong>Tuesday, 27th February, 2024.</strong></span> <br /> <br />
                            For further details and registration of your company and expatriates working with you,<br />
                            kindly go through the Handbook and User Manual available on the portal.<br /><br />
                            The companies can do the registration for their expatriates working with them from now <br />
                            onwards however the effective date of implementation of Expatriate Employment Levy <br />
                            will start from <span className="text-primary font-medium"><strong>Friday, 15th March, 2024.</strong></span><br />
                            The last date of compliance with EEL is <span className="text-primary font-medium"><strong>Monday, 15th April, 2024.</strong></span><br /><br />

                            <span className="text-red-700 font-medium"><strong>
                                EEL card is a mandatory document like passport, and will be required <br />
                                at the time of lawful exit and entry into the country.</strong>
                            </span>
                            <br />
                        </div>
                    </div>
                </>
            </CustomeDialog>
        </>
    );
};

export default LandinPage;
