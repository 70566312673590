import { Outlet } from "react-router-dom";
import FooterComponent from "../../components/FooterComponent";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import { useAuth } from "../Auth/Core";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from "src/components/ui/navigation-menu";
import { NavigationMenuSub } from "@radix-ui/react-navigation-menu";

type Props = {};

const LayoutMain = (props: Props) => {
  const { currentUser } = useAuth();
  return (
    <>
      <div className="flex flex-col min-h-screen bg-white">
        <div className="w-full sticky left-0 top-0 z-40 bg-white shadow-md">
          {/* <p>{remaining} seconds remaining</p> */}
          <HeaderComponent />
        </div>
        <div className="flex-grow">
          <div className=" md:mb-14">
            {/* Content Page */}
            <Outlet />
          </div>
        </div>
        <div className="w-full static z-10 bg-white md:mt-32 md:fixed md:bottom-0 ">
          <FooterComponent />
        </div>
      </div >
    </>
  );
};

export default LayoutMain;
