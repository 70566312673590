import React, { useEffect, useState } from "react";
import { DataTable } from "./EmployeeTable/components/data-table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { columns } from "./EmployeeTable/components/columns";
import { getAllPaymentsAsync } from "src/Silcers/paymentsListSlicer";

type Props = {};

type EmployueeeListSchema = {
  id: string;
  user_profile_photo: string;
  user_name: string;
  gender: string;
  designation: string;
  nationality: string;
  passport_number: string;
  passport_expiry: string;
  visa_number: string;
  visa_type: string;
  visa_expiry: string;
};

const PaymentList = (props: Props) => {

  const { allPayments, isError, isLoading, errorMessage } = useSelector(
    (state: RootState) => state.paymentsList
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPaymentsAsync());
    return () => {};
  }, []);

  // console.log({allPayments});
  

  return (
    <div>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <>{<DataTable data={allPayments || []} columns={columns} />}</>
      )}
    </div>
  );
};

export default PaymentList;
