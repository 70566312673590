import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Button } from "src/components/ui/button";
import { Loader2 } from "lucide-react";

export interface ITableActionMenuOptions {
  title: string;
  icon: any;
  onClick: () => void;
}

type Props = {
  title: string;
  titleIcon?: any;
  options: ITableActionMenuOptions[];
  isLoading?: boolean;
  isDisabled?: boolean
};

{
  /* <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
          Asc
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
          Desc
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
          <EyeNoneIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
          Hide
        </DropdownMenuItem> */
}

const ListActionsDropdowns = ({
  title,
  options,
  titleIcon,
  isLoading,
  isDisabled
}: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          disabled={isLoading || isDisabled}
          size="sm"
          className="-ml-3 h-8 bg-secondary text-secondary-foreground hover:bg-gray-200 hover:text-secondary-foreground hover:outline-primary outline-none active:outline-none focus:outline-none"
        >
          {(isLoading) && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          <span>{title}</span>
          {titleIcon}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {options &&
          options.map(({ title, icon, onClick }: ITableActionMenuOptions) => {
            return (
              <DropdownMenuItem onClick={onClick}>
                {icon}
                {title}
              </DropdownMenuItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ListActionsDropdowns;
